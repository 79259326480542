<div class="modal-header">
  <div class="modal-title" appTestLocator="change-status__title">Сменить статус</div>
</div>
<div class="modal-body" appTestLocator="change-status__change-status-content">
  <div
    *ngFor="let item of statuses"
    [class.active]="item.value === ngModel.status"
    (click)="selectStatus(item.value)"
    [ngClass]="item.classes"
    appTestLocator="change-status__select-{{ item.value }}"
    class="section-status"
  >
    <div class="section-status-circle">
      <app-status-viewer
        [status]="item.value"
        appTestLocator="change-status__status-{{ item.value }}"
      ></app-status-viewer>
    </div>
    <span appTestLocator="change-status__status-{{ item.value }}_name">
      {{ item.name }}
    </span>
  </div>
</div>
<div class="modal-footer">
  <button
    class="btn btn-dc-outline"
    (click)="close()"
    appTestLocator="change-status__cancel"
    [disabled]="!hasChanges()"
  >
    Отменить
  </button>
  <button
    class="btn btn-dc-primary"
    (click)="save()"
    appTestLocator="change-status__confirm"
    [disabled]="!hasChanges()"
  >
    Сменить
  </button>
</div>
