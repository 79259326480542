import { Component, EventEmitter, HostListener, OnDestroy, Output } from '@angular/core';

@Component({
  selector: 'app-rich-modal',
  templateUrl: './rich-modal.component.html',
  styleUrls: ['./rich-modal.component.scss'],
})
export class RichModalComponent {
  @Output() close = new EventEmitter();
  x: number;
  y: number;
  px: number;
  py: number;
  width: number;
  height: number;
  minWidth: number;
  minHeight: number;
  draggingCorner: boolean;
  draggingWindow: boolean;
  resizer: Function;

  constructor() {
    this.x = 300;
    this.y = 50;
    this.px = 0;
    this.py = 0;
    this.width = 900;
    this.height = 600;
    this.draggingCorner = false;
    this.draggingWindow = false;
    this.minWidth = 800;
    this.minHeight = 600;

    window.onmousemove = this.onWindowDrag;
  }

  onWindowPress(event: MouseEvent) {
    this.draggingWindow = true;
    this.px = event.clientX;
    this.py = event.clientY;
  }

  onWindowDrag(event: MouseEvent) {
    if (!this.draggingWindow) {
      return;
    }
    const offsetX = event.clientX - this.px;
    const offsetY = event.clientY - this.py;
    this.x += offsetX;
    this.y += offsetY;
    this.px = event.clientX;
    this.py = event.clientY;
  }

  topLeftResize(offsetX: number, offsetY: number) {
    this.x += offsetX;
    this.y += offsetY;
    this.width -= offsetX;
    this.height -= offsetY;
  }

  topRightResize(offsetX: number, offsetY: number) {
    this.y += offsetY;
    this.width += offsetX;
    this.height -= offsetY;
  }

  bottomLeftResize(offsetX: number, offsetY: number) {
    this.x += offsetX;
    this.width -= offsetX;
    this.height += offsetY;
  }

  bottomRightResize(offsetX: number, offsetY: number) {
    this.width += offsetX;
    this.height += offsetY;
  }

  onCornerClick(event: MouseEvent, resizer?: Function) {
    this.draggingCorner = true;
    this.px = event.clientX;
    this.py = event.clientY;
    this.resizer = resizer;
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('document:mousemove', ['$event'])
  onCornerMove(event: MouseEvent) {
    this.onWindowDrag(event);
    if (!this.draggingCorner) {
      return;
    } else {
      const offsetX = event.clientX - this.px;
      const offsetY = event.clientY - this.py;

      const lastX = this.x;
      const lastY = this.y;
      const pWidth = this.width;
      const pHeight = this.height;

      this.resizer(offsetX, offsetY);

      if (pWidth < 800 || pHeight < 600) {
        this.x = lastX;
        this.y = lastY;
        this.width = 800;
        this.height = 600;
      }
      this.px = event.clientX;
      this.py = event.clientY;
    }
  }

  onClose() {
    this.close.emit();
  }

  @HostListener('document:mouseup', ['$event'])
  onCornerRelease() {
    this.draggingWindow = false;
    this.draggingCorner = false;
  }
}
