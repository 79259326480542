import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FileManagerService } from '@app/file_manager/services/file_manager.service';
import { AuthService } from '@app/shared/services/auth.service';
import { NotificationsService } from 'angular2-notifications';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CompetenceService } from '@app/+competence-map/services/competence.service';
import { Router } from '@angular/router';
import { REDIRECT_TO_LOGIN } from '@app/auth/redirect-after-login.constants';
import { UserFile } from '@app/file_manager/models/user-file.model';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { delay, switchMap, tap } from 'rxjs/internal/operators';
import { FILE_MANAGER_LAYOUTS, SHARE_TYPE, VIEW_MODE_TYPE } from '@app/file_manager/file_manager.constants';
import { Role } from '@app/shared/models/user.model';
import { DCTreeItem } from '@app/+competence-map/models/competence-map.models';
import { ROLES } from '@app/shared/constants/roles.constants';
import { onlyActiveItems } from '@app/+competence-map/helpers/competence-map.helpers';
import { deepCopy } from '@app/shared/utils';
import { CMCatalogTypes } from '@app/+competence-map/constants/sections.constants';

@Component({
  selector: 'app-file-manager-light',
  templateUrl: './file-manager-light.component.html',
  styleUrls: ['./file-manager-light.component.scss'],
})
/**
 * @deprecated
 */
export class FileManagerLightComponent implements OnInit, OnDestroy {
  multipleSelection = false;
  layout: FILE_MANAGER_LAYOUTS = FILE_MANAGER_LAYOUTS.PROFILE;

  fileManagerLayouts = FILE_MANAGER_LAYOUTS;

  CURRENT_DIR: UserFile;
  SHARE_TYPE = SHARE_TYPE;
  VIEW: VIEW_MODE_TYPE = VIEW_MODE_TYPE.LIST;

  files: any = {};

  filters: any = {};

  ROLES: Role[] = ROLES;

  searchForm: FormGroup;
  searchFormShare: FormGroup;

  isSearch: boolean = false;
  isSearchShare: boolean = false;

  limit: number = 0;
  used: number = 0;
  selected: UserFile[] = [];
  buffer: UserFile[] = [];
  goods: DCTreeItem[];
  services: DCTreeItem[];

  @Output() onExportSelected = new EventEmitter<UserFile[]>();

  private destroyed$: Subject<boolean> = new Subject();
  private dbClick$: Subject<boolean> = new Subject();
  private onChangeSearch$: Subject<boolean> = new Subject();

  constructor(
    private fileManagerService: FileManagerService,
    private user: AuthService,
    private notify: NotificationsService,
    private fb: FormBuilder,
    private translate: TranslateService,
    private competenceService: CompetenceService,
    private router: Router
  ) {
    this.translate.setDefaultLang('ru');
    this.initSearch();
  }

  get authUser(): Observable<any> {
    return this.user.userStream;
  }

  ngOnInit(): void {
    if (this.user.user_id) {
      this.init();
    } else {
      this.router.navigate([REDIRECT_TO_LOGIN]);
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.dbClick$.complete();
    this.onChangeSearch$.complete();
    this.destroyed$.complete();
  }

  init(): void {
    this.files = {
      my: new UserFile({ id: 0, opened: true }),
      share: new UserFile({ id: -1, opened: true }, true),
    };
    this.CURRENT_DIR = this.getRootDir();
    forkJoin([
      this.fileManagerService.getFilesRoot(+this.user.user_id, this.getRootDir().id),
      this.fileManagerService.getFilesSharedRoot(+this.user.user_id, this.getRootSharedDir().id),
      this.fileManagerService.space(+this.user.user_id),
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (data: [UserFile[], UserFile[], any]) => {
          this.getRootDir().setChildren(data[0]);
          this.getRootSharedDir().setChildren(data[1]);
          this.limit = data[2].limit;
          this.used = data[2].used;
        },
        (err) => console.log(err)
      );

    forkJoin([
      this.competenceService.getTree(CMCatalogTypes.GOODS),
      this.competenceService.getTree(CMCatalogTypes.SERVICES),
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe((competencies: DCTreeItem[][]) => {
        this.goods = onlyActiveItems(deepCopy(competencies[0]));
        this.services = onlyActiveItems(deepCopy(competencies[1]));
      });
  }

  getFiles(CURRENT_DIR?: UserFile, callback?: Function): void {
    const _currentDir = CURRENT_DIR || this.CURRENT_DIR;
    (_currentDir.hasRoot()
      ? _currentDir.hasShare()
        ? this.fileManagerService.getFilesSharedRoot(+this.user.user_id, _currentDir.id)
        : this.fileManagerService.getFilesRoot(+this.user.user_id, _currentDir.id)
      : this.fileManagerService.getFilesDir(_currentDir.id, _currentDir.id, _currentDir.hasShare())
    )
      .pipe(
        tap((data: UserFile[]) => {
          _currentDir.setChildren(data.sort(this.fileManagerService.sort.bind(this.fileManagerService)));
          if (CURRENT_DIR) {
            // this.resetSelected();
            // console.log(this.selected);
          }
          if (callback) {
            callback.apply(this, [_currentDir]);
          }
        }),
        switchMap(() => this.fileManagerService.space(+this.user.user_id)),
        takeUntil(this.destroyed$)
      )
      .subscribe(
        (data: any) => {
          this.limit = data.limit;
          this.used = data.used;
        },
        (err) => console.log(err)
      );
  }

  onChangeSearch(data: string): void {
    this.onChangeSearch$.next(true);
    of(null)
      .pipe(delay(500), takeUntil(this.onChangeSearch$))
      .subscribe(() => {
        this.search();
      });
  }

  goBack(): void {
    if (!this.CURRENT_DIR.hasRoot()) {
      const file: UserFile = this.findFile(this.CURRENT_DIR.parent);
      if (file) {
        this.setCurrentDir(file);
      }
    }
  }

  getRootDir(): UserFile {
    return this.files.my;
  }

  getRootSharedDir(): UserFile {
    return this.files.share;
  }

  openDir($event: any) {
    if ($event.file.hasFile()) {
      this.fileManagerService.openFile($event.file);
    } else {
      if ($event.setCurrent) {
        this.setCurrentDir($event.file);
      } else {
        this.getFiles($event.file, (file: UserFile) => {
          file.opened = !file.opened;
        });
      }
    }
  }

  openRootDir(file: UserFile, setCurrent?: boolean) {
    setCurrent = setCurrent === true;
    if (setCurrent) {
      this.dbClick$.next(true);
      this.openDir({ file: file, setCurrent: setCurrent });
    } else {
      of(null)
        .pipe(delay(250), takeUntil(this.dbClick$))
        .subscribe(() => {
          this.openDir({ file: file, setCurrent: setCurrent });
        });
    }
  }

  searchFormSubmit() {
    this.search();

    this.isSearch = false;
    this.isSearchShare = false;
  }

  searchFormShareSubmit() {
    this.search();

    this.isSearch = false;
    this.isSearchShare = false;
  }

  search() {
    if (this.getSearchString().length) {
      this.fileManagerService
        .search(+this.user.user_id, this.getSearchString(), !this.hasSearchShare())
        .subscribe((data: UserFile[]) => {
          this.CURRENT_DIR = !this.hasSearchShare() ? this.getRootDir() : this.getRootSharedDir();
          this.CURRENT_DIR.children = data;
        });
    }
  }

  initSearch(): void {
    this.isSearch = false;
    this.isSearchShare = false;
    this.searchForm = this.fb.group({
      query: [''],
    });
    this.searchForm.valueChanges.subscribe((data) => {
      this.onChangeSearch(data);
    });
    this.searchFormShare = this.fb.group({
      query: [''],
    });
    this.searchFormShare.valueChanges.subscribe((data) => {
      this.onChangeSearch(data);
    });
  }

  resetSearch(init?: Function): void {
    this.isSearch = false;
    this.isSearchShare = false;
    this.searchForm.reset();
    this.searchFormShare.reset();
    if (init) {
      init.apply(this);
    }
  }

  setCurrentDir(file: UserFile) {
    file.opened = true;
    this.CURRENT_DIR = file;
    this.getFiles(file);
    this.openTree(file);
  }

  openHelper(): void {
    this.fileManagerService.openHelper();
  }

  private getSearchString(): string {
    if (this.isSearch) {
      return this.searchForm.controls.query.value || '';
    }
    if (this.isSearchShare) {
      return this.searchFormShare.controls.query.value || '';
    }
    return '';
  }

  hasSearchApply() {
    return !!this.searchForm.controls.query.value;
  }

  hasSearchShareApply() {
    return !!this.searchFormShare.controls.query.value;
  }

  private hasSearchShare(): boolean {
    return this.isSearchShare === true;
  }

  private findFile(id: number, files?: UserFile[]): UserFile {
    let res = null;
    if (id > 0) {
      files = files || (this.CURRENT_DIR.hasShare() ? this.getRootSharedDir().children : this.getRootDir().children);
      files.forEach((file: UserFile) => {
        if (file.id === id) {
          res = file;
        } else {
          if (file.children.length) {
            res = this.findFile(id, file.children);
          }
        }
      });
      return res;
    } else {
      return id === -1 ? this.getRootSharedDir() : this.getRootDir();
    }
  }

  private openTree(file: UserFile) {
    if (file.parent) {
      const parent_file: UserFile = this.findFile(file.parent);
      parent_file.opened = true;
      if (parent_file) {
        this.openTree(parent_file);
      }
    }
  }

  exportSelected(selected: UserFile[]) {
    this.onExportSelected.emit(selected);
  }

  selectFile(userFiles: UserFile[]) {
    this.onExportSelected.emit(userFiles);
  }
}
