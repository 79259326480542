<table class="dc-table table">
  <thead>
  <tr>
    <th>ИНН</th>
    <th>Имя</th>
    <th>Баланс</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let supplier of suppliers">
    <td>{{supplier.itn}}</td>
    <td>{{supplier.name}}</td>
    <td>{{supplier.balance}}</td>
  </tr>
  </tbody>
</table>
