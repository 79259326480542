<div class="file-manager-tree-file">
  <div
    class="file-manager-tree-file__title"
    (selectFile.single)="selectFile()"
    (selectFile.double)="openSelectFile()"
    appTestLocator="tree__file-{{ file?.id }}"
  >
    <app-svg-icon
      *ngIf="file?.fileExtType()"
      [width]="12"
      [name]="file?.fileExtType()?.icon"
      class="file-manager-tree-file__icon"
    ></app-svg-icon>
    <span class="file-manager-tree-file__title-text" [innerHTML]="file?.name | highlight: searchQuery"></span>
  </div>

  <div class="file-manager-tree-file__info">
    <app-svg-icon
      appTestLocator="tree__file-{{ file?.id }}_crown"
      *ngIf="isShowIcon && file?.in_shared_dir && file?.is_shared"
      (click)="showUserFile()"
      [width]="16"
      [height]="12.8"
      class="file-manager-tree-file__info-icon"
      name="crown"
    ></app-svg-icon>
    <app-svg-icon
      *ngIf="isShowIcon && (file?.inner_sharing || (file?.is_shared && !file?.in_shared_dir))"
      (click)="shareFile()"
      appTestLocator="tree__file-{{ file?.id }}_share"
      [width]="16"
      [height]="12.8"
      class="file-manager-tree-file__info-icon"
      [class.disabled]="file?.inner_sharing && !file?.is_shared"
      name="users"
    ></app-svg-icon>
  </div>
</div>
