import { AuthorFile } from './author-file.model';
import { DEFAULT_EXT, EXT, ExtInterface, SHARE_TYPE } from '../file_manager.constants';
import * as moment from 'moment';
import { RolesEnum } from '@app/shared/constants/roles.constants';
export interface UserFileZipInterface {
  created_at?: Date;
  requested?: boolean;
  url?: string;
}
export class UserFileZip {
  created_at: Date;
  requested: boolean;
  url: string;
  constructor(data: UserFileZipInterface) {
    this.created_at = data.created_at ? moment(data.created_at).toDate() : null;
    this.requested = data.requested === true;
    this.url = data.url || null;
  }
}

/**
 * @deprecated
 */
export class UserFile {
  id: number;
  name: string;
  url: string;
  is_file: boolean;
  share: boolean;
  is_shared: boolean = false;
  sharedUsers: UserFile[] = [];
  author: AuthorFile;
  created_at: string;
  link_user: AuthorFile;
  mimetype: string;
  opened = false;
  children: UserFile[] = [];
  parent = 0;
  showUserInfo: boolean = false;
  comment?: string;
  showUserInfoInArea: boolean = false;
  for_providers?: boolean;
  // поле используется только для фронте
  for_customers?: boolean;

  constructor(data: any, share?: boolean) {
    this.id = data.id !== undefined ? data.id : null;
    this.url = data.url || null;
    this.name = data.name || null;
    this.mimetype = data.mimetype || null;
    this.children = data.children || [];
    this.share = share === true;
    this.is_file = data.is_file || false;
    this.opened = data.opened || false;
    this.parent = data.parent !== undefined ? data.parent : 0;
    this.children = data.children || [];
    this.author = new AuthorFile(data.author || {});
    this.is_shared = data.is_shared === true;
    this.link_user = new AuthorFile(data.link_user || {});
    this.created_at = data.created_at || null;
  }

  hasShare(): boolean {
    return this.share === true;
  }

  hasTrade(): boolean {
    return this.id === -2;
  }

  hasShared(): boolean {
    return this.is_shared === true;
  }

  getSharedUsers(): UserFile[] {
    return this.sharedUsers;
  }

  hasRoot(): boolean {
    return !(this.id > 0);
  }

  hasFile(): boolean {
    return this.is_file;
  }

  setChildren(data: UserFile[]): void {
    this.children.forEach((file: UserFile) => {
      data.some((_file: UserFile) => {
        if (_file.id === file.id) {
          _file.opened = file.opened;
          _file.children = file.children;
          return true;
        }
        return false;
      });
    });
    this.children = data;
  }

  addChildren(data: UserFile, id?: number): void {
    id = id || null;
    if (id && this.children.filter((file: UserFile) => file.id === id).length) {
      this.children.forEach((file: UserFile, i: number) => {
        if (file.id === id) {
          data.opened = file.opened;
          data.children = file.children;
          Object.assign(file, data);
        }
      });
    } else {
      this.children.push(data);
    }
  }

  basename(): string {
    if (!this.hasFile()) {
      return this.name;
    }
    const name = this.name.split('.');
    if (name.length === 2 || name.length === 1) {
      return name[0];
    } else if (name.length > 2) {
      name.splice(name.length - 1, 1);
      return name.join('.');
    }
    return null;
  }

  ext(): string {
    if (!this.hasFile()) {
      return null;
    }
    const name = this.name.split('.');
    if (name.length === 2) {
      return name[1];
    } else if (name.length > 2) {
      return name[name.length - 1];
    } else {
      return name[0];
    }
  }

  extType(): ExtInterface {
    if (!this.hasFile()) {
      return DEFAULT_EXT;
    } else {
      const ext: ExtInterface[] = EXT.filter((data: ExtInterface) => data.ext.indexOf(this.ext()) > -1);
      return ext.length ? ext[0] : DEFAULT_EXT;
    }
  }
}

export interface ShareUsersRequestConfig {
  file_id: number;
  type: SHARE_TYPE;
  query?: string;
  user_role?: RolesEnum;
  page?: number;
  per_page?: number;
  competencies?: string;
}
