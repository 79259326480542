<div
  class="kt-f-users mb-2"
  (click)="select(item)"
  (dblclick)="userDbClick()"
  [ngClass]="{ active: selected?.id === item?.id, disabled: !isAccessChangeOptions }"
>
  <div>
    <div class="names">
      {{ item | initials }}
    </div>
    <div class="content">
      <div>{{ item?.typeLabel }}</div>
      <div>{{ item?.competenciesString || '-' }}</div>
      <div>{{ item?.company | companyName }}</div>
      <div *ngFor="let phone of item?.phones">{{ phone | textSeparator: '-':'3' }}</div>
    </div>
  </div>
</div>
