import { TreeComponent, TreeNode } from '@circlon/angular-tree-component';
import { DCTreeItem } from '../models/competence-map.models';
import { TreeSectionsAndFilters } from '../models/user-competency.model';
import { TradeCard } from '@app/+trades/models/trades.model';

export function doForAllSync<T extends { children?: T[] }>(
  nodes: T[],
  task: (node: T, nodes: T[], i: number, parent?: T) => void,
  parent?: T
): void {
  if (!nodes) {
    return;
  }
  if (!nodes.length) {
    return;
  }

  nodes.forEach((node: T, i) => {
    task(node, nodes, i, parent);
    doForAllSync(node.children, task, node);
  });
}

export function getFullPathOfNode(n: TreeNode): string {
  let node = Object.assign({}, n);
  let fullPath = '';
  while (node.parent) {
    fullPath = `${node.data.title}/${fullPath}`;
    node = node.parent;
  }
  return fullPath;
}

export function sortTreeObject(treeArray: DCTreeItem[]): DCTreeItem[] {
  if (treeArray.length === 1 && treeArray[0].children && treeArray[0].children.length) {
    treeArray[0].children = sortTreeObject(treeArray[0].children);
    return treeArray;
  }

  return treeArray.sort((a, b) => {
    if (b.children && b.children.length) {
      b.children = sortTreeObject(b.children);
    } else if (a.children && a.children.length) {
      a.children = sortTreeObject(a.children);
    }
    return a.title.localeCompare(b.title);
  });
}

export function onlyActiveItems(treeArray: DCTreeItem[]): DCTreeItem[] {
  return treeArray.filter((item) => {
    if (item.children && item.children.length) {
      item.children = onlyActiveItems(item.children);
    }
    return item.status === 'active';
  });
}

export function onlySelectedItems(treeArray: DCTreeItem[]): DCTreeItem[] {
  return treeArray.filter((item) => {
    if (item.children && item.children.length) {
      item.children = onlySelectedItems(item.children);
    }
    return item.selected;
  });
}

export function keepCaretState(storageKey: string, event, sectionId?: number) {
  const str = localStorage.getItem(storageKey);
  try {
    const caretsObject = JSON.parse(str) || {};
    if (sectionId) {
      caretsObject[sectionId] = caretsObject[sectionId] || {};
      caretsObject[sectionId].filters = caretsObject[sectionId].filters || {};
      caretsObject[sectionId].filters[event.node.data.id] = event.isExpanded;
    } else {
      caretsObject.sections = caretsObject.sections || {};
      caretsObject.sections[event.node.data.id] = event.isExpanded;
    }
    localStorage.setItem(storageKey, JSON.stringify(caretsObject));
  } catch (err) {
    console.log(err);
    return;
  }
}

const toggleItem = (storageKey: string, tree: TreeComponent, sectionId?: number) => {
  if (!tree.treeModel.getVisibleRoots().length) {
    return;
  }
  let carets: any = localStorage.getItem(storageKey);

  if (!carets) return;

  try {
    carets = JSON.parse(carets);
    if (sectionId) {
      carets = carets[sectionId] || {};
      carets = carets.filters || {};
    } else {
      carets = carets.sections || {};
    }

    tree.treeModel.doForAll((node) => {
      if (carets[node.data.id]) {
        node.expand();
      }
    });
  } catch (err) {
    console.log(err);
    return;
  }
};

export function toggleByLocalStorage(_storageKey: string, _tree: TreeComponent, _sectionId?: number): void {
  // setTimeout(toggleItem, 0, _storageKey, _tree, _sectionId);
  toggleItem(_storageKey, _tree, _sectionId);
}

export function filterSectionsBySelected(
  competencies: DCTreeItem[][],
  selected: TreeSectionsAndFilters[]
): DCTreeItem[][] {
  if (!selected || (selected && !Object.keys(selected).length)) {
    return [];
  }
  competencies.forEach((catalogType, catalogIndex) => {
    const catalogName = getCatalogName(catalogIndex);
    selected.forEach((item) => {
      if (item.catalog === catalogName) {
        doForAllSync(catalogType, (competency) => {
          if (item[competency.id] && item[competency.id].selected) {
            competency.selected = true;
          }
        });
      }
    });
  });

  return competencies.map((item) => onlySelectedItems(item));
}

export function getCatalogName(catalogIndex: number) {
  return catalogIndex === 0 ? 'goods' : 'services';
}

export function formatSectionsString(competencies: DCTreeItem[][], separator = ', ', postFix = '.'): string {
  let string = '';

  competencies.forEach((competence) => {
    doForAllSync(competence, (item) => (string += `${item.title}${separator}`));
  });

  const splitedString = string.split(separator);
  splitedString.splice(-1, 1);

  string = splitedString.join(separator);

  return string + postFix;
}

export function formatSectionsStringByOneLevel(competencies: any[], separator = ', ', postFix = '.'): string {
  let string = '';

  if (!Array.isArray(competencies)) {
    return string;
  }

  if (!competencies.length) {
    return string;
  }

  for (let i = 0; i < competencies.length; i++) {
    const competence = competencies[i];
    const previousCompetence = competencies[i - 1];

    string += `${getHeading(previousCompetence, competence)}${competence.title}${separator}`;
  }

  const splitedString = string.split(separator);
  splitedString.splice(-1, 1);

  string = splitedString.join(separator);

  function getHeading(prev, current) {
    if (!prev) {
      return format(current.catalog);
    }

    if (prev.catalog !== current.catalog) {
      return format(current.catalog);
    }

    return '';
  }

  function format(catalog: string) {
    return `${catalog === 'goods' ? 'Товары' : 'Услуги'}${separator}`;
  }

  return string + postFix;
}

export function getAllSectionIds(sections) {
  const ids = [];

  doForAllSync(sections, (item: any) => {
    ids.push(item.id);
  });

  return ids;
}

export function ungroupFilters(filters) {
  const result = [];

  filters.forEach((category) => {
    Object.keys(category).forEach((key) => {
      category[key].forEach((filter) => result.push(filter));
    });
  });

  return result;
}

export function formatFilterString(filters) {
  let string = '';

  function stringByType(item, separator = ' ') {
    if (item.type === 'bool') {
      return !!item.value ? item.title + separator : `${item.title} - нет${separator}`;
    }

    if (item.type === 'range') {
      return `${item.title} от ${item.less} до ${item.greater} ${item.unit ? item.unit.short_title : ''}${separator}`;
    }

    return item.title + separator;
  }

  doForAllSync(filters, (item) => {
    if (item.children.length) {
      string += `${stringByType(item, ', ')}`;
    } else {
      string += `${stringByType(item, '; ')}`;
    }
  });

  return string;
}

export function generateFilterString(competencies, card: TradeCard, filters) {
  const selected = card.providers_filter ? card.providers_filter.data : {};

  const ungroupedFilters = ungroupFilters(filters);

  competencies.forEach((catalog, catalogIndex) => {
    const catalogName = getCatalogName(catalogIndex);
    doForAllSync(catalog, (item: any) => {
      item.filtersString = '';
      if (
        selected[catalogName][item.id] &&
        selected[catalogName][item.id].filters &&
        selected[catalogName][item.id].filters.length
      ) {
        selected[catalogName][item.id].filters.forEach((selectedFilter) => {
          doForAllSync(ungroupedFilters, (filter) => {
            if (selectedFilter.filter_id === filter.id) {
              if (filter.type === 'bool') {
                filter.value = selectedFilter.value;
              }

              if (filter.type === 'range') {
                filter.less = selectedFilter.less;
                filter.greater = selectedFilter.greater;
              }
            }
          });
        });

        const sectionFilters = ungroupedFilters.filter((filter) => filter.section_id === item.id);
        item.filtersString = formatFilterString(sectionFilters);
      }
    });
  });
}
