import { Component, Input } from '@angular/core';
import { ROLES, RolesEnum } from '@app/shared/constants/roles.constants';

@Component({
  selector: 'app-chat-contact',
  templateUrl: './chat-contact.component.html',
  styleUrls: ['./chat-contact.component.scss'],
})
export class ChatContactComponent {
  @Input() contact;

  roles = ROLES;
  rolesEnum = RolesEnum;

  constructor() {}
}
