import {Component, Input} from '@angular/core';
import {NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-preview-content-modal',
  templateUrl: './preview-content-modal.component.html',
  styleUrls: ['./preview-content-modal.component.scss']
})
export class PreviewContentModalComponent {
  @Input() link: string;
  @Input() title?: string;
  @Input() fileName?: string;

  public isLoaded: boolean;

  constructor(private activeModal: NgbActiveModal) {}

  public close(): void {
    this.activeModal.close();
  }
}
