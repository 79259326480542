import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-livesearch-select',
  templateUrl: './livesearch-select.component.html',
  styleUrls: ['./livesearch-select.component.scss'],
})
export class LivesearchSelectComponent implements OnInit {
  @Input() value = '';
  @Input() list: string[];
  @Input() placeholder = '';
  @Input() isNumber: boolean;

  @Output() searchEvent = new EventEmitter<string>();
  @Output() selectEvent = new EventEmitter<string>();

  isListOpen = false;
  timeout = null;
  searchChanges = false;

  constructor() {}

  ngOnInit() {}

  showSuggestions() {
    this.openList();

    this.emitSearch();
  }

  emitSearch() {
    this.searchChanges = true;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.searchEvent.emit(this.value);
    }, 400);
  }

  selectSuggestion(item: any) {
    this.value = item;
    this.selectEvent.emit(this.value);
    this.closeList();
  }

  openList() {
    this.isListOpen = true;
  }

  closeList() {
    this.isListOpen = false;
  }

  handleFocus(event) {
    event.stopPropagation();
    if (!this.value || (this.value && !this.searchChanges)) {
      return;
    }

    this.openList();
  }
}
