<div class="dc-modal-x-button dc-fa-button" (click)="close()">
  <app-svg-icon [height]="20" [width]="20" name="close"></app-svg-icon>
</div>
<div class="modal-window-content">

  <div *ngIf="title" class="modal-header">
    <div class="modal-title text-center">
      {{title}}
    </div>
  </div>

  <div class="modal-body">
    <pdf-viewer [src]="link"
                [render-text]="true"
                [original-size]="false"
                [stick-to-page]="true"
                [show-all]="true"
                [autoresize]="true"
                style="display: block;max-height: 650px;overflow: auto;"
    ></pdf-viewer>
  </div>
</div>
