import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FlaskQueryFilter } from '@app/shared/models/filters.model';
import { Project } from '@app/+competence-map/models/projects.models';
import { CMActions, CMProjectStatuses } from '@app/+competence-map/constants/projects.constants';
import { CompetenceService } from '@app/+competence-map/services/competence.service';
import { AuthService } from '@app/shared/services/auth.service';
import { RolesEnum } from '@app/shared/constants/roles.constants';
import { DestroyService } from '@app/services/destroy.service';
import { takeUntil } from 'rxjs/operators';
import { CMCatalogTypes } from '@app/+competence-map/constants/sections.constants';

@Component({
  selector: 'app-logs-label',
  templateUrl: './logs-label.component.html',
  styleUrls: ['./logs-label.component.scss'],
  providers: [DestroyService],
})
export class LogsLabelComponent implements OnInit {
  @Input() projectTypes: CMActions[];

  @Input() catalog: CMCatalogTypes;

  @Output() clickEvent = new EventEmitter();

  projects: Project[] = [];
  isLoading: boolean;

  filter: FlaskQueryFilter[] = [];

  get isSuperuser(): boolean {
    return this.authService.user_type === RolesEnum.SUPERUSER;
  }

  constructor(
    private competenceService: CompetenceService,
    private authService: AuthService,
    private destroy$: DestroyService
  ) {
    this.competenceService.projectLogsLength$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.loadProjects(this.filter);
    });
  }

  ngOnInit(): void {
    const filter = [
      {
        name: 'action',
        op: 'in_',
        val: this.projectTypes,
      },
      {
        name: 'status',
        op: 'eq',
        val: CMProjectStatuses.AGREED,
      },
    ];

    this.filter = filter;

    this.loadProjects(this.filter);
  }

  loadProjects(filter: FlaskQueryFilter[] = []) {
    this.isLoading = true;

    this.competenceService
      .getProjects(filter)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (value) => {
          this.projects = value.filter((item) => {
            return !item.logs.length;
          });
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  handleClick() {
    this.clickEvent.emit();
  }
}
