import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { MAX_WEIGTH_VALUE, MIN_WEIGTH_VALUE, VALID_WEIGHT_VALUE } from '../constants/lots.constants';
import { Lot } from '../models/lots.model';

export class LotsHelper {
  private static weightValueItem(itemsCount: number): number {
    return VALID_WEIGHT_VALUE / itemsCount;
  }

  static getAutoWeightForItem(itemsLength: number): number {
    return this.weightValueItem(itemsLength);
  }

  // Вес первого лота считается по формуле:
  // (10 – (Округл. до 2 знака после запятой (10/«количество лотов в КТ»))*(«количество лотов в КТ»-1))
  static getAutoWeightForFirstItem(itemsLength: number): number {
    return VALID_WEIGHT_VALUE - +this.weightValueItem(itemsLength).toFixed(2) * (itemsLength - 1);
  }

  static getGeneralWeight(items: any[] = []): number {
    let sum = 0;
    items.forEach((item) => {
      sum += (item.value ? +item.value.weight : 0) || +item.weight || 0;
    });

    return sum;
  }

  static fillExtraToServer(lots: Lot[]): Lot[] {
    return lots.map((lot, i) => {
      if (!lot.extra.id) {
        delete lot.extra.id;
        delete lot.id;
      }

      if (lot.extra.id) {
        lot.id = lot.extra.id;
      }

      lot.title = lot.extra.title;
      lot.weight = lot.extra.weight;
      lot.number = i + 1;
      lot.nomenclatures = [];

      lot.extra.nomenclatures.forEach((nom, nIndex) => {
        lot.nomenclatures.push({
          weight: nom.weight,
          title: nom.title,
          unit_id: nom.unit_id,
          criteria: [],
        });

        if (!nom.id) {
          delete nom.id;
          delete lot.nomenclatures[nIndex].id;
        }

        if (nom.id) {
          lot.nomenclatures[nIndex].id = nom.id;
        }

        lot.extra.criteria.forEach((criteria, cIndex) => {
          lot.nomenclatures[nIndex].criteria.push({
            title: criteria.title,
            unit_id: nom.criteria[cIndex].unit_id,
            type: criteria.type,
            min: nom.criteria[cIndex].min,
            max: nom.criteria[cIndex].max,
            weight: nom.criteria[cIndex].weight,
            comment: nom.criteria[cIndex].comment,
            best_direction: nom.criteria[cIndex].best_direction,
            sort: cIndex,
          });

          const criteriaExtra = lot.extra.nomenclatures[nIndex].criteria[cIndex];
          if (!criteriaExtra.id) {
            delete criteriaExtra.id;
            delete lot.nomenclatures[nIndex].criteria[cIndex].id;
          }

          if (criteriaExtra.id) {
            lot.nomenclatures[nIndex].criteria[cIndex].id = criteriaExtra.id;
          }

          if (criteria.id) {
            delete criteria.id;
          }
        });
      });

      return lot;
    });
  }

  static fillServerToLots(lots: Lot[]): Lot[] {
    return lots.map((lot) => {
      lot.extra.id = lot.id;
      lot.extra.title = lot.title;
      lot.extra.weight = lot.weight;
      lot.extra.number = lot.number;

      lot.extra.nomenclatures = [];

      lot.nomenclatures.forEach((nom, nIndex) => {
        lot.extra.nomenclatures.push({
          criteria: [],
          weight: nom.weight,
          unit_id: nom.unit_id,
          title: nom.title,
          id: nom.id,
        });

        lot.extra.criteria = [];

        nom.criteria.sort((a, b) => a.sort - b.sort);

        nom.criteria.forEach((criteria, cIndex) => {
          lot.extra.criteria.push({
            title: criteria.title,
            type: criteria.type,
            best_direction: criteria.best_direction,
          });

          lot.extra.nomenclatures[nIndex].criteria.push({
            weight: criteria.weight,
            best_direction: criteria.best_direction,
            unit_id: criteria.unit_id,
            id: lot.nomenclatures[nIndex].criteria[cIndex].id,
            comment: criteria.comment,
            min: criteria.min,
            max: criteria.max,
          });
        });
      });

      return lot;
    });
  }

  static fillIdToLots(lots: Lot[]) {
    lots.forEach((lot) => {
      lot.extra.id = lot.id;

      lot.nomenclatures.forEach((nom, nIndex) => {
        lot.extra.nomenclatures[nIndex].id = nom.id;

        nom.criteria.sort((a, b) => a.sort - b.sort);
        nom.criteria.forEach((criteria, cIndex) => {
          lot.extra.nomenclatures[nIndex].criteria[cIndex].id = criteria.id;
        });
      });

      return lot;
    });
  }

  static getNomenclatureDataPaste(data: string, maxLength: number | null = null): string[] {
    return data.split(/\n/).map((val) => {
      if (maxLength && val.length > maxLength) {
        return val.slice(0, maxLength);
      }

      return val;
    });
  }

  static getNomenclatures(formGroup: FormGroup) {
    return (formGroup.get('nomenclatures') as FormArray).controls as FormGroup[];
  }

  static getLotCriteria(formGroup: FormGroup) {
    return (formGroup.get('criteria') as FormArray).controls as FormGroup[];
  }

  static getFormArrayControls<T extends AbstractControl>(formGroup: FormGroup, controlName: string) {
    if (!formGroup) {
      return [];
    }

    return (formGroup.get(controlName) as FormArray).controls as T[];
  }

  static isWeightValueValid(data: string) {
    return !isNaN(Number(data)) && +data <= MAX_WEIGTH_VALUE && +data > MIN_WEIGTH_VALUE;
  }
}
