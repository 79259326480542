import { NgModule } from '@angular/core';
import { SearchBarComponent } from './search-bar.component';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

@NgModule({
  imports: [FontAwesomeModule],
  declarations: [SearchBarComponent],
  exports: [SearchBarComponent],
})
export class SearchBarModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faSearch);
  }
}
