<div
  class="clock-widget"
  [style.background]="background"
  [class.collapsed]="menuCollapsed"
  (click)="showTimezoneModal()"
>
  <div *ngIf="!menuCollapsed">
    <div class="clock-mark"></div>
    <div class="clock-mark"></div>
    <div class="clock-mark" [ngClass]="{ 'is-hide': true }"></div>
    <div class="clock-mark"></div>
    <div class="clock-mark"></div>
    <div class="clock-mark" [ngClass]="{ 'is-hide': true }"></div>
    <div class="clock-mark"></div>
    <div class="clock-mark"></div>
    <div class="clock-mark" [ngClass]="{ 'is-hide': true }"></div>
    <div class="clock-mark"></div>
    <div class="clock-mark"></div>
    <div class="clock-mark" [ngClass]="{ 'is-hide': true }"></div>

    <div *ngIf="isDigitVisible" class="digit-container">
      <div class="clock-digit" style="top: 2%; left: 71%"></div>
      <div class="clock-digit" style="top: 18%; left: 87%"></div>
      <div class="clock-digit" style="top: 42%; left: 100%">III</div>
      <div class="clock-digit" style="top: 63%; left: 87%"></div>
      <div class="clock-digit" style="top: 79%; left: 71%"></div>
      <div class="clock-digit" style="top: 100%; left: 41%">VI</div>
      <div class="clock-digit" style="top: 79%; left: 25%"></div>
      <div class="clock-digit" style="top: 63%; left: 8%"></div>
      <div class="clock-digit" style="top: 42%; left: -8%">IX</div>
      <div class="clock-digit" style="top: 18%; left: 6%"></div>
      <div class="clock-digit" style="top: 2%; left: 23%"></div>
      <div class="clock-digit" style="top: -10%; left: 40%">XII</div>
    </div>

    <div class="hours-container" [ngStyle]="{ transform: hoursHandAngle }">
      <div class="hours" [ngStyle]="{ background: clockHandColor }"></div>
    </div>
    <div class="minutes-container" [ngStyle]="{ transform: minutesHandAngle }">
      <div class="minutes" [ngStyle]="{ background: clockHandColor }"></div>
    </div>
    <div class="seconds-container" [ngStyle]="{ transform: secondsHandAngle }">
      <div class="seconds" [ngStyle]="{ background: secondsHandColor }"></div>
    </div>
  </div>

  <div *ngIf="menuCollapsed" class="small-clock">{{ currentTime }}</div>
</div>

<app-modal-window [isShowed]="modalIsOpen" backdrop="static" (closeEvent)="closeModal()">
  <div class="modal-header center">
    <div class="modal-title">Настроить часовой пояс</div>
  </div>
  <div class="modal-body">
    <label> Выберите часовой пояс: </label>
    <div class="form-group">
      <div class="custom-form-input">
        <ng-select
          class="bootstrap-select dc-select"
          [items]="timezones"
          bindValue="value"
          [clearable]="false"
          bindLabel="name"
          dropdownPosition="bottom"
          [(ngModel)]="timeZoneOffset"
          (change)="showShiftedDate()"
        >
          <ng-template ng-option-tmp let-item="item">
            <div class="dc-select-item">{{ item.name }}</div>
          </ng-template>
        </ng-select>
      </div>
    </div>
    <div>
      <p><span class="clock-modal-label">Текущие дата и время:</span> {{ timezoneModalDateStr }}</p>
      <p *ngIf="isShiftedDateShown">
        <span class="clock-modal-label">Новые дата и время:</span> {{ timezoneModalNewDateStr }}
      </p>
    </div>
  </div>
  <div class="modal-footer">
    <div class="dc-footer-buttons">
      <button type="button" class="btn btn btn-dc-outline" (click)="closeModal()">Отменить</button>
      <button type="button" class="btn btn btn-dc-primary" (click)="timeZoneSelect()">Сохранить</button>
    </div>
  </div>
</app-modal-window>
