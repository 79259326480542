<div class="info-day" #infoDayMain>
  <div class="text-center info-day-header">
    <span class="info-day-header-text">{{ title }}</span>
    <div class="info-day-header-button">
      <app-svg-icon [height]="12" [width]="16" name="check" class="green day-ok"
                    *ngIf="currentStateDay == stateDay.FINISH">
      </app-svg-icon>
      <span>{{ eventList.length }}</span>
      <app-svg-icon [height]="16" [width]="16" name="plus" (click)="addEvent()"></app-svg-icon>
    </div>
  </div>
  <div class="info-day-events" [style.max-height.px]="maxHeightEvents">
    <div class="row info-day-event" *ngFor="let event of eventList">
      <div
        [ngbTooltip]="findStateHelper(event.status)"
        class="col-4 text-center info-day-event-time"
        [style.background-color]="setColor(event.status)"
        [style.color]="state.UNDER_CONTROL == event.status ? '#fff' : ''"
        (click)="showEvent(event.id)"
        *ngIf="!event.end_date; else periodEventDate"
      >
        <b>{{ event.start_time.slice(0, -3) }}</b>
      </div>
      <ng-template #periodEventDate>
        <div
          [ngbTooltip]="findStateHelper(event.status)"
          class="col-4 text-center info-day-event-date_time"
          [ngClass]="state.UNDER_CONTROL == event.status ? 'color_after_white' : ''"
          [style.background-color]="setColor(event.status)"
          [style.color]="state.UNDER_CONTROL == event.status ? '#fff' : ''"
          (click)="showEvent(event.id)"
        >
          <div>
            <span class="period_event-time">{{ event.start_time.slice(0, -3) }}</span>
            <span class="period_event-date">{{ getDateFrontFormat(event.extra.start_date_first_event || event.start_date) }}</span>
          </div>
          <div>
            <span class="period_event-time">{{ event.end_time?.slice(0, -3) || '&nbsp;' }}</span>
            <span class="period_event-date">{{ getDateFrontFormat(event.end_date) }}</span>
          </div>
        </div>
      </ng-template>
      <div class="col-6 info-day-event-title" (click)="showEvent(event.id)">
        {{ event.title }}
      </div>
      <div class="col-2 text-right info-day-event-button">
        <app-svg-icon [height]="16" [width]="18" name="edit_list" (click)="editEvent(event)"></app-svg-icon>
        <app-svg-icon [height]="16" [width]="14" name="copy" (click)="editEvent(event, true)"></app-svg-icon>
        <app-svg-icon [height]="14" [width]="14" name="close_wide" (click)="deleteEvent(event)"></app-svg-icon>
      </div>
    </div>
  </div>
</div>
