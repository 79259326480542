import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NotificationsService } from 'angular2-notifications';

import { AuthService } from '@app/shared/services/auth.service';

import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

@Component({
  templateUrl: './confirm-change-email.component.html',
})
export class ConfirmChangeEmailComponent implements OnDestroy {
  public errors: Record<string, string> | null = null;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private notify: NotificationsService
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      const key = params['key'];
      if (key)
        this.authService
          .confirmChangeEmail(key)
          .pipe(
            takeUntil(this.ngUnsubscribe),
            switchMap(() => {
              return this.authService.updateUser();
            })
          )
          .subscribe(
            () => this.notify.success('Успешно!', 'Email изменен'),
            (error) => {
              this.errors = error;
              if (this.errors && this.errors['message'] === 'Key does not exist') {
                this.notify.error('Ошибка!', 'Ссылка некорректна или устарела');
              } else if (this.errors && this.errors['message']) {
                this.notify.error('Ошибка!', this.errors['message']);
              }
            }
          );
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.ngUnsubscribe = null;
  }
}
