import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

import { ROLES } from '@app/shared/constants/roles.constants';
import { AuthService } from '@app/shared/services/auth.service';
import { ChatService } from '@app/chat/services/chat.service';
import { SocketDataService } from '@app/services/socket-data.service';
import { ChatRoom, ChatSection } from '@app/chat/models/chat.model';
import { ChatSectionsEnum } from '@app/chat/constants/chat-sections.constants';
import { User } from '@app/shared/models/user.model';

@Component({
  selector: 'app-chat-group-or-theme-edit',
  templateUrl: './chat-group-or-theme-edit.component.html',
  styleUrls: ['./chat-group-or-theme-edit.component.scss'],
})
export class ChatGroupOrThemeEditComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  groupEditForm: FormGroup;
  contacts: User[] = [];
  groupContacts = [];
  roles = ROLES;
  editingObject: ChatRoom;
  chatSectionSelected: ChatSection = {} as ChatSection;
  contactsOnlyFilter;
  filterString: string = '';
  chatSectionsEnum = ChatSectionsEnum;
  userId: number;

  constructor(
    private authService: AuthService,
    private chatService: ChatService,
    private chatDataService: SocketDataService
  ) {}

  ngOnInit() {
    this.chatSectionSelected = this.chatService.getChatSectionSelected();
    this.editingObject = this.chatService.getEditingGroupOrThemeObject();
    this.editingObject.userItems = this.editingObject.userItems || [];
    this.groupContacts = this.editingObject.userItems || [];
    this.contactsOnlyFilter = this.chatService.getContactsOnlyFilter();

    this.userId = +this.authService.user_id;

    this.fillUserLists(this.chatService.getContacts());

    if (!this.groupContacts.length) {
      this.authService.userStream.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
        this.groupContacts = [user];
      });
    } else {
      this.groupContacts.forEach((contact) => (contact.selected = false));
    }

    this.chatService.contactsChanged
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((contacts) => this.fillUserLists(contacts));

    this.initForm();
    this.chatService.contactsOnlyFilterChanged
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((contactsOnlyFilter) => (this.contactsOnlyFilter = contactsOnlyFilter));
  }

  fillUserLists(contacts: User[]) {
    this.contacts = [];
    this.groupContacts = [];

    Object.keys(contacts).forEach((room_id) => {
      contacts[room_id].selected = false;

      if (!this.editingObject.group) {
        this.contacts.push(contacts[room_id]);
        return;
      }
    });

    if (this.editingObject.group) {
      this.contacts = this.removeSelf(this.editingObject.group.userItems);

      if (this.editingObject.users) {
        this.contacts = this.contacts.filter((contact) => !this.editingObject.users.includes(contact.id));
      }
    }

    this.groupContacts.push(...this.editingObject.userItems);
  }

  removeSelf(contacts) {
    return contacts.filter((contact) => contact.id !== this.userId);
  }

  initForm() {
    for (const user of this.editingObject.userItems) {
      this.contacts = this.contacts.filter((contact) => contact.id !== user.id);
    }
    this.groupEditForm = new FormGroup({
      title: new FormControl(this.editingObject.title || null, Validators.required),
      filter: new FormControl(''),
    });
  }

  onSubmit() {
    const data: any = {
      title: this.groupEditForm.value.title,
      users: this.groupContacts.map((contact) => contact.id),
      section: this.chatService.getChatSectionSelected().name,
    };
    if (this.editingObject.id) {
      data.id = this.editingObject.id;
      if (this.editingObject.group_id) {
        data.group_id = this.editingObject.group_id;
        if (this.chatSectionSelected.name === ChatSectionsEnum.TECH) {
          this.chatDataService.updateTechTopic(data);
        } else if (this.chatSectionSelected.name === ChatSectionsEnum.TRADE) {
          this.chatDataService.updateTradeTopic({
            id: data.id,
            title: data.title,
            users: data.users,
          });
        } else {
          this.chatDataService.updateTopic(data);
        }
      } else {
        if (this.chatSectionSelected.name !== ChatSectionsEnum.ADMIN) {
          data.owner_id = this.editingObject.owner_id;
          this.chatDataService.updateGroup(data);
        } else {
          this.chatDataService.changeAdminGroupOwner({
            ...data,
            owner_id: this.editingObject.owner_id,
          });
        }
      }
    } else {
      if (this.editingObject.group_id) {
        data.group_id = this.editingObject.group_id;
        if (this.chatSectionSelected.name === ChatSectionsEnum.TECH) {
          this.chatDataService.addTechTopic(data.title);
        } else if (this.chatSectionSelected.name === ChatSectionsEnum.TRADE) {
          this.chatDataService.addTradeTopic({
            title: data.title,
            users: data.users,
            trade_id: this.editingObject.trade_id,
          });
        } else {
          this.chatDataService.addTopic(data);
        }
      } else {
        data.owner_id = this.editingObject.owner_id;
        this.chatDataService.addGroup(data);
      }
    }
    this.chatService.resetEditingGroupOrThemeObject();
    this.chatService.isEditingGroupOrThemeChanged.next(false);
  }

  onCancelGroupEdit() {
    this.chatService.resetEditingGroupOrThemeObject();
    this.chatService.isEditingGroupOrThemeChanged.next(false);
    this.chatService.toggleContactsOnlyFilter(false);
  }

  moveToGroup() {
    this.groupContacts.push(...this.contacts);
    this.contacts = [];
  }

  moveFromGroup() {
    this.contacts.push(...this.groupContacts.filter((contact) => +contact.id !== +this.userId));
    this.groupContacts = this.groupContacts.filter((contact) => +contact.id === +this.userId);
  }

  onContactClick(contact) {
    if (
      (this.editingObject.id && this.editingObject.owner_id !== this.userId) ||
      contact.id === this.userId ||
      (this.chatSectionSelected.name === this.chatSectionsEnum.TRADE && !this.editingObject.group)
    ) {
      return;
    }

    this.groupContacts.push(...this.contacts.filter((item) => contact.id === item.id));
    this.contacts = this.contacts.filter((item) => contact.id !== item.id);
  }

  onGroupContactClick(contact) {
    if (
      (this.editingObject.id && this.editingObject.owner_id !== this.userId) ||
      contact.id === this.userId ||
      (this.chatSectionSelected.name === this.chatSectionsEnum.TRADE && !this.editingObject.group)
    ) {
      return;
    }

    this.contacts.push(...this.groupContacts.filter((item) => contact.id === item.id));
    this.groupContacts = this.groupContacts.filter((item) => contact.id !== item.id);
  }

  onContactCrownClick(contact) {
    if (this.chatSectionSelected.name === this.chatSectionsEnum.TRADE) {
      return;
    }
    this.editingObject.owner_id = contact.id;
  }

  onFilterToggle() {
    this.chatService.toggleContactsOnlyFilter(true);
  }

  onFilterEdit($event: Event) {
    $event.stopPropagation();
    this.filterString = this.groupEditForm.value.filter;
  }

  onClearFilter() {
    this.chatService.toggleContactsOnlyFilter(false);
    this.chatService.setContactsOnlyFilter(false);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
