import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

import { AuthService } from '@app/shared/services/auth.service';
import { FormHelper } from '@app/shared/helpers/form.helper';
import { PersonalDataService } from '@app/shared/services/personal-data.service';
import { User } from '@app/shared/models/user.model';

import { ChangeEmailModalComponent } from '../shared/components/change-email-modal/change-email-modal.component';
import { ChangePasswordModalComponent } from '../shared/components/change-password-modal/change-password-modal.component';
import { ProfileModalBaseOptions } from '../constants/profile.modal.constants';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { SetPasswordDialogComponent } from '@app/profile/shared/components/set-password-dialog/set-password-dialog.component';
import { RolesEnum } from '@app/shared/constants/roles.constants';
import { PreviewContentModalComponent } from '@app/profile/shared/components/preview-content-modal/preview-content-modal.component';
import { FilesService } from '@app/shared/services/files.service';
import { TextUpperCasePipe } from '@app/shared/pipes/text-upper-case.pipe';
import { FORM_VALIDATOR_MESSAGES } from '@app/shared/constants/form.constants';

@Component({
  selector: 'app-profile-contact',
  templateUrl: './profile-contact.component.html',
  styleUrls: ['./profile-contact.component.scss'],
})
export class ProfileContactComponent implements OnInit, OnDestroy {
  form: FormGroup;
  phones: FormArray;
  user: User = {} as User;
  roles = RolesEnum;

  messages = FORM_VALIDATOR_MESSAGES;

  readonly phoneDynamicMask =
    '000-000-00||000-000-000||00-0000-0000||0-000-000-0000||00-000-000-0000||00-000-0000-0000';
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private readonly LOREM_PASSWORD = '123456789012';

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private authService: AuthService,
    private personalDataService: PersonalDataService,
    private notify: NotificationsService,
    private filesService: FilesService,
    private router: Router
  ) {
    this.initializeForm();
  }

  get phoneMessage(): string {
    switch (this.phones.length) {
      case 1: {
        return 'Вы можете добавить ещё 2 номера';
      }
      case 2: {
        return 'Вы можете добавить ещё 1 номер';
      }
      default: {
        return '';
      }
    }
  }

  get hasNewPassword(): boolean {
    return this.user.flags?.password_auto;
  }

  get isCanBePayer(): boolean {
    if (this.user && this.user.type === this.roles.ADMIN_OF_DIRECTION) {
      return this.user?.flags.is_can_be_payer;
    }
  }

  get isAdminOfDirection(): boolean {
    return this.user && this.user.type === this.roles.ADMIN_OF_DIRECTION;
  }

  get isOperator(): boolean {
    return this.user && this.user.type === this.roles.OPERATOR;
  }

  get isValidForm(): boolean {
    const invalidFields = FormHelper.findInvalidControlsRecursive(this.form);
    return this.isInvalidForm(['first_name', 'second_name', 'phones'], invalidFields);
  }

  get isValidCheck(): boolean {
    const invalidFields = FormHelper.findInvalidControlsRecursive(this.form);
    if (this.isOperator) {
      return this.isInvalidForm(['checkRules'], invalidFields);
    } else if (this.isAdminOfDirection && !this.isCanBePayer) {
      return this.isInvalidForm(['checkRules', 'checkContract'], invalidFields);
    } else {
      return true;
    }
  }

  ngOnInit(): void {
    this.authService.userStream.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
      const { first_name, second_name, patronymic, email, phones } = user;

      if (phones && phones.length) {
        phones.forEach((phone: string) => {
          const phoneIdx = this.phones.controls.findIndex((formPhone: AbstractControl) => formPhone.value === phone);
          if (phoneIdx === -1) {
            this.phones.push(this.fb.control(phone, Validators.required));
          }
        });
      } else {
        this.phones.push(this.fb.control(null, Validators.required));
      }

      FormHelper.updateForm(this.form, { first_name, second_name, patronymic, email });
      this.form.get('password').setValue(user?.flags?.password_auto ? null : this.LOREM_PASSWORD);
      this.user = { ...user };

      if (this.isOperator) {
        this.form.addControl('checkRules', this.fb.control(false, Validators.requiredTrue));
      } else if (this.isAdminOfDirection && !this.isCanBePayer) {
        this.form.addControl('checkRules', this.fb.control(false, Validators.requiredTrue));
        this.form.addControl('checkContract', this.fb.control(false, Validators.requiredTrue));
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.ngUnsubscribe = null;
  }

  public addPhone(): void {
    this.phones.push(this.fb.control(null));
  }

  public removePhone(index: number): void {
    this.phones.removeAt(index);
  }

  public updateUser(): void {
    const textUpperCase = new TextUpperCasePipe();

    if (this.isValidForm && !this.isValidCheck) {
      this.notify.error('Внимание!', 'Данные не могут быть сохранены пока вы не согласитесь с Правилами');
      return;
    } else if (!this.isValidForm && (this.isValidCheck || !this.isValidCheck)) {
      this.notify.error('Внимание!', 'Пожалуйста, проверьте правильность заполнения полей');
      return;
    } else if (this.hasNewPassword) {
      this.notify.error('Внимание!', 'Вы должны задать пароль!');
      return;
    }

    const { email, phones } = this.form.value;
    const first_name = this.form.value['first_name'] ? textUpperCase.transform(this.form.value['first_name']) : null;
    const second_name = this.form.value['second_name'] ? textUpperCase.transform(this.form.value['second_name']) : null;
    const patronymic = this.form.value['patronymic'] ? textUpperCase.transform(this.form.value['patronymic']) : '';

    this.personalDataService
      .setPersonalData({ first_name, second_name, patronymic, email, phones })
      .pipe(
        takeUntil(this.ngUnsubscribe),
        switchMap(() => this.authService.updateUser())
      )
      .subscribe(
        (user: User) => {
          this.authService.nextUserData(user);
          this.notify.success('Успешно!', 'Данные успешно сохранены');
          this.router.navigate(['profile']);
        },
        (error) => {
          this.notify.error('Внимание', error.error.message);
        }
      );
  }

  public cancel(): void {
    this.router.navigate(['profile']);
  }

  public changePasswordDialog(): void {
    const modal = this.modalService.open(ChangePasswordModalComponent, {
      ...ProfileModalBaseOptions,
      windowClass: 'dc-modal profile-modal modal-window',
    });
    (<ChangePasswordModalComponent>modal.componentInstance).currentEmail = this.user.email;
  }

  public changeEmailDialog(): void {
    const modal = this.modalService.open(ChangeEmailModalComponent, {
      ...ProfileModalBaseOptions,
      windowClass: 'dc-modal profile-modal modal-window',
    });
    (<ChangeEmailModalComponent>modal.componentInstance).currentEmail = this.user.email;
  }

  public setPasswordDialog(): void {
    const modal = this.modalService.open(SetPasswordDialogComponent, {
      ...ProfileModalBaseOptions,
      windowClass: 'dc-modal profile-modal modal-window',
    });

    modal.result.then((value) => {
      this.user.flags.password_auto = !value?.success;
      this.form.get('password').setValue(this.LOREM_PASSWORD);
    });
  }

  public getUserRules(): void {
    this.filesService
      .getUserRules()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map((fileBlob: Blob) => {
          return URL.createObjectURL(new Blob([fileBlob], { type: 'application/pdf' }));
        })
      )
      .subscribe((link) => {
        const modal = this.modalService.open(PreviewContentModalComponent, {
          ...ProfileModalBaseOptions,
          windowClass: 'dc-modal profile-modal modal-window',
          size: 'xl',
        });

        (<PreviewContentModalComponent>modal.componentInstance).link = link;
      });
  }

  public getContractRules(): void {
    this.filesService
      .getAgreement()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map((fileBlob: Blob) => {
          return URL.createObjectURL(new Blob([fileBlob], { type: 'application/pdf' }));
        })
      )
      .subscribe((link) => {
        const modal = this.modalService.open(PreviewContentModalComponent, {
          ...ProfileModalBaseOptions,
          windowClass: 'dc-modal profile-modal modal-window',
          size: 'xl',
        });

        (<PreviewContentModalComponent>modal.componentInstance).link = link;
      });
  }

  private initializeForm(): void {
    this.phones = this.fb.array([]);

    this.form = this.fb.group({
      first_name: [null, Validators.required],
      second_name: [null, Validators.required],
      patronymic: [null],
      email: [{ value: null, disabled: true }],
      password: [{ value: null, disabled: true }],
      phones: this.phones,
    });
  }

  private isInvalidForm(fields: string[], invalidFields: string[]): boolean {
    let result = true;
    fields.forEach((value) => {
      if (invalidFields.includes(value)) {
        result = false;
      }
    });
    return result;
  }
}
