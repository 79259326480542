import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MultiSelectListItem } from '../../models/multi-select-list-item';

@Component({
  selector: 'app-flag-filter',
  templateUrl: './flag-filter.component.html',
  styleUrls: ['./flag-filter.component.sass'],
})
export class FlagFilterComponent implements OnInit, OnChanges {
  @Input() selected: any[] = [];
  @Input() list: MultiSelectListItem[];

  @Output() submitEvent = new EventEmitter<{ [key: string]: any }>();

  constructor() {}

  ngOnInit() {}

  ngOnChanges() {
    if (this.selected) {
      this.list.forEach((item) => {
        item.checked = !!this.selected[item.id];
      });
    }
  }

  apply() {
    const result = {};
    this.list.forEach((status) => (result[status.id] = !!status.checked || undefined));
    this.submitEvent.emit({ value: result });
  }
}
