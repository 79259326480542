import { Pipe, PipeTransform } from '@angular/core';
import { Employee } from '@app/shared/models/employee.model';

@Pipe({
  name: 'shortName',
})
export class ShortNamePipe implements PipeTransform {
  transform(value: Employee): string {
    if (!value.second_name) return 'unknown';
    return `${value.second_name} ${value.first_name ? value.first_name[0] : ''}.${
      value.patronymic ? value.patronymic[0] + '.' : ''
    }`;
  }
}
