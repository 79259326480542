import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[selectFile.single],[selectFile.double]',
})
export class SelectFileDirective implements OnInit, OnDestroy {
  @Input() debounce = 300;
  @Output('selectFile.double') doubleClick = new EventEmitter();
  @Output('selectFile.single') singleClick = new EventEmitter();

  private clicksSubject = new Subject<MouseEvent>();
  private subscription: Subscription;

  @HostListener('click', ['$event'])
  private clickEvent(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.clicksSubject.next(event);
  }
  @HostListener('dblclick', ['$event'])
  private doubleClickEvent(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.clicksSubject.next(event);
  }

  ngOnInit() {
    this.subscription = this.clicksSubject.pipe(debounceTime(this.debounce)).subscribe((event) => {
      if (event.type === 'click') {
        this.singleClick.emit(event);
      } else {
        this.doubleClick.emit(event);
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
