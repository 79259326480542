import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserFile } from '../../../models/user-file.model';

@Component({
  template: '',
})
export abstract class FileManagerTreeItemComponent {
  @Input() file: UserFile;
  @Input() isSearchQuery: boolean = false;
  @Input() searchQuery: string = '';

  /**
   * Одинарный клик на папку/файл, ни чего фукнционального не несет
   */
  @Output() readonly onSelectFile = new EventEmitter<unknown>();
  /**
   * Двой клик на папку/файл, выделение и открытие папки/файла
   */
  @Output() readonly onOpenSelectFile = new EventEmitter<unknown>();
  /**
   * Клик по каретке
   */
  @Output() readonly onExpandToggleFile = new EventEmitter<unknown>();
  /**
   * Справка для рутовых папок
   */
  @Output() readonly onHelpFile = new EventEmitter<unknown>();
  /**
   * Информация о пользователе
   */
  @Output() readonly onUserInfoFile = new EventEmitter<unknown>();
  /**
   * Расшаривание файло/папки
   */
  @Output() readonly onShareFile = new EventEmitter<unknown>();

  toggleOpenFile(): void {
    this.onExpandToggleFile.emit();
  }

  selectFile(): void {
    this.onSelectFile.emit();
  }

  openSelectFile(): void {
    this.onOpenSelectFile.emit();
  }

  showHelpFile(): void {
    this.onHelpFile.emit();
  }

  showUserFile(): void {
    this.onUserInfoFile.emit();
  }

  shareFile(): void {
    this.onShareFile.emit();
  }
}
