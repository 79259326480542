import { FlaskQueryFilter } from '@app/shared/models/filters.model';
import { Column } from '@app/shared/models/table';
import { RolesEnum } from '@app/shared/constants/roles.constants';

export enum FilterTemplatesKeys {
  ID = 'id',
  VALUE = 'value',
  STATUS = 'status',
  CREATED_AT = 'created_at',
  AUTHOR_ID = 'author_id',
  UNITS = 'units_of_measure_id',
  TITLE = 'title',
  TYPE = 'type',
  SETTINGS = 'settings',
}

export const FILTER_TEMPLATES_COLUMNS: Column<FlaskQueryFilter[]>[] = [
  {
    id: FilterTemplatesKeys.ID,
    name: 'ID',
    isEnabledSearch: true,
    searchPlaceholder: 'Поиск по id',
    data: {
      isSticky: true,
      indent: 0,
    },
    width: 75,
    filterConfig: [
      {
        name: FilterTemplatesKeys.ID,
        op: 'in_',
        val: '',
      },
    ],
  },
  {
    id: FilterTemplatesKeys.TITLE,
    width: 300,
    name: 'Название фильтра',
    isEnabledSearch: true,
    data: {
      isSticky: true,
      indent: 95,
    },
    searchPlaceholder: 'Поиск по названию',
    filterConfig: [
      {
        name: FilterTemplatesKeys.TITLE,
        op: 'in_',
        val: '',
      },
    ],
  },
  {
    id: FilterTemplatesKeys.TYPE,
    width: 120,
    name: 'Тип',
    isEnabledFilter: true,
    searchPlaceholder: 'Фильтр по типу',
    filterConfig: [
      {
        name: FilterTemplatesKeys.TYPE,
        op: 'in_',
        val: '',
      },
    ],
  },
  {
    id: FilterTemplatesKeys.UNITS,
    width: 125,
    name: 'Единицы',
    isEnabledSearch: true,
    searchPlaceholder: 'Поиск по единице',
    filterConfig: [
      {
        name: FilterTemplatesKeys.UNITS,
        op: 'in_',
        val: '',
      },
    ],
  },
  {
    id: FilterTemplatesKeys.AUTHOR_ID,
    width: 130,
    name: 'ID автора',
    searchPlaceholder: 'Поиск по id автора',
    isEnabledSearch: true,
    filterConfig: [
      {
        name: FilterTemplatesKeys.AUTHOR_ID,
        op: 'in_',
        val: '',
      },
    ],
  },
  {
    id: FilterTemplatesKeys.CREATED_AT,
    width: 165,
    name: 'Дата создания',
    searchPlaceholder: 'Показать',
    isEnabledSearch: true,
    filterConfig: [
      {
        name: FilterTemplatesKeys.CREATED_AT,
        op: 'between',
        val: null,
      },
    ],
  },
  {
    id: FilterTemplatesKeys.STATUS,
    width: 100,
    name: 'Статус',
    isEnabledFilter: true,
    searchPlaceholder: 'Фильтр по статусу',
    filterConfig: [
      {
        name: FilterTemplatesKeys.STATUS,
        op: 'in_',
        val: '',
      },
    ],
  },
  {
    id: FilterTemplatesKeys.SETTINGS,
    width: 50,
    name: '',
  },
].map((column) => {
  return {
    ...column,
    defaultFilterConfig: column.filterConfig,
  };
});

export enum FilterTemplateTypes {
  CHOICE = 'choice',
  RANGE = 'range',
  BOOL = 'bool',
  SIMPLE = 'simple',
}

export enum FilterTemplateStatuses {
  ACTIVE = 'active',
  IN_ARCHIVE = 'in_archive',
  ARCHIVE = 'archive',
  DRAFT = 'draft',
  DELETED = 'deleted',
}

export const FILTER_TEMPLATE_TYPES_LABELS = {
  [FilterTemplateTypes.CHOICE]: 'множество',
  [FilterTemplateTypes.RANGE]: 'диапазон',
  [FilterTemplateTypes.BOOL]: 'да/нет',
};

export const FILTER_TEMPLATES_STATUSES = [
  {
    label: 'Актив',
    value: FilterTemplateStatuses.ACTIVE,
    class: 'status-green',
  },
  {
    label: 'Просмотр',
    value: FilterTemplateStatuses.DRAFT,
    class: 'status-yellow',
  },
  {
    label: 'Архив',
    value: FilterTemplateStatuses.ARCHIVE,
    class: 'status-red',
  },
];

export const FILTER_TEMPLATES_TYPES = [
  {
    label: FILTER_TEMPLATE_TYPES_LABELS[FilterTemplateTypes.BOOL],
    value: FilterTemplateTypes.BOOL,
  },
  {
    label: FILTER_TEMPLATE_TYPES_LABELS[FilterTemplateTypes.RANGE],
    value: FilterTemplateTypes.RANGE,
  },
  {
    label: FILTER_TEMPLATE_TYPES_LABELS[FilterTemplateTypes.CHOICE],
    value: FilterTemplateTypes.CHOICE,
  },
];
