<div class="section-header">
  <div>
    <div class="section-header-row" appTestLocator="add-section__project">
      <div class="section-header-label">Проект ID:</div>
      <div class="section-header-value">{{ projectId }}</div>
    </div>
    <div class="section-header-row" appTestLocator="add-section__status">
      <div class="section-header-label">Статус:</div>
      <div class="section-header-value d-flex align-items-center">
        {{ projectStatus | cmProjectStatus }}&nbsp;&nbsp;
        <app-svg-icon name="change" width="16" class="color-brand-light"></app-svg-icon>
      </div>
    </div>
    <div class="section-header-row" appTestLocator="add-section__author">
      <div class="section-header-label">Автор:</div>
      <div class="section-header-value">{{ userAuthor | roleName }}</div>
    </div>
    <div class="section-header-row" appTestLocator="add-section__date">
      <div class="section-header-label">Дата:</div>
      <div class="section-header-value">{{ projectDate | dateFormat }}</div>
    </div>
  </div>
  <div>
    <div class="section-header-text" appTestLocator="add-section__title">
      Создать новый раздел<br />
      {{ level }} уровня
    </div>
  </div>
  <div>
    <div
      class="section-header-archive"
      (click)="archive()"
      [class.disabled]="isArchiveDisabled()"
      appTestLocator="add-section__archive"
    >
      <app-svg-icon name="trash" width="16"></app-svg-icon>
      <span>Перенести в архив</span>
    </div>
  </div>
</div>

<div class="modal-body" [class.disabled]="isDataDisabled()">
  <form [formGroup]="form" class="section-form">
    <div class="section-header-row section-path" appTestLocator="add-section__path">
      <div class="section-header-label">Путь:</div>
      <div class="section-header-value">{{ path }}</div>
    </div>

    <div class="section-form-fields">
      <div class="array-sections" formArrayName="sections" *ngIf="sections">
        <div *ngFor="let section of sections.controls; let i = index" class="d-flex align-items-center mb-4">
          <dc-form-field-wrapper [style]="'material'" [vertically]="true" [required]="true" class="mr-1">
            <dc-form-input [maxLength]="30" [isShowLength]="true">
              <input
                matInput
                formInput
                [formControlName]="i"
                placeholder="Введите название нового раздела"
                [matAutocomplete]="words"
                appTestLocator="add-section__input-{{ i }}"
                #ref
                (ngModelChange)="search($event, ref)"
              />
            </dc-form-input>

            <mat-autocomplete autoActiveFirstOption #words="matAutocomplete">
              <mat-option *ngFor="let option of words$ | async" [value]="option">
                {{ option }}
              </mat-option>
            </mat-autocomplete>
          </dc-form-field-wrapper>
          <div
            (click)="removeSection(i)"
            *ngIf="i > 0"
            class="color-brand-light cursor-pointer"
            appTestLocator="add-section__input-{{ i }}_remove"
          >
            <app-svg-icon name="close" width="20" height="20"></app-svg-icon>
          </div>
        </div>
      </div>
      <div class="section-add-new" (click)="addName()" appTestLocator="add-section__add-new">
        <app-svg-icon name="plus" width="14"></app-svg-icon>
        <span>Добавить еще раздел</span>
      </div>
    </div>
  </form>
</div>

<app-operation-modal-footer
  (onCancel)="cancel()"
  (onSave)="save($event)"
  (onAgree)="agree($event)"
  [project]="project"
  [form]="form"
>
</app-operation-modal-footer>
