import { UnitsMapResolver } from './shared/resolvers/units.resolver';
import { Routes } from '@angular/router';
// Other routes
import { authRoutes } from './auth/auth.routes';
import { profileRoutes } from '@app/profile/profile.routers';
import { calendarRoutes } from '@app/calendar/calendar.routes';
// Layout components - компоненты обертки (компоненты, в которы встраиваются текущие компоненты)
import { DashboardLayoutComponent } from '@app/shared/layout_components/dashboard_layout/dashboard_layout.component';
import { AuthLayoutComponent } from '@app/shared/layout_components/auth-layout/auth-layout.component';
// Other components
// Guards
import { IsLoggedIn, IsNotBlocked } from '@app/shared/services/guard.service';
import { ChatComponent } from '@app/chat/chat.component';
import { ChatMessagesComponent } from './chat/chat-messages/chat-messages.component';
import { NotificationComponent } from '@app/notification/notification.component';
import { NotFoundComponent } from '@app/shared/components/not-found/not-found.comnponent';
import { GoodsResolver } from '@app/shared/resolvers/goods.resolver';
import { ServicesResolver } from '@app/shared/resolvers/services.resolver';

export const appRoutes: Routes = [
  {
    path: 'null',
    canActivate: [IsLoggedIn],
    component: DashboardLayoutComponent,
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: authRoutes,
  },
  {
    path: '',
    canActivate: [IsLoggedIn],
    component: DashboardLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: '/profile',
        pathMatch: 'full',
      },
      {
        path: 'profile',
        children: profileRoutes,
      },
      {
        path: 'employees',
        canActivate: [IsNotBlocked],
        loadChildren: () => import('app/+employees/employees.module').then((m) => m.EmployeesModule),
        resolve: {
          goods: GoodsResolver,
          services: ServicesResolver,
        },
      },
      {
        path: 'map',
        // canActivate: [HasAccess],
        loadChildren: () => import('app/+competence-map/competence-map.module').then((m) => m.CompetenceMapModule),
      },
      {
        path: 'tariffs-payments',
        loadChildren: () => import('app/+tariffs/tariffs.module').then((m) => m.TariffsModule),
      },
      {
        path: 'tso',
        loadChildren: () => import('app/+tso/tso.module').then((m) => m.TsoModule),
      },
      {
        path: 'trades',
        loadChildren: () => import('app/+trades/trades.module').then((m) => m.TradesModule),
        resolve: {
          goods: GoodsResolver,
          services: ServicesResolver,
          unitsMap: UnitsMapResolver,
        },
      },
      {
        path: 'file-manager',
        loadChildren: () => import('app/file-manager/file-manager.module').then((m) => m.FileManagerModule),
      },
      // TODO: старый ФО только для тестирования
      {
        path: 'files',
        loadChildren: () => import('app/file_manager/file_manager.module').then((m) => m.FileManagerModule),
      },
      {
        path: 'FAQ',
        loadChildren: () => import('app/+faq/faq.module').then((m) => m.FaqModule),
      },
      {
        path: 'calendar',
        canActivate: [
          /* TODO:isAdmin*/
        ],
        children: calendarRoutes,
      },
      //  any routes with DashboardLayoutComponent
    ],
  },
  {
    path: 'chat',
    canActivate: [
      /* TODO:isAdmin*/
    ],
    outlet: 'popup',
    component: ChatComponent,
    children: [
      {
        path: ':roomId',
        component: ChatMessagesComponent,
      },
    ],
  },
  {
    path: 'notification',
    canActivate: [
      /* TODO:isAdmin*/
    ],
    outlet: 'popup',
    component: NotificationComponent,
  },
  { path: '**', component: NotFoundComponent },
];
