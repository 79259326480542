<div class="d-flex align-items-center">
  <div class="mr-2">
    <input
      class="cbx-input"
      [id]="item.id"
      type="checkbox"
      (change)="onCheckboxChange($event.target.checked)"
      [checked]="item.selected"
      [disabled]="readonly"
    />
    <label class="cbx-label" [for]="item.id" [class.cbx-label-interminate]="hasSelectedChildren"></label>
  </div>

  <div
    class="dc-competence-tree-item dc-competence-filter-tree-item"
    [class.selected]="selected"
    (click)="onSelectClicked()"
  >
    <div class="dc-competence-tree-item-inner">
      <div class="dc-competence-tree-item-heading">
        <app-svg-icon
          [name]="!node.isExpanded ? 'caret_down' : 'caret_up'"
          [class.disabled]="
            node.level === 3 ||
            template_filter.type === filterTemplateTypes.BOOL ||
            template_filter.type === filterTemplateTypes.RANGE
          "
          (click)="toggleNodeClicked()"
          width="10"
        ></app-svg-icon>

        <div class="dc-competence-tree-item-title">{{ template_filter.title }}</div>
      </div>

      <div class="dc-competence-tree-item-buttons">
        <div class="dc-competence-tree-item-status"></div>
      </div>
    </div>

    <div [ngSwitch]="template_filter.type">
      <div *ngSwitchCase="filterTemplateTypes.RANGE" class="filter-specific">
        <app-user-filters-cell-range
          [readonly]="readonly"
          [node]="item.instance_filter"
          [competencyData]="item.providerCompetency"
          (changeEvent)="saveRange($event)"
        ></app-user-filters-cell-range>
      </div>

      <div *ngSwitchCase="filterTemplateTypes.BOOL" class="filter-specific">
        <app-form-checkbox-switch
          [readonly]="readonly"
          [checked]="item.providerCompetency?.value_bool"
          (change)="changeSwitch($event)"
        ></app-form-checkbox-switch>
      </div>
    </div>
  </div>
</div>
