import {
  OnInit,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { ModalInjectorService } from '@app/modal-injector/services/modal-injector.service';
import { CalendarDataService } from '@app/shared/services/calendar/calendar-data.service';

@Component({
  selector: 'app-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss'],
})
export class TimepickerComponent implements OnInit {
  @Input() id;
  @Input() dpID;
  @Input() controlNameTime;
  @Input() startValue;

  minute = 0;
  hour = 8;

  constructor(
    private cdRef: ChangeDetectorRef,
    private modalInjectorService: ModalInjectorService,
    private calendarDataService: CalendarDataService
  ) {}

  padStartString(value) {
    return value.toString().padStart(2, '0');
  }

  changeValue(value, diff) {
    if (value == 'hour') {
      this.hour = (this.hour + diff) % 24
      if (this.hour < 0) {
        this.hour += 24;
      }
    } else if (value == 'minute') {
      this.minute = (this.minute + diff) % 60
      if (this.minute < 0) {
        this.minute += 60;
      }
    }
  }

  selectTime() {
    this.calendarDataService.updateDatepicker({id: this.dpID, value: `${this.padStartString(this.hour)}:${this.padStartString(this.minute)}`});
    this.modalInjectorService.closeModal(this.id)
  }

  ngOnInit() {
    this.startValue = this.startValue.substr(0, 5)
    if (this.startValue && !~this.startValue.indexOf('_')) {
      const parseStartTime = this.startValue.split(':');
      this.hour = Number(parseStartTime[0]) % 24;
      this.minute = Number(parseStartTime[1]) % 60;
    }
  }
}
