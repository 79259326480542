import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { CreateOrChangeFilterTemplateParams, FilterTemplate } from '@app/+competence-map/models/filter-templates.model';
import { FlaskQueryFilter } from '@app/shared/models/filters.model';

import { CompetenceService } from '@app/+competence-map/services/competence.service';
import { DestroyService } from '@app/services/destroy.service';

import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FilterTemplatesHelper } from '@app/+competence-map/helpers/filter-templates.helper';
import { FilterTemplateTypes } from '@app/+competence-map/constants/filter-templates.constants';

@Component({
  selector: 'app-filter-template-tree-selected',
  templateUrl: './filter-template-tree-selected.component.html',
  styleUrls: ['./filter-template-tree-selected.component.scss'],
})
export class FilterTemplateTreeSelectedComponent implements OnInit {
  @Input() searchString: string = '';
  @Input() isDisabled = false;
  @Output() selectTemplateFilter = new EventEmitter<FilterTemplate>();

  filterTemplates: FilterTemplate[] = [];
  filterConfig: FlaskQueryFilter[] = [
    { name: 'level', val: '1', op: 'eq' },
    { name: 'status', op: 'notin_', val: ['archive'] },
  ];
  selectedFilterTemplate: FormControl = new FormControl(null);

  get filterTemplateTypes(): typeof FilterTemplateTypes {
    return FilterTemplateTypes;
  }

  @Input() set selectedFilter(value: FilterTemplate) {
    if (!value) {
      this.selectedFilterTemplate.setValue(null);
    }
    this.selectedFilterTemplate.setValue(value);
  }

  constructor(private competenceService: CompetenceService, private destroy$: DestroyService) {}

  ngOnInit(): void {
    this.initData();
  }

  initData() {
    this.competenceService
      .getFilterTemplates(this.filterConfig)
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.filterTemplates = value;
      });
  }

  getChildrenStructureFilter(nodeId: string): Observable<CreateOrChangeFilterTemplateParams[]> {
    return this.competenceService.getTemplateStructureFilter({ node_id: nodeId });
  }

  toggleTreeSelectedFilter(filter: FilterTemplate, isExpandAll = false, event: Event): void {
    const findSelectedFilter = this.filterTemplates.find((value) => value.id === filter.id) || filter;

    if (isExpandAll) {
      findSelectedFilter.isExpandedAll = !findSelectedFilter.isExpandedAll;
      findSelectedFilter.isExpanded = findSelectedFilter.isExpandedAll;
    } else {
      findSelectedFilter.isExpanded = !findSelectedFilter.isExpanded;
    }
    event.preventDefault();

    if (findSelectedFilter.isExpanded && findSelectedFilter.id && typeof findSelectedFilter.id === 'number') {
      this.getChildrenStructureFilter(findSelectedFilter.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe((structureFilters) => {
          findSelectedFilter.children = structureFilters[0].childes.map((childrenStructureFilterList) =>
            FilterTemplatesHelper.transformToFilterTemplate(childrenStructureFilterList, isExpandAll)
          );
        });
    }
  }

  getNestedTitleClassName(treeItem: Partial<FilterTemplate>): string {
    const size = treeItem.virtualLevel ? treeItem.virtualLevel : treeItem.level;
    if (size === 2) {
      return `padding-l-54`;
    }
    return `padding-l-${size * 10}`;
  }

  selectFilter(templateFilter: FilterTemplate): void {
    this.selectedFilterTemplate.setValue(templateFilter);
    this.selectTemplateFilter.emit(templateFilter);
  }
}
