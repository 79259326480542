import { Component, OnDestroy, OnInit, SecurityContext } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '@app/shared/services/auth.service';
import { PersonalDataService } from '@app/shared/services/personal-data.service';
import { FilesService } from '@app/shared/services/files.service';

import { User } from '@app/shared/models/user.model';
import { CompanyCreate } from '@app/shared/models/company.model';
import { RolesEnum } from '@app/shared/constants/roles.constants';
import { Router } from '@angular/router';
import { ProfileModalBaseOptions } from '@app/profile/constants/profile.modal.constants';
import { WelcomeModalComponent } from '@app/profile/shared/components/welcome-modal/welcome-modal.component';
import { WelcomeContractModalComponent } from '@app/profile/shared/components/welcome-contract-modal/welcome-contract-modal.component';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { FILE_MANAGER_LAYOUTS } from '@app/file_manager/file_manager.constants';
import { ProfileDocumentsDialogComponent } from '../shared/components/profile-documents-dialog/profile-documents-dialog.component';
import { SetPasswordDialogComponent } from '@app/profile/shared/components/set-password-dialog/set-password-dialog.component';
import { UserTypes } from '@app/shared/types/user.types';
import { DocumentArchive } from '@app/shared/models/document-archive.interface';

import { LegalStatusesEnum } from '@app/shared/types/legal-statuses.types';
import { ResidencesEnum } from '@app/shared/types/residences.types';
import { DomSanitizer } from '@angular/platform-browser';
import {
  ExportFileManagerType,
  FileManagerExportComponent,
} from '@app/file-manager/file-manager-export/file-manager-export.component';

type states = 'closed' | 'opened' | 'saved' | 'saved_animate';

interface FileDialogTypeLabel {
  type: string;
  title: string;
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  tradesLayouts = FILE_MANAGER_LAYOUTS;

  legalStatusesEnum = LegalStatusesEnum;
  residencesEnum = ResidencesEnum;

  company_data_status: states = 'closed';
  user: User;
  errors: Object = {};
  cardUrl = '';
  tmp;
  isShowEmailPass = false;
  isShowNewPass = false;
  isShowSetPass = false;
  adminOfDirectionHasCompany = true;
  savedUser: any = {} as any;
  isEditContract = false;

  public rolesEnum = RolesEnum;
  public userType: UserTypes;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private notify: NotificationsService,
    private personalDataService: PersonalDataService,
    private translateService: TranslateService,
    private authService: AuthService,
    private filesService: FilesService,
    private modalService: NgbModal,
    private router: Router,
    private domSanitizer: DomSanitizer
  ) {}

  get isCanBePayer(): boolean {
    if (this.user && this.user.type === this.rolesEnum.ADMIN_OF_DIRECTION) {
      return this.user?.flags.is_can_be_payer;
    }
    return false;
  }

  get hasNewPassword(): boolean {
    return this.user.flags?.password_auto;
  }

  get isShowContractLink(): boolean {
    return (
      this.user &&
      (this.user.type === this.rolesEnum.PARTNER ||
        this.user.type === this.rolesEnum.EXPERT ||
        this.user.type === this.rolesEnum.ADMIN_OF_USER ||
        this.user.type === this.rolesEnum.ADMIN_OF_DIRECTION)
    );
  }

  get parentUser(): User {
    return this.user.parent;
  }

  get parentUserName(): String {
    if (!this.parentUser.second_name) return '';
    return `${this.parentUser.second_name} ${this.parentUser.first_name} ${
      this.parentUser.patronymic ? this.parentUser.patronymic : ''
    }`;
  }

  get postalAddress() {
    if (this.user?.company?.postal_address) {
      const { country, province, locality, address } = this.user?.company?.postal_address;
      return `${country || ''} ${province || ''} ${locality || ''} ${address || ''}`;
    } else {
      return '';
    }
  }

  get registrationAddress() {
    if (this.user?.company?.registration_address) {
      const { country, province, locality, address } = this.user?.company?.registration_address;
      return `${country || ''} ${province || ''} ${locality || ''} ${address || ''}`;
    } else {
      return '';
    }
  }

  get postalParentAddress() {
    if (this.parentUser?.company?.postal_address) {
      const { country, province, locality, address } = this.parentUser?.company?.postal_address;
      return `${country || ''} ${province || ''} ${locality || ''} ${address || ''}`;
    } else {
      return '';
    }
  }

  get registrationParentAddress() {
    if (this.parentUser?.company?.registration_address) {
      const { country, province, locality, address } = this.parentUser?.company?.registration_address;
      return `${country || ''} ${province || ''} ${locality || ''} ${address || ''}`;
    } else {
      return '';
    }
  }

  ngOnInit() {
    // this.authService.updateUser().subscribe();
    this.authService.userStream.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
      this.userType = user.type;
      if (
        user.type !== RolesEnum.OPERATOR &&
        user.type !== RolesEnum.ADMIN_OF_DIRECTION &&
        user.company &&
        user.company.owner &&
        user.company.owner.id !== user.id
      ) {
        user.company = new CompanyCreate();
      }

      if (user.type === RolesEnum.ADMIN_OF_DIRECTION || user.type === RolesEnum.OPERATOR) {
        if (user.company.owner.id !== user.id) {
          this.adminOfDirectionHasCompany = false;
          this.company_data_status = 'saved';
        } else {
          this.adminOfDirectionHasCompany = true;
        }
      }

      this.user = user;

      this.savedUser = JSON.parse(JSON.stringify(user));
      if (!this.user.phones.length) {
        this.user.phones = [];
        this.isEditContract = false;
        this.showWelcomeModal();
      } else {
        this.isEditContract = true;
      }

      if ((!this.user.company || !this.user.company.id) && this.user.phones.length) {
        this.showWelcomeContractModal();
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.ngUnsubscribe = null;
  }

  public editUserContact(): void {
    this.router.navigate(['profile', 'contact']);
  }

  public editUserContract(): void {
    this.router.navigate(['profile', 'contract']);
  }

  public showSetPasswordModal(): void {
    this.modalService.open(SetPasswordDialogComponent, {
      ...ProfileModalBaseOptions,
      windowClass: 'dc-modal faq-modal modal-window',
    });
  }

  public showArchiveRules(): void {
    this.filesService
      .getUserRulesArchive()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((documents) => {
        this.openArchiveDocumentsModal('Архив Правил', documents);
      });
  }

  public showArchiveAgreements(userType: UserTypes): void {
    const { type, title } = this.getFileDialogLabel(userType);
    this.filesService
      .getAgreementsArchive(type)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((documents) => {
        this.openArchiveDocumentsModal(`Архив Договоров для ${title}`, documents);
      });
  }

  public selectProfileDocument(userType?: UserTypes): void {
    const { type, title } = this.getFileDialogLabel(userType);

    const modal = this.modalService.open(FileManagerExportComponent, {
      centered: true,
      animation: true,
      windowClass: 'dc-modal export-file-manager-modal modal-window',
      size: 'xl',
    });
    (<FileManagerExportComponent>modal.componentInstance).title = type
      ? `Выбор Договора для ${title}`
      : `Выбор  Правил`;

    (<FileManagerExportComponent>modal.componentInstance).exportType = ExportFileManagerType.PROFILE;
    (<FileManagerExportComponent>modal.componentInstance).selectFolders = false;

    modal.result.then((result) => {
      if (result && result.file && result.file.id) {
        let setFileObservable$ = null;
        if (type) {
          setFileObservable$ = this.filesService.setAgreementsFile(result.file.id, type);
        } else {
          setFileObservable$ = this.filesService.setUserRules(result.file.id);
        }

        setFileObservable$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
          () => {
            this.notify.success('Успешно', `Вы добавили новый файл ${type ? 'Договора для ' + title : 'Правил'}`);
          },
          (error) => {
            if (error?.error?.message) {
              this.notify.error('Внимание', error?.error?.message);
            }
          }
        );
      }
    });
  }

  public getRulesFile(): void {
    this.filesService
      .getUserRules()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map((fileBlob: Blob) => {
          return new Blob([fileBlob], { type: 'application/pdf' });
        })
      )
      .subscribe(
        (fileBlob) => {
          window.open(this.getResourceUrl(fileBlob));
        },
        () => {
          this.notify.warn('Внимание', 'Папка пуста!');
        }
      );
  }

  public getAgreementsFile(userType?: UserTypes): void {
    const { type } = this.getFileDialogLabel(userType);
    this.filesService
      .getAgreement(type)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map((fileBlob: Blob) => {
          return new Blob([fileBlob], { type: 'application/pdf' });
        })
      )
      .subscribe(
        (fileBlob) => {
          window.open(this.getResourceUrl(fileBlob));
        },
        () => {
          this.notify.warn('Внимание', 'Папка пуста!');
        }
      );
  }

  private getResourceUrl(file: Blob): string {
    return this.domSanitizer.sanitize(
      SecurityContext.RESOURCE_URL,
      this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file))
    );
  }

  private showWelcomeModal(): void {
    this.modalService.open(WelcomeModalComponent, {
      ...ProfileModalBaseOptions,
      windowClass: 'dc-modal profile-modal modal-window',
      size: 'lg',
    });
  }

  private showWelcomeContractModal(): void {
    this.modalService.open(WelcomeContractModalComponent, {
      ...ProfileModalBaseOptions,
      windowClass: 'dc-modal profile-modal modal-window',
    });
  }

  private openArchiveDocumentsModal(title: string, data: DocumentArchive[]): void {
    const modal = this.modalService.open(ProfileDocumentsDialogComponent, {
      ...ProfileModalBaseOptions,
      windowClass: 'dc-modal profile-modal modal-window',
      size: 'lg',
      backdrop: true,
    });

    (<ProfileDocumentsDialogComponent>modal.componentInstance).documents = data;
    (<ProfileDocumentsDialogComponent>modal.componentInstance).title = title;
  }

  private getFileDialogLabel(userType: UserTypes): FileDialogTypeLabel {
    switch (userType) {
      case RolesEnum.ADMIN_OF_DIRECTION:
      case RolesEnum.ADMIN_OF_USER: {
        return { type: 'holding', title: 'АП/АН' };
      }
      case RolesEnum.PARTNER: {
        return { type: 'partner', title: 'Агентов' };
      }
      case RolesEnum.EXPERT: {
        return { type: 'expert', title: 'Экспертов' };
      }
      default: {
        return { type: '', title: '' };
      }
    }
  }
}
