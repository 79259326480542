import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-line-clamp',
  templateUrl: './line-clamp.component.html',
  styleUrls: ['./line-clamp.component.scss'],
})
export class LineClampComponent {
  @Input() content: string;
  @ViewChild(NgbTooltip, { read: ElementRef }) private readonly outlet?: ElementRef<HTMLElement>;

  constructor(private el: ElementRef) {}

  get overflown(): boolean {
    if (!this.outlet) {
      return false;
    }

    const { scrollWidth } = this.outlet.nativeElement;
    const { clientWidth } = this.el.nativeElement;

    return scrollWidth - clientWidth > 0;
  }
}
