export enum FaqQuestionStatus {
  InDevelop = 'in_develop',
  OnVerification = 'on_verification',
  Approved = 'approved',
  Archived = 'archived',
}

export enum FaqTabs {
  Questions,
  Modules,
}

export enum FaqActionTypes {
  Sort,
  Edit,
  Add,
  Filter,
  Create,
}

export enum FaqModalActions {
  Created,
  Updated,
  Cancel,
  Filtered,
  Close,
}
