<div class="kt-flow">
  <div class="kt-flow-item">
    <input
      type="text"
      placeholder="Поиск по сотрудникам"
      class="kt-input"
      [(ngModel)]="employeeInputValue"
      [disabled]="!employees.length"
    />
  </div>
  <div class="kt-flow-item"></div>
  <div class="kt-flow-item"></div>
  <div class="kt-flow-item overflow">
    <app-user-item
      *ngFor="let user of employees | usersInitialsFilter: employeeInputValue"
      [item]="user"
      [selected]="selectedEmployee"
      (onSelected)="selectEmployee($event)"
    >
    </app-user-item>

    <div *ngIf="!employees.length">Нет сотрудников</div>
  </div>
  <div class="kt-flow-item flow-buttons">
    <a (click)="remove()" [ngClass]="{ disabled: readonly || !user?.id || !isAccessChangeOptions }">
      <fa-icon [icon]="['fas', 'angle-left']"></fa-icon>
    </a>
    <a (click)="move()" [ngClass]="{ disabled: readonly || !selectedEmployee?.id || !isAccessChangeOptions }">
      <fa-icon [icon]="['fas', 'angle-right']"></fa-icon>
    </a>
  </div>
  <div class="kt-flow-item overflow">
    <app-user-item *ngIf="user?.id" [item]="user"> </app-user-item>
  </div>
</div>
