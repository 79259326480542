import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UserFile } from '@app/file_manager/models/user-file.model';
import { FILE_MANAGER_LAYOUTS } from '@app/file_manager/file_manager.constants';

@Component({
  selector: 'app-file-item-list',
  templateUrl: './file-item-list.component.html',
  styleUrls: ['./file-item-list.component.scss'],
})
export class FileItemListComponent {
  @Input() file: UserFile;
  @Input() selected: UserFile[] = [];
  @Input() layout: FILE_MANAGER_LAYOUTS;
  @Input() searchQuery: string;

  @Output() onShare = new EventEmitter<UserFile[]>();
  @Output() onSelected = new EventEmitter<UserFile[]>();

  fileManagerLayouts = FILE_MANAGER_LAYOUTS;

  hasSelected(file: UserFile): boolean {
    return this.selected.some((data: UserFile) => data.id === file.id);
  }

  share(event, file: UserFile[]): void {
    this.onShare.emit(file);
    event.stopPropagation();
  }

  toggleInfoUser(event, file: UserFile): void {
    file.showUserInfoInArea = !file.showUserInfoInArea;
    if (event) {
      event.stopPropagation();
    }
  }

  fileAllAccessChanged(event, file: UserFile) {
    if (event) {
      event.stopPropagation();
    }
    if (file.for_providers) {
      file.for_customers = false;
    }
    this.onSelected.emit(this.selected);
  }

  preventSelect(event) {
    if (event) {
      event.stopPropagation();
    }
  }

  fileCustomerAccessChanged(event, file: UserFile) {
    if (event) {
      event.stopPropagation();
    }
    if (file.for_customers) {
      file.for_providers = false;
    }
    this.onSelected.emit(this.selected);
  }
}
