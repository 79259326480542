import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NotificationsService} from 'angular2-notifications';
import {
  EventModel, FINISH_STATE,
  LIST_REPEAT_DAY_SELECT,
  LIST_REPEAT_SELECT,
  LIST_STATE,
  STATE, TITLE_FORM_EVENT
} from '@app/shared/components/calendar-widget/constants/calendar.constants';
import {mergeMap} from 'rxjs/operators';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CalendarDataService} from '@app/shared/services/calendar/calendar-data.service';
import {ModalInjectorService} from '@app/modal-injector/services/modal-injector.service';

@Component({
  selector: 'app-add-event',
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddEventComponent implements OnInit, OnDestroy {

  @Input() id;
  @Input() isShowed;
  @Input() day: string;
  @Input() hour: string;
  @Input() showModal: boolean;
  @Input() copy: boolean;
  @Input() eventModel: EventModel;
  @Output() showModalChange = new EventEmitter<boolean>();
  @ViewChild('repeatSelect') private repeatSelect: any;

  public form: FormGroup;
  listState = LIST_STATE;
  repeatSelectList = LIST_REPEAT_SELECT;
  repeatSelectDayList = LIST_REPEAT_DAY_SELECT;
  daysOfTheWeek = LIST_REPEAT_SELECT[LIST_REPEAT_SELECT.length - 1].value;
  idEvent;
  repeatEditId;
  showCloseModal;
  formValueBackup;

  constructor(
    private http: HttpClient,
    private notify: NotificationsService,
    private calendarDataService: CalendarDataService,
    private modalInjectorService: ModalInjectorService,
  ) {}

  setState(state) {
    this.form.get('status').patchValue(state);
  }

  selectDayRepeat(value) {
    const formValue = [...this.form.get('days_of_the_week').value],
      indexValue = formValue.indexOf(value);
    if (~indexValue) {
      formValue.splice(indexValue,1);
      this.form.get('days_of_the_week').patchValue(formValue);
    } else {
      formValue.push(value);
      this.form.get('days_of_the_week').patchValue(formValue);
    }
  }

  getNameDay() {
    const value = this.form.get('days_of_the_week').value.sort();
    return this.repeatSelectDayList.filter(item => value.includes(item.value)).map(item => item.name);
  }

  addEvent() {
    Object.keys(this.form.value).forEach(key => {
      this.form.controls[key].markAsTouched();
    })
    if (this.repeatEditId && this.idEvent) {
      const valueEvent = {...this.form.value};
      if (FINISH_STATE.includes(valueEvent.status)) {
        const valueCurrentEvent = {status: valueEvent.status};
        valueEvent.status = this.formValueBackup.status;
        this.updateCurrentEventAndParent(valueCurrentEvent, valueEvent).subscribe(
          () => this.successSave(),
        (err) => this.errorSave(err)
        )
      } else {
        if (this.checkBackup(valueEvent)) {
          this.notify.success('Успешно!', 'Данные успешно сохранены');
          this.closeHelper(true)
        } else {
          this.updateEvent(this.repeatEditId, true);
        }
      }
    } else {
      this.updateEvent(this.idEvent);
    }
  }

  updateEvent(id, repeat = false) {
    this.calendarDataService.addEvent(this.form.value, id, repeat).subscribe(
      () => this.successSave(),
      (err) => this.errorSave(err)
    );
  }

  updateCurrentEventAndParent(valueCurrentEvent, valueEvent) {
    if (this.checkBackup(valueEvent)) {
      return this.calendarDataService.addEvent(valueCurrentEvent, this.idEvent)
    } else {
      return this.calendarDataService.addEvent(valueCurrentEvent, this.idEvent).pipe(
        mergeMap(() => {
          return this.calendarDataService.addEvent(valueEvent, this.repeatEditId, true);
        })
      )
    }
  }

  successSave() {
    this.notify.success('Успешно!', 'Данные успешно сохранены');
    this.calendarDataService.updateDataAll();
    this.closeHelper(true)
  }

  errorSave(err) {
    let msg = '';
    if (err.error.message === 'Validation failed') {
      msg = `Пожалуйста, проверьте заполненные поля`;
      Object.keys(TITLE_FORM_EVENT).forEach(key => {
        if (err.error.errors?.[key]) {
          msg += `<br><br>${TITLE_FORM_EVENT[key]} - ${err.error.errors?.[key].join(' ')}`;
        }
      })
    } else {
      msg = err.error.message;
    }
    this.notify.error('Внимание!', msg);
  }

  closeDropdown() {
    setTimeout(() => {
      this.repeatSelect.close();
    }, 100);
  }

  checkBackup(value = null) {
    return JSON.stringify(this.formValueBackup) == JSON.stringify(value || {...this.form.value})
  }

  ngOnInit() {

    this.form = new FormGroup({
      title: new FormControl('', Validators.required),
      status: new FormControl(STATE.IMPORTANT_IN_TIME, []),
      description: new FormControl('', Validators.required),
      start_date: new FormControl('', []),
      start_time: new FormControl('', []),
      end_date: new FormControl('', []),
      end_time: new FormControl('', []),
      repeat_type: new FormControl(this.repeatSelectList[0].value, []),
      days_of_the_week: new FormControl([], []),
    });

    if (this.day) {
      this.form.get('start_date').patchValue(this.day);
    }
    if (this.hour) {
      this.form.get('start_time').patchValue(this.hour);
    }
    if (this.eventModel) {
      Object.keys(this.form.value).forEach(key => {
        if (this.eventModel[key]) {
          if (~['end_time', 'start_time'].indexOf(key)) {
            this.form.get(key).patchValue(this.eventModel[key].substr(0, 5));
          } else {
            this.form.get(key).patchValue(this.eventModel[key]);
          }
        }
      })

      if (this.eventModel.extra?.repeat_type && this.eventModel.extra?.repeat_type !== LIST_REPEAT_SELECT[0].value) {
        this.repeatEditId = this.eventModel.extra['repeat_id'];
        this.form.get('repeat_type').patchValue(this.eventModel.extra['repeat_type']);
        this.form.get('days_of_the_week').patchValue(this.eventModel.extra['days_of_the_week'] || []);
        this.form.get('start_date').patchValue(this.eventModel.extra['start_date_first_event']);
      }

      if (!this.copy) {
        this.idEvent = this.eventModel.id;
      } else {
        if (FINISH_STATE.includes(this.form.get('status').value)) {
          this.form.get('status').patchValue(STATE.IMPORTANT_IN_TIME);
        }
      }
    }

    setTimeout(() => this.formValueBackup = {...this.form.value}, 0);
  }

  close() {
    if (!this.showCloseModal && !this.modalInjectorService.findTopElem()) {
      if (this.checkBackup() && !this.copy) {
        this.closeMain();
      } else {
        this.showCloseModal = true;
      }
    }
  }

  closeHelper(mainClose = false) {
    setTimeout(() => {this.showCloseModal = false});
    if (mainClose) {
      this.closeMain();
    }
  }

  closeMain() {
    this.showModal = false;
    this.showModalChange.emit(this.showModal);
    this.modalInjectorService.closeModal(this.id);
  }

  ngOnDestroy() {}
}
