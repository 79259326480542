import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DCTreeItem } from '@app/+competence-map/models/competence-map.models';
import { CompetenceService } from '@app/+competence-map/services/competence.service';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Project } from '@app/+competence-map/models/projects.models';
import { CMCatalogTypes } from '@app/+competence-map/constants/sections.constants';
import { CMProjectStatuses } from '@app/+competence-map/constants/projects.constants';
import { UserFilter } from '@app/+competence-map/models/user-filters.model';
import { SplitSectionParams } from '@app/+competence-map/models/sections.model';
import { CompetenceSectionsService } from '@app/+competence-map/services/competence-sections.service';
import { ProjectBase, ProjectBaseComponent } from '@app/+competence-map/models/project.class';
import { PortalService } from '@app/shared/services/portal.service';
import { AuthService } from '@app/shared/services/auth.service';
import { DestroyService } from '@app/services/destroy.service';
import { TreeHelper } from '@app/shared/helpers/tree.helper';

interface NewSection {
  title: string;
  id: number;
  sections: DCTreeItem[];
  filters: UserFilter[];
}
@Component({
  selector: 'app-split-section',
  templateUrl: './split-section.component.html',
  styleUrls: ['./split-section.component.scss'],
  providers: [PortalService, DestroyService],
})
export class SplitSectionComponent extends ProjectBaseComponent implements OnInit, ProjectBase {
  @Input() catalog: CMCatalogTypes;
  @Input() path: string;
  @Input() currentSection: DCTreeItem = {
    children: [],
  } as DCTreeItem;
  @Input() filters: UserFilter[] = [];

  form: FormGroup;
  newSections: FormArray;

  @Output() closeEvent = new EventEmitter();
  @Output() reloadEvent = new EventEmitter();

  constructor(
    private competenceService: CompetenceService,
    private competenceSectionsService: CompetenceSectionsService,
    private destroy$: DestroyService,
    private fb: FormBuilder,
    protected portalService: PortalService,
    protected authService: AuthService
  ) {
    super(portalService, authService);
  }

  get isNew() {
    return !this.project || (this.project && !this.project.id);
  }

  get level(): number {
    if (this.isNew) {
      return this.currentSection.level;
    } else {
      return this.project.spec[this.project.action].level;
    }
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  createEntities(id, sections, prefix) {
    return sections.map((item, i) => {
      return this.fb.group({
        nativeId: item.id,
        id: `${prefix}_${id}_${i}`,
        title: item.title,
        checked: item.checked,
      });
    });
  }

  validateSelection(item) {
    this.newSections.controls.forEach((control) => {
      const cArray = <FormArray>control.get('sections');

      cArray.controls.forEach((el: any) => {
        if (item.value.nativeId === el.value.nativeId && item.value.id !== el.value.id && el.value.checked) {
          el.controls.checked.patchValue(false);
        }
      });
    });
  }

  getNewSection(data: NewSection) {
    const sectionsArray = this.createEntities(data.id, data.sections, 's');
    const filtersArray = this.createEntities(data.id, data.filters, 'f');

    return this.fb.group({
      title: [data.title, Validators.required],
      sections: this.fb.array(sectionsArray),
      filters: this.fb.array(filtersArray),
    });
  }

  addNewSection() {
    this.newSections.push(
      this.getNewSection({
        title: '',
        id: this.newSections.length + 1,
        sections: this.currentSection.children,
        filters: this.filters.map((item) => {
          return {
            ...item,
            title: item.template_filter.title,
          };
        }),
      })
    );
  }

  private initializeForm(): void {
    if (this.isNew) {
      const filters = this.filters.map((item) => {
        return {
          ...item,
          title: item.template_filter.title,
        };
      });
      this.newSections = this.fb.array([
        this.getNewSection({
          title: '',
          id: 0,
          sections: this.currentSection.children,
          filters,
        }),
        this.getNewSection({
          title: '',
          id: 1,
          sections: this.currentSection.children,
          filters,
        }),
      ]);

      this.form = this.fb.group({
        sections: this.newSections,
      });
    }

    if (!this.isNew) {
      this.newSections = this.fb.array([]);

      this.path = this.project.path;

      this.form = this.fb.group({
        sections: this.newSections,
      });

      const sectionChildes = this.project.spec[this.project.action].new_sections
        .map((section) => section.childes_sections_ids)
        .reduce((a, b) => [...a, ...b]);

      this.competenceSectionsService
        .getSections([
          {
            name: 'id',
            op: 'eq',
            val: this.project.spec[this.project.action].id,
          },
        ])
        .pipe(
          map((sections) => {
            const result = sections.map((item) => {
              return {
                ...item,
                parent: {
                  id: item.parent_id,
                },
              };
            });

            this.currentSection = TreeHelper.arrayToTree(result)[0];
          }),
          switchMap(() => {
            return this.competenceSectionsService.getSections([
              {
                name: 'id',
                op: 'in_',
                val: sectionChildes,
              },
              {
                name: 'level',
                op: 'eq',
                val: this.currentSection.level + 1,
              },
            ]);
          }),
          tap((children) => {
            this.currentSection.children = (children as DCTreeItem[]).filter(
              (item) => item.level >= this.currentSection.level + 1
            );
          }),
          switchMap(() => {
            return this.competenceService.getUserFilters([
              {
                name: 'id',
                op: 'in_',
                val: this.getFiltersIds(),
              },
            ]);
          }),
          takeUntil(this.destroy$)
        )
        .subscribe((filters) => {
          this.filters = filters.map((item) => {
            return {
              ...item,
              title: item.template_filter.title,
            };
          });

          this.project.spec[this.project.action].new_sections.forEach((item, i) => {
            this.newSections.push(
              this.getNewSection({
                title: item.section_title,
                id: i,
                sections: this.currentSection.children.map((el) => {
                  return {
                    ...el,
                    checked: item.childes_sections_ids.includes(el.id),
                  };
                }),
                filters: this.filters.map((el) => {
                  return {
                    ...el,
                    checked: item.instance_filters_ids.includes(el.id),
                  };
                }),
              })
            );
          });
        });
    }
  }

  getFiltersIds(): string[] {
    const result = [];

    this.project.spec[this.project.action].new_sections.forEach((item) => {
      item.instance_filters_ids.forEach((id) => {
        if (!result.includes(id)) {
          result.push(id);
        }
      });
    });

    return result;
  }

  getSectionsIds(): string[] {
    const result = [];

    this.project.spec[this.project.action].new_sections.forEach((item) => {
      item.childes_sections_ids.forEach((id) => {
        if (!result.includes(id)) {
          result.push(id);
        }
      });
    });

    return result;
  }

  resetSelected(controls: AbstractControl[]) {
    controls.forEach((control) => {
      control['controls'].checked.patchValue(false);
    });
  }

  canAddNew() {
    return this.currentSection.children && this.newSections.controls.length < this.currentSection.children.length;
  }

  canRemove(i: number) {
    return i > 1;
  }

  remove(i: number) {
    this.newSections.removeAt(i);
  }

  addProject(status?: CMProjectStatuses): void {
    const params: SplitSectionParams = {
      id: this.currentSection.id,
      new_sections: this.form.value.sections.map((item) => {
        return {
          childes_sections_ids: item.sections.filter((section) => !!section.checked).map((section) => section.nativeId),
          instance_filters_ids: item.filters.filter((filter) => !!filter.checked).map((filter) => filter.nativeId),
          section_title: item.title,
        };
      }),
    };

    this.competenceSectionsService
      .splitSections(params)
      .pipe(
        switchMap((project) => {
          if (!status) {
            return of(project);
          } else {
            return this.competenceService.updateProject({
              id: project.id,
              status,
            });
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((project) => {
        this.project = project;

        this.reloadEvent.emit();
        this.competenceService.projectSuccess(status);
        this.form.markAsPristine();
      }, this.competenceService.error);
  }

  updateProject(status?: CMProjectStatuses) {
    this.competenceService
      .updateProject({
        id: this.project.id,
        spec: {
          [this.project.action]: {
            id: this.project.spec[this.project.action].id,
            new_sections: this.form.value.sections.map((item) => {
              return {
                childes_sections_ids: item.sections
                  .filter((section) => !!section.checked)
                  .map((section) => section.nativeId),
                instance_filters_ids: item.filters
                  .filter((filter) => !!filter.checked)
                  .map((filter) => filter.nativeId),
                section_title: item.title,
              };
            }),
          },
        },
        status,
      } as Project)
      .pipe(takeUntil(this.destroy$))
      .subscribe((project) => {
        this.project = project;

        this.reloadEvent.emit();
        this.competenceService.projectSuccess(status);
        this.form.markAsPristine();
      }, this.competenceService.error);
  }

  cancel() {
    this.initializeForm();
  }

  save(status: CMProjectStatuses) {
    if (this.isNew) {
      this.addProject(status);
    } else {
      this.updateProject(status);
    }
  }

  agree(status: CMProjectStatuses) {
    if (this.isNew) {
      this.addProject(status);
    } else {
      this.updateProject(status);
    }
  }

  archive() {
    this.warnChangeArchiveStatus(() => {
      this.updateProject(CMProjectStatuses.ARCHIVE);
    });
  }

  isDataDisabled() {
    return (
      this.project &&
      (this.project.status === CMProjectStatuses.ARCHIVE ||
        this.project.status === CMProjectStatuses.AGREED ||
        this.project.status === CMProjectStatuses.ON_AGREEMENT)
    );
  }

  isArchiveDisabled() {
    if (this.isNew) {
      return true;
    }
    return this.project.status === CMProjectStatuses.ARCHIVE || this.project.status === CMProjectStatuses.AGREED;
  }

  isSectionSelected(section: DCTreeItem) {
    const selectedSections = this.newSections.value
      .map((item) => item.sections)
      .reduce((a, b) => a.concat(...b), [])
      .filter((item) => item.checked);

    return !!selectedSections.find((item) => item.nativeId === section.id);
  }
}
