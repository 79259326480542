import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { FormControl, FormGroup } from '@angular/forms';

import { combineLatest } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

import {
  CEIL_SLIDER_OPTION,
  FLOOR_SLIDER_OPTION,
} from '@app/shared/components/form/form-range-slider/form-range-slider.constants';
import { DestroyService } from '@app/services/destroy.service';
import { SliderValue } from '@app/shared/models/slider.model';

@Component({
  selector: 'app-form-range-slider',
  templateUrl: './form-range-slider.component.html',
  styleUrls: ['./form-range-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormRangeSliderComponent implements OnInit {
  @Input() minRange: number = null;
  @Input() maxRange: number = null;
  @Output() changeRange = new EventEmitter();

  private _readonly = false;

  form: FormGroup = new FormGroup({
    inputMinValue: new FormControl(0),
    inputMaxValue: new FormControl(0),
    sliderValue: new FormControl([0, 0]),
  });

  options: Options = {
    step: 0.0001,
    floor: FLOOR_SLIDER_OPTION,
    ceil: CEIL_SLIDER_OPTION,
    enforceStep: false,
    enforceRange: false,
    translate: (): string => {
      return '';
    },
    combineLabels: (): string => {
      return '';
    },
  };

  @Input() set setValueFrom(value: number) {
    const inputValue = value === null ? 0 : value;
    this.inputMinControl.setValue(inputValue, { emitEvent: false });
  }

  @Input() set setValueTo(value: number) {
    const inputValue = value === null ? 0 : value;
    this.inputMaxControl.setValue(inputValue, { emitEvent: false });
  }

  @Input() set setSliderValue(sliderValue: number[]) {
    if (sliderValue) {
      this.sliderControl.setValue(sliderValue);
    }
  }

  @Input() set step(step: number) {
    this.options = {
      ...this.options,
      step: step,
    };
  }

  @Input() set readonly(value: boolean) {
    this._readonly = value;
    this.options = {
      ...this.options,
      readOnly: value,
      disabled: value,
    };
  }

  get inputMinControl() {
    return this.form.get('inputMinValue');
  }

  get inputMaxControl() {
    return this.form.get('inputMaxValue');
  }

  get sliderControl() {
    return this.form.get('sliderValue');
  }

  get readonly(): boolean {
    return this._readonly;
  }

  constructor(private destroy$: DestroyService) {}

  ngOnInit() {
    const newOptions: Options = Object.assign({}, this.options);
    if (this.minRange !== null) {
      newOptions.floor = this.minRange;
    }

    if (this.maxRange !== null) {
      newOptions.ceil = this.maxRange;
    }

    this.options = newOptions;

    this.sliderControl.valueChanges
      .pipe(startWith([this.inputMinControl.value, this.inputMaxControl.value]), takeUntil(this.destroy$))
      .subscribe(([min, max]) => {
        this.inputMinControl.setValue(min, { emitEvent: false });
        this.inputMaxControl.setValue(max, { emitEvent: false });
        this.changeRange.emit({ min: parseFloat(min) || 0, max: parseFloat(max) || 0 });
      });

    combineLatest([
      this.inputMinControl.valueChanges.pipe(startWith([this.inputMinControl.value])),
      this.inputMaxControl.valueChanges.pipe(startWith([this.inputMaxControl.value])),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((minMax) => {
        this.sliderControl.setValue(minMax, { emitEvent: false });
      });
  }

  controlFocus($event: FocusEvent, fieldName: string) {
    if (this._readonly) {
      return;
    }
    if (this[fieldName] === 0) {
      this[fieldName] = '';
    }
  }

  controlBlur($event: FocusEvent, fieldName: string) {
    if (this._readonly) {
      return;
    }

    if (!this[fieldName]) {
      this[fieldName] = 0;
    }
  }
}
