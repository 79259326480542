<div class="section-header">
  <div>
    <div class="section-header-row" appTestLocator="add-unit__project">
      <div class="section-header-label">Проект ID:</div>
      <div class="section-header-value">{{ projectId }}</div>
    </div>
    <div class="section-header-row" appTestLocator="add-unit__status">
      <div class="section-header-label">Статус:</div>
      <div class="section-header-value d-flex align-items-center">
        {{ projectStatus | cmProjectStatus }}&nbsp;&nbsp;
        <app-svg-icon name="change" width="16" class="color-brand-light"></app-svg-icon>
      </div>
    </div>
    <div class="section-header-row" appTestLocator="add-unit__author">
      <div class="section-header-label">Автор:</div>
      <div class="section-header-value">{{ userAuthor | roleName }}</div>
    </div>
    <div class="section-header-row" appTestLocator="add-unit__date">
      <div class="section-header-label">Дата:</div>
      <div class="section-header-value">{{ projectDate | dateFormat }}</div>
    </div>
  </div>
  <div>
    <div class="section-header-text" appTestLocator="add-unit__title">Добавить новую единицу измерения</div>
  </div>
  <div>
    <div
      class="section-header-archive"
      (click)="archive()"
      [class.disabled]="isArchiveDisabled()"
      appTestLocator="add-unit__archive"
    >
      <app-svg-icon name="trash" width="16"></app-svg-icon>
      <span>Перенести в архив</span>
    </div>
  </div>
</div>
<div [formGroup]="form" class="modal-body" [class.disabled]="isDataDisabled()">
  <div formArrayName="sections">
    <div class="row">
      <div class="col-6">
        <div class="mb-2" appTestLocator="add-unit__fullname_heading">Полное название</div>
      </div>
      <div class="col-6">
        <div class="mb-2" appTestLocator="add-unit__name_heading">Сокращенное название</div>
      </div>
    </div>
    <div class="array-sections">
      <div *ngFor="let section of sections.controls; let i = index" class="d-flex">
        <div class="row mb-4 mr-1 unit-group" [formGroupName]="i">
          <div class="col-6">
            <dc-form-field-wrapper [style]="'material'" [required]="true">
              <dc-form-input [maxLength]="30" [isShowLength]="true">
                <input
                  formInput
                  formControlName="fullName"
                  placeholder="Введите полное название единицы"
                  appTestLocator="add-unit__fullname_input-{{ i }}"
                />
              </dc-form-input>
              <!-- <div
                *ngIf="section.get('fullName').touched && section.get('fullName').invalid && section.get('fullName').errors.required"
                class="form-field-error"
                appTestLocator="add-unit__fullname_error-{{ i }}"
              >
                {{ messages.required }}
              </div> -->
            </dc-form-field-wrapper>
          </div>
          <div class="col-6">
            <dc-form-field-wrapper [style]="'material'">
              <dc-form-input [maxLength]="10" [isShowLength]="true">
                <input
                  formInput
                  formControlName="name"
                  placeholder="Введите сокращенное название"
                  appTestLocator="add-unit__name_input-{{ i }}"
                />
              </dc-form-input>
            </dc-form-field-wrapper>
          </div>
        </div>
        <div
          (click)="remove($event, i)"
          *ngIf="i > 0"
          class="color-brand-light cursor-pointer"
          appTestLocator="add-unit__remove_input-{{ i }}"
        >
          <app-svg-icon name="close" width="20" height="20"></app-svg-icon>
        </div>
      </div>
    </div>
  </div>

  <div class="dc-icon-button" (click)="addName()" appTestLocator="add-unit__add-new">
    <app-svg-icon name="plus" width="14"></app-svg-icon>
    <span>Добавить еще единицу измерения</span>
  </div>
</div>

<app-operation-modal-footer
  (onCancel)="cancel()"
  (onSave)="save($event)"
  (onAgree)="agree($event)"
  [project]="project"
  [form]="form"
>
</app-operation-modal-footer>
