import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { BACKEND_DATE_FORMAT, DATE_FORMAT } from '@app/shared/constants/date.constants';

export interface DoubleDatePickerParams {
  fromDate: string | Date;
  toDate: string | Date;
  fromTime?: string | Date;
  toTime?: string | Date;
  name?: string;
}

@Component({
  selector: 'app-double-datepicker-filter',
  templateUrl: './double-datepicker-filter.component.html',
  styleUrls: ['./double-datepicker-filter.component.scss'],
})
export class DoubleDatepickerFilterComponent implements OnChanges {
  @Input() value: DoubleDatePickerParams = {
    fromDate: null,
    toDate: null,
    name: null,
  };

  @Input() placeholder: string;
  @Input() fromDatePlaceholder: string = '';
  @Input() toDatePlaceholder: string = '';
  @Input() withName: boolean;
  @Input() filterSuggestions: string[];
  @Input() externalDateFormat = BACKEND_DATE_FORMAT;

  form: FormGroup = new FormGroup({
    fromDate: new FormControl(''),
    toDate: new FormControl(''),
    name: new FormControl(''),
  });

  localValue: DoubleDatePickerParams = {
    fromDate: null,
    toDate: null,
    name: null,
  };

  @Output() submitEvent = new EventEmitter<DoubleDatePickerParams>();
  @Output() closeEvent = new EventEmitter<unknown>();
  @Output() searchEvent = new EventEmitter<string>();

  ngOnChanges(changes: SimpleChanges) {
    const { value } = changes;

    if (value && value.currentValue) {
      // TODO: С датами явно что то надо придумать, так делать не хорошо!!!
      this.localValue = Object.assign({}, this.value, {
        toDate: this.value.toDate ? moment(this.value.toDate, this.externalDateFormat).format(DATE_FORMAT) : null,
      });
      this.form.patchValue(this.localValue);
    }
  }

  apply(): void {
    const { value } = this.form;
    this.submitEvent.emit(value);
  }

  search(value: string): void {
    this.localValue.name = value;

    this.searchEvent.emit(value);
  }

  select(value: string): void {
    this.localValue.name = value;
  }

  cancel(): void {
    this.closeEvent.emit();
  }
}
