import { Directive, ElementRef, HostListener, Input, OnChanges } from '@angular/core';
import { FormControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[onlyNumber]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: OnlyNumberDirective,
      multi: true,
    },
  ],
})
export class OnlyNumberDirective implements OnChanges {
  @Input() fractionSize = 2;
  private specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    'Control',
    'v',
    'ArrowLeft',
    'ArrowRight',
    'Delete',
  ];
  private regexPreventInput: RegExp;
  private reqexValidation = /^\d+(\.[0-9]+)*$/;
  private reqexKeyPrevent = /^\d+\.{0,1}\d*$/;

  constructor(private el: ElementRef) {
    this.regexPreventInput = this.getReqExp(this.fractionSize);
  }

  ngOnChanges() {
    this.regexPreventInput = this.getReqExp(this.fractionSize);
  }

  validate(c: FormControl): { [key: string]: { valid: boolean } } {
    const v = c.value;
    const invalid = { onlyNumber: { valid: false } };

    // if (v && !String(v).match(this.regex)) {
    //   return invalid;
    // }

    if (v && !this.reqexValidation.test(v)) {
      return invalid;
    }

    return null;
  }

  getReqExp(fractionSize: number): RegExp {
    return new RegExp('^[0-9]+(.[0-9]{0,' + fractionSize + '}){0,1}$', 'g');
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    const current: string = this.el.nativeElement.value;

    const next: string = current.concat(event.key);

    const selected = this.getSelectedText(event.target);

    if (next && !String(next).match(this.regexPreventInput) && !selected) {
      event.preventDefault();
      return;
    }

    if (!this.reqexKeyPrevent.test(next)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event']) onPast(event: ClipboardEvent) {
    const clipboardData = event.clipboardData.getData('Text');
    if (isNaN(Number(clipboardData))) {
      event.preventDefault();
    }
  }

  getSelectedText(elem) {
    if (elem.tagName === 'TEXTAREA' || (elem.tagName === 'INPUT' && elem.type === 'text')) {
      return elem.value.substring(elem.selectionStart, elem.selectionEnd);
    }
    return null;
  }
}
