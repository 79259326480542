<div class="mb-4">
  <dc-form-field-wrapper [style]="'material'" [vertically]="true" [required]="true">
    <dc-form-input [isShowClear]="true">
      <input formInput [placeholder]="placeholder" [(ngModel)]="searchValue" />
    </dc-form-input>
  </dc-form-field-wrapper>
</div>

<div class="search-list mb-4" *ngIf="list && list | filter : searchValue : 'label' as searchList">
  <ng-container *ngIf="searchList.length; else empty">
    <div *ngFor="let item of searchList">
      <div
        class="search-list__item"
        [class.search-list__item--selected]="selected?.id === item.id"
        [ngbTooltip]="item.label"
        [disableTooltip]="item.label?.toString()?.length < 24"
        (click)="select(item)"
      >
        <div class="text-overflow">
          <span [innerHTML]="item.label | highlight : searchValue"></span>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #empty>Ничего не найдено</ng-template>
</div>

<div class="d-flex justify-content-between">
  <button class="btn btn-dc-outline" [disabled]="!selected" (click)="cancel()">Отменить</button>
  <button class="btn btn-dc-primary" [disabled]="!selected" (click)="apply()">Применить</button>
</div>
