import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompetenceService } from '@app/+competence-map/services/competence.service';
import { DCTreeItem } from '@app/+competence-map/models/competence-map.models';
import { switchMap, takeUntil } from 'rxjs/operators';
import { MultipleSearchSuggestion } from '@app/shared/models/multiple-search.model';
import { combineLatest, of } from 'rxjs';
import { Project } from '@app/+competence-map/models/projects.models';
import { UnionSectionParams } from '@app/+competence-map/models/sections.model';
import { CMActions, CMProjectStatuses } from '@app/+competence-map/constants/projects.constants';
import { CompetenceSectionsService } from '@app/+competence-map/services/competence-sections.service';
import { ProjectBase, ProjectBaseComponent } from '@app/+competence-map/models/project.class';
import { PortalService } from '@app/shared/services/portal.service';
import { AuthService } from '@app/shared/services/auth.service';
import { CMCatalogTypes, CMSectionStatuses } from '@app/+competence-map/constants/sections.constants';
import { DestroyService } from '@app/services/destroy.service';
import { SectionAccessService } from '@app/+competence-map/services/section-access.service';
import { RolesEnum } from '@app/shared/constants/roles.constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MultiSelectListItem } from '@app/shared/models/multi-select-list-item';

@Component({
  selector: 'app-union-section',
  templateUrl: './union-section.component.html',
  styleUrls: ['./union-section.component.scss'],
  providers: [PortalService, DestroyService],
})
export class UnionSectionComponent extends ProjectBaseComponent implements OnInit, ProjectBase {
  @Input() path: string;
  @Input() currentSection: DCTreeItem = {} as DCTreeItem;

  currentUnions: number[];
  list: MultipleSearchSuggestion[];

  form: FormGroup;

  private newName = '';

  @Output() closeEvent = new EventEmitter();
  @Output() reloadEvent = new EventEmitter();

  constructor(
    private competenceService: CompetenceService,
    private competenceSectionsService: CompetenceSectionsService,
    private destroy$: DestroyService,
    protected portalService: PortalService,
    protected authService: AuthService,
    private sectionAccessService: SectionAccessService,
    private fb: FormBuilder
  ) {
    super(portalService, authService);
  }

  get isNew() {
    return !this.project || (this.project && !this.project.id);
  }

  get level(): number {
    if (this.project && this.project.spec[CMActions.CREATE_SECTIONS].length) {
      return this.project.spec[CMActions.CREATE_SECTIONS][0].level;
    }
    return this.currentSection && this.currentSection.level && +this.currentSection.level;
  }

  ngOnInit(): void {
    const parentId = this.isNew
      ? (this.currentSection.parent_id as number)
      : (this.project.spec[CMActions.CREATE_SECTIONS][0].parent_id as number);
    const catalog = this.isNew ? this.currentSection.catalog : this.project.spec[CMActions.CREATE_SECTIONS][0].catalog;

    if (!this.isNew) {
      this.currentSection = {
        id: this.project.object_to.sections_for_union[0],
      } as DCTreeItem;

      this.newName = this.project.spec[CMActions.CREATE_SECTIONS][0].title;
      this.currentUnions = (this.project.object_to.sections_for_union as number[]).filter(
        (item) => item !== this.currentSection.id
      );
    }

    this.form = this.fb.group({
      sections: [this.currentUnions, Validators.required],
      newName: [this.newName, Validators.required],
    });

    this.disableName();

    this.getList(parentId, catalog);
  }

  disableName() {
    if (this.isDataDisabled()) {
      this.form.controls.newName.disable({
        emitEvent: false,
      });
    }
  }

  getList(parentId: number, catalog: CMCatalogTypes) {
    const params = [
      {
        name: 'parent_id',
        op: 'eq',
        val: parentId,
      },
      {
        name: 'catalog',
        op: 'eq',
        val: catalog,
      },
    ];

    combineLatest([
      this.competenceSectionsService.getSections(params),
      this.sectionAccessService.getSectionAccess([
        {
          name: 'user_id',
          op: 'eq',
          val: this.authService.user_id,
        },
      ]),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([sections, access]) => {
        const result = sections.map((item) => {
          return {
            ...item,
            parent: {
              id: item.parent_id,
            },
          };
        });

        if (!this.isNew) {
          this.currentSection.title = result.find((item) => item.id === this.currentSection.id).title;
        }

        this.list = result
          .filter((item) => item.id !== this.currentSection.id && item.level === this.level)
          .map((item) => {
            return {
              id: item.id,
              label: item.title,
              value: this.isNew ? false : this.currentUnions.includes(+item.id),
              disabled:
                item.status === CMSectionStatuses.ARCHIVE ||
                // повторяет логику директивы appSectionAccess
                (this.authService.user_type === RolesEnum.EXPERT
                  ? !access.find((accessItem) => +accessItem.section.tree_id === +item.tree_id)
                  : false),
            };
          });
      });
  }

  addProject(status?: CMProjectStatuses): void {
    const { newName } = this.form.getRawValue();
    const params: UnionSectionParams = {
      section_ids: this.currentUnions.concat(this.currentSection.id),
      title: newName,
    };
    this.competenceSectionsService
      .unionSections(params)
      .pipe(
        switchMap((project) => {
          if (!status) {
            return of(project);
          } else {
            return this.competenceService.updateProject({
              id: project.id,
              status,
            });
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((project) => {
        this.project = project;

        this.disableName();

        this.reloadEvent.emit();
        this.competenceService.projectSuccess(status);
        this.form.markAsPristine();
      }, this.competenceService.error);
  }

  updateProject(status?: CMProjectStatuses) {
    const { newName } = this.form.getRawValue();

    this.competenceService
      .updateProject({
        id: this.project.id,
        object_to: {
          sections_for_union: this.currentUnions.concat(this.currentSection.id),
        },
        spec: {
          [CMActions.CREATE_SECTIONS]: this.project.spec[CMActions.CREATE_SECTIONS].map((item) => {
            return {
              ...item,
              title: newName,
            };
          }),
        },
        status,
      } as Project)
      .subscribe((project) => {
        this.project = project;

        this.disableName();

        this.reloadEvent.emit();
        this.competenceService.projectSuccess(status);
        this.form.markAsPristine();
      }, this.competenceService.error);
  }

  select(event: MultiSelectListItem[]) {
    this.currentUnions = event.filter((item) => item.value).map((item) => +item.id);

    this.form.controls.sections.patchValue(this.currentUnions);

    this.form.markAsDirty();
  }

  changeName(value: string) {
    this.form.controls.newName.patchValue(value);
  }

  cancel() {
    if (!this.isNew) {
      const newName = this.project.spec[CMActions.CREATE_SECTIONS][0].title;
      const currentUnions = this.project.object_to.sections_for_union.filter((item) => item !== this.currentSection.id);

      this.currentUnions = currentUnions;
      this.newName = newName;
    } else {
      this.currentUnions = [];
      this.newName = '';
    }

    this.form.setValue({
      newName: this.newName,
      sections: this.currentUnions,
    });

    this.list.forEach((item) => {
      item.value = this.isNew ? false : this.currentUnions.includes(+item.id);
    });

    this.form.markAsPristine();
  }

  save(status: CMProjectStatuses) {
    if (this.isNew) {
      this.addProject(status);
    } else {
      this.updateProject(status);
    }
  }

  agree(status: CMProjectStatuses) {
    if (this.isNew) {
      this.addProject(status);
    } else {
      this.updateProject(status);
    }
  }

  archive() {
    this.warnChangeArchiveStatus(() => {
      this.updateProject(CMProjectStatuses.ARCHIVE);
    });
  }
}
