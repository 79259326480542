<div class="chat-section-select">
  <div class="row">
    <div
      *ngFor="let section of chatSections"
      (click)="onSectionSelect(section)"
      [ngClass]="section.isSelected ? 'active' : ''"
      title="{{ section.title }}"
      class="col-4 text-center chat__section-select chat__section-select"
    >
      <fa-icon [icon]="section.icon" *ngIf="section.icon" class="mr-1"></fa-icon>
      <span *ngIf="section.name === chatSectionsEnum.DUTY_TSO">ОТП</span>
      <span *ngIf="section.counter && !section.isSelected" class="unread-messages-count unread-messages-count-active">
        {{ section.counter }}
      </span>
    </div>
  </div>
</div>
