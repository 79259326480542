import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { User } from '@app/shared/models/user.model';
import { merge } from 'lodash-es';

@Component({
  selector: 'app-select-user-list',
  templateUrl: './select-user-list.component.html',
  styleUrls: ['./select-user-list.component.scss'],
})
export class SelectUserListComponent implements OnInit, OnChanges {
  @Input() employees: User[];
  @Input() user: User = null;

  @Input() isAccessChangeOptions = true;
  @Input() readonly = false;

  selectedEmployee: User = null;

  employeeInputValue = '';

  @Output() onChangeEvent = new EventEmitter<User>();

  constructor() {}

  ngOnInit() {}

  ngOnChanges() {}

  /**
   * Выбрать сотрудника
   * @param {User} item
   */
  selectEmployee(item: User) {
    this.selectedEmployee = item;
  }

  /**
   * Перенос выбранного сотрудника из левого списка в правый
   */
  move() {
    if (!this.selectedEmployee.id) {
      return;
    }

    this.user = merge({}, this.selectedEmployee);
    const index = this.employees.findIndex((employee) => this.selectedEmployee.id === employee.id);
    this.employees.splice(index, 1);
    this.selectedEmployee = this.resetSelectedUser();

    this.onChangeEvent.emit(this.user);
  }

  /**
   * Перенос выбранного кастомера из правого списка в левый
   */
  remove() {
    if (!this.user.id) {
      return;
    }

    this.employees = [].concat([this.user], this.employees);

    this.user = this.resetSelectedUser();

    this.onChangeEvent.emit(this.user);
  }

  /**
   * Сбросить выбор кастомера
   * @returns {User}
   */
  resetSelectedUser(): User {
    return merge({}, <User>{});
  }
}
