import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-boolean-filter',
  templateUrl: './boolean-filter.component.html',
  styleUrls: ['./boolean-filter.component.sass'],
})
export class BooleanFilterComponent {
  _selected = [];
  @Input() set selected(value: boolean) {
    if (value === undefined) {
      this._selected = [];
    } else {
      this._selected = [value];
    }
  }
  _list = [];
  @Input() set list(value: string[]) {
    this._list = [{ id: false, name: value[0] }, { id: true, name: value[1] }];
  }
  @Output() submitEvent = new EventEmitter<{ [key: string]: any[] }>();

  constructor() {}

  onSubmit({ value }) {
    if (value.length === 0 || value.length === 2) {
      this.submitEvent.emit({});
      return;
    }
    this.submitEvent.emit({ value: value[0] });
  }
}
