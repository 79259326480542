import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TextMaskModule } from 'angular2-text-mask';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faFileAlt,
  faTimes,
  faAngleDoubleDown,
  faExclamation,
  faPlus,
  faPencilAlt,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import { faFileWord, faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons';

import { NgbAccordionModule, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { SVGSpriteModule } from '@app/svg-sprite/svg-sprite.module';

import { SharedModule } from 'app/shared/shared.module';
import { DatePickerModule } from 'app/shared/components/ng2-datepicker/ng2-datepicker.module';
import { ProfileComponent } from './profile/profile.component';

import { PersonalDataService } from 'app/shared/services/personal-data.service';

import { ResidencyPipe } from '../shared/pipes/residency.pipe';
import { LegalStatusesPipe } from '../shared/pipes/legal-status.pipe';
import { ProfileContactComponent } from './profile-contact/profile-contact.component';
import { ProfileContractComponent } from './profile-contract/profile-contract.component';

import { ChangePasswordModalComponent } from '@app/profile/shared/components/change-password-modal/change-password-modal.component';
import { ChangeEmailModalComponent } from '@app/profile/shared/components/change-email-modal/change-email-modal.component';
import { ResetPasswordModalComponent } from '@app/profile/shared/components/reset-password-modal/reset-password-modal.component';
import { PasswordFormInputComponent } from '@app/profile/shared/components/password-form-input/password-form-input.component';
import { WelcomeModalComponent } from '@app/profile/shared/components/welcome-modal/welcome-modal.component';
import { WelcomeContractModalComponent } from '@app/profile/shared/components/welcome-contract-modal/welcome-contract-modal.component';
import { PreviewContentModalComponent } from '@app/profile/shared/components/preview-content-modal/preview-content-modal.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxMaskModule } from 'ngx-mask';
import { SetPasswordDialogComponent } from './shared/components/set-password-dialog/set-password-dialog.component';
import { ProfileDocumentsDialogComponent } from './shared/components/profile-documents-dialog/profile-documents-dialog.component';
import { LocationSelectComponent } from './shared/components/location-select/location-select.component';
import { SelectDocumentsDialogComponent } from './shared/components/select-documents-dialog/select-documents-dialog.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { FileManagerPublicModule } from '@app/file_manager/file-manager-public.module';

@NgModule({
  imports: [
    FormsModule,
    SharedModule,
    CommonModule,
    FontAwesomeModule,
    DatePickerModule,
    TextMaskModule,
    ReactiveFormsModule,
    SVGSpriteModule,
    NgbDatepickerModule,
    NgSelectModule,
    PdfViewerModule,
    NgxMaskModule.forChild(),
    FileManagerPublicModule,
    TranslateModule.forChild(),
    NgbAccordionModule,
    TextFieldModule,
  ],
  declarations: [
    ProfileComponent,
    ResidencyPipe,
    LegalStatusesPipe,
    ProfileContactComponent,
    ProfileContractComponent,
    ChangePasswordModalComponent,
    ChangeEmailModalComponent,
    ResetPasswordModalComponent,
    PasswordFormInputComponent,
    WelcomeModalComponent,
    WelcomeContractModalComponent,
    PreviewContentModalComponent,
    SetPasswordDialogComponent,
    ProfileDocumentsDialogComponent,
    LocationSelectComponent,
    SelectDocumentsDialogComponent,
  ],
  providers: [PersonalDataService],
  exports: [LegalStatusesPipe],
})
export class ProfileModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faFileAlt,
      faTimes,
      faAngleDoubleDown,
      faFileWord,
      faEdit,
      faExclamation,
      faPlus,
      faPencilAlt,
      faTrashAlt,
      faEye,
      faEyeSlash
    );
  }
}
