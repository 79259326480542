import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompetenceService } from '@app/+competence-map/services/competence.service';
import { Project } from '@app/+competence-map/models/projects.models';
import { CMCatalogTypes } from '@app/+competence-map/constants/sections.constants';
import { CMProjectStatuses } from '@app/+competence-map/constants/projects.constants';
import { switchMap, takeUntil } from 'rxjs/internal/operators';
import { of } from 'rxjs';
import { CompetenceSectionsService } from '@app/+competence-map/services/competence-sections.service';
import { DCTreeItem } from '@app/+competence-map/models/competence-map.models';
import { ProjectBase, ProjectBaseComponent } from '@app/+competence-map/models/project.class';
import { PortalService } from '@app/shared/services/portal.service';
import { AuthService } from '@app/shared/services/auth.service';
import { DestroyService } from '@app/services/destroy.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SectionUpdateParams } from '@app/+competence-map/models/sections.model';

@Component({
  selector: 'app-rename-section',
  templateUrl: './rename-section.component.html',
  styleUrls: ['./rename-section.component.scss'],
  providers: [PortalService, DestroyService],
})
export class RenameSectionComponent extends ProjectBaseComponent implements OnInit, ProjectBase {
  @Input() catalog: CMCatalogTypes;
  @Input() path: string;

  form: FormGroup;
  value: string;

  @Output() closeEvent = new EventEmitter();
  @Output() reloadEvent = new EventEmitter();

  constructor(
    private competenceService: CompetenceService,
    private competenceSectionsService: CompetenceSectionsService,
    private destroy$: DestroyService,
    private fb: FormBuilder,
    protected portalService: PortalService,
    protected authService: AuthService
  ) {
    super(portalService, authService);
  }

  get level(): number {
    if (this.project && this.project.spec[this.project.action]) {
      return this.project.spec[this.project.action].level;
    }
    return this.parent && this.parent.level && +this.parent.level;
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  private initializeForm(): void {
    if (!this.isNew) {
      this.value = (this.project && this.project.spec[this.project.action]?.title) || '';
      this.path = this.project.path;
    } else {
      this.value = this.parent.title;
    }

    this.form = this.fb.group({
      name: this.fb.control(this.value, Validators.required),
    });
  }

  addProject(status: CMProjectStatuses): void {
    const name = this.form.controls.name.value;
    this.competenceSectionsService
      .updateSection(this.parent.id.toString(), {
        title: name,
      } as SectionUpdateParams)
      .pipe(
        switchMap((project) => {
          if (!status) {
            return of(project);
          } else {
            return this.competenceService.updateProject({
              id: project.id,
              title: name,
              status,
            });
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (project) => {
          this.project = project;

          this.reloadEvent.emit();
          this.competenceService.projectSuccess(status);
          this.form.markAsPristine();
        },
        (err) => {
          this.competenceService.error(err);
          this.showInvalid(err);
        }
      );
  }

  updateProject(status?: CMProjectStatuses): void {
    const name = this.form.controls.name.value;
    if (!name) {
      return;
    }
    this.competenceService
      .updateProject({
        id: this.project.id,
        title: name,
        status,
      } as Project)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (project) => {
          this.project = project;

          this.reloadEvent.emit();
          this.competenceService.projectSuccess(status);
          this.form.markAsPristine();
        },
        (err) => {
          this.competenceService.error(err);
          this.showInvalid(err);
        }
      );
  }

  showInvalid(err: HttpErrorResponse) {
    if (err.error?.errors?.title) {
      this.form.controls.name.setErrors({ required: true });
    }
  }

  cancel() {
    this.initializeForm();
  }

  save(status: CMProjectStatuses) {
    if (this.isNew) {
      this.addProject(status);
    } else {
      this.updateProject(status);
    }
  }

  agree(status: CMProjectStatuses) {
    if (this.isNew) {
      this.addProject(status);
    } else {
      this.updateProject(status);
    }
  }

  archive() {
    this.warnChangeArchiveStatus(() => {
      this.updateProject(CMProjectStatuses.ARCHIVE);
    });
  }
}
