<div class="date-container">
  <div class="date-container__start" appTestLocator="double-datepicker-filter__date-from">
    <span class="padding-r-8">Даты с</span>
    <app-datepicker
      controlNameDate="fromDate"
      [showError]="false"
      [form]="form"
      [fieldParams]="{ dateLabel: fromDatePlaceholder }"
    ></app-datepicker>
  </div>
  <div class="date-container__end" appTestLocator="double-datepicker-filter__date-to">
    <span class="padding-r-8">по</span>
    <app-datepicker
      controlNameDate="toDate"
      [showError]="false"
      [form]="form"
      [fieldParams]="{ dateLabel: toDatePlaceholder }"
    ></app-datepicker>
  </div>
</div>

<div *ngIf="withName" appTestLocator="double-datepicker-filter__name">
  <app-livesearch-select
    [value]="localValue.name"
    [placeholder]="placeholder"
    [list]="filterSuggestions"
    (searchEvent)="search($event)"
    (selectEvent)="select($event)"
  >
  </app-livesearch-select>
</div>

<div class="d-flex justify-content-center action-btns">
  <button
    type="button"
    class="btn btn-dc-outline margin-r-30"
    (click)="cancel()"
    appTestLocator="double-datepicker-filter__cancel-button"
  >
    Отменить
  </button>
  <button
    type="button"
    class="btn btn-dc-primary"
    (click)="apply()"
    appTestLocator="double-datepicker-filter__confirm-button"
  >
    Применить
  </button>
</div>
