import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SVGFileComponent } from './components/svg-file/svg-file.component';
import { SVGIconComponent } from './components/svg-icon/svg-icon.component';
import { SVGSpriteLoaderService } from './services/svg-sprite-loader.service';

@NgModule({
  declarations: [SVGFileComponent, SVGIconComponent],
  exports: [SVGFileComponent, SVGIconComponent],
  imports: [CommonModule],
  providers: [SVGSpriteLoaderService],
})
export class SVGSpriteModule {}
