import { Component, Input, OnInit } from '@angular/core';
import { NotificationSettingModel, settingNotificationColumns } from '@app/notification/const/notification.const';
import { NotificationService } from '@app/notification/services/notification.service';

@Component({
  selector: 'app-notification-setting',
  templateUrl: './notification-setting.component.html',
  styleUrls: ['./notification-setting.component.scss'],
})
export class NotificationSettingComponent implements OnInit {
  @Input() dataSource: NotificationSettingModel[] = [];
  @Input() loading: boolean;
  columns = settingNotificationColumns;

  constructor(private notificationService: NotificationService) {}

  resetSetting() {
    this.notificationService.loadSetting();
  }

  saveSetting() {
    this.notificationService.saveSetting(this.dataSource);
  }

  ngOnInit() {
    this.notificationService.loadSetting();
  }
}
