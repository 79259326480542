<div class="fileman-top-icons">
  <div class="fileman-top-icons-container {{ layout }} padding-l-8">
    <div class="margin-r-18">
      <ng-container *ngFor="let button of buttons">
        <div class="ftpic-item" *ngIf="button.showed">
          <a class="ftpic-item-label" *ngIf="button.label">{{ button.label }}</a>
          <div container="body" triggers="mouseenter:mouseleave" placement="top" ngbTooltip="{{ button.name }}">
            <button class="btn-link" (click)="button.click()" [class.disabled]="button.disabled()">
              <app-svg-icon [height]="22" [name]="button.icon"></app-svg-icon>
              <span
                class="arrow-fill"
                *ngIf="
                  button.hasUpload &&
                  legends.length &&
                  (legends[legends.length - 1].status !== 100 || legends[legends.length - 1].error)
                "
                >{{ legends[legends.length - 1].status }}%</span
              >
            </button>
          </div>
        </div>
      </ng-container>
    </div>
    <div>
      <ng-container *ngFor="let button of buttonsNav">
        <div class="ftpic-item" *ngIf="button.showed" [class.hidden]="button.hidden()">
          <a class="ftpic-item-label" *ngIf="button.label">{{ button.label }}</a>
          <div container="body" triggers="mouseenter:mouseleave" placement="top" ngbTooltip="{{ button.name }}">
            <button class="btn-link" (click)="button.click()" [class.disabled]="button.disabled()">
              <app-svg-icon [height]="22" [name]="button.icon"></app-svg-icon>
              <span
                class="arrow-fill"
                *ngIf="
                  button.hasUpload &&
                  legends.length &&
                  (legends[legends.length - 1].status !== 100 || legends[legends.length - 1].error)
                "
                >{{ legends[legends.length - 1].status }}%</span
              >
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="fileman-top-selected-container padding-l-14 padding-t-6">
    <p [class.hidden]="selected.length == 0">
      выбрано элементов: {{ selected.length }} <a class="margin-l-30" (click)="resetSelected()">Отменить</a>
    </p>
  </div>
</div>

<app-modal-window
  [isShowed]="modal[hasEditForm ? 'edit' : 'add'].isOpen"
  backdrop="static"
  customClass="fileman-modal"
  [centered]="true"
  (closeEvent)="modal[hasEditForm ? 'edit' : 'add'].close()"
>
  <div>
    <div class="modal-header center">
      <div class="modal-title">{{ (hasEditForm ? 'fm_edit_title' : 'fm_add_title') | translate }}</div>
    </div>
    <div class="modal-body margin-b-46">
      <form [formGroup]="editForm">
        <dc-form-field-wrapper [style]="'material'" [vertically]="true">
          <dc-form-input [maxLength]="100" [isShowLength]="true">
            <label for="fmName" class="margin-b-10">{{ 'fm_name' | translate }}</label>
            <input
              formInput
              class="dc-input form-control"
              formControlName="name"
              id="fmName"
              placeholder="{{ (hasEditForm ? 'fm_edit_folder_name' : 'fm_add_folder_name') | translate }}"
              [attr.disabled]="false"
            />
          </dc-form-input>
        </dc-form-field-wrapper>
      </form>
    </div>
    <div class="modal-footer">
      <div class="flmmu-buttons dc-footer-buttons">
        <button class="btn btn-dc-outline" (click)="modal[hasEditForm ? 'edit' : 'add'].close()">
          {{ 'fm_cancel' | translate }}
        </button>

        <button
          class="btn btn-dc-primary"
          [disabled]="!editForm.valid || !editForm.dirty"
          (click)="modal[hasEditForm ? 'edit' : 'add'].action()"
        >
          {{ (hasEditForm ? 'fm_save' : 'fm_add_folder') | translate }}
        </button>
      </div>
    </div>
  </div>
</app-modal-window>

<app-modal-window
  [isShowed]="modal.archive.isOpen"
  (closeEvent)="modal.archive.close()"
  [customClass]="'fileman-modal-zips'"
>
  <div class="container-fluid">
    <div class="fileman-modal-zips-wrapper">
      <div class="fileman-modal-zips-item text-center">
        <b>{{ 'fm_download_archive' | translate }}</b>
      </div>
      <div class="fileman-modal-zips-item percentage">
        <div
          class="flmm-p-item"
          *ngFor="let archive of getArchives() | USER_FILE_ZIP_SORT"
          (click)="downloadArchive(archive)"
        >
          <div class="flmm-p-item-title">{{ archive.created_at | date: 'dd.MM.yy HH:mm:ss' }}</div>
          <div class="text-right">
            <a *ngIf="archive.requested">
              <fa-icon [icon]="['fas', 'download']"></fa-icon>
            </a>
          </div>
        </div>
      </div>
      <div class="fileman-modal-zips-item self-grid text-center">
        <div class="flmmu-buttons dc-footer-buttons">
          <button class="btn btn-dc-outline" (click)="modal.archive.close()">{{ 'fm_close' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</app-modal-window>

<app-modal-window [isShowed]="modal.upload.isOpen" (closeEvent)="modal.upload.close()">
  <div class="container-fluid">
    <div class="fileman-modal-upload">
      <div class="fileman-modal-upload-item text-center">
        <b>{{ 'fm_download_title' | translate }}</b>
      </div>
      <div class="fileman-modal-upload-item upload-field-container">
        <div class="flmui-upload-field text-center">
          <input type="file" id="fileform2" (change)="modal.upload.action($event)" multiple />
          {{ 'fm_download_dnd' | translate }}
        </div>
      </div>
      <div class="fileman-modal-upload-item self-grid text-center">
        <div class="flmmu-buttons dc-footer-buttons">
          <button class="btn btn-dc-outline" (click)="modal.upload.close()">{{ 'fm_close' | translate }}</button>
        </div>
        <div class="flmmu-buttons dc-footer-buttons">
          <input type="file" id="fileform" (change)="modal.upload.action($event)" />
          <button class="btn btn-dc-primary">{{ 'fm_download_chose' | translate }}</button>
        </div>
      </div>
      <div class="fileman-modal-upload-item text-center">
        <a (click)="hasLegend = !hasLegend"
          ><b>{{ 'fm_download_legend' | translate }}</b
          >&nbsp;&nbsp;<fa-icon [icon]="['fas', hasLegend ? 'chevron-down' : 'chevron-up']"></fa-icon>
        </a>
      </div>
      <div class="fileman-modal-upload-item percentage" *ngIf="hasLegend">
        <div class="flmm-p-item" *ngFor="let legend of legends">
          <div class="flmm-p-item-title">{{ legend.file.name }}</div>
          <div class="text-right">
            <a (click)="abort($event, legend)" *ngIf="!legend.ready">
              <fa-icon [icon]="['fas', 'times']"></fa-icon>
            </a>
          </div>
          <div class="percentage-container">
            <div
              class="percentage-c-fill"
              [ngStyle]="{ width: (legend.error ? 100 : legend.status) + '%' }"
              [ngClass]="{ 'per-100red': legend.error }"
            ></div>
            <div class="percentage-c-percent">{{ legend.error ? legend.error : legend.status + '%' }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-modal-window>

<app-modal-window customClass="fileman-modal" [isShowed]="modal.delete.isOpen" (closeEvent)="modal.delete.close()">
  <div class="modal-header">
    <div class="modal-title">{{ 'fm_delete_title' | translate }} {{ getFilesNameBySelected() }}?</div>
  </div>
  <div class="modal-body margin-t-26 margin-b-50">{{ 'fm_delete_body' | translate }}</div>
  <div class="modal-footer">
    <div class="dc-footer-buttons">
      <button class="btn btn-dc-outline" (click)="modal.delete.close()">{{ 'fm_cancel_approve' | translate }}</button
      >&nbsp;
      <button class="btn btn-dc-primary" (click)="modal.delete.action()">{{ 'fm_delete_approve' | translate }}</button>
    </div>
  </div>
</app-modal-window>
