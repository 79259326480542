<div (click)="cancel()" class="dc-fileman-modal-x-button">
  <app-svg-icon [width]="20" [height]="20" name="close"></app-svg-icon>
</div>
<div class="modal-header">
  <div class="modal-title"></div>
</div>
<div class="modal-body">
  <div>Внимание!</div>
  <div>Архив для скачивания сформирован. Ссылка на него будет доступна в течение трёх суток.</div>

  <div>Вы хотите скачать архив сейчас?</div>
</div>
<div class="modal-footer">
  <div class="dc-footer-buttons">
    <button class="btn btn-dc-outline" (click)="cancel()">Нет, позже</button>
    <button class="btn btn-dc-primary" (click)="download()">Да, скачать</button>
  </div>
</div>
