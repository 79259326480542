import { CatalogTab } from '../models/competence-map.models';
import { CMSectionStatuses } from '@app/+competence-map/constants/sections.constants';

export const COMPETENCE_TABS: CatalogTab[] = [
  {
    icon: 'case',
    label: 'Каталог товаров',
    title: 'Редактор карты компетенций',
    value: 'product_catalog',
    routes: ['catalog', 'goods'],
  },
  {
    icon: 'truck',
    label: 'Каталог работ и услуг',
    title: 'Редактор карты компетенций',
    value: 'job_catalog',
    routes: ['catalog', 'services'],
  },
  {
    icon: 'pages',
    label: 'БШФ',
    title: 'БШФ',
    value: 'filter_templates',
    routes: ['filter-templates'],
  },
  {
    icon: 'pages',
    label: 'БПФ',
    title: 'БПФ',
    value: 'filters',
    routes: ['filters'],
  },
  {
    icon: 'book',
    label: 'БД слов',
    title: 'БД слов',
    value: 'words_storage',
    routes: ['words-storage'],
  },
  {
    icon: 'pencil',
    label: 'БД единиц',
    title: 'БД единиц',
    value: 'units_storage',
    routes: ['units-storage'],
  },
  {
    icon: 'unlock',
    label: 'Права',
    title: 'Права',
    value: 'access',
    routes: ['access-control'],
  },
  {
    icon: 'list',
    label: 'Проекты',
    title: 'Журнал изменений карты компетенций',
    value: 'my_projects',
    routes: ['my-projects'],
  },
];

export const MY_COMPETENCE_TABS: CatalogTab[] = [
  {
    icon: 'case',
    label: 'Каталог товаров',
    title: 'Мои Компетенции',
    value: 'product_catalog',
  },
  {
    icon: 'truck',
    label: 'Каталог работ и услуг',
    title: 'Мои Компетенции',
    value: 'job_catalog',
  },
];

export const CATALOG_LABELS = {
  goods: 'КТ',
  services: 'КРУ',
};

export const CATALOG_LABELS_FULL = {
  goods: 'КАТАЛОГ ТОВАРОВ',
  services: 'КАТАЛОГ УСЛУГ',
};

export const SUPPLIERS_SELECTION_TABS: CatalogTab[] = [
  {
    icon: 'case',
    label: 'Каталог товаров',
    title: 'Выбор поставшиков',
    value: 'product_catalog',
  },
  {
    icon: 'truck',
    label: 'Каталог работ и услуг',
    title: 'Выбор поставшиков',
    value: 'job_catalog',
  },
  {
    label: 'Дополнительные фильтры',
    title: 'Выбор поставшиков',
    value: 'additional-filters',
  },
];

export const SECTION_STATUSES = [
  {
    label: 'Актив',
    value: CMSectionStatuses.ACTIVE,
    class: 'status-green',
  },
  {
    label: 'Просмотр',
    value: CMSectionStatuses.DRAFT,
    class: 'status-yellow',
  },
  {
    label: 'Архив',
    value: CMSectionStatuses.ARCHIVE,
    class: 'status-red',
  },
];

export enum LexiconStatuses {
  ACTIVE = 'active',
  ARCHIVE = 'archive',
}

type CMStatuses = 'active' | 'archive';

type CMStatus = { [key in CMStatuses]: string };

export const CMStatusLabels: CMStatus = {
  [LexiconStatuses.ACTIVE]: 'Актив',
  [LexiconStatuses.ARCHIVE]: 'Актив',
};

export const COMMENTS_MAPPING: Record<string, string> = {
  one: 'комментарий',
  few: 'комментария',
  other: 'комментариев',
};

export type ProjectCommentType = 'deleted' | 'changed' | 'not_changed' | 'created';

export enum ProjectCommentAction {
  deleted = 'deleted', // удалил
  CHANGED = 'changed', // изменил
  NOT_CHANGED = 'not_changed', // ничего не изменил
  CREATED = 'created', // создал
}
