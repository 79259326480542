import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CMActions, CMProjectStatuses } from '@app/+competence-map/constants/projects.constants';
import { UnitsStatuses } from '@app/+competence-map/constants/units.constants';
import { ProjectBaseComponent, ProjectBase } from '@app/+competence-map/models/project.class';
import { Project } from '@app/+competence-map/models/projects.models';
import { CompetenceService } from '@app/+competence-map/services/competence.service';
import { MultiSelectListItem } from '@app/shared/models/multi-select-list-item';
import { AuthService } from '@app/shared/services/auth.service';
import { PortalService } from '@app/shared/services/portal.service';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-change-status-unit',
  templateUrl: './change-status-unit.component.html',
  styleUrls: ['./change-status-unit.component.scss'],
  providers: [PortalService],
})
export class ChangeStatusUnitComponent extends ProjectBaseComponent implements OnInit, ProjectBase {
  @Input() unitId: number;
  @Input() status: UnitsStatuses;

  statuses: MultiSelectListItem[] = [
    {
      id: UnitsStatuses.ACTIVE,
      name: 'Актив',
      value: UnitsStatuses.ACTIVE,
      icon: ['fas', 'circle'],
      classes: ['status-green'],
    },
    {
      id: UnitsStatuses.ARCHIVE,
      name: 'Архив',
      value: UnitsStatuses.ARCHIVE,
      icon: ['fas', 'circle'],
      classes: ['status-red'],
    },
  ];

  form: FormGroup;

  constructor(
    private competenceService: CompetenceService,
    private fb: FormBuilder,
    protected portalService: PortalService,
    protected authService: AuthService
  ) {
    super(portalService, authService);
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    console.log(this.project);

    this.form = this.fb.group({
      status: [this.status || this.project.spec[CMActions.DELETE_UNITS_MEASURE].status],
    });
  }

  addProject(status?: CMProjectStatuses): void {
    this.competenceService
      .updateUnit(this.unitId, {
        status: this.form.value.status,
      })
      .pipe(
        switchMap((project) => {
          if (!status) {
            return of(project);
          } else {
            return this.competenceService.updateProject({
              id: project.id,
              status,
            });
          }
        })
      )
      .subscribe((project) => {
        this.project = project;

        this.reloadEvent.emit();

        this.competenceService.projectSuccess(status);
      }, this.competenceService.error);
  }

  updateProject(status?: CMProjectStatuses): void {
    this.competenceService
      .updateProject({
        id: this.project.id,
        status,
        spec: {
          [CMActions.DELETE_UNITS_MEASURE]: {
            ...this.project.spec[CMActions.DELETE_UNITS_MEASURE],
            status: this.form.value.status,
          },
        },
      } as Project)
      .subscribe((project) => {
        this.project = project;

        this.reloadEvent.emit();
        this.competenceService.projectSuccess(status);
      }, this.competenceService.error);
  }

  cancel() {
    this.form.controls.status.patchValue(this.status || this.project.spec[CMActions.DELETE_UNITS_MEASURE].status);
    this.form.markAsPristine();
  }

  save(status: CMProjectStatuses) {
    if (this.isNew) {
      this.addProject(status);
    } else {
      this.updateProject(status);
    }
  }

  agree(status: CMProjectStatuses) {
    if (this.isNew) {
      this.addProject(status);
    } else {
      this.updateProject(status);
    }
  }

  archive() {
    this.warnChangeArchiveStatus(() => {
      this.updateProject(CMProjectStatuses.ARCHIVE);
    });
  }

  selectStatus(status) {
    this.form.controls.status.patchValue(status);
    this.form.markAsDirty();
  }
}
