<div class="section-header">
  <div>
    <div class="section-header-row" appTestLocator="rename-section__project">
      <div class="section-header-label">Проект ID:</div>
      <div class="section-header-value">{{ projectId }}</div>
    </div>
    <div class="section-header-row" appTestLocator="rename-section__status">
      <div class="section-header-label">Статус:</div>
      <div class="section-header-value d-flex align-items-center">
        {{ projectStatus | cmProjectStatus }}&nbsp;&nbsp;
        <app-svg-icon name="change" width="16" class="color-brand-light"></app-svg-icon>
      </div>
    </div>
    <div class="section-header-row" appTestLocator="rename-section__author">
      <div class="section-header-label">Автор:</div>
      <div class="section-header-value">{{ userAuthor | roleName }}</div>
    </div>
    <div class="section-header-row" appTestLocator="rename-section__date">
      <div class="section-header-label">Дата:</div>
      <div class="section-header-value">{{ projectDate | dateFormat }}</div>
    </div>
  </div>
  <div>
    <div class="section-header-text" appTestLocator="rename-section__title">
      Переименовать раздел<br />
      {{ level }} уровня
    </div>
  </div>
  <div>
    <div
      class="section-header-archive"
      (click)="archive()"
      [class.disabled]="isArchiveDisabled()"
      appTestLocator="rename-section__archive"
    >
      <app-svg-icon name="trash" width="16"></app-svg-icon>
      <span>Перенести в архив</span>
    </div>
  </div>
</div>

<div class="modal-body" [class.disabled]="isDataDisabled()">
  <form [formGroup]="form" class="section-form">
    <div class="section-header-row section-path" appTestLocator="rename-section__path">
      <div class="section-header-label">Путь:</div>
      <div class="section-header-value">{{ path }}</div>
    </div>

    <div class="section-form-fields">
      <dc-form-field-wrapper [style]="'material'" [vertically]="true" [required]="true">
        <dc-form-input [maxLength]="30" [isShowLength]="true">
          <input
            formInput
            formControlName="name"
            placeholder="Введите новое название раздела"
            appTestLocator="rename-section__input"
          />
        </dc-form-input>
      </dc-form-field-wrapper>
    </div>
  </form>
</div>

<app-operation-modal-footer
  (onCancel)="cancel()"
  (onSave)="save($event)"
  (onAgree)="agree($event)"
  [project]="project"
  [form]="form"
>
</app-operation-modal-footer>
