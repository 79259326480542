import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

import { ROLES } from '@app/shared/constants/roles.constants';

import { ChatService } from '@app/chat/services/chat.service';
import { SocketDataService } from '@app/services/socket-data.service';
import { ChatRoom } from '@app/chat/models/chat.model';
import { Role } from '@app/shared/models/user.model';
import { ChatSectionsEnum, TradeRoomsTypes } from '@app/chat/constants/chat-sections.constants';

@Component({
  selector: 'app-chat-theme',
  templateUrl: './chat-theme.component.html',
  styleUrls: ['./chat-theme.component.scss'],
})
export class ChatThemeComponent implements OnInit, OnDestroy {
  @Input() userId;
  @Input() group: ChatRoom;
  @Input() theme: ChatRoom;
  @Input() contactSelected: ChatRoom;

  roles: Role[] = ROLES;
  chatSectionSelected;
  isReallyRemoving = false;
  chatSectionsEnum = ChatSectionsEnum;
  tradeRoomsTypes = TradeRoomsTypes;
  themeSelected: ChatRoom;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private chatService: ChatService, private chatDataService: SocketDataService) {}

  ngOnInit() {
    this.chatSectionSelected = this.chatService.getChatSectionSelected();
    this.chatService.chatSectionSelectedChanged
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((chatSectionSelected) => (this.chatSectionSelected = chatSectionSelected));

    this.chatService.themeSelectedChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((themeSelected) => {
      this.themeSelected = themeSelected;
    });
  }

  onThemeSelect(theme: ChatRoom) {
    console.log('SELECTED THEME', theme);
    this.chatService.setRoomSelected(theme);
    this.chatService.focusChanged.next(true);
    if (theme.counter) {
      this.chatDataService.setMessageRead(theme.room_id);
    }
  }

  onThemeUsersToggle() {
    this.chatService.toggleThemeOpen(this.theme, this.chatService.getChatSectionSelected().name);
  }

  onThemeEdit() {
    this.chatService.setEditingGroupOrThemeObject({
      ...this.theme,
      group: this.group,
    });

    this.chatService.isEditingGroupOrThemeChanged.next(true);
  }

  onRemoveThemeAsk() {
    this.isReallyRemoving = true;
  }

  onRemoveTheme() {
    if (this.chatSectionSelected.name === this.chatSectionsEnum.TRADE) {
      this.chatDataService.removeTradeTopic({
        id: this.theme.id,
      });
    } else {
      this.chatDataService.removeTopic({
        topic_id: this.theme.id,
        section: this.chatService.getChatSectionSelected(),
        room_id: this.theme.room_id,
      });
    }

    this.isReallyRemoving = false;
  }

  canEditTheme() {
    return (
      (this.userId === this.group.owner_id &&
        ((this.chatSectionSelected.name === this.chatSectionsEnum.TRADE &&
          (this.theme.type === this.tradeRoomsTypes.CUSTOMER_OTHER ||
            this.theme.type === this.tradeRoomsTypes.PROVIDER_OTHER)) ||
          this.chatSectionSelected.name === this.chatSectionsEnum.ADMIN ||
          this.chatSectionSelected.name === this.chatSectionsEnum.HOLDING)) ||
      (this.chatSectionSelected.name === this.chatSectionsEnum.TECH && this.userId === this.group.owner_id)
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
