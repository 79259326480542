import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { UserFile } from '../models/user-file.model';
import { Subject, of } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-file-manager-tree',
  templateUrl: './file-manager-tree.component.html',
  styleUrls: ['./file-manager-tree.component.scss'],
})
export class FileManagerTreeComponent implements OnDestroy {
  @Input() files: UserFile[];
  @Input() current: UserFile;
  @Input() searchQuery: string;
  @Input() isSearch: boolean;
  @Input() searchResults: number[];

  @Output() onOpen = new EventEmitter();
  @Output() onShare = new EventEmitter();

  private dbClick$: Subject<boolean> = new Subject();

  constructor(private notify: NotificationsService) {}

  ngOnDestroy() {
    this.dbClick$.complete();
  }

  share($event: UserFile[]): void {
    this.onShare.emit($event);
  }

  toggleInfoUser(file: UserFile): void {
    file.showUserInfo = !file.showUserInfo;
  }

  toggleOpen(file: UserFile, dbclick?: boolean): void {
    dbclick = dbclick || false;
    if (dbclick) {
      this.dbClick$.next(true);
      if (this.isSearchResults(file)) {
        this.notify.warn('Событие', 'Данной папки (файла) нет среди результатов поиска');
        return;
      }
      this.onOpen.emit({
        file: file,
        setCurrent: true,
      });
    } else {
      of(null)
        .pipe(delay(250), takeUntil(this.dbClick$))
        .subscribe(() => {
          if (this.isSearchResults(file)) {
            this.notify.warn('Событие', 'Данной папки (файла) нет среди результатов поиска');
            return;
          }
          this.onOpen.emit({
            file: file,
            setCurrent: false,
          });
        });
    }
  }

  open($event) {
    this.onOpen.emit($event);
  }

  getDirs(file: UserFile) {
    return !file.hasFile();
  }

  isSearchResults(file: UserFile): boolean {
    if (!this.isSearch) {
      return false;
    }
    return !(this.isSearch && this.searchResults.includes(file.id));
  }
}
