import {
  CopyFilterTemplateParams,
  CreateOrChangeFilterTemplateParams,
  FilterTemplate,
} from '../models/filter-templates.model';
import { uniqueId } from 'lodash-es';
import { FilterTemplateStatuses } from '@app/+competence-map/constants/filter-templates.constants';

export interface SelectOptions<T = unknown> {
  value: T;
  id: string;
  label: string;
}

export class FilterTemplatesHelper {
  static getTreeSectionFilter(
    filterTemplateParams: CopyFilterTemplateParams,
    mode: 'set' | 'get' = 'set',
    includeChildren: boolean = true
  ): CopyFilterTemplateParams {
    const result: CopyFilterTemplateParams = {} as CopyFilterTemplateParams;
    if (filterTemplateParams?.node_id) {
      result.node_id = filterTemplateParams?.node_id;
    } else if (mode === 'set') {
      result.node_id = `virtual_${uniqueId()}`;
    } else {
      result.node_id = null;
    }

    result.node_info = filterTemplateParams?.node_info;
    if (filterTemplateParams?.donor_node_id) {
      result.donor_node_id = filterTemplateParams?.donor_node_id;
    }
    delete result.node_info.created_at;

    if (mode === 'get' && typeof result?.node_id !== 'number' && result?.node_id?.includes('virtual')) {
      result.node_id = null;
    }

    if (filterTemplateParams.childes) {
      result.childes = [];
      if (includeChildren) {
        filterTemplateParams.childes.forEach((item) =>
          result.childes.push(FilterTemplatesHelper.getTreeSectionFilter(item, mode, includeChildren))
        );
      }
    }
    return result;
  }

  static selectorsFilter<T>(value: string, list: T[], field: string, sortField?: string): SelectOptions[] {
    const filterValue = value ? value.toString().toLowerCase() : value;

    const selectOptions = list.reduce((acc, current) => {
      acc.push({ id: current['id'], value: current, label: current[field] });
      return acc;
    }, []);

    const result =
      sortField !== undefined
        ? selectOptions.sort((a, b) => a[sortField.toLowerCase()] - b[sortField.toLowerCase()])
        : selectOptions;
    return result.filter((field1) => field1.label.toLowerCase().indexOf(filterValue) === 0);
  }

  static selectorsFilterMultiField<T>(value: string, list: T[], fields: string[], sortField?: string): SelectOptions[] {
    const filterValue = value ? value.toString().toLowerCase() : value;

    const selectOptions = list.reduce((acc, current) => {
      let label = '';
      fields.forEach((item, index) => {
        label += current[item];
        if (fields.length - 1 !== index) {
          label += ', ';
        }
      });
      acc.push({
        id: current['id'],
        value: current,
        label,
      });
      return acc;
    }, []);

    const result =
      sortField !== undefined
        ? selectOptions.sort((a, b) => a[sortField.toLowerCase()] - b[sortField.toLowerCase()])
        : selectOptions;
    return result.filter((field1) => field1.label.toLowerCase().indexOf(filterValue) === 0);
  }

  static transformToFilterTemplate(
    treeItem: CreateOrChangeFilterTemplateParams,
    isExpanded = false,
    treeLevel = null
  ): FilterTemplate {
    const virtualLevel = treeItem?.node_info?.level === 1 ? 2 : treeLevel;
    const nextTreeLevel = virtualLevel ? virtualLevel + 1 : null;

    return {
      ...treeItem?.node_info,
      id: treeItem.node_info.level === 1 ? String(treeItem?.node_id) : null,
      node_id: treeItem?.node_id,
      children: treeItem?.childes?.length
        ? treeItem.childes.map((value) => this.transformToFilterTemplate(value, isExpanded, nextTreeLevel))
        : [],
      isExpanded,
      virtualLevel,
    };
  }

  static isUpdateChildNodes(oldStatus: FilterTemplateStatuses, newStatus: FilterTemplateStatuses): boolean {
    if (oldStatus === FilterTemplateStatuses.ACTIVE && newStatus === FilterTemplateStatuses.DRAFT) {
      return true;
    }
    return oldStatus === FilterTemplateStatuses.DRAFT && newStatus === FilterTemplateStatuses.ARCHIVE;
  }

  static findFilterTemplateNode(list: FilterTemplate[], id: string): FilterTemplate | undefined {
    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      if (item.id === id || item.node_id === id) {
        return item;
      }
      if (item?.children?.length) {
        return this.findFilterTemplateNode(item?.children, id);
      }
    }
  }

  static FilterTemplateParamsNode(
    list: CreateOrChangeFilterTemplateParams[],
    id: string
  ): CreateOrChangeFilterTemplateParams | undefined {
    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      if (item.node_id === id) {
        return item;
      }
      if (item?.childes?.length) {
        return this.FilterTemplateParamsNode(item?.childes, id);
      }
    }
  }
}
