<div class="view-week" id="view-week" (scroll)="recountEventStateDeb()">

  <table>
    <thead>
    <tr>
      <th></th>
      <th *ngFor="let day of calendarData">
        <div class="view-week-header" [ngClass]="{
        'current-day-view-week' : day.current,
        'old-month-view-week' : day.oldMonth
        }">
          {{day.dayOfWeek}} {{day.dayNumber}}
          <ng-container *ngIf="eventForHour[day.day]">
            <span *ngIf="eventForHour[day.day].total"
                  [ngClass]="eventForHour[day.day].total > 9 ? 'big_number' : ''"
            >{{eventForHour[day.day].total}}</span>
            <app-svg-icon [height]="12" [width]="16" name="check" class="green"
                          *ngIf="eventForHour[day.day].total && eventForHour[day.day].state == state_day.FINISH">
            </app-svg-icon>
          </ng-container>
        </div>
        <div class="view-week-color-block" *ngIf="eventForHour[day.day] && eventForHour[day.day].total">
          <ng-container *ngFor="let color of listState">
            <div
              [style.border-color]="color.color"
              [style.background-color]="upStateList[day.day] && upStateList[day.day][color.key] ? color.color : 'unset'">
            </div>
          </ng-container>
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let hour of range(24)">
      <tr class="view-week-days">
        <td class="view-week-days-hour" [ngClass]="{ 'week-current': currentHour == hour && currentDayOnCalendar }">
          {{hour}}:00
        </td>
        <td
          *ngFor="let day of calendarData; let i = index"
          [class]="day.class"
          id="row_{{hour}}"
          [ngClass]="{
              'week-current': currentHour == hour && currentDayOnCalendar,
              'day-of-week-current': currentDay == day.dayNumber && currentDayOnCalendar,
              'day-off': i > 4
            }"
        >
          <div class="view-week-days-hour-body">
            <div class="view-week-days-hour-body-header row">
              <div class="col-12">
                <app-svg-icon [height]="12" [width]="16" name="check" class="green"
                              *ngIf="eventForHour[day.day] && eventForHour[day.day][hour].list.length && eventForHour[day.day][hour]?.state == state_day.FINISH">
                </app-svg-icon>
                <span *ngIf="eventForHour[day.day] && eventForHour[day.day][hour].list.length">{{ eventForHour[day.day][hour].list.length}}</span>
                <app-svg-icon
                  [height]="14"
                  [width]="14"
                  name="plus"
                  class="pointer change-color hover-color"
                  [ngClass]="!checkDay(day.dayForDatepicker, hour) ? 'disabled' : ''"
                  (click)="checkDay(day.dayForDatepicker, hour) && addEvent(day.dayForDatepicker, hour)"
                ></app-svg-icon>
              </div>
            </div>
            <ng-container *ngIf="eventForHour[day.day] && eventForHour[day.day][hour]?.list">
              <div
                *ngFor="let event of eventForHour[day.day][hour].list.slice(0, maxLengthList)"
                [style.color]="state.UNDER_CONTROL == event.status ? '#fff' : ''"
                (click)="infoForDay($event, day)"
                class="view-week-days-hour-body-events pointer"
                   [style.background-color]="!finishState.includes(event.status) ? setColor(event.status) : 'unset'">
                <span [ngbTooltip]="findStateHelper(event.status)" [style.background-color]="finishState.includes(event.status) ? setColor(event.status) : 'unset'">
                  {{event.start_time.substr(0, 5)}}</span
                >&nbsp;<span>{{event.title}}</span>
              </div>

              <app-svg-icon *ngIf="eventForHour[day.day][hour].list.length > maxLengthList" (click)="infoForDay($event, day)"
                [height]="8" [width]="12" name="down" class="pointer low-height hover-color"></app-svg-icon>
            </ng-container>
          </div>
        </td>
      </tr>
    </ng-container>

    </tbody>
    <tfoot>
    <tr>
      <td></td>
      <td *ngFor="let day of calendarData">
        <div class="view-week-color-block" *ngIf="eventForHour[day.day] && eventForHour[day.day].total">
          <ng-container *ngFor="let color of listState">
            <div
              [style.border-color]="color.color"
              [style.background-color]="downStateList[day.day] && downStateList[day.day][color.key] ? color.color : '#fff'">
            </div>
          </ng-container>
        </div>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
