<app-modal-window
  #modalAddEvent
  [idModalInjector]="id"
  [customClass]="'modal_add_event'"
  [preventClose]="true"
  [keyboard]="false"
  [backdrop]="'static'"
  [isShowed]="isShowed"
  (closeEvent)="close()"
  (outClick)="close()"
>
  <div class="add-event">
    <div class="text-center add-event-header">
      <h2>{{ copy ? 'Копировать событие' : idEvent ? 'Редактировать событие' : 'Создать событие' }}</h2>
    </div>
    <form [formGroup]="form" (ngSubmit)="addEvent()">
      <div class="row">
        <div class="col-8 add-event-main-info">
          <dc-form-field-wrapper class="add-event-title mb-4 mt-0" [style]="'bootstrap'">
            <dc-form-label>Название</dc-form-label>
            <dc-form-input [maxLength]="50" [isShowLength]="true">
              <textarea formInput formControlName="title" rows="2" placeholder="Введите название события"></textarea>
            </dc-form-input>
            <div *ngIf="form.get('title').touched && form.get('title').invalid" class="form-field-error">
              {{ messages.required }}
            </div>
          </dc-form-field-wrapper>
          <dc-form-field-wrapper class="add-event-description mb-4" [style]="'bootstrap'">
            <dc-form-label>Описание</dc-form-label>
            <dc-form-input [maxLength]="300" [isShowLength]="true">
              <textarea
                formInput
                formControlName="description"
                rows="5"
                placeholder="Введите описание события"
              ></textarea>
            </dc-form-input>
            <div *ngIf="form.get('description').touched && form.get('description').invalid" class="form-field-error">
              {{ messages.required }}
            </div>
          </dc-form-field-wrapper>
        </div>
        <div class="col-4 add-event-state-info">
          <span>Статус</span>
          <div class="color-legend">
            <ul>
              <ng-container *ngFor="let state of listState">
                <li
                  (click)="(idEvent || !state.disable_create) && setState(state.key)"
                  [ngClass]="{ disable_state: !idEvent && state.disable_create }"
                >
                  <span
                    [style.background-color]="state.color"
                    [class.active]="state.key == form.get('status').value"
                  ></span>
                  {{ state.text }}
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-4 add-event-start_date">
          <div style="max-width: 280px">
            Начало события
            <app-datepicker
              [controlNameDate]="'start_date'"
              [controlNameTime]="'start_time'"
              [form]="form"
              [timeRequire]="false"
            ></app-datepicker>
          </div>
        </div>
        <div class="col-4 add-event-end_date">
          <ng-container
            *ngIf="form.get('repeat_type').value !== this.repeatSelectList[0].value; else endDateNoRequired"
          >
            Окончание события
            <app-datepicker
              [controlNameDate]="'end_date'"
              [controlNameTime]="'end_time'"
              [form]="form"
              [timeRequire]="false"
            ></app-datepicker>
          </ng-container>
          <ng-template #endDateNoRequired>
            Окончание события
            <app-datepicker
              [controlNameDate]="'end_date'"
              [controlNameTime]="'end_time'"
              [form]="form"
              [timeRequire]="false"
              [dateRequire]="false"
            ></app-datepicker>
          </ng-template>
        </div>
        <div class="col-4 add-event-repeat_select">
          Повторение
          <ng-select
            #repeatSelect
            class="bootstrap-select"
            formControlName="repeat_type"
            [clearable]="false"
            [items]="repeatSelectList"
            [closeOnSelect]="false"
            dropdownPosition="bottom"
            bindValue="value"
          >
            <ng-template ng-label-tmp let-item="item">
              {{ item.name }}
              <ng-container *ngIf="item.value === daysOfTheWeek">
                {{ getNameDay() }}
              </ng-container>
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
              <div (click)="item.value !== daysOfTheWeek && closeDropdown()" class="ng-option-elem">
                {{ item.name }}
              </div>
              <div *ngIf="item.value === daysOfTheWeek" class="days_of_the_week">
                <ng-container *ngFor="let day of repeatSelectDayList">
                  <div
                    (click)="selectDayRepeat(day.value)"
                    class="day_repeat_on_select"
                    [style.font-weight]="day.fontWeight || 'initial'"
                    [ngClass]="{ active_day_repeat: form.get('days_of_the_week').value.includes(day.value) }"
                  >
                    {{ day.name }}
                  </div>
                </ng-container>
                <div class="button_ok_on_repeat_select">
                  <app-svg-icon [height]="15.5" [width]="15.5" name="ok" (click)="closeDropdown()"></app-svg-icon>
                </div>
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="dc-footer-buttons margin-btn">
        <button class="btn btn-dc-outline" type="button" (click)="close()">Отменить</button>
        <button class="btn btn-dc-primary" type="submit" [disabled]="checkBackup()">Сохранить</button>
      </div>
    </form>
  </div>
</app-modal-window>

<app-modal-window
  [customClass]="'modal_delete_event'"
  [backdrop]="'static'"
  [isShowed]="showCloseModal"
  (closeEvent)="closeHelper()"
  [centered]="true"
>
  <div class="close-event">
    <div class="close-event-text">
      Вы действительно хотите закрыть эту страницу?
      <br /><br />
      Введённые данные не будут сохранены!
    </div>
    <div class="modal-footer">
      <button class="btn btn-dc-outline" (click)="closeHelper()">Нет, отменить</button>
      <button class="btn btn-dc-primary" (click)="closeHelper(true)">Да, закрыть</button>
    </div>
  </div>
</app-modal-window>
