import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterSimple',
})
export class FilterSimplePipe implements PipeTransform {
  transform(items: string[], value: string): any {
    if (!value) {
      return items;
    }
    const lowerCaseValue = value.toLowerCase();
    return [...items].filter((item) => {
      return item.toLowerCase().includes(lowerCaseValue);
    });
  }
}
