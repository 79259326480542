<form [formGroup]="form">
  <div class="d-flex">
    <div class="mr-3">
      <app-form-range-slider
        *ngIf="form && node.template_filter"
        [readonly]="readonly"
        [setValueFrom]="competencyData ? competencyData?.value_range_from || 0 : node?.range_from"
        [setValueTo]="competencyData ? competencyData?.value_range_to || 0 : node?.range_to"
        [minRange]="competencyData ? node?.range_from : node.template_filter?.default_range_from"
        [maxRange]="competencyData ? node?.range_to : node.template_filter?.default_range_to"
        (changeRange)="setRangeValues($event)"
      ></app-form-range-slider>
    </div>
    <div class="d-flex align-items-start" *ngIf="!readonly && !competencyData">
      <button
        class="dc-icon-button mr-1"
        (click)="save()"
        [disabled]="form.invalid || !form.dirty"
        appTestLocator="user-filters-cell-range__save"
      >
        <app-svg-icon name="save" width="14"></app-svg-icon>
      </button>

      <button
        class="dc-icon-button"
        (click)="reset()"
        appTestLocator="user-filters-cell-range__reset"
        [disabled]="!form.dirty"
      >
        <app-svg-icon name="close" width="14"></app-svg-icon>
      </button>
    </div>
  </div>
</form>
