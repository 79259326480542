<ng-container>
  <table class="competence-tree-table">
    <thead>
      <tr>
        <th>Товары</th>
        <th>Фильтры</th>
      </tr>
    </thead>
    <tbody *ngIf="competenceTree?.goods?.length; else EMPTY_TABLE">
      <ng-container
        [ngTemplateOutlet]="COMPETENCE_ITEM"
        [ngTemplateOutletContext]="{ list: competenceTree?.goods, level: 0 }"
      ></ng-container>
    </tbody>
  </table>
  <table class="competence-tree-table">
    <thead>
      <tr>
        <th>Работы и услуги</th>
        <th>Фильтры</th>
      </tr>
    </thead>
    <tbody *ngIf="competenceTree?.services?.length; else EMPTY_TABLE">
      <ng-container
        [ngTemplateOutlet]="COMPETENCE_ITEM"
        [ngTemplateOutletContext]="{ list: competenceTree?.services, level: 0 }"
      ></ng-container>
    </tbody>
  </table>
</ng-container>

<ng-template #COMPETENCE_ITEM let-list="list" let-level="level">
  <ng-container *ngFor="let item of list">
    <tr *ngIf="item.type === 'sector' && item.selected" class="level-{{ level }}">
      <td>
        <div class="name name--competence margin-l-{{ level * 12 }}">
          <app-svg-icon
            class="caret"
            width="9.5"
            height="9.5"
            [class.disabled]="!item.children?.length"
            [name]="item.expanded && item.children?.length ? 'caret_up' : 'caret_down'"
            [style.opacity]="item.children?.length ? 1 : 0.3"
            (click)="toggle($event, item)"
          ></app-svg-icon>
          <span>
            <app-line-clamp [content]="item.section.title"></app-line-clamp>
          </span>
          <app-svg-icon
            *ngIf="level === 0"
            class="collapse-arrow color-brand-light icon-button"
            [height]="14"
            [width]="12"
            [class.disabled]="!item.children?.length"
            [name]="isAllExpanded(item) && item.children?.length ? 'd-arrow-up' : 'd-arrow-down'"
            (click)="toggleAll($event, item)"
          ></app-svg-icon>
        </div>
      </td>
      <td>
        <ng-container *ngIf="item?.filters?.length; else EMPTY_FILTER">
          <ng-container
            [ngTemplateOutlet]="FILTER"
            [ngTemplateOutletContext]="{ $implicit: item.filters, level: 0 }"
          ></ng-container>
        </ng-container>
        <ng-template #EMPTY_FILTER>-</ng-template>
      </td>
    </tr>

    <ng-container *ngIf="item?.children?.length && item?.expanded">
      <ng-container
        [ngTemplateOutlet]="COMPETENCE_ITEM"
        [ngTemplateOutletContext]="{ list: item.children, level: level + 1 }"
      ></ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #EMPTY_TABLE>
  <tbody>
    <tr>
      <td>-</td>
      <td>-</td>
    </tr>
  </tbody>
</ng-template>

<ng-template #FILTER let-filters let-level="level">
  <div *ngFor="let filter of filters" class="competence-tree-table__filter" [class.padding-l-16]="level > 0">
    <ng-container *ngIf="isShowFilter(filter)">
      <div class="name">
        <app-svg-icon
          class="caret"
          width="9.5"
          height="9.5"
          [class.disabled]="!filter.filters?.length"
          [style.opacity]="filter.filters?.length ? 1 : 0.3"
          [name]="filter.expanded && filter.filters?.length ? 'caret_up' : 'caret_down'"
          (click)="toggle($event, filter)"
        ></app-svg-icon>
        <span [ngSwitch]="filter?.instance_filter.type">
          <ng-container *ngSwitchCase="'choice'">
            <app-line-clamp [content]="filter?.instance_filter.template_filter?.title"></app-line-clamp>
          </ng-container>

          <ng-container *ngSwitchCase="'simple'">
            <app-line-clamp [content]="filter?.instance_filter?.template_filter?.value"></app-line-clamp>
          </ng-container>

          <ng-container *ngSwitchCase="'range'">
            <app-line-clamp [content]="filter | providerFilterRange : getSelectedFilter(filter)"></app-line-clamp>
          </ng-container>

          <ng-container *ngSwitchCase="'bool'">
            <app-line-clamp [content]="filter | providerFilterBoolean : getSelectedFilter(filter)"></app-line-clamp>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <app-line-clamp
              [content]="
                filter?.instance_filter?.template_filter?.title + ' ' + filter?.instance_filter?.template_filter?.value
              "
            ></app-line-clamp>
          </ng-container>
        </span>
      </div>

      <ng-container
        *ngIf="filter?.filters?.length && filter?.expanded"
        [ngTemplateOutlet]="FILTER"
        [ngTemplateOutletContext]="{ $implicit: filter?.filters, level: level + 1 }"
      ></ng-container>
    </ng-container>
  </div>
</ng-template>
