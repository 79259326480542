import { NgModule } from '@angular/core';
import { HighlightSearchPipe } from './highlight-search.pipe';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  exports: [HighlightSearchPipe],
  declarations: [HighlightSearchPipe],
})
export class HighlightSearchModule {}
