import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { User } from '@app/shared/models/user.model';

@Component({
  selector: 'app-file-manager-modal-user-info',
  templateUrl: './file-manager-modal-user-info.component.html',
  styleUrls: ['./file-manager-modal-user-info.component.scss'],
})
export class FileManagerModalUserInfoComponent {
  @Input() user: User;
  @Input() title = 'Владелец папки/файла';
  constructor(private readonly activeModal: NgbActiveModal) {}

  cancel(): void {
    this.activeModal.close();
  }
}
