import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TreeNode } from '@circlon/angular-tree-component';
import { FilterTemplateTypes } from '@app/+competence-map/constants/filter-templates.constants';
import { TreeSectionsAndFilters } from '@app/+competence-map/models/user-competency.model';
import { CMSectionStatuses } from '@app/+competence-map/constants/sections.constants';

@Component({
  selector: 'app-my-catalog-filter-tree-item',
  templateUrl: './my-catalog-filter-tree-item.component.html',
  styleUrls: ['./my-catalog-filter-tree-item.component.scss'],
})
export class MyCatalogFilterTreeItemComponent {
  @Input() item: TreeSectionsAndFilters;
  @Input() node: TreeNode;
  @Input() selected: boolean;
  @Input() readonly: boolean;

  @Output() onToggle: EventEmitter<any> = new EventEmitter();
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Output() saveBoolEvent: EventEmitter<any> = new EventEmitter();
  @Output() saveRangeEvent: EventEmitter<any> = new EventEmitter();

  @Output() onCheckboxChanges: EventEmitter<TreeSectionsAndFilters> = new EventEmitter<TreeSectionsAndFilters>();

  filterTemplateTypes = FilterTemplateTypes;

  get hasSelectedChildren() {
    return (
      this.item.children?.length && this.item.children?.some((item) => item.selected) && !this.hasAllSelectedChildren
    );
  }

  get hasAllSelectedChildren() {
    return this.item.children?.every((item) => item.selected);
  }

  get template_filter() {
    this.item.instance_filter.template_filter.type = this.item.instance_filter.type;
    return this.item.instance_filter.template_filter;
  }

  toggleNodeClicked(): void {
    this.onToggle.next();
  }

  onSelectClicked(): void {
    this.onSelect.emit({
      id: this.item.id,
      node: this.node,
    });
  }

  saveRange(event: { id: string; min: number; max: number }) {
    this.item.providerCompetency.value_range_from = event.min;
    this.item.providerCompetency.value_range_to = event.max;

    this.saveRangeEvent.emit(event);
  }

  changeSwitch(event) {
    this.item.providerCompetency.value_bool = event.target.checked;
    this.saveBoolEvent.emit();
  }

  toggleSelected(items: Array<any>) {
    items.forEach((item) => {
      if (item.status !== CMSectionStatuses.ARCHIVE) {
        item.selected = this.item.selected;
        if (item.children) {
          this.toggleSelected(item.children);
        }
      }
    });
  }

  onCheckboxChange(newValue: boolean): void {
    this.item.selected = newValue;

    if (this.item.children) {
      this.toggleSelected(this.item.children);
    }

    this.onCheckboxChanges.emit(this.item);

    if (this.node.realParent) {
      this.node.realParent.data.selected = this.node.realParent.children.every((item) => item.data.selected);
    }
  }
}
