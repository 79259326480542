import { takeUntil } from 'rxjs/operators';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { AuthService } from '@app/shared/services/auth.service';
import { CalendarService } from '@app/shared/services/calendar/calendar.service';
import { CalendarDataService } from '@app/shared/services/calendar/calendar-data.service';
import {
  EventDayListModel,
  EventHourListModel,
  STATE_DAY_PLANNER,
  TYPE_PLANNER,
} from '@app/calendar/constants/calendar.constants';
import { BACKEND_DATE_FORMAT, DATE_FORMAT } from '@app/shared/constants/date.constants';
import {
  EventFilterModel,
  EventModel,
  FINISH_STATE,
  LIST_STATE,
} from '@app/shared/components/calendar-widget/constants/calendar.constants';
import { TYPE_SELECT } from '@app/shared/components/calendar-widget/constants/select-date.constants';
import { ModalInjectorConstants } from '@app/modal-injector/constants/modal-injector.constants';
import { ModalInjectorService } from '@app/modal-injector/services/modal-injector.service';
import { MultiSelectListItem } from '@app/shared/models/multi-select-list-item';
import { UserSettings } from '@app/shared/models/user-settings.model';
import { debounce, range } from 'lodash-es';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CalendarComponent implements OnInit, AfterViewInit {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  id = -1;
  enumTypePlanner = TYPE_PLANNER;
  typeSelect = TYPE_SELECT;
  typePlanner = TYPE_PLANNER.MONTH;
  listState = LIST_STATE;
  listStateForFilter: MultiSelectListItem[] = [...LIST_STATE].map((item) => {
    return {
      id: item.key,
      name: item.text,
      icon: ['fas', 'circle'],
      classes: ['event-filter'],
      color: item.color,
    };
  });
  selectFilterState = [];
  showFilter = false;
  showModalHelper = false;
  currentDayOnCalendar: boolean;
  calendarData: Object[] = [];
  events: EventModel[] = [];
  eventForDay: EventDayListModel = {};
  eventForHour: EventHourListModel = {};
  currentYear: string = moment().locale('ru').format('YYYY');
  currentMonth: string = moment().locale('ru').format('MMMM');
  currentWeek: number = moment().locale('ru').isoWeek();
  heightViewEvent;
  paddingMainPlanner = 40;
  maxWidthDate = {
    [TYPE_PLANNER.WEEK]: 414,
    [TYPE_PLANNER.MONTH]: 240,
    [TYPE_PLANNER.YEAR]: 160,
  };
  @ViewChild('mainPlanner') private mainPlanner: ElementRef;
  @ViewChild('headerPlanner') private headerPlanner: ElementRef;
  debCalcHeightViewEvent = debounce(this.calcHeightViewEvent, 50);

  updateCalendar = {
    [TYPE_PLANNER.WEEK]: () => {
      this.currentDayOnCalendar = false;
      const startDate = moment()
        .isoWeekYear(+this.currentYear)
        .isoWeek(this.currentWeek)
        .startOf('week');
      const startDateEvent = startDate.format(BACKEND_DATE_FORMAT);
      this.calendarData = [];
      for (let i = 0; i < 7; i++) {
        const day: any = {
          dayNumber: startDate.format('D'),
          day: startDate.format(BACKEND_DATE_FORMAT),
          dayForDatepicker: startDate.format(DATE_FORMAT),
          dayOfWeek: startDate.format('dd'),
          class: 'view-week-days-hour',
        };
        if (+moment().startOf('day') === +startDate) {
          day.class += ' view-week-days-hour-current';
          day.current = true;
          this.currentDayOnCalendar = true;
        }
        if (this.currentMonth !== startDate.format('MMMM')) {
          day.oldMonth = true;
          day.class += ' view-week-days-hour-old_month';
        }
        startDate.add(1, 'day');
        this.calendarData.push(day);
      }
      const filterData: EventFilterModel = {
        startDate: startDateEvent,
        endDate: startDate.format(BACKEND_DATE_FORMAT),
        state: this.selectFilterState,
      };
      this.calendarDataService.updateSaveFilter(filterData.state, TYPE_PLANNER.WEEK);
      this.calendarDataService.getEventsByPeriod(filterData).subscribe((res: EventModel[]) => {
        this.events = res;
        this.updateEvent[TYPE_PLANNER.WEEK]();
      });
    },
    [TYPE_PLANNER.MONTH]: () => {
      this.currentDayOnCalendar = false;
      const startDate = moment(`${this.currentMonth}${this.currentYear}`, 'MMMYYYY').startOf('week');
      const startDateEvent = startDate.format(BACKEND_DATE_FORMAT),
        endOfCurrentMonth = +moment(`${this.currentMonth}${this.currentYear}`, 'MMMYYYY').endOf('month');
      this.calendarData = [];
      let week = [],
        numberWeek = 0;
      while (endOfCurrentMonth > +startDate) {
        numberWeek = startDate.isoWeek();
        for (let i = 0; i < 7; i++) {
          const day: any = {
            dayNumber: startDate.format('D'),
            day: startDate.format(BACKEND_DATE_FORMAT),
            dayForDatepicker: startDate.format(DATE_FORMAT),
            dayOfWeek: startDate.format('dd'),
            class: 'view-month-days-day',
          };
          if (+moment().startOf('day') === +startDate) {
            day.class += ' view-month-days-day-current';
            this.currentDayOnCalendar = true;
          }
          if (this.currentMonth !== startDate.format('MMMM')) {
            day.class += ' view-month-days-day-old_month';
          }
          startDate.add(1, 'day');
          week.push(day);
        }
        this.calendarData.push({ number: numberWeek, days: week });
        week = [];
      }
      const filterData: EventFilterModel = {
        startDate: startDateEvent,
        endDate: startDate.format(BACKEND_DATE_FORMAT),
        state: this.selectFilterState,
      };
      this.calendarDataService.updateSaveFilter(filterData.state, TYPE_PLANNER.MONTH);
      this.calendarDataService.getEventsByPeriod(filterData).subscribe((res: EventModel[]) => {
        this.events = res;
        this.updateEvent[TYPE_PLANNER.MONTH]();
      });
    },
    [TYPE_PLANNER.YEAR]: () => {
      this.calendarDataService.updateSaveFilter([], TYPE_PLANNER.YEAR);
    },
  };

  updateEvent = {
    [TYPE_PLANNER.MONTH]: () => {
      this.eventForDay = {};
      if (Array.isArray(this.events)) {
        this.events.forEach((item) => {
          if (!this.eventForDay[item['start_date']]) {
            this.eventForDay[item['start_date']] = {
              list: [],
              state: STATE_DAY_PLANNER.FINISH,
            };
          }
          this.eventForDay[item['start_date']].list.push(item);
          // tslint:disable-next-line:no-bitwise
          if (
            this.eventForDay[item['start_date']].state === STATE_DAY_PLANNER.FINISH &&
            !~FINISH_STATE.indexOf(item.status)
          ) {
            this.eventForDay[item['start_date']].state = STATE_DAY_PLANNER.IN_PROGRESS;
          }
        });
      }
      Object.keys(this.eventForDay).forEach((key) => {
        this.eventForDay[key].list = this.eventForDay[key].list.sort(function (a, b) {
          return Number(a.start_time.replace(/:/g, '')) - Number(b.start_time.replace(/:/g, ''));
        });
      });
    },
    [TYPE_PLANNER.WEEK]: () => {
      this.eventForHour = {};
      if (Array.isArray(this.events)) {
        this.events.forEach((item) => {
          if (!this.eventForHour[item['start_date']]) {
            this.eventForHour[item['start_date']] = {
              total: 0,
              state: STATE_DAY_PLANNER.FINISH,
              stateList: {},
            };
            range(24).forEach((time) => {
              this.eventForHour[item['start_date']][time] = {
                list: [],
                state: STATE_DAY_PLANNER.FINISH,
              };
            });
          }
          this.eventForHour[item['start_date']][+item['start_time'].substr(0, 2)].list.push(item);
          this.eventForHour[item['start_date']].total++;
          this.eventForHour[item['start_date']].stateList[item.status] = true;
          // tslint:disable-next-line:no-bitwise
          if (
            this.eventForHour[item['start_date']].state === STATE_DAY_PLANNER.FINISH &&
            !~FINISH_STATE.indexOf(item.status)
          ) {
            this.eventForHour[item['start_date']].state = STATE_DAY_PLANNER.IN_PROGRESS;
          }
          // tslint:disable-next-line:max-line-length no-bitwise
          if (
            this.eventForHour[item['start_date']][+item['start_time'].substr(0, 2)].state ===
              STATE_DAY_PLANNER.FINISH &&
            !~FINISH_STATE.indexOf(item.status)
          ) {
            this.eventForHour[item['start_date']][+item['start_time'].substr(0, 2)].state =
              STATE_DAY_PLANNER.IN_PROGRESS;
          }
        });
      }
      Object.keys(this.eventForHour).forEach((day) => {
        Object.keys(day).forEach((key) => {
          this.eventForHour[day][key].list = this.eventForHour[day][key].list.sort(function (a, b) {
            return Number(a.start_time.replace(/:/g, '')) - Number(b.start_time.replace(/:/g, ''));
          });
        });
      });
    },
  };

  constructor(
    private authService: AuthService,
    private calendarDataService: CalendarDataService,
    private calendarService: CalendarService,
    private modalInjectorService: ModalInjectorService
  ) {}

  changeTypePlanner(type: TYPE_PLANNER) {
    this.setFilter(true);
    this.calendarData = [];
    this.typePlanner = type;
    this.currentYear = moment().locale('ru').format('YYYY');
    this.currentMonth = moment().locale('ru').format('MMMM');
    this.currentWeek = moment().locale('ru').isoWeek();
    this.updateCalendar[type]();
  }

  closeModalHelper() {
    this.showModalHelper = false;
  }

  ngOnInit() {
    this.calendarDataService.getSaveFilter().subscribe((res: UserSettings) => {
      if (res?.calendar_settings?.filter) {
        this.selectFilterState = res.calendar_settings.filter.split(',');
      }
      if (res?.calendar_settings?.type) {
        this.typePlanner = res.calendar_settings.type;
      }
      this.updateCalendar[this.typePlanner]();
    });

    this.calendarDataService.updateDataAllEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
      this.updateCalendar[this.typePlanner]();
    });

    this.calendarDataService.updateDataEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
      if (!event.id) {
        this.updateCalendar?.[this.typePlanner]();
      }
      if (event.id === this.id) {
        switch (event.type) {
          case TYPE_SELECT.WEEK:
            if (this.typePlanner === TYPE_PLANNER.WEEK) {
              const nextWeek = moment()
                .isoWeekYear(+this.currentYear)
                .isoWeek(event.value)
                .startOf('week')
                .add(3, 'days');
              this.currentYear = nextWeek.format('YYYY');
              this.currentMonth = nextWeek.format('MMMM');
              this.currentWeek = nextWeek.isoWeek();
            } else {
              this.currentWeek = +event.value;
            }
            break;
          case TYPE_SELECT.YEAR:
            if (this.typePlanner === TYPE_PLANNER.WEEK) {
              const nextYear = moment()
                .isoWeekYear(+event.value)
                .isoWeek(this.currentWeek)
                .startOf('week')
                .add(3, 'days');
              this.currentYear = nextYear.format('YYYY');
              this.currentMonth = nextYear.format('MMMM');
              this.currentWeek = nextYear.isoWeek();
            } else {
              this.currentYear = event.value;
            }
            break;
          case TYPE_SELECT.MONTH:
            this.currentMonth = event.value;
            if (this.typePlanner === TYPE_PLANNER.WEEK) {
              let startMonth = moment(`${this.currentMonth}${this.currentYear}`, 'MMMYYYY').startOf('month');
              while (startMonth.format('d') !== '4') {
                startMonth = startMonth.add(1, 'days');
              }
              this.currentYear = startMonth.format('YYYY');
              this.currentMonth = startMonth.format('MMMM');
              this.currentWeek = startMonth.isoWeek();
            }
            break;
        }
        this.updateCalendar?.[this.typePlanner]();
      }
    });

    this.calendarDataService.openMonthPlannerEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
      this.typePlanner = this.enumTypePlanner.MONTH;
      this.currentYear = event.year;
      this.currentMonth = event.month;
      this.updateCalendar?.[TYPE_PLANNER.MONTH]();
    });

    this.calendarDataService.openWeekPlannerEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
      this.typePlanner = this.enumTypePlanner.WEEK;
      this.currentYear = event.year;
      this.currentWeek = event.week;
      this.updateCalendar?.[TYPE_PLANNER.WEEK]();
    });
  }

  calcHeightViewEvent() {
    this.heightViewEvent =
      this.mainPlanner?.nativeElement?.offsetHeight -
      this.headerPlanner?.nativeElement?.offsetHeight -
      this.paddingMainPlanner;
  }

  ngAfterViewInit() {
    this.debCalcHeightViewEvent();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.debCalcHeightViewEvent();
  }

  showSelect(type: TYPE_SELECT, event) {
    const parent = event.target.getBoundingClientRect(),
      countWeek = moment(this.currentYear, 'YYYY').endOf('year').isoWeek();
    this.modalInjectorService.addModal(ModalInjectorConstants.SELECT_DATE, {
      type,
      parent,
      countWeek,
      parentId: this.id,
    });
  }

  changeDate(difference) {
    switch (this.typePlanner) {
      case TYPE_PLANNER.WEEK:
        const nextWeek = moment()
          .isoWeekYear(+this.currentYear)
          .isoWeek(this.currentWeek)
          .startOf('week')
          .add(3, 'days')
          .add(difference, 'weeks');
        this.currentYear = nextWeek.format('YYYY');
        this.currentMonth = nextWeek.format('MMMM');
        this.currentWeek = nextWeek.isoWeek();
        this.updateCalendar[TYPE_PLANNER.WEEK]();
        break;
      case TYPE_PLANNER.MONTH:
        const nextMonth = moment(`${this.currentMonth}${this.currentYear}`, 'MMMYYYY').add(difference, 'month');
        this.currentYear = nextMonth.format('YYYY');
        this.currentMonth = nextMonth.format('MMMM');
        this.updateCalendar[TYPE_PLANNER.MONTH]();
        break;
      case TYPE_PLANNER.YEAR:
        const nextYear = moment(`${this.currentMonth}${this.currentYear}`, 'MMMYYYY').add(difference, 'year');
        this.currentYear = nextYear.format('YYYY');
        break;
    }
  }

  setFilter(clear = false) {
    this.listStateForFilter.forEach((item) => {
      item.checked = this.selectFilterState.includes(item.id) && !clear;
    });
  }

  openFilter() {
    this.setFilter();
    this.showFilter = true;
  }

  addEvent() {
    this.modalInjectorService.addModal(ModalInjectorConstants.ADD_EVENT_CALENDAR, { show: true, addEvent: true });
  }

  applyFilter(event) {
    this.selectFilterState = event.value;
    this.updateCalendar[this.typePlanner]();
    this.showFilter = false;
  }
}
