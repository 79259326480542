import { ChangeDetectionStrategy, Component, Inject, Input, OnChanges, OnInit } from '@angular/core';

import { SVGSpriteLoaderService } from '../../services/svg-sprite-loader.service';
import { SVG_SPRITE_CONFIG } from '../../constants/svg-sprite.config';
import { SVGSpriteConfig } from '../../types/svg-sprite-config.type';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-svg-icon',
  template: `
    <svg [style.width.px]="width" [style.height.px]="height" *ngIf="xLinkName$ | async">
      <use [attr.xlink:href]="xLinkName"></use>
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SVGIconComponent implements OnInit, OnChanges {
  @Input() width = 24;
  @Input() height = 24;
  @Input() name: string;

  public xLinkName$: Observable<boolean>;

  get xLinkName(): string {
    return `#${this.config.spriteIconPrefix}` + this.name;
  }

  constructor(
    private svgSpriteService: SVGSpriteLoaderService,
    @Inject(SVG_SPRITE_CONFIG) private config: SVGSpriteConfig
  ) {}

  ngOnChanges() {
    this.xLinkName$ = this.svgSpriteService.getIcon(this.name);
  }

  ngOnInit(): void {
    this.xLinkName$ = this.svgSpriteService.getIcon(this.name);
  }
}
