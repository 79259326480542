export enum POSITION {
  TOP = 'top',
  TOP_RIGHT = 'top_right',
  TOP_LEFT = 'top_left',
  RIGHT = 'right',
  DOWN = 'down',
  DOWN_RIGHT = 'down_right',
  DOWN_LEFT = 'down_left',
  LEFT = 'left',
  CENTER = 'center',
}

export interface TYPE_PARENT {
  top: number;
  left: number;
  height: number;
  width: number;
}
