<ng-container *ngIf="templateFilterType === filterTemplateTypes.RANGE; else SHOW_TREE">
  <app-form-range-slider
    *ngIf="data.length"
    [readonly]="true"
    [setValueFrom]="fromRangeValue"
    [setValueTo]="toRangeValue"
  ></app-form-range-slider>
</ng-container>

<ng-template #SHOW_TREE>
  <div class="tree-container">
    <tree-root #tree [nodes]="data">
      <ng-template #treeNodeFullTemplate let-node let-index="index" let-templates="templates">
        <div
          class="tree-node"
          [class]="'tree-node-level-' + node.level"
          appTestLocator="union-tree-view__item-{{ node?.data?.id }}"
        >
          <div
            class="node-content-wrapper"
            [class.node-content-wrapper-active]="node.isActive"
            [class.node-content-wrapper-focused]="node.isFocused"
          >
            <ng-container *treeMobxAutorun="{ dontDetach: false }">
              <span
                [class.toggle-children-wrapper-expanded]="node.isExpanded"
                [class.toggle-children-wrapper-collapsed]="node.isCollapsed"
                class="toggle-children-wrapper"
                (click)="node.mouseAction('expanderClick', $event)"
                appTestLocator="union-tree-view__item-{{ node?.data?.id }}_arrow"
              >
                <span class="toggle-children">
                  <app-svg-icon
                    [name]="!node.isExpanded ? 'caret_down' : 'caret_up'"
                    [class.disabled]="!node.hasChildren"
                    width="10"
                  ></app-svg-icon>
                </span>
              </span>
            </ng-container>
            <span class="label" appTestLocator="union-tree-view__item-{{ node?.data?.id }}_title">{{
              node?.data?.title
            }}</span>
          </div>
          <tree-node-children [node]="node" [templates]="templates"></tree-node-children>
        </div>
      </ng-template>
      <ng-template #loadingTemplate>Загрузка...</ng-template>
    </tree-root>
  </div>
</ng-template>
