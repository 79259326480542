import {Directive, Input} from '@angular/core';
import {FormControl, NG_VALIDATORS} from '@angular/forms';
import * as moment from 'moment';
import {BACKEND_DATE_FORMAT} from '../constants/date.constants';

@Directive({
  selector: '[maxDateValue]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: MaxDateValueDirective,
      multi: true
    }
  ]
})
export class MaxDateValueDirective {
  @Input('maxDateValue') maxDateValue: string;

  constructor() {
  }

  validate(c: FormControl): {[key: string]: {valid: boolean}} {
    const v = c.value;
    const invalid = {maxDateValue: {valid: false}};

    if (!v) {
      return null;
    }

    if (!this.isDateValid(v)) {
      return invalid;
    }

    if (!this.maxDateValue) {
      return null;
    }

    if (moment(v, BACKEND_DATE_FORMAT).valueOf() > moment(this.maxDateValue, BACKEND_DATE_FORMAT).valueOf()) {
      return invalid;
    }

    return null;
  }

  isDateValid(date: string) {
    return moment(date, BACKEND_DATE_FORMAT).isValid();
  }
}
