<div class="container-fluid pt-3">
  <div class="competence-map-header">
    <div class="header1">{{ currentTab?.title }}</div>
    <button
      class="dc-toolbar-btn"
      (click)="isHelperOpen = true"
      container="body"
      placement="top"
      triggers="mouseenter:mouseleave"
      ngbTooltip="Справка"
    >
      <fa-icon [icon]="['fas', 'question']"></fa-icon>
    </button>
  </div>

  <div class="dc-tabs">
    <div
      class="dc-tab"
      *ngFor="let tab of tabs"
      [class.active]="currentTabValue === tab.value"
      (click)="onSelectTab(tab)"
    >
      <div class="dc-tab-icon">
        <app-svg-icon [name]="tab.icon"></app-svg-icon>
      </div>
      <div class="dc-tab-title"></div>
      {{ tab.label }}
    </div>
  </div>
  <div class="dc-tab-content">
    <div [hidden]="currentTabValue !== 'product_catalog'">
      <app-my-catalog
        [catalog]="catalogTypes.GOODS"
        [competencies]="competencies"
        [isExpert]="true"
        [isMyCompetencies]="true"
      ></app-my-catalog>
    </div>

    <div [hidden]="currentTabValue !== 'job_catalog'">
      <app-my-catalog
        [catalog]="catalogTypes.SERVICES"
        [competencies]="competencies"
        [isExpert]="true"
        [isMyCompetencies]="true"
      ></app-my-catalog>
    </div>
  </div>
  <div class="pb-5">
    <button class="btn btn-dc-outline mr-2" (click)="cancel()" [disabled]="!hasChanges()">Отменить</button>
    <button class="btn btn-dc-primary" (click)="save()" type="submit" [disabled]="!hasChanges()">Сохранить</button>
  </div>
</div>

<!-- Help modal window -->
<app-modal-window [isShowed]="isHelperOpen" backdrop="static" (closeEvent)="closeHelper()">
  <div class="modal-header center">
    <div class="modal-title">Справка</div>
  </div>
  <div class="modal-body"></div>
  <div class="modal-footer">
    <div class="dc-footer-buttons">
      <button class="btn btn-dc-outline" (click)="closeHelper()">Закрыть</button>&nbsp;
    </div>
  </div>
</app-modal-window>
