import { Subscription } from 'rxjs';
import { UserFile } from './models/user-file.model';

export enum ExtType {
  IMAGE = 'image',
  PDF = 'pdf',
}
export const tradeFolder: any = {
  id: -2,
  is_file: false,
  name: 'Мои торги',
};
export interface ExtInterface {
  hasImage?: boolean;
  hasPdf?: boolean;
  hasDoc?: boolean;
  hasPpt?: boolean;
  ext?: string[];
  icon: string;
}

export interface LegendInterface {
  file: File | UserFile;
  status: number;
  error: any;
  subscribe: Subscription;
  ready: boolean;
}

export const DEFAULT_EXT: ExtInterface = {
  hasImage: false,
  hasPdf: false,
  hasDoc: false,
  hasPpt: false,
  ext: null,
  icon: 'file',
};
export const EXT: ExtInterface[] = [
  {
    hasPdf: true,
    ext: ['pdf'],
    icon: 'file-pdf',
  },
  {
    hasDoc: true,
    ext: ['docs', 'docx', 'docm', 'dotx', 'doc', 'rtf'],
    icon: 'file-word',
  },
  {
    ext: ['txt'],
    icon: 'file-alt',
  },
  {
    ext: ['xlsx', 'xls', 'csv'],
    icon: 'file-excel',
  },
  {
    hasPpt: true,
    ext: ['ppt', 'pptx', 'pps', 'ppsx', 'pot', 'potx'],
    icon: 'file-powerpoint',
  },
  {
    hasImage: true,
    ext: ['jpg', 'jpeg', 'png', 'bmp', 'gif'],
    icon: 'file-image',
  },
];

export enum VIEW_MODE_TYPE {
  LIST,
  DETAIL,
}

export enum SHARE_TYPE {
  DISALLOW,
  ALLOW,
}

export enum BUFFER_TYPE {
  COPY,
  CUT,
}

export enum FILE_MANAGER_LAYOUTS {
  BASE = 'base',
  TRADES = 'trades',
  EXPORT = 'export',
  PROFILE = 'profile',
}

export const SHARE_LIST_PER_PAGE = 20;

export const SHARE_MAX_FILES_COUNT_TITLE = 4;

export const MODULE_TITLE: string = 'Менеджер файлов';

export const TOOLTIP_FILE_TITLE_LENGTH = 30;
