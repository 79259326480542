<div class="section-header">
  <div class="row section-header-info">
    <div class="col-6">
      <div class="section-header-row" appTestLocator="split-section__author">
        <div class="section-header-label">Автор:</div>
        <div class="section-header-value">{{ userAuthor | roleName }}</div>
      </div>
      <div class="section-header-row" appTestLocator="split-section__data">
        <div class="section-header-label">Дата:</div>
        <div class="section-header-value">{{ projectDate | dateFormat }}</div>
      </div>
    </div>
    <div class="col-6">
      <div class="section-header-row" appTestLocator="split-section__project">
        <div class="section-header-label">Проект ID:</div>
        <div class="section-header-value">{{ projectId }}</div>
      </div>
      <div class="section-header-row" appTestLocator="split-section__status">
        <div class="section-header-label">Статус:</div>
        <div class="section-header-value d-flex align-items-center">
          {{ projectStatus | cmProjectStatus }}&nbsp;&nbsp;
          <app-svg-icon name="change" width="16" class="color-brand-light"></app-svg-icon>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="section-header-text" appTestLocator="split-section__title">Разделить раздел {{ level }} уровня</div>
  </div>
  <div>
    <div
      class="section-header-archive"
      (click)="archive()"
      [class.disabled]="isArchiveDisabled()"
      appTestLocator="split-section__archive"
    >
      <app-svg-icon name="trash" width="16"></app-svg-icon>
      <span>Перенести в архив</span>
    </div>
  </div>
</div>
<div class="modal-body">
  <div class="section-form">
    <div class="section-header-row section-path" appTestLocator="split-section__path">
      <div class="section-header-label">Путь:</div>
      <div class="section-header-value">{{ path }}</div>
    </div>
    <div class="row">
      <div class="col-4">
        <div class="mb-3" appTestLocator="split-section__current-section_title">Название делимого раздела</div>
        <div class="mb-5">
          <dc-form-field-wrapper [style]="'material'" [vertically]="true" [required]="true">
            <dc-form-input>
              <input
                appTestLocator="split-section__current-section_input"
                formInput
                [ngModel]="currentSection?.title"
                [disabled]="true"
                placeholder="Введите название или выберите из каталога"
              />
            </dc-form-input>
          </dc-form-field-wrapper>
        </div>
        <div class="mb-2" appTestLocator="split-section__current-section_chidren-title">Вложенные разделы</div>
        <div class="mb-3">
          <div class="sections-block" appTestLocator="split-section__section-children">
            <div
              *ngFor="let section of currentSection?.children"
              class="section-nested-item"
              appTestLocator="split-section__current-section_chidren-item-{{ section.id }}"
              [class.selected]="isSectionSelected(section)"
            >
              {{ section.title }}
            </div>
          </div>
          <div
            *ngIf="!currentSection?.children?.length"
            class="section-nested-item"
            appTestLocator="split-section__current-section_chidren-no-data"
          >
            Нет разделов
          </div>
        </div>
        <div class="mb-2" appTestLocator="split-section__current-section-filters_title">Фильтры раздела</div>
        <div class="mb-3">
          <div class="sections-block" appTestLocator="split-section__filter-children">
            <div
              *ngFor="let filter of filters"
              class="section-nested-item"
              appTestLocator="split-section__current-section-filters_item-{{ filter.id }}"
            >
              {{ filter?.template_filter?.title }}
            </div>
          </div>
          <div *ngIf="!filters.length" class="section-nested-item">Нет фильтров</div>
        </div>
      </div>
      <form class="col-8" [formGroup]="form">
        <div formArrayName="sections" class="d-flex">
          <div
            *ngFor="let section of newSections?.controls; let i = index"
            class="mr-4"
            appTestLocator="split-section__section-{{ i }}"
          >
            <div [formGroupName]="i" class="split-new-section">
              <div class="mb-3" class="split-new-section__title" appTestLocator="split-section__section-{{ i }}_title">
                Название {{ i + 1 }}-го раздела
                <div
                  class="dc-icon-button"
                  (click)="remove(i)"
                  *ngIf="canRemove(i)"
                  appTestLocator="split-section__section-{{ i }}_remove"
                >
                  <app-svg-icon name="close-search" width="14"></app-svg-icon>
                </div>
              </div>
              <div class="mb-3">
                <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true" [required]="true">
                  <dc-form-input [maxLength]="30" [isShowLength]="true" [class.disabled]="isDataDisabled()">
                    <textarea
                      appTestLocator="split-section__section-{{ i + 1 }}_textarea"
                      formInput
                      formControlName="title"
                      placeholder="Введите название {{ i + 1 }} раздела"
                    ></textarea>
                  </dc-form-input>
                </dc-form-field-wrapper>
              </div>
              <div
                class="mb-2 section-modal-reset"
                (click)="resetSelected(section?.controls?.sections.controls)"
                appTestLocator="split-section__section_reset-selected-sections"
              >
                <app-svg-icon name="close-search" width="14"></app-svg-icon>
                Снять выделение
              </div>
              <div formArrayName="sections" class="mb-4 sections-block">
                <div *ngFor="let item of section?.controls?.sections.controls; let j = index">
                  <div [formGroupName]="j" [class.disabled]="isDataDisabled()">
                    <input
                      class="cbx-input"
                      [id]="item.value.id"
                      formControlName="checked"
                      type="checkbox"
                      (change)="validateSelection(item)"
                    />
                    <label
                      class="cbx-label"
                      [for]="item.value.id"
                      appTestLocator="split-section__section-checkbox-{{ item.value.id }}"
                    >
                      <span class="cbx-label-text">{{ item.value.title }}</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="mb-2 section-modal-reset" (click)="resetSelected(section?.controls?.filters.controls)">
                <app-svg-icon name="close-search" width="14"></app-svg-icon>
                Снять выделение
              </div>
              <div formArrayName="filters" class="mb-4 sections-block">
                <div *ngFor="let item of section?.controls?.filters.controls; let k = index">
                  <div [formGroupName]="k" [class.disabled]="isDataDisabled()">
                    <input
                      class="cbx-input"
                      [id]="item.value.id"
                      formControlName="checked"
                      type="checkbox"
                      [checked]="item.value.checked"
                    />
                    <label
                      class="cbx-label"
                      [for]="item.value.id"
                      appTestLocator="split-section__filter-checkbox-{{ item.value.id }}"
                    >
                      <span class="cbx-label-text">{{ item.value?.title }}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="add-new-section">
            <button
              class="dc-icon-button"
              (click)="addNewSection()"
              [disabled]="!canAddNew() || isDataDisabled()"
              appTestLocator="split-section__new-section"
            >
              <app-svg-icon name="plus" width="14"></app-svg-icon>
              <span class="mr-1">Добавить еще раздел</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<app-operation-modal-footer
  (onCancel)="cancel()"
  (onSave)="save($event)"
  (onAgree)="agree($event)"
  [project]="project"
  [form]="form"
>
</app-operation-modal-footer>
