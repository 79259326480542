import { HelpHint } from '../models/help-hint.model';

export const HELP_HINTS: HelpHint = {
  EMPLOYEE: {
    TOOLBAR_BUTTON: 32,
    SIDEBAR_TOOLTIP: 33,
  },
  FAQ: {
    SIDEBAR_TOOLTIP: 30,
  },
  FAQ_EDIT: {
    SIDEBAR_TOOLTIP: 39,
  },
  FILE_MANAGER: {
    SIDEBAR_TOOLTIP: 31,
  },
};
