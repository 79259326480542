import { Component, ViewChild } from '@angular/core';
import { AuthService } from '@app/shared/services/auth.service';
import { Validation } from '@app/shared/services/validation.service';
import { NotificationsService } from 'angular2-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import isEmpty from 'lodash-es/isEmpty';
import { environment } from '@app/environments/environment';
import { PersonalDataService } from '@app/shared/services/personal-data.service';

@Component({
  templateUrl: `./signup.component.html`,
  styleUrls: ['../auth.component.sass'],
})
export class SignupComponent {
  @ViewChild('captchaRef') captchaRef;

  email = '';
  password = '';
  password_double = '';
  role = 'admin_of_user';
  errors: any = {};
  private captcha_token;
  env = environment;
  private _tso_id = '';
  isShowPass = false;

  set tso_id(value) {
    this.errors['tso_id'] = undefined;
    this._tso_id = value;
  }

  get tso_id() {
    return this._tso_id;
  }

  constructor(
    private authService: AuthService,
    private notify: NotificationsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private personalDataService: PersonalDataService,
    private translate: TranslateService
  ) {
    this.translate.setDefaultLang('ru');
    this.role = this.activatedRoute.snapshot.data['role'];
  }

  handleCorrectCaptcha(res): void {
    this.captcha_token = res;
  }

  resetCaptcha(): void {
    if (this.captcha_token) {
      this.captchaRef.reset();
      this.captcha_token = null;
    }
  }

  register(): void {
    this.errors = Validation.ValidateRegister(
      this.email,
      this.password,
      this.password_double,
      this.captcha_token,
      this.tso_id
    );
    if (!isEmpty(this.errors)) return;
    this.authService
      .register(this.email, this.password, this.role, this.captcha_token, this.tso_id || undefined)
      .subscribe(
        () => {
          this.notify.success(
            'Успешно!',
            'Вы успешно зарегестрировались. Вам на почту отправлено письмо с подтверждением!'
          );
          this.personalDataService.showCurrentDatesNoti();

          this.router.navigate(['signin']);
        },
        (err) => {
          const error = err.error;
          if (error.errors) this.errors = error.errors;
          if (error.message !== 'Validation failed')
            this.notify.error('Ошибка!', this.translate.instant(error.message));
          this.resetCaptcha();
        }
      );
  }
}
