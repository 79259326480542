import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DCTreeItem } from '@app/+competence-map/models/competence-map.models';
import { ITreeOptions, TREE_ACTIONS, TreeComponent, TreeModel, TreeNode } from '@circlon/angular-tree-component';
import { takeUntil } from 'rxjs/internal/operators';
import { CMCatalogTypes } from '@app/+competence-map/constants/sections.constants';
import { DestroyService } from '@app/services/destroy.service';

@Component({
  selector: 'app-share-filter-tree',
  templateUrl: './share-filter-tree.component.html',
  styleUrls: ['./share-filter-tree.component.scss'],
  providers: [DestroyService],
})
export class ShareFilterTreeComponent implements OnInit {
  @Input() active: number;
  @Input() counter: number;
  @Input() items: DCTreeItem[];
  @Input() type: CMCatalogTypes;
  @Input() selected: number[] = [];

  @ViewChild('itemsTree') private itemsTree: TreeComponent;

  searchForm: FormGroup;
  selectedAll: boolean = false;
  isTreeExpanded1: boolean = false;
  isTreeExpanded2: boolean = false;
  treeOptions: ITreeOptions = {};

  constructor(private fb: FormBuilder, private destroy$: DestroyService) {}

  ngOnInit() {
    this.searchForm = this.fb.group({
      query: this.fb.control(''),
    });
    this.searchForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.isTreeExpanded1 = true;
      this.isTreeExpanded2 = true;
      this.itemsTree.treeModel.filterNodes((node) => {
        return node.data.title.toLowerCase().includes(data.query?.toLowerCase());
      });
    });
  }

  resetSearch(): void {
    this.searchForm.setValue({ query: '' });
  }

  selectAll(items: DCTreeItem[], event): void {
    if (items.length > 0) {
      items.map((item: DCTreeItem) => {
        item.selected = event.target.checked;
        this.selectItem(item);
        if (item.children?.length > 0) {
          this.selectAll(item.children, event);
        }
      });
    }
  }

  selectItem(item: DCTreeItem): void {
    if (item.selected) {
      this.selected.push(item.id);
    } else {
      this.selected.splice(this.selected.indexOf(item.id), 1);
    }
  }

  expandItem(tree: TreeModel, node: TreeNode, $event: any): void {
    TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
  }

  toggleByLevel(tc: any, level: number): void {
    const tree: TreeModel = tc.treeModel;
    switch (level) {
      case 1:
        if (this.isTreeExpanded1) {
          tree.getVisibleRoots().forEach((node: TreeNode) => node.collapse());
          this.isTreeExpanded1 = false;
        } else {
          tree.getVisibleRoots().forEach((node: TreeNode) => node.expand());
          this.isTreeExpanded1 = true;
        }
        break;
      case 2:
        if (!this.isTreeExpanded2 && !this.isTreeExpanded1) {
          this.toggleByLevel(tc, 1);
        }
        if (this.isTreeExpanded2) {
          tree.getVisibleRoots().forEach((node: TreeNode) => {
            node.getVisibleChildren().forEach((n: TreeNode) => n.collapse());
          });
          this.isTreeExpanded2 = false;
        } else {
          tree.getVisibleRoots().forEach((node: TreeNode) => {
            node.getVisibleChildren().forEach((n: TreeNode) => n.expand());
          });
          this.isTreeExpanded2 = true;
        }
        break;
    }
  }
}
