import { Component, Input, OnDestroy, OnInit, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

import { BehaviorSubject, concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { GeoService } from '@app/shared/services/geo.service';
import { Address } from '@app/shared/models/location.model';

@Component({
  selector: 'app-location-select',
  templateUrl: './location-select.component.html',
  styleUrls: ['./location-select.component.scss'],
})
export class LocationSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() userId?: number;
  public locationSearchSubject = new BehaviorSubject<string>('');
  public locationSearchQuery$ = this.locationSearchSubject.asObservable();
  public locations$: Observable<string[]>;
  public loadingLocations: boolean = false;
  public value: string;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(@Self() public ngControl: NgControl, private geoService: GeoService) {
    this.ngControl.valueAccessor = this;
  }

  get invalid(): boolean {
    return this.ngControl.control.invalid;
  }

  ngOnInit(): void {
    this.locations$ = concat(
      of([]),
      this.locationSearchQuery$.pipe(
        map((value) => value),
        takeUntil(this.ngUnsubscribe),
        filter((queryString: string) => queryString !== null && queryString.length >= 3),
        distinctUntilChanged(),
        debounceTime(800),
        tap(() => (this.loadingLocations = true)),
        switchMap((queryString: string) => {
          return this.geoService.getLocations(queryString).pipe(
            takeUntil(this.ngUnsubscribe),
            catchError(() => of([])),
            tap(() => (this.loadingLocations = false))
          );
        })
      )
    );
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.ngUnsubscribe = null;
  }

  public trackByFn(item: string) {
    return item;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {}

  writeValue(obj: Address | string): void {
    if (typeof obj === 'string') {
      this.value = obj;
    } else {
      const { country, province, locality, address } = obj;
      this.value = `${country ? country + ', ' : ''}${province ? province + ', ' : ''}${
        locality ? locality + ', ' : ''
      }${address ? address : ''}`;
    }
    this.locationSearchSubject.next(this.value);
  }

  public onChange = (value: any) => {};
  public onTouched = () => {};
}
