import { Pipe, PipeTransform } from '@angular/core';

import { UnitsMeasureRelation } from '../../models/units.model';

@Pipe({
  name: 'unitFormat',
})
export class UnitFormatPipe implements PipeTransform {
  transform(value: UnitsMeasureRelation): string {
    const result: string[] = [];

    result.push(value.full_name);

    if (value.name) {
      result.push(value.name);
    }

    return result.join(', ');
  }
}
