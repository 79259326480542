export interface NotificationModel {
  id: number;
  sending_date: string;
  status: string;
  text: string;
  viewed_date: string;
  source: NotificationSourceModel;
}

export interface NotificationSourceModel {
  module: string;
  user_types: string[];
  subsection?: string;
  params?: NotificationSourceParamsModel;
}

export interface NotificationSourceParamsModel {
  room_id?: string;
  trade_id?: number;
  provider_trade_id?: number;
  modal?: string;
}

export interface NotificationManyModel {
  notifications: NotificationModel[];
  total: number;
}

export interface NotificationSettingModel {
  details: NotificationSettingDetailsModel;
  flags: NotificationSettingFlagsModel;
  type: string;
}

export interface NotificationSettingFlagsModel {
  mail: boolean;
  none: boolean;
  status_bar: boolean;
}

export interface NotificationSettingDetailsModel {
  action_description: string;
  comment: string;
  event_description: string;
  module: string;
  text: string;
}

export interface NotificationDataSourceModel {
  noti?: NotificationManyModel;
  settings?: NotificationSettingModel[];
  loading?: boolean;
}

export interface NotificationColumnModel {
  label: string;
  width: string;
}

export enum NotificationTabEnum {
  LIST = 'list',
  SETTING = 'setting',
}

export enum NotificationStatusEnum {
  SENT = 'sent',
  VIEWED = 'viewed',
  DELETED = 'deleted',
}

export enum NotificationEventEnum {
  NEW_NOTIFICATION = 'newNotification',
  SHOW_MORE_NOTIFICATION = 'showMoreNotification',
}

export const settingNotificationColumns: NotificationColumnModel[] = [
  {
    label: 'Событие',
    width: '200px',
  },
  {
    label: 'Почта',
    width: '50px',
  },
  {
    label: 'Статус-бар',
    width: '50px',
  },
];

export const notificationUrl = 'notification';
