<div class="section-header">
  <div>
    <div class="section-header-row" appTestLocator="change-status__project">
      <div class="section-header-label">Проект ID:</div>
      <div class="section-header-value">{{ projectId }}</div>
    </div>
    <div class="section-header-row" appTestLocator="change-status__status">
      <div class="section-header-label">Статус:</div>
      <div class="section-header-value d-flex align-items-center">
        {{ projectStatus | cmProjectStatus }}&nbsp;&nbsp;
        <app-svg-icon name="change" width="16" class="color-brand-light"></app-svg-icon>
      </div>
    </div>
    <div class="section-header-row" appTestLocator="change-status__author">
      <div class="section-header-label">Автор:</div>
      <div class="section-header-value">{{ userAuthor | roleName }}</div>
    </div>
    <div class="section-header-row" appTestLocator="change-status__date">
      <div class="section-header-label">Дата:</div>
      <div class="section-header-value">{{ projectDate | dateFormat }}</div>
    </div>
  </div>
  <div>
    <div class="section-header-text" appTestLocator="change-status__title">Сменить статус</div>
  </div>
  <div>
    <div
      class="section-header-archive"
      (click)="archive()"
      [class.disabled]="isArchiveDisabled()"
      appTestLocator="change-status__archive"
    >
      <app-svg-icon name="trash" width="16"></app-svg-icon>
      <span>Перенести в архив</span>
    </div>
  </div>
</div>
<div class="modal-body" [class.disabled]="isDataDisabled()">
  <div
    *ngFor="let item of statuses"
    [class.active]="item.value === form.value.status"
    (click)="selectStatus(item.value)"
    appTestLocator="change-status__item-{{ item.value }}"
    class="section-status"
  >
    <div class="section-status-circle">
      <app-status-viewer [status]="item.value"></app-status-viewer>
    </div>
    <span appTestLocator="change-status__item-{{ item.value }}_name">
      {{ item.name }}
    </span>
  </div>
</div>

<app-operation-modal-footer
  (onCancel)="cancel()"
  (onSave)="save($event)"
  (onAgree)="agree($event)"
  [project]="project"
  [form]="form"
>
</app-operation-modal-footer>
