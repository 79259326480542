import { Pipe, PipeTransform } from '@angular/core';
import { ProviderCompetency, TreeSectionsAndFiltersItem } from '@app/+competence-map/models/user-competency.model';
import { isNull, isUndefined } from 'lodash-es';

@Pipe({ name: 'providerFilterBoolean' })
export class ProviderFilterBooleanPipe implements PipeTransform {
  transform(filter: TreeSectionsAndFiltersItem, providerFilter?: ProviderCompetency): string {
    const value =
      isUndefined(providerFilter) || isNull(providerFilter?.value_bool)
        ? filter.instance_filter.template_filter.value
        : providerFilter?.value_bool;

    return `${filter?.instance_filter?.template_filter?.title}: ${!value || value === 'false' ? 'НЕТ' : 'ДА'}`;
  }
}

@Pipe({ name: 'providerFilterRange' })
export class ProviderFilterRangePipe implements PipeTransform {
  transform(filter: TreeSectionsAndFiltersItem, providerFilter?: ProviderCompetency): string {
    return `
      ${filter?.instance_filter?.template_filter?.title}
      от ${providerFilter?.value_range_from || filter.instance_filter.range_from}
      до ${providerFilter?.value_range_to || filter.instance_filter.range_to}
    `;
  }
}
