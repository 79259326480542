import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { User } from '@app/shared/models/user.model';
import { getTitleRole } from '@app/shared/utils';
import { formatSectionsStringByOneLevel } from '@app/+competence-map/helpers/competence-map.helpers';

@Component({
  selector: 'app-user-item',
  templateUrl: './user-item.component.html',
  styleUrls: ['./user-item.component.scss'],
})
export class UserItemComponent implements OnInit, OnChanges {
  @Input() item: User = <User>{};
  @Input() selected: User = {} as User;

  @Input() isAccessChangeOptions = true;

  @Output() onSelected = new EventEmitter<User>();
  @Output() onUserDbClick = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.item && changes.item.currentValue) {
      this.item.typeLabel = getTitleRole(changes.item.currentValue.type);
      this.item.competenciesString = formatSectionsStringByOneLevel(
        changes.item.currentValue.competence_sections,
        ' / ',
        ''
      );
    }
  }

  /**
   * Функция эмитит юзера с зависимости от переменных
   * @param {User} item
   */
  select(item: User) {
    if (!this.isAccessChangeOptions) {
      return;
    }
    this.onSelected.emit(item);
  }

  userDbClick() {
    this.onUserDbClick.emit();
  }
}
