<div class="calendar" [ngClass]="forPlanner ? 'for_planner' : ''" #calendarMain>
  <table>
    <colgroup>
      <col width="22px" />
      <col width="34px" />
      <col width="34px" />
      <col width="34px" />
      <col width="34px" />
      <col width="34px" />
      <col width="34px" />
      <col width="34px" />
    </colgroup>
    <tbody>
      <tr class="calendar-heading" [ngClass]="fillTitle ? 'fill_title' : ''" (click)="forPlanner && openMonthPlanner()">
        <td colspan="2" class="calendar-heading-left">
          <app-svg-icon
            [height]="12"
            [width]="8"
            name="left"
            (click)="changeMonth(-1)"
            *ngIf="!forPlanner"
          ></app-svg-icon>
        </td>
        <td class="calendar-heading-date" colspan="4">
          <span (click)="!forPlanner && showSelect(typeSelect.MONTH, $event)">{{ currentMonth }}</span
          ><ng-container *ngIf="!forPlanner">
            &nbsp;<span (click)="showSelect(typeSelect.YEAR, $event)">{{ currentYear }}</span>
          </ng-container>
        </td>
        <td class="calendar-heading-right" colspan="2">
          <app-svg-icon
            [height]="12"
            [width]="8"
            name="right"
            (click)="changeMonth(1)"
            *ngIf="!forPlanner"
          ></app-svg-icon>
        </td>
      </tr>
      <tr class="calendar-days-name">
        <td></td>
        <td [ngClass]="{ 'day-of-week-current': nowDayWeekNumber === 1 && currentDayOnCalendar }"><div>Пн</div></td>
        <td [ngClass]="{ 'day-of-week-current': nowDayWeekNumber === 2 && currentDayOnCalendar }"><div>Вт</div></td>
        <td [ngClass]="{ 'day-of-week-current': nowDayWeekNumber === 3 && currentDayOnCalendar }"><div>Ср</div></td>
        <td [ngClass]="{ 'day-of-week-current': nowDayWeekNumber === 4 && currentDayOnCalendar }"><div>Чт</div></td>
        <td [ngClass]="{ 'day-of-week-current': nowDayWeekNumber === 5 && currentDayOnCalendar }"><div>Пт</div></td>
        <td [ngClass]="{ 'day-of-week-current': nowDayWeekNumber === 6 && currentDayOnCalendar }"><div>Сб</div></td>
        <td [ngClass]="{ 'day-of-week-current': nowDayWeekNumber === 7 && currentDayOnCalendar }"><div>Вс</div></td>
      </tr>
      <ng-container *ngFor="let week of calendarData">
        <tr class="calendar-days">
          <td class="week-number" [ngClass]="{ 'week-current': nowWeek == week.number && currentDayOnCalendar }">
            {{ week.number }}
          </td>
          <td
            (click)="dpID ? selectDate(day) : eventForDay[day.day] ? infoForDay(day) : addEvent(day.dayForDatepicker)"
            style="position: relative"
            *ngFor="let day of week.days; let i = index"
            [class]="day.class"
            [ngClass]="{
              disabled: day.minDateDisabled || day.maxDateDisabled,
              'week-current': nowWeek == week.number && currentDayOnCalendar,
              'day-of-week-current': i === nowDayWeekNumber - 1 && currentDayOnCalendar,
              'day-off': i > 4,
              'day-event-end': eventForDay[day.day] == 1,
              'day-event': eventForDay[day.day] == 2
            }"
          >
            <div>{{ day.dayNumber }}</div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <div class="calendar-link_planner" *ngIf="!dpID && !forPlanner">
    <app-svg-icon [height]="18" [width]="16" name="planner"></app-svg-icon>
    <a [routerLink]="'/calendar'"> Перейти в планер </a>
  </div>
</div>
