import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserFile } from '../../models/user-file.model';

@Component({
  selector: 'app-file-manager-area-breadcrumbs',
  templateUrl: './file-manager-area-breadcrumbs.component.html',
  styleUrls: ['./file-manager-area-breadcrumbs.component.scss'],
})
export class FileManagerAreaBreadcrumbsComponent {
  @Input() breadcrumbs: UserFile[] = [];
  @Output() readonly onNavigateFolder = new EventEmitter<UserFile>();

  navigateFolder(file: UserFile): void {
    this.onNavigateFolder.emit(file);
  }
}
