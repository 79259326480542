import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UserFile } from '@app/file-manager/models/user-file.model';

@Component({
  selector: 'app-select-one-file-confirm-modal',
  templateUrl: './select-one-file-confirm-modal.component.html',
  styleUrls: ['./select-one-file-confirm-modal.component.scss'],
})
export class SelectOneFileConfirmModalComponent {
  @Input() file: UserFile;
  @Output() onSave = new EventEmitter<UserFile>();
  @Output() onView = new EventEmitter<void>();

  constructor(private readonly activeModal: NgbActiveModal) {}

  cancel(): void {
    this.activeModal.dismiss();
  }

  save(): void {
    this.onSave.emit(this.file);
  }

  viewFile(): void {
    this.onView.emit();
  }
}
