import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';

@Pipe({
  name: 'amount',
  pure: true,
})
export class AmountPipe implements PipeTransform {
  transform(value: any): string {
    return formatAmount(value);
  }
}

export function formatAmount(value) {
  const num = parseAmount(value);
  if (typeof num !== 'number') {
    return value;
  }
  return formatNumber(num, 'ru-RU', '1.2-2');
}

export function parseAmount(value) {
  if (!value || typeof value !== 'string') {
    return value;
  }
  const num = parseFloat(value.replace(/\s/g, '').replace(/,/, '.'));
  if (isNaN(num)) {
    return value;
  }
  return num;
}
