<div class="blue-links pt-3">
  <div *ngIf="currentTso?.id">
    <span class="mr-1">ОТП:</span><a (click)="openTsoEdit()">ID-{{ currentTso.id }},{{ currentTso | initials }}</a>
  </div>

  <div *ngIf="!currentTso?.id" class="blue-links">
    <span class="mr-1">ОТП не выбран</span><a (click)="openTsoEdit()">Выбрать</a>
  </div>
</div>

<app-modal-window [isShowed]="isShowed" (closeEvent)="closeTsoEdit()" customClass="change-tso-modal">
  <app-select-user-list
    [employees]="availableTso"
    [user]="currentTso"
    *ngIf="isShowed"
    (onChangeEvent)="changeSelection($event)"
  ></app-select-user-list>
  <div class="d-flex justify-content-center">
    <button class="btn btn-dc-primary" (click)="changeTso()">Сохранить</button>
  </div>
</app-modal-window>
