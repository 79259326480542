import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserFile } from '@app/file_manager/models/user-file.model';

@Component({
  selector: 'app-file-item-detail',
  templateUrl: './file-item-detail.component.html',
  styleUrls: ['./file-item-detail.component.scss'],
})
export class FileItemDetailComponent {
  @Input() file: UserFile;
  @Input() selected: UserFile[] = [];
  @Input() searchQuery: string;
  @Input() current: UserFile;

  @Output() onShare = new EventEmitter<UserFile[]>();

  hasSelected(file: UserFile): boolean {
    return this.selected.some((data: UserFile) => data.id === file.id);
  }

  share(event, file: UserFile[]): void {
    this.onShare.emit(file);
    event.stopPropagation();
  }

  toggleInfoUser(event, file: UserFile): void {
    file.showUserInfoInArea = !file.showUserInfoInArea;
    if (event) {
      event.stopPropagation();
    }
  }

  isSelected(file: UserFile): boolean {
    return file.id === this.current?.id;
  }
}
