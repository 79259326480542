import { Component } from '@angular/core';

import { FileManagerTreeItemComponent } from '../file-manager-tree-item/file-manager-tree-item.component';

@Component({
  selector: 'app-file-manager-tree-file',
  templateUrl: './file-manager-tree-file.component.html',
  styleUrls: ['./file-manager-tree-file.component.scss'],
})
export class FileManagerTreeFileComponent extends FileManagerTreeItemComponent {
  get isShowIcon(): boolean {
    if (this.file.virtualShared && this.file?.is_shared) {
      return true;
    }
    return this.file?.inner_sharing || (this.file?.is_shared && !this.file?.virtualShared);
  }
}
