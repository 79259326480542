import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isNegative',
})
export class IsNegativePipe implements PipeTransform {
  transform(value: number): boolean {
    return value < 0;
  }
}
