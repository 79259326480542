export enum ModalInjectorConstants {
  CALENDAR_WIDGET = 'calendar_widget',
  ADD_EVENT_CALENDAR = 'add_event_calendar',
  SHOW_EVENT_CALENDAR = 'show_event_calendar',
  INFO_FOR_DAY = 'info_for_day',
  SELECT_DATE = 'select_date',
  DELETE_EVENT = 'delete_event',
  DATEPICKER_WIDGET = 'datepicker_widget',
  TIMEPICKER_WIDGET = 'timepicker_widget',
  CLOSE_EVENT = 'close_event',
}
