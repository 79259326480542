import { Role } from '@app/shared/models/user.model';
import { Component, Output, EventEmitter, Input } from '@angular/core';
import { ROLES, RolesEnum } from '@app/shared/constants/roles.constants';
import { MultiSelectListItem } from '@app/shared/models/multi-select-list-item';

@Component({
  selector: 'app-chat-roles-filter',
  templateUrl: './chat-roles-filter.component.html',
  styleUrls: ['./chat-roles-filter.component.scss'],
})
export class ChatRolesFilterComponent {
  roles: Role[] = ROLES;
  list: MultiSelectListItem[] = [];

  @Input() selected: any[] = [];
  @Input() isDutyFilterModalOpened = false;

  @Output() submitEvent = new EventEmitter<string[]>();
  @Output() closeEvent = new EventEmitter();

  constructor() {
    this.list = this.roles
      .filter(role => role.value !== RolesEnum.PARTNER)
      .map(role => ({
        id: role.value,
        name: role.title,
      }));
  }

  closeDutyTsoFilter() {
    this.isDutyFilterModalOpened = false;
    this.closeEvent.emit();
  }

  applyDutyTsoFilter(event) {
    this.submitEvent.emit(event.value);
    this.closeDutyTsoFilter();
  }
}
