<div class="share-user-filter__header">
  <h3 class="share-user-filter__header-title">Настроить фильтр сотрудников</h3>
</div>

<div class="share-user-filter__body">
  <div class="share-user-filter__tabs">
    <div
      *ngFor="let tab of shareUserFilterTabs"
      (click)="changeTab(tab.value)"
      class="share-user-filter__tabs-item"
      [class.active]="tab.value === activeTab"
    >
      <div class="share-user-filter__tabs-item_title" [innerHTML]="tab.title"></div>
      <div class="share-user-filter__tabs-item_count">
        <span [innerHTML]="tab.count"></span>
      </div>
    </div>
  </div>

  <div class="share-user-filter__body-content">
    <ng-container [ngSwitch]="activeTab">
      <ng-container *ngSwitchCase="shareUserFilterTab.USER_ROLES">
        <app-file-manager-share-user-filter-roles
          [items]="roles"
          [selected]="filterConfig[shareUserFilterTab.USER_ROLES]"
          (selectRoleEmit)="changeFilter(shareUserFilterTab.USER_ROLES, $event)"
        ></app-file-manager-share-user-filter-roles>
      </ng-container>

      <ng-container *ngSwitchCase="shareUserFilterTab.GOODS">
        <app-file-manager-share-user-filter-tree
          [items]="goods"
          [type]="cmCatalogTypes.GOODS"
          [selected]="filterConfig[shareUserFilterTab.GOODS]"
          (selectItemEmit)="changeFilter(shareUserFilterTab.GOODS, $event)"
        ></app-file-manager-share-user-filter-tree>
      </ng-container>

      <ng-container *ngSwitchCase="shareUserFilterTab.SERVICES">
        <app-file-manager-share-user-filter-tree
          [items]="services"
          [type]="cmCatalogTypes.SERVICES"
          [selected]="filterConfig[shareUserFilterTab.SERVICES]"
          (selectItemEmit)="changeFilter(shareUserFilterTab.SERVICES, $event)"
        ></app-file-manager-share-user-filter-tree>
      </ng-container>
    </ng-container>
  </div>
</div>

<div class="share-user-filter__footer dc-footer-buttons">
  <button class="btn btn-dc-outline" (click)="cancel()">Отменить</button>
  <button class="btn btn-dc-primary" (click)="save()">Применить</button>
</div>
