import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@app/shared/models/user.model';
import { RolesEnum } from '@app/shared/constants/roles.constants';

@Pipe({
  name: 'contactsFilter',
})
export class ContactsFilterPipe implements PipeTransform {
  transform(
    items: User[],
    filterObject: {
      roles: RolesEnum[];
      competencies: string[];
    },
    sortField = 'second_name'
  ): any {
    if (!filterObject || (filterObject && !filterObject.roles && !filterObject.competencies)) {
      return this.sortItems(items, sortField);
    }

    if (!filterObject.roles.length && !filterObject.competencies.length) {
      return this.sortItems(items, sortField);
    }

    if (filterObject.roles.length) {
      items = this.filterByRoles(items, filterObject.roles, sortField);
    }

    if (filterObject.competencies.length) {
      items = this.filterByCompetencies(items, filterObject.competencies, sortField);
    }

    return items;
  }

  filterByRoles(users: User[], roles: RolesEnum[], sortField: string): User[] {
    return roles
      .map((role) => {
        return this.sortItems(
          users.filter((user) => {
            if (role === RolesEnum.TSO) {
              return user.flags.is_tso;
            }
            return user.type === role;
          }),
          sortField
        );
      })
      .filter((item) => item.length)
      .reduce((a, b) => [...a, ...b], []);
  }

  filterByCompetencies(users: User[], competencies: string[], sortField: string): User[] {
    return competencies
      .map((compId) => {
        return this.sortItems(
          users.filter((user) => user.competence_sections.map((section) => section.id).includes(+compId)),
          sortField
        );
      })
      .filter((item) => item.length)
      .reduce((a, b) => [...a, ...b], []);
  }

  sortItems(items: User[], sortField: string) {
    items.sort((a, b) => {
      return a[sortField].localeCompare(b[sortField], ['ru', 'en'], { sensitivity: 'base' });
    });

    return items;
  }
}
