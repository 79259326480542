import { DatesHelper } from '@app/shared/helpers/dates.helper';
import { filter, takeUntil, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DestroyService } from '@app/services/destroy.service';
import { AdditionalFilters } from '../../models/suppliers-selection.model';
import moment from 'moment';
import { DATE_FORMAT } from '@app/shared/constants/date.constants';

@Component({
  selector: 'app-additional-filters',
  templateUrl: './additional-filters.component.html',
  styleUrls: ['./additional-filters.component.scss'],
  providers: [DestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdditionalFiltersComponent implements OnInit {
  @Input() filters: AdditionalFilters;
  @Output() changed: EventEmitter<AdditionalFilters> = new EventEmitter<AdditionalFilters>();

  form;
  currentDate = moment();

  constructor(private fb: FormBuilder, private destroy$: DestroyService) {
    this.form = this.fb.group({
      not_natural_person: this.fb.control(false),
      from_date_registration_payer: this.fb.control(''),
      to_date_registration_payer: this.fb.control(''),
      from_date_state_registration: this.fb.control(''),
      to_date_state_registration: this.fb.control(''),
    });
  }

  ngOnInit(): void {
    if (this.filters) {
      this.form.setValue(this.filters);
    }

    this.form.valueChanges
      .pipe(
        debounceTime(100),
        distinctUntilChanged(this.compareFormData),
        filter(() => this.form.valid),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        const formValue = this.form.getRawValue();
        this.changed.emit({
          not_natural_person: formValue.not_natural_person,
          from_date_registration_payer: DatesHelper.fromDateToServerDate(
            formValue.from_date_registration_payer,
            DATE_FORMAT
          ),
          to_date_registration_payer: DatesHelper.fromDateToServerDate(
            formValue.to_date_registration_payer,
            DATE_FORMAT
          ),
          from_date_state_registration: DatesHelper.fromDateToServerDate(
            formValue.from_date_state_registration,
            DATE_FORMAT
          ),
          to_date_state_registration: DatesHelper.fromDateToServerDate(
            formValue.to_date_state_registration,
            DATE_FORMAT
          ),
        });
      });
  }

  private compareFormData(a: AdditionalFilters, b: AdditionalFilters): boolean {
    return (
      a.not_natural_person === b.not_natural_person &&
      a.from_date_registration_payer === b.from_date_registration_payer &&
      a.from_date_state_registration === b.from_date_state_registration &&
      a.to_date_registration_payer === b.to_date_registration_payer &&
      a.to_date_state_registration === b.to_date_state_registration
    );
  }
}
