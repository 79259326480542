import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NotificationsService } from 'angular2-notifications';
import { switchMap, takeUntil } from 'rxjs/operators';

import { AuthService } from '@app/shared/services/auth.service';
import { DestroyService } from '@app/services/destroy.service';

import { REDIRECT_AFTER_LOGIN } from '../redirect-after-login.constants';

@Component({
  template: ``,
  providers: [DestroyService],
})
export class InviteComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private destroyed: DestroyService,
    private router: Router,
    private notify: NotificationsService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(
        switchMap((params) => {
          const email = params['email'];
          const key = params['key'];
          if (email && key) {
            return this.authService.inviteConfirmUser(email, key);
          } else {
            throw new Error('Not email of key');
          }
        }),
        switchMap(() => {
          return this.authService.updateUser();
        }),
        takeUntil(this.destroyed)
      )
      .subscribe(
        () => {
          this.notify.success('Успешно!', 'Добро пожаловать!');
          this.router.navigate([REDIRECT_AFTER_LOGIN]);
        },
        () => {
          this.router.navigate(['signin']);
        }
      );
  }

  //   this.activatedRoute.queryParams.subscribe(params => {
  //     const email = params['email'];
  //     const key = params['key'];
  //     if (email && key)
  //       this.authService.inviteConfirmUser(email, key).subscribe(
  //         () => {
  //           this.notify.success('Успешно!', 'Добро пожаловать!');
  //           authService.updateUser().subscribe(user => this.router.navigate([REDIRECT_AFTER_LOGIN]));
  //         },
  //         () => this.router.navigate(['signin'])
  //       );
  //   });
  // }
}
