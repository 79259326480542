import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { passwordRequirements } from '@app/profile/constants/profile.modal.constants';
import { FormHelper } from '@app/shared/helpers/form.helper';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '@app/shared/services/auth.service';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-set-password-dialog',
  templateUrl: './set-password-dialog.component.html',
  styleUrls: ['./set-password-dialog.component.scss'],
})
export class SetPasswordDialogComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public passwordUpdated: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private activeModal: NgbActiveModal,
    private authService: AuthService,
    private notify: NotificationsService
  ) {}

  get newPasswordInvalid(): boolean {
    return this.form.get('new_password').touched && this.form.get('new_password').invalid;
  }

  get notEquivalentPasswords(): boolean | null {
    if (this.form.get('confirm_password').touched) {
      return this.form.get('confirm_password').errors?.notEquivalent;
    }
    return null;
  }

  ngOnInit(): void {
    this.form = this.fb.group(
      {
        new_password: [null, Validators.pattern(passwordRequirements)],
        confirm_password: [null, Validators.required],
      },
      { validator: FormHelper.checkIfMatchingPasswords('new_password', 'confirm_password') }
    );
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.ngUnsubscribe = null;
  }

  public close(success: boolean = false): void {
    this.activeModal.close({ success: success });
  }

  public success(): void {
    if (this.form.valid) {
      const { new_password } = this.form.value;
      this.authService
        .changePassword('***', new_password)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          () => {
            this.passwordUpdated = true;
            this.notify.success('УСПЕШНО', 'Данные успешно сохранены');
            this.close(true);
          },
          (err) => {}
        );
    }
  }
}
