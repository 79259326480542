import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const PASSWORD_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PasswordFormInputComponent),
  multi: true,
};

@Component({
  selector: 'app-password-form-input',
  templateUrl: './password-form-input.component.html',
  styleUrls: ['./password-form-input.component.scss'],
  providers: [PASSWORD_VALUE_ACCESSOR],
})
export class PasswordFormInputComponent implements ControlValueAccessor {
  public isShow: boolean = false;
  @Input() disabled: boolean;
  @Input() placeholder: string = '';
  @Input() classes: string[];
  value: any = '';

  constructor() {}

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  changeInputType() {
    this.isShow = !this.isShow;
  }
}
