<div
  *ngIf="tab.group_id"
  (click)="onTabSelect()"
  class="chat__tab"
  [ngClass]="{ active: active, new_messages: !active && tab.counter }"
  title="Тема {{ tab.title }}"
>
  Тема {{ tab.title }}
  <a (click)="onTabRemove($event)" *ngIf="active" class="chat__tab-times">
    <fa-icon [icon]="['fas', 'times']"></fa-icon>
  </a>
  <span *ngIf="!active && tab.counter" class="chat__tab-counter">
    {{ tab.counter }}
  </span>
</div>
<div
  *ngIf="tab.first_name || tab.second_name || tab.email"
  (click)="onTabSelect()"
  class="chat__tab"
  [ngClass]="{ active: active, new_messages: !active && tab.counter }"
  title="{{ getTabTitle() }}"
>
  {{ getTabTitle() }}
  <a (click)="onTabRemove($event)" *ngIf="active" class="chat__tab-times">
    <fa-icon [icon]="['fas', 'times']"></fa-icon>
  </a>
  <span *ngIf="!active && tab.counter" class="chat__tab-counter">
    {{ tab.counter }}
  </span>
</div>
<div
  *ngIf="tab.title && !tab.group_id"
  (click)="onTabSelect()"
  class="chat__tab"
  [ngClass]="{ active: active, new_messages: !active && tab.counter }"
  title="Группа {{ tab.title }}"
>
  Группа {{ tab.title }}
  <a (click)="onTabRemove($event)" *ngIf="active" class="chat__tab-times">
    <fa-icon [icon]="['fas', 'times']"></fa-icon>
  </a>
  <span *ngIf="!active && tab.counter" class="chat__tab-counter">
    {{ tab.counter }}
  </span>
</div>
