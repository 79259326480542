import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { TreeNode } from '@circlon/angular-tree-component';
import { AuthorFile } from '@app/file_manager/models/author-file.model';
import { Subject } from 'rxjs';
import { SHARE_TYPE } from '@app/file_manager/file_manager.constants';

@Component({
  selector: 'app-share-tree-item',
  templateUrl: './share-tree-item.component.html',
  styleUrls: ['./share-tree-item.component.scss'],
})
export class ShareTreeItemComponent implements OnDestroy {
  @Input() node: TreeNode;
  @Input() item: AuthorFile;
  @Input() type: SHARE_TYPE;
  @Input() detached: boolean;

  @Output() onToggle = new EventEmitter();
  @Output() onInfo = new EventEmitter<AuthorFile>();
  @Output() onCompetence = new EventEmitter<AuthorFile>();
  @Output() onAdd = new EventEmitter<AuthorFile>();

  private dbClick$: Subject<boolean> = new Subject();

  SHARE_TYPE = SHARE_TYPE;

  ngOnDestroy() {
    this.dbClick$.complete();
  }

  toggleNodeClicked(): void {
    this.onToggle.emit();
  }

  toggleSelected(items: AuthorFile[]) {
    items.forEach((item: AuthorFile) => {
      item.selected = this.item.selected;
      if (item.children) {
        this.toggleSelected(item.children);
      }
    });
  }

  add(): void {
    this.dbClick$.next(true);
    this.item.selected = true;
    if (this.node && this.item.children) {
      this.toggleSelected(this.item.children);
    }
    this.onAdd.emit(this.item);
  }

  onCheckboxChange(event): void {
    event.stopPropagation();
    this.item.selected = !this.item.selected;
    if (this.node && this.item.children) {
      this.toggleSelected(this.item.children);
    }
  }

  info(event): void {
    this.onInfo.emit(this.item);
    event.stopPropagation();
  }

  competence(event): void {
    this.onCompetence.emit(this.item);
    event.stopPropagation();
  }
}
