import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { profileDocumentColumns } from '@app/profile/constants/profile-document.constants';
import { DocumentArchive } from '@app/shared/models/document-archive.interface';

@Component({
  selector: 'app-profile-documents-dialog',
  templateUrl: './profile-documents-dialog.component.html',
  styleUrls: ['./profile-documents-dialog.component.scss']
})
export class ProfileDocumentsDialogComponent implements OnInit {

  @Input() title: string;
  @Input() documents: DocumentArchive[];

  public columns = profileDocumentColumns;

  constructor(
    private activeModal: NgbActiveModal,
  ) {}

  ngOnInit(): void {
  }

  public close(): void {
    this.activeModal.close();
  }

}
