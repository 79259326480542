import { Directive, ElementRef, HostListener, Input, OnChanges } from '@angular/core';
import { FormControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[onlyLetter]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: OnlyLetterDirective,
      multi: true,
    },
  ],
})
export class OnlyLetterDirective implements OnChanges {
  private specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    'Control',
    'v',
    'ArrowLeft',
    'ArrowRight',
    'Delete',
  ];

  @Input() onlyLetter: boolean = true;
  @Input() isSpace: boolean = false;

  private regexPreventInput: RegExp;
  private rgxValidation: RegExp;

  constructor(private el: ElementRef) {
    this.regexPreventInput = this.getReqExp();
    this.rgxValidation = this.getReqExp(false);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);
    const selected = this.getSelectedText(event.target);
    if (next && !String(next).match(this.regexPreventInput) && !selected) {
      event.preventDefault();
      return;
    }

    if (!this.rgxValidation.test(next)) {
      event.preventDefault();
    }
  }

  ngOnChanges() {
    this.regexPreventInput = this.getReqExp();
  }

  validate(c: FormControl): { [key: string]: { valid: boolean } } {
    const v = c.value;
    const invalid = { onlyLetter: { valid: false } };

    if (v && !this.rgxValidation.test(v)) {
      return invalid;
    }

    return null;
  }

  getReqExp(firstChar: boolean = true): RegExp {
    let result: string;
    if (this.onlyLetter) {
      result = `${firstChar ? '^' : ''}([a-zA-Zа-яА-Я${this.isSpace ? '" "' : ''}]+)*$`;
    } else {
      result = `${firstChar ? '^' : ''}([a-zA-Zа-яА-Я0-9${this.isSpace ? '" "' : ''}])*$`;
    }
    return new RegExp(result, firstChar ? 'g' : '');
  }

  getSelectedText(elem) {
    if (elem.tagName === 'TEXTAREA' || (elem.tagName === 'INPUT' && elem.type === 'text')) {
      return elem.value.substring(elem.selectionStart, elem.selectionEnd);
    }
    return null;
  }
}
