<ng-select
  class="bootstrap-select dc-select"
  typeToSearchText="Начните вводить адрес"
  notFoundText="По вашему запросу ничего не найдено"
  [virtualScroll]="true"
  [items]="locations$ | async"
  [typeahead]="locationSearchSubject"
  [loading]="loadingLocations"
  [editableSearchTerm]="true"
  (change)="onChange($event)"
  dropdownPosition="auto"
  [(ngModel)]="value"
  [ngClass]="{ 'is-invalid': invalid }"
>
  <ng-template ng-option-tmp let-item="item">
    <div class="dc-select-item">{{ item }}</div>
  </ng-template>
</ng-select>
<div class="dc-select-message" *ngIf="userId && !invalid">
  <div class="dc-select-message-question">Не нашли свой адрес?</div>
  <div class="dc-select-message-answer">
    Обратитесь к оператору&nbsp;<span class="underline">технической поддержки</span>
    <app-svg-icon [chatLink]="userId" [height]="14" [width]="16" name="comment"></app-svg-icon>
  </div>
</div>
<div class="dc-error-message" *ngIf="invalid">Это поле обязательно для заполения</div>
