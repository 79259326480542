<div class="fileman-container-list" [class.active]="hasSelected(file)">
  <div class="margin-r-20 fileman-checkbox-container">
    <input [checked]="hasSelected(file)" class="cbx-input" [id]="file.id" type="checkbox" />
    <label class="cbx-label" [for]="file.id"></label>
  </div>
  <a href class="margin-right-link icon">
    <app-svg-icon
      [width]="file.hasFile() ? 12 : 16"
      [height]="file.hasFile() ? 16 : 12"
      [name]="file.hasFile() ? file.extType().icon : 'folder'"
    ></app-svg-icon>
  </a>
  <a href class="text-overflow fileman-ul-container-list-link mr-2">
    <span [innerHTML]="file.name | highlight: searchQuery"></span>
  </a>
  <button
    class="btn-link btn-link__share hover margin-right-link"
    *ngIf="file.is_shared && !file.hasShare()"
    (click)="share($event, [file])"
  >
    <app-svg-icon [width]="20" [height]="20" [name]="'users'"></app-svg-icon>
  </button>
  <button
    class="btn-link btn-link__share hover margin-right-link"
    *ngIf="file.hasShare() && !file.hasTrade()"
    (click)="toggleInfoUser($event, file)"
  >
    <app-svg-icon [width]="20" [height]="20" [name]="'crown'"></app-svg-icon>
  </button>
  <app-modal-window [isShowed]="file.hasShare() && file.showUserInfoInArea" (closeEvent)="toggleInfoUser($event, file)">
    <app-user-card [user]="file.author" *ngIf="!!file.showUserInfoInArea"></app-user-card>
  </app-modal-window>
  <ng-container *ngIf="layout === fileManagerLayouts.TRADES && file.is_file">
    <a
      class="mr-1 fileman-ul-container-list-access-checkbox"
      [class.disabled]="!hasSelected(file)"
      (click)="preventSelect($event)"
    >
      <input
        id="customer{{ file.id }}"
        class="cbx-input"
        type="checkbox"
        [readonly]="!hasSelected(file)"
        [(ngModel)]="file.for_customers"
        (change)="fileCustomerAccessChanged($event, file)"
      />
      <label class="cbx-label" for="customer{{ file.id }}">
        <span class="cbx-label-text">Для заказчика</span>
      </label>
    </a>
    <a
      class="fileman-ul-container-list-access-checkbox"
      [class.disabled]="!hasSelected(file)"
      (click)="preventSelect($event)"
    >
      <input
        id="all{{ file.id }}"
        class="cbx-input"
        type="checkbox"
        [readonly]="!hasSelected(file)"
        [(ngModel)]="file.for_providers"
        (change)="fileAllAccessChanged($event, file)"
      />
      <label class="cbx-label" for="all{{ file.id }}">
        <span class="cbx-label-text">Для всех</span>
      </label>
    </a>
  </ng-container>
</div>
