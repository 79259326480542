import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import * as moment from 'moment';
import { AuthService } from '@app/shared/services/auth.service';
import { CalendarService } from '@app/shared/services/calendar/calendar.service';
import { CalendarDataService } from '@app/shared/services/calendar/calendar-data.service';
import {ModalInjectorService} from "@app/modal-injector/services/modal-injector.service";
import {EventDayListModel, STATE_DAY_PLANNER} from "@app/calendar/constants/calendar.constants";
import {FINISH_STATE, LIST_STATE, STATE} from "@app/shared/components/calendar-widget/constants/calendar.constants";
import {ModalInjectorConstants} from "@app/modal-injector/constants/modal-injector.constants";
import {DATE_FORMAT} from "@app/shared/constants/date.constants";
import {POSITION} from "@app/shared/components/move-pin/constants/move-pin.constants";

@Component({
  selector: 'app-view-month',
  templateUrl: './view-month.component.html',
  styleUrls: ['./view-month.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ViewMonthComponent implements AfterViewInit {

  @Input() set calendarData(value) {
    this.inputCalendarData = value;
    this.recalculationHeight();
  }
  @Input() currentDayOnCalendar;
  @Input() eventForDay: EventDayListModel;
  @Input() set heightView(value: number) {
    this.inputHeightView = value;
    this.recalculationHeight();
  }
  inputHeightView: number;
  inputCalendarData;
  state_day = STATE_DAY_PLANNER;
  maxValueHeightTd = 105;
  state = STATE;
  finishState = FINISH_STATE;
  maxLengthList = 3;
  heightTd = this.maxValueHeightTd;
  paddingBottom = 35;
  nowDayWeekNumber: number = moment().locale('ru').day();
  nowWeek: number = moment().locale('ru').isoWeek();
  listState = LIST_STATE;
  @ViewChild('firstRowMonth') private firstRowMonth: ElementRef;

  constructor(
    private authService: AuthService,
    private calendarDataService: CalendarDataService,
    private calendarService: CalendarService,
    private modalInjectorService: ModalInjectorService,
  ) {}

  ngAfterViewInit() {
    this.recalculationHeight();
  }

  recalculationHeight() {
    if (this.inputHeightView && this.firstRowMonth?.nativeElement?.offsetHeight) {
      let newHeight = Math.floor((this.inputHeightView - this.firstRowMonth?.nativeElement?.offsetHeight - this.paddingBottom) / (this.inputCalendarData.length || 5));
      if (newHeight < this.maxValueHeightTd) newHeight = this.maxValueHeightTd
      this.heightTd = newHeight;
      this.maxLengthList = Math.floor((this.heightTd-33) / 24)
    }
  }

  setColor(state) {
    return this.listState.find((item) => item.key == state).color;
  }

  infoForDay(event, day) {
    const parent = event.target.getBoundingClientRect();
    this.modalInjectorService.addModal(ModalInjectorConstants.INFO_FOR_DAY, {
      day: day.day,
      dayForDatepicker: day.dayForDatepicker,
      parent,
      position: POSITION.CENTER,
    });
  }

  checkDay(day) {
    return +moment(day, DATE_FORMAT) >= +moment().startOf('days');
  }

  findStateHelper(state) {
    return this.listState.find(item => item.key == state)?.text || ''
  }

  openWeekPlanner(week) {
    this.calendarDataService.openWeekPlanner({ week: week.number, year: week.days[0].day.slice(0,4) });
  }

  addEvent(day) {
    this.modalInjectorService.addModal(ModalInjectorConstants.ADD_EVENT_CALENDAR, { show: true, addEvent: true, day });
  }

}
