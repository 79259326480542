<div (click)="open($event)" class="tooltip-container" #container [ngClass]="{ white: tooltipWhite }">
  <div class="ico" #icon>
    <fa-icon [icon]="['fas', 'question-circle']"></fa-icon>
  </div>
  <div class="tooltip-text-container shadow" appClickElsewhere="" (appClickElsewhere)="close()" #textContainer>
    <div class="close-tooltip-icon">
      <fa-icon [icon]="['fas', 'times']" class="fa-times" (click)="close()"></fa-icon>
    </div>
    <fa-icon *ngIf="isLoading" class="fa-spin" [icon]="['fas', 'spinner']" [spin]="true"></fa-icon>
    <span *ngIf="hint" class="hint-value">{{ hint.value }}</span>
    <div class="faq-hint-images" *ngIf="hint && hint.status === FaqQuestionStatus.Approved">
      <app-faq-files-field-component
        [field]="FaqTabs.Modules"
        [itemId]="hint.id"
        [readonly]="true"
      ></app-faq-files-field-component>
    </div>
  </div>
</div>
