import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';

import { Subject } from 'rxjs';

import { AuthService } from '@app/shared/services/auth.service';
import { ChatService } from '@app/chat/services/chat.service';
import { SocketDataService } from '@app/services/socket-data.service';
import { ChatMessage, ChatRoom } from '@app/chat/models/chat.model';
import { FileManagerService } from '@app/file_manager/services/file_manager.service';

import saveAs from 'file-saver';
import { User } from '@app/shared/models/user.model';
import { ChatSectionsEnum } from '@app/chat/constants/chat-sections.constants';

@Component({
  selector: 'app-chat-messages',
  templateUrl: './chat-messages.component.html',
  styleUrls: ['./chat-messages.component.scss'],
})
export class ChatMessagesComponent implements OnInit, OnDestroy, AfterViewChecked {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  @ViewChild('scrollMe') private scrollMe: ElementRef;
  messages: ChatMessage[] = [];
  roomSelected: ChatRoom;
  userId;
  skipScroll = false;
  updateScroll = false;
  scrollHeight = 0;
  count: number;
  chatSectionEnum = ChatSectionsEnum;
  isTradeGroup = this.chatService.isTradeGroup;

  constructor(
    private chatService: ChatService,
    private chatDataService: SocketDataService,
    private authService: AuthService,
    private fileManagerService: FileManagerService
  ) {
    this.userId = this.authService.user_id;

    this.chatService.contactSelectedChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((roomSelected) => {
      this.roomSelected = roomSelected;
      this.updateScroll = true;

      if (this.roomSelected && this.roomSelected.room_id) {
        this.chatDataService.loadPreviousMessages({ room_id: this.roomSelected.room_id });
      }
    });
  }

  ngOnInit() {
    this.chatService.messagesChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((messages) => {
      console.log(messages);
      this.roomSelected = this.chatService.getContactSelected();

      const { room_id } = this.roomSelected;
      if (this.skipScroll) {
        this.skipScroll = false;
      } else {
        this.updateScroll = true;
      }

      if (!messages[room_id]) {
        return;
      }

      if (room_id) {
        const prevMessagesLength = this.messages.length;
        const newMessagesLength = messages[room_id].list.length || 0;

        if (prevMessagesLength < newMessagesLength) {
          this.chatDataService.setMessageRead(room_id);
        }
      }
      this.updateMessages(room_id, messages[room_id].list || []);
    });
  }

  ngAfterViewChecked() {
    if (this.updateScroll) {
      this.scrollMe.nativeElement.scrollTop = this.scrollMe.nativeElement.scrollHeight;
      this.updateScroll = false;
    }
  }

  updateMessages(room_id: string, messages: ChatMessage[]) {
    this.messages = JSON.parse(JSON.stringify(messages));
    this.count = this.chatService.getMessagesRoomStore(room_id).count;

    const tradeGroup = this.chatService.getGroups()[`dg-${this.roomSelected.group_id}`];

    this.messages.forEach((message) => {
      if (tradeGroup) {
        if (message.flags && message.flags.trade_provider && !tradeGroup.providers.includes(+this.userId)) {
          message.author = {
            second_name: 'Поставщик',
          } as User;
        }

        if (message.flags && message.flags.trade_customer && !tradeGroup.customers.includes(+this.userId)) {
          message.author = {
            second_name: 'Заказчик',
          } as User;
        }
      }

      // TODO: почему-то flags стало приходить пустое
      if (message.author.flags?.is_tso) {
        message.author.second_name = `${message.author.id}, ${message.author.second_name}`;
      }
    });
  }

  showMoreMessages() {
    this.skipScroll = true;

    this.chatDataService.loadPreviousMessages({
      room_id: this.roomSelected.room_id,
    });
  }

  onRemoveMessage(message) {
    this.chatDataService.deleteMessage(message.id);
  }

  downloadFile(file) {
    this.fileManagerService.downloadFile(file.url).subscribe((blob) => {
      saveAs(blob, `${file.filename}`);
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
