<div class="wrapper form-group">
  <div class="search">
    <fa-icon *ngIf="isLoading" class="fa-spin" [icon]="['fas', 'spinner']" [spin]="true"></fa-icon>
    <input
      class="form-control"
      #searchLocation
      (focusout)="searchFocusOut()"
      [disabled]="disabled"
      [(value)]="searchText"
      (keyup)="onKeyup(searchLocation.value)"
      type="text"
      placeholder="Название..."
      title="введите название города"
    />
    <fa-icon
      *ngIf="showAddLocation && element"
      class="ico-add add-title"
      [icon]="['fas', 'plus-square']"
      (mousedown)="$event.preventDefault()"
      (click)="addNewLocation()"
    ></fa-icon>
  </div>
  <div class="select" *ngIf="locations.length">
    <div class="input-group-btn">
      <div class="dropdown-menu" [ngStyle]="{ display: 'block', 'margin-top': '-.875rem' }">
        <a
          class="dropdown-item"
          *ngFor="let location of locations"
          (mousedown)="$event.preventDefault()"
          (click)="onSelectLocation(location)"
          [ngStyle]="{ cursor: 'pointer' }"
        >
          <span *ngIf="location.city.title">{{ location.city.title }} </span>
          <div class="small-text">
            <span *ngIf="location.region.title">{{ location.region.title }} </span>
            <span *ngIf="location.country.title">{{ location.country.title }} </span>
          </div>
        </a>
      </div>
    </div>
  </div>
  <small *ngIf="location.isSelected && location.city.id"
    >{{ location.region.title }}, {{ location.country.title }}</small
  >
  <a *ngIf="isAddCityButtonVisible" href="#" (click)="$event.preventDefault(); onAddLocationLinkClick()">
    Не нашли свой город?
  </a>
</div>

<ng-template #add_location_modal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <div class="modal-title">Добавление новой локации</div>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <form #formRef="ngForm" action="">
            <label class="location-item">
              Страна
              <input required [(ngModel)]="newCountry" type="text" name="newCountry" class="form-control" />
            </label>
            <label class="location-item">
              Область / край
              <input required [(ngModel)]="newRegion" type="text" name="newRegion" class="form-control" />
            </label>
            <label class="location-item">
              Город
              <input required [(ngModel)]="newCity" type="text" name="newCity" class="form-control" />
            </label>
            <br />
            <label class="location-item text-area">
              Комментарий
              <textarea [(ngModel)]="newComment" type="text" name="newComment" class="form-control"> </textarea>
            </label>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn btn-dc-outline" (click)="d('Close')">Отмена</button>
    <button type="button" class="btn btn-dc-primary">Отправить</button>
  </div>
</ng-template>
