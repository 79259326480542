import { Component, Input, OnInit } from '@angular/core';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from 'angular2-notifications';

import { EMPTY, forkJoin, Observable } from 'rxjs';
import { expand, map, takeUntil, reduce, filter } from 'rxjs/operators';

import { DestroyService } from '@app/services/destroy.service';
import { AuthService } from '@app/shared/services/auth.service';

import { FileManagerService } from '@app/file-manager/services/file-manager.service';
import { FileManagerDataService } from '@app/file-manager/services/file-manager-data.service';
import { ViewModeEnum } from '@app/file-manager/models/view-mode.enum';
import { UserFile } from '@app/file-manager/models/user-file.model';
import { FileManagerBaseComponent } from '@app/file-manager/components/file-manager-base/file-manager-base.component';
import { SelectOneFileConfirmModalComponent } from '@app/file-manager/file-manager-export/select-one-file-confirm-modal/select-one-file-confirm-modal.component';
import { FileManagerModalShareFilesComponent } from '@app/file-manager/components/file-manager-modal-share-files/file-manager-modal-share-files.component';

export enum ExportFileManagerType {
  TRADES,
  TRADE_LOGO,
  PROFILE,
  CHAT,
  COMPETENCE_MAP,
}

@Component({
  selector: 'app-file-manager-export',
  templateUrl: './file-manager-export.component.html',
  styleUrls: ['./file-manager-export.component.scss'],
  providers: [DestroyService],
})
export class FileManagerExportComponent extends FileManagerBaseComponent implements OnInit {
  fullSelectedFiles: UserFile[] = [];
  @Input() title?: string;
  @Input() subTitle?: string;
  @Input() selectFolders: boolean = true;
  @Input() selectMultiple: boolean = true;
  @Input() exportType: ExportFileManagerType;
  @Input() viewMode = ViewModeEnum.LIST;
  @Input() fileExtensions?: string[];
  @Input() alwaysShowCancel?: boolean = false;
  @Input() set addedFiles(files: UserFile[]) {
    this.fileManagerInitialized$
      .pipe(
        filter((Initialized) => Initialized),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        for (let i = 0; i < files.length; i++) {
          const file = this.findFile(files[i].id);
          if (file) {
            file.virtualDisabled = true;
          }
        }
      });
  }

  get exportFileManagerType(): typeof ExportFileManagerType {
    return ExportFileManagerType;
  }

  get selectedFilesAndFolders(): UserFile[] {
    return [...new Set([...this.selectedFiles, ...this.fullSelectedFiles])];
  }

  constructor(
    readonly user: AuthService,
    readonly modalService: NgbModal,
    readonly destroy$: DestroyService,
    readonly notify: NotificationsService,
    readonly fileManagerService: FileManagerService,
    readonly fileManagerDataService: FileManagerDataService,
    private readonly activeModal: NgbActiveModal
  ) {
    super(user, modalService, destroy$, notify, fileManagerService, fileManagerDataService);
  }

  ngOnInit(): void {
    if (this.fileExtensions?.length || this.exportType === ExportFileManagerType.TRADE_LOGO) {
      super.search('', this.fileExtensions);
    } else {
      this.updateSpaceInfo();
      this.initialize();
    }
  }

  save(): void {
    let selectedFiles: UserFile[] = [...this.selectedFiles.filter((item) => item.hasFile())];
    if (this.selectFolders) {
      const onlyFoldersSelected = this.selectedFiles.filter((item) => !item.hasFile());
      const filesInFolder: Array<Observable<UserFile[]>> = [];

      if (onlyFoldersSelected?.length) {
        for (const userFile of onlyFoldersSelected) {
          filesInFolder.push(this.getFileInFolderRecursive(userFile));
        }
      }

      forkJoin(filesInFolder)
        .pipe(
          reduce((result, item) => {
            return result.concat(...item);
          }, []),
          takeUntil(this.destroy$)
        )
        .subscribe((list) => {
          selectedFiles = [...selectedFiles, ...list];
          this.activeModal.close(selectedFiles);
        });
    } else {
      this.activeModal.close(selectedFiles);
    }
  }

  resetSelectedFiles() {
    super.resetSelectedFiles();
    this.fullSelectedFiles = [];
  }

  cancel(): void {
    this.activeModal.close();
  }

  shareFile(file?: UserFile[] | UserFile): void {
    const files = file ? (Array.isArray(file) ? file : [file]) : this.selectedFiles;
    const modal = this.modalService.open(FileManagerModalShareFilesComponent, {
      centered: true,
      animation: true,
      windowClass: 'dc-modal modal-window',
      size: 'xl',
    });

    modal.componentInstance.files = files;
    modal.componentInstance.title = 'Управление правами доступа (только просмотр)';
    modal.componentInstance.readonly = true;
    modal.componentInstance.authUserRole = this.user.user_type;
    modal.result.then(
      () => {},
      () => {}
    );
  }

  setSelectFiles(list: UserFile[]): void {
    if (this.exportType === ExportFileManagerType.PROFILE || this.exportType === ExportFileManagerType.TRADE_LOGO) {
      this.selectedFiles = list;
      this.confirmDialogForProfile(list[0]);
    } else {
      super.setSelectFiles(list);
      const result: UserFile[] = this.selectedFiles.filter((file) => file.hasFile());
      const selectedFolders: { folderId: number; files$: Observable<UserFile[]> }[] = [];
      this.selectedFiles.forEach((file) => {
        if (!file.hasFile()) {
          selectedFolders.push({ folderId: file.id, files$: this.getFileInFolderRecursive(file) });
        }
      });
      this.fullSelectedFiles = result;

      forkJoin(selectedFolders.map((selectedFolder) => selectedFolder.files$))
        .pipe(takeUntil(this.destroy$))
        .subscribe((folderFiles) => {
          const folders = this.selectedFiles.filter((file) => !file.hasFile()).map((file) => file.id);
          selectedFolders.forEach((selectedFile, index) => {
            if (folders.includes(selectedFile.folderId)) {
              result.push(...folderFiles[index]);
            }
          });

          this.fullSelectedFiles = result;
        });
    }
  }

  private getFileInFolderRecursive(file: UserFile): Observable<UserFile[]> {
    return this.fileManagerService.getChildrenFilesStream(file.id, file.virtualShared).pipe(
      expand((item: UserFile) => {
        return item.hasFile()
          ? EMPTY
          : this.fileManagerService.getChildrenFilesStream(item.id, item.virtualShared).pipe(takeUntil(this.destroy$));
      }),
      reduce((result, item) => {
        return result.concat(item);
      }, []),
      map((list) => list.filter((item) => item.hasFile())),
      takeUntil(this.destroy$)
    );
  }

  private confirmDialogForProfile(file: UserFile): void {
    const modal = this.modalService.open(SelectOneFileConfirmModalComponent, {
      centered: true,
      animation: true,
      windowClass: 'dc-modal fileman-modal modal-window',
    });
    modal.componentInstance.file = file;
    modal.componentInstance.onSave.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      modal.dismiss();
      this.activeModal.close({ file: value });
    });
    modal.componentInstance.onView.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.previewDocument(file);
    });
    modal.result.then(
      () => {},
      () => {
        // В случае отмены либо закрытия очищаем буфер
        this.resetSelectedFiles();
      }
    );
  }
}
