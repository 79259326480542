import { Component, EventEmitter, Input, Output, OnDestroy } from '@angular/core';
import { TreeNode } from '@circlon/angular-tree-component';
import { Subject } from 'rxjs';
import { DCTreeItem } from '@app/+competence-map/models/competence-map.models';
import { CMCatalogTypes } from '@app/+competence-map/constants/sections.constants';

@Component({
  selector: 'app-share-filter-item',
  templateUrl: './share-filter-item.component.html',
  styleUrls: ['./share-filter-item.component.scss'],
})
export class ShareFilterItemComponent implements OnDestroy {
  @Input() node: TreeNode;
  @Input() item: DCTreeItem;
  @Input() type: CMCatalogTypes;

  @Output() onToggle = new EventEmitter();
  @Output() onSelect = new EventEmitter<DCTreeItem>();

  private dbClick$: Subject<boolean> = new Subject();

  ngOnDestroy() {
    this.dbClick$.complete();
  }

  toggleSelected(items: DCTreeItem[]) {
    items.forEach((item: DCTreeItem) => {
      item.selected = this.item.selected;
      this.onSelect.emit(item);
      if (item.children) {
        this.toggleSelected(item.children);
      }
    });
  }

  toggleNodeClicked(): void {
    this.dbClick$.next(true);
    this.onToggle.emit();
  }

  onCheckboxChange(event): void {
    event.stopPropagation();
    this.item.selected = !this.item.selected;
    this.onSelect.emit(this.item);
    if (this.node && this.item.children) {
      this.toggleSelected(this.item.children);
    }
  }
}
