import { Column } from '@app/shared/models/table';

export const profileDocumentColumns: Column<any>[] = [
  {
    id: 'name',
    label: 'Наименование',
    width: 30
  },
  {
    id: 'begin',
    label: 'Начало действия (GMT)',
    width: 35
  },
  {
    id: 'end',
    label: 'Окончание действия (GMT)',
    width: 35
  },
];
