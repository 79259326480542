<div class="d-flex mb-4 align-items-center">
  <h2 class="mr-5 mb-0">Разделы каталога</h2>
  <div>
    <input
      class="cbx-input"
      id="show_my_{{ catalog }}"
      type="checkbox"
      [class.disabled]="data.length"
      [(ngModel)]="isShowedMy"
      (ngModelChange)="showOnlyMySections()"
    />
    <label class="cbx-label" for="show_my_{{ catalog }}">
      <span class="cbx-label-text color-brand-light">Показать мои компетенции</span>
    </label>
  </div>
</div>

<div class="d-flex">
  <div class="mr-2" [class.disabled]="readonly">
    <input
      class="cbx-input"
      id="all_selected_{{ catalog }}"
      type="checkbox"
      [ngModel]="allSectionsSelected"
      (ngModelChange)="selectAll($event)"
    />
    <label
      class="cbx-label"
      for="all_selected_{{ catalog }}"
      [class.cbx-label-interminate]="allPartiallySelected"
    ></label>
  </div>
  <div>
    <div class="competence-map-section-header">
      <div class="competence-map-section-header-name">
        <span class="mr-2">Название раздела</span>
        <div class="dc-icon-filter-button">
          <button class="dc-icon-button" (click)="openSearchModal()" [class.active]="hasFilterValue(CMActions.SEARCH)">
            <app-svg-icon name="search" width="12" height="12"></app-svg-icon>
          </button>
          <button
            class="dc-icon-button ml-1"
            (click)="filterTreeData([])"
            [class.dc-icon-button-hidden]="!hasFilterValue(CMActions.SEARCH)"
          >
            <app-svg-icon name="close_wide" width="12" height="12"></app-svg-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="competence-map-section-toggles">
      <button class="dc-icon-button" (click)="toggleByLevel(catalogTree, 1)">
        <app-svg-icon [name]="!isCatalogTreeExpandedL1 ? 'caret_down' : 'caret_up'" width="10"></app-svg-icon>
      </button>
      <button class="dc-icon-button" (click)="toggleByLevel(catalogTree, 2)">
        <app-svg-icon [name]="!isCatalogTreeExpandedL2 ? 'caret_down' : 'caret_up'" width="10"></app-svg-icon>
      </button>
    </div>
  </div>
</div>

<tree-root
  #catalogTree
  (toggleExpanded)="keepCaretState('editorCarets', $event)"
  [nodes]="data"
  [options]="treeOptions"
  class="dc-tree dc-tree--my-competencies my-competences-container"
>
  <ng-template #treeNodeTemplate let-node let-index="index">
    <app-my-catalog-tree-item
      [node]="node"
      [checked]="node.data | cmMapper : getValue : selectionMap"
      [selected]="node.data.id === selectedItemId"
      (onSelect)="sectionSelected($event)"
      [readonly]="readonly"
      [isConsiderFilterForDisplayCheckboxes]="!isCheckboxShowsSelected"
      [isFiltered]="isShowedMy"
      (onToggle)="expandItem(catalogTree, node, $event)"
      (onCheckboxChanges)="checkboxSelectionChanged($event)"
      (onDoubleArrowClick)="expandItemFully(node)"
    >
    </app-my-catalog-tree-item>
  </ng-template>
</tree-root>

<app-modal-window [isShowed]="modals[CMActions.SEARCH]?.isShowed" (closeEvent)="closeActionModal(CMActions.SEARCH)">
  <div class="modal-header">
    <div class="modal-title">Поиск по разделам</div>
  </div>
  <app-multiple-search-text
    *ngIf="modals[CMActions.SEARCH]?.isShowed"
    [filterSuggestions]="treeSuggestions"
    placeholder="Начните вводить название раздела"
    (submitEvent)="filterTreeData($event.value)"
  ></app-multiple-search-text>
</app-modal-window>
