import {EventModel} from "@app/shared/components/calendar-widget/constants/calendar.constants";

export enum TYPE_PLANNER {
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export enum STATE_DAY_PLANNER {
  IN_PROGRESS = 'in_progress',
  FINISH = 'finish',
}

export interface EventDayListModel {
  [date: string]: {
    list: EventModel[];
    state: STATE_DAY_PLANNER;
  }
}

export interface EventHourListModel {
  [date: string]: {
    [time: number]: {
      list: EventModel[];
      state: STATE_DAY_PLANNER;
    }
    total: number;
    state: STATE_DAY_PLANNER;
    stateList: {
      [key: string]: boolean;
    }
  }
}

export interface StateListColorModel {
  [date: string]: {
    [key: string]: boolean;
  }
}
