<div class="dc-modal-x-button dc-fa-button" (click)="close()">
  <app-svg-icon [height]="20" [width]="20" name="close"></app-svg-icon>
</div>
<div class="modal-window-content">
  <div class="modal-body">
    <div>Для начала работы Вам необходимо заполнить реквизиты пользователя.</div>

    <div class="margin-t-22">Перейти к форме заполнения?</div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-dc-outline" (click)="close()">Нет, позже</button>
    <button type="button" class="btn btn-dc-primary" (click)="success()">Да, перейти</button>
  </div>
</div>
