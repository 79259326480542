<div class="section-header">
  <div class="d-flex flex-row">
    <div class="d-flex flex-column">
      <div class="section-header-row" appTestLocator="union-filter-template__author">
        <div class="section-header-label">Автор:</div>
        <div class="section-header-value">{{ userAuthor | roleName: 'small' }}</div>
      </div>
      <div class="section-header-row" appTestLocator="union-filter-template__date">
        <div class="section-header-label">Дата:</div>
        <div class="section-header-value">{{ projectDate | dateFormat }}</div>
      </div>
    </div>
    <div class="d-flex flex-column ml-3">
      <div class="section-header-row" appTestLocator="union-filter-template__project">
        <div class="section-header-label">Проект ID:</div>
        <div class="section-header-value">{{ projectId }}</div>
      </div>
      <div class="section-header-row" appTestLocator="union-filter-template__status">
        <div class="section-header-label">Статус:</div>
        <div class="section-header-value d-flex align-items-center">
          {{ projectStatus | cmProjectStatus }}&nbsp;&nbsp;
          <app-svg-icon name="change" width="16" class="color-brand-light"></app-svg-icon>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="section-header-text" appTestLocator="union-filter-template__title">Объединить фильтры</div>
  </div>
  <div>
    <div
      class="section-header-archive"
      [class.disabled]="isArchiveDisabled()"
      (click)="archive()"
      appTestLocator="union-filter-template__archive"
    >
      <app-svg-icon name="trash" width="16"></app-svg-icon>
      <span>Перенести в архив</span>
    </div>
  </div>
</div>

<div class="modal-body">
  <div class="section-form">
    <div class="row">
      <div class="col-4">
        <div class="mb-3" appTestLocator="union-filter-template__union-filter-heading">Название текущего фильтра</div>
        <div class="mb-5">
          <dc-form-field-wrapper [style]="'material'" [vertically]="true">
            <dc-form-input>
              <input
                formInput
                [ngModel]="templateFilter?.title"
                [disabled]="true"
                appTestLocator="union-filter-template__union-filter-list"
              />
            </dc-form-input>
          </dc-form-field-wrapper>
        </div>

        <div class="mb-3" appTestLocator="union-filter-template__unit-heading">Единица измерения</div>
        <div class="mb-5">
          <dc-form-field-wrapper [style]="'material'" [vertically]="true">
            <dc-form-input>
              <input
                formInput
                [value]="templateFilter ? templateFilter?.units_of_measure : '-'"
                [disabled]="true"
                appTestLocator="union-filter-template__unit"
              />
            </dc-form-input>
          </dc-form-field-wrapper>
        </div>

        <div class="mb-2">
          <input
            class="cbx-input"
            id="all-checked"
            type="checkbox"
            appTestLocator="union-filter-template__select-all"
          />
        </div>
        <div class="mb-3 section-tree-container">
          <app-union-tree-view [data]="templateFilter?.children"></app-union-tree-view>
        </div>
      </div>

      <div class="col-4">
        <div class="mb-3" appTestLocator="union-filter-template__union-filter-heading">Объединить с фильтрами</div>
        <div class="mb-5">
          <dc-form-field-wrapper [style]="'material'" [vertically]="true">
            <dc-form-input>
              <input formInput [(ngModel)]="filterValue" appTestLocator="union-filter-template__union-filter-list" />
            </dc-form-input>
          </dc-form-field-wrapper>
        </div>

        <div class="mb-3" appTestLocator="union-filter-template__unit-heading">Единица измерения</div>
        <div class="mb-5">
          <dc-form-field-wrapper [style]="'material'" [vertically]="true">
            <dc-form-input>
              <input
                formInput
                [value]="templateFilter ? templateFilter?.units_of_measure : '-'"
                [disabled]="true"
                appTestLocator="union-filter-template__unit"
              />
            </dc-form-input>
          </dc-form-field-wrapper>
        </div>

        <div class="mb-2">
          <input
            class="cbx-input"
            id="all-checked"
            type="checkbox"
            appTestLocator="union-filter-template__select-all"
          />
        </div>
        <div class="mb-3 section-tree-container">
          <app-union-tree-choice
            *ngIf="!filterTemplateSnapshot"
            [disabled]="isDataDisabled()"
            [filterValue]="filterValue"
            [selectedNodes]="rootCheckedTemplateFiltersIds"
            [selectedNodesForm]="formCheckedItems"
            [checkedSelectedNodes]="rootCheckedTemplateFiltersViewIds"
            [data]="filterTemplateItems$ | async"
            (selectEvent)="selectTreeItem($event)"
          ></app-union-tree-choice>

          <app-union-tree-choice-snapshot
            *ngIf="!!filterTemplateSnapshot"
            [disabled]="isDataDisabled()"
            [filterValue]="filterValue"
            [selectedNodes]="rootCheckedTemplateFiltersIds"
            [data]="filterTemplateSnapshot"
          ></app-union-tree-choice-snapshot>
        </div>
      </div>

      <div class="col-4">
        <form [formGroup]="unionForm">
          <div class="mb-3" appTestLocator="union-filter-template__new-filter_heading">
            Название объединенного фильтра
          </div>
          <div class="mb-5">
            <dc-form-field-wrapper [style]="'material'" [vertically]="true" [class.disabled]="isDataDisabled()">
              <dc-form-input [isShowLength]="true" [maxLength]="30">
                <input
                  formInput
                  formControlName="unionName"
                  placeholder="Введите или выберите из словаря"
                  appTestLocator="union-filter-template__new-filter_name"
                />
              </dc-form-input>
            </dc-form-field-wrapper>
          </div>

          <div class="mb-3" appTestLocator="union-filter-template__new-filter_unit-heading">Единица измерения</div>
          <div class="mb-5">
            <dc-form-field-wrapper [style]="'material'" [vertically]="true">
              <dc-form-input>
                <input
                  formInput
                  [value]="templateFilter ? templateFilter?.units_of_measure : '-'"
                  [disabled]="true"
                  appTestLocator="union-filter-template__new-filter_unit"
                />
              </dc-form-input>
            </dc-form-field-wrapper>
          </div>

          <div class="mb-2" appTestLocator="union-filter-template__new-filter_values-heading">
            Значения объединеного дерева
          </div>
          <div class="mb-3 section-tree-container">
            <app-union-tree-view
              [data]="unionFilterTree"
              [templateFilterType]="firstTemplateFilterType"
            ></app-union-tree-view>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<app-operation-modal-footer
  (onCancel)="cancel()"
  (onSave)="save($event)"
  (onAgree)="agree($event)"
  [project]="project"
  [form]="unionForm"
>
</app-operation-modal-footer>
