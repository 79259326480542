<div class="section-header">
  <div>
    <div class="section-header-row" appTestLocator="move-section__project">
      <div class="section-header-label">Проект ID:</div>
      <div class="section-header-value">{{ projectId }}</div>
    </div>
    <div class="section-header-row" appTestLocator="move-section__status">
      <div class="section-header-label">Статус:</div>
      <div class="section-header-value d-flex align-items-center">
        {{ projectStatus | cmProjectStatus }}&nbsp;&nbsp;
        <app-svg-icon name="change" width="16" class="color-brand-light"></app-svg-icon>
      </div>
    </div>
    <div class="section-header-row" appTestLocator="move-section__author">
      <div class="section-header-label">Автор:</div>
      <div class="section-header-value">{{ userAuthor | roleName }}</div>
    </div>
    <div class="section-header-row" appTestLocator="move-section__date">
      <div class="section-header-label">Дата:</div>
      <div class="section-header-value">{{ projectDate | dateFormat }}</div>
    </div>
  </div>
  <div>
    <div class="section-header-text" appTestLocator="move-section__title">Перенести раздел</div>
  </div>
  <div>
    <div
      class="section-header-archive"
      (click)="archive()"
      [class.disabled]="isArchiveDisabled()"
      appTestLocator="move-section__archive"
    >
      <app-svg-icon name="trash" width="16"></app-svg-icon>
      <span>Перенести в архив</span>
    </div>
  </div>
</div>

<form class="modal-body" [formGroup]="form">
  <div class="section-form">
    <div class="section-header-row section-path" appTestLocator="move-section__path">
      <div class="section-header-label">Путь:</div>
      <div class="section-header-value">{{ path }}</div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="mb-3" appTestLocator="move-section__from_title">Расположение раздела</div>
        <div class="scroll-container">
          <div class="base-tree">
            <tree-root
              *ngIf="sections?.length"
              #sourceTree
              [(nodes)]="sections"
              (initialized)="onInitializedTree(sourceTree)"
            >
              <ng-template #treeNodeTemplate let-node>
                <div [class.selected]="node.data.id === currentNode?.data?.id" class="base-tree-item">
                  <app-svg-icon
                    [name]="!node.isExpanded ? 'caret_down' : 'caret_up'"
                    [class.disabled]="!node.hasChildren || node.data.status"
                    (click)="toggleItem(sourceTree, node)"
                    appTestLocator="move-section__from-item-{{ node.data.id }}"
                    width="10"
                  ></app-svg-icon>
                  <div
                    class="base-tree-item-title text-overflow"
                    appTestLocator="move-section__from-item-{{ node.data.id }}_title"
                  >
                    {{ node?.data?.title }}
                  </div>
                </div>
              </ng-template>
              <ng-template #loadingTemplate>Загрузка...</ng-template>
            </tree-root>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="mb-3" appTestLocator="move-section__to_title">Перенести раздел в</div>
        <div class="mb-3">
          <dc-form-field-wrapper [style]="'material'" [vertically]="true" [required]="true">
            <dc-form-input [isShowClear]="true">
              <input
                formInput
                [(ngModel)]="searchValue"
                [ngModelOptions]="{ standalone: true }"
                appTestLocator="move-section__to-search"
                placeholder="Введите название или выберите из каталога"
                (ngModelChange)="filterSections()"
              />
            </dc-form-input>
          </dc-form-field-wrapper>
        </div>
        <div class="scroll-container">
          <div class="base-tree">
            <tree-root
              *ngIf="targetSections?.length"
              #destTree
              [(nodes)]="targetSections"
              (initialized)="onInitializedTree(destTree)"
            >
              <ng-template #treeNodeTemplate let-node>
                <div
                  [class.selected]="node.data.id === selected?.data?.id"
                  class="base-tree-item"
                  appTestLocator="move-section__to-item-{{ node.data.id }}_select"
                >
                  <app-svg-icon
                    [name]="!node.isExpanded ? 'caret_down' : 'caret_up'"
                    [class.disabled]="!node.visibleChildren.length"
                    (click)="toggleItem(destTree, node)"
                    appTestLocator="move-section__to-item-{{ node.data.id }}_arrow"
                    width="10"
                  ></app-svg-icon>
                  <div
                    (click)="select(node)"
                    [class.inactive]="node.data.searchedInactive"
                    class="base-tree-item-title text-overflow"
                    appTestLocator="move-section__to-item-{{ node.data.id }}_title"
                  >
                    {{ node?.data?.title }}
                  </div>
                </div>
              </ng-template>
              <ng-template #loadingTemplate>Загрузка...</ng-template>
            </tree-root>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<app-operation-modal-footer
  (onCancel)="cancel()"
  (onSave)="save($event)"
  (onAgree)="agree($event)"
  [project]="project"
  [form]="form"
>
</app-operation-modal-footer>
