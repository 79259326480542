import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Unit } from '../models/user-settings.model';
import { PersonalDataService } from '../services/personal-data.service';

@Injectable({ providedIn: 'root' })
export class UnitsMapResolver implements Resolve<Observable<Map<number, Unit>>> {
  constructor(private personalDataService: PersonalDataService) {}

  resolve(): Observable<Map<number, Unit>> {
    return this.personalDataService.getUnits().pipe(map((units) => new Map(units.map((unit) => [unit.id, unit]))));
  }
}
