import { Pipe, PipeTransform } from '@angular/core';
import {UserFileZip} from '../models/user-file.model';

@Pipe({
  name: 'USER_FILE_ZIP_SORT'
})
export class UserFileZipPipe implements PipeTransform {

  transform(value: UserFileZip[]): UserFileZip[] {
    return value.sort((a: UserFileZip, b: UserFileZip) => {
      return b.created_at.valueOf() - a.created_at.valueOf();
    });
  }

}
