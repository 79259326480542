<div>
  <div
    *ngIf="
      chatSectionSelected.name === chatSectionsEnum.ADMIN ||
      chatSectionSelected.name === chatSectionsEnum.HOLDING ||
      chatSectionSelected.name === chatSectionsEnum.TRADE
    "
    class="group__filters"
    [class.invisible]="chatSectionSelected.name === chatSectionsEnum.TRADE"
  >
    <div class="sort__button">
      <fa-icon [icon]="['fas', 'sort-amount-down']"></fa-icon>
    </div>
    <div (click)="onSortDirectionNext()" class="sort__button">
      <span>Сортировка: {{ order.label }}</span>
      <ul *ngIf="isChoosingSortDirection" class="sort__direction-select list-group">
        <li
          class="list-group-item"
          *ngFor="let type of orderTypeDirections"
          (click)="onSortDirectionChange($event, type)"
        >
          <span [class.c-black]="type.selected">{{ type.label }}</span>
          <ul class="list-group" *ngIf="type.selected && type.directions?.length">
            <li
              *ngFor="let direction of type.directions"
              [class.c-black]="direction.selected"
              (click)="onSortDirectionChange($event, direction)"
            >
              {{ direction.label }}
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>

  <div
    *ngIf="
      chatSectionSelected.name === chatSectionsEnum.TECH &&
      (user.type === rolesEnum.ADMIN_OF_USER ||
        user.type === rolesEnum.ADMIN_OF_DIRECTION ||
        user.type === rolesEnum.OPERATOR)
    "
  >
    <div>
      <app-change-tso [user]="user"></app-change-tso>
    </div>
  </div>

  <div *ngIf="chatSectionSelected.name !== chatSectionsEnum.TECH" class="group">
    <div>
      <a class="mr-2" [class.disabled]="!groups.length" (click)="onToggleGroupsExpanded()">
        <fa-icon [icon]="['fas', isGroupsExpanded ? 'chevron-up' : 'chevron-down']"></fa-icon>
      </a>
      <a>Группы</a>
    </div>
    <div class="d-flex align-items-center">
      <a
        class="icon-link mr-2"
        (click)="onGroupEditClick()"
        *ngIf="
          chatSectionSelected.name !== chatSectionsEnum.TRADE && chatSectionSelected.name !== chatSectionsEnum.DUTY_TSO
        "
      >
        <fa-icon [icon]="['fas', 'plus']"></fa-icon>
      </a>

      <div class="group-menu-trigger mr-2">
        <a
          class="icon-link"
          (click)="openDutyFilterMenu()"
          *ngIf="chatSectionSelected.name === chatSectionsEnum.DUTY_TSO"
        >
          <fa-icon [icon]="['fas', 'sort-amount-down']"></fa-icon>
        </a>
        <div class="group-menu" *ngIf="isDutyFilterMenuOpened">
          <div>
            <a class="icon-link" (click)="openDutyTsoFilter($event)">
              <fa-icon [icon]="['fas', 'sort-amount-down']"></fa-icon>
            </a>
          </div>
          <div>
            <a class="icon-link" (click)="resetDutyTsoFilter()">
              <fa-icon [icon]="['fas', 'ban']"></fa-icon>
            </a>
          </div>
        </div>
      </div>
      <span class="chat-number">{{ groups.length }}</span>
    </div>
  </div>

  <div *ngIf="isGroupsExpanded" class="groups-list">
    <div *ngFor="let group of groups | groupSort: order | groupsRolesFilter: rolesSelected">
      <app-chat-group [group]="group" [contactSelected]="contactSelected"></app-chat-group>
    </div>
  </div>
</div>

<div *ngIf="chatSectionSelected.name === chatSectionsEnum.DUTY_TSO">
  <app-chat-roles-filter
    [isDutyFilterModalOpened]="isDutyFilterModalOpened"
    [selected]="rolesSelected"
    (closeEvent)="closeDutyTsoFilter()"
    (submitEvent)="applyDutyTsoFilter($event)"
  ></app-chat-roles-filter>
</div>
