<div
  class="contacts__filters"
  *ngIf="
    chatSectionSelected.name === chatSectionsEnum.ADMIN ||
    chatSectionSelected.name === chatSectionsEnum.HOLDING ||
    chatSectionSelected.name === chatSectionsEnum.TRADE
  "
  [class.invisible]="chatSectionSelected.name === chatSectionsEnum.TRADE"
>
  <div class="d-flex align-items-center" (click)="onFilterToggle()">
    <div class="sort__button mr-1">
      <fa-icon [icon]="['fas', 'sort-amount-down']"></fa-icon>
    </div>
    <div class="sort__button">
      <a>Фильтр</a>
    </div>
  </div>
  <div class="sort__clean" (click)="onClearFilter()"><fa-icon [icon]="['fas', 'ban']"></fa-icon> Очистить</div>
</div>
<div class="contacts">
  <div class="d-flex align-items-center" (click)="onContactsExpand()">
    <div class="mr-2">
      <a [class.disabled]="!contacts.length">
        <fa-icon [icon]="['fas', isContactsExpanded ? 'chevron-up' : 'chevron-down']"></fa-icon>
      </a>
    </div>
    <div>
      <a>Контакты</a>
    </div>
  </div>
  <div>
    <span class="contacts__number">{{ contacts.length }}</span>
  </div>
</div>
<div class="contacts-list">
  <div *ngFor="let contact of contacts | contactsFilter: contactsOnlyFilter | usersInitialsFilter: filterString">
    <div
      *ngIf="contact.status !== 'in_archive' && isContactsExpanded"
      [routerLink]="contact.room_id"
      [class.disabled]="!contact.room_id"
      (click)="onContactSelect(contact)"
      [class.contact_selected]="contactSelected.room_id === contact.room_id"
      class="contact"
    >
      <div class="d-flex align-items-center">
        <div class="mr-2">
          <fa-icon [icon]="['fas', 'user']"></fa-icon>
        </div>
        <div>
          <h4 class="contact__name">{{ contact | initials }}</h4>
          <div class="mb-2">
            <div class="contact__type" *ngIf="contact.type === rolesEnum.PARTNER">
              <ng-container *ngIf="contact.is_tso && !contact.is_duty_tso">
                ОТП
              </ng-container>
              <ng-container *ngIf="contact.is_duty_tso">
                Деж. ОТП
              </ng-container>
              <ng-container *ngIf="!contact.is_duty_tso && !contact.is_tso">
                {{ contact.type | titleRole }}
              </ng-container>
            </div>
            <div class="contact__type" *ngIf="contact.type !== rolesEnum.PARTNER">
              {{ contact.type | titleRole }}
            </div>
            <div class="contact__competence" *ngIf="!!contact.competence_sections">
              {{ contact.competence_sections | competencies }}
            </div>
            <div class="contact__company">
              {{ contact.company | companyName }}
            </div>
          </div>
        </div>
      </div>
      <div>
        <span *ngIf="contact.counter" class="unread-message-count">
          {{ contact.counter }}
        </span>
      </div>
    </div>
  </div>
</div>
