import { Directive, HostBinding, Input } from '@angular/core';

// TODO: in progress
function Locator(value: RegExp): Function {
  return (target: object, propName: string) => {
    let _val: string = target[propName];
    Object.defineProperty(target, propName, {
      get(): string | unknown {
        return _val;
      },
      set(item: string): void {
        if (!item) {
          throw new Error('No test locator value');
        }

        if (!item.match(value)) {
          console.error('Invalid locator value', item);
          throw new Error('Invalid locator value');
        }

        // TODO: Fix ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked
        _val = item;
      },
    });
  };
}

@Directive({
  selector: '[appTestLocator]',
})
export class TestLocatorDirective {
  // BEM like format
  // @Locator(/^\S+(__)\S+_?\S+$/)
  @Input('appTestLocator')
  value: string;

  @HostBinding('attr.data-testid') get testId() {
    return this.value;
  }
}
