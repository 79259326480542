export class Location {
  public city: City;
  public region: Region;
  public country: Country;
  public isSelected: boolean;

  constructor(data = null) {
    for (const prop in data) {
      if (data.hasOwnProperty(prop)) {
        if (prop in this) {
          this[prop] = new this[prop](data[prop]);
          continue;
        }
        this[prop] = data[prop];
      }
    }
    this.city = this.city ? this.city : new City();
    this.region = this.region ? this.region : new Region();
    this.country = this.country ? this.country : new Country();
    if (this.city.id) this.isSelected = true;
  }

  get fullName(): string {
    return `${this.country.title || ''} ${this.region.title || ''} ${this.city.title || ''}`;
  }
}

export class SubLocation {
  public id?: number;
  public title?: string;

  constructor(data = null) {
    for (const prop in data) {
      if (data.hasOwnProperty(prop)) {
        if (prop in this) {
          this[prop] = new this[prop](data[prop]);
          continue;
        }
        this[prop] = data[prop];
      }
    }
  }
}

export class City extends SubLocation {
}

export class Region extends SubLocation {
}

export class Country extends SubLocation {
}

export interface Address {
  /** идентификатор */
  id?: number;

  /** Адрес */
  address?: string;

  /** Страна */
  country?: string;

  /** Широта */
  latitude?: string;

  /** Долгота */
  longitude?: string;

  /** Населённый пункт */
  locality?: string;

  /** Область/край */
  province?: string;
}
