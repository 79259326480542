<div
  (dblclick)="add()"
  class="d-flex align-items-center shared-users-list-item padding-l-30"
  [class.checked]="item.selected"
>
  <div class="d-flex shared-users-list-item-checkbox">
    <input
      class="cbx-input"
      [id]="'authorFile_' + item.id"
      type="checkbox"
      (change)="onCheckboxChange($event)"
      *ngIf="!(detached && type === fileManagerShareUserType.DISALLOW)"
      [checked]="item.selected"
      [disabled]="readonly"
    />
    <label [class.disabled]="detached || readonly" class="cbx-label" [for]="'authorFile_' + item.id"></label>
  </div>
  <div class="shared-users-list-item-toggle" *ngIf="node">
    <app-svg-icon
      [name]="!node?.isExpanded ? 'caret_down' : 'caret_up'"
      [style.opacity]="node?.hasChildren ? 1 : 0.3"
      (click)="toggleNodeClicked()"
      [width]="10"
      [height]="6"
    ></app-svg-icon>
  </div>
  <div class="margin-l-6" *ngIf="node" [class.disabled]="detached">
    <p>{{ item | initials }}</p>
  </div>
  <div *ngIf="!node" [class.disabled]="detached">
    <p class="d-flex shared-users-list-item-title align-items-center">
      <span>{{ item | initials }}</span>
      <span class="shared-users-list-item-title__company">, {{ item.company?.name }}</span>
    </p>
  </div>
  <div class="shared-users-list-item-buttons margin-r-10">
    <app-status-circle [status]="item.status"></app-status-circle>
    <button>
      <app-svg-icon [width]="18" [height]="14" name="id-card" (click)="info($event)"></app-svg-icon>
    </button>
    <button
      [disabled]="
        item?.type === userTypes.ACCOUNTANT || item?.type === userTypes.SUPERUSER || item?.type === userTypes.TSO
      "
    >
      <app-svg-icon [width]="18" [height]="14" name="competence-card" (click)="competence($event)"></app-svg-icon>
    </button>
  </div>
</div>
