import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MultiSelectListItem } from '@app/shared/models/multi-select-list-item';

@Component({
  selector: 'app-change-status',
  templateUrl: './change-status.component.html',
  styleUrls: ['./change-status.component.scss'],
})
export class ChangeStatusComponent implements OnInit {
  @Input() value: string | number;
  @Input() disableClose: boolean = false;
  @Input()
  ngModel = {} as any;

  @Input() statuses: MultiSelectListItem[];

  @Output() closeEvent = new EventEmitter();
  @Output() saveEvent = new EventEmitter<{ status: string }>();

  constructor() {}

  hasChanges() {
    return this.ngModel.status !== this.value;
  }

  ngOnInit(): void {
    this.ngModel.status = this.value;
  }

  selectStatus(status) {
    this.ngModel.status = status;
  }

  close() {
    if (this.disableClose) {
      this.ngModel.status = this.value;
    } else {
      this.closeEvent.emit();
    }
  }

  save() {
    this.saveEvent.emit({
      status: this.ngModel.status,
    });

    this.closeEvent.emit();
  }
}
