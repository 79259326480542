import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { DCTreeItem } from '../../../+competence-map/models/competence-map.models';
import { ITreeOptions, TreeNode } from '@circlon/angular-tree-component';
import { doForAllSync } from '../../../+competence-map/helpers/competence-map.helpers';

@Component({
  selector: 'app-competencies-filter',
  templateUrl: './competencies-filter.component.html',
  styleUrls: ['./competencies-filter.component.scss'],
})
export class CompetenciesFilterComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() goods: DCTreeItem[];
  @Input() services: DCTreeItem[];

  @Input() selected: number[];
  @Input() hasSubmit = true;

  @ViewChild('goodsTree') private goodsTree;
  @ViewChild('servicesTree') private servicesTree;

  @Output() submitEvent = new EventEmitter<{ [key: string]: any }>();
  @Output() changeEvent = new EventEmitter<{ [key: string]: any }>();

  customTemplateStringOptions: ITreeOptions = {};
  active: number = 0;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.goods && this.services) {
      this.goodsTree.treeModel.expandAll();
      this.servicesTree.treeModel.expandAll();
    }
  }

  ngOnChanges() {
    setTimeout(() => {
      this.clear();
      if (this.selected && this.selected.length) {
        this.selected.forEach((id) => {
          doForAllSync(this.goodsTree.treeModel.getVisibleRoots(), (node: any) => {
            if (node.data.id === id) {
              node.data.selected = true;
            }
          });

          doForAllSync(this.servicesTree.treeModel.getVisibleRoots(), (node: any) => {
            node.data.selected = node.data.id === id;
          });
        });
      }
    }, 0);
  }

  clear() {
    doForAllSync(this.goodsTree.treeModel.getVisibleRoots(), (node: any) => (node.data.selected = false));
    doForAllSync(this.servicesTree.treeModel.getVisibleRoots(), (node: any) => (node.data.selected = false));
  }

  apply() {
    this.submitEvent.emit({ value: this.getData() });
  }

  change() {
    this.changeEvent.emit({ value: this.getData() });
  }

  private getData(): number[] {
    const data = [];
    doForAllSync(this.goodsTree.treeModel.getVisibleRoots(), (node: any) => {
      if (node.data.selected) {
        // console.log(node.data);
        data.push(node.data.id);
      }
    });

    doForAllSync(this.servicesTree.treeModel.getVisibleRoots(), (node: any) => {
      if (node.data.selected) {
        // console.log(node.data);
        data.push(node.data.id);
      }
    });

    return data;
  }

  filterTree(event, tree: TreeNode) {
    tree.treeModel.filterNodes((node) => {
      return node.data.title.startsWith(event.target.value);
    });
  }
}
