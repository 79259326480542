import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { CMCatalogTypes, CMSectionStatuses } from '../../+competence-map/constants/sections.constants';
import { UserCompetencyTypes } from '../../+competence-map/constants/user-competency.constants';
import { TreeSectionsAndFiltersItem } from '../../+competence-map/models/user-competency.model';
import { CompetenceService } from '../../+competence-map/services/competence.service';
import { TreeHelper } from '../helpers/tree.helper';
import { FlaskQueryFilter } from '../models/filters.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ServicesResolver implements Resolve<Observable<TreeSectionsAndFiltersItem[]>> {
  filters: FlaskQueryFilter[] = [
    {
      name: 'type',
      op: 'eq',
      val: UserCompetencyTypes.SECTOR,
    },
    {
      name: 'status_section',
      op: 'eq',
      val: CMSectionStatuses.ACTIVE,
    },
    {
      name: 'catalog',
      op: 'eq',
      val: CMCatalogTypes.SERVICES,
    },
  ];

  constructor(private service: CompetenceService) {}

  resolve(): Observable<TreeSectionsAndFiltersItem[]> {
    return this.service.getSectionsAndFilters(this.filters).pipe(
      map((value) => {
        const result = value.map((item) => {
          return {
            ...item,
            parent: {
              id: item.parent_id,
            },
          };
        });

        return TreeHelper.list_to_tree(result);
      })
    );
  }
}
