<div class="filter-grid-body-item" [class.hidden]="active !== counter">
  <div class="filter-search margin-b-20 margin-t-4">
    <form [formGroup]="searchForm">
      <dc-form-field-wrapper [style]="'material'" [vertically]="true">
        <dc-form-input [isShowLength]="false">
          <input formInput formControlName="query" placeholder="Начните вводить название товара или услуги" />
        </dc-form-input>
      </dc-form-field-wrapper>
    </form>
    <button class="btn-link hover shared-users-little-icons" (click)="resetSearch()">
      <app-svg-icon [width]="13" [height]="13" name="times"></app-svg-icon>
    </button>
  </div>
  <div class="select-all">
    <input
      [checked]="selectedAll"
      [id]="'selected_all_' + type"
      (change)="selectAll(items, $event)"
      class="cbx-input"
      type="checkbox"
    />
    <label class="cbx-label" [for]="'selected_all_' + type">
      <span class="margin-l-10">Выделить всё/снять выделение</span>
    </label>
  </div>
  <div class="expand-all margin-l-26">
    <button class="dc-icon-button" (click)="toggleByLevel(itemsTree, 1)">
      <app-svg-icon [name]="!isTreeExpanded1 ? 'caret_down' : 'caret_up'" [width]="10" [height]="6"> </app-svg-icon>
    </button>
    <button class="dc-icon-button" (click)="toggleByLevel(itemsTree, 2)">
      <app-svg-icon [name]="!isTreeExpanded1 ? 'caret_down' : 'caret_up'" [width]="10" [height]="6"> </app-svg-icon>
    </button>
  </div>
  <div class="filter-tree">
    <tree-root #itemsTree [nodes]="items" [options]="treeOptions">
      <ng-template #treeNodeTemplate let-node>
        <app-share-filter-item
          [node]="node"
          [item]="node.data"
          [type]="type"
          (onToggle)="expandItem(itemsTree, node, $event)"
          (onSelect)="selectItem($event)"
        >
        </app-share-filter-item>
      </ng-template>
    </tree-root>
  </div>
</div>
