import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AuthService } from '@app/shared/services/auth.service';
import { RolesEnum } from '@app/shared/constants/roles.constants';
import { FormGroup } from '@angular/forms';
import { CMProjectStatuses } from '@app/+competence-map/constants/projects.constants';
import { Project } from '@app/+competence-map/models/projects.models';

@Component({
  selector: 'app-operation-modal-footer',
  templateUrl: './operation-modal-footer.component.html',
  styleUrls: ['./operation-modal-footer.component.scss'],
})
export class OperationModalFooterComponent implements OnInit, OnChanges {
  @Input() form: FormGroup;
  @Input() setSaveDisabled: boolean = false;
  @Input() setAgreeDisabled: boolean = false;
  @Input() project: Project;

  @Output() onCancel: EventEmitter<void> = new EventEmitter();
  @Output() onSave: EventEmitter<CMProjectStatuses> = new EventEmitter();
  @Output() onAgree: EventEmitter<CMProjectStatuses> = new EventEmitter<CMProjectStatuses>();
  @Output() onReturn: EventEmitter<void> = new EventEmitter();
  @Output() onArchive: EventEmitter<string> = new EventEmitter<string>();

  currentUserType: string;
  agreeButtonName: string;
  CMProjectStatuses = CMProjectStatuses;

  constructor(private authService: AuthService) {
    this.currentUserType = this.authService.user_type.toString();
  }

  get isNew() {
    return !this.project || (this.project && !this.project.id);
  }

  ngOnInit() {
    this.agreeButtonName = this.isNew
      ? this.getAgreeButtonName(this.currentUserType)
      : this.getAgreeButtonName(this.project.author.type);
  }

  ngOnChanges() {
    this.agreeButtonName = this.isNew
      ? this.getAgreeButtonName(this.currentUserType)
      : this.getAgreeButtonName(this.project.author.type);
  }

  getAgreeButtonName(currentUserType): string {
    if (currentUserType === RolesEnum.EXPERT) {
      if (this.project && this.project.status === CMProjectStatuses.ON_AGREEMENT) {
        return 'Вернуть';
      }
      return 'На согласование';
    }

    if (currentUserType === RolesEnum.SUPERUSER) {
      if (this.project && this.project.status === CMProjectStatuses.ON_AGREEMENT) {
        return 'Вернуть';
      }
      return 'Согласовать';
    }
  }

  getStatusOnAgree(currentUserType): CMProjectStatuses {
    if (currentUserType === RolesEnum.EXPERT) {
      if (this.project && this.project.status === CMProjectStatuses.ON_AGREEMENT) {
        return CMProjectStatuses.EDITING;
      }
      return CMProjectStatuses.ON_AGREEMENT;
    }

    if (currentUserType === RolesEnum.SUPERUSER) {
      if (this.project && this.project.status === CMProjectStatuses.ON_AGREEMENT) {
        return CMProjectStatuses.EDITING;
      }
      return CMProjectStatuses.AGREED;
    }
  }

  cancel() {
    this.onCancel.emit();
  }

  save() {
    // TODO: будут проблемы - подумать и убрать
    // UPD: проблемы начались мне кажется лучше эту ответственность на форму проектов переложить!!!
    // UPD: сейчас добавился вызов markAsPristine вместе с завязкой кнопок на dirty в форме проектов, возможно, уже не актуально
    if (this.form && this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.onSave.emit();
  }

  agree() {
    if (this.form && this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    const status = this.isNew
      ? this.getStatusOnAgree(this.currentUserType)
      : this.getStatusOnAgree(this.project.author.type);
    this.onAgree.emit(status);
  }

  isSaveDisabled() {
    if (this.setSaveDisabled) {
      return true;
    }

    return (
      this.project &&
      (this.project.status === CMProjectStatuses.AGREED ||
        this.project.status === CMProjectStatuses.ON_AGREEMENT ||
        this.project.status === CMProjectStatuses.ARCHIVE)
    );
  }

  isCancelDisabled() {
    if (this.setSaveDisabled) {
      return false;
    }
    return this.isSaveDisabled();
  }

  isAgreeDisabled() {
    if (this.setAgreeDisabled && !this.form.dirty) {
      return true;
    }

    return (
      this.project &&
      (this.project.status === CMProjectStatuses.AGREED || this.project.status === CMProjectStatuses.ARCHIVE)
    );
  }
}
