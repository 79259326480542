import { UserTypes } from '@app/shared/types/user.types';
import { SectionUser } from '@app/shared/models/section-user.model';
import { User } from '@app/shared/models/user.model';
import { UserStatusesTypes } from '@app/shared/types/user-statuses.types';
import { Company } from '@app/shared/models/company.model';

import { getTitleRole } from '@app/shared/utils';

import { formatSectionsStringByOneLevel } from '@app/+competence-map/helpers/competence-map.helpers';
import { RolesEnum } from '@app/shared/constants/roles.constants';

export class AuthorFile extends User {
  company: Company;
  competence_sections: SectionUser[];
  created_at: string;
  first_name: string;
  id: number;
  patronymic: string;
  phones: string[];
  second_name: string;
  status: UserStatusesTypes;
  type: UserTypes;
  parent_id: number;
  parent: User;
  competenciesString?;
  selected?: boolean;
  userTypeLabel?: string;
  children?: AuthorFile[];
  sortValue?: number;

  constructor(data: AuthorFile) {
    super(data);
    this.id = data.id || null;
    this.first_name = data.first_name || null;
    this.second_name = data.second_name || null;
    this.patronymic = data.patronymic || null;
    this.status = data.status || null;
    this.children = data.children || [];
    this.parent_id = data.parent_id || null;
    this.parent = data.parent || null;
    this.type = data.type;
    this.userTypeLabel = getTitleRole(data.type);
    this.competence_sections = data.competence_sections;
    this.competenciesString = formatSectionsStringByOneLevel(data.competence_sections, ' / ', '');
    this.company = data.company;
    this.phones = data.phones;
    this.selected = data.selected || false;
    this.sortValue = this.sortUserRoles(data.type);
  }

  private sortUserRoles(type: UserTypes): number {
    if (type === RolesEnum.ACCOUNTANT) {
      return 1;
    } else if (type === RolesEnum.EXPERT) {
      return 2;
    } else if (type === RolesEnum.PARTNER) {
      return 3;
    } else if (type === RolesEnum.ADMIN_OF_USER) {
      return 4;
    } else if (type === RolesEnum.ADMIN_OF_DIRECTION) {
      return 5;
    } else if (type === RolesEnum.OPERATOR) {
      return 6;
    }
  }
}
