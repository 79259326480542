<div (click)="cancel()" class="dc-fileman-modal-x-button">
  <app-svg-icon [width]="20" [height]="20" name="close"></app-svg-icon>
</div>
<div class="modal-header center">
  <div class="modal-title">{{ title }}</div>
  <div *ngIf="subTitle" class="modal-subtitle padding-t-10">"{{ subTitle }}"</div>
</div>
<div class="modal-body">
  <div class="file-manager" [class.file-manager__min-height]="exportType !== exportFileManagerType.PROFILE">
    <div class="file-manager__search file-manager__section">
      <div class="file-manager__left">
        <app-file-manager-search
          placeholder="Введите имя файла или ФИО его владельца"
          (onSearch)="search($event)"
        ></app-file-manager-search>
      </div>
      <div class="file-manager__right">
        <app-file-manager-selected-info
          *ngIf="fullSelectedFiles?.length || alwaysShowCancel"
          [selectedFiled]="fullSelectedFiles"
          (onResetSelected)="resetSelectedFiles()"
        ></app-file-manager-selected-info>
      </div>
    </div>

    <div class="file-manager__search-result file-manager__section">
      <div class="file-manager__left"></div>
      <div class="file-manager__right">
        <span *ngIf="searchQuery">Выведены результаты поиска по запросу "{{ searchQuery }}"</span>
      </div>
    </div>

    <div class="file-manager__body file-manager__section">
      <div class="file-manager__left">
        <app-file-manager-space-info
          [spaceInfo]="fileManagerSpace$ | async"
          class="margin-b-16"
        ></app-file-manager-space-info>

        <div class="file-manager__tree file-manager__tree-modal">
          <div class="file-manager__tree-inner">
            <app-file-manager-tree
              [isRoot]="true"
              [isSearchQuery]="isSearch"
              [searchQuery]="searchQuery"
              [selected]="currentSelected"
              [selectedAndOpened]="currentOpened"
              [files]="files"
              [openDelayTooltip]="openDelayTooltip"
              (onToggleFile)="toggleFolder($event)"
              (onSelectFile)="selectFile($event)"
              (onShareFile)="shareFile($event)"
              (onOpenSelectFile)="openSelectFile($event)"
              (onShowUserInfo)="showUserInfo($event)"
              (onHelpFile)="openHelp($event)"
            ></app-file-manager-tree>
          </div>
        </div>
      </div>
      <div class="file-manager__right">
        <app-file-manager-area-breadcrumbs
          class="margin-b-10"
          *ngIf="!isSearch && breadcrumbsTreeFiles.length"
          [breadcrumbs]="breadcrumbsTreeFiles"
          (onNavigateFolder)="openSelectFile($event)"
        ></app-file-manager-area-breadcrumbs>

        <div class="file-manager__area">
          <app-file-manager-area
            [viewMode]="viewMode"
            [files]="currentOpened?.virtualChildren"
            [isSearchQuery]="isSearch"
            [searchQuery]="searchQuery"
            [searchAreaFiles]="searchAreaFiles"
            [currentFileSelected]="currentSelected"
            [selectedFiles]="selectedFiles"
            [bufferFiles]="bufferFiles"
            [openDelayTooltip]="openDelayTooltip"
            (onSelected)="setSelectFiles($event)"
            (onShareFile)="shareFile($event)"
            (onSelectedViewFile)="selectFile($event)"
            (onOpenSelectedFile)="openSelectFile($event)"
            (onShowUserInfo)="showUserInfo($event)"
          ></app-file-manager-area>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="exportType !== exportFileManagerType.PROFILE" class="modal-footer">
  <div class="dc-footer-buttons">
    <button class="btn btn-dc-outline" (click)="cancel()">Отменить</button>
    <button
      class="btn btn-dc-primary"
      [disabled]="(!selectMultiple && selectedFiles.length !== 1) || (selectMultiple && selectedFiles.length < 1)"
      (click)="save()"
    >
      Добавить
    </button>
  </div>
</div>
