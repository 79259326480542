import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { DocumentArchive } from '@app/shared/models/document-archive.interface';
import { mapToObject } from '@app/shared/utils';

@Injectable()
export class FilesService {
  constructor(private http: HttpClient) {}

  uploadTemplate(file, type): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    const headers: HttpHeaders = new HttpHeaders()
      .set('Enctype', 'multipart/form-data')
      .set('Accept', 'application/json');

    return this.http.post(`${environment.api_url}/doc/${type}`, formData, { headers });
  }

  getDocumentUrl(companyId, type): Observable<any> {
    return this.http.get(`${environment.api_url}/company/${companyId}/${type}`);
  }

  getTemplateUrl(type): Observable<any> {
    return this.http.get(`${environment.api_url}/upload/${type}`);
  }

  getLegend(): Observable<any> {
    return this.http.get(`${environment.api_url}/upload/legend`);
  }

  getAgreement(type?: string): Observable<Blob> {
    const fromObject = mapToObject({ type } || {});
    const params = new HttpParams({ fromObject });
    return this.http.get(`${environment.api_url}/file/agreement`, { params, responseType: 'blob' });
  }

  getUserRules(): Observable<Blob> {
    return this.http.get(`${environment.api_url}/file/rules`, { responseType: 'blob' });
  }

  getAgreementsArchive(type?: string): Observable<DocumentArchive[]> {
    const fromObject = mapToObject({ type } || {});
    const params = new HttpParams({ fromObject });
    return this.http.get<DocumentArchive[]>(`${environment.api_url}/file/agreements_archive`, { params });
  }

  getUserRulesArchive(): Observable<DocumentArchive[]> {
    return this.http.get<DocumentArchive[]>(`${environment.api_url}/file/rules_archive`);
  }

  setAgreementsFile(fileId: number, userType: string): Observable<any> {
    return this.http.post<any>(`${environment.api_url}/file/agreement`, {
      agreement_type: userType,
      file_id: fileId,
    });
  }

  setUserRules(fileId: number): Observable<any> {
    return this.http.post<any>(`${environment.api_url}/file/rules`, {
      file_id: fileId,
    });
  }
}
