import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import { ModalInjectorService } from '@app/modal-injector/services/modal-injector.service';

@Component({
  selector: 'app-modal-window',
  templateUrl: './modal-window.component.html',
  styleUrls: ['./modal-window.component.scss'],
})
export class ModalWindowComponent implements OnInit, OnChanges, OnDestroy {
  // TODO: переосмыслить компонент !
  @ViewChild('modal') private modal: TemplateRef<any>;
  private modalRef: NgbModalRef;

  isShowedNow: boolean;

  get ngbModalRef(): NgbModalRef {
    return this.modalRef;
  }

  @Input() idModalInjector: number;
  @Input() isShowed: boolean;
  @Input() preventClose: boolean;
  @Input() customClass: string;
  @Input() disableClose: boolean = false;
  @Input() centered: boolean = false;
  @Input() beforeDismiss?: () => boolean | Promise<boolean>;

  @Output() closeEvent = new EventEmitter();
  @Output() outClick = new EventEmitter();
  @Input() backdrop: NgbModalOptions['backdrop'] = true;
  @Input() keyboard: NgbModalOptions['keyboard'] = true;

  constructor(private modalInjectorService: ModalInjectorService, private modalService: NgbModal) {
    this.isShowedNow = this.isShowed;
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {}

  ngOnChanges() {
    this.init();
  }

  init() {
    if (this.isShowedNow !== this.isShowed) {
      this.isShowedNow = this.isShowed;
      setTimeout(() => {
        if (this.isShowed) {
          this.showModal();
        } else {
          this.closeModal();
        }
      }, 0);
    }
  }

  showModal(): void {
    this.modalRef = this.openModalWithOptions(this.modal, `modal-window ${this.customClass || ''}`);
    this.modalRef.result
      .then(() => {
        this.modalRef = null;
        if (this.idModalInjector) {
          this.modalInjectorService.closeModal(this.idModalInjector);
        }
        this.closeEvent.emit();
      })
      .catch(() => {
        this.modalRef = null;
        if (this.idModalInjector) {
          this.modalInjectorService.closeModal(this.idModalInjector);
        }
        this.closeEvent.emit();
      });
  }

  private openModalWithOptions(content: any, wclass?: string): NgbModalRef {
    return this.modalService.open(content, {
      windowClass: 'dc-modal ' + (wclass || ''),
      backdrop: this.backdrop,
      keyboard: this.keyboard,
      centered: this.centered,
      beforeDismiss: this.beforeDismiss,
    });
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  dismissModal() {
    if (this.modalRef) {
      if (this.preventClose) {
        this.closeEvent.emit();
      } else {
        this.modalRef.dismiss();
      }
    }
  }
}
