import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '@app/environments/environment';

import { Observable, Subject, throwError as observableThrowError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { Log } from '@app/shared/models/log.model';
import { Employee } from '@app/shared/models/employee.model';
import { EmployeeTreeFilter } from '@app/+employees/shared';
import { mapToObject } from '@app/shared/utils';
import { ChatService } from '@app/chat/services/chat.service';
import { User } from '@app/shared/models/user.model';
import { doForAllSync } from '@app/+competence-map/helpers/competence-map.helpers';
import { DutyFormData, DutyListItem, DutyQueryParams } from '@app/shared/models/duty.model';

@Injectable()
export class EmployeesService {
  employeeAdded = new Subject<Employee>();
  employee: Employee = new Employee();

  constructor(private http: HttpClient, private chatService: ChatService) {}

  getSelfEmployees(user_id: number, queryParams?: EmployeeTreeFilter): Observable<Employee> {
    const fromObject = mapToObject(queryParams || {});
    const params = new HttpParams({ fromObject });

    return this.http
      .get(`${environment.api_url}/user/${user_id}/employees`, { params })
      .pipe(map((user) => new Employee(user)));
  }

  getEmployees(user_id: number): Observable<Employee> {
    return this.http
      .get(`${environment.api_url}/user/${user_id}/employees`, {
        params: {
          include: 'company.owner,trades',
          all: 'true',
        },
      })
      .pipe(
        map((user: User) => {
          doForAllSync((user as any).children, (node: any) => {
            node.room_id = this.chatService.getRoomIdByUserId(node.id);
          });

          return new Employee(user);
        })
      );
  }

  getEmployeeData(user_id): Observable<any> {
    return this.http.get(`${environment.api_url}/user/${user_id}`);
  }

  getStatusLogs(employee_id): Observable<Array<Log>> {
    return this.http.get<Array<Log>>(`${environment.api_url}/user/${employee_id}/status_log`).pipe(
      map((logs: Array<Log>) => {
        return logs.map((log) => new Log(log));
      })
    );
  }

  createEmployee(user_id, employee: Employee): Observable<Employee> {
    return this.http.post<Employee>(`${environment.api_url}/user/${user_id}/employees`, employee).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  updateUserData(user_id, data): Observable<any> {
    return this.http.put(`${environment.api_url}/user/${user_id}`, data);
  }

  sendInvitation(user_id, data): Observable<any> {
    return this.http.put(`${environment.api_url}/user/${user_id}/invitation`, data);
  }

  getExperts(): Observable<Employee[]> {
    return this.http.get<User[]>(`${environment.api_url}/users?filter[type]=expert`).pipe(
      map((users: Array<User>) => users.map((user) => new Employee(user))),
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  getSuperuser(): Observable<Employee> {
    return this.http.get<User[]>(`${environment.api_url}/users?filter[type]=superuser`).pipe(
      map((users: Array<User>) => users.map((user) => new Employee(user))[0]),
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  getAgents(): Observable<Employee[]> {
    return this.http.get<User[]>(`${environment.api_url}/users?filter[type]=partner`).pipe(
      map((agents: Array<User>) => agents.map((agent) => new Employee(agent))),
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  /**
   * Выгрузить график дежурств за определенный срок (по умолчанию выгружает только активные
   и неподтвержденные дежурства).
   Доступно АТП и агентам. Агент может видеть только свои дежурства. Для АТП, если user_id не указан, или id=0, выгружается для всех.
   */
  getDuty(params: DutyQueryParams): Observable<DutyListItem[]> {
    const httpParams = {};
    Object.keys(params).forEach((key) => (httpParams[key] = params[key]));

    return this.http
      .get(`${environment.api_url}/user/duty`, {
        params: httpParams,
      })
      .pipe(map((res: DutyListItem[]) => res));
  }

  setDuty(data: DutyFormData): Observable<DutyListItem> {
    return this.http.post(`${environment.api_url}/user/duty`, data).pipe(map((res: DutyListItem) => res));
  }

  acceptDuty(schedule_id: number) {
    return this.http.patch(`${environment.api_url}/user/duty/${schedule_id}`, {});
  }

  deleteDuty(schedule_id: number) {
    return this.http.delete(`${environment.api_url}/user/duty/${schedule_id}`);
  }
}
