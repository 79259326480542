<div (click)="cancel()" class="dc-fileman-modal-x-button">
  <app-svg-icon [width]="20" [height]="20" name="close"></app-svg-icon>
</div>
<div class="modal-body">
  <div class="select-profile-confirm__title">Вы действительно хотите выбрать данный файл?</div>
  <a class="select-profile-confirm__file" (click)="viewFile()">Посмотреть файл: "{{ file?.name }}"</a>
</div>
<div class="modal-footer">
  <div class="dc-footer-buttons">
    <button class="btn btn-dc-outline" (click)="cancel()">Нет, отменить</button>
    <button class="btn btn-dc-primary" (click)="save()">Да, применить</button>
  </div>
</div>
