<ng-template #modal let-close="close">
  <div
    (click)="dismissModal()"
    [class.disabled_icon]="disableClose"
    class="move-pin__close-button"
    appTestLocator="modal__close-button"
  ></div>
  <div class="modal-window-content" (clickOutside)="outClick.emit()">
    <ng-content></ng-content>
  </div>
</ng-template>
