<div class="form-group-icon">
  <input
    [type]="isShow ? 'text' : 'password'"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [value]="value"
    [ngClass]="classes"
    (input)="onChange($event.target.value)"
    (blur)="onTouched()"
  />
  <button type="button" class="icon-button form-control-icon" (click)="changeInputType()">
    <app-svg-icon
      *ngIf="!isShow"
      width="18"
      height="12"
      name="eye"
    ></app-svg-icon>

    <app-svg-icon
      *ngIf="isShow"
      width="20"
      height="16"
      name="eye_slash"
    ></app-svg-icon>
  </button>
</div>
