import { Pipe, PipeTransform } from '@angular/core';
import { DatesHelper } from '../helpers/dates.helper';

@Pipe({
  name: 'dateTimeFormat',
})
export class DateTimeFormatPipe implements PipeTransform {
  transform(value: any, offset?: any): any {
    if (!value) {
      return '-';
    }
    return DatesHelper.formatDateTime(value, offset);
  }
}
