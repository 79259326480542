export enum InviteSupplierStatuses {
  INVITED = 'invited',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  SELECTED = 'selected',
}

export const INVITE_SUPPLIERS_LABELS = {
  [InviteSupplierStatuses.INVITED]: 'Приглашен',
  [InviteSupplierStatuses.ACCEPTED]: 'Допущен к торгам',
  [InviteSupplierStatuses.DECLINED]: 'Отказ поставщика',
  [InviteSupplierStatuses.SELECTED]: 'Выбран исполнителем',
};

export const SUPPLIER_CARD_STATUSES = [
  {
    id: InviteSupplierStatuses.INVITED,
    name: INVITE_SUPPLIERS_LABELS[InviteSupplierStatuses.INVITED],
    checked: false,
  },
  {
    id: InviteSupplierStatuses.ACCEPTED,
    name: INVITE_SUPPLIERS_LABELS[InviteSupplierStatuses.ACCEPTED],
    checked: false,
  },
  {
    id: InviteSupplierStatuses.DECLINED,
    name: INVITE_SUPPLIERS_LABELS[InviteSupplierStatuses.DECLINED],
    checked: false,
  },
  {
    id: InviteSupplierStatuses.SELECTED,
    name: INVITE_SUPPLIERS_LABELS[InviteSupplierStatuses.SELECTED],
    checked: false,
  },
];
