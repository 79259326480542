<app-rich-modal (close)="onChatClose()" class="chat">
  <div class="chat__loader" *ngIf="chatLoading$ | async">
    <app-svg-icon [height]="64" [width]="64" name="loader-animate" class="chat__loader-icon"></app-svg-icon>
  </div>
  <div class="row chat__tabs">
    <div [ngClass]="isContactsHidden ? 'col-12' : 'col-7'">
      <app-chat-tabs></app-chat-tabs>
    </div>
    <div [ngClass]="isContactsHidden ? 'hidden' : 'col-5'">
      <app-chat-section-select></app-chat-section-select>
    </div>
  </div>
  <div class="row chat__messages-row">
    <div [ngClass]="isContactsHidden ? 'col-12' : 'col-7'" class="chat__messages">
      <!-- <app-chat-messages *ngIf="contactSelected?.room_id"></app-chat-messages> -->
      <router-outlet></router-outlet>

      <div
        (click)="$event.stopPropagation(); toggleContactsHidden()"
        class="chat__contacts-toggle d-flex align-items-center justify-content-center"
      >
        <fa-icon [icon]="['fas', isContactsHidden ? 'angle-left' : 'angle-right']"></fa-icon>
      </div>
    </div>
    <div [ngClass]="isContactsHidden ? 'hidden' : ''" class="col-5 chat__contacts-and-groups">
      <app-chat-groups></app-chat-groups>
      <app-chat-contacts
        *ngIf="
          chatSectionSelected.name !== chatSectionsEnum.TECH && chatSectionSelected.name !== chatSectionsEnum.DUTY_TSO
        "
      ></app-chat-contacts>
    </div>
  </div>
  <div class="row filter-row">
    <div [ngClass]="isContactsHidden ? 'col-12' : 'col-7'">
      <app-chat-input *ngIf="!isTradeGroup(contactSelected)"></app-chat-input>
    </div>
    <div
      [ngClass]="isContactsHidden ? 'hidden' : 'col-5'"
      *ngIf="
        chatSectionSelected.name !== chatSectionsEnum.TECH && chatSectionSelected.name !== chatSectionsEnum.DUTY_TSO
      "
    >
      <app-chat-contacts-filter></app-chat-contacts-filter>
    </div>
  </div>
  <!-- popup for editing group -->
  <app-chat-group-or-theme-edit *ngIf="isEditingGroupOrTheme"></app-chat-group-or-theme-edit>
  <!-- popup for editing group end -->
  <!-- popup for editing group -->
  <app-chat-contacts-only-filter
    *ngIf="
      isEditingContactsOnlyFilter &&
      (chatSectionSelected.name === chatSectionsEnum.ADMIN ||
        chatSectionSelected.name === chatSectionsEnum.HOLDING ||
        chatSectionSelected.name === chatSectionsEnum.DUTY_TSO)
    "
  ></app-chat-contacts-only-filter>
  <!-- popup for editing group end -->
</app-rich-modal>
