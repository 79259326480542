import { Pipe, PipeTransform } from '@angular/core';
import { UserTypes } from '@app/shared/types/user.types';
import { getShortTitleRole, getTitleRole } from '@app/shared/utils';

@Pipe({
  name: 'titleRole',
})
export class TitleRolePipe implements PipeTransform {
  transform(type: UserTypes, isShort: boolean = false): any {
    return isShort ? getShortTitleRole(type) : getTitleRole(type);
  }
}
