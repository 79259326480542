<h2 class="mb-4">Подключенные фильтры БПФ</h2>

<div class="d-flex">
  <div class="competence-map-user-categories">
    <div class="competence-map-user-categories-title">Категории фильтров</div>
    <ng-container *ngIf="section?.id">
      <div *ngFor="let filterCategory of filtersCategories" class="filter-category">
        <div class="competence-map-filter-category">
          <div class="mr-2">
            <app-svg-icon name="folder" width="16" *ngIf="filterCategory.value !== category?.value"></app-svg-icon>
            <app-svg-icon name="folder_open" width="16" *ngIf="filterCategory.value === category?.value"></app-svg-icon>
          </div>
          <div
            class="competence-map-filter-category__title"
            (click)="selectCategory(filterCategory)"
            [class.disabled]="!filterCategory.filtersCount"
          >
            {{ filterCategory.label }}
            <div class="competence-map-filter-category__counter">{{ filterCategory.filtersCount }}</div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="competence-map-filters-connected">
    <div class="d-flex mb-3 align-items-center">
      <div class="mr-3 d-inline-flex" [class.disabled]="readonly || !section">
        <input
          class="cbx-input"
          id="all_filters_selected_{{ catalog }}"
          type="checkbox"
          [ngModel]="allFiltersSelected"
          (ngModelChange)="selectAll($event)"
        />
        <label
          class="cbx-label"
          for="all_filters_selected_{{ catalog }}"
          [class.cbx-label-interminate]="allPartiallySelected"
          [class.disabled]="!data.length"
        ></label>
      </div>
      <div class="competence-map-section-header">
        <div class="competence-map-section-header-name">
          <span class="mr-2">Название фильтра</span>
          <div class="dc-icon-filter-button">
            <button
              class="dc-icon-button"
              (click)="openSearchModal()"
              [class.disabled]="!data.length"
              [class.active]="hasFilterValue(CMActions.SEARCH)"
            >
              <app-svg-icon name="search" width="12" height="12"></app-svg-icon>
            </button>
            <button
              class="dc-icon-button ml-1"
              (click)="filterTreeData([])"
              [class.dc-icon-button-hidden]="!hasFilterValue(CMActions.SEARCH)"
            >
              <app-svg-icon name="close_wide" width="12" height="12"></app-svg-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="section" class="dc-tree--my-competencies">
      <tree-root #filtersTree [nodes]="data" [options]="treeOptions" class="dc-tree">
        <ng-template #treeNodeTemplate let-node let-index="index">
          <app-my-catalog-filter-tree-item
            [readonly]="readonly"
            [node]="node"
            [item]="node.data"
            (onToggle)="expandItem(filtersTree, node, $event)"
            (onSelect)="filterSelected($event)"
            (saveBoolEvent)="saveBool()"
            (saveRangeEvent)="saveRange($event)"
            (onCheckboxChanges)="checkboxSelectionChanged($event)"
          ></app-my-catalog-filter-tree-item>
        </ng-template>
      </tree-root>
    </div>
  </div>
</div>

<app-modal-window [isShowed]="modals[CMActions.SEARCH]?.isShowed" (closeEvent)="closeActionModal(CMActions.SEARCH)">
  <div class="modal-header">
    <div class="modal-title">Поиск по БПФ</div>
  </div>
  <app-multiple-search-text
    *ngIf="modals[CMActions.SEARCH]?.isShowed"
    [filterSuggestions]="treeSuggestions"
    placeholder="Начните вводить название БПФ"
    (submitEvent)="filterTreeData($event.value)"
  ></app-multiple-search-text>
</app-modal-window>
