<app-modal-window [isShowed]="_isOpen" [customClass]="'share-modal'" (closeEvent)="this.cancel()">
  <div class="fileman-file-open" *ngIf="_isOpen && !filter.isOpen">
    <div class="modal-header">
      <p class="text-center modal-title margin-b-10">
        {{ 'fm_access_header' | translate }}
      </p>
      <div class="file-name">
        <p>
          <b>{{ 'fm_access_files_label' | translate }}</b> {{ getSelectedFileName(files) }}
          <span
            *ngIf="files?.length > SHARE_MAX_FILES_COUNT_TITLE"
            #filenameFilesBlock
            (click)="showMoreSelectedFileName(filenameFilesBlock, files)"
          >
            {{ 'fm_share_more_title' | translate }}
            {{ files.slice(SHARE_MAX_FILES_COUNT_TITLE, files.length).length }}
          </span>
        </p>
      </div>
    </div>
    <div class="modal-body shared-users-grid">
      <div class="shared-users-grid-block" *ngIf="users[SHARE_TYPE.DISALLOW]">
        <div class="share-modal-header margin-b-14">
          <div>{{ 'fm_access_disallow' | translate }}</div>
          <button class="btn-link hover" (click)="onFilter(SHARE_TYPE.DISALLOW)">
            <app-svg-icon
              [width]="12"
              [height]="12"
              name="sort-amount-down"
              *ngIf="!filterApplied[SHARE_TYPE.DISALLOW]"
            >
            </app-svg-icon>
            <app-svg-icon
              [width]="20"
              [height]="20"
              name="sort-amount-down-color"
              *ngIf="filterApplied[SHARE_TYPE.DISALLOW]"
            >
            </app-svg-icon>
          </button>
          <button *ngIf="filterApplied[SHARE_TYPE.DISALLOW]" class="btn-link hover">
            <app-svg-icon
              [width]="12"
              [height]="12"
              name="times"
              (click)="resetFilters(SHARE_TYPE.DISALLOW, applyFilters)"
            >
            </app-svg-icon>
          </button>
        </div>
        <div class="share-modal-search">
          <form [formGroup]="filters[SHARE_TYPE.DISALLOW]?.search">
            <dc-form-field-wrapper [style]="'material'" [vertically]="true">
              <dc-form-input [isShowLength]="false">
                <input
                  formInput
                  formControlName="query"
                  placeholder="{{ 'fm_search_by_user' | translate }}"
                  [attr.disabled]="false"
                />
              </dc-form-input>
            </dc-form-field-wrapper>
          </form>
          <button
            class="btn-link hover shared-users-little-icons"
            (click)="resetFilters(SHARE_TYPE.DISALLOW, applyFilters)"
          >
            <app-svg-icon [width]="13" [height]="13" name="times"></app-svg-icon>
          </button>
        </div>
        <div class="d-flex margin-t-20 shared-users-select">
          <div class="shared-users-select-checkbox" [class.disabled]="isSelectAllDisabled()">
            <input
              *ngIf="selected"
              [checked]="selected[SHARE_TYPE.DISALLOW]"
              [disabled]="loading[SHARE_TYPE.DISALLOW]"
              (change)="selectAllUsers(SHARE_TYPE.DISALLOW)"
              class="cbx-input"
              [id]="'selected_' + SHARE_TYPE.DISALLOW"
              type="checkbox"
            />
            <label class="cbx-label" [for]="'selected_' + SHARE_TYPE.DISALLOW"></label>
          </div>
          <p class="margin-l-10">{{ 'fm_select_all' | translate }}</p>
        </div>
        <div class="shared-users-section-toggles margin-l-26">
          <button class="dc-icon-button" (click)="toggleByLevel(disallowTree, 1)">
            <app-svg-icon [name]="!isUnshareTreeExpandedL1 ? 'caret_down' : 'caret_up'" [width]="10" [height]="6">
            </app-svg-icon>
          </button>
          <button class="dc-icon-button" (click)="toggleByLevel(disallowTree, 2)">
            <app-svg-icon [name]="!isUnshareTreeExpandedL2 ? 'caret_down' : 'caret_up'" [width]="10" [height]="6">
            </app-svg-icon>
          </button>
        </div>
        <div class="shared-users-list list-unstyled" [class.shared-users-list__loading]="loading[SHARE_TYPE.DISALLOW]">
          <tree-root #disallowTree [nodes]="users[SHARE_TYPE.DISALLOW]" [options]="treeOptions" class="dc-tree">
            <ng-template #treeNodeTemplate let-node let-index="index">
              <app-share-tree-item
                [node]="node"
                [item]="node.data"
                [detached]="isDetached(SHARE_TYPE.DISALLOW, node.data.id)"
                [type]="SHARE_TYPE.DISALLOW"
                (onToggle)="expandItem(disallowTree, node, $event)"
                (onInfo)="toggleUserInfo($event)"
                (onCompetence)="openUserCompetence($event)"
                (onAdd)="addSelectedUsersFromTo(SHARE_TYPE.DISALLOW, SHARE_TYPE.ALLOW)"
              >
              </app-share-tree-item>
            </ng-template>
          </tree-root>
        </div>
      </div>
      <div class="shared-users-controls margin-b-6">
        <button
          class="margin-b-10"
          [disabled]="!(getSelectedUsers(SHARE_TYPE.ALLOW).length > 0)"
          (click)="addSelectedUsersFromTo(SHARE_TYPE.ALLOW, SHARE_TYPE.DISALLOW)"
        >
          <app-svg-icon [width]="20" [height]="20" name="caret-square-left"></app-svg-icon>
        </button>
        <button
          [disabled]="!(getSelectedUsers(SHARE_TYPE.DISALLOW).length > 0)"
          (click)="addSelectedUsersFromTo(SHARE_TYPE.DISALLOW, SHARE_TYPE.ALLOW)"
        >
          <app-svg-icon [width]="20" [height]="20" name="caret-square-right"></app-svg-icon>
        </button>
      </div>
      <div class="shared-users-grid-block" *ngIf="users[SHARE_TYPE.ALLOW]">
        <div class="share-modal-header margin-b-14">
          <div>{{ 'fm_access_allow' | translate }}</div>
          <button class="btn-link hover" (click)="onFilter(SHARE_TYPE.ALLOW)">
            <app-svg-icon [width]="12" [height]="12" name="sort-amount-down" *ngIf="!filterApplied[SHARE_TYPE.ALLOW]">
            </app-svg-icon>
            <app-svg-icon
              [width]="20"
              [height]="20"
              name="sort-amount-down-color"
              *ngIf="filterApplied[SHARE_TYPE.ALLOW]"
            >
            </app-svg-icon>
          </button>
          <button *ngIf="filterApplied[SHARE_TYPE.ALLOW]" class="btn-link hover">
            <app-svg-icon
              [width]="12"
              [height]="12"
              name="times"
              (click)="resetFilters(SHARE_TYPE.ALLOW, applyFilters)"
            >
            </app-svg-icon>
          </button>
        </div>
        <div class="share-modal-search">
          <form [formGroup]="filters[SHARE_TYPE.ALLOW]?.search">
            <dc-form-field-wrapper [style]="'material'" [vertically]="true">
              <dc-form-input [isShowLength]="false">
                <input
                  formInput
                  formControlName="query"
                  placeholder="{{ 'fm_search_by_user' | translate }}"
                  [attr.disabled]="false"
                />
              </dc-form-input>
            </dc-form-field-wrapper>
          </form>
          <button
            class="btn-link hover shared-users-little-icons"
            (click)="resetFilters(SHARE_TYPE.ALLOW, applyFilters)"
          >
            <app-svg-icon [width]="13" [height]="13" name="times"></app-svg-icon>
          </button>
        </div>
        <div class="d-flex margin-t-20">
          <div [class.disabled]="isSelectAllDisabled()">
            <input
              *ngIf="selected"
              [checked]="selected[SHARE_TYPE.ALLOW]"
              [disabled]="loading[SHARE_TYPE.ALLOW]"
              (change)="selectAllUsers(SHARE_TYPE.ALLOW)"
              class="cbx-input"
              [id]="'selected_' + SHARE_TYPE.ALLOW"
              type="checkbox"
            />
            <label class="cbx-label" [for]="'selected_' + SHARE_TYPE.ALLOW"></label>
          </div>
          <p class="margin-l-10">{{ 'fm_select_all' | translate }}</p>
        </div>
        <div class="shared-users-list list-unstyled" [class.shared-users-list__loading]="loading[SHARE_TYPE.ALLOW]">
          <div *ngFor="let item of users[SHARE_TYPE.ALLOW]">
            <app-share-tree-item
              [item]="item"
              [type]="SHARE_TYPE.ALLOW"
              [detached]="false"
              (onInfo)="toggleUserInfo($event)"
              (onCompetence)="openUserCompetence($event)"
              (onAdd)="addSelectedUsersFromTo(SHARE_TYPE.ALLOW, SHARE_TYPE.DISALLOW)"
            >
            </app-share-tree-item>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer dc-footer-buttons margin-t-20">
      <button class="btn btn-dc-outline margin-r-30" (click)="cancel()">
        {{ 'fm_cancel' | translate }}
      </button>
      <button class="btn btn-dc-primary" (click)="save()">
        {{ 'fm_save' | translate }}
      </button>
    </div>
  </div>
  <app-share-filter
    [isOpen]="filter.isOpen"
    [type]="filter.type"
    [filter]="filters[filter.type]"
    (onApply)="filter.action()"
    (onCancel)="filter.close()"
  >
  </app-share-filter>
</app-modal-window>
<app-modal-window [isShowed]="!!selectedUserInfo" (closeEvent)="toggleUserInfo()">
  <app-user-card [user]="selectedUserInfo" *ngIf="!!selectedUserInfo"></app-user-card>
</app-modal-window>
