import { Directive, ElementRef, HostListener } from '@angular/core';
import { FormControl, NG_VALIDATORS } from '@angular/forms';
import { MIN_MAX_REGEXP } from '@app/+trades/constants/lots.constants';

@Directive({
  selector: '[criteriaNumber]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: CriteriaNumberDirective,
      multi: true,
    },
  ],
})
export class CriteriaNumberDirective {
  private specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    'Control',
    'v',
    'ArrowLeft',
    'ArrowRight',
    'Delete',
  ];
  private reqexValidation = /^\-?\d+(\.[0-9]+)*$/;

  constructor(private el: ElementRef) {}

  validate(c: FormControl): { [key: string]: { valid: boolean } } {
    const v = c.value;
    const invalid = { onlyNumber: { valid: false } };

    // if (v && !String(v).match(this.regex)) {
    //   return invalid;
    // }

    if (v && !this.reqexValidation.test(v)) {
      return invalid;
    }

    return null;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    const current: string = this.el.nativeElement.value;

    const next: string = this.splice((event.target as any).selectionStart, 0, event.key, current);
    const selected = this.getSelectedText(event.target);

    if (next && !String(next).match(MIN_MAX_REGEXP) && !selected) {
      event.preventDefault();
      return;
    }
  }

  getSelectedText(elem) {
    if (elem.tagName === 'TEXTAREA' || (elem.tagName === 'INPUT' && elem.type === 'text')) {
      return elem.value.substring(elem.selectionStart, elem.selectionEnd);
    }
    return null;
  }

  splice(start, delCount, newSubStr, value) {
    return value.slice(0, start) + newSubStr + value.slice(start + Math.abs(delCount));
  }
}
