import { cloneDeep } from 'lodash-es';
import { TradesService } from '@app/+trades/services/trades.service';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { TradeCard } from '@app/+trades/models/trades.model';
import { SUPPLIERS_SELECTION_TABS } from '@app/+competence-map/constants/competence-map.constants';
import { CatalogTab } from '@app/+competence-map/models/competence-map.models';
import { CompetenceService } from '@app/+competence-map/services/competence.service';
import { CMCatalogTypes } from '@app/+competence-map/constants/sections.constants';
import { TradeCompetency, TreeSectionsAndFilters } from '@app/+competence-map/models/user-competency.model';
import {
  CompetenceDataService,
  GOODS_COMPETENCE_STATE,
  SERVICES_COMPETENCE_STATE,
} from '@app/+competence-map/services/competence-data.service';
import { filter, map, switchMap, takeUntil, tap, finalize, debounceTime } from 'rxjs/operators';
import { TreeHelper } from '@app/shared/helpers/tree.helper';
import { DestroyService } from '@app/services/destroy.service';
import { Subject } from 'rxjs';
import { UserCompetencyTypes } from '../constants/user-competency.constants';
import { AdditionalFilters } from '../models/suppliers-selection.model';
import { TradesHelper } from '@app/+trades/helpers/trades.helper';
import { PotentialProviderInfo } from '@app/+trades/models/suppliers.model';
import { TradeCompetenciesService } from '@app/+trades/services/trade-competencies.service';

@Component({
  selector: 'app-suppliers-selection',
  templateUrl: './suppliers-selection.component.html',
  styleUrls: ['./suppliers-selection.component.scss'],
  providers: [DestroyService],
})
export class SuppliersSelectionComponent {
  private _card = <TradeCard>{};
  get card() {
    return this._card;
  }
  @Input() set card(value: TradeCard) {
    if (value) {
      this._card = value;
      this.additionalFilters = cloneDeep(this._card.providers_filter?.additional_filters);
      this.loadCompetencies();
    }
  }
  @Input() readonly: boolean;

  @Output() changeSearchData = new EventEmitter<TreeSectionsAndFilters[]>();

  potentialSuppliers: PotentialProviderInfo[];
  additionalFilters: AdditionalFilters;
  competencies: TradeCompetency[];
  catalogTypes = CMCatalogTypes;
  readonly tabs: CatalogTab[] = SUPPLIERS_SELECTION_TABS;
  currentTab: CatalogTab = SUPPLIERS_SELECTION_TABS[0];
  currentTabValue: string = this.currentTab.value;
  isShowHelp = false;
  selectedServicesCount: number = 0;
  selectedGoodsCount: number = 0;
  loadingSuppliers: boolean = false;

  private _searchSuppliers$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(SERVICES_COMPETENCE_STATE) private servicesStateService: CompetenceDataService,
    @Inject(GOODS_COMPETENCE_STATE) private goodsStateService: CompetenceDataService,
    private tradesService: TradesService,
    private competenceService: CompetenceService,
    private tradeCompetenciesService: TradeCompetenciesService,
    private destroy$: DestroyService
  ) {
    this.tradesService.cardChanged$
      .pipe(
        map((card) => card.providers_filter?.data),
        filter((competencies) => !!competencies && Array.isArray(competencies)),
        map((competencies) => competencies.filter((c) => c.type === UserCompetencyTypes.SECTOR)),
        takeUntil(this.destroy$)
      )
      .subscribe((competencies) => this.setSelectedSections(competencies));

    this._searchSuppliers$
      .pipe(
        debounceTime(200),
        map(() => [
          ...this.goodsStateService.competencySelectionSections,
          ...this.goodsStateService.competencySelectionFilters,
          ...this.servicesStateService.competencySelectionSections,
          ...this.servicesStateService.competencySelectionFilters,
        ]),
        tap((selected) => this.changeSearchData.emit(selected)),
        switchMap((selected) =>
          this.getProviders(TradesHelper.convertTreeCompetenciesToTradeCompetencies(selected, this.card.id))
        ),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  loadCompetencies() {
    this.competenceService
      .getTradeCompetency([{ name: 'trade_id', op: 'eq', val: this._card.id }])
      .pipe(
        tap((competencies) => {
          this.competencies = competencies;
          this.tradeCompetenciesService.set(competencies);
        }),
        switchMap((nodes) => this.getProviders(nodes)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  onSelectTab(tab: CatalogTab) {
    this.currentTabValue = tab.value;
  }

  onSearch(): void {
    this._searchSuppliers$.next();
  }

  additionalFiltersChanged(data: AdditionalFilters) {
    if (this._card.providers_filter) {
      this._card.providers_filter.additional_filters = data;
    } else {
      this._card.providers_filter = {
        additional_filters: data,
        data: [],
      };
    }

    this.tradesService.storeCard(this._card);
    this.onSearch();
  }

  showedMyCompetencies(
    event: {
      nodes: TreeSectionsAndFilters[];
      hiddenIds: { [key: number]: boolean };
    } | null
  ) {
    let selected: TradeCompetency[];
    const catalog = this.currentTabValue === 'product_catalog' ? CMCatalogTypes.GOODS : CMCatalogTypes.SERVICES;

    if (event) {
      const stateService = catalog === CMCatalogTypes.GOODS ? this.servicesStateService : this.goodsStateService;
      const selectedMyCompetencies = TradesHelper.convertTreeCompetenciesToTradeCompetencies(
        event.nodes,
        this.card.id
      ).filter((el) => !event.hiddenIds[el.node_in_tree_id]);

      catalog === CMCatalogTypes.GOODS
        ? (this.selectedGoodsCount = selectedMyCompetencies.length)
        : (this.selectedServicesCount = selectedMyCompetencies.length);

      selected = [
        ...selectedMyCompetencies,
        ...TradesHelper.convertTreeCompetenciesToTradeCompetencies(
          stateService.competencySelectionSections,
          this.card.id
        ),
        ...TradesHelper.convertTreeCompetenciesToTradeCompetencies(
          stateService.competencySelectionFilters,
          this.card.id
        ),
      ];
    } else {
      const goodsSelected = TradesHelper.convertTreeCompetenciesToTradeCompetencies(
        this.goodsStateService.competencySelectionSections,
        this.card.id
      );
      const serviceSelected = TradesHelper.convertTreeCompetenciesToTradeCompetencies(
        this.servicesStateService.competencySelectionSections,
        this.card.id
      );

      (selected = [
        ...goodsSelected,
        ...TradesHelper.convertTreeCompetenciesToTradeCompetencies(
          this.goodsStateService.competencySelectionFilters,
          this.card.id
        ),
        ...serviceSelected,
        ...TradesHelper.convertTreeCompetenciesToTradeCompetencies(
          this.servicesStateService.competencySelectionFilters,
          this.card.id
        ),
      ]),
        catalog === CMCatalogTypes.GOODS
          ? (this.selectedGoodsCount = goodsSelected.length)
          : (this.selectedServicesCount = serviceSelected.length);
    }

    this.getProviders(selected).pipe(takeUntil(this.destroy$)).subscribe();
  }

  private getProviders(nodes: TradeCompetency[]) {
    this.loadingSuppliers = true;
    return this.competenceService.getProviders(TradesHelper.buildPotentialProvidersPayload(this.card, nodes)).pipe(
      tap((value: PotentialProviderInfo[]) => {
        this.potentialSuppliers = value.filter((supplier) => supplier.user_info.id !== this.card.owner.id);
      }),
      finalize(() => (this.loadingSuppliers = false))
    );
  }

  private setSelectedSections(competencies: TreeSectionsAndFilters[]) {
    this.selectedGoodsCount = 0;
    this.selectedServicesCount = 0;

    competencies.forEach((tree) => {
      const increment = TreeHelper.flatTree(tree).filter((el) => el.selected).length;
      tree.catalog === CMCatalogTypes.GOODS
        ? (this.selectedGoodsCount += increment)
        : (this.selectedServicesCount += increment);
    });
  }
}
