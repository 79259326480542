import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { delay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { FileManagerService } from '../services/file_manager.service';
import { UserFile } from '../models/user-file.model';
import { AuthService } from '@app/shared/services/auth.service';
import { HttpEventType } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  BUFFER_TYPE,
  ExtType,
  FILE_MANAGER_LAYOUTS,
  LegendInterface,
  MODULE_TITLE,
  SHARE_TYPE,
  VIEW_MODE_TYPE,
} from '../file_manager.constants';
import { TranslateService } from '@ngx-translate/core';
import { Role } from '@app/shared/models/user.model';
import { ROLES, RolesEnum } from '@app/shared/constants/roles.constants';
import print from 'print-js';
import saveAs from 'file-saver';
import { CompetenceService } from '@app/+competence-map/services/competence.service';
import { Router } from '@angular/router';
import { REDIRECT_TO_LOGIN } from '@app/auth/redirect-after-login.constants';
import { AuthorFile } from '@app/file_manager/models/author-file.model';
import { DestroyService } from '@app/services/destroy.service';

@Component({
  selector: 'app-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.scss'],
  providers: [DestroyService],
})
export class FileManagerComponent implements OnInit, OnDestroy {
  @Input() multipleSelection = true;
  @Input() layout: FILE_MANAGER_LAYOUTS = FILE_MANAGER_LAYOUTS.BASE;
  @Input() disabledExportButton: boolean = false;

  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('shareInput') shareInput: ElementRef;

  fileManagerLayouts = FILE_MANAGER_LAYOUTS;

  CURRENT_DIR: UserFile;
  VIEW_TYPE = VIEW_MODE_TYPE;
  MODULE_TITLE = MODULE_TITLE;
  VIEW: VIEW_MODE_TYPE = VIEW_MODE_TYPE.LIST;
  BUFFER_TYPE: BUFFER_TYPE;
  SHARE_TYPE = SHARE_TYPE;

  files: any = {};

  filters: any = {};
  searchResults: number[];

  ROLES: Role[] = ROLES;

  searchForm: FormGroup;

  isSearch: boolean = false;
  isSearchShare: boolean = false;
  isRootOpened: boolean = true;
  isRootShareOpened: boolean = false;

  limit: number = 0;
  used: number = 0;
  remaining: number = 0;
  percentUsed: number = 0;

  selected: UserFile[] = [];
  buffer: UserFile[] = [];
  history: UserFile[] = [];
  legends: LegendInterface[] = [];

  modal: any = {
    share: {
      files: [],
      isOpen: false,
      action: (users: [AuthorFile[], AuthorFile[]]) => {
        if (this.user.user_type === RolesEnum.SUPERUSER) {
          if (!users[this.SHARE_TYPE.DISALLOW].length && users[this.SHARE_TYPE.ALLOW].length) {
            this.shareAll(this.modal.share.files);
            return;
          }

          if (users[this.SHARE_TYPE.DISALLOW].length && !users[this.SHARE_TYPE.ALLOW].length) {
            this.unshareAll(this.modal.share.files);
            return;
          }
        }

        this.share(
          this.modal.share.files,
          users[this.SHARE_TYPE.ALLOW].map((user: AuthorFile) => user.id),
          users[this.SHARE_TYPE.DISALLOW].map((user: AuthorFile) => user.id)
        );
      },
      close: () => {
        this.modal.share.files = [];
        this.modal.share.isOpen = false;
      },
    },
    helper: {
      isOpen: false,
      close: () => {
        this.modal.helper.isOpen = false;
      },
    },
  };

  @Output() onExportSelected = new EventEmitter<UserFile[]>();

  private destroyed$: Subject<boolean> = new Subject();
  private dbClick$: Subject<boolean> = new Subject();
  private onChangeSearch$: Subject<boolean> = new Subject();

  get searchString(): string {
    return this.searchForm.controls.query.value || '';
  }

  constructor(
    private fileManagerService: FileManagerService,
    private user: AuthService,
    private notify: NotificationsService,
    private fb: FormBuilder,
    private translate: TranslateService,
    private competenceService: CompetenceService,
    private router: Router,
    private destroy$: DestroyService
  ) {
    this.translate.setDefaultLang('ru');
    this.initSearch();
  }

  get authUser(): Observable<any> {
    return this.user.userStream;
  }

  ngOnInit(): void {
    if (this.user.user_id) {
      this.init();
      this.fileManagerService.openHelper$.subscribe(() => {
        this.modal.helper.isOpen = true;
      });
    } else {
      this.router.navigate([REDIRECT_TO_LOGIN]);
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.dbClick$.complete();
    this.onChangeSearch$.complete();
    this.destroyed$.complete();
  }

  init(): void {
    this.files = {
      my: new UserFile({ id: 0, opened: true }),
      share: new UserFile({ id: -1, opened: true }, true),
    };
    this.CURRENT_DIR = this.getRootDir();
    forkJoin([
      this.fileManagerService.getFilesRoot(+this.user.user_id, this.getRootDir().id),
      this.fileManagerService.getFilesSharedRoot(+this.user.user_id, this.getRootSharedDir().id),
      this.fileManagerService.space(+this.user.user_id),
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (data: [UserFile[], UserFile[], any]) => {
          this.getRootDir().setChildren(data[0]);
          this.getRootSharedDir().setChildren(data[1]);
          this.limit = data[2].limit;
          this.used = data[2].used;
          this.remaining = data[2].remaining;
          this.percentUsed = data[2].percentUsed;
        },
        (err) => console.log(err)
      );
  }

  onShare(files: UserFile[]) {
    this.modal.share.files = files;
    this.modal.share.isOpen = true;
  }

  exportSelected(selected: UserFile[]) {
    this.onExportSelected.emit(selected);
  }

  getFiles(CURRENT_DIR?: UserFile, callback?: Function): void {
    const _currentDir = CURRENT_DIR || this.CURRENT_DIR;
    (_currentDir.hasRoot()
      ? _currentDir.hasShare()
        ? this.fileManagerService.getFilesSharedRoot(+this.user.user_id, _currentDir.id)
        : this.fileManagerService.getFilesRoot(+this.user.user_id, _currentDir.id)
      : this.fileManagerService.getFilesDir(_currentDir.id, _currentDir.id, _currentDir.hasShare())
    )
      .pipe(
        tap((data: UserFile[]) => {
          _currentDir.setChildren(data.sort(this.fileManagerService.sort.bind(this.fileManagerService)));
          if (CURRENT_DIR) {
            // this.resetSelected();
            // console.log(this.selected);
          }
          if (callback) {
            callback.apply(this, [_currentDir]);
          }
        }),
        switchMap(() => this.fileManagerService.space(+this.user.user_id)),
        takeUntil(this.destroyed$)
      )
      .subscribe(
        (data: any) => {
          this.limit = data.limit;
          this.used = data.used;
          this.remaining = data.remaining;
          this.percentUsed = data.percentUsed;
        },
        (err) => console.log(err)
      );
  }

  onChangeSearch(data: string): void {
    this.onChangeSearch$.next(true);
    of(null)
      .pipe(delay(500), takeUntil(this.onChangeSearch$))
      .subscribe(() => {
        this.search();
      });
  }

  goBack(): void {
    if (!this.CURRENT_DIR.hasRoot()) {
      const file: UserFile = this.findFile(this.CURRENT_DIR.parent);
      if (file) {
        this.history.push(this.CURRENT_DIR);
        this.setCurrentDir(file, true);
      }
    }
  }

  goForward() {
    const file: UserFile = this.history.pop();
    if (file && !file.is_file) {
      this.setCurrentDir(file, true);
    }
  }

  view(): void {
    if (this.VIEW === this.VIEW_TYPE.LIST) {
      this.VIEW = VIEW_MODE_TYPE.DETAIL;
    } else {
      this.VIEW = VIEW_MODE_TYPE.LIST;
    }
  }

  getRootDir(): UserFile {
    return this.files.my;
  }

  getRootSharedDir(): UserFile {
    return this.files.share;
  }

  copy(): void {
    this.BUFFER_TYPE = BUFFER_TYPE.COPY;
    this.buffer = Object.assign([], this.selected);
  }

  cut() {
    this.BUFFER_TYPE = BUFFER_TYPE.CUT;
    this.buffer = Object.assign([], this.selected);
  }

  paste(): void {
    if (this.buffer.length) {
      const obs: Observable<any>[] = this.buffer
        .filter((file: UserFile) => {
          return file.id !== this.CURRENT_DIR.id;
        })
        .map((file: UserFile) => {
          return this.BUFFER_TYPE === BUFFER_TYPE.COPY
            ? this.fileManagerService.copy(file.id, this.CURRENT_DIR.id)
            : this.fileManagerService.move(file.id, this.CURRENT_DIR.id);
        });
      forkJoin(obs).subscribe(
        (data: any) => {
          this.BUFFER_TYPE === BUFFER_TYPE.COPY
            ? this.translate.get('fm_msg_copy_success').subscribe((msg) => this.notify.success('успешно', msg))
            : this.translate.get('fm_msg_move_success').subscribe((msg) => this.notify.success('успешно', msg));
          this.resetBuffer();
          this.resetSelected();
          this.getFiles();
        },
        (err) => {
          this.resetBuffer();
          this.resetSelected();
          console.log(err);
        }
      );
    }
  }

  delete(files: UserFile[]): void {
    const obs: Observable<any>[] = files
      .filter((file: UserFile) => {
        return file.id !== this.CURRENT_DIR.id;
      })
      .map((file: UserFile) => this.fileManagerService.delete(file.id));
    forkJoin(obs).subscribe(
      (data: any) => {
        this.resetBuffer();
        this.resetSelected();
        this.getFiles();
        this.translate.get('fm_msg_delete_success').subscribe((msg) => this.notify.success('успешно', msg));
      },
      (err) => {
        this.resetBuffer();
        this.resetSelected();
        this.translate.get('fm_msg_delete_error').subscribe((msg) => this.notify.error('внимание', msg));
      }
    );
  }

  download(files: UserFile[]): void {
    if (files.length > 1 || files.some((f: UserFile) => !f.is_file)) {
      this.fileManagerService
        .downloadFiles(
          files.map((f: UserFile) => f.id),
          +this.user.user_id
        )
        .subscribe(
          () => {
            this.notify.success('успешно', this.translate.instant('fm_msg_archive_download_success'));
          },
          () => {
            this.notify.error('внимание', this.translate.instant('fm_msg_archive_download_failure'));
          }
        );
    } else if (files.length === 1 && files[0].is_file) {
      this.fileManagerService.downloadFile(files[0].url).subscribe(
        (blob) => {
          saveAs(blob, `${files[0].name}`);
          this.notify.success('успешно', this.translate.instant('fm_msg_download_success'));
        },
        () => {
          this.notify.error('внимание', this.translate.instant('fm_msg_download_failure'));
        }
      );
    }
  }

  print(file: UserFile): void {
    print({
      printable: [file.url],
      type: file.extType().hasPdf ? ExtType.PDF : ExtType.IMAGE,
    });
  }

  upload($event): void {
    if ($event.target.files && $event.target.files.length) {
      const files: FileList = $event.target.files;
      for (let i = 0; i < files.length; i++) {
        const legend: LegendInterface = {
          file: files.item(i),
          status: null,
          error: null,
          subscribe: null,
          ready: false,
        };
        legend.subscribe = (
          this.CURRENT_DIR.hasRoot()
            ? this.fileManagerService.uploadFileRoot(legend.file, +this.user.user_id)
            : this.fileManagerService.uploadFile(legend.file, this.CURRENT_DIR.id)
        )
          .pipe(takeUntil(this.destroyed$))
          .subscribe(
            (event) => {
              switch (event.type) {
                case HttpEventType.Sent:
                  legend.status = 0;
                  break;
                case HttpEventType.Response:
                  legend.status = 100;
                  legend.ready = true;
                  this.translate.get('fm_msg_upload_success').subscribe((msg) => this.notify.success('успешно', msg));
                  this.getFiles();
                  break;
                case 1:
                  {
                    if (Math.round(legend.status) !== Math.round((event['loaded'] / event['total']) * 100)) {
                      legend.status = (event['loaded'] / event['total']) * 100;
                      legend.status = Math.round(legend.status);
                    }
                  }
                  break;
              }
            },
            (error: Error) => {
              legend.status = 100;
              legend.ready = true;
              this.translate.get('fm_msg_upload_failure').subscribe((msg) => (legend.error = msg));
            }
          );
        this.legends.unshift(legend);
      }
      $event.target.value = null;
    }
  }

  add(name: string): void {
    if (
      this.CURRENT_DIR.children
        .filter((file: UserFile) => !file.hasFile())
        .some((file: UserFile) => file.basename() === name)
    ) {
      this.translate.get('fm_msg_file_exist').subscribe((msg) => this.notify.error('внимание', msg));
      return;
    }
    (this.CURRENT_DIR.hasRoot()
      ? this.fileManagerService.addDirRoot(+this.user.user_id, name)
      : this.fileManagerService.addDir(this.CURRENT_DIR.id, name)
    )
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (file: UserFile) => {
          this.CURRENT_DIR.addChildren(file);
          this.sortCurrentDir();
          this.translate.get('fm_msg_add_success').subscribe((msg) => this.notify.success('успешно', msg));
        },
        (err) => {
          console.log(err);
        }
      );
  }

  edit(name: string): void {
    if (this.CURRENT_DIR.children.some((file: UserFile) => file.basename() === name)) {
      this.translate.get('fm_msg_file_exist').subscribe((msg) => this.notify.error('внимание', msg));
      return;
    }
    if (this.selected[0].hasFile()) {
      name += `.${this.selected[0].ext()}`;
    }
    this.fileManagerService
      .editFile(this.selected[0].id, name)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (file: UserFile) => {
          this.CURRENT_DIR.addChildren(file, file.id);
          this.sortCurrentDir();
          this.translate.get('fm_msg_edit_success').subscribe((msg) => this.notify.success('успешно', msg));
          this.resetSelected();
        },
        (err) => {
          console.log(err);
        }
      );
  }

  openDir($event: any) {
    if ($event.file.hasFile()) {
      this.fileManagerService.openFile($event.file);
    } else {
      if ($event.setCurrent) {
        this.setCurrentDir($event.file);
      } else {
        this.getFiles($event.file, (file: UserFile) => {
          file.opened = !file.opened;
        });
      }
    }
  }

  openRootDir(file: UserFile, setCurrent?: boolean) {
    setCurrent = setCurrent === true;
    if (setCurrent) {
      this.dbClick$.next(true);
      this.openDir({ file: file, setCurrent: setCurrent });
    } else {
      of(null)
        .pipe(delay(250), takeUntil(this.dbClick$))
        .subscribe(() => {
          this.openDir({ file: file, setCurrent: setCurrent });
        });
    }
  }

  isSearchQuery(): boolean {
    return this.searchForm.dirty;
  }

  searchFormSubmit() {
    this.search();
  }

  search() {
    if (this.searchString.length) {
      this.fileManagerService
        .search(+this.user.user_id, this.searchString)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: UserFile[]) => {
            this.searchResults = data.map((file: UserFile) => {
              return file.id;
            });
            this.isSearch = true;
          },
          (err) => {
            console.log(err);
            this.resetSearch();
          }
        );
    } else {
      this.resetSearch();
    }
  }

  resetSearch(): void {
    this.searchForm.reset();
    this.isSearch = false;
    this.searchResults = [];
  }

  setCurrentDir(file: UserFile, history?: boolean) {
    // TODO: Временно убрать
    // file.opened = true;
    this.CURRENT_DIR = file;
    this.getFiles(file);
    this.openTree(file);
    this.resetRoot(file);
    if (!history) {
      this.resetHistory();
    }
  }

  openHelper(): void {
    this.fileManagerService.openHelper();
  }

  private share(files: UserFile[], shared: number[], unshared: number[]): void {
    const obsShared: Observable<any>[] = files.map((file: UserFile) => {
      return this.fileManagerService.share(file.id, 'some', shared).pipe(tap(() => (file.is_shared = true)));
    });
    const obsUnshared: Observable<any>[] = files.map((file: UserFile) => {
      return this.fileManagerService.unshare(file.id, 'some', unshared).pipe(tap(() => (file.is_shared = true)));
    });
    forkJoin([...obsShared, ...obsUnshared]).subscribe(
      (data: any) => {
        this.translate.get('fm_msg_share_success').subscribe((msg) => this.notify.success('успешно', msg));
        this.resetBuffer();
        this.resetSelected();
        this.modal.share.close();
      },
      (err) => {
        this.resetBuffer();
        this.resetSelected();
        console.log(err);
      }
    );
  }

  private initSearch(): void {
    this.searchForm = this.fb.group({
      query: this.fb.control(''),
    });
    this.searchForm.valueChanges.subscribe((data) => {
      this.onChangeSearch(data);
    });
  }

  private shareAll(files: UserFile[]): void {
    const obs: Observable<any>[] = files.map((file: UserFile) => {
      return this.fileManagerService.share(file.id, 'all').pipe(tap(() => (file.is_shared = true)));
    });
    forkJoin(obs).subscribe(
      () => {
        this.translate.get('fm_msg_share_success').subscribe((msg) => this.notify.success('успешно', msg));
        this.resetBuffer();
        this.resetSelected();
        this.modal.share.close();
      },
      (err) => {
        this.resetBuffer();
        this.resetSelected();
        console.log(err);
      }
    );
  }

  private unshareAll(files: UserFile[]): void {
    const obs: Observable<any>[] = files.map((file: UserFile) => {
      return this.fileManagerService.unshare(file.id, 'all').pipe(tap(() => (file.is_shared = true)));
    });
    forkJoin(obs).subscribe(
      () => {
        this.translate.get('fm_msg_share_success').subscribe((msg) => this.notify.success('успешно', msg));
        this.resetBuffer();
        this.resetSelected();
        this.modal.share.close();
      },
      (err) => {
        this.resetBuffer();
        this.resetSelected();
        console.log(err);
      }
    );
  }

  private hasSearchShare(): boolean {
    return this.isSearchShare === true;
  }

  private resetBuffer(): void {
    this.BUFFER_TYPE = null;
    this.buffer = [];
  }

  private resetSelected(): void {
    this.selected = [];
  }

  private sortCurrentDir() {
    this.CURRENT_DIR.children = this.CURRENT_DIR.children.sort(
      this.fileManagerService.sort.bind(this.fileManagerService)
    );
  }

  private resetHistory(): void {
    this.history = [];
  }

  private resetRoot(file: UserFile): void {
    this.isRootOpened = file.hasRoot() && !file.hasShare();
    this.isRootShareOpened = file.hasRoot() && file.hasShare();
  }

  private findFile(id: number, files?: UserFile[]): UserFile {
    let res = null;
    if (id > 0) {
      files = files || (this.CURRENT_DIR.hasShare() ? this.getRootSharedDir().children : this.getRootDir().children);
      files.forEach((file: UserFile) => {
        if (file.id === id) {
          res = file;
        } else {
          if (file.children.length) {
            res = this.findFile(id, file.children);
          }
        }
      });
      return res;
    } else {
      return id === -1 ? this.getRootSharedDir() : this.getRootDir();
    }
  }

  private openTree(file: UserFile) {
    if (file.parent) {
      const parent_file: UserFile = this.findFile(file.parent);
      parent_file.opened = true;
      if (parent_file) {
        this.openTree(parent_file);
      }
    }
  }
}
