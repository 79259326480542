export interface Initials {
  first_name: string;
  second_name: string;
  patronymic?: string;
}

export class BaseUser implements Initials {
  id: number;
  email: string;
  first_name: string;
  second_name: string;
  patronymic?: string;
  phones?: Array<string>;

  constructor(data) {
    for (const prop in data) {
      if (data.hasOwnProperty(prop)) {
        if (prop in this) {
          this[prop] = new this[prop](data[prop]);
          continue;
        }
        this[prop] = data[prop];
      }
    }
    this.phones = !this.phones ? [] : this.phones;
  }

  get fullName() {
    if (!this.second_name) return 'unknown';
    return `${this.second_name} ${this.first_name} ${this.patronymic ? this.patronymic : ''}`;
  }

  get shortName() {
    if (!this.second_name) return 'unknown';
    return `${this.second_name} ${this.first_name ? this.first_name[0] : ''}.${
      this.patronymic ? this.patronymic[0] + '.' : ''
    }`;
  }
}
