<ul class="list-unstyled mb-3">
  <li *ngFor="let item of list">
    <a>
      <input [id]="item.id" class="cbx-input" [(ngModel)]="item.checked" type="checkbox" />
      <label class="cbx-label" [for]="item.id">
        <ng-container *ngIf="item.icon">
          <fa-icon [icon]="item.icon" [ngClass]="item.classes"></fa-icon>
        </ng-container>
        <span class="cbx-label-text">{{ item.name }}</span>
      </label>
    </a>
  </li>
</ul>
<div class="d-flex justify-content-center">
  <button class="btn btn-dc-primary" (click)="apply()">Фильтровать</button>
</div>
