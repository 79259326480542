<div class="dc-modal-x-button dc-fa-button" (click)="close()">
  <app-svg-icon [height]="20" [width]="20" name="close"></app-svg-icon>
</div>

<div class="modal-window-content">
  <div class="modal-header">
    <div class="primary-title text-center">
      Онлайн сервис Прямой Контакт приветствует Вас!
    </div>
  </div>

  <div class="modal-body">
    <div class="secondary-title text-center">
      Для полноценной работы с сервисом Вам необходимо завершить процедуру регистрации.
    </div>

    <div class="welcome-message">
      Для этого заполните раздел “Личные данные”, примите условия договора и правила сервиса. После этого все вкладки
      левого и верхнего меню станут активными.
    </div>

    <div class="welcome-message">
      На страницах сервиса в верхнем меню Вы можете воспользоваться видео-инструкциями
      <app-svg-icon [width]="20" [height]="15" name="film"></app-svg-icon>, а также дополнительными разъяснениями,
      которые вызываются кликом по иконкам со знаками вопроса
      <app-svg-icon [width]="20" [height]="20" name="note"></app-svg-icon>. Если у Вас ещё останутся вопросы, Вы сможете
      их задать оператору технической поддержки, нажав на иконку
      <app-svg-icon [width]="20" [height]="20" name="life_ring"></app-svg-icon> в верхнем меню.
    </div>
  </div>

  <div class="modal-footer">
    <div class="footer-title text-center">
      Желаем приятной работы в сервисе Прямой контакт!
    </div>
    <div class="contact-form-link">
      <a (click)="navigateToContactForm()">Перейти к заполнению данных</a>
    </div>
  </div>
</div>
