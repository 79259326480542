import { Component, Inject, OnDestroy } from '@angular/core';
import { CatalogTab } from '@app/+competence-map/models/competence-map.models';
import { MY_COMPETENCE_TABS } from '@app/+competence-map/constants/competence-map.constants';
import { CMCatalogTypes } from '@app/+competence-map/constants/sections.constants';

import { CompetenceService } from '@app/+competence-map/services/competence.service';
import {
  CompetenceDataService,
  GOODS_COMPETENCE_STATE,
  SERVICES_COMPETENCE_STATE,
} from '@app/+competence-map/services/competence-data.service';
import { NotificationsService } from 'angular2-notifications';
import { CmHelper } from '@app/+competence-map/helpers/cm.helper';
import { ProviderCompetency } from '../models/user-competency.model';
import { forkJoin } from 'rxjs';
import { DestroyService } from '@app/services/destroy.service';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '@app/shared/services/auth.service';

@Component({
  selector: 'app-my-competences',
  templateUrl: './my-competences.component.html',
  styleUrls: ['./my-competences.component.scss'],
  providers: [DestroyService],
})
export class MyCompetencesComponent implements OnDestroy {
  tabs: CatalogTab[] = MY_COMPETENCE_TABS;
  currentTab: CatalogTab;
  currentTabValue: string;
  isHelperOpen: boolean;

  catalogTypes = CMCatalogTypes;
  competencies: ProviderCompetency[] = [];

  constructor(
    @Inject(SERVICES_COMPETENCE_STATE) private servicesStateService: CompetenceDataService,
    @Inject(GOODS_COMPETENCE_STATE) private goodsStateService: CompetenceDataService,
    private authService: AuthService,
    private competenceService: CompetenceService,
    private notify: NotificationsService,
    private destroy$: DestroyService
  ) {
    this.currentTab = this.tabs[0];
    this.currentTabValue = this.currentTab.value;

    this.loadCompetencies();
  }

  get selectedData() {
    return [
      ...this.servicesStateService.competencySelectionSections,
      ...this.servicesStateService.competencySelectionFilters,
      ...this.goodsStateService.competencySelectionSections,
      ...this.goodsStateService.competencySelectionFilters,
    ];
  }

  loadCompetencies() {
    this.competenceService
      .getProviderCompetency([
        {
          name: 'author_id',
          op: 'eq',
          val: this.authService.user_id,
        },
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((competencies) => {
        this.competencies = competencies;
      });
  }

  onSelectTab(tab: CatalogTab) {
    this.currentTabValue = tab.value;
  }

  closeHelper() {
    this.isHelperOpen = false;
  }

  hasChanges(): boolean {
    return (
      !!CmHelper.getNewCompetencies(this.selectedData, this.competencies).length ||
      !!CmHelper.getExistsCompetencies(this.selectedData, this.competencies).length ||
      !!CmHelper.getDeletedCompetencies(this.selectedData, this.competencies).length
    );
  }

  save() {
    const newCompetencies = CmHelper.getNewCompetencies(this.selectedData, this.competencies);
    const existsCompetencies = CmHelper.getExistsCompetencies(this.selectedData, this.competencies);
    const competenciesForDelete = CmHelper.getDeletedCompetencies(this.selectedData, this.competencies);

    console.log(this.selectedData);

    console.log('newCompetencies', newCompetencies);
    console.log('existsCompetencies', existsCompetencies);
    console.log('competenciesForDelete', competenciesForDelete);

    const queries = [];

    if (newCompetencies.length) {
      queries.push(
        this.competenceService.addProviderCompetency(CmHelper.getParamsForCreateCompetencies(newCompetencies))
      );
    }

    if (existsCompetencies.length) {
      queries.push(
        this.competenceService.changeProviderCompetency(
          CmHelper.getParamsForUpdateCompetencies(existsCompetencies, this.competencies)
        )
      );
    }

    if (competenciesForDelete.length) {
      queries.push(
        this.competenceService.changeProviderCompetency(
          CmHelper.getParamsForDeleteCompetencies(competenciesForDelete, this.competencies)
        )
      );
    }

    forkJoin(queries)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.loadCompetencies();
          this.notify.success('Успешно', 'Компетенции обновлены');
        },
        (err) => {
          this.competenceService.error(err);
        }
      );
  }

  cancel() {
    this.goodsStateService.resetSubject.next();
    this.servicesStateService.resetSubject.next();
  }

  ngOnDestroy() {
    this.goodsStateService.competencySelectionSections = [];
    this.goodsStateService.competencySelectionFilters = [];
    this.servicesStateService.competencySelectionSections = [];
    this.servicesStateService.competencySelectionFilters = [];
  }
}
