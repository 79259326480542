import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-status-circle',
  templateUrl: './status-circle.component.html',
  styleUrls: ['./status-circle.component.scss'],
})
export class StatusCircleComponent {
  @Input() status;

  constructor() {}
}
