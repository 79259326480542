<div *ngIf="!readonly && competencies">
  <div class="header mb-4">
    <div class="dc-tabs mb-0">
      <div
        class="dc-tab dc-tab--secondary"
        *ngFor="let tab of tabs"
        (click)="onSelectTab(tab)"
        [class.active]="currentTabValue === tab.value"
      >
        <span class="padding-r-6">{{ tab.label }}</span>
        <div *ngIf="tab.value !== 'additional-filters'" class="selected-counter">
          {{ tab.value === 'product_catalog' ? selectedGoodsCount : selectedServicesCount }}
        </div>
        <app-faq-tooltip
          tooltipName="supplier-tab-{{ tab.value }}"
          class="faq-tooltip-brand faq-tooltip-md"
        ></app-faq-tooltip>
      </div>
    </div>
    <button class="icon-button" [chatLink]="card?.id">
      <app-svg-icon name="comment" width="16" heigh="16"></app-svg-icon>
    </button>
    <div
      class="competence-map-providers-counter"
      container="body"
      placement="left"
      ngbTooltip="Количество потенциальных поставщиков"
    >
      <span>{{ potentialSuppliers?.length }}</span>
      <app-svg-icon [hidden]="!loadingSuppliers" class="fa-spin" name="loading" width="16" height="16"></app-svg-icon>
    </div>
  </div>

  <app-my-catalog
    [catalog]="catalogTypes.GOODS"
    [readonly]="readonly"
    [competencies]="competencies"
    [hidden]="currentTabValue !== 'product_catalog'"
    (searchEvent)="onSearch()"
    (onShowedMyCompetencies)="showedMyCompetencies($event)"
  >
  </app-my-catalog>

  <app-my-catalog
    [catalog]="catalogTypes.SERVICES"
    [readonly]="readonly"
    [competencies]="competencies"
    [hidden]="currentTabValue !== 'job_catalog'"
    (searchEvent)="onSearch()"
    (onShowedMyCompetencies)="showedMyCompetencies($event)"
  >
  </app-my-catalog>

  <div [hidden]="currentTabValue !== 'additional-filters'">
    <app-additional-filters
      [filters]="card.providers_filter?.additional_filters"
      (changed)="additionalFiltersChanged($event)"
    >
    </app-additional-filters>
  </div>
</div>

<div *ngIf="readonly && competencies" class="readonly-container">
  <app-competence-map-view [competencies]="competencies"></app-competence-map-view>

  <div class="additional-filters">
    <h6>Дополнительные фильтры</h6>

    <ul class="additional-filters-list">
      <li>
        <b>Дата государственной регистрации компании поставщика:</b>
        <div
          *ngIf="
            additionalFilters?.from_date_state_registration || additionalFilters?.to_date_state_registration;
            else empty
          "
        >
          <span *ngIf="additionalFilters?.from_date_state_registration as value">C {{ value | dateFormat }} </span>
          <span *ngIf="additionalFilters?.to_date_state_registration as value">По {{ value | dateFormat }}</span>
        </div>
      </li>
      <li>
        <b>Дата регистрации поставщика в сервисе ПК:</b>
        <div
          *ngIf="
            additionalFilters?.from_date_registration_payer || additionalFilters?.to_date_registration_payer;
            else empty
          "
        >
          <span *ngIf="additionalFilters?.from_date_registration_payer as value">C {{ value | dateFormat }} </span>
          <span *ngIf="additionalFilters?.to_date_registration_payer as value">По {{ value | dateFormat }}</span>
        </div>
      </li>
    </ul>
  </div>
</div>

<ng-template #empty><div>-</div></ng-template>
