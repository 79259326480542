import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterPipe<T> implements PipeTransform {
  transform(items: T[], value: string, field: string): T[] {
    if (!value) {
      return items;
    }
    const lowerCaseValue = value.toString().toLowerCase();
    return [...items].filter((item) => {
      return !!item[field] && item[field].toString().toLowerCase().includes(lowerCaseValue);
    });
  }
}
