import { takeUntil } from 'rxjs/operators';
import { Component, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';

import { ChatService } from '@app/chat/services/chat.service';
import { AuthService } from '@app/shared/services/auth.service';
import { ChatRoom, ChatSection } from '@app/chat/models/chat.model';
import { ChatSectionsEnum } from '@app/chat/constants/chat-sections.constants';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  isEditingGroupOrTheme: boolean;
  isEditingContactsOnlyFilter: boolean;
  isContactsHidden: boolean = false;
  contactSelected: ChatRoom = {} as ChatRoom;
  chatSectionSelected: ChatSection = {} as ChatSection;
  chatSectionsEnum = ChatSectionsEnum;
  groupSelected: ChatRoom;
  isTradeGroup = this.chatService.isTradeGroup;

  chatLoading$ = this.chatService.chatLoading.asObservable();

  constructor(private chatService: ChatService, private authService: AuthService) {
    this.chatService.isEditingGroupOrThemeChanged
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((isEditingGroupOrTheme) => (this.isEditingGroupOrTheme = isEditingGroupOrTheme));

    this.chatService.isEditingContactsOnlyFilterChanged
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((isEditingContactsOnlyFilter) => (this.isEditingContactsOnlyFilter = isEditingContactsOnlyFilter));

    this.chatService.chatSectionSelectedChanged
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((chatSectionSelected) => (this.chatSectionSelected = chatSectionSelected));

    this.chatService.contactSelectedChanged
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((contactSelected) => (this.contactSelected = contactSelected));

    this.chatService.groupSelectedChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((groupSelected) => {
      this.groupSelected = groupSelected;
    });

    this.authService.userStream.pipe().subscribe(() => {
      this.isEditingGroupOrTheme = this.chatService.isEditingGroupOrTheme;
      this.isEditingContactsOnlyFilter = this.chatService.isEditingContactsOnlyFilter;

      this.contactSelected = this.chatService.getContactSelected();
      this.chatSectionSelected = this.chatService.getChatSectionSelected();
    });
  }

  onChatClose() {
    this.chatService.toggleChatOpen();
  }

  toggleContactsHidden() {
    this.isContactsHidden = !this.isContactsHidden;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
