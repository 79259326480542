import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import * as moment from 'moment';

@Injectable()
export class CalendarService {
  calendarChanged = new Subject<any[]>();
  currentMonthChanged = new Subject<any[]>();
  todayEventsChanged = new Subject<number>();
  moment = moment;
  month: number = +moment().format('M');
  year: number = +moment().format('YYYY');
  events: any[];
  todayEventsCount: number;

  setEvents(events, widget) {
    this.events = events;
    if (widget) {
      this.currentMonthChanged.next(this.events);
    }
    // this.updateWidget(null);
  }

  setTodayEventsCount(todayEventsCount: number) {
    this.todayEventsCount = todayEventsCount;
    this.todayEventsChanged.next(this.todayEventsCount);
  }

  getEvents() {
    return this.events.slice();
  }

  setEvent(event) {
    this.events.push(event);
    this.calendarChanged.next(this.events.slice());
    this.updateWidget(null);
  }

  updateEvent(updatedEvent) {
    this.events = this.events.map((event) => {
      if (event.id === updatedEvent.id) {
        event = updatedEvent;
      }
      return event;
    });
    this.calendarChanged.next(this.events.slice());
    this.updateWidget(null);
  }

  deleteEvent(event_id: number) {
    const term = Object.assign({}, this.events[0]);
    term.datetime = term.datetime || '';
    this.events = this.events.filter((event) => event.id !== event_id);
    this.calendarChanged.next(this.events.slice());
    this.updateWidget(term.datetime);
  }

  updateWidget(datetime) {
    const currentMonthAndYear: string = moment().format('MMMM YYYY');
    const term = Object.assign({}, this.events[0]);
    term.datetime = term.datetime || '';
    if (
      moment(term.datetime).format('MMMM YYYY') === currentMonthAndYear ||
      moment(datetime).format('MMMM YYYY') === currentMonthAndYear
    ) {
      this.currentMonthChanged.next(this.events.slice());
    }
  }
}
