import { takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { ROLES, RolesEnum } from '@app/shared/constants/roles.constants';
import { AuthService } from '@app/shared/services/auth.service';
import { ChatService } from '../services/chat.service';
import { SocketDataService } from '../../services/socket-data.service';
import { ChatRoom, ChatSection } from '@app/chat/models/chat.model';
import { ChatSectionsEnum } from '@app/chat/constants/chat-sections.constants';

@Component({
  selector: 'app-chat-contacts',
  templateUrl: './chat-contacts.component.html',
  styleUrls: ['./chat-contacts.component.scss'],
})
export class ChatContactsComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  roles = ROLES;
  rolesEnum = RolesEnum;

  contacts: ChatRoom[] = [];
  contactSelected: ChatRoom;
  chatSectionSelected: ChatSection;
  filterString: string;
  isContactsExpanded: boolean = true;
  chatSectionsEnum = ChatSectionsEnum;
  contactsOnlyFilter: { roles: string[]; competencies: number[] };
  groupSelected: ChatRoom;

  constructor(
    private chatService: ChatService,
    private chatDataService: SocketDataService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.chatSectionSelected = this.chatService.getChatSectionSelected();
    this.contactsOnlyFilter = this.chatService.getContactsOnlyFilter();

    this.chatService.contactsFilterChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((filterString) => {
      this.filterString = filterString;
      this.removeSelf(this.chatService.getContacts());
    });

    this.removeSelf(this.chatService.getContacts());

    this.chatService.contactsChanged
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((contacts) => this.removeSelf(contacts));

    this.contactSelected = this.chatService.getContactSelected();
    this.chatService.contactSelectedChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((contactSelected) => {
      this.contactSelected = contactSelected;
    });

    this.chatService.groupSelectedChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((groupSelected) => {
      this.groupSelected = groupSelected;

      this.removeSelf(this.chatService.getContacts());
    });

    this.chatService.themeSelectedChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((themeSelected) => {
      this.groupSelected = themeSelected;

      this.removeSelf(this.chatService.getContacts());
    });

    this.chatService.chatSectionSelectedChanged
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((chatSection) => (this.chatSectionSelected = chatSection));

    this.filterString = this.chatService.contactsFilter;
    this.chatService.contactsOnlyFilterChanged
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((contactsOnlyFilter) => (this.contactsOnlyFilter = contactsOnlyFilter));
  }

  removeSelf(contacts) {
    delete contacts[+this.authService.user_id];
    this.filterContacts(contacts);
  }

  filterContacts(contacts) {
    this.contacts = [];
    Object.keys(contacts).forEach((room_id) => {
      this.contacts.push(contacts[room_id]);
    });

    if (this.chatSectionSelected.name === ChatSectionsEnum.TRADE) {
      this.filterForTrade();
    }
  }

  filterForTrade() {
    if (!this.groupSelected || (this.groupSelected && !this.groupSelected.room_id)) {
      this.contacts = [];
      return;
    }

    if (this.groupSelected.section.name !== ChatSectionsEnum.TRADE) {
      this.contacts = [];
      return;
    }

    this.contacts = this.contacts.filter((contact) => this.groupSelected.users.includes(+contact.id));

    if (this.groupSelected.is_provider_in_trade && this.groupSelected.group_id) {
      this.contacts.push({
        second_name: this.groupSelected.providers.includes(+this.authService.user_id) ? 'Заказчики' : 'Поставщики',
        section: this.groupSelected.section,
        room_id: null,
      } as ChatRoom);
    }
  }

  onContactSelect(contact: ChatRoom) {
    this.chatService.setRoomSelected(contact);
    this.chatService.focusChanged.next(true);

    if (contact.counter) {
      this.chatDataService.setMessageRead(contact.room_id);
    }
  }

  onContactsExpand() {
    this.isContactsExpanded = !this.isContactsExpanded;
  }

  onFilterToggle() {
    this.chatService.toggleContactsOnlyFilter(true);
  }

  onClearFilter() {
    this.chatService.toggleContactsOnlyFilter(false);
    this.chatService.contactsOnlyFilterChanged.next({
      roles: [],
      competencies: [],
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
