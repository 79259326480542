<div class="container">
  <div class="row">
    <div class="col-md-4"></div>
    <div class="col-md-4">
      <section class="auth-form">
        <form role="auth" novalidate>
          <h3>Введите новый пароль</h3>

          <div class="form-group" [ngClass]="{ 'has-danger': errors.password || errors.password_double }">
            <div class="inline middle group-pass">
              <input
                type="{{ !isShowPass ? 'password' : 'text' }}"
                name="password"
                [(ngModel)]="password"
                class="form-control"
                placeholder="Введите пароль"
                appInputTrim
              />
              <button class="btn btn btn-dc-primary show-pass" type="button" (click)="isShowPass = !isShowPass">
                <fa-icon [icon]="['fas', isShowPass ? 'eye' : 'eye-slash']"></fa-icon>
              </button>
            </div>
            <div class="form-control-feedback" *ngFor="let err of errors.password">{{ err | translate }}</div>
          </div>

          <div class="form-group" [ngClass]="{ 'has-danger': errors.password_double }">
            <div class="inline middle group-pass">
              <input
                type="{{ !isShowPass ? 'password' : 'text' }}"
                name="password"
                [(ngModel)]="password_double"
                class="form-control"
                placeholder="Подтвердите пароль"
                appInputTrim
              />
              <button class="btn btn btn-dc-primary show-pass" type="button" (click)="isShowPass = !isShowPass">
                <fa-icon [icon]="['fas', isShowPass ? 'eye' : 'eye-slash']"></fa-icon>
              </button>
            </div>
            <div class="form-control-feedback" *ngFor="let err of errors.password_double">{{ err | translate }}</div>
          </div>

          <button type="submit" (click)="confirm()" class="btn btn-lg btn-dc-primary btn-block">Подтвердить</button>
          <button type="button" [routerLink]="['/signin']" class="btn btn-lg btn-dc-primary btn-block">Вход</button>
        </form>
      </section>
    </div>
    <div class="col-md-4"></div>
  </div>
</div>
