import { Component, EventEmitter, Input, OnDestroy, Output, TemplateRef } from '@angular/core';
import { MultipleSearchSuggestion, MultipleSelectConfig } from '@app/shared/models/multiple-search.model';
import { FilterPipe } from '@app/shared/pipes/filter.pipe';
import { FormControl } from '@angular/forms';
import { startWith, takeUntil } from 'rxjs/operators';
import { DestroyService } from '@app/services/destroy.service';

@Component({
  selector: 'app-multiple-livesearch-select',
  templateUrl: './multiple-livesearch-select.component.html',
  styleUrls: ['./multiple-livesearch-select.component.scss'],
  providers: [FilterPipe, DestroyService],
})
export class MultipleLivesearchSelectComponent implements OnDestroy {
  @Input() template: TemplateRef<any>;
  @Input() title: string = 'Все';
  @Input() placeholder: string = '';
  @Input() isNumber: boolean;
  @Input() disabled: boolean;

  @Output() searchEvent = new EventEmitter<string>();
  @Output() selectEvent = new EventEmitter<MultipleSearchSuggestion[]>();

  searchValue = '';
  allChecked: boolean;
  isPartialSelected: boolean;
  config: MultipleSelectConfig;
  searchControl = new FormControl('', []);

  private _list: MultipleSearchSuggestion[] = [];

  @Input() set list(list: MultipleSearchSuggestion[]) {
    if (!list.length) {
      return;
    }

    this.searchControl.valueChanges.pipe(startWith(''), takeUntil(this.destroy$)).subscribe((searchValue) => {
      if (!searchValue) {
        this._list = list;
      } else {
        this._list = this.filterList(list, searchValue.toLowerCase());
        this.searchEvent.emit(searchValue);
      }
    });

    this._list = list;
    this.config = list[0].config;
    this.isPartialSelected = this.isPartialSelectedCheck(list);
    this.allChecked = this.list.length && this.list.every((item) => item.value);
  }

  constructor(private filterPipe: FilterPipe<MultipleSearchSuggestion>, private destroy$: DestroyService) {}

  get list(): MultipleSearchSuggestion[] {
    return this._list;
  }

  get isToggleAllDisable() {
    return !this.filterPipe.transform(this._list, this.searchValue, this.searchedFieldName).length;
  }

  get partiallySelected(): boolean {
    return this.isPartialSelected || this.isPartialSelectedCheck(this.list);
  }

  get searchedFieldName() {
    return this.config?.searchedFieldName ? this.config.searchedFieldName : 'label';
  }

  selectSuggestion(item: MultipleSearchSuggestion) {
    item.value = !item.value;
    this.allChecked = this.list.every((listItem) => listItem.value);
    this.isPartialSelected = this.isPartialSelectedCheck(this.list);
    this.selectEvent.emit(this.list);
  }

  changeAll() {
    this.list
      .filter((item) => !item.disabled)
      .forEach((item) => {
        item.value = this.allChecked;
      });
    this.selectEvent.emit(this.list);
  }

  private isPartialSelectedCheck(list: MultipleSearchSuggestion[]): boolean {
    return list.some((item) => item.value) && !list.every((item) => item.value);
  }

  private filterList(list: MultipleSearchSuggestion[], searchValue: string): MultipleSearchSuggestion[] {
    return list
      .map((item) => {
        if ((item.data[1] as string)?.toLowerCase().includes(searchValue)) {
          return item;
        } else return null;
      })
      .filter(Boolean);
  }

  ngOnDestroy() {
    this.searchEvent.next('');
  }
}
