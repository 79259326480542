<form (ngSubmit)="onSubmit()">
  <div class="group-edit">
    <div class="container-fluid pl-4 pr-4">
      <h4 class="text-center">Задать фильтры</h4>

      <div class="row">
        <div class="col-6">
          <h6>Роли</h6>
          <div class="mb-2" *ngFor="let role of roles">
            <input
              [id]="role.value"
              class="cbx-input"
              [(ngModel)]="role.selected"
              (ngModelChange)="changeRoles(role)"
              [ngModelOptions]="{ standalone: true }"
              type="checkbox"
            />
            <label class="cbx-label" [for]="role.value">
              <span class="cbx-label-text">{{ role.title }}</span>
            </label>
          </div>
        </div>
        <div class="col-6" *ngIf="chatSection.name !== chatSectionsEnum.DUTY_TSO">
          <h6 class="pl-4">Направления</h6>
          <app-competencies-filter
            *ngIf="goods?.length"
            [goods]="goods"
            [hasSubmit]="false"
            [services]="services"
            [selected]="competenciesSelected"
            (changeEvent)="changeCompetencies($event)"
          ></app-competencies-filter>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <div class="mr-2 text-right">
          <button (click)="onCancelEdit()" class="btn btn-dc-primary" type="button">Отменить</button>
        </div>
        <div class="text-left">
          <button type="submit" class="btn btn-dc-primary">Сохранить</button>
        </div>
      </div>
    </div>
  </div>
</form>
