<div class="datepicker-container u-is-unselectable">
  <div class="datepicker-input-container" *ngIf="isLabelShowed">
    <input
      readonly
      type="text"
      class="kt-input datepicker-input"
      [placeholder]="placeholder"
      [(ngModel)]="value"
      (click)="toggle()"
      [required]="isRequired"
      [disabled]="disabled"
    />
    <div class="datepicker-input-icon" (click)="toggle()">
      <i>
        <svg width="58px" height="58px" viewBox="0 0 58 58" version="1.1">
          <g id="calendar" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Group" fill-rule="nonzero" fill="#000000">
              <path
                d="M42.899,4.5 C42.434,2.221 40.415,0.5 38,0.5 C37.447,0.5 37,0.947 37,1.5 C37,2.053 37.447,2.5 38,2.5 C39.654,2.5 41,3.846 41,5.5 C41,7.154 39.654,8.5 38,8.5 C37.447,8.5 37,8.947 37,9.5 C37,10.053 37.447,10.5 38,10.5 C40.414,10.5 42.434,8.779 42.899,6.5 L56,6.5 L56,15.5 L2,15.5 L2,6.5 L16,6.5 L19,6.5 C19.553,6.5 20,6.053 20,5.5 C20,4.947 19.553,4.5 19,4.5 L17.184,4.5 C17.598,3.338 18.698,2.5 20,2.5 C21.654,2.5 23,3.846 23,5.5 C23,7.154 21.654,8.5 20,8.5 C19.447,8.5 19,8.947 19,9.5 C19,10.053 19.447,10.5 20,10.5 C22.757,10.5 25,8.257 25,5.5 C25,2.743 22.757,0.5 20,0.5 C17.586,0.5 15.566,2.221 15.101,4.5 L0,4.5 L0,17.5 L0,57.5 L58,57.5 L58,17.5 L58,4.5 L42.899,4.5 Z M56,55.5 L2,55.5 L2,17.5 L56,17.5 L56,55.5 Z"
                id="Shape"
              ></path>
              <path
                d="M26,2.5 C27.654,2.5 29,3.846 29,5.5 C29,7.154 27.654,8.5 26,8.5 C25.447,8.5 25,8.947 25,9.5 C25,10.053 25.447,10.5 26,10.5 C28.757,10.5 31,8.257 31,5.5 C31,2.743 28.757,0.5 26,0.5 C25.447,0.5 25,0.947 25,1.5 C25,2.053 25.447,2.5 26,2.5 Z"
                id="Shape"
              ></path>
              <path
                d="M32,2.5 C33.654,2.5 35,3.846 35,5.5 C35,7.154 33.654,8.5 32,8.5 C31.447,8.5 31,8.947 31,9.5 C31,10.053 31.447,10.5 32,10.5 C34.757,10.5 37,8.257 37,5.5 C37,2.743 34.757,0.5 32,0.5 C31.447,0.5 31,0.947 31,1.5 C31,2.053 31.447,2.5 32,2.5 Z"
                id="Shape"
              ></path>
              <circle id="Oval" cx="22" cy="24.5" r="1"></circle>
              <circle id="Oval" cx="29" cy="24.5" r="1"></circle>
              <circle id="Oval" cx="36" cy="24.5" r="1"></circle>
              <circle id="Oval" cx="43" cy="24.5" r="1"></circle>
              <circle id="Oval" cx="50" cy="24.5" r="1"></circle>
              <circle id="Oval" cx="8" cy="32.5" r="1"></circle>
              <circle id="Oval" cx="15" cy="32.5" r="1"></circle>
              <circle id="Oval" cx="22" cy="32.5" r="1"></circle>
              <circle id="Oval" cx="29" cy="32.5" r="1"></circle>
              <circle id="Oval" cx="36" cy="32.5" r="1"></circle>
              <circle id="Oval" cx="43" cy="32.5" r="1"></circle>
              <circle id="Oval" cx="50" cy="32.5" r="1"></circle>
              <circle id="Oval" cx="8" cy="39.5" r="1"></circle>
              <circle id="Oval" cx="15" cy="39.5" r="1"></circle>
              <circle id="Oval" cx="22" cy="39.5" r="1"></circle>
              <circle id="Oval" cx="29" cy="39.5" r="1"></circle>
              <circle id="Oval" cx="36" cy="39.5" r="1"></circle>
              <circle id="Oval" cx="43" cy="39.5" r="1"></circle>
              <circle id="Oval" cx="50" cy="39.5" r="1"></circle>
              <circle id="Oval" cx="8" cy="47.5" r="1"></circle>
              <circle id="Oval" cx="15" cy="47.5" r="1"></circle>
              <circle id="Oval" cx="22" cy="47.5" r="1"></circle>
              <circle id="Oval" cx="29" cy="47.5" r="1"></circle>
              <circle id="Oval" cx="36" cy="47.5" r="1"></circle>
            </g>
          </g>
        </svg>
      </i>
    </div>
  </div>
  <div class="datepicker-calendar" *ngIf="opened">
    <div class="datepicker-calendar-top">
      <span class="year-title" (click)="openYearPicker()">{{ currentDate.format('YYYY') || 'Выберите месяц' }}</span>
      <button type="button" (click)="openYearPicker()">
        {{ options.selectYearText }}
      </button>
      <i class="close" (click)="close()">
        <svg width="350px" height="349px" viewBox="-1 0 350 349" version="1.1">
          <g id="delete" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path
              d="M336.559,68.611 L231.016,174.165 L336.559,279.714 C352.258,295.419 352.258,320.859 336.559,336.564 C328.715,344.408 318.431,348.333 308.152,348.333 C297.856,348.333 287.571,344.414 279.733,336.564 L174.167,231.003 L68.609,336.563 C60.766,344.407 50.481,348.332 40.193,348.332 C29.908,348.332 19.63,344.413 11.78,336.563 C-3.919,320.865 -3.919,295.424 11.78,279.713 L117.32,174.164 L11.774,68.611 C-3.925,52.912 -3.925,27.466 11.774,11.767 C27.47,-3.92 52.901,-3.92 68.603,11.767 L174.166,117.321 L279.721,11.767 C295.426,-3.92 320.86,-3.92 336.553,11.767 C352.258,27.466 352.258,52.912 336.559,68.611 Z"
              id="Shape"
              fill="#000000"
              fill-rule="nonzero"
            ></path>
          </g>
        </svg>
      </i>
    </div>
    <div class="datepicker-calendar-container">
      <div *ngIf="!yearPicker">
        <div class="datepicker-calendar-month-section" *ngIf="!options.onlyMonth">
          <i (click)="prevMonth()">
            <svg width="190px" height="306px" viewBox="58 0 190 306" version="1.1">
              <g
                id="keyboard-left-arrow-button"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
                transform="translate(58.000000, 0.000000)"
              >
                <g id="chevron-left" fill-rule="nonzero" fill="#000000">
                  <polygon
                    id="Shape"
                    points="189.35 35.7 153.65 0 0.65 153 153.65 306 189.35 270.3 72.05 153"
                  ></polygon>
                </g>
              </g>
            </svg>
          </i>
          <span class="month-title">{{ currentDate.format('MMMM') }}</span>
          <i (click)="nextMonth()">
            <svg width="190px" height="306px" viewBox="58 0 190 306" version="1.1">
              <g
                id="keyboard-right-arrow-button"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
                transform="translate(58.000000, 0.000000)"
              >
                <g id="chevron-right" fill-rule="nonzero" fill="#000000">
                  <polygon id="Shape" points="36.35 0 0.65 35.7 117.95 153 0.65 270.3 36.35 306 189.35 153"></polygon>
                </g>
              </g>
            </svg>
          </i>
        </div>
        <div class="datepicker-calendar-day-names" *ngIf="!options.onlyMonth">
          <span>S</span>
          <span>M</span>
          <span>T</span>
          <span>W</span>
          <span>T</span>
          <span>F</span>
          <span>S</span>
        </div>
        <div class="datepicker-calendar-days-container" *ngIf="!options.onlyMonth">
          <span
            class="day"
            *ngFor="let d of days; let i = index"
            (click)="selectDate($event, d.momentObj)"
            [ngClass]="{ disabled: !d.enabled, today: d.today, selected: d.selected }"
          >
            {{ d.day }}
          </span>
        </div>
        <div class="datepicker-calendar-days-container" *ngIf="options.onlyMonth">
          <span
            class="month"
            *ngFor="let m of months; let i = index"
            (click)="selectMonth($event, m, currentDate.format('YYYY'))"
          >
            {{ m }}
          </span>
        </div>
        <div class="datepicker-buttons" *ngIf="!options.autoApply">
          <button type="button" class="btn btn-dc-outline u-is-small" (click)="clear()">
            {{ options.clearText }}
          </button>
          <button type="button" class="btn btn-dc-primary u-is-small" (click)="today()">
            {{ options.todayText }}
          </button>
        </div>
      </div>
      <div *ngIf="yearPicker">
        <div class="datepicker-calendar-years-container">
          <span class="year" *ngFor="let y of years; let i = index" (click)="selectYear($event, y)">
            {{ y }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
