import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { PDFProgressData } from 'ng2-pdf-viewer';
import print from 'print-js';
import { saveAs } from 'file-saver';

import { UserFile } from '@app/file-manager/models/user-file.model';
import { NotificationsService } from 'angular2-notifications';
import { viewerType } from 'ngx-doc-viewer/document-viewer.component';

@Component({
  selector: 'app-file-manager-modal-file-preview',
  templateUrl: './file-manager-modal-file-preview.component.html',
  styleUrls: ['./file-manager-modal-file-preview.component.scss'],
})
export class FileManagerModalFilePreviewComponent {
  @Input() file: UserFile;
  loadingProgress = 0;
  isLoading = true;

  get viewer(): viewerType {
    if (
      this.file?.fileExtType()?.hasOdt ||
      this.file?.fileExtType()?.hasDoc ||
      this.file?.fileExtType()?.hasTable ||
      this.file?.fileExtType()?.hasPpt ||
      this.file?.fileExtType()?.hasOdt ||
      this.file?.fileExtType()?.hasOds ||
      this.file?.fileExtType()?.hasOdp
    ) {
      return 'office';
    }
    return 'google';
  }

  get isPdfFile(): boolean {
    return this.file?.fileExtType()?.hasPdf;
  }

  get isImageFile(): boolean {
    return this.file?.fileExtType()?.hasImage;
  }

  get isDocFile(): boolean {
    return (
      this.file?.fileExtType()?.hasDoc ||
      this.file?.fileExtType()?.hasRtf ||
      this.file?.fileExtType()?.hasPdf ||
      this.file?.fileExtType()?.hasTxt ||
      this.file?.fileExtType()?.hasTable ||
      this.file?.fileExtType()?.hasCsv ||
      this.file?.fileExtType()?.hasPpt ||
      this.file?.fileExtType()?.hasOdt ||
      this.file?.fileExtType()?.hasOds ||
      this.file?.fileExtType()?.hasOdp
    );
  }

  get isShowIconFile(): boolean {
    return !this.isDocFile && !this.isImageFile && !this.isPdfFile;
  }

  get fileUrl(): string {
    return decodeURI(this.file?.url);
  }

  constructor(private readonly activeModal: NgbActiveModal, private readonly notify: NotificationsService) {}

  cancel(): void {
    this.activeModal.close();
  }

  loadingPreview(event): void {
    console.log(event);
  }

  uploadProgress(event: PDFProgressData) {
    this.loadingProgress = Math.round((100 * event.loaded) / event.total);
    if (event.loaded === event.total) {
      this.isLoading = false;
    }
  }

  printFile(): void {
    print({
      printable: [this.file.url],
      type: this.isPdfFile ? 'pdf' : 'image',
    });
  }

  downloadFile(): void {
    try {
      saveAs(this.file?.url, this.file?.name);
      this.notify.success('Успешно', 'Файл успешно загружен');
    } catch (error) {
      this.notify.error('Внимание', 'Не удалось загрузить файл');
    }
  }
}
