import { TemplateRef, Type } from '@angular/core';
import { Subject } from 'rxjs';
import { OverlayRef } from '@angular/cdk/overlay';
import { takeUntil } from 'rxjs/operators';

export type PopoverContent = TemplateRef<any> | Type<any> | string;

export interface CoordinatesConfig {
  offsetX?: number;
  offsetY?: number;
  originX: string;
  originY: string;
  overlayX: string;
  overlayY: string;
}

export interface PopoverParams<T> {
  origin: HTMLElement;
  content: PopoverContent;
  data?: T;
  width?: string | number;
  height: string | number;
  config?: CoordinatesConfig;
}

export interface PopoverCloseEvent<T = any> {
  type: 'backdropClick' | 'close';
  data: T;
}

export class PopoverRef<T = any> {
  private afterClosed = new Subject<PopoverCloseEvent<T>>();
  afterClosed$ = this.afterClosed.asObservable();

  constructor(public overlay: OverlayRef, public content: PopoverContent, public data: T) {
    overlay
      .backdropClick()
      .pipe(takeUntil(this.afterClosed))
      .subscribe(() => {
        this._close('backdropClick', null);
      });
  }

  close(data?: T) {
    this._close('close', data);
  }

  private _close(type: PopoverCloseEvent['type'], data?: T) {
    this.overlay.dispose();
    this.afterClosed.next({
      type,
      data,
    });
    this.afterClosed.complete();
  }
}
