import { environment } from './../../environments/environment.local';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';

import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';
import { ResetPassComponent } from './resetpass/resetpass.component';
import { ConfirmResetPassComponent } from './confirm_resetpass/confirm_resetpass.component';
import { ActivateComponent } from './activate/activate.component';
import { InviteComponent } from './invite/invite.component';
import { ConfirmChangeEmailComponent } from './confirm-change-email/confirm-change-email.component';
import { SharedModule } from '@app/shared/shared.module';
import { InputTrimModule } from '../shared/directives/trim.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEye, faEyeSlash, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

library.add(faEye, faEyeSlash, faChevronLeft);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './i18n/', '.json');
}

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    RecaptchaModule,
    RouterModule,
    SharedModule,
    InputTrimModule,
    FontAwesomeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  declarations: [
    SigninComponent,
    SignupComponent,
    ActivateComponent,
    InviteComponent,
    ResetPassComponent,
    ConfirmResetPassComponent,
    ConfirmChangeEmailComponent,
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.google_captcha_key } as RecaptchaSettings,
    },
  ],
})
export class AuthModule {}
