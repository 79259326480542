import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { User } from '@app/shared/models/user.model';

import { AuthService } from '@app/shared/services/auth.service';
import { Observable } from 'rxjs';
import { AuthorFile } from '@app/file_manager/models/author-file.model';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss'],
})
export class UserCardComponent implements OnInit {
  @Input() title: string = 'Контакты';
  @Input() user: User | AuthorFile | number;
  user$: Observable<User>;

  @Output() closeEvent = new EventEmitter();
  @Output() reloadEvent = new EventEmitter();

  get userId() {
    return +this.authService.user_id;
  }

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.user$ = this.authService.getUserById(typeof this.user === 'number' ? this.user : this.user.id);
  }
}
