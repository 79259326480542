import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ShareUserFilterTab } from '@app/file-manager/models/share-user-filter-tab.enum';
import { ShareUserFilterInterface } from '@app/file-manager/models/share-user-filter.interface';

import { CompetenceSectionsService } from '@app/+competence-map/services/competence-sections.service';
import { Section } from '@app/+competence-map/models/sections.model';
import { DCTreeItem } from '@app/+competence-map/models/competence-map.models';
import { CMCatalogTypes, CMSectionStatuses } from '@app/+competence-map/constants/sections.constants';

import { takeUntil } from 'rxjs/operators';

import { DestroyService } from '@app/services/destroy.service';
import { TreeHelper } from '@app/shared/helpers/tree.helper';
import { ROLES, RolesEnum } from 'app/shared/constants/roles.constants';
import { Role } from 'app/shared/models/user.model';
import { deepCopy } from '@app/shared/utils';

@Component({
  selector: 'app-file-manager-share-user-filter',
  templateUrl: './file-manager-share-user-filter.component.html',
  styleUrls: ['./file-manager-share-user-filter.component.scss'],
  providers: [DestroyService],
})
export class FileManagerShareUserFilterComponent implements OnInit {
  private _filterConfig: ShareUserFilterInterface;

  @Input() activeTab: ShareUserFilterTab;

  @Output() readonly closeEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() readonly shareUserFilterEvent: EventEmitter<ShareUserFilterInterface> =
    new EventEmitter<ShareUserFilterInterface>();

  goods: DCTreeItem[];
  services: DCTreeItem[];
  roles: Role[] = deepCopy(ROLES);
  shareUserFilterTabs = [
    {
      title: 'Роли',
      value: ShareUserFilterTab.USER_ROLES,
      count: 0,
      filterData: [],
    },
    {
      title: 'Компетенции в товарах',
      value: ShareUserFilterTab.GOODS,
      count: 0,
      filterData: [],
    },
    {
      title: 'Компетенции в работах и услугах',
      value: ShareUserFilterTab.SERVICES,
      count: 0,
      filterData: [],
    },
  ];

  @Input() set filterConfig(config: ShareUserFilterInterface) {
    this._filterConfig = deepCopy(config);
  }

  get filterConfig(): ShareUserFilterInterface {
    return this._filterConfig;
  }

  get shareUserFilterTab(): typeof ShareUserFilterTab {
    return ShareUserFilterTab;
  }

  get cmCatalogTypes(): typeof CMCatalogTypes {
    return CMCatalogTypes;
  }

  constructor(
    private readonly competenceSectionsService: CompetenceSectionsService,
    private readonly destroy$: DestroyService
  ) {}

  ngOnInit(): void {
    this.activeTab = this.shareUserFilterTabs[0].value;
    Object.keys(this._filterConfig).forEach((value) => {
      const current = this.shareUserFilterTabs.find((tab) => tab.value === value);
      current.count = this._filterConfig[value].length;
      current.filterData = [...this._filterConfig[value]];
    });
    this.fetchCompetenceSections();
  }

  save(): void {
    const result: ShareUserFilterInterface = {} as ShareUserFilterInterface;
    for (let i = 0; i < this.shareUserFilterTabs.length; i++) {
      const current = this.shareUserFilterTabs[i];
      result[current.value] = current.filterData;
    }
    this.shareUserFilterEvent.emit(result);
  }

  cancel(): void {
    this.closeEvent.emit();
  }

  changeTab(tabValue: ShareUserFilterTab): void {
    if (this.activeTab !== tabValue) {
      this.activeTab = tabValue;
    }
  }

  /**
   * Получить данные для фильтров
   */
  fetchCompetenceSections(): void {
    this.competenceSectionsService
      .getSections([
        {
          name: 'catalog',
          op: 'in_',
          val: [CMCatalogTypes.GOODS, CMCatalogTypes.SERVICES],
        },
        {
          name: 'status',
          op: 'in_',
          val: [CMSectionStatuses.ACTIVE],
        },
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((sections: Section[]) => {
        const goods = sections
          .filter((item) => item.catalog === CMCatalogTypes.GOODS)
          .map((item) => {
            return {
              ...item,
              parent: {
                id: item.parent_id,
              },
            };
          });
        const services = sections
          .filter((item) => item.catalog === CMCatalogTypes.SERVICES)
          .map((item) => {
            return {
              ...item,
              parent: {
                id: item.parent_id,
              },
            };
          });
        this.goods = TreeHelper.arrayToTree(goods);
        this.services = TreeHelper.arrayToTree(services);
      });
  }

  changeFilter(tab: ShareUserFilterTab, data: RolesEnum[] | number[]): void {
    const tabInfo = this.shareUserFilterTabs.find((value) => value.value === tab);
    tabInfo.count = data.length;
    tabInfo.filterData = [...data];
  }
}
