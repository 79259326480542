<div class="section-header">
  <div class="d-flex flex-row">
    <div class="d-flex flex-column">
      <div class="section-header-row">
        <div class="section-header-label" appTestLocator="edit-filter-template__author">Автор:</div>
        <div class="section-header-value">{{ userAuthor | roleName : 'small' }}</div>
      </div>
      <div class="section-header-row" appTestLocator="edit-filter-template__date">
        <div class="section-header-label">Дата:</div>
        <div class="section-header-value">{{ projectDate | dateFormat }}</div>
      </div>
    </div>
    <div class="d-flex flex-column ml-3">
      <div class="section-header-row" appTestLocator="edit-filter-template__project">
        <div class="section-header-label">Проект ID:</div>
        <div class="section-header-value">{{ projectId }}</div>
      </div>
      <div class="section-header-row" appTestLocator="edit-filter-template__status">
        <div class="section-header-label">Статус:</div>
        <div class="section-header-value d-flex align-items-center">
          {{ projectStatus | cmProjectStatus }}&nbsp;&nbsp;
          <app-svg-icon name="change" width="16" class="color-brand-light"></app-svg-icon>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="section-header-text" appTestLocator="edit-filter-template__title">{{ title }}</div>
  </div>
  <div>
    <div
      class="section-header-archive"
      [class.disabled]="isArchiveDisabled()"
      (click)="archive()"
      appTestLocator="edit-filter-template__archive"
    >
      <app-svg-icon name="trash" width="16"></app-svg-icon>
      <span>Перенести в архив</span>
    </div>
  </div>
</div>

<div class="modal-body">
  <div class="section-header-row section-path" appTestLocator="edit-filter-template__path">
    <div class="section-header-label">Путь:</div>
    <div class="section-header-value">БШФ/{{ project?.name || '' }}</div>
  </div>
  <form [formGroup]="form" class="section-form">
    <!-- нужен что бы убрать фокус с matAutocomplete -->
    <input #hiddenInput type="text" class="d-none" />

    <div class="section-form-fields">
      <div class="row">
        <div class="col-4">
          <div class="mb-3" appTestLocator="edit-filter-template__filter-name">Название фильтра/значения</div>
          <dc-form-field-wrapper
            class="filter-template-name"
            [style]="'material'"
            [vertically]="true"
            [required]="true"
          >
            <dc-form-input
              *ngIf="
                selectedTemplateFilter && !isNew && selectedTemplateFilter?.action === filterTemplateActionType.ADD
              "
              [isShowLength]="true"
              [maxLength]="300"
            >
              <input
                formInput
                formControlName="title"
                placeholder="Введите или выберите из словаря"
                appTestLocator="edit-filter-template__filter-name_input"
              />
            </dc-form-input>
            <dc-form-input
              *ngIf="
                (!selectedTemplateFilter && isNew) ||
                (isNew && selectedTemplateFilter?.action === filterTemplateActionType.ADD) ||
                selectedTemplateFilter?.action === filterTemplateActionType.EDIT
              "
              [isShowLength]="true"
              [maxLength]="30"
            >
              <input
                formInput
                formControlName="title"
                placeholder="Введите или выберите из словаря"
                appTestLocator="edit-filter-template__filter-name_input"
              />
            </dc-form-input>
            <div
              *ngIf="form.get('title').touched && form.get('title').invalid && form.get('title').errors?.required"
              class="form-field-error"
              appTestLocator="edit-filter-template__filter-name_error"
            >
              Укажите название фильтра/значения
            </div>

            <div *ngIf="form.get('title').errors?.invalidOneLength" class="form-field-error">
              Значение одного фильтра не должно превышать 30 символов
            </div>

            <div *ngIf="form.get('title').errors?.invalidMultiLength" class="form-field-error">
              Значение одного и/или нескольких из указанных фильтров больше 30 символов
            </div>

            <div class="filter-template-list">
              <app-filter-template-tree-selected
                class="filter-template-list__items"
                appTestLocator="edit-filter-template__filter-name_list"
                [isDisabled]="disableItems"
                [searchString]="form.get('title').value"
                [selectedFilter]="selectedFilter?.node_info"
                (selectTemplateFilter)="selectTemplateFilter($event)"
              >
              </app-filter-template-tree-selected>
            </div>
          </dc-form-field-wrapper>
        </div>
        <div class="col-4">
          <div class="mb-3" appTestLocator="edit-filter-template__unit_name">Единица измерения (необязательно)</div>
          <div class="mb-5">
            <dc-form-field-wrapper [style]="'material'" [vertically]="true" [required]="false">
              <dc-form-input [maxLength]="30" [isShowLength]="true">
                <input
                  formInput
                  [matAutocomplete]="units"
                  formControlName="unit"
                  placeholder="Введите название или выберите из базы"
                  appTestLocator="edit-filter-template__unit_input"
                />
              </dc-form-input>

              <mat-autocomplete autoActiveFirstOption #units="matAutocomplete" [displayWith]="displayFn">
                <mat-option
                  *ngFor="let option of unitsOptions$ | async"
                  [value]="option"
                  appTestLocator="edit-filter-template__unit_list-option"
                >
                  {{ option.label }}
                </mat-option>
              </mat-autocomplete>
            </dc-form-field-wrapper>
          </div>

          <div class="filter-types-edit">
            <div class="mb-3" appTestLocator="edit-filter-template__filter-type">Тип фильтра/значения</div>
            <div class="filter-types-edit-row">
              <div class="dc-radio-input">
                <input
                  type="radio"
                  name="filterType"
                  id="boolean"
                  formControlName="filterType"
                  [value]="filterTemplateTypes.BOOL"
                />
                <label class="radio-label" for="boolean" appTestLocator="edit-filter-template__filter-type_bool">
                  да/нет
                </label>
              </div>
            </div>
            <div class="filter-types-edit-row flex-column align-items-start">
              <div class="dc-radio-input">
                <input
                  type="radio"
                  name="filterType"
                  id="range"
                  formControlName="filterType"
                  [value]="filterTemplateTypes.RANGE"
                />
                <label class="radio-label" for="range" appTestLocator="edit-filter-template__filter-type_range">
                  диапазон
                </label>
              </div>
              <app-form-range-slider
                [setValueFrom]="form.get('min')?.value"
                [setValueTo]="form.get('max')?.value"
                [readonly]="disableFormSlider || form.get('filterType')?.value !== filterTemplateTypes.RANGE"
                (changeRange)="setRangeValues($event)"
                class="create-filter-slider"
              ></app-form-range-slider>
              <div *ngIf="IsRangeInvalid" class="form-field-error">
                Левое число должно быть меньше или равно правому
              </div>
            </div>
            <div class="filter-types-edit-row">
              <div class="dc-radio-input">
                <input
                  type="radio"
                  name="filterType"
                  id="choice"
                  formControlName="filterType"
                  [value]="filterTemplateTypes.CHOICE"
                />
                <label class="radio-label" for="choice" appTestLocator="edit-filter-template__filter-type_choice">
                  множество
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="mb-3" appTestLocator="edit-filter-template__filter-tree">Дерево фильтра:</div>
          <div class="filter-types-edit-row">
            <app-filter-template-tree-item
              *ngIf="treeFilterData"
              [project]="project"
              [isCreateTemplateFilter]="isCreateTemplateFilter"
              [selectedTemplateFilter]="selectedTemplateFilter"
              [filterTemplateParams]="treeFilterData"
              [lastEditingFilter]="lastEditingFilter"
            ></app-filter-template-tree-item>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<app-operation-modal-footer
  (onCancel)="cancel()"
  (onSave)="save($event)"
  (onAgree)="agree($event)"
  [project]="project"
  [setSaveDisabled]="disableFooterSaveButton"
  [setAgreeDisabled]="disableFooterAgreeButton"
  [form]="formFooter"
>
</app-operation-modal-footer>
