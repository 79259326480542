<div class="info-event">
  <ng-container *ngIf="event">
    <div
      class="text-center info-event-header"
      [style.background-color]="status.color"
      [style.color]="state.UNDER_CONTROL == event.status ? '#fff' : ''"
    >
      {{ title }}
    </div>
    <div class="row info-event-status">
      <div class="col-9 info-event-status-title">{{ status.text }}</div>
      <div class="col-3 text-right info-event-status-button">
        <app-svg-icon [height]="16" [width]="18" name="edit_list" (click)="editEvent(event)"></app-svg-icon>
        <app-svg-icon [height]="16" [width]="14" name="copy" (click)="editEvent(event, true)"></app-svg-icon>
        <app-svg-icon [height]="14" [width]="14" name="close_wide" (click)="deleteEvent(event)"></app-svg-icon>
      </div>
    </div>
    <div class="info-event-title">
      {{ event.title }}
    </div>
    <div class="info-event-description">
      {{ event.description }}
    </div>
  </ng-container>
</div>
