import { Component, OnChanges, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { User } from '@app/shared/models/user.model';
import { AuthService } from '@app/shared/services/auth.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.sass'],
})
export class LeftMenuComponent implements OnDestroy, OnChanges {
  @Input() menu_items;
  @Input() menu_disabled;
  @Input() menuCollapsed = false;
  @Output() toggleItem = new EventEmitter();
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  user: User;

  element = 'country';
  isAddCityButtonVisible = true;
  isClockCollapsed = false;
  isCalendarCollapsed = false;
  need_set_password = false;

  constructor(private authService: AuthService) {}

  ngOnChanges() {
    this.authService.userStream.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
      this.user = user;
      this.need_set_password = !user.has_password;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.ngUnsubscribe = null;
  }

  onToggleItem(item) {
    item.open = !item.open;
    this.toggleItem.emit();
  }
}
