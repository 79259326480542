import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { takeUntil } from 'rxjs/operators';

import { NotificationsService } from 'angular2-notifications';

import { ProjectBase, ProjectBaseComponent } from '@app/+competence-map/models/project.class';
import { FilterTemplatesHelper } from '@app/+competence-map/helpers/filter-templates.helper';

import { Project } from '@app/+competence-map/models/projects.models';
import { DCTreeItem } from '@app/+competence-map/models/competence-map.models';
import { FilterTemplate } from '@app/+competence-map/models/filter-templates.model';
import { FilterTemplateTypes } from '@app/+competence-map/constants/filter-templates.constants';
import { CMActions, CMProjectStatuses } from '@app/+competence-map/constants/projects.constants';

import { DestroyService } from '@app/services/destroy.service';
import { AuthService } from '@app/shared/services/auth.service';
import { PortalService } from '@app/shared/services/portal.service';
import { CompetenceService } from '@app/+competence-map/services/competence.service';
import { uniq } from 'lodash-es';

@Component({
  selector: 'app-split-filter-template',
  templateUrl: './split-filter-template.component.html',
  styleUrls: ['./split-filter-template.component.scss'],
  providers: [PortalService, DestroyService],
})
export class SplitFilterTemplateComponent extends ProjectBaseComponent implements OnInit, ProjectBase {
  form: FormGroup;

  @Input() templateFilter: FilterTemplate;
  @Input() filters: DCTreeItem[];

  @Output() reloadEvent = new EventEmitter();

  @Input() set projectData(value: Project) {
    if (value.spec[CMActions.SPLIT_TEMPLATE_STRUCTURE_FILTER]) {
      this.project = value;

      const { new_nodes, node_id } = value.spec[CMActions.SPLIT_TEMPLATE_STRUCTURE_FILTER];

      const ids = uniq<number>(new_nodes.map((node) => node.childes).reduce((a, b) => [...a, ...b]));

      this.templateFilter = {
        ...this.templateFilter,
        title: value.name,
        id: node_id,
        type: FilterTemplateTypes.CHOICE,
      } as FilterTemplate;

      this.competenceService
        .getTemplateStructureFilter({ template_filter_ids: [...ids] })
        .pipe(takeUntil(this.destroy$))
        .subscribe((structureFilters) => {
          this.templateFilter.children = structureFilters[0].childes.map((childrenStructureFilterList) =>
            FilterTemplatesHelper.transformToFilterTemplate(childrenStructureFilterList)
          );
        });

      const sections = this.fb.array([]);

      new_nodes.forEach((node, index) => {
        sections.push(
          this.getNewSection({
            title: node.node_info.title,
            id: index,
            values: node.childes,
          }) as any
        );
      });

      this.form = this.fb.group({ sections });
    }
  }

  get selectedTreeNodes(): number[] {
    const { sections } = this.form.value;
    return sections
      .map((section) => {
        return section.values;
      })
      .flat();
  }

  get treeItemChildes(): FilterTemplate[] {
    return this.templateFilter?.children;
  }

  get formSections(): FormArray {
    return this.form.get('sections') as FormArray;
  }

  get canAddNew(): boolean {
    if (this.templateFilter?.children?.length) {
      return this.formSections.controls.length < 10;
    }
  }

  constructor(
    private fb: FormBuilder,
    private destroy$: DestroyService,
    private notify: NotificationsService,
    private competenceService: CompetenceService,
    protected authService: AuthService,
    protected portalService: PortalService
  ) {
    super(portalService, authService);
    this.initializeForm();
  }

  ngOnInit(): void {
    // if (!this.project) {
    //   this.initializeForm();
    // }
  }

  save(status: CMProjectStatuses): void {
    if (this.isNew) {
      this.addProject(status);
    } else {
      this.updateProject(status);
    }
  }

  agree(status: CMProjectStatuses): void {
    if (this.isNew) {
      this.addProject(status);
    } else {
      this.updateProject(status);
    }
  }

  archive(): void {
    this.warnChangeArchiveStatus(() => {
      this.updateProject(CMProjectStatuses.ARCHIVE);
    });
  }

  cancel(): void {
    this.formSections.controls.splice(2);
    this.formSections.controls.forEach((section) => {
      section.get('title').setValue('');
      section.get('values').setValue([]);
    });
  }

  addProject(status: CMProjectStatuses): void {
    const data = this.getSplitSaveData();
    if (!data) {
      return;
    }
    this.competenceService
      .splitFilterSection(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (project) => {
          this.projectData = project;
          if (status === CMProjectStatuses.AGREED || status === CMProjectStatuses.ON_AGREEMENT) {
            this.updateProject(status);
          } else {
            this.competenceService.projectSuccess(status);
          }
        },
        (httpError) => {
          this.competenceService.error(httpError);
        }
      );
  }

  updateProject(status: CMProjectStatuses): void {
    const data = this.getSplitSaveData();
    if (!data) {
      return;
    }
    this.competenceService
      .updateProject({
        ...this.project,
        status,
        spec: {
          split_template_structure_filter: data,
        },
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (project) => {
          this.competenceService.projectSuccess(status);
          this.reloadEvent.emit();
          this.projectData = project;
        },
        (httpError) => {
          this.competenceService.error(httpError);
        }
      );
  }

  addNewSection() {
    this.formSections.push(
      this.getNewSection({
        title: '',
        id: this.formSections.length + 1,
        values: [],
      })
    );
  }

  selectTreeItem(treeNodes: number[], index: number): void {
    this.formSections.controls[index].get('values').setValue(treeNodes);
  }

  private initializeForm(): void {
    const sections = this.fb.array([
      this.getNewSection({
        title: '',
        id: 0,
        values: [],
      }),
      this.getNewSection({
        title: '',
        id: 1,
        values: [],
      }),
    ]);

    this.form = this.fb.group({ sections });
  }

  private getSplitSaveData(): any {
    const { sections } = this.form.value;
    const { units_of_measure_id, default_range_from, default_range_to, type, value } = this.templateFilter;
    const newNodes = [];

    const duplicateSections = [...sections].sort();

    for (let i = 0; i < duplicateSections?.length; i++) {
      if (!duplicateSections[i]?.title) {
        this.notify.error('Ошибка', 'Заголовок/заголовки новых фильров пусты');
        return;
      }

      if (duplicateSections[i + 1]?.title === duplicateSections[i]?.title) {
        this.notify.error('Ошибка', 'Заголовки новых фильров совпадают');
        return;
      }
    }

    sections.forEach((section) => {
      const childes = section.values;
      newNodes.push({
        node_info: {
          title: section.title,
          units_of_measure_id,
          default_range_from,
          default_range_to,
          type,
          value,
        },
        childes,
      });
    });

    return {
      new_nodes: newNodes,
      node_id: Number.parseInt(this.templateFilter.id, 10),
    };
  }

  private getNewSection(data: any) {
    return this.fb.group({
      title: [data.title, Validators.required],
      unit: [{ value: ' - ', disabled: true }],
      values: [[...data?.values], Validators.required],
    });
  }

  removeSection(i: number): void {
    (this.form.controls['sections'] as FormArray).removeAt(i);
  }
}
