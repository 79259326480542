<div
  (click)="onThemeSelect(theme)"
  [routerLink]="theme.room_id"
  (mouseleave)="theme.menuExpanded = false; theme.menuShown = false"
  [class.selected]="contactSelected.room_id === theme.room_id || themeSelected?.room_id === theme?.room_id"
  class="theme"
>
  <div (mouseenter)="$event.stopPropagation(); theme.menuShown = true" class="theme-title">
    <div class="theme-title-text text-overflow">
      <span class="text-overflow" [title]="theme.title">Тема {{ theme.title }}</span>
    </div>
    <span *ngIf="theme.counter" class="chat-number">{{ theme.counter }}</span>
    <div
      *ngIf="canEditTheme()"
      [class.hidden]="!theme.menuShown"
      (mouseenter)="$event.stopPropagation(); theme.menuExpanded = true"
      (mouseleave)="theme.menuExpanded = false"
      class="theme-menu text-right"
    >
      <a (click)="onThemeEdit()">
        <fa-icon [icon]="['fas', 'cog']"></fa-icon>
      </a>
      <div
        [class.hidden]="!theme.menuExpanded"
        (mouseenter)="$event.stopPropagation(); theme.menuShown = true; theme.menuExpanded = true"
        (mouseleave)="theme.menuExpanded = false"
      >
        <div>
          <a (click)="onRemoveThemeAsk()">
            <fa-icon [icon]="['fas', 'trash']"></fa-icon>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<app-modal-window [isShowed]="isReallyRemoving" (closeEvent)="isReallyRemoving = false" customClass="confirm-modal">
  <p class="mb-0">Удалить тему</p>
  <p class="mb-5 text-overflow">
    <b [title]="group.title">Тема {{ group.title }}</b>
  </p>
  <div class="d-flex justify-content-between">
    <button class="btn btn-dc-outline" (click)="isReallyRemoving = false">Отменить</button>
    <button class="btn btn-dc-primary" (click)="onRemoveTheme()">Удалить</button>
  </div>
</app-modal-window>
