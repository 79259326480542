import { Component } from '@angular/core';
import { UserFile } from '@app/file_manager/models/user-file.model';
import { PopoverRef } from '@app/shared/helpers/popover-helper';

@Component({
  selector: 'app-files-tooltip',
  templateUrl: './files-tooltip.component.html',
  styleUrls: ['./files-tooltip.component.scss'],
})
export class FilesTooltipComponent {
  public files: UserFile[];

  constructor(private popoverRef: PopoverRef) {
    const { files } = this.popoverRef.data;
    this.files = files;
  }
}
