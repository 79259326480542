<div
  class="d-flex align-items-center shared-users-list-item padding-l-30"
  [class.checked]="node.data.selected"
  (dblclick)="toggleNodeClicked()"
>
  <div class="d-flex shared-users-list-item-checkbox">
    <input
      class="cbx-input"
      [id]="type + node.data.id"
      type="checkbox"
      (change)="onCheckboxChange($event)"
      [checked]="node.data.selected"
    />
    <label class="cbx-label" [for]="type + node.data.id"></label>
  </div>
  <div class="shared-users-list-item-toggle" *ngIf="node">
    <app-svg-icon
      [name]="!node?.isExpanded ? 'caret_down' : 'caret_up'"
      [style.opacity]="node?.hasChildren ? 1 : 0.3"
      (click)="toggleNodeClicked()"
      [width]="10"
      [height]="6"
    ></app-svg-icon>
  </div>
  <div class="margin-l-6">
    <p>{{ node.data.title }}</p>
  </div>
</div>
