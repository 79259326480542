import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';

import { SharedModule } from '@app/shared/shared.module';

import { CalendarComponent } from './calendar/calendar.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faCaretDown,
  faQuestion,
  faDollarSign,
  faCar,
  faBicycle,
  faStar,
  faTimes,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { faApple } from '@fortawesome/fontawesome-free-brands';
import { SVGSpriteModule } from '@app/svg-sprite/svg-sprite.module';
import { ViewMonthComponent } from '@app/calendar/view-month/view-month.component';
import { ViewYearComponent } from '@app/calendar/view-year/view-year.component';
import { ViewWeekComponent } from '@app/calendar/view-week/view-week.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

library.add(
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faCaretDown,
  faQuestion,
  faDollarSign,
  faCar,
  faBicycle,
  faStar,
  faTimes,
  faPlus,
  faBell,
  faApple as any
);

@NgModule({
  imports: [CommonModule, FormsModule, TextMaskModule, SharedModule, FontAwesomeModule, SVGSpriteModule, NgbModule],
  declarations: [CalendarComponent, ViewMonthComponent, ViewYearComponent, ViewWeekComponent],
  providers: [],
})
export class CalendarModule {}
