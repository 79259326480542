import { Component, ElementRef, Inject, OnInit, Optional, Renderer2, ViewChild } from '@angular/core';
import { FORM_FIELD, FormFieldWrapperComponent } from '../form-field-wrapper/form-field-wrapper.component';

@Component({
  selector: 'dc-form-label',
  templateUrl: './form-label.component.html',
  styleUrls: ['./form-label.component.scss'],
})
export class FormLabelComponent implements OnInit {
  @ViewChild('label', {static: true, read: ElementRef}) elementRef: ElementRef;

  constructor(
    private renderer: Renderer2,
    @Optional() @Inject(FORM_FIELD) private formFieldWrapper?: FormFieldWrapperComponent,
  ) {}

  ngOnInit(): void {
    if (this.formFieldWrapper && this.formFieldWrapper.id) {
      this.renderer.setAttribute(this.elementRef.nativeElement, 'for', this.formFieldWrapper.id);
    }
  }
}
