import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DestroyService } from '@app/services/destroy.service';
import { ITreeOptions, TreeComponent as TreeComponentLib, TreeNode } from '@circlon/angular-tree-component';

import { first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-split-tree-choice',
  templateUrl: './split-tree-choice.component.html',
  styleUrls: ['./split-tree-choice.component.scss'],
  providers: [DestroyService],
})
export class SplitTreeChoiceComponent implements AfterViewInit, OnChanges {
  unique = (Math.random() * 1e8).toString(16);
  nodes = [];
  isLoadData = true;
  allChecked: boolean;
  @Output() selectEvent = new EventEmitter<number[]>();
  @Input() data: any[] = [];
  @Input() selectedNodes: number[] = [];
  @Input() disabled: boolean;
  @ViewChild('tree', { static: true }) private tree: TreeComponentLib;

  options: ITreeOptions = {
    useCheckbox: true,
    useTriState: false,
  };

  get allItemChecked(): boolean {
    return this.tree.treeModel?.roots?.every((treeChild) => treeChild.isSelected === true);
  }

  constructor(private destroyed: DestroyService) {}

  ngAfterViewInit(): void {
    this.tree.initialized.pipe(first(), takeUntil(this.destroyed)).subscribe(() => {
      this.setTreeSelected();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { data, selectedNodes } = changes;
    if (this.isLoadData) {
      this.nodes = data.currentValue;
      if (this.nodes) {
        this.isLoadData = false;
      }
    }

    if (!selectedNodes.isFirstChange() && selectedNodes.currentValue !== selectedNodes.previousValue) {
      this.setTreeSelected(true);
    }
    // TODO: Временно убрал для отображения состояния после сохранения/согласования удалить после тестирования
    // if (!selectedNodes?.isFirstChange() && !selectedNodes?.currentValue?.length) {
    //   this.tree.treeModel.doForAll((node: TreeNode) => {
    //     // node.setIsSelected(false);
    //     // this.allChecked = false;
    //   });
    // }
  }

  isNodeSelected(node: TreeNode): boolean {
    return node.isSelected;
  }

  select(node: TreeNode, event: Event): void {
    event.stopPropagation();
    const searchedId = this.selectedNodes.indexOf(node.data.node_id) || null;
    if (searchedId > -1) {
      node.setIsSelected(false);
      this.selectedNodes.splice(searchedId, 1);
    } else {
      node.setIsSelected(true);
      this.selectedNodes.push(node.data.node_id);
    }

    this.selectChildren(node);
    this.updateParentSelection(node);

    this.selectEvent.emit(this.selectedNodes);
  }

  private selectChildren(parentNode: TreeNode): void {
    parentNode.children.forEach((childNode: TreeNode) => {
      if (this.selectedNodes.includes(parentNode.data.node_id) && !childNode.isSelected) {
        childNode.setIsSelected(true);
        this.selectedNodes.push(childNode.data.node_id);
      } else if (!this.selectedNodes.includes(parentNode.data.node_id) && childNode.isSelected) {
        childNode.setIsSelected(false);
        const childIndex = this.selectedNodes.indexOf(childNode.data.node_id);
        if (childIndex > -1) {
          this.selectedNodes.splice(childIndex, 1);
        }
      }
      this.selectChildren(childNode);
    });
  }

  private updateParentSelection(node: TreeNode): void {
    if (node.parent) {
      const isChildSelected = node.parent.children.some((childNode: TreeNode) => childNode.isSelected);
      node.parent.setIsSelected(isChildSelected);

      if (node.parent.data && node.parent.data.node_id) {
        const parentIndex = this.selectedNodes.indexOf(node.parent.data.node_id);

        if (isChildSelected && parentIndex === -1) {
          this.selectedNodes.push(node.parent.data.node_id);
        } else if (!isChildSelected && parentIndex > -1) {
          this.selectedNodes.splice(parentIndex, 1);
        }
      }

      this.updateParentSelection(node.parent);
    }
  }

  changeAll(): void {
    const checkedStatus = this.allItemChecked;
    this.tree.treeModel.roots.forEach((rootNode: TreeNode) => {
      this.selectNodeAndChildren(rootNode, !checkedStatus);
    });

    const selectedNodes = [];
    this.tree.treeModel.roots.forEach((rootNode: TreeNode) => {
      this.collectSelectedNodes(rootNode, selectedNodes);
    });

    this.selectEvent.emit(selectedNodes);
  }

  private selectNodeAndChildren(node: TreeNode, isSelected: boolean): void {
    node.setIsSelected(isSelected);

    node.children.forEach((childNode: TreeNode) => {
      this.selectNodeAndChildren(childNode, isSelected);
    });
  }

  private collectSelectedNodes(node: TreeNode, selectedNodes: number[]): void {
    if (node.isSelected) {
      selectedNodes.push(node.data.node_id);
    }

    node.children.forEach((childNode: TreeNode) => {
      this.collectSelectedNodes(childNode, selectedNodes);
    });
  }

  private setTreeSelected(isClear: boolean = false): void {
    this.tree.treeModel.doForAll((node: TreeNode) => {
      if (this.selectedNodes.includes(node.data.node_id)) {
        node.setIsSelected(true);
      } else if (isClear) {
        node.setIsSelected(false);
      }
    });
  }
}
