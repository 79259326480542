import { Column } from '@app/shared/models/table';
import { RolesEnum } from '@app/shared/constants/roles.constants';

export enum CMActions {
  CREATE_SECTIONS = 'create_sections',
  CHANGE_SECTION = 'change_section_title',
  CHANGE_SECTION_ROOT = 'change_section',
  MOVE_SECTION = 'move_section',
  SPLIT_SECTION = 'split_section',
  UNION_SECTION = 'union_section',

  CREATE_FILTERS = 'create_filters',
  SPLIT_INSTANCE_FILTER = 'split_instance_filter',
  UNION_INSTANCE_FILTER = 'union_instance_filter',

  SPLIT_FILTER = 'split_filter',
  UNION_FILTER = 'union_filter',
  SPLIT_SECTION_FILTERS = 'split_section_filters',
  CHANGE_SECTION_STATUS = 'change_section_status',
  CHANGE_FILTER = 'change_filter',
  CHANGE_FILTER_STATUS = 'change_filter_status',

  CREATE_GROUP_UNITS_MEASURE = 'create_group_units_measure',
  CHANGE_GROUP_UNITS_MEASURE = 'change_group_units_measure',
  TEMPLATE_STRUCTURE_FILTER_NESTED_IN = 'template_structure_filter_nested_in',

  CREATE_LEXICON = 'create_lexicon',

  CHOOSE_ACTIONS = 'choose_actions',
  STATUS_FILTER = 'status_filter',
  SEARCH = 'search',
  CHANGES = 'changes',

  COPY_FILTER = 'copy_filter',

  CREATE_COMMENT = 'create_comment',
  VIEW_COMMENT = 'open_comment',

  USER_CARD = 'user_card',
  CREATE_TEMPLATE_STRUCTURE_FILTER = 'create_template_structure_filter',
  CHANGE_TEMPLATE_STRUCTURE_FILTER = 'change_template_structure_filter',
  COPY_TEMPLATE_STRUCTURE_FILTER = 'copy_template_structure_filter',
  SPLIT_TEMPLATE_STRUCTURE_FILTER = 'split_template_structure_filter',
  CHANGE_STATUS_TEMPLATE_STRUCTURE_FILTER = 'change_template_filter_status',
  CHANGE_TEMPLATE_FILTER = 'change_template_filter',

  // words
  CHANGE_WORD_STATUS = 'change_word_status',
  CREATE_WORD = 'create_word',

  // units
  CREATE_UNITS_MEASURE = 'create_units_measure',
  CHANGE_UNITS_MEASURE = 'change_units_measure',
  DELETE_UNITS_MEASURE = 'delete_units_measure',

  FILE_IMPORT = 'file_import',
}

export enum ProjectLogActions {
  VIEWED = 'viewed',
}

export const PROJECT_ACTION_LABELS = {
  [CMActions.CREATE_SECTIONS]: 'Создание раздела',
  [CMActions.CHANGE_SECTION]: 'Переименование раздела',
  [CMActions.CHANGE_SECTION_ROOT]: 'Изменение раздела',
  [CMActions.MOVE_SECTION]: 'Перенос раздела',
  [CMActions.SPLIT_SECTION]: 'Разделение раздела',
  [CMActions.UNION_SECTION]: 'Объединение раздела',
  [CMActions.CHANGE_SECTION_STATUS]: 'Изменение статуса раздела',

  [CMActions.CREATE_FILTERS]: 'Создание БПФ',
  [CMActions.SPLIT_INSTANCE_FILTER]: 'Разделение БПФ',
  [CMActions.UNION_INSTANCE_FILTER]: 'Объединение БПФ',

  [CMActions.CREATE_UNITS_MEASURE]: 'Создание единицы измерения',
  [CMActions.CREATE_GROUP_UNITS_MEASURE]: 'Создание группы единиц измерения',
  [CMActions.CREATE_LEXICON]: 'Создание слова/словосочетания',

  [CMActions.CREATE_TEMPLATE_STRUCTURE_FILTER]: 'Создание БШФ',
  [CMActions.CHANGE_TEMPLATE_STRUCTURE_FILTER]: 'Редактирование БШФ',
  [CMActions.COPY_TEMPLATE_STRUCTURE_FILTER]: 'Копирование БШФ',
  [CMActions.CHANGE_TEMPLATE_FILTER]: 'Редактирование БШФ',
  [CMActions.SPLIT_FILTER]: 'Разделение БПФ',
  [CMActions.SPLIT_TEMPLATE_STRUCTURE_FILTER]: 'Разделение БШФ',
  [CMActions.UNION_FILTER]: 'Объединение БШФ',

  [CMActions.CHANGE_STATUS_TEMPLATE_STRUCTURE_FILTER]: 'Изменение статуса БШФ',
  [CMActions.CHANGE_FILTER_STATUS]: 'Изменение статуса БПФ',
  [CMActions.CHANGE_FILTER]: 'Редактирование БПФ',

  [CMActions.CREATE_UNITS_MEASURE]: 'Создание единицы измерения',
  [CMActions.CHANGE_UNITS_MEASURE]: 'Изменение единицы измерения',
  [CMActions.DELETE_UNITS_MEASURE]: 'Удаление единицы измерения',

  [CMActions.CREATE_WORD]: 'Создание слова',

  // [CMActions.SPLIT_SECTION_FILTERS]: 'Создание раздела',
};

export enum CMProjectStatuses {
  EDITING = 'editing',
  ON_AGREEMENT = 'on_agreement',
  AGREED = 'agreed',
  ARCHIVE = 'archive',
  DELETED = 'deleted',
  ROLLBACK = 'rollback',
}

export const PROJECT_STATUSES = [
  {
    label: 'Редактирование',
    value: CMProjectStatuses.EDITING,
  },
  {
    label: 'На согласовании',
    value: CMProjectStatuses.ON_AGREEMENT,
  },
  {
    label: 'Согласовано',
    value: CMProjectStatuses.AGREED,
  },
  {
    label: 'Архив',
    value: CMProjectStatuses.ARCHIVE,
  },
];

export const PROJECT_TITLES = {
  goods: 'Каталог товаров',
  services: 'Каталог работ и услуг',
};

export enum ProjectColumnKeys {
  ID = 'id',
  TITLE = 'name',
  PATH = 'path',
  TYPE = 'action',
  AUTHOR = 'author_id',
  SEND_DATE = 'created_at',
  STATUS = 'status',
  COMMENT = 'comments',
  SELECT = '',
}

export const PROJECT_COLUMNS: Column<any>[] = [
  {
    id: ProjectColumnKeys.ID,
    name: 'ID',
    isEnabledSearch: true,
    searchPlaceholder: 'Поиск по id',
    width: 100,
    data: {
      isSticky: true,
      indent: 0,
      enableRoles: [RolesEnum.EXPERT, RolesEnum.SUPERUSER],
    },
    filterConfig: [
      {
        name: ProjectColumnKeys.ID,
        op: 'in_',
        val: '',
      },
    ],
  },
  {
    id: ProjectColumnKeys.TITLE,
    name: 'Название',
    isEnabledSearch: true,
    searchPlaceholder: 'Поиск по названию',
    width: 200,
    data: {
      isSticky: true,
      indent: 100,
      enableRoles: [RolesEnum.EXPERT, RolesEnum.SUPERUSER],
    },
    filterConfig: [
      {
        name: ProjectColumnKeys.TITLE,
        op: 'in_',
        val: '',
      },
    ],
  },
  {
    id: ProjectColumnKeys.PATH,
    name: 'Путь проекта',
    isEnabledSearch: true,
    searchPlaceholder: 'Поиск по пути',
    width: 220,
    data: {
      enableRoles: [RolesEnum.EXPERT, RolesEnum.SUPERUSER],
    },
    filterConfig: [
      {
        name: ProjectColumnKeys.PATH,
        op: 'in_',
        val: '',
      },
    ],
  },
  {
    id: ProjectColumnKeys.TYPE,
    name: 'Тип проекта',
    isEnabledSearch: true,
    searchPlaceholder: 'Поиск по типу',
    width: 250,
    data: {
      enableRoles: [RolesEnum.EXPERT, RolesEnum.SUPERUSER],
    },
    filterConfig: [
      {
        name: ProjectColumnKeys.TYPE,
        op: 'in_',
        val: '',
      },
    ],
  },
  {
    id: ProjectColumnKeys.AUTHOR,
    name: 'Автор проекта',
    isEnabledSearch: true,
    searchPlaceholder: 'Поиск по автору',
    width: 250,
    data: {
      enableRoles: [RolesEnum.EXPERT, RolesEnum.SUPERUSER],
    },
    filterConfig: [
      {
        name: ProjectColumnKeys.AUTHOR,
        op: 'in_',
        val: '',
      },
    ],
  },
  {
    id: ProjectColumnKeys.SEND_DATE,
    name: 'Дата отправки',
    isEnabledSearch: true,
    width: 150,
    data: {
      enableRoles: [RolesEnum.EXPERT, RolesEnum.SUPERUSER],
    },
    filterConfig: [
      {
        name: ProjectColumnKeys.SEND_DATE,
        op: 'between',
        val: null,
      },
    ],
  },
  {
    id: ProjectColumnKeys.STATUS,
    name: 'Статус',
    isEnabledFilter: true,
    width: 200,
    searchPlaceholder: 'Показать',
    data: {
      enableRoles: [RolesEnum.EXPERT, RolesEnum.SUPERUSER],
    },
    filterConfig: [
      {
        name: ProjectColumnKeys.STATUS,
        op: 'in_',
        val: '',
      },
    ],
  },
  {
    id: ProjectColumnKeys.COMMENT,
    name: 'Комментарий',
    data: {
      enableRoles: [RolesEnum.EXPERT, RolesEnum.SUPERUSER],
    },
    width: 300,
  },
  {
    id: ProjectColumnKeys.SELECT,
    name: 'Выбрать',
    data: {
      isSelect: true,
      enableRoles: [RolesEnum.SUPERUSER, RolesEnum.EXPERT],
    },
    width: 150,
  },
].map((column) => {
  return {
    ...column,
    defaultFilterConfig: column.filterConfig,
  };
});
