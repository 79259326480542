<div class="row">
  <div class="col-10">
    <div #scrollMe class="chat__wrapper">
      <app-chat-tab
        class="chat__tab"
        *ngFor="let tab of tabs"
        [tab]="tab"
        [routerLink]="tab.room_id"
        [active]="contactSelected.room_id === tab.room_id"
        [contactSelected]="contactSelected"
      ></app-chat-tab>
    </div>
  </div>
  <div (click)="scroll(-50)" class="col-1 chat__tab-toggle" [ngClass]="{ 'chat__tab-toggle_finish': !canScrollL() }">
    <fa-icon [icon]="['fas', 'angle-left']"></fa-icon>
  </div>
  <div (click)="scroll(+50)" class="col-1 chat__tab-toggle" [ngClass]="{ 'chat__tab-toggle_finish': !canScrollR() }">
    <fa-icon [icon]="['fas', 'angle-right']"></fa-icon>
  </div>
</div>
