<div class="dc-modal-x-button dc-fa-button" (click)="close()">
  <app-svg-icon [height]="20" [width]="20" name="close"></app-svg-icon>
</div>
<div class="modal-window-content">
  <div class="modal-header margin-b-20">
    <div class="modal-title text-center">
      {{ title }}
    </div>
  </div>

  <div class="modal-body margin-b-0">
    <table class="base-table">
      <thead>
        <tr>
          <th *ngFor="let column of columns">{{ column.label }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of documents">
          <td>
            <div class="document-name">
              <a [href]="item.url" target="_blank">
                <app-svg-icon [width]="12" [height]="16" name="download"></app-svg-icon>
                <span>{{ item?.name }}</span>
              </a>
            </div>
          </td>
          <td>{{ item?.begin | dateTableFormat }}</td>
          <td>{{ item?.end | dateTableFormat }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
