import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { RolesEnum } from '@app/shared/constants/roles.constants';
import { Role } from '@app/shared/models/user.model';

@Component({
  selector: 'app-file-manager-share-user-filter-roles',
  templateUrl: './file-manager-share-user-filter-roles.component.html',
  styleUrls: ['./file-manager-share-user-filter-roles.component.scss'],
})
export class FileManagerShareUserFilterRolesComponent implements OnInit {
  @Input() selected: RolesEnum[] = [];
  @Input() items: Role[] = [];

  @Output() selectRoleEmit: EventEmitter<RolesEnum[]> = new EventEmitter<RolesEnum[]>();
  selectedAllRoles: boolean = false;

  ngOnInit(): void {
    this.items.forEach((role) => {
      role.selected = !!this.selected.includes(role.value as RolesEnum);
    });
  }

  selectRole(): void {
    const selectedRoles = this.items.filter((role) => role.selected === true);
    this.selectRoleEmit.emit(selectedRoles.map((value) => value.value as RolesEnum));
  }

  selectAllRoles(event: Event): void {
    this.selectedAllRoles = !this.selectedAllRoles;
    this.items.forEach((value) => (value.selected = this.selectedAllRoles));
    this.selectRole();
  }
}
