import { Injectable } from '@angular/core';
import { TradeCompetency } from '@app/+competence-map/models/user-competency.model';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class TradeCompetenciesService {
  private _data$: Subject<TradeCompetency[]> = new BehaviorSubject([]);
  data$ = this._data$.asObservable();

  set(value: TradeCompetency[]) {
    this._data$.next(value);
  }
}
