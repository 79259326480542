import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { FileManagerSpaceInterface } from '@app/file-manager/models/file-manager-space.interface';

@Component({
  selector: 'app-file-manager-space-info',
  templateUrl: './file-manager-space-info.component.html',
  styleUrls: ['./file-manager-space-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileManagerSpaceInfoComponent {
  @Input() spaceInfo: FileManagerSpaceInterface;

  get percentUsed(): number {
    return this.formatPercentUsed(this.spaceInfo?.limit, this.spaceInfo?.used);
  }

  get limitSpace(): string {
    return this.formatBytes(this.spaceInfo?.limit);
  }

  get remainingSpace(): string {
    return this.formatBytes(this.spaceInfo?.remaining);
  }

  get spaceClass(): string {
    if (this.percentUsed >= 90 && this.percentUsed <= 97) {
      return 'file-manager-space-info__warning';
    } else if (this.percentUsed >= 98) {
      return 'file-manager-space-info__danger';
    } else {
      return '';
    }
  }

  private formatPercentUsed(limit: number, used: number): number {
    return Math.round((100 * used) / limit);
  }

  private formatBytes(bytes: number, decimals?: number) {
    if (bytes === 0) return '0 МБ';
    const k = 1024,
      dm = decimals || 0,
      sizes = ['Байт', 'КБ', 'МБ', 'ГБ', 'ТБ'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
