import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-file-manager-modal-archive-upload',
  templateUrl: './file-manager-modal-archive-upload.component.html',
  styleUrls: ['./file-manager-modal-archive-upload.component.scss'],
})
export class FileManagerModalArchiveUploadComponent {
  constructor(private readonly activeModal: NgbActiveModal) {}

  download(): void {
    this.activeModal.close(true);
  }

  cancel(): void {
    this.activeModal.close(false);
  }
}
