import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { FilterTemplateStatuses, FilterTemplateTypes } from '@app/+competence-map/constants/filter-templates.constants';
import { FilterTemplateDataService } from '@app/+competence-map/services/filter-template-data.service';
import {
  CreateOrChangeFilterTemplateParams,
  SelectedTemplateFilter,
  TreeAction,
} from '@app/+competence-map/models/filter-templates.model';
import { Project } from '@app/+competence-map/models/projects.models';
import { CMActions, CMProjectStatuses } from '@app/+competence-map/constants/projects.constants';
import { KeyValue } from '@app/shared/models/base-utility.model';

@Component({
  selector: 'app-filter-template-tree-item',
  templateUrl: './filter-template-tree-item.component.html',
  styleUrls: ['./filter-template-tree-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterTemplateTreeItemComponent {
  @Input() parentFilterTemplateParams?: CreateOrChangeFilterTemplateParams;
  @Input() filterTemplateParams: CreateOrChangeFilterTemplateParams;
  @Input() isRoot = true;
  @Input() isCollapsed = false;
  @Input() tmpLevel = 1;
  @Input() isCreateTemplateFilter: boolean;
  @Input() project: Project;
  @Input() mirrorsArrayIds: KeyValue<number> = {};
  @Input() editChildrenItemsArrayIds: string[] = [];
  @Input() lastEditingFilter: SelectedTemplateFilter;

  private _selectedTemplateFilter: SelectedTemplateFilter | null = null;

  @Input() set selectedTemplateFilter(value: SelectedTemplateFilter | null) {
    this._selectedTemplateFilter = value;

    if (!this.project.status) {
      const processChild = (child: CreateOrChangeFilterTemplateParams) => {
        this.editChildrenItemsArrayIds = [...this.editChildrenItemsArrayIds, child.node_id];

        if (child?.childes?.length) {
          child.childes.forEach(processChild);
        }
      };

      value.item.childes?.forEach(processChild);
    }

    if (!this.isVirtualItem(value?.id)) {
      this.mirrorsArrayIds[value.id] = (this.mirrorsArrayIds[value.id] || 0) + 1;
    }
  }

  get selectedTemplateFilter(): SelectedTemplateFilter {
    return this._selectedTemplateFilter;
  }

  get projectSpecType(): CMActions {
    const projectSpec = this.project.spec;
    if (projectSpec[CMActions.CREATE_TEMPLATE_STRUCTURE_FILTER]) {
      return CMActions.CREATE_TEMPLATE_STRUCTURE_FILTER;
    }
    if (projectSpec[CMActions.CHANGE_TEMPLATE_STRUCTURE_FILTER]) {
      return CMActions.CHANGE_TEMPLATE_STRUCTURE_FILTER;
    }
    if (projectSpec[CMActions.COPY_TEMPLATE_STRUCTURE_FILTER]) {
      return CMActions.COPY_TEMPLATE_STRUCTURE_FILTER;
    }
  }

  get treeAction(): typeof TreeAction {
    return TreeAction;
  }

  get filterTemplateTypes(): typeof FilterTemplateTypes {
    return FilterTemplateTypes;
  }

  get isDisabledCollapsed(): boolean {
    return (
      !this.filterTemplateParams?.childes?.length &&
      this.filterTemplateParams?.node_info?.type === FilterTemplateTypes.CHOICE
    );
  }

  get isItemDisplayActions(): boolean {
    if (this.isRoot) {
      return true;
    }

    if (this.isVirtualItem(this.filterTemplateParams?.node_id)) {
      return true;
    }

    return this.isExistItemInMirrorArray || this.isExistItemInEditChildrenArray;
  }

  get hideDeleteButton(): boolean {
    if (this.isExistItemInEditChildrenArray) {
      return true;
    }

    return this.isRoot;
  }

  get isAddChildCurrentTreeItem(): boolean {
    if (this._selectedTemplateFilter && this._selectedTemplateFilter.action === TreeAction.ADD) {
      return this._selectedTemplateFilter?.id === this.filterTemplateParams?.node_id;
    }
    return false;
  }

  get isDisableAdd(): boolean {
    const currentItem = this.filterTemplateParams;
    if (this.tmpLevel === 10) {
      return true;
    }

    if (!this.filterTemplateParams.node_id) {
      return true;
    }

    if (
      currentItem.node_info.status === FilterTemplateStatuses.ARCHIVE ||
      this.project.status === CMProjectStatuses.ARCHIVE
    ) {
      return true;
    }

    if (this.project.status === CMProjectStatuses.AGREED || this.project.status === CMProjectStatuses.ON_AGREEMENT) {
      return true;
    }

    if (
      this.filterTemplateParams.node_info.type === FilterTemplateTypes.RANGE ||
      this.filterTemplateParams.node_info.type === FilterTemplateTypes.BOOL
    ) {
      return true;
    }

    if (this.isVirtualItem(this.filterTemplateParams?.node_id)) {
      return false;
    }

    if (
      this.isCreateTemplateFilter &&
      this.isVirtualItem(this.filterTemplateParams?.node_id) &&
      this.isVirtualItem(this.parentFilterTemplateParams?.node_id)
    ) {
      return false;
    }

    if (this.isCreateTemplateFilter && this.parentFilterTemplateParams?.node_id && this.filterTemplateParams?.node_id) {
      return true;
    }

    if (this.isExistItemInEditChildrenArray) {
      return false;
    }

    if (this.isExistItemInMirrorArray && !this.isRoot) {
      return true;
    }

    return !!this.filterTemplateParams?.node_info?.mirror_template_filter_id || this.isAddChildCurrentTreeItem;
  }

  get isEditing(): boolean {
    const { node_id, index } = this.filterTemplateParams;

    if (this.selectedTemplateFilter?.action === TreeAction.ROLLBACK) {
      return this.selectedTemplateFilter.item.index === index;
    }

    if (
      this.lastEditingFilter &&
      (this.mirrorsArrayIds[node_id] > 1 || this.lastEditingFilter?.action === TreeAction.DELETE)
    ) {
      return this.lastEditingFilter?.item.index === index;
    }

    return (
      (this.selectedTemplateFilter?.id === node_id && this.selectedTemplateFilter.action === this.treeAction.EDIT) ||
      !node_id
    );
  }

  get isExistItemInMirrorArray(): boolean {
    return !!this.mirrorsArrayIds[this.filterTemplateParams.node_id];
  }

  get isExistItemInEditChildrenArray(): boolean {
    return this.editChildrenItemsArrayIds.some((mirrorId) => this.filterTemplateParams.node_id === mirrorId);
  }

  get isDisableEdit(): boolean {
    if (!this.filterTemplateParams?.node_id) {
      return true;
    }

    if (this.filterTemplateParams?.node_info?.status === FilterTemplateStatuses.ARCHIVE) {
      return true;
    }

    if (
      (this.project?.action === CMActions.CREATE_TEMPLATE_STRUCTURE_FILTER ||
        this.project?.action === CMActions.CHANGE_TEMPLATE_STRUCTURE_FILTER) &&
      this.project?.status === CMProjectStatuses.ARCHIVE
    ) {
      return true;
    }

    if (this.project?.status === CMProjectStatuses.AGREED || this.project?.status === CMProjectStatuses.ON_AGREEMENT) {
      return true;
    }

    if (
      this.isCreateTemplateFilter &&
      this.isVirtualItem(this.filterTemplateParams?.node_id) &&
      this.isVirtualItem(this.parentFilterTemplateParams?.node_id)
    ) {
      return false;
    }

    if (this.isCreateTemplateFilter && this.parentFilterTemplateParams?.node_id && this.filterTemplateParams?.node_id) {
      return true;
    }

    if (
      this.selectedTemplateFilter?.id === this.filterTemplateParams?.node_id &&
      this.selectedTemplateFilter?.action === this.treeAction.EDIT
    ) {
      return true;
    }

    return (
      this.tmpLevel !== this.filterTemplateParams?.node_info?.level ||
      !!this.filterTemplateParams?.node_info?.mirror_template_filter_id
    );
  }

  get isDisableDelete(): boolean {
    if (
      this.filterTemplateParams?.node_info.status === FilterTemplateStatuses.ARCHIVE ||
      this.project.status === CMProjectStatuses.ARCHIVE
    ) {
      return true;
    }

    if (this.project.status === CMProjectStatuses.AGREED || this.project.status === CMProjectStatuses.ON_AGREEMENT) {
      return true;
    }

    if (!this.filterTemplateParams.node_id) {
      return true;
    }

    if (this.isItemDisplayActions) {
      return false;
    }

    if (
      !this.isVirtualItem(this.filterTemplateParams?.node_id) &&
      !this.parentFilterTemplateParams?.node_info?.mirror_template_filter_id &&
      this.filterTemplateParams?.node_info?.mirror_template_filter_id
    ) {
      return false;
    }

    return !this.isVirtualItem(this.filterTemplateParams?.node_id);
  }

  get isChildMirrorHideAction(): boolean {
    return !!(
      this.parentFilterTemplateParams?.node_info?.mirror_template_filter_id &&
      this.filterTemplateParams?.node_info?.mirror_template_filter_id
    );
  }

  constructor(private filterTemplateDataService: FilterTemplateDataService) {}

  isVirtualItem(itemId: string): boolean {
    return /virtual/g.test(itemId);
  }

  sendAction(action: TreeAction, data: CreateOrChangeFilterTemplateParams): void {
    if (action === TreeAction.DELETE && this.isDisableDelete) {
      return;
    }

    const mirrorId = this.mirrorsArrayIds[data.node_id];

    if (action === TreeAction.DELETE && mirrorId) {
      if (mirrorId === 1) {
        delete this.mirrorsArrayIds[data.node_id];
      } else {
        this.mirrorsArrayIds[data.node_id] = mirrorId - 1;
      }
    }

    this.filterTemplateDataService.treeActionSubject.next({ action, data });
  }

  changeCollapseItem(): void {
    this.isCollapsed = !this.isCollapsed;
  }
}
