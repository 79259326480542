import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Location } from 'app/shared/models/location.model';
import { environment } from 'environments/environment';

@Injectable()
export class SearchLocationService {
  public user: Object = {};

  constructor(private http: HttpClient) {}

  searchLocation(text: string, element?: string, location: Location = new Location()): Observable<any> {
    let url = `${environment.api_url}/locations`;
    switch (element) {
      //     GET /locations/country/{country_id}/regions
      //     GET /locations/region/{region_id}/cities
      case 'city':
        url += `/region/${location.region.id}/cities?q=`;
        break;

      case 'region':
        url += `/country/${location.country.id}/regions?q=`;
        break;
      case 'country':
        url += '/countries?q=';
        break;
      default:
        url += '?q=';
    }
    url += text;

    return this.http.get(url).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }
}
