<div class="mr-3">
  <div class="d-flex justify-content-center">
    <div class="calendar-current-month">
      <span
        class="calendar-month-back-trigger calendar-month-trigger"
        mwlCalendarPreviousView
        [view]="view"
        [(viewDate)]="viewDate"
      >
        <fa-icon [icon]="['fas', 'angle-left']" class="mr-4"></fa-icon>
      </span>
      <span class="calendar-current-month-text">{{ getFormatedMonth(viewDate) }}</span>
      <span
        class="calendar-month-next-trigger calendar-month-trigger"
        mwlCalendarNextView
        [view]="view"
        [(viewDate)]="viewDate"
      >
        <fa-icon [icon]="['fas', 'angle-right']" class="ml-4"></fa-icon>
      </span>
    </div>
  </div>

  <mwl-calendar-month-view
    [viewDate]="viewDate"
    [weekendDays]="weekendDays"
    [weekStartsOn]="1"
    [refresh]="refresh"
    [locale]="locale"
    (dayClicked)="dayClicked($event.day)"
    (beforeViewRender)="beforeMonthViewRender($event)"
  >
  </mwl-calendar-month-view>
</div>
