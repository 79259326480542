import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { from, Observable, of } from 'rxjs';
import { ViewPdfModalComponent } from '../shared/components/view-pdf-modal/view-pdf-modal.component';
import { catchError } from 'rxjs/operators';

@Injectable()
export class PaymentPdfService {
  constructor(private ngbModal: NgbModal) {}

  viewDoc(doc: string, title: string): Observable<boolean> {
    const modal = this.ngbModal.open(ViewPdfModalComponent, { windowClass: 'pdf-view', backdrop: 'static' });

    modal.componentInstance.docLink = doc;
    modal.componentInstance.title = title;

    return from(modal.result).pipe(
      catchError((error) => {
        return of(undefined);
      })
    );
  }
}
