import { RolesEnum } from '@app/shared/constants/roles.constants';

export let LEGAL_STATUSES = [
  {
    value: 'natural_person',
    title: 'Физ.лицо',
    label: 'Физическое лицо',
    roles: [
      RolesEnum.ADMIN_OF_USER,
      RolesEnum.ADMIN_OF_DIRECTION,
      RolesEnum.PARTNER,
      RolesEnum.OPERATOR,
      RolesEnum.ACCOUNTANT,
      RolesEnum.EXPERT,
      RolesEnum.SUPERUSER,
      RolesEnum.TSO,
    ],
  },
  {
    value: 'legal_entity',
    title: 'Юр.лицо',
    label: 'Юридическое лицо',
    roles: [
      RolesEnum.ADMIN_OF_USER,
      RolesEnum.ADMIN_OF_DIRECTION
    ],
  },
  {
    value: 'individual_entrepreneur',
    title: 'ИП',
    label: 'Индивидуальный предприниматель',
    roles: [
      RolesEnum.ADMIN_OF_USER,
      RolesEnum.ADMIN_OF_DIRECTION
    ],
  }
];
