export class TreeHelper {
  static treeToArray(treeObjArray, childrenAttr = 'children') {
    let result = [];
    function flattenChild(childObj) {
      let array = [];

      const childCopy = Object.assign({}, childObj, {
        parent: Object.assign({}, childObj.parent),
      });
      delete childCopy[childrenAttr];
      array.push(childCopy);

      array = array.concat(processChildren(childObj));

      return array;
    }

    function processChildren(obj) {
      let array = [];

      if (obj[childrenAttr]) {
        obj[childrenAttr].forEach(function (childObj) {
          array = array.concat(flattenChild(childObj));
        });
      }

      return array;
    }

    for (let i = 0; i < treeObjArray.length; i++) {
      const treeObj = treeObjArray[i];

      const objCopy = Object.assign({}, treeObj);

      if (!objCopy[childrenAttr]) {
        result.push(objCopy);
      } else {
        delete objCopy[childrenAttr];
        result = [].concat(result, [objCopy], processChildren(treeObj));
      }
    }

    return result;
  }

  static arrayToTree(arr) {
    const tree = [];
    const hash = {};
    for (let i = 0; i < arr.length; i++) {
      const obj = arr[i];
      hash[obj.id] = obj;
      hash[obj.id]['children'] = [];
    }

    for (const id in hash) {
      if (hash.hasOwnProperty(id)) {
        const el = hash[id];
        // If the element is not at the root level, add it to its parent array of children.
        if (el.parent && el.parent.id) {
          if (hash[el['parent']['id']]) {
            hash[el['parent']['id']]['children'].push(el);
          } else {
            tree.push(el);
          }
        } else {
          tree.push(el);
        }
      }
    }
    return tree;
  }

  static list_to_tree(list, parent_id = 'parent_id') {
    var map = {},
      node,
      roots = [],
      i;

    for (i = 0; i < list.length; i += 1) {
      map[list[i].id] = i; // initialize the map
      list[i].children = []; // initialize the children
    }

    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (!!node[parent_id]) {
        // if you have dangling branches check that map[node.parentId] exists
        list[map[node[parent_id]]]?.children.push(node);
      } else {
        roots.push(node);
      }
    }
    return roots;
  }

  static flatTree<T extends { children: T[] }>(tree: T, childrenAttr = 'children'): T[] {
    const children: T[] = [];
    const stack = [tree];

    while (stack.length) {
      const el = stack.pop();
      if (el[childrenAttr]?.length) {
        stack.push(...el[childrenAttr]);
      }

      children.push(el);
    }

    return children;
  }
}
