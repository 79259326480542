import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { DestroyService } from '@app/services/destroy.service';

@Component({
  selector: 'app-file-manager-search',
  templateUrl: './file-manager-search.component.html',
  styleUrls: ['./file-manager-search.component.scss'],
  providers: [DestroyService],
})
export class FileManagerSearchComponent implements OnInit {
  @Input() placeholder: string;
  @Output() onSearch = new EventEmitter<string>();
  searchForm: FormGroup;

  get isSearchQuery(): boolean {
    return this.searchForm?.dirty && this.searchForm.controls['query'].value.length;
  }

  constructor(private readonly formBuilder: FormBuilder, private readonly destroy$: DestroyService) {}

  ngOnInit(): void {
    this.initializeForm();
    this.searchForm.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((value) => {
        this.onSearch.emit(value.query);
      });
  }

  resetSearch(): void {
    this.searchForm.reset();
    this.onSearch.emit('');
  }

  private initializeForm(): void {
    this.searchForm = this.formBuilder.group({
      query: this.formBuilder.control(''),
    });
  }
}
