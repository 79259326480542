<form [formGroup]="form">
  <div class="checkbox">
    <input class="cbx-input" id="with_individuals" type="checkbox" formControlName="not_natural_person" />
    <label class="cbx-label" for="with_individuals">
      <span class="cbx-label-text color-brand-light">
        Кроме физических лиц, не зарегистрированных<br />в качестве предпринимателей
      </span>
    </label>
  </div>

  <label>Дата государственной регистрации компании поставщика</label>
  <div class="date-range">
    <app-datepicker
      controlNameDate="from_date_state_registration"
      [form]="form"
      [maxDate]="currentDate"
      [fieldParams]="{ dateLabel: 'С' }"
      [dateRequire]="false"
    ></app-datepicker>

    <app-datepicker
      controlNameDate="to_date_state_registration"
      [form]="form"
      [fieldParams]="{ dateLabel: 'по' }"
      [dateRequire]="false"
    ></app-datepicker>
  </div>

  <label>Дата регистрации поставщика в сервисе ПК</label>
  <div class="date-range">
    <app-datepicker
      controlNameDate="from_date_registration_payer"
      [form]="form"
      [maxDate]="currentDate"
      [fieldParams]="{ dateLabel: 'С' }"
      [dateRequire]="false"
    ></app-datepicker>

    <app-datepicker
      controlNameDate="to_date_registration_payer"
      [form]="form"
      [fieldParams]="{ dateLabel: 'по' }"
      [dateRequire]="false"
    ></app-datepicker>
  </div>
</form>
