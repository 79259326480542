import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProviderCompetency } from '@app/+competence-map/models/user-competency.model';
import { UserFilter } from '@app/+competence-map/models/user-filters.model';

@Component({
  selector: 'app-user-filters-cell-range',
  templateUrl: './user-filters-cell-range.component.html',
  styleUrls: ['./user-filters-cell-range.component.scss'],
})
export class UserFiltersCellRangeComponent implements OnInit {
  @Input() node: UserFilter;
  @Input() competencyData: ProviderCompetency;
  @Input() readonly: boolean;

  form: FormGroup;

  @Output() saveEvent = new EventEmitter<{ id: string; min: number; max: number }>();
  @Output() resetEvent = new EventEmitter<{ id: string; min: number; max: number }>();
  @Output() changeEvent = new EventEmitter<{ id: string; min: number; max: number }>();

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      min: 0,
      max: 0,
    });

    if (this.competencyData) {
      this.form.setValue({
        min: this.competencyData.value_range_from,
        max: this.competencyData.value_range_to,
      });
    }
  }

  save() {
    this.saveEvent.emit({
      id: this.node.id,
      min: this.form.value.min,
      max: this.form.value.max,
    });
  }

  reset() {
    if (!!this.competencyData) {
      this.form.setValue({
        min: this.competencyData.value_range_from,
        max: this.competencyData.value_range_to,
      });

      this.saveEvent.emit({
        id: this.node.id,
        min: this.competencyData.value_range_from,
        max: this.competencyData.value_range_to,
      });
    } else {
      this.form.setValue({
        min: this.node.range_from,
        max: this.node.range_to,
      });

      this.saveEvent.emit({
        id: this.node.id,
        min: this.node.range_from,
        max: this.node.range_to,
      });
    }

    this.form.markAsPristine();
  }

  setRangeValues(range: { min: number; max: number }): void {
    this.form.get('min').setValue(range.min);
    this.form.get('max').setValue(range.max);

    this.form.markAsDirty();

    this.changeEvent.emit({
      id: this.node.id,
      min: this.form.value.min,
      max: this.form.value.max,
    });
  }
}
