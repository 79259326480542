<div>
  <app-livesearch-select
    [value]="value"
    [placeholder]="placeholder"
    [list]="filterSuggestions"
    [isNumber]="isNumber"
    (selectEvent)="select($event)"
    (searchEvent)="search($event)"
  >
  </app-livesearch-select>

  <div class="d-flex justify-content-end">
    <button class="btn btn-dc-primary" (click)="apply()">Применить</button>
  </div>
</div>
