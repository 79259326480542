export function createParamString(...params: [string, any][]): string {
  const result: string[] = [];
  for (const [name, value] of params) {
    if (Array.isArray(value)) {
      for (const arrayItem of value) {
        const arrayItemValue = arrayItem?.toString();
        if (arrayItemValue !== undefined) {
          result.push(`${name}=${encodeURIComponent(arrayItemValue)}`);
        }
      }
    } else {
      const stringValue = value?.toString();
      if (stringValue !== undefined) {
        result.push(`${name}=${encodeURIComponent(stringValue)}`);
      }
    }
  }
  return result.join('&');
}
