import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { ModalInjectorService } from '@app/modal-injector/services/modal-injector.service';
import { Subject } from 'rxjs';
import { SharedModule } from '@app/shared/shared.module';
import { ListModalInjector } from '@app/modal-injector/models/modal-injector.model';

@Component({
  selector: 'app-modal-injector',
  templateUrl: './modal-injector.component.html',
  styleUrls: [],
})
export class ModalInjectorComponent implements OnInit {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  viewModalInject: ListModalInjector[] = [];

  constructor(private modalInjectorService: ModalInjectorService) {}

  template = {
    get calendar_widget() {
      return {
        template: '<app-move-pin [id]="id"><app-calendar-widget [id]="id"></app-calendar-widget></app-move-pin>',
        imports: [SharedModule],
      };
    },
    get datepicker_widget() {
      return {
        template:
          '<app-move-pin [id]="id" [parent]="parent" [classForChildren]="classForChildren" [noPinAndMove]="true" [position]="\'down\'">' +
          '<app-calendar-widget [id]="id" [dpID]="dpID" [startValue]="startValue" [minDate]="minDate" [maxDate]="maxDate"></app-calendar-widget>' +
          '</app-move-pin>',
        imports: [SharedModule],
        alwaysTop: true,
      };
    },
    get timepicker_widget() {
      return {
        template:
          '<app-move-pin [id]="id" [parent]="parent" [classForChildren]="classForChildren" [noPinAndMove]="true" [position]="\'down\'" [margin]="margin">' +
          '<app-timepicker [id]="id" [dpID]="dpID" [startValue]="startValue"></app-timepicker>' +
          '</app-move-pin>',
        imports: [SharedModule],
        alwaysTop: true,
      };
    },
    get add_event_calendar() {
      return {
        template:
          '<app-add-event [id]="id" [(showModal)]="show" [day]="day" [hour]="hour" [eventModel]="eventModel" [copy]="copy" [isShowed]="show"></app-add-event>',
        imports: [SharedModule],
        blockCloseWindow: true,
      };
    },
    get info_for_day() {
      return {
        template:
          '<app-move-pin [id]="id" [parent]="parent" [resize]="true" [widthPx]="520" [heightPx]="335" [position]="position">' +
          '<app-info-for-day [modalId]="id" [day]="day" [dayForDatepicker]="dayForDatepicker"></app-info-for-day>' +
          '</app-move-pin>',
        imports: [SharedModule],
      };
    },
    get show_event_calendar() {
      return {
        template:
          '<app-move-pin [id]="id" [parent]="parent" [classForChildren]="classForChildren" [color]="color" [resize]="true" [widthPx]="520" [heightPx]="335" [mlPinIcon]="15">' +
          '<app-info-event [modalId]="id" [event_id]="event_id" (colorEvent)="color = $event"></app-info-event>' +
          '</app-move-pin>',
        imports: [SharedModule],
      };
    },
    get select_date() {
      return {
        template:
          '<app-select-date [id]="id" [parent]="parent" [type]="type" [countWeek]="countWeek" [parentId]="parentId"></app-select-date>',
        imports: [SharedModule],
        blockCloseWindow: true,
      };
    },
    get delete_event() {
      return {
        template:
          '<app-modal-window #modalAddEvent [idModalInjector]="id" [customClass]="\'modal_delete_event\'" [backdrop]="\'static\'" [isShowed]="show" [centered]="true">' +
          '<app-delete-event [id]="id" [event]="event" [(showModal)]="show"></app-delete-event>' +
          '</app-modal-window>',
        imports: [SharedModule],
        blockCloseWindow: true,
      };
    },
  };

  ngOnInit() {
    this.modalInjectorService.modalInject$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((action) => {
      this.viewModalInject = action;
      this.viewModalInject.forEach((item, index) => {
        if (!item.template) {
          this.viewModalInject[index] = {
            ...item,
            ...this.template[item.name_temp],
          };
        }
      });
    });
  }
}
