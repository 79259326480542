<div class="bar-container inline middle center space-between">
  <div class="menu">
    <img src="assets/img/logo_h50px.png" />
    <div class="menu-button menu-time">
      <app-clock-widget [menuCollapsed]="false"></app-clock-widget>
    </div>
    <div class="menu-button menu-date" (click)="onCalendarWidgetOpen()">
      <div class="menu-date-number">
        <h1>{{ day }}</h1>
      </div>
      <div class="menu-date-month" style="width: max-content">
        <span>{{ month }}</span>
        <br />
        <span>{{ dayOfWeek }}</span>
      </div>
    </div>
  </div>

  <div class="user-toolbar">
    <div class="toolbar-controls">
      <div class="toolbar-control" title="Тех. поддержка">
        <a class="inline center middle color1font">
          <app-svg-icon name="life_ring" height="16" width="16"></app-svg-icon>
          <div class="num" [ngClass]="{ 'zero-value': supportMessages === 0 }">{{ supportMessages }}</div>
        </a>
      </div>

      <div class="toolbar-control" title="Чат">
        <a class="inline center middle color1font" (click)="onChatOpen()">
          <app-svg-icon name="envelope" height="16" width="16"></app-svg-icon>
          <div class="num" [ngClass]="{ 'zero-value': unreadMessages === 0 }">{{ unreadMessages }}</div>
        </a>
      </div>

      <div
        title="Уведомления"
        class="toolbar-control"
        [ngClass]="{
          disabled:
            (user?.type === rolesEnum.ADMIN_OF_DIRECTION || user?.type === rolesEnum.OPERATOR) && need_set_password
        }"
      >
        <a class="inline center middle color1font" (click)="onNotificationOpen()">
          <app-svg-icon name="bell" height="16" width="16"></app-svg-icon>
          <div class="num" [ngClass]="{ 'zero-value': todayEventsCount === 0 }">{{ todayEventsCount }}</div>
        </a>
      </div>
    </div>

    <div class="toolbar-notifications">
      <div class="toolbar-notification" title="Краткий обзор системы (видео)">
        <a class="">
          <app-svg-icon name="film" height="16" width="16"></app-svg-icon>
        </a>
      </div>
      <app-faq-tooltip tooltipName="personal_data__header-2"></app-faq-tooltip>
    </div>

    <div class="toolbar-userinfo">
      <div *ngIf="user" class="user-info">
        <div *ngIf="isSelfPayer" class="user-info-self-payer">
          <app-svg-icon name="ruble" width="16" height="16"></app-svg-icon>
        </div>

        <div class="user-info-status">
          <div [ngClass]="userStatus$ | async"></div>
        </div>

        <div class="user-info-id">ID-{{ user.id }}</div>

        <div class="user-info-name">{{ shortName || ' ' }}</div>

        <div class="user-info-role">{{ user.type | titleFrom : ROLES }}</div>

        <div class="user-info-type">
          <ng-container *ngIf="user.type !== rolesEnum.PARTNER; else PARTNER_USER">
            <ng-container
              *ngIf="
                user.type === rolesEnum.SUPERUSER ||
                user.type === rolesEnum.ACCOUNTANT ||
                user.type === rolesEnum.EXPERT
              "
            >
              ПК
            </ng-container>

            <ng-container *ngIf="user.type === rolesEnum.ADMIN_OF_USER || user.type === rolesEnum.OPERATOR">
              <ng-container *ngIf="user.company.legal_status === legalStatusesEnum.NATURAL_PERSON"
                >Физ. лицо</ng-container
              >
              <ng-container *ngIf="user.company.legal_status !== legalStatusesEnum.NATURAL_PERSON">
                <ng-container *ngIf="user.company.legal_status === legalStatusesEnum.INDIVIDUAL_ENTREPRENEUR"
                  >ИП</ng-container
                >
                <ng-container *ngIf="user.company.legal_status !== legalStatusesEnum.INDIVIDUAL_ENTREPRENEUR">
                  {{ user.company?.organization_type }} {{ user.company?.organization_name }}
                </ng-container>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="user.type === rolesEnum.ADMIN_OF_DIRECTION">
              <ng-container *ngIf="isCanBePayer">
                <ng-container *ngIf="user.company.legal_status === legalStatusesEnum.NATURAL_PERSON"
                  >Физ. лицо</ng-container
                >
                <ng-container *ngIf="user.company.legal_status !== legalStatusesEnum.NATURAL_PERSON">
                  <ng-container *ngIf="user.company.legal_status === legalStatusesEnum.INDIVIDUAL_ENTREPRENEUR"
                    >ИП</ng-container
                  >
                  <ng-container *ngIf="user.company.legal_status !== legalStatusesEnum.INDIVIDUAL_ENTREPRENEUR">
                    {{ user.company?.organization_type }} {{ user.company?.organization_name }}
                  </ng-container>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="!isCanBePayer">
                <ng-container *ngIf="parentUser.company.legal_status === legalStatusesEnum.NATURAL_PERSON">
                  Физ. лицо {{ parentUser | initials }}
                </ng-container>
                <ng-container *ngIf="parentUser.company.legal_status !== legalStatusesEnum.NATURAL_PERSON">
                  <ng-container *ngIf="parentUser.company.legal_status === legalStatusesEnum.INDIVIDUAL_ENTREPRENEUR">
                    ИП {{ parentUser | initials }}
                  </ng-container>
                  <ng-container *ngIf="parentUser.company.legal_status !== legalStatusesEnum.INDIVIDUAL_ENTREPRENEUR">
                    {{ parentUser.company?.organization_type }} {{ parentUser.company?.organization_name }}
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-template #PARTNER_USER>ПК</ng-template>
        </div>
      </div>
    </div>

    <div class="toolbar-logout" title="Выход из системы">
      <app-svg-icon class="pointer" name="sign-out" height="16" width="16" (click)="onLogout()">></app-svg-icon>
    </div>
  </div>
</div>
