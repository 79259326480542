<div class="dc-modal-x-button dc-fa-button" (click)="close()">
  <app-svg-icon [height]="20" [width]="20" name="close"></app-svg-icon>
</div>
<div class="modal-window-content">
  <div class="modal-header">
    <div class="title text-left">Вы действительно хотите изменить пароль?</div>
  </div>

  <div class="modal-body">Новый пароль будет выслан Вам на электронный адрес {{ currentEmail }}</div>

  <div class="modal-footer">
    <button type="button" class="btn btn-dc-outline" (click)="close()">Нет, отменить</button>
    <button type="button" class="btn btn-dc-primary" [disabled]="submit" (click)="success()">Да, изменить</button>
  </div>
</div>
