<app-file-manager-search
  placeholder="Начните вводить название товара или услуги"
  (onSearch)="searchItems($event)"
></app-file-manager-search>

<div class="select-all margin-t-8">
  <input
    [checked]="selectedAll"
    [id]="'selected_all_' + type"
    (change)="selectAll(items, $event)"
    class="cbx-input"
    type="checkbox"
  />
  <label class="cbx-label" [for]="'selected_all_' + type">
    <span class="margin-l-10">Выделить всё/снять выделение</span>
  </label>
</div>

<div class="expand-all margin-l-26">
  <button class="dc-icon-button" (click)="toggleByLevel(itemsTree, 1)">
    <app-svg-icon [name]="!isTreeExpanded1 ? 'caret_down' : 'caret_up'" [width]="10" [height]="6"></app-svg-icon>
  </button>
  <button class="dc-icon-button" (click)="toggleByLevel(itemsTree, 2)">
    <app-svg-icon [name]="!isTreeExpanded1 ? 'caret_down' : 'caret_up'" [width]="10" [height]="6"></app-svg-icon>
  </button>
</div>

<div class="filter-tree">
  <tree-root #itemsTree [nodes]="items" [options]="treeOptions">
    <ng-template #treeNodeTemplate let-node>
      <app-file-manager-share-user-filter-tree-item
        [node]="node"
        [item]="node.data"
        [type]="type"
        (onToggle)="expandItem(itemsTree, node, $event)"
        (onSelect)="selectItem($event)"
      >
      </app-file-manager-share-user-filter-tree-item>
    </ng-template>
  </tree-root>
</div>
