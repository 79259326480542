import { FileExtInterface } from '../models/file-ext.interface';

// не работает - ods, odp, rtf

export const DEFAULT_FILE_EXT: FileExtInterface = {
  hasImage: false,
  hasPdf: false,
  hasDoc: false,
  hasTable: false,
  hasCsv: false,
  hasOdt: false,
  hasOds: false,
  hasOdp: false,
  hasPpt: false,
  hasRtf: false,
  ext: null,
  icon: 'file-any',
};
export const FILE_EXT_LIST: FileExtInterface[] = [
  {
    hasPdf: true,
    ext: ['pdf', 'PDF'],
    icon: 'file-pdf',
  },
  {
    hasRtf: true,
    ext: ['rtf', 'RTF'],
    icon: 'file-word',
  },
  {
    hasDoc: true,
    ext: ['docs', 'docx', 'docm', 'dotx', 'doc', 'DOCS', 'DOCX', 'DOCM', 'DOTX', 'DOC'],
    icon: 'file-word',
  },
  {
    hasTxt: true,
    ext: ['txt', 'TXT'],
    icon: 'file-txt',
  },
  {
    hasPpt: true,
    ext: ['ppt', 'pptx', 'pps', 'ppsx', 'pot', 'potx', 'PPT', 'PPTX', 'PPS', 'PPSX', 'POT', 'POTX'],
    icon: 'file-powerpoint',
  },
  {
    hasImage: true,
    ext: ['jpg', 'jpeg', 'png', 'bmp', 'gif', 'JPG', 'JPEG', 'PNG', 'BMP', 'GIF'],
    icon: 'file-image',
  },
  {
    hasTable: true,
    ext: ['xlsx', 'xls', 'XLSX', 'XLS'],
    icon: 'file-excel',
  },
  {
    hasCsv: true,
    ext: ['csv', 'CSV'],
    icon: 'file-excel',
  },
  {
    hasOdt: true,
    ext: ['odt', 'ODT'],
    icon: 'file-odt',
  },
  {
    hasOds: true,
    ext: ['ods', 'ODS'],
    icon: 'file-ods',
  },
  {
    hasOdp: true,
    ext: ['odp', 'ODP'],
    icon: 'file-odp',
  },
];
