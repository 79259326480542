import { Pipe, PipeTransform } from '@angular/core';
import { Company } from '@app/shared/models/company.model';

@Pipe({
  name: 'companyAuthorizedPerson',
})
export class CompanyAuthorizedPersonPipe implements PipeTransform {
  transform(value: Company): any {
    if (!value) {
      return '';
    }

    if (!value.authorized_person_second_name && value.authorized_person_first_name) {
      return '-';
    }

    return `${value.authorized_person_second_name || ''} ${value.authorized_person_first_name || ''} ${
      value.authorized_person_patronymic || ''
    }`;
  }
}
