import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SHARE_TYPE } from '../../file_manager.constants';
import { ShareUsersFilter } from '../../models/share-users-form.model';
import { DCTreeItem } from '@app/+competence-map/models/competence-map.models';
import { CMCatalogTypes, CMSectionStatuses } from '@app/+competence-map/constants/sections.constants';
import { takeUntil } from 'rxjs/operators';
import { CompetenceService } from '@app/+competence-map/services/competence.service';
import { CompetenceSectionsService } from '@app/+competence-map/services/competence-sections.service';
import { ShareUsersFilterRole } from '@app/file_manager/models/share-users-form.model';
import { TreeHelper } from '@app/shared/helpers/tree.helper';
import { FileManagerService } from '@app/file_manager/services/file_manager.service';
import { DestroyService } from '@app/services/destroy.service';
import { Section } from '@app/+competence-map/models/sections.model';

@Component({
  selector: 'app-share-filter',
  templateUrl: './share-filter.component.html',
  styleUrls: ['./share-filter.component.scss'],
  providers: [DestroyService],
})
export class ShareFilterComponent {
  @Input() filter: ShareUsersFilter;
  @Input() _isOpen: boolean;
  @Input() type: SHARE_TYPE;

  @Output() onApply = new EventEmitter();
  @Output() onCancel = new EventEmitter();

  goods: DCTreeItem[];
  services: DCTreeItem[];
  selectedAllRoles: boolean = false;
  active: number = 0;
  selectedGoods: [];
  selectedServices: [];
  filterType = CMCatalogTypes;

  constructor(
    private competenceService: CompetenceService,
    private competenceSectionsService: CompetenceSectionsService,
    private fileManagerService: FileManagerService,
    private destroy$: DestroyService
  ) {}

  @Input() set isOpen(value: boolean) {
    this._isOpen = value;
    if (this._isOpen) {
      this.selectedGoods = [];
      this.selectedServices = [];
      this.active = 0;
      this.fetch();
    }
  }

  fetch(): void {
    this.competenceSectionsService
      .getSections([
        {
          name: 'catalog',
          op: 'in_',
          val: [CMCatalogTypes.GOODS, CMCatalogTypes.SERVICES],
        },
        {
          name: 'status',
          op: 'in_',
          val: [CMSectionStatuses.ACTIVE],
        },
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((sections: Section[]) => {
        const goods = sections
          .filter((item) => item.catalog === CMCatalogTypes.GOODS)
          .map((item) => {
            return {
              ...item,
              parent: {
                id: item.parent_id,
              },
            };
          });
        const services = sections
          .filter((item) => item.catalog === CMCatalogTypes.SERVICES)
          .map((item) => {
            return {
              ...item,
              parent: {
                id: item.parent_id,
              },
            };
          });
        this.goods = TreeHelper.arrayToTree(goods);
        this.services = TreeHelper.arrayToTree(services);
      });
  }

  apply(): void {
    this.mergeCompetencies();
    this.onApply.emit();
  }

  cancel(): void {
    this.onCancel.emit();
  }

  mergeCompetencies(): void {
    this.filter.competenciesSelected = [...this.selectedGoods, ...this.selectedServices];
  }

  getSelectedCountRoles(): number {
    return this.filter.roles.filter((role: ShareUsersFilterRole) => role.checked).length;
  }

  openHelper(): void {
    this.fileManagerService.openHelper();
  }

  getSelectedCount(items: DCTreeItem[]): number {
    if (!items) {
      return 0;
    }
    const entities = TreeHelper.treeToArray(items);
    const count = entities.filter((item) => {
      return item.selected;
    });
    return count.length;
  }

  selectAllRoles(event): void {
    this.filter.roles.forEach((role: ShareUsersFilterRole) => {
      role.checked = event.target.checked;
    });
  }
}
