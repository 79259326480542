import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Column } from '@app/shared/models/table';

@Component({
  selector: 'app-table-header-cell',
  templateUrl: './table-header-cell.component.html',
  styleUrls: ['./table-header-cell.component.scss'],
})
export class TableHeaderCellComponent {
  @Input() column: Column<any>;

  @Output() filterEvent = new EventEmitter();
  @Output() clearEvent = new EventEmitter();

  clickFilterIcon() {
    this.filterEvent.emit();
  }

  clickFilterClearIcon() {
    this.clearEvent.emit();
  }
}
