<div class="container-fluid">
  <div class="row">
    <div class="col-4">
      <div class="file-tree-title">Мои файлы</div>
      <app-file-manager-tree
        *ngIf="getRootDir().opened"
        [files]="getRootDir().children"
        (onOpen)="openDir($event)"
        [current]="CURRENT_DIR"
      ></app-file-manager-tree>

      <div class="file-tree-title">Файлы с открытым доступом</div>
      <app-file-manager-tree
        *ngIf="getRootSharedDir().opened"
        [files]="getRootSharedDir().children"
        (onOpen)="openDir($event)"
        [current]="CURRENT_DIR"
      ></app-file-manager-tree>
    </div>
    <div class="col-8">
      <app-file-manager-area
        [selected]="selected"
        [current]="CURRENT_DIR"
        [view]="1"
        [multipleSelection]="multipleSelection"
        (onOpen)="setCurrentDir($event)"
        (onBack)="goBack()"
        [layout]="layout"
        (onSelected)="selectFile($event)"
      ></app-file-manager-area>
    </div>
  </div>
</div>
