import { FaqQuestionStatus, FaqTabs } from '../enums/faq.common.enum';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { RolesEnum } from './roles.constants';

export const FAQ_STATUSES = [
  { value: FaqQuestionStatus.Approved, label: 'Актив' },
  { value: FaqQuestionStatus.OnVerification, label: 'Согласовать' },
  { value: FaqQuestionStatus.InDevelop, label: 'В работе' },
  { value: FaqQuestionStatus.Archived, label: 'Архив' },
];

export const FAQ_STATUSES_COLORS = {
  [FaqQuestionStatus.Archived]: '#F44336',
  [FaqQuestionStatus.OnVerification]: '#E9E00F',
  [FaqQuestionStatus.Approved]: '#8BC34A',
  [FaqQuestionStatus.InDevelop]: '#C1C1C1',
};

export const FAQ_NOTIFICATION_TITLE = {
  SUCCESS: 'Успешно!',
  WARN: 'Внимание!',
};
export const FAQ_NOTIFICATIONS = {
  MODULE_CREATED: 'Модуль успешно создан!',
  MODULE_EXIST: 'Такой модуль уже существует!',
  CATEGORY_CREATED: 'Раздел вопросов успешно создан.',
  CATEGORY_EXIST: 'Такой вопрос уже существует!',
  CATEGORY_UPDATED: 'Вопрос успешно обновлен!',
  HINT_CREATED: 'Примечание успешно создано!',
  HINT_UPDATED: 'Примечание успешно обновлено!',
  MODULES_SORTED: 'Модули успешно отсортированы!',
  CATEGORIES_SORTED: 'Порядок вопросов успешно изменен.',
  STATUS_CHANGED: 'Статус успешно изменен!',
  PERMISSIONS_UPDATED: 'Права успешно обновлены!',
};

export const FaqModalBaseOptions: NgbModalOptions = {
  centered: true,
  backdropClass: 'creating-modal-bg',
  backdrop: 'static',
};

export const FaqApiMethods = {
  getItems: {
    [FaqTabs.Questions]: 'getCategories',
    [FaqTabs.Modules]: 'getModules',
  },
  getPermissions: {
    [FaqTabs.Questions]: 'getFaqPermissions',
    [FaqTabs.Modules]: 'getHintPermissions',
  },
};

export const FaqStatusStates = {
  [FaqTabs.Modules]: {
    [RolesEnum.SUPERUSER]: {
      [FaqQuestionStatus.InDevelop]: [FaqQuestionStatus.Approved, FaqQuestionStatus.OnVerification],
      [FaqQuestionStatus.OnVerification]: [FaqQuestionStatus.InDevelop, FaqQuestionStatus.Approved],
    },
    [RolesEnum.EXPERT]: {
      [FaqQuestionStatus.InDevelop]: [FaqQuestionStatus.OnVerification],
      [FaqQuestionStatus.OnVerification]: [FaqQuestionStatus.InDevelop],
    },
  },
  [FaqTabs.Questions]: {
    [RolesEnum.SUPERUSER]: {
      [FaqQuestionStatus.InDevelop]: [
        FaqQuestionStatus.Approved,
        FaqQuestionStatus.OnVerification,
        FaqQuestionStatus.Archived,
      ],
      [FaqQuestionStatus.OnVerification]: [
        FaqQuestionStatus.InDevelop,
        FaqQuestionStatus.Archived,
        FaqQuestionStatus.Approved,
      ],
      [FaqQuestionStatus.Archived]: [FaqQuestionStatus.InDevelop, FaqQuestionStatus.Approved],
      [FaqQuestionStatus.Approved]: [FaqQuestionStatus.InDevelop, FaqQuestionStatus.Archived],
    },
    [RolesEnum.EXPERT]: {
      [FaqQuestionStatus.InDevelop]: [FaqQuestionStatus.OnVerification],
      [FaqQuestionStatus.OnVerification]: [FaqQuestionStatus.InDevelop],
    },
  },
};

export const FaqStatusOrder = {
  [FaqQuestionStatus.Archived]: 1,
  [FaqQuestionStatus.InDevelop]: 2,
  [FaqQuestionStatus.OnVerification]: 3,
  [FaqQuestionStatus.Approved]: 4,
};
