import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, throwError, Subject } from 'rxjs';
import { ShareUsersRequestConfig, UserFile, UserFileZip, UserFileZipInterface } from '../models/user-file.model';
import { environment } from '@app/environments/environment';
import { catchError } from 'rxjs/operators';
import { map, pluck } from 'rxjs/internal/operators';
import { SHARE_LIST_PER_PAGE, SHARE_TYPE } from '../file_manager.constants';
import { AuthorFile } from '../models/author-file.model';
import { TradeFilesAddParams, TradeFilesDeleteParams, TradeFilesUpdateParams } from '@app/+trades/models/trades.model';

@Injectable({
  providedIn: 'root',
})
export class FileManagerService {
  private openFile$: Subject<UserFile> = new Subject();
  public openHelper$: Subject<boolean> = new Subject<boolean>();

  constructor(private $http: HttpClient) {}

  openFile(file: UserFile): void {
    this.openFile$.next(file);
  }

  openHelper(): void {
    this.openHelper$.next(true);
  }

  onOpenFile(): Observable<UserFile> {
    return this.openFile$;
  }

  sort(a: UserFile, b: UserFile) {
    if (!a.hasFile() && b.hasFile()) {
      return -1;
    } else if (a.hasFile() && !b.hasFile()) {
      return 1;
    } else if (!a.hasFile() && !b.hasFile()) {
      return this.sortNumberString(a, b);
    } else if (a.hasFile() && b.hasFile()) {
      return this.sortNumberString(a, b);
    }
    return 0;
  }

  /**
   *
   * @param {UserFile} a
   * @param {UserFile} b
   * @param {boolean} files
   * @returns {number}
   */
  private sortNumberString(a: UserFile, b: UserFile): number {
    if (a.basename().search(/^[0-9]+$/i) > -1 && b.basename().search(/^[0-9]+$/i) === -1) {
      return -1;
    } else if (a.basename().search(/^[0-9]+$/i) === -1 && b.basename().search(/^[0-9]+$/i) > -1) {
      return 1;
    } else if (a.basename().search(/^[0-9]+$/i) > -1 && b.basename().search(/^[0-9]+$/i) > -1) {
      const a_number = parseInt(a.basename(), 10);
      const b_number = parseInt(b.basename(), 10);
      if (a_number > b_number) {
        return 1;
      } else if (a_number < b_number) {
        return -1;
      }
    } else {
      if (a.basename() > b.basename()) {
        return 1;
      } else if (a.basename() < b.basename()) {
        return -1;
      }
    }
    return 0;
  }

  getZips(user_id: number): Observable<UserFileZip[]> {
    return this.$http.get(`${environment.api_url}/user/${user_id}/files/zips`).pipe(
      map((data: UserFileZipInterface[]) =>
        data.map((_data: UserFileZipInterface) => new UserFileZip(Object.assign(_data)))
      ),
      catchError((err) => of([]))
    );
  }

  getFilesRoot(user_id: number, parent?: number, share?: boolean): Observable<UserFile[]> {
    return this.$http.get(`${environment.api_url}/user/${user_id}/files/root?destination=own`).pipe(
      map((data: any) =>
        data.map((_data) => new UserFile(Object.assign(_data, { parent: parent }))).sort(this.sort.bind(this))
      ),
      catchError((err) => throwError(err))
    );
  }

  getFilesSharedRoot(user_id: number, parent?: number): Observable<UserFile[]> {
    return this.$http.get(`${environment.api_url}/user/${user_id}/files/root?destination=shared`).pipe(
      map((data: any) =>
        [].concat(
          data.map((_data) => new UserFile(Object.assign(_data, { parent: parent }), true)).sort(this.sort.bind(this))
        )
      ),
      catchError((err) => throwError(err))
    );
  }

  getFilesDir(file_id: number, parent?: number, share?: boolean): Observable<UserFile[]> {
    return this.$http.get(`${environment.api_url}/file/${file_id}/children`).pipe(
      map((data: any) =>
        data
          .map((_data) => new UserFile(Object.assign(_data, { parent: parent }), share === true))
          .sort(this.sort.bind(this))
      ),
      catchError((err) => throwError(err))
    );
  }

  addDirRoot(user_id: number, name: string) {
    return this.$http
      .post(`${environment.api_url}/user/${user_id}/files/root`, {
        name: name,
      })
      .pipe(
        map((data: any) => new UserFile(data)),
        catchError((err) => throwError(err))
      );
  }

  addDir(file_id: number, name: string) {
    return this.$http
      .post(`${environment.api_url}/file/${file_id}`, {
        name: name,
      })
      .pipe(
        map((data: any) => new UserFile(data)),
        catchError((err) => throwError(err))
      );
  }

  delete(file_id: number) {
    return this.$http.delete(`${environment.api_url}/file/${file_id}`, {}).pipe(catchError((err) => throwError(err)));
  }

  search(user_id: number, search: string, own?: boolean) {
    return this.$http
      .get(
        `${environment.api_url}/user/${user_id}/files/search?q=${encodeURI(search)}
        ${own ? (own === true ? '&destination=own' : '&destination=shared') : ''}`
      )
      .pipe(
        map((data: any) => data.map((_data) => new UserFile(_data, own !== true)).sort(this.sort.bind(this))),
        catchError((err) => throwError(err))
      );
  }

  getDisallowUsers(file_id: number, query?: string, user_role?: string) {
    query = query || null;
    user_role = user_role || null;
    return this.$http
      .get(`${environment.api_url}/file/${file_id}/share`, {
        params: {
          allowance: 'disallow',
          query: query,
          user_role: user_role,
        },
      })
      .pipe(
        pluck('items'),
        catchError((err) => throwError(err))
      );
  }

  getShareUsers(
    params: ShareUsersRequestConfig = <ShareUsersRequestConfig>{
      page: 1,
      per_page: SHARE_LIST_PER_PAGE,
    }
  ): Observable<AuthorFile[]> {
    return this.$http
      .get(`${environment.api_url}/file/${params.file_id}/share`, {
        params: Object.assign(
          params.query ? { query: params.query } : {},
          params.user_role ? { user_role: params.user_role } : {},
          { allowance: params.type === SHARE_TYPE.ALLOW ? 'allow' : 'disallow' },
          params.per_page || params.page
            ? { page: params.page || 1, per_page: params.per_page || SHARE_LIST_PER_PAGE }
            : {},
          params.competencies ? { section_ids: params.competencies } : {}
        ),
      })
      .pipe(
        map((data: any) => ({
          ...data,
          items: data.items.map((item) => new AuthorFile(item)),
        })),
        catchError(() => throwError([]))
      );
  }

  legend(user_id: number): Observable<any> {
    return this.$http.get(`${environment.api_url}/user/${user_id}/files/days_download`).pipe(
      map((data: any) => data.map((_data) => new UserFile(_data))),
      catchError(() => throwError([]))
    );
  }

  share(file_id: number, type: 'all' | 'some', ids?: number[]) {
    return this.$http
      .post(`${environment.api_url}/file/${file_id}/share`, {
        type,
        user_ids: ids,
      })
      .pipe(catchError((err) => throwError(err)));
  }

  unshare(file_id: number, type: 'all' | 'some', ids?: number[]) {
    return this.$http
      .post(`${environment.api_url}/file/${file_id}/unshare`, {
        type,
        user_ids: ids,
      })
      .pipe(catchError((err) => throwError(err)));
  }

  editFile(file_id: number, name: string) {
    return this.$http
      .put(`${environment.api_url}/file/${file_id}`, {
        new_name: name,
      })
      .pipe(
        map((data: any) => new UserFile(data)),
        catchError((err) => throwError(err))
      );
  }

  copy(file_id: number, destination_id: number) {
    return this.$http
      .post(`${environment.api_url}/file/${file_id}/copy`, {
        destination_id: destination_id,
      })
      .pipe(
        map((data: any) => new UserFile(data)),
        catchError((err) => throwError(err))
      );
  }

  move(file_id: number, destination_id: number) {
    return this.$http
      .post(`${environment.api_url}/file/${file_id}/move`, {
        destination_id: destination_id,
      })
      .pipe(
        map((data: any) => new UserFile(data)),
        catchError((err) => throwError(err))
      );
  }

  uploadFileRoot(file: any, user_id: number): Observable<any> {
    const data: FormData = new FormData();
    data.append('file', file, file.name);

    const headers: HttpHeaders = new HttpHeaders()
      .set('Enctype', 'multipart/form-data')
      .set('Accept', 'application/json');

    return this.$http
      .post(`${environment.api_url}/user/${user_id}/files/root/add_file`, data, {
        headers: headers,
        reportProgress: true,
        observe: 'events',
      })
      .pipe(catchError((err) => throwError(err)));
  }

  uploadFile(file: any, file_id: number): Observable<any> {
    const data: FormData = new FormData();
    data.append('file', file, file.name);

    const headers: HttpHeaders = new HttpHeaders()
      .set('Enctype', 'multipart/form-data')
      .set('Accept', 'application/json');

    return this.$http
      .post(`${environment.api_url}/file/${file_id}/add_file`, data, {
        headers: headers,
        reportProgress: true,
        observe: 'events',
      })
      .pipe(catchError((err) => throwError(err)));
  }

  downloadFiles(file_ids: number[], user_id: number): Observable<any> {
    return this.$http
      .post(`${environment.api_url}/user/${user_id}/files/download`, {
        file_ids: file_ids,
      })
      .pipe(pluck('name'));
  }

  downloadFile(url: string) {
    return this.$http
      .get(`${url}`, {
        responseType: 'blob',
        params: {
          disposition: 'attachment',
        },
      })
      .pipe(catchError((err) => throwError(err)));
  }

  space(user_id: number) {
    return this.$http.get(`${environment.api_url}/user/${user_id}/files/space`).pipe(
      map((data: any) => {
        data.percentUsed = this.formatPercentUsed(data.limit, data.used);
        data.used = this.formatBytes(data.used);
        data.limit = this.formatBytes(data.limit);
        data.remaining = this.formatBytes(data.remaining);
        return data;
      }),
      catchError((err) => throwError(err))
    );
  }

  private formatPercentUsed(limit: number, used: number): number {
    return Math.round((100 * used) / limit);
  }

  private formatBytes(bytes: number, decimals?: number) {
    if (bytes === 0) return '0 МБ';
    const k = 1024,
      dm = decimals || 0,
      sizes = ['Байт', 'КБ', 'МБ', 'ГБ', 'ТБ'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  uploadTradeLogo(tradeId: number, file: File) {
    if (!file) {
      return of(null);
    }
    const data: FormData = new FormData();
    data.append('file', file, file.name);

    const headers: HttpHeaders = new HttpHeaders()
      .set('Enctype', 'multipart/form-data')
      .set('Accept', 'application/json');

    return this.$http
      .post(`${environment.api_url}/file/logos/${tradeId}/`, data, {
        headers: headers,
      })
      .pipe(catchError((err) => throwError(err)));
  }

  getTradeLogoUrl(tradeId: number, logoFile: Blob): string {
    return logoFile.size > 5 ? `${environment.api_url}/file/logos/${tradeId}/?width=300&height=148` : null;
  }

  getTradeLogoFile(tradeId: number): Observable<Blob> {
    return this.$http
      .get(`${environment.api_url}/file/logos/${tradeId}/`, {
        responseType: 'blob',
        params: {
          width: '300',
          height: '148',
        },
      })
      .pipe(
        map((logo) => {
          return logo;
        }),
        catchError((err) => throwError(err))
      );
  }

  deleteTradeLogo(tradeId: number) {
    return this.$http
      .delete(`${environment.api_url}/file/logos/${tradeId}/`)
      .pipe(catchError((err) => throwError(err)));
  }

  getTradeFiles(tradeId: number): Observable<UserFile[]> {
    return this.$http.get(`${environment.api_url}/trade/${tradeId}/files`).pipe(
      map((documents: UserFile[]) => documents),
      catchError((err) => throwError(err))
    );
  }

  addTradeFiles(tradeId: number, data: TradeFilesAddParams) {
    return this.$http
      .post(`${environment.api_url}/trade/${tradeId}/files`, data)
      .pipe(catchError((err) => throwError(err)));
  }

  updateTradeFiles(tradeId: number, data: TradeFilesUpdateParams) {
    return this.$http
      .put(`${environment.api_url}/trade/${tradeId}/files`, data)
      .pipe(catchError((err) => throwError(err)));
  }

  deleteTradeFiles(tradeId: number, data: TradeFilesDeleteParams) {
    const httpParams = new HttpParams();
    Object.keys(data).forEach((key) => {
      httpParams.set(key, data[key]);
    });

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: data,
    };

    return this.$http
      .delete(`${environment.api_url}/trade/${tradeId}/files`, options)
      .pipe(catchError((err) => throwError(err)));
  }
}
