import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { Observable, of, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { SVG_SPRITE_CONFIG } from '../constants/svg-sprite.config';
import { SVGSpriteConfig } from '../types/svg-sprite-config.type';
import { SVGSpriteColorMode } from '../types/svg-sprite-color-mode.type';

@Injectable()
export class SVGSpriteLoaderService {
  public icons: string[] = [];
  private loadQueue: Record<string, Observable<boolean>> = {};
  private sprite: Element;

  constructor(
    private httpClient: HttpClient,
    @Inject(SVG_SPRITE_CONFIG) private cfg: SVGSpriteConfig,
    @Inject(DOCUMENT) private doc: Document
  ) {
    this.initContainer();
  }

  public initContainer(): void {
    const hasContainer = this.doc.getElementById(this.cfg.spriteContainer);
    if (!this.sprite && !hasContainer) {
      const container = this.doc.createElement('div');
      this.sprite = this.doc.createElementNS('http://www.w3.org/2000/svg', 'svg');
      container.style.display = 'none';
      container.id = this.cfg.spriteContainer;
      container.append(this.sprite);
      this.doc.body.append(container);
    } else if (hasContainer) {
      this.sprite = hasContainer.getElementsByTagNameNS('http://www.w3.org/2000/svg', 'svg')[0];
    }
  }

  public getIcon(name: string): Observable<boolean> {
    const iconName = this.cfg.spriteIconPrefix + name;
    if (this.icons.indexOf(iconName) !== -1) {
      return of(true);
    } else {
      if (this.loadQueue[iconName]) {
        return this.loadQueue[iconName];
      }
      this.loadQueue[iconName] = this.loadIcon(name, 'icon');
      return this.loadQueue[iconName];
    }
  }

  public getFile(name: string): Observable<boolean> {
    const fileName = this.cfg.spriteIconPrefix + name;
    if (this.icons.indexOf(fileName) !== -1) {
      return of(true);
    } else {
      if (this.loadQueue[fileName]) {
        return this.loadQueue[fileName];
      }
      this.loadQueue[fileName] = this.loadIcon(name, 'file', 'inherit');
      return this.loadQueue[fileName];
    }
  }

  public loadIcon(
    name: string,
    type: 'icon' | 'file',
    colorMode: SVGSpriteColorMode = 'standart'
  ): Observable<boolean> {
    const isLoaded = new Subject<boolean>();

    if (!this.sprite) {
      this.initContainer();
    }

    const prefix = type === 'icon' ? this.cfg.spriteIconPrefix : this.cfg.spriteFilePrefix;
    const url = type === 'icon' ? this.cfg.getBaseSvgIconPath(name) : this.cfg.getBaseSvgFilePath(name);

    this.httpClient
      .get(url, { responseType: 'text' })
      .pipe(take(1))
      .subscribe(
        (data) => {
          const parser = new DOMParser();
          const tmp = parser.parseFromString(data, 'image/svg+xml');
          const svgElement = tmp.getElementsByTagName('svg')[0];
          if (svgElement) {
            const icon = this.createSVGSymbol(prefix + name, svgElement, colorMode);
            this.sprite.appendChild(icon);
            isLoaded.next(true);
            isLoaded.complete();
            this.icons.push(prefix + name);
            this.clearQueueByName(prefix + name);
          } else {
            isLoaded.next(false);
            isLoaded.complete();
            this.clearQueueByName(prefix + name);
            return;
          }
        },
        () => {
          isLoaded.next(false);
          isLoaded.complete();
          this.clearQueueByName(prefix + name);
        }
      );
    return isLoaded.asObservable();
  }

  private clearQueueByName(name: string): void {
    if (this.loadQueue[name]) {
      delete this.loadQueue[name];
    }
  }

  private createSVGSymbol(name: string, svg: SVGSVGElement, colorMode: SVGSpriteColorMode) {
    const icon = document.createElementNS('http://www.w3.org/2000/svg', 'symbol');
    const { viewBox } = svg;
    icon.setAttribute('id', name);
    icon.setAttribute(
      'viewBox',
      `${viewBox.baseVal.x} ${viewBox.baseVal.y} ${viewBox.baseVal.width} ${viewBox.baseVal.height}`
    );

    while (svg.childElementCount) {
      icon.appendChild(this.normalizeColor(svg.children[0], colorMode));
    }
    return icon;
  }

  private normalizeColor(part: SVGElement | Element, colorMode: SVGSpriteColorMode) {
    switch (colorMode) {
      case 'fill':
        return this.fillColorPrecessSVGElem(part);
      case 'standart':
        return this.standartColorPrecessSVGElem(part);
      case 'stroke':
        return this.strokeColorPrecessSVGElem(part);
      case 'inherit':
      default:
        return this.inheritColorPrecessSVGElem(part);
    }
  }

  private fillColorPrecessSVGElem(element: SVGElement | Element): SVGElement | Element {
    element.setAttribute('fill', 'currentColor');
    element.removeAttribute('stroke');
    return element;
  }

  private strokeColorPrecessSVGElem(element: SVGElement | Element): SVGElement | Element {
    element.setAttribute('stroke', 'currentColor');
    element.removeAttribute('fill');
    return element;
  }

  private standartColorPrecessSVGElem(element: SVGElement | Element): SVGElement | Element {
    if (element.hasAttribute('fill') && element.getAttribute('fill') !== 'none') {
      element.setAttribute('fill', 'currentColor');
    }
    if (element.hasAttribute('stroke') && element.getAttribute('stroke') !== 'none') {
      element.setAttribute('stroke', 'currentColor');
    }
    return element;
  }

  private inheritColorPrecessSVGElem(element: SVGElement | Element): SVGElement | Element {
    return element;
  }
}
