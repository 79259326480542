<div class="tile-container-icon" [ngClass]="{ 'tile-container-icon__folder': !file.hasFile() }">
  <app-svg-icon
    [width]="file.hasFile() ? 30 : 40"
    [height]="file.hasFile() ? 40 : 30"
    [name]="file.hasFile() ? file.fileExtType().icon : 'folder'"
  ></app-svg-icon>
</div>
<div class="tile-container-actions">
  <div
    class="tile-container-actions__check"
    [class.system-folder]="file.system"
    [class.active]="fileSelectedValue"
    (click)="$event.stopPropagation()"
  >
    <input
      class="cbx-input"
      [id]="file?.id"
      [(ngModel)]="fileSelectedValue"
      appTestLocator="area__file-{{ file?.id }}_checkbox"
      (change)="checkFile($event)"
      [disabled]="file?.virtualDisabled"
      type="checkbox"
    />
    <label class="cbx-label" [for]="file.id"></label>
  </div>
  <div *ngIf="file?.in_shared_dir" class="tile-container-actions__item">
    <app-svg-icon
      (click)="showUserInfo($event)"
      [width]="16"
      [height]="12.8"
      name="crown"
      appTestLocator="area__file-{{ file?.id }}_crown"
    ></app-svg-icon>
  </div>
  <div
    *ngIf="!file?.in_shared_dir && (file?.is_shared || file?.inner_sharing)"
    class="tile-container-actions__item"
    [class.disabled]="file?.inner_sharing && !file?.is_shared"
  >
    <app-svg-icon
      (click)="shareFile($event)"
      [width]="18"
      [height]="12.6"
      name="users"
      appTestLocator="area__file-{{ file?.id }}_share"
    ></app-svg-icon>
  </div>
</div>
<div class="tile-container-title" [ngbTooltip]="file.name" [disableTooltip]="file.name.length < 30">
  <span [innerHTML]="file.name | highlight: searchQuery"></span>
</div>
