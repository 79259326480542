import { Injectable } from '@angular/core';
import { AddLexiconQueryParams, AddLexiconsResponse, Lexicon } from '../models/words.model';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { environment } from '@app/environments/environment';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FlaskQueryFilter } from '@app/shared/models/filters.model';

@Injectable({
  providedIn: 'root',
})
export class WordsStorageService {
  constructor(private http: HttpClient) {}

  getWords(filter: FlaskQueryFilter[] = []): Observable<Lexicon[]> {
    const params = new HttpParams({
      fromObject: {
        filter: JSON.stringify(filter),
        sort: 'value',
      },
    });

    return this.http
      .get<Lexicon[]>(`${environment.api_url}/competency-map/lexicon`, {
        headers: {
          Accept: 'application/vnd.api+json',
        },
        params,
      })
      .pipe(
        catchError((e) => {
          return observableThrowError(e);
        })
      );
  }

  addWord(body: AddLexiconQueryParams): Observable<AddLexiconsResponse> {
    return this.http.post<AddLexiconsResponse>(`${environment.api_url}/competency-map/lexicon`, body).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  updateWord(body: AddLexiconQueryParams): Observable<Lexicon[]> {
    return this.http.patch<Lexicon[]>(`${environment.api_url}/competency-map/lexicon/${body.id}`, body).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  importFromFile(file_id: number) {
    return this.http.post<unknown>(`${environment.api_url}/competency-map/lexicon/file/${file_id}`, {}).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }
}
