<div class="section-header">
  <div>
    <div class="section-header-row" appTestLocator="add-user-filter__project">
      <div class="section-header-label">Проект ID:</div>
      <div class="section-header-value">{{ projectId }}</div>
    </div>
    <div class="section-header-row" appTestLocator="add-user-filter__status">
      <div class="section-header-label">Статус:</div>
      <div class="section-header-value d-flex align-items-center">
        {{ projectStatus | cmProjectStatus }}&nbsp;&nbsp;
        <app-svg-icon name="change" width="16" class="color-brand-light"></app-svg-icon>
      </div>
    </div>
    <div class="section-header-row" appTestLocator="add-user-filter__author">
      <div class="section-header-label">Автор:</div>
      <div class="section-header-value">{{ userAuthor | roleName }}</div>
    </div>
    <div class="section-header-row" appTestLocator="add-user-filter__date">
      <div class="section-header-label">Дата:</div>
      <div class="section-header-value">{{ projectDate | dateFormat }}</div>
    </div>
  </div>
  <div>
    <div class="section-header-text" appTestLocator="add-user-filter__title">Добавить фильтр</div>
  </div>
  <div>
    <div
      class="section-header-archive"
      (click)="archive()"
      [class.disabled]="isArchiveDisabled()"
      appTestLocator="add-user-filter__archive"
    >
      <app-svg-icon name="trash" width="16"></app-svg-icon>
      <span>Перенести в архив</span>
    </div>
  </div>
</div>

<div class="modal-body">
  <div class="section-form">
    <div class="row mb-5">
      <div class="col-6">
        <div
          class="mb-3"
          appTestLocator="add-user-filter__new-filter_name"
          appTestLocator="add-user-filter__filter-templates_name"
        >
          Название фильтра
        </div>
        <div class="mb-3">
          <dc-form-field-wrapper [style]="'material'" [vertically]="true" [required]="true">
            <dc-form-input [isShowClear]="true">
              <input
                formInput
                [(ngModel)]="filterTemplatesSearchValue"
                (ngModelChange)="filterTreeData(filtersTree, filterTemplatesSearchValue)"
                placeholder="Введите название или выберите из БШФ"
                appTestLocator="add-user-filter__filter-templates_search"
              />
            </dc-form-input>
          </dc-form-field-wrapper>
        </div>
        <div class="mb-2" [class.disabled]="isDataDisabled()">
          <input
            class="cbx-input"
            id="all_filters"
            type="checkbox"
            [(ngModel)]="allFilters"
            (ngModelChange)="toggleFilters(filterTemplates)"
          />
          <label
            class="cbx-label text-overflow"
            for="all_filters"
            appTestLocator="add-user-filter__filter-templates_select-all"
          >
            <span class="cbx-label-text text-overflow">Все фильтры</span>
          </label>
        </div>
        <div class="scroll-container">
          <tree-root #filtersTree [nodes]="filterTemplates" [options]="treeOptions" class="dc-tree">
            <ng-template #treeNodeTemplate let-node let-index="index">
              <div class="d-flex align-items-center mb-2">
                <app-svg-icon
                  (click)="expandFilterItem(filtersTree, node)"
                  class="mr-2"
                  [name]="!node.isExpanded ? 'caret_down' : 'caret_up'"
                  [class.disabled]="
                    !node.hasChildren ||
                    node.level === 3 ||
                    node.data.type === filterTemplateTypes.BOOL ||
                    node.data.type === filterTemplateTypes.RANGE
                  "
                  width="10"
                  appTestLocator="add-user-filter__filter-templates_item-{{ node.data.id }}-arrow"
                ></app-svg-icon>
                <div [class.disabled]="node.data.status !== FilterTemplateStatuses.ACTIVE || isDataDisabled()">
                  <input
                    class="cbx-input"
                    [id]="'filter_' + node.data.id"
                    type="checkbox"
                    [(ngModel)]="node.data.checked"
                    (ngModelChange)="selectFilter(node)"
                  />
                  <label
                    class="cbx-label text-overflow"
                    [for]="'filter_' + node.data.id"
                    appTestLocator="add-user-filter__filter-templates_item-{{ node.data.id }}"
                  >
                    <span class="cbx-label-text text-overflow" [title]="node.data.title">{{ node.data.title }}</span>
                  </label>
                </div>
              </div>
            </ng-template>
          </tree-root>
        </div>
      </div>
      <div class="col-6">
        <div class="mb-3" appTestLocator="add-user-filter__sections_name">Название раздела</div>
        <div class="mb-3">
          <dc-form-field-wrapper [style]="'material'" [vertically]="true" [required]="true">
            <dc-form-input [isShowClear]="true">
              <input
                formInput
                [(ngModel)]="sectionsSearchValue"
                (ngModelChange)="filterTreeData(sectionsTree, sectionsSearchValue)"
                placeholder="Введите название или выберите из каталога"
                appTestLocator="add-user-filter__sections_search"
              />
            </dc-form-input>
          </dc-form-field-wrapper>
        </div>
        <div class="mb-2" [class.disabled]="isDataDisabled()">
          <input
            class="cbx-input"
            id="all_sections"
            type="checkbox"
            [(ngModel)]="allSections"
            (ngModelChange)="toggleSections(sections)"
          />
          <label
            class="cbx-label text-overflow"
            for="all_sections"
            appTestLocator="add-user-filter__sections_select-all"
          >
            <span class="cbx-label-text text-overflow">Все разделы</span>
          </label>
        </div>
        <div class="scroll-container">
          <tree-root #sectionsTree [nodes]="sections" [options]="treeOptions" class="dc-tree">
            <ng-template #treeNodeTemplate let-node let-index="index">
              <div class="d-flex align-items-center mb-2">
                <app-svg-icon
                  (click)="expandItem(sectionsTree, node)"
                  class="mr-2"
                  [class.disabled]="!node.hasChildren || node.data.level === 3"
                  [name]="!node.isExpanded ? 'caret_down' : 'caret_up'"
                  width="10"
                  appTestLocator="add-user-filter__sections_item-{{ node.data.id }}-arrow"
                ></app-svg-icon>
                <div [appSectionAccess]="node.data" class="d-flex" [class.disabled]="isDataDisabled()">
                  <input
                    class="cbx-input"
                    [id]="'section_' + node.data.id"
                    [(ngModel)]="node.data.selected"
                    (ngModelChange)="selectSection(node.data)"
                    type="checkbox"
                  />
                  <label
                    class="cbx-label text-overflow"
                    [for]="'section_' + node.data.id"
                    appTestLocator="add-user-filter__sections_item-{{ node.data.id }}"
                  >
                    <span class="cbx-label-text text-overflow" [title]="node.data.title">{{ node.data.title }}</span>
                  </label>
                </div>
              </div>
            </ng-template>
          </tree-root>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <div appTestLocator="add-user-filter__categories_name">Категория</div>
      <div class="d-flex">
        <div *ngFor="let category of categories" class="mr-3">
          <div [class.disabled]="isDataDisabled()">
            <input
              class="cbx-input"
              [id]="category.value"
              type="checkbox"
              [(ngModel)]="category.checked"
              (ngModelChange)="selectCategory()"
            />
            <label
              class="cbx-label"
              [for]="category.value"
              appTestLocator="add-user-filter__categories_item-{{ category.value }}"
            >
              <span class="cbx-label-text">{{ category.label }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-operation-modal-footer
  (onCancel)="cancel()"
  (onSave)="save($event)"
  (onAgree)="agree($event)"
  [project]="project"
  [form]="form"
>
</app-operation-modal-footer>
