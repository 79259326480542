import { AddSectionComponent } from '../+competence-editor/catalog/sections/add-section/add-section.component';
import { RenameSectionComponent } from '../+competence-editor/catalog/sections/rename-section/rename-section.component';
import { MoveSectionComponent } from '../+competence-editor/catalog/sections/move-section/move-section.component';
import { UnionSectionComponent } from '../+competence-editor/catalog/sections/union-section/union-section.component';
import { SplitSectionComponent } from '../+competence-editor/catalog/sections/split-section/split-section.component';
import { AddUserFilterComponent } from '../+competence-editor/user-filters/add-user-filter/add-user-filter.component';
import { AddUnitComponent } from '../+competence-editor/units-storage/add-unit/add-unit.component';
import { CreateFilterTemplateComponent } from '../+competence-editor/filter-templates/create-filter-template/create-filter-template.component';
import { ProjectCommentModalComponent } from '../competence-shared/project-comment-modal/project-comment-modal.component';
import { UnionFilterTemplateComponent } from '../+competence-editor/filter-templates/union-filter-template/union-filter-template.component';
import { SplitFilterTemplateComponent } from '../+competence-editor/filter-templates/split-filter-template/split-filter-template.component';
import { CMActions } from './projects.constants';
import { UserCardComponent } from '@app/shared/components/user-card/user-card.component';
import { ChangeStatusUnitComponent } from '../+competence-editor/units-storage/change-status-unit/change-status-unit.component';

export const DYNAMIC_PROJECT_CONTRACT = {
  [CMActions.CREATE_SECTIONS]: {
    componentType: AddSectionComponent,
    modalClasses: 'section-modal',
  },
  [CMActions.CHANGE_SECTION]: {
    componentType: RenameSectionComponent,
    modalClasses: 'section-modal',
  },
  [CMActions.MOVE_SECTION]: {
    componentType: MoveSectionComponent,
    modalClasses: 'section-modal',
  },
  [CMActions.UNION_SECTION]: {
    componentType: UnionSectionComponent,
    modalClasses: 'section-split-modal',
  },
  [CMActions.SPLIT_SECTION]: {
    componentType: SplitSectionComponent,
    modalClasses: 'section-split-modal',
  },
  [CMActions.CREATE_FILTERS]: {
    componentType: AddUserFilterComponent,
    modalClasses: 'user-filter-modal',
  },
  [CMActions.CREATE_UNITS_MEASURE]: {
    componentType: AddUnitComponent,
    modalClasses: 'section-modal',
  },
  [CMActions.DELETE_UNITS_MEASURE]: {
    componentType: ChangeStatusUnitComponent,
    modalClasses: 'section-modal',
  },

  [CMActions.USER_CARD]: {
    componentType: UserCardComponent,
    modalClasses: 'user-card-modal',
  },

  [CMActions.CREATE_TEMPLATE_STRUCTURE_FILTER]: {
    componentType: CreateFilterTemplateComponent,
    modalClasses: 'filter-template-modal',
  },
  [CMActions.CHANGE_TEMPLATE_STRUCTURE_FILTER]: {
    componentType: CreateFilterTemplateComponent,
    modalClasses: 'filter-template-modal',
  },
  [CMActions.COPY_TEMPLATE_STRUCTURE_FILTER]: {
    componentType: CreateFilterTemplateComponent,
    modalClasses: 'filter-template-modal',
  },
  [CMActions.CREATE_COMMENT]: {
    componentType: ProjectCommentModalComponent,
    modalClasses: 'project-modal',
  },
  [CMActions.UNION_FILTER]: {
    componentType: UnionFilterTemplateComponent,
    modalClasses: 'filter-template-modal',
  },
  [CMActions.SPLIT_TEMPLATE_STRUCTURE_FILTER]: {
    componentType: SplitFilterTemplateComponent,
    modalClasses: 'filter-template-modal',
  },
};
