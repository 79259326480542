import { FileExtInterface } from './file-ext.interface';
import { DEFAULT_FILE_EXT, FILE_EXT_LIST } from '../constants/file-ext.constants';
import { AuthorFile } from './author-file.model';
import { random } from 'lodash';
import { User } from '@app/shared/models/user.model';

export enum UserFileDestinationEnum {
  OWN = 'own',
  SHARED = 'shared',
}

export interface UserFileInterface {
  author?: AuthorFile;
  id?: number;
  is_file?: boolean;
  is_shared?: boolean;
  in_shared_dir?: boolean;
  mimetype?: string;
  name?: string;
  url?: string;
  size?: number;
  system?: boolean;
  children?: UserFileInterface[];
  search_result?: boolean;
  virtualDisabled?: boolean;
  inner_sharing?: boolean;
  virtualOpened?: boolean;
  virtualChildren?: UserFile[];
  virtualParentLink?: number | null;
  virtualRootType?: UserFileDestinationEnum | null;

  comment?: string | null;
  for_providers?: boolean | null;
  for_customers?: boolean | null;
}

export class UserFile implements UserFileInterface {
  id: number;
  author?: AuthorFile;
  is_file?: boolean;
  is_shared?: boolean;
  in_shared_dir?: boolean;
  mimetype?: string;
  name?: string;
  system?: boolean;
  url?: string;
  size?: number;
  search_result?: boolean;
  virtualDisabled: boolean = false;
  inner_sharing?: boolean;
  virtualShared?: boolean;
  virtualOpened?: boolean;
  virtualChildren?: UserFile[] = [];
  virtualParentLink?: number | null = null;
  virtualRootType?: UserFileDestinationEnum | null = null;
  trade_link_id?: number;

  comment?: string;
  for_providers?: boolean;
  for_customers?: boolean;
  created_at?: string;
  link_user?: User;

  constructor(file: UserFileInterface, sharedDir?: boolean) {
    if (file.id === 0 && file.system) {
      this.id = -random(true);
    } else if (file.id !== undefined) {
      this.id = file.id;
    } else {
      this.id = null;
    }
    this.is_file = file.is_file || false;
    this.is_shared = file.is_shared;
    this.mimetype = file.mimetype || null;
    this.in_shared_dir = file.in_shared_dir || null;
    this.name = file.name || null;
    this.system = file.system || null;
    this.url = file.url || null;
    this.size = file.size || null;
    this.author = file.author || null;
    this.search_result = file.search_result || null;
    this.inner_sharing = file.inner_sharing || null;
    this.virtualShared = sharedDir || false;
    this.virtualDisabled = false;
    this.virtualOpened = file.virtualOpened || false;
    this.virtualChildren = file.virtualChildren || [];
    this.virtualParentLink = file.virtualParentLink || file.virtualParentLink === 0 ? file.virtualParentLink : null;
    this.virtualRootType = file.virtualRootType || null;

    this.comment = file?.comment || null;
    this.for_providers = file?.for_providers || null;
    this.for_customers = file?.for_customers || null;
  }

  setChildren(files: UserFile[] = []): void {
    this.virtualChildren.forEach((file: UserFile) => {
      files.some((_file: UserFile) => {
        if (_file.id === file.id) {
          _file.virtualOpened = file.virtualOpened;
          _file.virtualChildren = file.virtualChildren;
          file.inner_sharing = _file.inner_sharing;
          file.is_shared = _file.is_shared;
          return true;
        }
        return false;
      });
    });
    this.virtualChildren = files;
  }

  addChildren(data: UserFile, fileId?: number): void {
    const id = fileId || null;
    if (id && this.virtualChildren.filter((file: UserFile) => file.id === id).length) {
      this.virtualChildren.forEach((file: UserFile) => {
        if (file.id === id) {
          data.virtualOpened = file.virtualOpened;
          data.virtualChildren = file.virtualChildren;
          file.name = data.name;
        }
      });
    } else {
      this.virtualChildren.push(data);
    }
  }

  hasFile(): boolean {
    return this.is_file;
  }

  hasRoot(): boolean {
    return this.virtualRootType !== null;
  }

  basename(): string {
    if (!this.hasFile()) {
      return this.name;
    }
    const name = this.name.split('.');
    if (name.length === 2 || name.length === 1) {
      return name[0];
    } else if (name.length > 2) {
      name.splice(name.length - 1, 1);
      return name.join('.');
    }
    return null;
  }

  fileExt(): string {
    if (!this.hasFile()) {
      return null;
    }
    const name = this.name.split('.');
    if (name.length === 2) {
      return name[1];
    } else if (name.length > 2) {
      return name[name.length - 1];
    } else {
      return name[0];
    }
  }

  fileExtType(): FileExtInterface {
    if (!this.hasFile()) {
      return DEFAULT_FILE_EXT;
    } else {
      const fileExt: FileExtInterface[] = FILE_EXT_LIST.filter(
        (file: FileExtInterface) => file.ext.indexOf(this.fileExt()) > -1
      );
      return fileExt.length ? fileExt[0] : DEFAULT_FILE_EXT;
    }
  }
}
