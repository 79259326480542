import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { FormControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[onlyInteger]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: OnlyIntegerDirective,
      multi: true,
    },
  ],
})
export class OnlyIntegerDirective {
  // true - integer only, false - any text, true is default
  @Input() onlyInteger: boolean;
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home'];
  private regex: RegExp = new RegExp(/^[0-9]+$/g);

  constructor(private el: ElementRef) {}

  validate(c: FormControl): { [key: string]: { valid: boolean } } {
    const v = c.value;
    const invalid = { onlyNumber: { valid: false } };

    if (!!v && !String(v).match(this.regex)) {
      return invalid;
    }

    return null;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.onlyInteger === false) {
      return;
    }
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}
