import { Component, Input, OnInit } from '@angular/core';
import { interval } from 'rxjs';
import { ClockService } from '../../services/clock.service';
import { AuthService } from '../../services/auth.service';
import { User } from '../../models/user.model';
import * as moment from 'moment';
import { TIMEZONES } from '@app/shared/constants/date.constants';
import { takeUntil } from 'rxjs/operators';
import { PersonalDataService } from '@app/shared/services/personal-data.service';
import { DestroyService } from '@app/services/destroy.service';

@Component({
  selector: 'app-clock-widget',
  templateUrl: './clock-widget.component.html',
  styleUrls: ['./clock-widget.component.scss'],
  providers: [ClockService, DestroyService],
})
export class ClockWidgetComponent implements OnInit {
  @Input() menuCollapsed = false;

  secondsHandAngle: string;
  minutesHandAngle: string;
  hoursHandAngle: string;
  background = 'none';
  // to set custom background uncomment
  // background: string = 'no-repeat 50% 50% url(assets/img/icons/clock-digits.svg)';
  timeZoneOffset = 0;
  isDigitVisible = true;
  clockHandColor = '#fff';
  secondsHandColor = '#fff';
  modalIsOpen = false;
  currentTime: string;
  timezones = TIMEZONES;
  savedTimezone = null;
  timezoneModalDateStr = '';
  timezoneModalNewDateStr = '';
  isShiftedDateShown = false;

  private clockTick$ = interval(1000);

  constructor(
    private authService: AuthService,
    private personalDataService: PersonalDataService,
    private destroy$: DestroyService
  ) {}

  ngOnInit() {
    this.authService.userStream.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.timeZoneOffset = user.timezone_offset;
      this.savedTimezone = user.timezone_offset;
      this.updateTime();
    });
    this.clockTick$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.updateTime();
    });
  }

  updateTime() {
    const date = new Date();
    this.secondsHandAngle = `rotateZ(${date.getUTCSeconds() * 6}deg)`;
    this.minutesHandAngle = `rotateZ(${(date.getUTCMinutes() + (this.timeZoneOffset % 60)) * 6}deg)`;
    this.hoursHandAngle = `rotateZ(${
      (date.getUTCHours() + Math.floor(this.timeZoneOffset / 60)) * 30 + date.getMinutes() / 2
    }deg)`;
    this.currentTime = moment().utc().add(this.timeZoneOffset, 'minutes').format('HH:mm');
    this.timezoneModalDateStr = moment().utc().add(this.savedTimezone, 'minutes').format('DD MMMM YYYY года, HH:mm');
  }

  showTimezoneModal() {
    this.timeZoneOffset = this.savedTimezone;
    this.isShiftedDateShown = false;
    this.modalIsOpen = !this.modalIsOpen;
  }

  showShiftedDate() {
    this.timezoneModalNewDateStr = moment()
      .utc()
      .add(this.timeZoneOffset, 'minutes')
      .format('DD MMMM YYYY года, HH:mm');
    this.isShiftedDateShown = true;
  }

  timeZoneSelect() {
    this.updateZone();
    this.closeModal();
  }

  closeModal() {
    this.modalIsOpen = false;
  }

  updateZone() {
    this.authService
      .setTimezoneOffset(this.timeZoneOffset)
      .pipe(takeUntil(this.destroy$))
      .subscribe((user: User) => {
        this.timeZoneOffset = user.timezone_offset;
        this.savedTimezone = user.timezone_offset;
        this.authService.nextUserData(user);
        this.personalDataService.showCurrentDatesNoti();

        this.closeModal();
      });
  }
}
