import {Pipe, PipeTransform} from '@angular/core';
import {LEGAL_STATUSES} from '../constants/legal-statuses.constants';

@Pipe({
  name: 'legalStatus'
})

export class LegalStatusesPipe implements PipeTransform {
  transform(legalStatus: any): any {
    let result = '';
    const foundItem = [...LEGAL_STATUSES].find(res => res.value === legalStatus);
    if (foundItem) {
      result = foundItem.title;
    }
    return result;
  }
}
