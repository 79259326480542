import { Component, Input } from '@angular/core';
import { Column } from '@app/shared/models/table';
import { FlaskQueryFilter } from '@app/shared/models/filters.model';
import { ProjectColumnKeys } from '@app/+competence-map/constants/projects.constants';
import * as moment from 'moment';
import { FlaskQueryHeaderCellComponent } from '@app/shared/components/table/classes/flask-query-header-cell.class';

@Component({
  selector: 'app-project-logs-header-cell',
  templateUrl: './project-logs-header-cell.component.html',
  styleUrls: ['./project-logs-header-cell.component.scss'],
})
export class ProjectLogsHeaderCellComponent extends FlaskQueryHeaderCellComponent {
  @Input() projectTypes: {
    id: string;
    name: string;
  }[];

  columnKeys = ProjectColumnKeys;

  constructor() {
    super();
  }

  getFilterValueParams(column: Column<FlaskQueryFilter[]>): any {
    const paramsList = column.filterConfig.map((item) => item.val);

    switch (column.id) {
      case ProjectColumnKeys.ID:
      case ProjectColumnKeys.TITLE:
      case ProjectColumnKeys.TYPE:
      case ProjectColumnKeys.AUTHOR: {
        return paramsList[0];
      }
      case ProjectColumnKeys.SEND_DATE: {
        if (!column.filterConfig[0].val) {
          return;
        }
        if (!column.filterConfig[0].val.length) {
          return;
        }
        const fromDate = column.filterConfig[0].val[0];
        const toDate = column.filterConfig[0].val[1];

        return {
          fromDate: fromDate || null,
          toDate: toDate || null,
        };
      }

      default: {
        return {};
      }
    }
  }
}
