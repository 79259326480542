import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-unsaved-confirm-leave',
  templateUrl: './unsaved-confirm-leave.component.html',
  styleUrls: ['./unsaved-confirm-leave.component.scss'],
})
export class UnsavedConfirmLeaveComponent {
  constructor(private activeModal: NgbActiveModal) {}

  action(exit: boolean): void {
    this.activeModal.close({ exit });
  }

  close(): void {
    this.activeModal.close({ exit: false });
  }
}
