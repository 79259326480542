<div #scrollMe [scrollTop]="scrollHeight" class="scroll-me">
  <ng-container *ngIf="!isTradeGroup(roomSelected)">
    <div *ngIf="count > messages.length && !!messages?.length" class="row">
      <div class="col-12 text-center">
        <a (click)="showMoreMessages()" class="chat__show-messages-btn">Показать раньше</a>
      </div>
    </div>
    <div *ngFor="let message of messages" class="row">
      <div [ngClass]="message.author_id == userId ? 'col-6 text-left' : 'text-right offset-6 col-6'">
        <div class="chat__message-info">
          <span *ngIf="message.author_id == userId">Я, </span>
          <span *ngIf="!(message.author_id == userId)">{{ message.author | initials }}, </span>
          <span>{{ message.created_at | dateTimeFormat }}</span>
          <a *ngIf="message.author_id == userId" (click)="onRemoveMessage(message)" class="chat__message-trash">
            <fa-icon [icon]="['fas', 'trash']"></fa-icon>
          </a>
        </div>
        <div
          class="chat__message"
          [innerHTML]="message.content | linkify"
          [ngClass]="message.author_id == userId ? 'chat__message-my' : 'chat__message-response'"
        ></div>
        <div class="mb-2 blue-links" *ngIf="message.attached_files.length">
          <div *ngFor="let file of message.attached_files">
            <a (click)="downloadFile(file)" class="d-inline-flex mr-3">
              <fa-icon [icon]="['fas', 'file']" class="mr-2"></fa-icon>
              {{ file.filename }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!messages.length">Нет сообщений</div>
  </ng-container>
</div>
