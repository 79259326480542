import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-status-viewer',
  templateUrl: './status-viewer.component.html',
  styleUrls: ['./status-viewer.component.scss'],
})
export class StatusViewerComponent {
  @Input() status: string;
}
