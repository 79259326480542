<ul class="nav flex-column left-menu">
  <!--Настройки-->
  <ng-container *ngIf="menu_items && menu_items.tuning">
    <!-- TODO: Убрал параметр для срабатывания routerLinkActive на всех элементах -->
    <li
      *ngFor="let item of menu_items.tuning; let first = first"
      [routerLinkActive]="['active']"
      [ngClass]="{ open: item.open }"
    >
      <a
        *ngIf="!item.sub_items"
        class="menu-item inline middle"
        [routerLink]="[item.link]"
        [ngClass]="{
          disabled:
            !first &&
            (item.link === 'disable' ||
              menu_disabled ||
              !user.company?.id ||
              ((user?.type === 'admin_of_direction' || user?.type === 'operator') &&
                user?.company?.owner?.id !== user?.id &&
                item.link === '/tariff') ||
              ((user?.type === 'admin_of_direction' || user?.type === 'operator') && need_set_password))
        }"
      >
        <app-svg-icon
          class="icon"
          *ngIf="item.sprite"
          [width]="(item?.sprite_size)[0]"
          [height]="(item?.sprite_size)[1]"
          [name]="item?.sprite"
        >
        </app-svg-icon>
        <span class="menu-label">{{ item.label }}</span>
        <app-faq-tooltip [tooltipName]="item.hintId" [tooltipWhite]="true"></app-faq-tooltip>
      </a>

      <a
        *ngIf="item.sub_items"
        class="menu-item inline middle"
        (click)="onToggleItem(item)"
        [ngClass]="{
          disabled:
            !first &&
            (item.link === 'disable' ||
              menu_disabled ||
              !user.company?.id ||
              ((user?.type === 'admin_of_direction' || user?.type === 'operator') &&
                user?.company?.owner?.id !== user?.id &&
                item.link === '/tariff') ||
              ((user?.type === 'admin_of_direction' || user?.type === 'operator') && need_set_password))
        }"
      >
        <fa-icon class="icon" *ngIf="item.icon" [icon]="item.icon" [title]="item.label"></fa-icon>
        <span class="menu-label">{{ item.label }}</span>
        <div *ngIf="item.sub_items" class="collapse-sub-menu">
          <fa-icon *ngIf="!item.open" class="icon-collapse" [icon]="['fas', 'plus-square']"></fa-icon>
          <fa-icon *ngIf="item.open" class="icon-collapse" [icon]="['fas', 'minus-square']"></fa-icon>
        </div>
        <app-faq-tooltip
          tooltipName="personal_data__header-2"
          [tooltipWhite]="true"
          class="no-margin"
        ></app-faq-tooltip>
      </a>

      <ul class="nav flex-column sub-menu" [ngClass]="{ 'is-open': item.open }">
        <li
          *ngFor="let sub_item of item.sub_items"
          [routerLinkActive]="['active']"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a
            class="sub-link menu-item inline middle"
            [routerLink]="[sub_item.link]"
            [ngClass]="{
              disabled:
                !first &&
                (item.link === 'disable' ||
                  menu_disabled ||
                  !user.company?.id ||
                  ((user?.type === 'admin_of_direction' || user?.type === 'operator') &&
                    user?.company?.owner?.id !== user?.id &&
                    item.link === '/tariff') ||
                  ((user?.type === 'admin_of_direction' || user?.type === 'operator') && need_set_password))
            }"
            [title]="sub_item.label"
          >
            <span class="icon text-icon no-margin">{{ sub_item.short }}</span>
            <span class="menu-label">{{ sub_item.label }}</span>
          </a>
        </li>
      </ul>
    </li>
  </ng-container>
</ul>
