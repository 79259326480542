import { RolesEnum } from '@app/shared/constants/roles.constants';

export const RESIDENCES = [
  {
    value: 'russian_federation',
    title: 'Резидент РФ',
    label: 'Резидент РФ',
    roles: [
      RolesEnum.ADMIN_OF_USER,
      RolesEnum.ADMIN_OF_DIRECTION,
      RolesEnum.PARTNER,
      RolesEnum.OPERATOR,
      RolesEnum.ACCOUNTANT,
      RolesEnum.EXPERT,
      RolesEnum.SUPERUSER,
      RolesEnum.TSO,
    ],
  },
  {
    value: 'other',
    title: 'Не резидент РФ',
    label: 'Не резидент РФ',
    roles: [
      RolesEnum.ADMIN_OF_USER,
      RolesEnum.ADMIN_OF_DIRECTION
    ],
  },
];
