import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  FaqCategory,
  FaqExistedImage,
  FaqFilesData,
  FaqModule,
  FaqPermission,
  FaqQuestion,
} from '@app/shared/models/faq.api.model';
import { environment } from '@app/environments/environment';
import {
  FaqApiModel,
  FaqCategoryApiParams,
  FaqHintApiParams,
  FaqPermissionsApiParams,
  FaqQuestionApiParams,
  FaqUpdatingModuleParams,
} from '@app/shared/models/faq.common.interfaces';
import { mapToObject } from '@app/shared/utils';
import { FaqQuestionStatus, FaqTabs } from '@app/shared/enums/faq.common.enum';

interface ImageApiParams {
  file: any;
  id: string;
  itemType: string;
  url: string;
  headers: HttpHeaders;
}

@Injectable()
export class FaqApiService {
  private static mapPermission(m: FaqApiModel.FaqPermission): FaqPermission {
    return new FaqPermission(m);
  }

  private static mapModule(m: FaqApiModel.FaqModule): FaqModule {
    return new FaqModule(m);
  }

  private static mapCategory(category: FaqApiModel.FaqCategory): FaqCategory {
    return new FaqCategory(category);
  }

  constructor(private http: HttpClient) {}

  public updateModule(faqModule: FaqUpdatingModuleParams, id: number): Observable<FaqModule> {
    const url = `${environment.api_url}/faq/hint_module/${id}`;
    return this.http.put(url, faqModule).pipe(
      map((data: FaqApiModel.FaqModule) => {
        return FaqApiService.mapModule(data);
      })
    ) as Observable<FaqModule>;
  }

  public createModule(faqModule: FaqModule): Observable<FaqModule> {
    const url = `${environment.api_url}/faq/hint_modules`;
    const params: FaqUpdatingModuleParams = {
      name: faqModule.name,
      status: FaqQuestionStatus.InDevelop,
      sort: faqModule.sort,
    };
    return this.http.post(url, params).pipe(
      map((data: FaqApiModel.FaqModule) => {
        return FaqApiService.mapModule(data);
      })
    ) as Observable<FaqModule>;
  }

  public updatePermission(params: FaqPermissionsApiParams[], tab: FaqTabs) {
    const url = `${environment.api_url}/faq/${tab === FaqTabs.Modules ? 'hint_permissions' : 'faq_permissions'}`;
    return this.http.post(url, params);
  }

  public createQuestion(question: FaqQuestion) {
    const url = `${environment.api_url}/faq/questions`;
    const params: FaqQuestionApiParams = {
      category: { id: question.categoryId },
      answer: question.answer,
      user_types: question.user_types,
      status: question.status,
      text: question.text,
      sort: question.sort,
    };
    return this.http.post(url, params).pipe(map((q: FaqApiModel.FaqQuestion) => q.id));
  }

  public updateQuestion(question: FaqQuestion, files: FaqFilesData = null): Observable<null> {
    const url = `${environment.api_url}/faq/question/${question.id}`;
    const params: FaqQuestionApiParams = {
      category: { id: question.categoryId },
      answer: question.answer,
      user_types: question.user_types,
      status: question.status,
      text: question.text,
      sort: question.sort,
    };
    return this.http.put(url, params).pipe(
      switchMap(() => this.saveImages(files, question.id)),
      switchMap(() => this.removeImages(files, question.id))
    ) as Observable<null>;
  }

  public getImageNames(tab: FaqTabs, id: string): Observable<string[] | null> {
    const itemType = tab === FaqTabs.Questions ? 'faq' : 'hint';
    const url = `${environment.api_url}/file/imgs/${itemType}/${id}/`;
    return this.http.get(url) as Observable<string[] | null>;
  }

  public getImagesByNames(tab: FaqTabs, id: string, names: string[]) {
    const files = names.map((name) => new FaqExistedImage(name));
    const itemType = tab === FaqTabs.Questions ? 'faq' : 'hint';
    return forkJoin(
      names.map((name) => {
        return this.getImageByName(name, itemType, id);
      })
    ).pipe(
      map((fileBlobs: Blob[]) => {
        fileBlobs.forEach((blob, index) => (files[index].blob = blob));
        return files;
      })
    );
  }

  private getImageByName(name: string, itemType: string, id: string) {
    const url = `${environment.api_url}/get_img/${itemType}/${id}/${name}/`;
    return this.http.get(url, {
      responseType: 'blob',
      params: {
        width: '1028',
        height: '1028',
      },
    });
  }

  public saveImages(files: FaqFilesData, id: number | string) {
    if (!files || !files.filesForSave.length) {
      return of(null);
    }
    const url = `${environment.api_url}/file/imgs/${files.itemType}/${id}/`;
    const headers: HttpHeaders = new HttpHeaders()
      .set('Enctype', 'multipart/form-data')
      .set('Accept', 'application/json');

    return forkJoin(
      files.filesForSave.map((f) => {
        return this.saveImage({
          file: f,
          headers,
          id: `${id}`,
          url,
          itemType: files.itemType,
        });
      })
    );
  }

  private saveImage({ file, id, itemType, url, headers }: ImageApiParams) {
    const params: FormData = new FormData();
    params.append('file', file, file.name);
    params.append('item_id', id);
    params.append('item_type', itemType);
    return this.http.post(url, params, { headers });
  }

  private removeImages(files: FaqFilesData = null, id: string | number): Observable<null> {
    if (!files || !files.filesForRemove.length) {
      return of(null);
    }
    const data = { img_names: files.filesForRemove };
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: data,
    };
    const url = `${environment.api_url}/file/imgs/${files.itemType}/${id}/`;
    return this.http.delete(url, options) as Observable<null>;
  }

  public saveHint(hint: FaqHintApiParams) {
    const url = `${environment.api_url}/faq/hints`;
    return this.http.post(url, hint).pipe(map((q: FaqApiModel.FaqHint) => q.id));
  }

  public updateHint(hint: FaqHintApiParams, files: FaqFilesData = null) {
    const url = `${environment.api_url}/faq/hint/${hint.id}`;
    const params = {
      module: hint.module,
      value: hint.value,
      status: hint.status,
      sort: hint.sort,
      name: hint.name,
    };
    return this.http.put(url, params).pipe(
      switchMap(() => this.saveImages(files, hint.id)),
      switchMap(() => this.removeImages(files, hint.id))
    ) as Observable<null>;
  }

  public updateCategory(category: FaqCategoryApiParams, id: number): Observable<any> {
    const url = `${environment.api_url}/faq/category/${id}`;
    return this.http.put(url, category);
  }

  public addCategory(category: FaqCategoryApiParams): Observable<any> {
    const url = `${environment.api_url}/faq/categories`;
    return this.http.post(url, category);
  }

  public getModules(query?) {
    const url = `${environment.api_url}/faq/hint_modules`;
    const fromObject = mapToObject(query ? { q: query } : {});
    const httpParams = new HttpParams({ fromObject });
    return this.http.get(url, { params: httpParams }).pipe(
      map((data: FaqApiModel.FaqModule[]) => {
        return data.map(FaqApiService.mapModule).sort((m1, m2) => m2.sort - m1.sort);
      })
    ) as Observable<FaqModule[]>;
  }

  public getCategories(query?): Observable<FaqCategory[]> {
    const url = `${environment.api_url}/faq/categories`;
    const fromObject = mapToObject(query ? { q: query } : {});
    const httpParams = new HttpParams({ fromObject });
    return this.http.get<FaqApiModel.FaqCategory[]>(url, { params: httpParams }).pipe(
      map((data: FaqApiModel.FaqCategory[]) => {
        return data.map(FaqApiService.mapCategory).sort((c1, c2) => c2.sort - c1.sort);
      })
    ) as Observable<FaqCategory[]>;
  }

  public getCategoriesWithActiveStatus(query?): Observable<FaqCategory[]> {
    return this.getCategories(query).pipe(
      map((data: FaqApiModel.FaqCategory[]) => {
        return data.reduce((categories: FaqCategory[], category: FaqCategory) => {
          if (category.status !== FaqQuestionStatus.Approved) {
            return categories;
          }
          category.questions = category.questions.filter((question) => question.status === FaqQuestionStatus.Approved);
          return categories.concat(category);
        }, []);
      })
    ) as Observable<FaqCategory[]>;
  }

  public getHintPermissions() {
    const url = `${environment.api_url}/faq/hint_permissions`;
    return this.http.get(url).pipe(
      map((data: FaqApiModel.FaqPermission[]) => {
        return data.map(FaqApiService.mapPermission);
      })
    ) as Observable<FaqPermission[]>;
  }

  public getFaqPermissions() {
    const url = `${environment.api_url}/faq/faq_permissions`;
    return this.http.get(url).pipe(
      map((data: FaqApiModel.FaqPermission[]) => {
        return data.map(FaqApiService.mapPermission);
      })
    ) as Observable<FaqPermission[]>;
  }
}
