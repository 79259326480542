import {Directive, ElementRef, Input, AfterViewInit, OnChanges} from '@angular/core';

@Directive({
  selector: '[appEllipsis]'
})
export class NgxMultiLineEllipsisDirective implements AfterViewInit, OnChanges {

  @Input() clamp: number;
  @Input() text: string;

  height: number;
  truncateChar = '...';

  chunks = null;
  lastChunk = null;
  splitChar = null;
  splitChars = ['.', ',', ' ', ''];

  constructor(
    private el: ElementRef
  ) { }

  ngAfterViewInit(): void {
    this.clampText();
  }

  ngOnChanges() {
    setTimeout(() => {
      this.clampText();
    }, 0);
  }

  clampText() {
    if (!this.text) {
      return;
    }
    this.height = this.getMaxHeight(this.el.nativeElement, this.clamp);

    if (this.height < this.el.nativeElement.clientHeight) {
      this.truncate(this.el.nativeElement, this.height);
    }
  }

  getMaxHeight(elem, clamp) {
    const lineHeight = this.getLineHeight(elem);
    return lineHeight * clamp;
  }

  getLineHeight(elem) {
    const style = window.getComputedStyle(elem, null);
    let lineHeight = style.getPropertyValue('line-height') as any;
    if (lineHeight === 'normal') {
      // normal line height uses a default value of roughly 1.2
      lineHeight = parseInt(style.getPropertyValue('font-size')) * 1.2;
    }

    return parseInt(lineHeight);
  }

  truncate(elem, height) {
    let target;
    let nodeValue;

    if (!height) return;

    target = elem.lastChild;
    nodeValue = target.nodeValue.replace(this.truncateChar, '');

    if (!this.chunks) {
      if (this.splitChars.length > 0) {
        this.splitChar = this.splitChars.shift();
      }

      this.chunks = nodeValue.split(this.splitChar);
    }

    if (this.chunks.length > 1) {
      this.lastChunk = this.chunks.pop();
      this.applyEllipsis(target, this.chunks.join(this.splitChar));
    } else {
      this.chunks = null;
    }

    if (this.chunks) {
      if (elem.clientHeight <= height) {
        if (this.splitChars.length >= 0 && this.splitChar !== '') {
          this.applyEllipsis(target, this.chunks.join(this.splitChar) + this.splitChar + this.lastChunk);
          this.chunks = null;
        } else {
          return elem.innerText;
        }
      }
    }

    return this.truncate(elem, height);
  }

  applyEllipsis(target, str) {
    target.nodeValue = str + this.truncateChar;
  }
}
