<form [formGroup]="searchForm">
  <dc-form-field-wrapper [style]="'material'" [vertically]="true">
    <dc-form-input [isShowLength]="false">
      <input formInput formControlName="query" [placeholder]="placeholder" [attr.disabled]="false" />
      <button (click)="resetSearch()" *ngIf="isSearchQuery" class="reset-search">
        <app-svg-icon [width]="14" [height]="14" name="times"> </app-svg-icon>
      </button>
    </dc-form-input>
  </dc-form-field-wrapper>
</form>
