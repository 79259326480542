export enum TYPE_SELECT {
  WEEK = 'week',
  YEAR = 'year',
  MONTH = 'month',
}

export const SIZE_WINDOW = {
  [TYPE_SELECT.WEEK]: {
    height: 218,
    width: 126,
  },
  [TYPE_SELECT.YEAR]: {
    height: 218,
    width: 90,
  },
  [TYPE_SELECT.MONTH]: {
    height: 218,
    width: 122,
  },
};

export const LIST_YEARS_LIMIT = {
  startYear: 1900,
  endYear: 2100,
};
