<div class="dc-modal-x-button dc-fa-button" (click)="close()">
  <app-svg-icon [height]="20" [width]="20" name="close"></app-svg-icon>
</div>
<div class="modal-window-content">
  <div class="modal-header">
    <div class="modal-title text-left">Изменить E-mail</div>
  </div>

  <div class="modal-body">
    <form [formGroup]="form" class="profile-email-form">
      <div class="form-body">
        <dc-form-field-wrapper [style]="'bootstrap'">
          <dc-form-label>Текущий E-mail:</dc-form-label>
          <input formInput placeholder="Текущий E-mail" formControlName="current_email" />
        </dc-form-field-wrapper>

        <dc-form-field-wrapper [style]="'bootstrap'">
          <dc-form-label>Введите новый E-mail:</dc-form-label>
          <input type="email" formInput placeholder="Новый E-mail" formControlName="new_email" />
          <div class="form-field-error" *ngIf="form.get('new_email').touched && form.get('new_email').invalid">
            <span *ngIf="form.get('new_email').errors.required">{{ messages.required }}</span>
            <span *ngIf="form.get('new_email').errors.pattern">Проверьте введённый E-mail</span>
          </div>
        </dc-form-field-wrapper>

        <dc-form-field-wrapper [style]="'bootstrap'" id="current_password">
          <dc-form-label>Введите текущий пароль:</dc-form-label>
          <app-password-form-input
            [ngClass]="{ 'invalid-password': !isCorrectPassword }"
            formInput
            formControlName="current_password"
            (input)="inputPassword()"
          ></app-password-form-input>
          <div
            class="form-field-error"
            *ngIf="form.get('current_password').touched && form.get('current_password').invalid"
          >
            <span *ngIf="form.get('current_password').errors.required">{{ messages.required }}</span>
          </div>
          <div *ngIf="!isCorrectPassword" class="form-field-error">Неверный пароль</div>
        </dc-form-field-wrapper>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-dc-outline" (click)="close()">Отменить</button>
    <button
      [disabled]="!isCorrectPassword || form.invalid"
      type="button"
      class="btn btn-dc-primary"
      (click)="success()"
    >
      Сохранить
    </button>
  </div>
</div>
