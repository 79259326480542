import { AuthService } from '@app/shared/services/auth.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@app/environments/environment';
import {
  NotificationDataSourceModel,
  NotificationEventEnum,
  NotificationManyModel,
  NotificationModel,
  NotificationSettingModel,
  notificationUrl,
} from '@app/notification/const/notification.const';

@Injectable()
export class NotificationService {
  messagesChanged: Subject<NotificationDataSourceModel> = new Subject<NotificationDataSourceModel>();
  messagesUpdated: Subject<any> = new Subject<any>();
  messageFiles$: Subject<any> = new Subject<any>();
  widgetMessageCounterChanged: Subject<number> = new Subject<number>();
  widgetMessageCounter: number = 0;
  baseData: NotificationDataSourceModel = {
    noti: {
      notifications: [],
      total: 0,
    },
    settings: [],
    loading: true,
  };

  constructor(private http: HttpClient, private authService: AuthService, private router: Router) {}

  updateCounters(data) {
    this.widgetMessageCounter = data?.count ?? 0;
    this.widgetMessageCounterChanged.next(this.widgetMessageCounter);
  }

  addFileNotification(data): void {
    this.messageFiles$.next(data.notification);
  }

  addNotification(data) {
    this.messagesUpdated.next(
      Object.assign(
        {},
        {
          data: data.notification,
          event: NotificationEventEnum.NEW_NOTIFICATION,
        }
      )
    );
    this.widgetMessageCounter++;
    this.widgetMessageCounterChanged.next(this.widgetMessageCounter);
  }

  toggleNotificationOpen() {
    if (!this.router.url.includes(notificationUrl)) {
      this.router.navigate([{ outlets: { popup: [notificationUrl] } }]);
    } else {
      this.router.navigate([{ outlets: { popup: null } }]);
    }
  }

  showMoreData(data = { limit: 10, offset: 0 }) {
    return new Promise((resolve) => {
      const params: { [param: string]: string | string[] } = Object.keys(data).reduce((p, k) => {
        const value = data[k];
        p[k] = '' + value;
        return p;
      }, {});
      this.http.get<NotificationManyModel>(`${environment.api_url}/notifications`, { params }).subscribe(
        (res) => {
          this.messagesUpdated.next(
            Object.assign({}, { data: res, event: NotificationEventEnum.SHOW_MORE_NOTIFICATION })
          );
          resolve(true);
        },
        (err) => {
          console.log(err);
          resolve(true);
        }
      );
    });
  }

  loadData() {
    this.messagesChanged.next(
      Object.assign(
        { ...this.baseData },
        {
          loading: true,
        }
      )
    );
    this.http
      .get<NotificationManyModel>(`${environment.api_url}/notifications`, { params: { limit: '10' } })
      .subscribe((res) => {
        this.messagesChanged.next(
          Object.assign(
            { ...this.baseData },
            {
              noti: res,
              loading: false,
            }
          )
        );
      });
  }

  loadSetting() {
    this.messagesChanged.next(
      Object.assign(
        { ...this.baseData },
        {
          loading: true,
        }
      )
    );
    this.http.get<NotificationSettingModel[]>(`${environment.api_url}/notifications/settings`).subscribe((res) => {
      this.messagesChanged.next(
        Object.assign(
          { ...this.baseData },
          {
            settings: res,
            loading: false,
          }
        )
      );
    });
  }

  viewNotification(body: Partial<NotificationModel>[] | null) {
    this.http.patch<NotificationSettingModel[]>(`${environment.api_url}/notifications`, body).subscribe(
      (res) => {
        if (this.widgetMessageCounter > 0) {
          this.widgetMessageCounter = body?.length ? this.widgetMessageCounter - body.length : 0;
          this.widgetMessageCounterChanged.next(this.widgetMessageCounter);
        }
      },
      (err) => {
        console.log(err);
        this.loadData();
      }
    );
  }

  saveSetting(body: NotificationSettingModel[]) {
    this.messagesChanged.next(
      Object.assign(
        { ...this.baseData },
        {
          loading: true,
        }
      )
    );
    this.http
      .patch<NotificationSettingModel[]>(`${environment.api_url}/notifications/settings`, body)
      .subscribe((res) => {
        this.messagesChanged.next(
          Object.assign(
            { ...this.baseData },
            {
              settings: res,
              loading: false,
            }
          )
        );
      });
  }
}
