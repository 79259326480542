<div class="d-flex align-items-baseline">
  <div class="thead-name" appTestLocator="column__name" [innerHTML]="column.name"></div>

  <div class="thead-operations">
    <ng-container *ngIf="column.isEnabledSearch">
      <div class="dc-icon-filter-button">
        <button
          class="dc-icon-button"
          (click)="clickFilterIcon()"
          [class.active]="column.isFilterActive"
          appTestLocator="column__search"
        >
          <app-svg-icon name="search" width="12" height="12"></app-svg-icon>
        </button>
        <button
          appTestLocator="column__reset-search"
          class="dc-icon-button"
          (click)="clickFilterClearIcon()"
          [class.dc-icon-button-hidden]="!column.isFilterActive"
        >
          <app-svg-icon name="close_wide" width="12" height="12"></app-svg-icon>
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="column.isEnabledFilter">
      <div class="dc-icon-filter-button">
        <button
          class="dc-icon-button"
          (click)="clickFilterIcon()"
          appTestLocator="column__filter"
          [class.active]="column.isFilterActive"
        >
          <app-svg-icon name="filter" width="12" height="12"></app-svg-icon>
        </button>
        <button
          appTestLocator="column__reset-filter"
          class="dc-icon-button"
          (click)="clickFilterClearIcon()"
          [class.dc-icon-button-hidden]="!column.isFilterActive"
        >
          <app-svg-icon name="close_wide" width="12" height="12"></app-svg-icon>
        </button>
      </div>
    </ng-container>
  </div>
</div>
