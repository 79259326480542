import { Routes } from '@angular/router';
import { MyCompetencesComponent } from './my-competences/my-competences.component';
import { ExpertCompetencesComponent } from './my-competences/expert-competences/expert-competences.component';

export const competenceMapRoutes: Routes = [
  {
    path: 'editor',
    loadChildren: () => import('./+competence-editor/competence-editor.module').then((m) => m.CompetenceEditorModule),
  },
  { path: 'my-competences', component: MyCompetencesComponent },
  { path: 'expert-competences', component: ExpertCompetencesComponent },
];
