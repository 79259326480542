<div class="container">
  <div class="row">
    <div class="col-md-4"></div>
    <div class="col-md-4" style="min-width: 385px">
      <section class="auth-form">
        <form role="auth" novalidate>
          <app-faq-tooltip tooltipName="auth__auth_signup" class="hint-right"></app-faq-tooltip>
          <h3>{{ role === 'admin_of_user' ? 'Зарегистрироваться' : 'Регистрация агента' }}</h3>

          <div class="form-group">
            <select
              [(ngModel)]="role"
              [ngModelOptions]="{ standalone: true }"
              class="form-control"
              id="exampleFormControlSelect1"
            >
              <option ngValue="admin_of_user">Администратор пользователя</option>
              <option ngValue="partner">Агент</option>
            </select>
          </div>

          <div class="form-group" [ngClass]="{ 'has-danger': errors.email }">
            <input
              type="email"
              [(ngModel)]="email"
              placeholder="Электронная почта"
              name="email"
              class="form-control input-lg"
              appInputTrim
            />
            <div class="form-control-feedback" *ngFor="let err of errors.email">{{ err | translate }}</div>
          </div>
          <div class="form-group" [ngClass]="{ 'has-danger': errors.password || errors.password_double }">
            <div class="inline middle group-pass">
              <input
                type="{{ !isShowPass ? 'password' : 'text' }}"
                name="password"
                [(ngModel)]="password"
                class="form-control"
                placeholder="Введите пароль"
                appInputTrim
              />
              <button class="btn btn-dc-primary show-pass" type="button" (click)="isShowPass = !isShowPass">
                <fa-icon [icon]="['fas', isShowPass ? 'eye' : 'eye-slash']"></fa-icon>
              </button>
            </div>
            <div class="form-control-feedback" *ngFor="let err of errors.password">{{ err | translate }}</div>
          </div>

          <div class="form-group" [ngClass]="{ 'has-danger': errors.password_double }">
            <div class="inline middle group-pass">
              <input
                type="{{ !isShowPass ? 'password' : 'text' }}"
                name="password"
                [(ngModel)]="password_double"
                class="form-control"
                placeholder="Подтвердите пароль"
                appInputTrim
              />
              <button class="btn btn-dc-primary show-pass" type="button" (click)="isShowPass = !isShowPass">
                <fa-icon [icon]="['fas', isShowPass ? 'eye' : 'eye-slash']"></fa-icon>
              </button>
            </div>
            <div class="form-control-feedback" *ngFor="let err of errors.password_double">{{ err | translate }}</div>
          </div>

          <div
            class="form-group"
            *ngIf="role === 'admin_of_user' || role === 'partner'"
            [ngClass]="{ 'has-danger': errors.tso_id }"
          >
            <input
              type="text"
              name="tso_id"
              class="form-control input-lg"
              [(ngModel)]="tso_id"
              placeholder="Промо-код агента (опционально)"
              appInputTrim
            />
            <div class="form-control-feedback" *ngFor="let err of errors.tso_id">
              {{ err.replace(tso_id) | translate }}
            </div>
          </div>
          <div class="form-group" [ngClass]="{ 'has-danger': errors.captcha }">
            <re-captcha #captchaRef="reCaptcha" (resolved)="handleCorrectCaptcha($event)"></re-captcha>

            <div class="form-control-feedback" *ngFor="let err of errors.captcha">{{ err | translate }}</div>
          </div>

          <button type="submit" (click)="register()" class="btn btn-lg btn-dc-primary btn-block">
            Зарегистрироваться
          </button>
          <button type="button" [routerLink]="['/signin']" class="btn btn-lg btn-dc-primary btn-block">Вход</button>
        </form>
      </section>
    </div>
    <div class="col-md-4"></div>
  </div>
</div>
