import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { AuthService } from '@app/shared/services/auth.service';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-change-email-modal',
  templateUrl: './change-email-modal.component.html',
  styleUrls: ['./change-email-modal.component.scss'],
})
export class ChangeEmailModalComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public isCorrectPassword: boolean = true;
  @Input() currentEmail: string;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private activeModal: NgbActiveModal,
    private notify: NotificationsService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      current_email: [{ value: this.currentEmail, disabled: true }],
      new_email: [null, [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]+'), Validators.required]],
      current_password: [null, Validators.required],
    });

    this.inputPassword();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.ngUnsubscribe = null;
  }

  public close(): void {
    this.activeModal.close();
  }

  public success(): void {
    const { new_email, current_password } = this.form.value;
    if (this.form.valid) {
      this.authService
        .changeEmail(current_password, new_email)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          () => {
            this.notify.success('Успешно!', 'Для завершения процедуры изменения E-mail, ссылка отправлена');
            this.close();
          },
          (err) => {
            if (err.error.message === 'Current password is incorrect') {
              this.isCorrectPassword = false;
            }
          }
        );
    }
  }

  public inputPassword(): void {
    this.isCorrectPassword = true;
  }
}
