import { cloneDeep } from 'lodash-es';
import { Component, EventEmitter, Input, Output, ViewChild, Inject } from '@angular/core';
import { ITreeOptions, TREE_ACTIONS, TreeComponent, TreeModel, TreeNode } from '@circlon/angular-tree-component';
import { doForAllSync } from '../../../helpers/competence-map.helpers';
import { CompetenceService } from '@app/+competence-map/services/competence.service';
import { FILTERS_CATEGORIES } from '@app/+competence-map/constants/user-filters.constants';
import { CatalogFilterCategory } from '@app/+competence-map/models/user-filters.model';
import { MultipleSearchSuggestion } from '@app/shared/models/multiple-search.model';
import { CMActions } from '@app/+competence-map/constants/projects.constants';
import { FlaskQueryFilter } from '@app/shared/models/filters.model';
import { TreeHelper } from '@app/shared/helpers/tree.helper';
import { ProviderCompetency, TreeSectionsAndFilters } from '@app/+competence-map/models/user-competency.model';
import {
  CompetenceDataService,
  GOODS_COMPETENCE_STATE,
  SERVICES_COMPETENCE_STATE,
} from '@app/+competence-map/services/competence-data.service';
import { UserCompetencyTypes } from '@app/+competence-map/constants/user-competency.constants';
import { switchMap } from 'rxjs/internal/operators';
import { filter, map, takeUntil } from 'rxjs/operators';
import { of } from 'rxjs';
import { CMCatalogTypes, CMSectionStatuses } from '@app/+competence-map/constants/sections.constants';
import { DestroyService } from '@app/services/destroy.service';

@Component({
  selector: 'app-my-catalog-filters',
  templateUrl: './my-catalog-filters.component.html',
  styleUrls: ['./my-catalog-filters.component.scss'],
  providers: [DestroyService],
})
export class MyCatalogFiltersComponent {
  private _catalog: CMCatalogTypes;
  get catalog() {
    return this._catalog;
  }
  @Input() set catalog(value: CMCatalogTypes) {
    this._catalog = value;
    this.initialize();
  }

  private _competencies: ProviderCompetency[];
  get competencies() {
    return this._competencies;
  }
  @Input() set competencies(competencies: ProviderCompetency[]) {
    this._competencies = competencies;

    if (this.state) {
      this.highlightByCompetencies();
    }
  }

  @Input() set sectionData(value: TreeSectionsAndFilters) {
    this.section = value;

    if (!value) {
      return;
    }
    this.data = [];

    this.setCounters();
    if (this.state) {
      this.state.competencySelectionFilters = [];
    }
    this.updateAllSelectedCheckbox();
  }

  @Input() data: TreeSectionsAndFilters[] = [];
  @Input() readonly: boolean;

  @Output() searchEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() onFilterSelect: EventEmitter<TreeSectionsAndFilters> = new EventEmitter<TreeSectionsAndFilters>();

  @ViewChild('filtersTree') private tree: TreeComponent;

  section: TreeSectionsAndFilters;

  treeOptions: ITreeOptions = {
    displayField: 'title',
    isExpandedField: 'expanded',
    idField: 'id',
  };
  filtersCategories: CatalogFilterCategory[] = cloneDeep(FILTERS_CATEGORIES);
  category: CatalogFilterCategory;
  treeSuggestions: MultipleSearchSuggestion[] = [];

  isExpanded = {
    commercial: true,
    standards: true,
    technological: true,
    logistic: true,
  };

  filterConfig: FlaskQueryFilter[];

  modals: {
    [key: string]: {
      isShowed: boolean;
      data?: any;
    };
  } = {};
  CMActions = CMActions;
  allFiltersSelected: boolean;

  filterValues: {
    [key: string]: {
      value: string | MultipleSearchSuggestion[];
    };
  } = {};

  private state: CompetenceDataService;

  get allPartiallySelected() {
    return this.data.length && this.data.some((item) => item.selected) && !this.data.every((item) => item.selected);
  }

  constructor(
    @Inject(SERVICES_COMPETENCE_STATE) private servicesStateService: CompetenceDataService,
    @Inject(GOODS_COMPETENCE_STATE) private goodsStateService: CompetenceDataService,
    private competenceService: CompetenceService,
    private destroy$: DestroyService
  ) {}

  initialize() {
    this.state = this._catalog === CMCatalogTypes.GOODS ? this.goodsStateService : this.servicesStateService;

    this.state.resetSubject.pipe(takeUntil(this.destroy$)).subscribe(() => this.initData());

    this.state.sectionSelectSubject
      .pipe(
        filter(() => !!this.section?.id),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.selectFilterByCurrentSection());
  }

  selectCategory(category: CatalogFilterCategory) {
    this.category = category;

    this.initData();
  }

  filterSelected(event) {
    this.onFilterSelect.emit(event.node.data);
  }

  highlightByCompetencies() {
    if (this.data) {
      doForAllSync(this.data, (item: TreeSectionsAndFilters) => {
        const findedCompetence = this.competencies.find((el) => +el.node_in_tree_id === +item.id);
        const { providerCompetency } = item;

        item.selected = !!findedCompetence && this.section.selected;

        if (findedCompetence && this.section.selected) {
          // сохранённые компетенции
          providerCompetency.value_bool = findedCompetence.value_bool === null ? true : findedCompetence.value_bool;
          providerCompetency.value_range_from = findedCompetence.value_range_from || item.instance_filter.range_from;
          providerCompetency.value_range_to = findedCompetence.value_range_to || item.instance_filter.range_to;
        } else {
          providerCompetency.value_bool = true;
          providerCompetency.value_range_from = item.instance_filter.range_from;
          providerCompetency.value_range_to = item.instance_filter.range_to;
        }
      });
    }

    this.state.competencySelectionFilters = this.data;

    this.updateAllSelectedCheckbox();
    this.searchEvent.emit();
  }

  checkboxSelectionChanged() {
    this.state.filterSelectSubject.next(this.section);
    this.state.competencySelectionFilters = this.data;

    this.updateAllSelectedCheckbox();
    this.searchEvent.emit();
  }

  updateAllSelectedCheckbox() {
    this.allFiltersSelected = this.data.length && this.data.every((item) => item.selected);
  }

  setCounters() {
    this.competenceService
      .getSectionsAndFilters([
        {
          name: 'type',
          op: 'eq',
          val: UserCompetencyTypes.FILTER,
        },
        {
          name: 'parent_id',
          op: 'eq',
          val: this.section.id.toString(),
        },
        {
          name: 'status_filter',
          op: 'eq',
          val: CMSectionStatuses.ACTIVE,
        },
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.filtersCategories.forEach((item) => {
          item.filtersCount = value.filter((el) => el.instance_filter.category === item.value).length;
        });
      });
  }

  initData() {
    if (this.category && !!this.section?.id) {
      this.competenceService
        .getSectionsAndFilters([
          {
            name: 'type',
            op: 'eq',
            val: UserCompetencyTypes.FILTER,
          },
          {
            name: 'status_filter',
            op: 'eq',
            val: CMSectionStatuses.ACTIVE,
          },
          {
            name: 'parent_id',
            op: 'eq',
            val: this.section.id.toString(),
          },
        ])
        .pipe(
          map((sectionsAndFilters) => {
            const result = sectionsAndFilters.map((item) => {
              return {
                ...item,
                parent: {
                  id: item.parent_id,
                },
              };
            });

            return result.filter((el) => el.instance_filter.category === this.category.value);
          }),
          switchMap((sectionsAndFilters) => {
            if (sectionsAndFilters.length) {
              return this.competenceService
                .getSectionsAndFilters([
                  {
                    or: sectionsAndFilters.map((item) => {
                      return {
                        and: [
                          { name: 'left', op: 'gte', val: +item.left },
                          { name: 'right', op: 'lte', val: +item.right },
                          { name: 'tree_id', op: 'eq', val: +item.tree_id },
                        ],
                      };
                    }),
                  },
                  {
                    name: 'status_filter',
                    op: 'eq',
                    val: CMSectionStatuses.ACTIVE,
                  },
                ] as any)
                .pipe(
                  map((flatTree) => {
                    return TreeHelper.arrayToTree(
                      flatTree
                        .filter((childItem) => childItem.type !== 'sector')
                        .map((childItem) => {
                          return {
                            ...childItem,
                            parent: {
                              id: childItem.parent_id,
                            },
                          };
                        })
                    );
                  })
                );
            } else {
              return of([]);
            }
          }),
          takeUntil(this.destroy$)
        )
        .subscribe(
          (value) => {
            this.data = value;

            this.getTreeSuggestions();

            this.highlightByCompetencies();

            setTimeout(() => {
              this.tree.treeModel.expandAll();
            }, 0);
          },
          (err) => {
            this.competenceService.error(err);
          }
        );
    }
  }

  selectFilterByCurrentSection() {
    doForAllSync(this.data, (el: TreeSectionsAndFilters) => {
      el.selected = this.section.selected;
    });

    this.state.competencySelectionFilters = this.data;
    this.updateAllSelectedCheckbox();
    this.searchEvent.emit();
  }

  getTreeSuggestions() {
    this.treeSuggestions = TreeHelper.treeToArray(this.data).map((item) => {
      return {
        id: item.instance_filter.id,
        label: item.instance_filter.path,
        value: true,
      };
    });
  }

  clearFilter() {
    this.tree.treeModel.clearFilter();
  }

  closeActionModal(action: CMActions) {
    this.modals[action] = {
      isShowed: false,
    };
  }

  filterTreeData(query: MultipleSearchSuggestion[]): void {
    this.tree.treeModel.filterNodes((node) => {
      const selected = query.filter((item) => item.value);

      if (!selected.length) {
        return true;
      }

      return selected.map((item) => item.label).includes(node.data.instance_filter.path);
    });

    this.filterValues[CMActions.SEARCH] = {
      value: query,
    };

    this.closeActionModal(CMActions.SEARCH);
  }

  expandItem(tree: TreeModel, node: TreeNode, $event: any): void {
    TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
  }

  openSearchModal() {
    this.modals[CMActions.SEARCH] = {
      isShowed: true,
    };
  }

  selectAll(value: boolean) {
    doForAllSync(this.data, (item) => {
      item.selected = value;
    });

    this.state.filterSelectSubject.next(this.section);
    this.state.competencySelectionFilters = this.data;
    this.searchEvent.emit();
  }

  hasFilterValue(action): boolean {
    return this.filterValues[action] && !!this.filterValues[action].value.length;
  }

  saveBool() {
    this.state.competencySelectionFilters = this.data;
    this.searchEvent.emit();
  }

  saveRange() {
    this.state.competencySelectionFilters = this.data;
    this.searchEvent.emit();
  }
}
