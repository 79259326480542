import {Company} from './company.model';
import {City, Country, Region} from './location.model';
import {User} from './user.model';
import {BaseUser} from './base-user.model';

export class Creator {
  /**
   * Преобразует JSON в класс
   * Вложенные Objects преобразуются во вложенные классы в соответствии с includeClasses
   */
  includeClasses = {
    company: Company,
    owner: BaseUser,
    postal_address: Location,
    registration_address: Location,
    city: City,
    region: Region,
    country: Country,
    editor: User,
  };

  constructor(data = {}) {
    for (const prop in data) {
      if (data.hasOwnProperty(prop)) {
        if (prop in this.includeClasses) {
          this[prop] = new this.includeClasses[prop](data[prop]);
          continue;
        }
        // TODO: Оставить только атрибуты класса
        // if (prop in this) this[prop] = data[prop];
        this[prop] = data[prop];
      }
    }
  }

}
