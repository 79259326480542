import {
  Directive,
  DoCheck,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
  Self,
} from '@angular/core';

import {
  CanUpdateErrorState,
  CanUpdateErrorStateCtor,
  ErrorStateMatcher,
  mixinErrorState,
} from '@angular/material/core';

import { AbstractControl, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { getSupportedInputTypes, Platform } from '@angular/cdk/platform';
import { Subject } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AutofillEvent, AutofillMonitor } from '@angular/cdk/text-field';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';
import {
  FORM_FIELD,
  FormFieldWrapperComponent,
} from '@app/shared/components/form/form-field-wrapper/form-field-wrapper.component';

class InputBase {
  constructor(
    public _defaultErrorStateMatcher: ErrorStateMatcher,
    public _parentForm: NgForm,
    public _parentFormGroup: FormGroupDirective,
    public ngControl: NgControl
  ) {}
}

export abstract class FormFieldControl<T = any> extends MatFormFieldControl<T> {
  readonly checkable: boolean;
  readonly checked: boolean;
  readonly type: string;

  onContainerClick(event: MouseEvent): void {}

  setDescribedByIds(ids: string[]): void {}
}

const FORM_INPUT_INVALID_TYPES = ['button', 'file', 'hidden', 'image', 'range', 'reset', 'submit'];
const FORM_INPUT_CHECKABLE_TYPES = ['checkbox', 'radio'];
const FormInputMixinBase: CanUpdateErrorStateCtor & typeof InputBase = mixinErrorState(InputBase);

let uniqueId = 0;

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: `[formInput], input[formInput], textarea[formInput]`,
  exportAs: 'formInput',
  providers: [{ provide: FormFieldControl, useExisting: FormFieldDirective }],
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    '[attr.placeholder]': 'placeholder',
    '[attr.id]': 'id',
    '[attr.readonly]': 'readonly && !isNativeSelect() || null',
    '[attr.aria-describedby]': 'ariaDescribedBy || null',
    '[attr.aria-invalid]': 'errorState',
    '[attr.aria-required]': 'required.toString()',
    '[disabled]': 'disabled',
    '[required]': 'required',
    '(blur)': 'focusedChanged(false)',
    '(focus)': 'focusedChanged(true)',
    '(input)': 'onInput()',
  },
})
export class FormFieldDirective<T = any>
  extends FormInputMixinBase
  implements FormFieldControl<T>, OnChanges, OnDestroy, OnInit, DoCheck, CanUpdateErrorState {
  readonly stateChanges: Subject<void> = new Subject<void>();
  focused: boolean = false;
  autofilled: boolean = false;
  checkable: boolean = false;
  ariaDescribedBy: string;
  controlType: string = 'form-input';
  protected uid = `form-field-input-${uniqueId++}`;
  protected previousNativeValue: any;
  protected neverEmptyInputTypes = [
    'date',
    'datetime',
    'month',
    'datetime-local',
    'time',
    'week',
  ].filter((value: string) => getSupportedInputTypes().has(value));
  private _inputValueAccessor: { value: any };

  @Input()
  placeholder: string;

  get checked(): boolean {
    return this._elementRef.nativeElement.checked;
  }

  set checked(checked: boolean) {
    this._elementRef.nativeElement.checked = checked;
  }

  get nativeElement(): any {
    return this._elementRef.nativeElement;
  }

  private _readonly = false;

  @Input()
  get readonly(): boolean {
    return this._readonly;
  }

  set readonly(value: boolean) {
    this._readonly = coerceBooleanProperty(value);
  }

  @Input()
  // @ts-ignore
  get value(): string {
    return this._inputValueAccessor.value;
  }

  // @ts-ignore
  set value(value: string) {
    if (value !== this.value) {
      this._inputValueAccessor.value = value;
      this.stateChanges.next();
    }
  }

  protected _type: string = 'text';

  @Input()
  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value || 'text';
    this.validateType();

    if (!this.isTextarea() && getSupportedInputTypes().has(this._type)) {
      (this._elementRef.nativeElement as HTMLInputElement).type = this._type;
    }

    if (FORM_INPUT_CHECKABLE_TYPES.indexOf(this._type) !== -1) {
      setTimeout(() => (this.checkable = true), 0);
    }
  }

  protected _disabled: boolean = false;

  @Input()
  get disabled(): boolean {
    if (this.ngControl && this.ngControl.disabled !== null) {
      return this.ngControl.disabled;
    }
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);

    if (this.focused) {
      this.focused = false;
      this.stateChanges.next();
    }
  }

  protected _id: string;

  @Input()
  get id(): string {
    if (this.formFieldWrapper && this.formFieldWrapper.id) {
      return this.formFieldWrapper.id;
    }
    return this._id;
  }

  set id(value: string) {
    this._id = value || this.uid;
  }

  protected _required: boolean = false;

  @Input()
  get required(): boolean {
    if (this.formFieldWrapper && this.formFieldWrapper.required) {
      return this.formFieldWrapper.required;
    } else {
      if (this.ngControl) {
        this.formFieldWrapper.required = hasRequiredField(this.ngControl.control);
        return hasRequiredField(this.ngControl.control) || this._required;
      } else {
        return this._required;
      }
    }
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }

  get empty(): boolean {
    return !this.autofilled && !this._elementRef.nativeElement.value && !this.isNeverEmpty() && !this.isBadInput();
  }

  get shouldLabelFloat(): boolean {
    if (this.isNativeSelect()) {
      const selectElement = this._elementRef.nativeElement as HTMLSelectElement;
      const firstOption: HTMLOptionElement | undefined = selectElement.options[0];

      return (
        this.focused ||
        selectElement.multiple ||
        !this.empty ||
        !!(selectElement.selectedIndex > -1 && firstOption && firstOption.label)
      );
    } else {
      return this.focused || !this.empty;
    }
  }

  constructor(
    private renderer: Renderer2,
    @Optional() protected _elementRef: ElementRef,
    @Optional() protected _platform: Platform,
    @Optional() _parentForm: NgForm,
    @Optional() _parentFormGroup: FormGroupDirective,
    @Optional() _defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() @Self() @Inject(MAT_INPUT_VALUE_ACCESSOR) inputValueAccessor: unknown,
    @Optional() private _autofillMonitor: AutofillMonitor,
    @Optional() ngZone: NgZone,
    @Optional() @Self() ngControl: NgControl,
    @Optional() @Inject(FORM_FIELD) private formFieldWrapper?: FormFieldWrapperComponent
  ) {
    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);
    const element = this._elementRef.nativeElement;

    this._inputValueAccessor = inputValueAccessor || element;
    this.previousNativeValue = this.value;

    if (_platform.IOS) {
      ngZone.runOutsideAngular(() => {
        _elementRef.nativeElement.addEventListener('keyup', (event: Event) => {
          const el = event.target as HTMLInputElement;
          if (!el.value && !el.selectionStart && !el.selectionEnd) {
            el.setSelectionRange(1, 1);
            el.setSelectionRange(0, 0);
          }
        });
      });
    }

    if (this.isNativeSelect()) {
      this.controlType = (element as HTMLSelectElement).multiple ? 'form-native-select-multiple' : 'form-native-select';
    }
  }

  ngOnInit(): void {
    if (this._platform.isBrowser) {
      this._autofillMonitor.monitor(this._elementRef.nativeElement).subscribe((event: AutofillEvent) => {
        this.autofilled = event.isAutofilled;
        this.stateChanges.next();
      });
    }
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.updateErrorState();
    }

    this.dirtyCheckNativeValue();
  }

  ngOnChanges(): void {
    this.stateChanges.next();
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();

    if (this._platform.isBrowser) {
      this._autofillMonitor.stopMonitoring(this._elementRef.nativeElement);
    }
  }

  focus(options?: FocusOptions): void {
    this._elementRef.nativeElement.focus(options);
  }

  focusedChanged(focus: boolean): void {
    if (focus !== this.focused && (!this.readonly || !focus)) {
      this.focused = focus;
      this.stateChanges.next();
    }
  }

  onInput(): void {}

  setLength(maxLength: number): void {
    this.renderer.setAttribute(this._elementRef.nativeElement, 'maxLength', maxLength.toString());
  }

  clear(): void {
    this.renderer.setValue(this._elementRef.nativeElement, '');
    this.ngControl.control.setValue('');
  }

  onContainerClick(event?: MouseEvent): void {
    if (!this.focused) {
      this.focus();
    }
  }

  setDescribedByIds(ids: string[]): void {
    this.ariaDescribedBy = ids.join(' ');
  }

  isNativeSelect(): boolean {
    return this._elementRef.nativeElement.nodeName.toLowerCase() === 'select';
  }

  isTextarea(): boolean {
    return this._elementRef.nativeElement.nodeName.toLowerCase() === 'textarea';
  }

  protected validateType(): void {
    if (FORM_INPUT_INVALID_TYPES.indexOf(this._type) !== -1) {
      throw Error(`Input type "${this._type}" isn't supported by formInput Directive`);
    }
  }

  protected isBadInput(): boolean {
    const validity = (this._elementRef.nativeElement as HTMLInputElement).validity;
    return validity && validity.badInput;
  }

  protected isNeverEmpty(): boolean {
    return this.neverEmptyInputTypes.indexOf(this._type) !== -1;
  }

  protected dirtyCheckNativeValue(): void {
    const newValue = this._elementRef.nativeElement.value;

    if (this.previousNativeValue !== newValue) {
      this.previousNativeValue = newValue;
      this.stateChanges.next();
    }
  }
}

export const hasRequiredField = (abstractControl: AbstractControl): boolean => {
  if (abstractControl.validator) {
    const validator = abstractControl.validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
  }
  if (abstractControl['controls']) {
    for (const controlName in abstractControl['controls']) {
      if (abstractControl['controls'][controlName]) {
        if (hasRequiredField(abstractControl['controls'][controlName])) {
          return true;
        }
      }
    }
  }
  return false;
};
