<div class="profile-form">
  <div class="profile-form-header">
    <h1 class="header-primary">Личные данные</h1>
    <div class="d-flex mod-align-center header-secondary">
      Пароль и контактная информация
      <button type="button" class="icon-button">
        <app-svg-icon [height]="16" [width]="16" name="note"></app-svg-icon>
      </button>
    </div>
  </div>

  <form [formGroup]="form" class="profile-form-contact profile-form-content" (ngSubmit)="updateUser()">
    <div class="form-body">
      <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
        <dc-form-label>Фамилия:</dc-form-label>
        <input [maxLength]="20" [onlyLetter]="true" formInput placeholder="Фамилия" formControlName="second_name" />
        <div *ngIf="form.get('second_name').touched && form.get('second_name').invalid" class="form-field-error">
          {{ messages.required }}
        </div>
      </dc-form-field-wrapper>

      <dc-form-field-wrapper [style]="'bootstrap'" id="first_name" [vertically]="true">
        <dc-form-label>Имя:</dc-form-label>
        <input [maxLength]="20" [onlyLetter]="true" formInput placeholder="Имя" formControlName="first_name" />
        <div *ngIf="form.get('first_name').touched && form.get('first_name').invalid" class="form-field-error">
          {{ messages.required }}
        </div>
      </dc-form-field-wrapper>

      <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
        <dc-form-label>Отчество:</dc-form-label>
        <input [maxLength]="20" [onlyLetter]="true" formInput placeholder="Отчество" formControlName="patronymic" />
      </dc-form-field-wrapper>

      <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true" required="true">
        <dc-form-label>Телефон:</dc-form-label>
        <div class="phones" formArrayName="phones">
          <div class="phone-form-field" *ngFor="let phone of phones.controls; let i = index">
            <input
              [formControlName]="i"
              [mask]="phoneDynamicMask"
              placeHolderCharacter="*"
              prefix="+"
              [showMaskTyped]="true"
            />
            <button type="button" class="icon-button" *ngIf="i !== 0" (click)="removePhone(i)">
              <app-svg-icon [width]="14" [height]="14" name="delete"></app-svg-icon>
            </button>
          </div>
          <button
            type="button"
            (click)="addPhone()"
            class="icon-button"
            *ngIf="phones.controls.length < 3 && !phones.controls[0]?.invalid"
          >
            <app-svg-icon [width]="14" [height]="14" name="plus"></app-svg-icon>
          </button>
        </div>
        <div class="phones-message" *ngIf="!phones.controls[0]?.invalid">{{ phoneMessage }}</div>
        <div class="form-field-error" *ngIf="phones.controls[0]?.invalid">{{ messages.required }}</div>
      </dc-form-field-wrapper>

      <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true" [required]="true">
        <dc-form-label>E-mail:</dc-form-label>
        <input formInput type="email" formControlName="email" />
        <button type="button" class="icon-button" (click)="changeEmailDialog()">
          <app-svg-icon [width]="16" [height]="16" name="edit"></app-svg-icon>
        </button>
      </dc-form-field-wrapper>

      <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true" [required]="true">
        <dc-form-label>Пароль:</dc-form-label>
        <input formInput type="password" formControlName="password" />
        <button
          type="button"
          class="icon-button"
          (click)="hasNewPassword ? setPasswordDialog() : changePasswordDialog()"
        >
          <app-svg-icon [width]="16" [height]="16" name="edit"></app-svg-icon>
        </button>
      </dc-form-field-wrapper>
    </div>

    <ng-container *ngIf="isOperator">
      <ng-container *ngTemplateOutlet="CHECK_RULES"></ng-container>
    </ng-container>

    <ng-container *ngIf="isAdminOfDirection && !isCanBePayer">
      <ng-container *ngTemplateOutlet="CHECK_RULES"></ng-container>
      <div class="form-section rule-section">
        <div class="form-section-content">
          <div class="d-flex contract-rule">
            <input id="checkContract" class="cbx-input" type="checkbox" formControlName="checkContract" />
            <label class="cbx-label" for="checkContract">
              <span class="cbx-label-text">Я согласен с условиями</span>
            </label>
            <a (click)="getContractRules()">Договора оферты</a>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="form-actions">
      <button class="btn btn-dc-outline" type="button" (click)="cancel()">Отменить</button>
      <button class="btn btn-dc-primary" type="submit" [disabled]="!form.dirty">Сохранить</button>
    </div>

    <ng-template #CHECK_RULES>
      <div class="form-section rule-section">
        <div class="form-section-content">
          <div class="d-flex contract-rule">
            <input id="checkRules" class="cbx-input" type="checkbox" formControlName="checkRules" />
            <label class="cbx-label" for="checkRules">
              <span class="cbx-label-text">Я согласен с</span>
            </label>
            <a (click)="getUserRules()">Правилами</a>
          </div>
        </div>
      </div>
    </ng-template>
  </form>
</div>
