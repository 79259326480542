<dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true" [required]="true" class="timepicker">
  <div (scroller)="changeValue('hour', $event)">
    <app-svg-icon [height]="8" [width]="12" name="up" (click)="changeValue('hour', +1)"></app-svg-icon>
    {{padStartString(hour)}}
    <app-svg-icon [height]="8" [width]="12" name="down" (click)="changeValue('hour', -1)"></app-svg-icon>
  </div>
  :
  <div (scroller)="changeValue('minute', $event)">
    <app-svg-icon [height]="8" [width]="12" name="up" (click)="changeValue('minute', +1)"></app-svg-icon>
    {{padStartString(minute)}}
    <app-svg-icon [height]="8" [width]="12" name="down" (click)="changeValue('minute', -1)"></app-svg-icon>
  </div>

  <button type="button" class="icon-button" (click)="selectTime()" #buttonTime>
    <app-svg-icon [height]="16" [width]="16" name="ok"></app-svg-icon>
  </button>
</dc-form-field-wrapper>
