import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[scroller]'
})
export class ScrollerDirective {

  @Output()
  scroller = new EventEmitter<number>();

  @HostListener('wheel', ['$event'])
  scrollerEvent(event) {
    if (event.deltaY < 0) {
      this.scroller.emit(1);
    } else {
      this.scroller.emit(-1);
    }
  }

}
