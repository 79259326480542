import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TreeNode } from '@circlon/angular-tree-component';

import { DCTreeItem } from '@app/+competence-map/models/competence-map.models';
import { CMCatalogTypes } from '@app/+competence-map/constants/sections.constants';

@Component({
  selector: 'app-file-manager-share-user-filter-tree-item',
  templateUrl: './file-manager-share-user-filter-tree-item.component.html',
  styleUrls: ['./file-manager-share-user-filter-tree-item.component.scss'],
})
export class FileManagerShareUserFilterTreeItemComponent {
  @Input() node: TreeNode;
  @Input() item: DCTreeItem;
  @Input() type: CMCatalogTypes;

  @Output() onToggle = new EventEmitter();
  @Output() onSelect = new EventEmitter<DCTreeItem>();

  toggleSelected(items: DCTreeItem[]) {
    items.forEach((item: DCTreeItem) => {
      item.selected = this.item.selected;
      this.onSelect.emit(item);
      if (item.children) {
        this.toggleSelected(item.children);
      }
    });
  }

  toggleNodeClicked(): void {
    this.onToggle.emit();
  }

  onCheckboxChange(event: Event): void {
    event.stopPropagation();
    this.item.selected = !this.item.selected;
    this.onSelect.emit(this.item);
    if (this.node && this.item.children) {
      this.toggleSelected(this.item.children);
    }
  }
}
