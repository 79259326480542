import { Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { TabValidator } from '../models/trade-card-tabs.model';
import { FormGroup } from '@angular/forms';
import { SupplierLotGroup } from '../form-types/lots';
import { FormArray } from '@angular/forms';

@Injectable()
export class TradesValidationService {
  _tradeCardTabValidator$: Subject<TabValidator>;

  constructor() {
    this._tradeCardTabValidator$ = new Subject<TabValidator>();
  }

  /**
   * Observable следящий за изменением состояния валидности стейта вкладки
   * @returns {Observable<TabValidator>}
   */
  get tradeCardTabValidator$() {
    return this._tradeCardTabValidator$.asObservable();
  }

  /**
   * Оповещение об изменении состояния валидности стейта вкладки
   * @returns {Observable<TabValidator>}
   */
  set tradeTabValidator$(tabValidator: TabValidator) {
    this._tradeCardTabValidator$.next(tabValidator);
  }

  validateControls(form: FormArray<FormGroup<SupplierLotGroup>>) {
    for (const key in form.controls) {
      if (form.controls.hasOwnProperty(key)) {
        form.controls[key].markAsTouched();
        form.controls[key].updateValueAndValidity();
      }
    }
  }
}
