import { FilterTemplateStatuses, FilterTemplateTypes } from '../constants/filter-templates.constants';
import { User } from '@app/shared/models/user.model';
import { UnitsMeasure } from '@app/+competence-map/models/units.model';

export interface FilterNode {
  id?: string;
  title: string;
  type: FilterTemplateTypes;
}

export interface FilterTemplate extends FilterNode {
  index?: number;
  node_id?: string;
  value?: string;
  status?: FilterTemplateStatuses;
  author_id?: string;
  author?: User;
  units_of_measure?: string;
  units_of_measure_id?: string;
  checked?: boolean;
  created_at?: string;
  level?: number;
  list_template_filters_ids?: number[];
  mirror_template_filter_id?: number;
  default_range_from?: number;
  default_range_to?: number;
  default_bool?: boolean;
  children?: FilterTemplate[];
  isExpanded?: boolean;
  isExpandedAll?: boolean;
  virtualLevel?: number;
  parent_id?: string | null;
  searchedInactive?: boolean;
  units_of_measure_relation?: UnitsMeasure;
}

export interface CreateOrChangeFilterTemplateParams {
  index?: number;
  node_id?: string;
  node_info: FilterTemplate;
  childes?: CreateOrChangeFilterTemplateParams[];
  template_filter_ids?: number[];
  unload_mirrors?: boolean;
}

export interface CopyFilterTemplateParams extends CreateOrChangeFilterTemplateParams {
  donor_node_id?: number;
}

export interface UpdateFilterTemplateParams {
  id?: string;
  status?: FilterTemplateStatuses;
  title?: string;
  units_of_measure_id?: number;
}

export enum TreeAction {
  ADD = 'add',
  DELETE = 'delete',
  EDIT = 'edit',
  ROLLBACK = 'rollback',
}

export interface TreeDataAction {
  action: TreeAction;
  data: CreateOrChangeFilterTemplateParams;
}

export interface SelectedTemplateFilter {
  id: string;
  action: TreeAction;
  item?: CreateOrChangeFilterTemplateParams;
}
