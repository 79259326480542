import { Injectable, InjectionToken } from '@angular/core';
import { TreeSectionsAndFilters } from '@app/+competence-map/models/user-competency.model';
import { Subject } from 'rxjs';

export const SERVICES_COMPETENCE_STATE = new InjectionToken<CompetenceDataService>('SERVICES_COMPETENCE_STATE');
export const GOODS_COMPETENCE_STATE = new InjectionToken<CompetenceDataService>('GOODS_COMPETENCE_STATE');

@Injectable()
export class CompetenceDataService {
  private _competencySelectionFilters: TreeSectionsAndFilters[] = [];
  private _competencySelectionSections: TreeSectionsAndFilters[] = [];

  sectionSelectSubject = new Subject();
  filterSelectSubject = new Subject<TreeSectionsAndFilters>();

  resetSubject = new Subject();

  constructor() {}

  set competencySelectionSections(value: TreeSectionsAndFilters[]) {
    this._competencySelectionSections = value;
  }

  get competencySelectionSections(): TreeSectionsAndFilters[] {
    return this._competencySelectionSections;
  }

  set competencySelectionFilters(value: TreeSectionsAndFilters[]) {
    this._competencySelectionFilters = value;
  }

  get competencySelectionFilters(): TreeSectionsAndFilters[] {
    return this._competencySelectionFilters;
  }
}
