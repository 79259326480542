import { AbstractControl, FormArray, ValidationErrors, ValidatorFn } from '@angular/forms';

export const notMatchPrimitiveItems: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (control.parent instanceof FormArray && control.parent.value?.length > 1 && !!control.value) {
    const formArray = control.parent;

    const hasMatchMoreThenOne =
      formArray.value.filter((_: any, index: number) => formArray.at(index).value === control.value).length > 1;

    if (hasMatchMoreThenOne) {
      return { hasMatches: true };
    }

    return null;
  }
  return null;
};
