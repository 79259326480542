<div (click)="cancel()" class="dc-fileman-modal-x-button">
  <app-svg-icon [width]="20" [height]="20" name="close"></app-svg-icon>
</div>

<div class="preview-document">
  <div class="preview-document__header">
    <div class="preview-document__header-title">
      {{ file?.name }}
    </div>

    <div class="preview-document__header-actions">
      <button class="dc-toolbar-btn margin-r-10" (click)="downloadFile()">
        <app-svg-icon width="18" height="18" name="save"></app-svg-icon>
      </button>

      <button class="dc-toolbar-btn" (click)="printFile()" [disabled]="!isPdfFile && !isImageFile">
        <app-svg-icon width="18" height="18" name="print"></app-svg-icon>
      </button>
    </div>
  </div>

  <div class="preview-document__content">
    <ng-container *ngIf="isDocFile">
      <ngx-doc-viewer [viewer]="viewer" [url]="fileUrl" class="preview-document__viewer"></ngx-doc-viewer>
    </ng-container>

    <ng-container *ngIf="isImageFile">
      <div *ngIf="isLoading" class="preview-document__content-message">
        <h5>Подготавливается просмотр документа, пожалуйста подождите</h5>
      </div>
      <img [src]="file?.url" alt="" (load)="isLoading = false" />
    </ng-container>

    <ng-container *ngIf="isShowIconFile">
      <div class="preview-document__content-message">
        <app-svg-icon height="100" width="100" [name]="file?.fileExtType()?.icon"></app-svg-icon>
        <h5 class="margin-t-20 text-center">
          <div>Файл данного формата невозможно открыть из сервиса ПК.</div>
          <div>Для просмотра файла, пожалуйста, скачайте его на свой компьютер</div>
        </h5>
      </div>
    </ng-container>
  </div>
</div>
