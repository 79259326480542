<div class="setting-notification">
  <div class="setting-notification__row">
    <table #tableScrollFile class="table table-sm table-hover table-bordered setting-notification__row__table">
      <colgroup>
        <col *ngFor="let column of columns" [style.width]="column.width || '96px'" />
      </colgroup>
      <thead>
        <tr class="fixed-header va-t">
          <td *ngFor="let column of columns" class="va-t">
            {{ column.label }}
          </td>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="dataSource?.length; else emptymessage">
          <tr *ngFor="let parse of dataSource; let i = index">
            <td>
              {{ parse.details?.event_description }}
            </td>
            <td>
              <a class="d-print-none">
                <input [(ngModel)]="parse.flags.mail" class="cbx-input" id="mail_{{ i }}" type="checkbox" />
                <label class="cbx-label" for="mail_{{ i }}"> </label>
              </a>
            </td>
            <td>
              <a class="d-print-none">
                <input [(ngModel)]="parse.flags.status_bar" class="cbx-input" id="status_bar_{{ i }}" type="checkbox" />
                <label class="cbx-label" for="status_bar_{{ i }}"> </label>
              </a>
            </td>
          </tr>
        </ng-container>
        <ng-template #emptymessage>
          <tr>
            <td [attr.colspan]="columns.length" class="table-no-data">
              {{ loading ? 'Загрузка...' : 'Нет доступных настроек' }}
            </td>
          </tr>
        </ng-template>
      </tbody>
    </table>
  </div>
  <div class="d-flex justify-content-between setting-notification__save">
    <button (click)="resetSetting()" class="btn btn-dc-outline">Отменить</button>
    <button (click)="saveSetting()" class="btn btn-dc-primary">Сохранить</button>
  </div>
</div>
