import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserFile, UserFileZip } from '../models/user-file.model';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FILE_MANAGER_LAYOUTS, LegendInterface, VIEW_MODE_TYPE } from '../file_manager.constants';
import { ToolbarButton } from '@app/file_manager/models/file-manager-toolbar.model';
import { FileManagerService } from '../services/file_manager.service';
import { AuthService } from '@app/shared/services/auth.service';

@Component({
  selector: 'app-file-manager-toolbar',
  templateUrl: './file-manager-toolbar.component.html',
  styleUrls: ['./file-manager-toolbar.component.scss'],
})
export class FileManagerToolbarComponent implements OnInit {
  @Input() selected: UserFile[] = [];
  @Input() buffer: UserFile[] = [];
  @Input() legends: LegendInterface[] = [];
  @Input() files: UserFile[] = [];
  @Input() history: UserFile[] = [];
  @Input() current: UserFile;
  @Input() layout: FILE_MANAGER_LAYOUTS;
  @Input() view: VIEW_MODE_TYPE;
  @Input() disabledExportButton: boolean = false;

  @Output() onAdd = new EventEmitter();
  @Output() onEdit = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @Output() onShare = new EventEmitter();
  @Output() onUpload = new EventEmitter();
  @Output() onDownload = new EventEmitter();
  @Output() onCut = new EventEmitter();
  @Output() onCopy = new EventEmitter();
  @Output() onPaste = new EventEmitter();
  @Output() onBack = new EventEmitter();
  @Output() onForward = new EventEmitter();
  @Output() onView = new EventEmitter();
  @Output() onResetSelected = new EventEmitter();
  @Output() onExport = new EventEmitter<UserFile[]>();

  VIEW_TYPE = VIEW_MODE_TYPE;
  hasLegend: boolean = true;
  hasEditForm: boolean = false;
  editForm: FormGroup;

  modal: any = {
    add: {
      isOpen: false,
      action: ($event?: any) => {
        this.add(this.getEditFormNameControl().value);
        this.modal.add.close();
      },
      close: () => (this.modal.add.isOpen = false),
    },
    edit: {
      isOpen: false,
      action: ($event?: any) => {
        this.edit(this.getEditFormNameControl().value);
        this.modal.edit.close();
      },
      close: () => (this.modal.edit.isOpen = false),
    },
    archive: {
      isOpen: false,
      archives: [],
      action: () => {},
      close: () => {
        this.modal.archive.isOpen = false;
      },
    },
    upload: {
      isOpen: false,
      action: ($event?: any) => {
        this.upload($event);
      },
      close: () => {
        this.modal.upload.isOpen = false;
      },
    },
    delete: {
      isOpen: false,
      action: () => {
        this.delete(this.selected);
        this.modal.delete.close();
      },
      close: () => (this.modal.delete.isOpen = false),
    },
  };

  buttons: ToolbarButton[] = this.getButtons();
  buttonsNav: ToolbarButton[] = this.getButtonsNav();

  constructor(private fileManagerService: FileManagerService, private user: AuthService, private fb: FormBuilder) {}

  ngOnInit() {
    this.buttons = this.getButtons();
    this.buttonsNav = this.getButtonsNav();
    this.editForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(100)]],
    });
  }

  getButtons(): ToolbarButton[] {
    return [
      {
        name: 'Создать папку',
        disabled: () => !this.canAddFolder(),
        click: () => {
          this.hasEditForm = false;
          this.resetEditFormNameControl('');
          this.modal.add.isOpen = true;
        },
        icon: 'folder_plus',
        showed: this.layout === FILE_MANAGER_LAYOUTS.BASE || this.layout === FILE_MANAGER_LAYOUTS.EXPORT,
      },
      {
        name: 'Вырезать',
        disabled: () => !this.canCutFile(),
        click: () => {
          this.cut();
        },
        icon: 'cut',
        showed: this.layout === FILE_MANAGER_LAYOUTS.BASE,
      },
      {
        name: 'Копировать',
        disabled: () => !this.canCopyFile(),
        click: () => {
          this.copy();
        },
        icon: 'copy_file',
        showed: this.layout === FILE_MANAGER_LAYOUTS.BASE || this.layout === FILE_MANAGER_LAYOUTS.EXPORT,
      },
      {
        name: 'Вставить',
        disabled: () => !this.canPasteFile(),
        click: () => {
          this.paste();
        },
        icon: 'paste',
        showed: this.layout === FILE_MANAGER_LAYOUTS.BASE || this.layout === FILE_MANAGER_LAYOUTS.EXPORT,
      },
      {
        name: 'Переименовать',
        disabled: () => !this.canEditFile(),
        click: () => {
          this.hasEditForm = true;
          this.resetEditFormNameControl(this.selected[0].basename());
          this.modal.edit.isOpen = true;
        },
        icon: 'edit_list',
        showed: this.layout === FILE_MANAGER_LAYOUTS.BASE,
      },
      {
        name: 'Загрузить с компьютера',
        disabled: () => !this.canUploadFile(),
        hasUpload: true,
        click: () => {
          this.fileManagerService.legend(+this.user.user_id).subscribe((data: UserFile[]) => {
            this.legends = Object.assign(
              this.legends,
              data.map((file: UserFile) => {
                const legend: LegendInterface = {
                  file: file,
                  status: 100,
                  error: null,
                  subscribe: null,
                  ready: true,
                };
                return legend;
              })
            );
            this.modal.upload.isOpen = true;
          });
        },
        icon: 'upload',
        showed: this.layout === FILE_MANAGER_LAYOUTS.BASE || this.layout === FILE_MANAGER_LAYOUTS.EXPORT,
      },
      {
        name: 'Скачать',
        disabled: () => !this.canDownloadFile(),
        click: () => {
          this.download(this.selected);
        },
        icon: 'file-download',
        showed: this.layout === FILE_MANAGER_LAYOUTS.BASE,
      },
      {
        name: 'Архив',
        disabled: () => !this.canDownloadArchive(),
        click: () => {
          this.fileManagerService.getZips(+this.user.user_id).subscribe((data: UserFileZip[]) => {
            this.modal.archive.archives = data;
            this.modal.archive.isOpen = true;
          });
        },
        icon: 'archives',
        showed: this.layout === FILE_MANAGER_LAYOUTS.BASE,
      },
      {
        name: 'Управление совместным доступом',
        disabled: () => !this.canShare(),
        click: () => {
          this.share(this.selected);
        },
        icon: 'users',
        showed: this.layout === FILE_MANAGER_LAYOUTS.BASE,
      },
      {
        name: 'Удалить',
        disabled: () => !this.canDeleteFile(),
        click: () => {
          this.modal.delete.isOpen = true;
        },
        icon: 'trash',
        showed: this.layout === FILE_MANAGER_LAYOUTS.BASE,
      },
      {
        name: 'Добавить',
        disabled: () => !this.canExportFileForTrades(),
        click: () => {
          this.export(this.selected);
        },
        icon: 'arrow-circle-right',
        label: 'Добавить в КТ',
        showed: this.layout === FILE_MANAGER_LAYOUTS.TRADES,
      },
      {
        name: 'Добавить',
        disabled: () => !this.canExportFile(),
        click: () => {
          this.export(this.selected);
        },
        icon: 'arrow-circle-right',
        label: 'Добавить',
        showed: this.layout === FILE_MANAGER_LAYOUTS.EXPORT,
      },
    ];
  }

  getButtonsNav(): ToolbarButton[] {
    return [
      {
        name: 'Назад',
        disabled: () => this.current.hasRoot(),
        hidden: () => false,
        click: () => {
          this.back();
        },
        icon: 'arrow-left',
        showed: this.layout === FILE_MANAGER_LAYOUTS.BASE,
      },
      {
        name: 'Вперед',
        disabled: () => this.hasHistory(),
        hidden: () => false,
        click: () => {
          this.forward();
        },
        icon: 'arrow-right',
        showed: this.layout === FILE_MANAGER_LAYOUTS.BASE,
      },
      {
        name: 'Вид списком',
        disabled: () => false,
        hidden: () => this.view === this.VIEW_TYPE.LIST,
        click: () => {
          this.changeView();
        },
        icon: 'list',
        showed: this.layout === FILE_MANAGER_LAYOUTS.BASE,
      },
      {
        name: 'Вид иконками',
        disabled: () => false,
        hidden: () => this.view === this.VIEW_TYPE.DETAIL,
        click: () => {
          this.changeView();
        },
        icon: 'th-large',
        showed: this.layout === FILE_MANAGER_LAYOUTS.BASE,
      },
      {
        name: 'Справка',
        disabled: () => false,
        hidden: () => false,
        click: () => {
          this.fileManagerService.openHelper();
        },
        icon: 'question-circle-color',
        showed: this.layout === FILE_MANAGER_LAYOUTS.BASE,
      },
    ];
  }

  canAddFolder(): boolean {
    return !this.current.share;
  }

  canCutFile(): boolean {
    return this.selected.length > 0;
  }

  canCopyFile(): boolean {
    return this.selected.length > 0;
  }

  canPasteFile(): boolean {
    return this.buffer.length > 0;
  }

  canEditFile(): boolean {
    return this.selected.length === 1;
  }

  canUploadFile(): boolean {
    return true;
  }

  canDownloadArchive(): boolean {
    return true;
  }

  canDownloadFile(): boolean {
    return this.selected.filter((f: UserFile) => f.id > 0).length > 0;
  }

  canDeleteFile(): boolean {
    return this.selected.length > 0;
  }

  canShare(): boolean {
    return this.selected.length > 0;
  }

  canExportFileForTrades(): boolean {
    return (
      this.selected &&
      this.selected.length > 0 &&
      this.selected.every((selected) => selected.is_file && (selected.for_customers || selected.for_providers))
    );
  }

  canExportFile(): boolean {
    return !this.disabledExportButton && this.selected && !!this.selected.length;
  }

  add($event?: any): void {
    this.onAdd.emit($event);
  }

  edit($event?: any): void {
    this.onEdit.emit($event);
  }

  delete($event?: any): void {
    this.onDelete.emit($event);
  }

  upload($event?: any): void {
    this.onUpload.emit($event);
  }

  download($event?: any): void {
    this.onDownload.emit($event);
  }

  share($event?: any): void {
    this.onShare.emit($event);
  }

  copy($event?: any): void {
    this.onCopy.emit($event);
  }

  paste($event?: any): void {
    this.onPaste.emit($event);
  }

  cut($event?: any): void {
    this.onCut.emit($event);
  }

  export($event?: any): void {
    this.onExport.emit($event);
  }

  back(): void {
    this.onBack.emit();
  }

  forward(): void {
    this.onForward.emit();
  }

  changeView(): void {
    this.onView.emit();
  }

  resetSelected(): void {
    this.onResetSelected.emit();
  }

  abort($event, legend: LegendInterface): boolean {
    $event.stopPropagation();
    $event.preventDefault();
    if (legend.status !== 100) {
      legend.subscribe.unsubscribe();
      legend.status = 100;
      legend.ready = true;
    }
    this.legends.splice(this.legends.indexOf(legend), 1);
    return false;
  }

  getArchives(): UserFileZip[] {
    return this.modal.archive.archives;
  }

  downloadArchive(file: UserFileZip): void {
    window.open(file.url, '_blank');
  }

  getFilesNameBySelected(): string {
    return this.selected.map((file: UserFile) => file.name).join(', ');
  }

  private getEditFormNameControl(): AbstractControl {
    return this.editForm.controls.name;
  }

  private resetEditFormNameControl(name?: string): void {
    this.getEditFormNameControl().reset(name || '');
  }

  private hasHistory(): boolean {
    return this.history.length === 0;
  }
}
