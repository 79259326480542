import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CMCatalogTypes } from '@app/+competence-map/constants/sections.constants';
import {
  ProviderCompetency,
  TradeCompetency,
  TreeSectionsAndFilters,
} from '@app/+competence-map/models/user-competency.model';

@Component({
  selector: 'app-my-catalog',
  templateUrl: './my-catalog.component.html',
  styleUrls: ['./my-catalog.component.scss'],
})
export class MyCatalogComponent {
  @Input() catalog: CMCatalogTypes;
  @Input() readonly: boolean;
  @Input() competencies: ProviderCompetency[] | TradeCompetency[];
  @Input() isExpert: boolean;
  @Input() isMyCompetencies = false;

  @Output() searchEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() onShowedMyCompetencies: EventEmitter<{
    nodes: TreeSectionsAndFilters[];
    hiddenIds: { [key: number]: boolean };
  }> = new EventEmitter<{
    nodes: TreeSectionsAndFilters[];
    hiddenIds: { [key: number]: boolean };
  }>();

  selectedSection: TreeSectionsAndFilters;

  onSearch() {
    this.searchEvent.emit();
  }

  sectionSelected(data: TreeSectionsAndFilters): void {
    this.selectedSection = data;
  }

  showedMyCompetencies(event: { nodes: TreeSectionsAndFilters[]; hiddenIds: { [key: number]: boolean } }) {
    this.selectedSection = null;
    this.onShowedMyCompetencies.emit(event);
  }
}
