<div class="d-flex pl-4 mb-4 blue-links">
  <div class="mr-2">
    <a (click)="active = 0" [ngClass]="{ active: active === 0 }">Товары</a>
  </div>
  <div>
    <a (click)="active = 1" [ngClass]="{ active: active === 1 }">Услуги</a>
  </div>
</div>

<div class="pl-4 mb-4">
  <div *ngIf="!goods?.length">Нет товаров</div>

  <div [hidden]="active === 1">
    <input
      class="kt-input mb-2"
      type="text"
      *ngIf="goods?.length"
      (keyup)="filterTree($event, goodsTree)"
      placeholder="Поиск"
    />

    <tree-root #goodsTree [nodes]="goods" [options]="customTemplateStringOptions">
      <ng-template #treeNodeTemplate let-node>
        <div class="mb-2">
          <input
            [id]="node.data.id"
            class="cbx-input"
            [(ngModel)]="node.data.selected"
            (ngModelChange)="change()"
            type="checkbox"
          />
          <label class="cbx-label" [for]="node.data.id">
            <span class="cbx-label-text" [ngbTooltip]="node.data.title">{{ node.data.title }}</span>
          </label>
        </div>
      </ng-template>
    </tree-root>
  </div>
  <div [hidden]="active === 0">
    <div *ngIf="!services?.length">Нет услуг</div>
    <input
      class="kt-input mb-2"
      type="text"
      *ngIf="services?.length"
      (keyup)="filterTree($event, servicesTree)"
      placeholder="Поиск"
    />

    <tree-root #servicesTree [nodes]="services" [options]="customTemplateStringOptions">
      <ng-template #treeNodeTemplate let-node>
        <div class="mb-2">
          <input
            [id]="node.data.id"
            class="cbx-input"
            [(ngModel)]="node.data.selected"
            (ngModelChange)="change()"
            type="checkbox"
          />
          <label class="cbx-label" [for]="node.data.id">
            <span class="cbx-label-text" [ngbTooltip]="node.data.title">{{ node.data.title }}</span>
          </label>
        </div>
      </ng-template>
    </tree-root>
  </div>
</div>
<div class="d-flex justify-content-center" *ngIf="hasSubmit">
  <button class="btn btn-dc-primary" (click)="apply()">Фильтровать</button>
</div>
