import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { saveAs } from 'file-saver';

import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';

import { catchError, takeUntil } from 'rxjs/operators';

import { NotificationsService } from 'angular2-notifications';
import { FileManagerModalArchiveUploadComponent } from '@app/file-manager/components/file-manager-modal-archive-upload/file-manager-modal-archive-upload.component';
import { NotificationService } from '@app/notification/services/notification.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  NOTIFICATION_CREATE_ZIP,
  NOTIFICATION_NOT_ENOUGH_FREE_SPACE,
  NOTIFICATION_NOT_FREE_SPACE,
} from '@app/shared/constants/file-manager.constants';
import {
  FileManagerHistoryAllInterface,
  FileManagerHistoryInterface,
} from '@app/file-manager/models/file-manager-state.interface';

@Injectable({
  providedIn: 'root',
})
export class FileManagerDataService implements OnDestroy {
  history: FileManagerHistoryAllInterface = {
    back: [],
    forward: [],
  };

  private destroy$ = new Subject();

  private isUploadFileProcessSubject = new BehaviorSubject<boolean>(false);
  isUploadFileProcess$ = this.isUploadFileProcessSubject.asObservable();

  constructor(
    private readonly httpClient: HttpClient,
    private readonly notify: NotificationsService,
    private readonly notificationService: NotificationService,
    private readonly modalService: NgbModal
  ) {
    this.notificationService.messageFiles$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ type, source, sending_date, text }) => {
        switch (type) {
          case NOTIFICATION_CREATE_ZIP: {
            const modal = this.modalService.open(FileManagerModalArchiveUploadComponent, {
              centered: true,
              windowClass: 'dc-modal modal-window',
              animation: true,
            });
            modal.result.then(
              (result) => {
                if (result) {
                  this.downloadFile(source?.params?.path, this.filenameByUrl(source?.params?.path));
                }
              },
              () => {}
            );
            break;
          }
          case NOTIFICATION_NOT_ENOUGH_FREE_SPACE: {
            if (source?.params?.type === 'warning') {
              this.notify.warn(`Внимание!`, `${text}`);
            } else {
              this.notify.error(`Внимание!`, `${text}`);
            }
            break;
          }

          case NOTIFICATION_NOT_FREE_SPACE: {
            this.notify.error(`Внимание!`, `${text}`);
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeUploadProcess(status: boolean): void {
    this.isUploadFileProcessSubject.next(status);
  }

  /**
   * Скачинвание одного файла по ссылке
   * @param link
   */
  downloadFileByLink(link: string): Observable<Blob> {
    return this.httpClient
      .get(`${link}`, {
        responseType: 'blob',
        params: {
          disposition: 'attachment',
        },
      })
      .pipe(catchError((err) => throwError(err)));
  }

  downloadFile(link: string, filename: string): void {
    try {
      saveAs(link, filename);
      this.notify.success('Успешно', 'Файл успешно загружен');
    } catch (error) {
      this.notify.error('Внимание', 'Не удалось загрузить файл');
    }
  }

  uniqueArray(list: FileManagerHistoryInterface[]): FileManagerHistoryInterface[] {
    const seen = {};
    const result = [];
    let index = 0;

    for (let i = 0; i < list.length; i++) {
      const currentItem = list[i];
      const currentItemEvent = currentItem.event;
      const key = `${currentItemEvent}_${currentItem.file.current.id}`;
      if (!seen[key]) {
        seen[key] = 1;
        result[index++] = currentItem;
      }
    }

    return result;
  }

  filenameByUrl(link: string): string | undefined {
    const linkParts = link.split('/');
    const filename = linkParts[linkParts?.length - 1];
    if (filename) {
      return filename;
    }
    return undefined;
  }
}
