<ul class="file-manager__tree" [class.file-manager__tree-root]="isRoot">
  <ng-container *ngIf="files?.length">
    <li
      *ngFor="let file of files"
      class="file-manager__tree-item"
      [class.file-manager__tree-item-file]="file?.hasFile()"
      [class.file-selected]="file?.id === selected?.id"
      [class.file-opened]="file?.virtualOpened"
    >
      <ng-container *ngIf="!file?.hasFile()">
        <app-file-manager-tree-folder
          [file]="file"
          [isRoot]="isRoot"
          [isSearchQuery]="isSearchQuery"
          [searchQuery]="searchQuery"
          (onSelectFile)="selectFile(file)"
          (onOpenSelectFile)="openSelectFile(file)"
          (onExpandToggleFile)="toggleFile(file)"
          (onUserInfoFile)="showUserInfoFile(file)"
          (onShareFile)="shareFile([file])"
          (onHelpFile)="showHelpFile()"
        ></app-file-manager-tree-folder>
        <app-file-manager-tree
          *ngIf="file?.virtualOpened"
          [files]="file?.virtualChildren"
          [isSearchQuery]="isSearchQuery"
          [searchQuery]="searchQuery"
          [selected]="selected"
          [openDelayTooltip]="openDelayTooltip"
          (onToggleFile)="toggleFile($event)"
          (onSelectFile)="selectFile($event)"
          (onShareFile)="shareFile($event)"
          (onOpenSelectFile)="openSelectFile($event)"
          (onShowUserInfo)="showUserInfoFile($event)"
        ></app-file-manager-tree>
      </ng-container>
      <ng-container *ngIf="file?.hasFile()">
        <app-file-manager-tree-file
          [file]="file"
          [searchQuery]="searchQuery"
          (onSelectFile)="selectFile(file)"
          (onOpenSelectFile)="openSelectFile(file)"
          (onExpandToggleFile)="toggleFile(file)"
          (onUserInfoFile)="showUserInfoFile(file)"
          (onShareFile)="shareFile([file])"
        ></app-file-manager-tree-file>
      </ng-container>
    </li>
  </ng-container>

  <li *ngIf="!files?.length" class="file-manager__tree-empty">
    <span>Папка пуста</span>
  </li>
</ul>
