import { Component, Input, OnInit } from '@angular/core';
import { CompetenceService } from '@app/+competence-map/services/competence.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CMCatalogTypes } from '@app/+competence-map/constants/sections.constants';
import { Project } from '@app/+competence-map/models/projects.models';
import { CMActions, CMProjectStatuses } from '@app/+competence-map/constants/projects.constants';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';
import { fromEvent, Observable, of } from 'rxjs';
import { CompetenceSectionsService } from '@app/+competence-map/services/competence-sections.service';
import { DCTreeItem } from '@app/+competence-map/models/competence-map.models';
import { ProjectBase, ProjectBaseComponent } from '@app/+competence-map/models/project.class';
import { PortalService } from '@app/shared/services/portal.service';
import { AuthService } from '@app/shared/services/auth.service';
import { WordsStorageService } from '@app/+competence-map/services/words-storage.service';
import { DestroyService } from '@app/services/destroy.service';
import { HttpErrorResponse } from '@angular/common/http';

import { notMatchPrimitiveItems } from '@app/+competence-map/validators/validators';

@Component({
  selector: 'app-add-section',
  templateUrl: './add-section.component.html',
  styleUrls: ['./add-section.component.scss'],
  providers: [PortalService, DestroyService],
})
export class AddSectionComponent extends ProjectBaseComponent implements OnInit, ProjectBase {
  @Input() catalog: CMCatalogTypes;
  @Input() path: string;

  form: FormGroup;
  sections: FormArray;

  words$: Observable<string[]>;

  constructor(
    private competenceService: CompetenceService,
    private competenceSectionsService: CompetenceSectionsService,
    private fb: FormBuilder,
    private destroy$: DestroyService,
    protected portalService: PortalService,
    protected authService: AuthService,
    private wordsStorageService: WordsStorageService
  ) {
    super(portalService, authService);
  }

  get level(): number {
    const counter = 1;

    if (this.project && this.project.spec[this.project.action].length) {
      return this.project.spec[this.project.action][0].level;
    }
    return (this.parent && this.parent.level && +this.parent.level + counter) || counter;
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  search(event, ref) {
    this.words$ = fromEvent(ref, 'keyup').pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((event: any) =>
        this.wordsStorageService.getWords([
          {
            name: 'value',
            op: 'ilike',
            val: event.target.value,
          },
        ])
      ),
      map((words) => words.map((word) => word.value)),
      takeUntil(this.destroy$)
    );
  }

  addName(): void {
    this.clearErrors();

    this.sections.push(this.fb.control('', [Validators.required, notMatchPrimitiveItems]));
  }

  removeSection(i: number) {
    this.clearErrors();
    this.sections.removeAt(i);
  }

  private initializeForm(): void {
    const names = this.project ? this.project.spec[this.project.action].map((spec) => spec.title) : [''];
    this.sections = this.fb.array(
      names.map((name) => {
        return this.fb.control(name, [Validators.required, notMatchPrimitiveItems]);
      })
    );

    this.form = this.fb.group({
      sections: this.sections,
    });

    if (this.project) {
      this.catalog = this.project.spec[this.project.action][0]?.catalog;
      this.path = this.project.path;
    }
  }

  addProject(status?: CMProjectStatuses): void {
    const names = this.sections.controls.map((control) => control.value);
    if (!names.length) {
      return;
    }
    const paramsData = names.map((name) => {
      return {
        catalog: this.catalog,
        title: name,
        parent_id: this.parent && this.parent.id,
      };
    });

    this.competenceSectionsService
      .createSection(paramsData)
      .pipe(
        switchMap((project) => {
          if (!status) {
            return of(project);
          } else {
            return this.competenceService.updateProject({
              id: project.id,
              status,
            });
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (project) => {
          this.project = project;

          this.reloadEvent.emit();

          this.competenceService.projectSuccess(status);
          this.form.markAsPristine();
        },
        (err) => {
          this.competenceService.error(err);
          this.showInvalid(err);
        }
      );
  }

  private showInvalid(err: HttpErrorResponse) {
    if (err.error?.errors?.spec) {
      Object.keys(err.error?.errors?.spec).forEach((key) => {
        this.sections.at(+key).setErrors({ required: true });
      });
    }
  }

  private clearErrors() {
    this.sections.controls.forEach((control) => {
      control.setErrors(null);
    });
  }

  updateProject(status?: CMProjectStatuses): void {
    const names = this.sections.controls.map((control) => control.value) as string[];
    if (!names.length) {
      return;
    }

    const newItems = this.project.spec[CMActions.CREATE_SECTIONS].map((item, i) => {
      return {
        ...item,
        title: names[i],
      };
    });

    this.competenceService
      .updateProject({
        id: this.project.id,
        status,
        spec: {
          [CMActions.CREATE_SECTIONS]: [
            ...newItems,
            ...names.slice(newItems.length).map((item) => {
              return {
                title: item,
                catalog: this.catalog,
                parent_id: newItems[0]?.parent_id,
              };
            }),
          ].filter((item) => !!item.title),
        },
      } as Project)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (project) => {
          this.project = project;

          this.reloadEvent.emit();
          this.competenceService.projectSuccess(status);
          this.form.markAsPristine();
        },
        (err) => {
          this.competenceService.error(err);
          this.showInvalid(err);
        }
      );
  }

  cancel() {
    this.initializeForm();
  }

  save(status: CMProjectStatuses) {
    if (this.isNew) {
      this.addProject(status);
    } else {
      this.updateProject(status);
    }
  }

  agree(status: CMProjectStatuses) {
    if (this.isNew) {
      this.addProject(status);
    } else {
      this.updateProject(status);
    }
  }

  archive() {
    this.warnChangeArchiveStatus(() => {
      this.updateProject(CMProjectStatuses.ARCHIVE);
    });
  }
}
