import { throwError as observableThrowError, Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { FaqApiModel } from '../models/faq.common.interfaces';
import { FaqHint } from '../models/faq.api.model';

@Injectable()
export class FaqTooltipService {
  url: string = environment.api_url;

  constructor(private http: HttpClient) {}

  getTooltipText(hintId: number): Observable<FaqHint> {
    return this.http.get(`${this.url}/faq/hint/${hintId}`).pipe(
      map((hint: FaqApiModel.FaqHint) => new FaqHint(hint, hintId)),
      catchError(e => {
        return observableThrowError(e);
      })
    ) as Observable<FaqHint>;
  }
}
