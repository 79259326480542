<div class="modal-header justify-content-center">
  <div class="modal-title">{{ title }}</div>
</div>
<div class="base-table-container">
  <table class="base-table sticky-table">
    <thead>
      <tr>
        <th *ngFor="let column of columns" [style.width.px]="column.width">
          <app-project-logs-header-cell
            [column]="column"
            [rows]="projects"
            (changeFilterEvent)="filterProjects($event)"
            [projectTypes]="projectTypesFilterItems"
          ></app-project-logs-header-cell>
        </th>
        <th *ngIf="isExpert">
          <div>
            <input
              class="cbx-input"
              [id]="'check_all'"
              type="checkbox"
              [(ngModel)]="allChecked"
              (ngModelChange)="handleAllCheck()"
            />
            <label class="cbx-label" [for]="'check_all'">
              <span class="cbx-label-text">Все</span>
            </label>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="isLoading">
        <td [colSpan]="4">Загрузка...</td>
      </tr>
      <tr *ngIf="!projects.length && !isLoading">
        <td [colSpan]="4">Нет данных</td>
      </tr>
      <tr *ngFor="let item of projects" [class.text-bold]="isExpert && !isViewed(item)">
        <ng-container *ngFor="let column of columns">
          <td [style.width.px]="column.width">
            <div [ngSwitch]="column.id">
              <ng-container *ngSwitchCase="columnKeys.SEND_DATE">
                <span>{{ item.created_at | dateFormat }}</span>
              </ng-container>
              <ng-container *ngSwitchCase="columnKeys.TYPE">
                <div class="d-flex">
                  <span class="mr-auto">{{ projectActionLabels[item.action] || '-' }}</span>
                  <button class="color-brand-light icon-button" (click)="openProject(item)">
                    <app-svg-icon name="blank" width="16"></app-svg-icon>
                  </button>
                </div>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <div class="text-overflow" [title]="item[column.id]">{{ item[column.id] || '-' }}</div>
              </ng-container>
            </div>
          </td>
        </ng-container>

        <td *ngIf="isExpert && !isViewed(item)">
          <!-- TODO: Если следовать макету этот блок можно убрать -->
          <div>
            <input
              class="cbx-input"
              [id]="item.id"
              type="checkbox"
              [(ngModel)]="viewedData[item.id]"
              (ngModelChange)="handleCheck(item.id)"
              [disabled]="isViewed(item)"
            />
            <label class="cbx-label" [for]="item.id"></label>
          </div>
        </td>

        <td *ngIf="isExpert && isViewed(item)"></td>
      </tr>
    </tbody>
  </table>
</div>

<div class="text-right pt-3" *ngIf="isExpert">
  <button class="btn btn-dc-primary" (click)="viewProjects()" [disabled]="!hasChanges()">Просмотрено</button>
</div>
