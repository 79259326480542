import { Component } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-welcome-modal',
  templateUrl: './welcome-modal.component.html',
  styleUrls: ['./welcome-modal.component.scss'],
})
export class WelcomeModalComponent {
  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private router: Router) {}

  public close(): void {
    this.activeModal.close();
  }

  public navigateToContactForm(): void {
    this.router.navigate(['profile', 'contact']).then(() => this.close());
  }
}
