import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '@app/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GeoService {
  constructor(private http: HttpClient) {}

  getLocations(query: string): Observable<string[]> {
    return this.http.get<any>(`${environment.api_url}/geo`, {params: {q: query}});
  }
}
