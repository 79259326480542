import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultipleLivesearchSelectComponent } from './multiple-livesearch-select/multiple-livesearch-select.component';
import { MultipleSearchTextComponent } from './multiple-search-text/multiple-search-text.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from '@app/shared/shared.module';
import { HighlightSearchModule } from '../highlight/highlight-search.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { LiveSearchComponent } from './live-search/live-search.component';

@NgModule({
  declarations: [MultipleLivesearchSelectComponent, MultipleSearchTextComponent, LiveSearchComponent],
  exports: [MultipleLivesearchSelectComponent, MultipleSearchTextComponent, LiveSearchComponent],
  imports: [
    CommonModule,
    FormsModule,
    FontAwesomeModule,
    SharedModule,
    HighlightSearchModule,
    NgbTooltipModule,
    ReactiveFormsModule,
  ],
})
export class SearchModule {}
