import { Component, Input, ViewChild } from '@angular/core';
import { TreeComponent as TreeComponentLib } from '@circlon/angular-tree-component';
import { FilterTemplateTypes } from '@app/+competence-map/constants/filter-templates.constants';
import {
  CEIL_SLIDER_OPTION,
  FLOOR_SLIDER_OPTION,
} from '@app/shared/components/form/form-range-slider/form-range-slider.constants';

@Component({
  selector: 'app-union-tree-view',
  templateUrl: './union-tree-view.component.html',
  styleUrls: ['./union-tree-view.component.scss'],
})
export class UnionTreeViewComponent {
  @Input() data: any[] = [];
  @Input() templateFilterType?: FilterTemplateTypes;

  @ViewChild('tree') private tree: TreeComponentLib;

  get filterTemplateTypes(): typeof FilterTemplateTypes {
    return FilterTemplateTypes;
  }

  get fromRangeValue(): number {
    return this.data?.reduce((acc, item) => Math.min(acc, item?.default_range_from), CEIL_SLIDER_OPTION);
  }

  get toRangeValue(): number {
    return this.data?.reduce((acc, item) => Math.max(acc, item?.default_range_to), FLOOR_SLIDER_OPTION);
  }
}
