import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LineClampComponent } from './line-clamp/line-clamp.component';

import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [LineClampComponent],
  imports: [CommonModule, NgbTooltipModule],
  exports: [LineClampComponent],
})
export class LineClampModule {}
