import { LegalStatusesTypes } from '../types/legal-statuses.types';
import { ResidencesTypes } from '../types/residences.types';
import { BaseUser } from './base-user.model';
import { Address } from '@app/shared/models/location.model';

export class Company {
  /**  */
  readonly id?: number;

  /** юридический статус  */
  legal_status?: LegalStatusesTypes;

  /** резиденство */
  residency?: ResidencesTypes;

  /**  */
  owner_id?: number;

  /**  */
  owner?: BaseUser;

  /** Дата государственной регистрации */
  date_state_registration?: string;

  /** Наименование и ИНН заказчика */
  name_and_itn?: string;

  /** основание полномочий уполномоченного на подписание договора */
  authority_basis?: string;

  /** лицо уполномоченного на подписание договора: ФИО */
  authorized_person_first_name?: string;

  /** лицо уполномоченного на подписание договора: ФИО */
  authorized_person_patronymic?: string;

  /** лицо уполномоченного на подписание договора: ФИО */
  authorized_person_second_name?: string;

  /** лицо уполномоченного на подписание договора: должность */
  position?: string;

  /** БИК */
  bank_bic?: string;

  /** к/сч  */
  bank_corresponding_account?: string;

  /** р/сч */
  bank_current_account?: string;

  /** название банка */
  bank_name?: string;

  /** Банк-эмитент */
  bankcard_bank_name?: string;

  /** номер банковской карты */
  bankcard_number?: string;

  /** Дата гос регистрации */
  certificate_date?: string;

  /** Номер свидетельства гос регистрации */
  certificate_number?: string;

  /** Дата создания договора */
  contract_start_date?: string;

  /** IBAN */
  iban?: string;

  /** КПП */
  iec?: string;

  /** ИНН */
  itn?: string;

  /** Полное название организации */
  name?: string;

  /** Номер договора */
  number_of_contract?: string;

  /** Краткое название организации */
  organization_name?: string;

  /** Организационно правовая форма */
  organization_type?: string;

  /**  Дата выдачи паспорта */
  passport_date?: string;

  /** номер подразделения */
  passport_division_code?: string;

  /** Кем выдан */
  passport_issued_by?: string;

  /** Номер паспорта */
  passport_number?: string;

  /** серия паспорта */
  passport_series?: string;

  /** Адрес */
  postal_address?: Address;

  /** id адреса проживания */
  postal_address_id?: number;

  /** индекс адреса проживания */
  postal_address_postcode?: string;

  /** ОГРН */
  psrn?: string;

  /** ОГРНИП */
  psrnsp?: string;

  /** Адрес */
  registration_address?: Address;

  /** id адреса регистрации */
  registration_address_id?: number;

  /** индекс адреса регистрации */
  registration_address_postcode?: string;

  site?: string;

  /** СНИЛС */
  snils?: string;

  /** SWIFT */
  swift?: string;

  constructor(data = {}) {
    for (const prop in data) {
      if (data.hasOwnProperty(prop)) {
        this[prop] = data[prop];
      }
    }
    this.owner = this.owner || new BaseUser({});
    this.name = this.name || 'Не выбрана';
  }
}

export class CompanyCreate {
  /** юридический статус  */
  legal_status?: LegalStatusesTypes;

  /** резиденство */
  residency?: ResidencesTypes;

  /** основание полномочий уполномоченного на подписание договора */
  authority_basis?: string;

  /** лицо уполномоченного на подписание договора: ФИО */
  authorized_person_first_name?: string;

  /** лицо уполномоченного на подписание договора: ФИО */
  authorized_person_patronymic?: string;

  /** лицо уполномоченного на подписание договора: ФИО */
  authorized_person_second_name?: string;

  /** лицо уполномоченного на подписание договора: должность */
  position?: string;

  /** БИК */
  bank_bic?: string;

  /** к/сч  */
  bank_corresponding_account?: string;

  /** р/сч */
  bank_current_account?: string;

  /** название банка */
  bank_name?: string;

  /** Банк-эмитент */
  bankcard_bank_name?: string;

  /** номер банковской карты */
  bankcard_number?: string;

  /** Дата гос регистрации */
  certificate_date?: string;

  /** Номер свидетельства гос регистрации */
  certificate_number?: string;

  /** Дата создания договора */
  contract_start_date?: string;

  /** IBAN */
  iban?: string;

  /** КПП */
  iec?: string;

  /** ИНН */
  itn?: string;

  /** Полное название организации */
  name?: string;

  /** Номер договора */
  number_of_contract?: string;

  /** Краткое название организации */
  organization_name?: string;

  /** Организационно правовая форма */
  organization_type?: string;

  /**  Дата выдачи паспорта */
  passport_date?: string;

  /** номер подразделения */
  passport_division_code?: string;

  /** Кем выдан */
  passport_issued_by?: string;

  /** Номер паспорта */
  passport_number?: string;

  /** серия паспорта */
  passport_series?: string;

  /** Адрес */
  postal_address?: string;

  /** индекс адреса проживания */
  postal_address_postcode?: string;

  /** ОГРН */
  psrn?: string;

  /** ОГРНИП */
  psrnsp?: string;

  /** Адрес */
  registration_address?: string;

  /** индекс адреса регистрации */
  registration_address_postcode?: string;

  site?: string;

  /** СНИЛС */
  snils?: string;

  /** SWIFT */
  swift?: string;

  constructor(data?: CompanyCreate) {
    for (const prop in data) {
      if (data.hasOwnProperty(prop)) {
        this[prop] = data[prop];
      }
    }
    this.name = this.name || 'Не выбрана';
  }
}

export class CompanyUpdate {
  /**  */
  readonly id?: number;

  /** основание полномочий уполномоченного на подписание договора */
  authority_basis?: string;

  /** лицо уполномоченного на подписание договора: ФИО */
  authorized_person_first_name?: string;

  /** лицо уполномоченного на подписание договора: ФИО */
  authorized_person_patronymic?: string;

  /** лицо уполномоченного на подписание договора: ФИО */
  authorized_person_second_name?: string;

  /** лицо уполномоченного на подписание договора: должность */
  position?: string;

  /** БИК */
  bank_bic?: string;

  /** к/сч  */
  bank_corresponding_account?: string;

  /** р/сч */
  bank_current_account?: string;

  /** название банка */
  bank_name?: string;

  /** Банк-эмитент */
  bankcard_bank_name?: string;

  /** номер банковской карты */
  bankcard_number?: string;

  /** Дата гос регистрации */
  certificate_date?: string;

  /** Номер свидетельства гос регистрации */
  certificate_number?: string;

  /** Дата создания договора */
  contract_start_date?: string;

  /** IBAN */
  iban?: string;

  /** КПП */
  iec?: string;

  /** ИНН */
  itn?: string;

  /** Полное название организации */
  name?: string;

  /** Номер договора */
  number_of_contract?: string;

  /** Краткое название организации */
  organization_name?: string;

  /** Организационно правовая форма */
  organization_type?: string;

  /**  Дата выдачи паспорта */
  passport_date?: string;

  /** номер подразделения */
  passport_division_code?: string;

  /** Кем выдан */
  passport_issued_by?: string;

  /** Номер паспорта */
  passport_number?: string;

  /** серия паспорта */
  passport_series?: string;

  /** Адрес */
  postal_address?: string;

  /** id адреса проживания */
  postal_address_id?: number;

  /** индекс адреса проживания */
  postal_address_postcode?: string;

  /** ОГРН */
  psrn?: string;

  /** ОГРНИП */
  psrnsp?: string;

  /** Адрес */
  registration_address?: string;

  /** id адреса регистрации */
  registration_address_id?: number;

  /** индекс адреса регистрации */
  registration_address_postcode?: string;

  site?: string;

  /** СНИЛС */
  snils?: string;

  /** SWIFT */
  swift?: string;

  constructor(data?: Company) {
    for (const prop in data) {
      if (data.hasOwnProperty(prop)) {
        this[prop] = data[prop];
      }
    }
  }
}
