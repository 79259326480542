import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  setItem<T = any>(key: string, value: T): void {
    localStorage.setItem(key, window.btoa(unescape(encodeURIComponent(JSON.stringify(value)))));
  }

  getItem<T = any>(key: string): T {
    const item = localStorage.getItem(key);
    if (item) {
      return JSON.parse(decodeURIComponent(escape(window.atob(item))));
    }
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  clear(): void {
    localStorage.clear();
  }
}
