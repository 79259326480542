import { Component, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { REDIRECT_AFTER_LOGIN, REDIRECT_TO_LOGIN } from '@app/auth/redirect-after-login.constants';
import { UserSettings } from '@app/shared/models/user-settings.model';
import { MENU_ITEMS } from '@app/shared/constants/menu-items.constants';
import { PersonalDataService } from '@app/shared/services/personal-data.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CdkPortalOutlet } from '@angular/cdk/portal';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnDestroy {
  menuItems: Array<any>;
  public options = {
    position: ['top'],
    timeOut: 5000,
    showProgressBar: false,
    theClass: 'notify-class',
    icons: {
      success:
        '<svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M6.79288 15.1642L0.292881 8.66416C-0.097627 8.27366 -0.097627 7.64049 0.292881 7.24995L1.70706 5.83573C2.09757 5.44518 2.73077 5.44518 3.12128 5.83573L7.49999 10.2144L16.8787 0.835728C17.2692 0.44522 17.9024 0.44522 18.2929 0.835728L19.7071 2.24995C20.0976 2.64045 20.0976 3.27362 19.7071 3.66416L8.2071 15.1642C7.81655 15.5547 7.18339 15.5547 6.79288 15.1642Z" fill="white"/>\n' +
        '</svg>\n',
      error:
        '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M20 10C20 15.5241 15.5225 20 10 20C4.47754 20 0 15.5241 0 10C0 4.47915 4.47754 0 10 0C15.5225 0 20 4.47915 20 10ZM10 12.0161C8.97561 12.0161 8.14516 12.8466 8.14516 13.871C8.14516 14.8954 8.97561 15.7258 10 15.7258C11.0244 15.7258 11.8548 14.8954 11.8548 13.871C11.8548 12.8466 11.0244 12.0161 10 12.0161ZM8.23899 5.34895L8.5381 10.8328C8.5521 11.0894 8.76427 11.2903 9.02125 11.2903H10.9788C11.2357 11.2903 11.4479 11.0894 11.4619 10.8328L11.761 5.34895C11.7761 5.07177 11.5554 4.83871 11.2779 4.83871H8.7221C8.44452 4.83871 8.22387 5.07177 8.23899 5.34895Z" fill="white"/>\n' +
        '</svg>\n',
      warn:
        '<svg width="7" height="20" viewBox="0 0 7 20" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M6.875 16.875C6.875 18.5981 5.47312 20 3.75 20C2.02688 20 0.625 18.5981 0.625 16.875C0.625 15.1519 2.02688 13.75 3.75 13.75C5.47312 13.75 6.875 15.1519 6.875 16.875ZM0.986719 0.984336L1.51797 11.6093C1.54293 12.1083 1.95473 12.5 2.4543 12.5H5.0457C5.54527 12.5 5.95707 12.1083 5.98203 11.6093L6.51328 0.984336C6.54004 0.448828 6.11313 0 5.57695 0H1.92305C1.38687 0 0.959961 0.448828 0.986719 0.984336Z" fill="white"/>\n' +
        '</svg>\n',
    },
  };
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  @ViewChild('portalContainer', { read: CdkPortalOutlet, static: false })
  virtualPortalOutlet: CdkPortalOutlet;

  @ViewChild('projectModalContainer', { read: ViewContainerRef, static: false })
  projectModalContainer: ViewContainerRef;

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private router: Router,
    private personalDataService: PersonalDataService
  ) {
    authService.updateUser().subscribe((user) => {
      if (user) {
        this.personalDataService
          .getUserSettings()
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((settings: UserSettings) => {
            this.menuItems = MENU_ITEMS[user.type].tuning;
            const found = this.menuItems.find((item) => item.link === settings.start_page);
            // TODO: МБ уберем здесь остальные параметры они вроде на Guards должны быть?
            if (found && found.link) {
              this.router.navigate([found.link]);
            } else if (this.menuItems.find((item) => item.link === this.router.url)) {
              // this.router.navigate([this.router.url]);
            } else {
              // this.router.navigate([REDIRECT_AFTER_LOGIN]);
            }
          });
      } else {
        this.router.navigate([REDIRECT_TO_LOGIN]);
      }
    });

    translate.setDefaultLang('ru');
    translate.use('ru');
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.ngUnsubscribe = null;
  }
}
