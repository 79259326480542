<div class="competence-map-catalog">
  <div class="competence-map-catalog-sections competence-map-catalog-item">
    <app-my-catalog-sections
      [isMyCompetencies]="isMyCompetencies"
      [readonly]="readonly"
      [catalog]="catalog"
      [competencies]="competencies"
      [isExpert]="isExpert"
      (searchEvent)="onSearch()"
      (onSectionSelect)="sectionSelected($event)"
      (onShowedMyCompetencies)="showedMyCompetencies($event)"
    ></app-my-catalog-sections>
  </div>
  <div class="competence-map-catalog-filters competence-map-catalog-item" *ngIf="!isExpert">
    <app-my-catalog-filters
      [catalog]="catalog"
      [readonly]="readonly"
      [sectionData]="selectedSection"
      [competencies]="competencies"
      (searchEvent)="onSearch()"
    ></app-my-catalog-filters>
  </div>
</div>
