import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { TreeComponent as TreeComponentLib, TreeNode } from '@circlon/angular-tree-component';

@Component({
  selector: 'app-split-tree-view',
  templateUrl: './split-tree-view.component.html',
  styleUrls: ['./split-tree-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SplitTreeViewComponent {
  @Input() data: any[] = [];
  @Input() selectedNodes: number[] = [];

  @ViewChild('tree') private tree: TreeComponentLib;

  isNodeSelected(node: TreeNode): boolean {
    return this.selectedNodes.includes(node.data.node_id);
  }
}
