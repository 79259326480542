import { Directive, HostListener, Input } from '@angular/core';
import { ChatService } from '@app/chat/services/chat.service';

@Directive({ selector: '[chatLink]' })
export class ChatLinkDirective {
  @Input('chatLink') userId: number;

  constructor(private chatService: ChatService) {}

  @HostListener('click', ['$event.target'])
  navigateToChat(): void {
    this.chatService.tryOpenChatByUserId(this.userId);
  }
}
