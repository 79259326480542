import { ChangeDetectorRef, Directive, HostBinding, Input } from '@angular/core';
import { SectionAccess } from '@app/+competence-map/models/access.model';
import { DCTreeItem } from '@app/+competence-map/models/competence-map.models';
import { SectionAccessService } from '@app/+competence-map/services/section-access.service';
import { DestroyService } from '@app/services/destroy.service';
import { RolesEnum } from '@app/shared/constants/roles.constants';
import { AuthService } from '@app/shared/services/auth.service';
import { BehaviorSubject } from 'rxjs';
import { delay, switchMap, takeUntil, tap, filter } from 'rxjs/operators';

@Directive({
  selector: '[appSectionAccess]',
  providers: [DestroyService],
})
export class SectionAccessDirective {
  sectionChange$ = new BehaviorSubject<DCTreeItem>(null);
  _section: DCTreeItem;

  @Input('appSectionAccess') set sectionSetter(section: DCTreeItem) {
    this._section = section;

    this.sectionChange$.next(this._section);
  }

  @HostBinding('class._disabled') disabled: boolean;

  constructor(
    private authService: AuthService,
    private sectionAccessService: SectionAccessService,
    private destroy$: DestroyService
  ) {
    this.sectionChange$
      .pipe(
        filter((section) => !!section),
        switchMap((section) =>
          this.sectionAccessService.userAccess$.pipe(
            tap((access) => {
              if (this.authService.user_type === RolesEnum.SUPERUSER) {
                this.disabled = false;
                section.hasAccess = true;
                return;
              }

              const hasAccess = this.hasAccess(access, section.tree_id);

              this.disabled = !hasAccess;

              section.hasAccess = hasAccess;
            })
          )
        ),
        delay(0),
        takeUntil(this.destroy$)
      )
      .subscribe((access) => {});
  }

  hasAccess(access: SectionAccess[], treeId: number): boolean {
    return !!access.find((item) => +item.section.tree_id === +treeId);
  }
}
