import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-start-page-dialog',
  templateUrl: './start-page-dialog.component.html',
  styleUrls: ['./start-page-dialog.component.scss']
})
export class StartPageDialogComponent implements OnInit {
  @Input() startPageSelected: string;
  @Input() menuItems: Array<any>;

  public pageSelected: string;
  public selectedMenuItem: string;

  constructor(
    private activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.pageSelected = this.startPageSelected;
  }

  public close(): void {
    this.activeModal.close(0);
  }

  public selectPage(menuItem: any): void {
    this.selectedMenuItem = menuItem;
  }

  public success(): void {
    this.activeModal.close({menuItem: this.selectedMenuItem, selectedPageValue: this.pageSelected});
  }
}
