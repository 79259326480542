import {Component} from '@angular/core';
import {VERSIONS_DESCRIBE} from './versions.constants';

const {version: appVersion, build} = require('../../../../../package.json');

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.sass']
})

export class AuthLayoutComponent {
  appVersion;
  build;
  VERSIONS_DESCRIBE = VERSIONS_DESCRIBE;
  selectVersion = appVersion;

  constructor() {
    this.appVersion = appVersion;
    this.build = build;
  }

  keys(obj): Array<any> {
    return Object.keys(obj);
  }
}
