<div class="dc-modal-x-button dc-fa-button" (click)="close()">
  <app-svg-icon [height]="20" [width]="20" name="close"></app-svg-icon>
</div>
<div class="modal-window-content">
  <div class="modal-header">
    <div class="modal-title text-left">
      {{title}}
    </div>
  </div>

  <div class="modal-body">
    <app-file-manager-light
      (onExportSelected)="selectFile($event)"
    ></app-file-manager-light>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-dc-outline" (click)="close()">
      Отменить
    </button>
    <button type="button" class="btn btn-dc-primary" (click)="success()" [disabled]="!selectedFile">
      Добавить
    </button>
  </div>
</div>
