import { Pipe, PipeTransform } from '@angular/core';
import { formatSectionsStringByOneLevel } from '@app/+competence-map/helpers/competence-map.helpers';

@Pipe({
  name: 'competencies',
})
export class CompetenciesPipe implements PipeTransform {
  transform(competence_sections: any[], separator = ' / ', postfix = ''): any {
    return formatSectionsStringByOneLevel(competence_sections, separator, postfix);
  }
}
