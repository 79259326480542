<div
  class="move-pin"
  id="move-pin"
  [ngClass]="classForChildren"
  [style.top.px]="y"
  [style.left.px]="x"
  [style.width]="widthPx ? widthPx + 'px' : width"
  [style.height]="heightPx ? heightPx + 'px' : height"
  (mousedown)="!noPinAndMove && onWindowPress($event)"
  (mousemove)="!noPinAndMove && onWindowDrag($event)"
  (clickOutside)="onClickedOutside($event)"
  #mainWindow
>
  <div (click)="onClose()" class="move-pin__close-button" *ngIf="!noPinAndMove"></div>
  <div class="move-pin__move-button" *ngIf="!noPinAndMove" [style.margin-left.px]="mlPinIcon">
    <app-svg-icon [height]="16" [width]="16" name="move"></app-svg-icon>
  </div>
  <div class="move-pin__pin-button" (click)="pinFlag = !pinFlag" *ngIf="!noPinAndMove">
    <div class="pin_icon" [ngClass]="{ pin_icon_on: pinFlag }">
      <app-svg-icon
        [height]="pinFlag ? 15 : 16"
        [width]="pinFlag ? 11 : 12"
        name="pin"
        [ngStyle]="pinFlag && color ? { color: color } : {}"
      >
      </app-svg-icon>
    </div>
  </div>
  <ng-content></ng-content>
  <div
    (mousedown)="onCornerClick($event)"
    class="pin-modal-bottom-right-resize"
    *ngIf="resize"
  >
    <app-svg-icon
      [height]="8"
      [width]="8"
      name="resize"
    >
    </app-svg-icon>
  </div>
</div>
