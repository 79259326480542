import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textSeparator'
})
export class TextSeparatorPipe implements PipeTransform {

  transform(value: any, separator: string, count?: number): any {
    if (!value) {
      return value;
    }

    // console.log(value);

    const regex = new RegExp( `.{1,${count}}`, 'g' );
    const segments = value.match(regex);

    return segments.join(separator);
  }

}
