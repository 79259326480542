import { Component, Input, OnInit } from '@angular/core';
import {
  NotificationManyModel,
  NotificationSourceModel,
  NotificationStatusEnum,
} from '@app/notification/const/notification.const';
import { NotificationService } from '@app/notification/services/notification.service';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { ChatService } from '@app/chat/services/chat.service';
import { CHAT_SECTIONS } from '@app/chat/constants/chat-sections.constants';
import { PaymentRegistryDataService } from '@app/+tariffs/services/payment-registry-data.service';
import { PaymentTabEnum } from '@app/+tariffs/constants/payment.constants';
import { AuthService } from '@app/shared/services/auth.service';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
})
export class NotificationListComponent implements OnInit {
  @Input() dataSource: NotificationManyModel = {
    notifications: [],
    total: 0,
  };
  @Input() countNotification = 0;
  faExternalLinkAlt = faExternalLinkAlt;
  disableButtonShowMore = false;
  notificationStatusEnum = NotificationStatusEnum;

  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private chatService: ChatService,
    public paymentRegistryData: PaymentRegistryDataService,
    public authService: AuthService
  ) {}

  viewedNoti(noti) {
    noti.status = NotificationStatusEnum.VIEWED;
    this.notificationService.viewNotification([
      {
        id: noti.id,
        status: NotificationStatusEnum.VIEWED,
      },
    ]);
  }

  viewedAll() {
    this.dataSource.notifications.forEach((item) => {
      item.status === NotificationStatusEnum.SENT && (item.status = NotificationStatusEnum.VIEWED);
    });
    this.notificationService.viewNotification(null);
  }

  async showMore(offset = 0) {
    this.disableButtonShowMore = true;
    await this.notificationService.showMoreData({ limit: 10, offset });
    this.disableButtonShowMore = false;
  }

  clearOutletsPopup() {
    this.router.navigate([{ outlets: { popup: null } }]);
  }

  generateLinkNotification(source: Partial<NotificationSourceModel>, noti) {
    noti.status === this.notificationStatusEnum.SENT && this.viewedNoti(noti);
    this.notificationService.toggleNotificationOpen();
    switch (source?.module) {
      case 'employees':
        this.router
          .navigate([{ outlets: { popup: null } }])
          .then(() => this.router.navigate(['/employees'], { queryParams: source?.params }));
        break;
      case 'chat':
        const url = ['chat'];
        source.params?.room_id && url.push(source.params.room_id);
        this.router.navigate([{ outlets: { popup: url } }]).then(() => {
          if (source.subsection && !source.params?.room_id)
            setTimeout(() => this.chatService.setChatSectionSelected(CHAT_SECTIONS[source.subsection]), 0);
        });
        break;
      case 'competence':
        switch (source?.subsection) {
          case 'my_projects':
            this.router.navigate(['/map/my-competences']).then(() => this.clearOutletsPopup());
            break;
        }
        break;
      case 'trades': {
        const tradeCardId = source.subsection === 'customer' ? source.params.trade_id : source.params.provider_trade_id;
        this.router.navigate([`/trades/${source.subsection}/card/${tradeCardId}`]).then(() => this.clearOutletsPopup());
        break;
      }
      case 'profile':
        this.router.navigate(['/profile']).then(() => this.clearOutletsPopup());
        break;
      case 'faq':
        switch (source?.subsection) {
          case 'edit':
            this.router.navigate(['/FAQ/edit']).then(() => this.clearOutletsPopup());
            break;
        }
        break;
      case 'tariffs':
        switch (source?.subsection) {
          case 'tariffs':
            this.router.navigate(['/tariffs-payments/tariffs']).then(() => this.clearOutletsPopup());
            break;
          case 'transactions':
            this.router
              .navigate(['/tariffs-payments/registry'])
              .then(() => this.clearOutletsPopup())
              .then(() => {
                this.paymentRegistryData.init();
                this.paymentRegistryData.onChangeTab(PaymentTabEnum.ACCRUALS);
              });
            break;
          case 'payment_registry':
            this.router
              .navigate(['/tariffs-payments/registry'])
              .then(() => this.clearOutletsPopup())
              .then(() => {
                this.paymentRegistryData.init();
                this.paymentRegistryData.onChangeTab(PaymentTabEnum.REGISTRY);
              });
            break;
        }
        break;
      case 'tso':
        switch (source?.subsection) {
          case 'duty':
            this.router.navigate(['/tso/duty']).then(() => this.clearOutletsPopup());
            break;
        }
        break;
      case 'file_manager':
        this.router.navigate(['/file-manager']).then(() => this.clearOutletsPopup());
        break;
      case 'calendar':
        this.router.navigate(['/calendar']).then(() => this.clearOutletsPopup());
        break;
    }
  }

  ngOnInit() {
    this.notificationService.loadData();
  }
}
