<div class="planner" #mainPlanner>

  <div class="row planner-header" #headerPlanner>
    <div class="col-3 planner-header-type">
      <span [ngClass]="enumTypePlanner.WEEK === typePlanner ? 'active' : ''" (click)="changeTypePlanner(enumTypePlanner.WEEK)">
        Неделя
      </span>
      <span [ngClass]="enumTypePlanner.MONTH === typePlanner ? 'active' : ''" (click)="changeTypePlanner(enumTypePlanner.MONTH)">
        Месяц
      </span>
      <span [ngClass]="enumTypePlanner.YEAR === typePlanner ? 'active' : ''" (click)="changeTypePlanner(enumTypePlanner.YEAR)">
        Год
      </span>
    </div>
    <div class="col-6 planner-header-date" [style.max-width.px]="maxWidthDate[typePlanner]">
      <div class="planner-header-date-left">
        <app-svg-icon [height]="12" [width]="8" name="left" (click)="changeDate(-1)" class="pointer"></app-svg-icon>
      </div>
      <div class="planner-header-date-center">
          <ng-container *ngIf="enumTypePlanner.WEEK === typePlanner">
            <span (click)="showSelect(typeSelect.WEEK, $event)">{{ currentWeek }} неделя</span
          >&nbsp;</ng-container
          ><ng-container *ngIf="enumTypePlanner.YEAR !== typePlanner">
            <span (click)="showSelect(typeSelect.MONTH, $event)">{{ currentMonth }}</span
          >&nbsp;</ng-container
          ><span (click)="showSelect(typeSelect.YEAR, $event)">{{ currentYear }}</span>
      </div>
      <div class="planner-header-date-right">
        <app-svg-icon [height]="12" [width]="8" name="right" (click)="changeDate(1)" class="pointer"></app-svg-icon>
      </div>
    </div>
    <div class="col-3 planner-header-button">
      <ng-container *ngIf="typePlanner !== enumTypePlanner.YEAR">
        <button class="dc-toolbar-btn mr-0" (click)="openFilter()">
          <ng-container *ngIf="selectFilterState?.length; else iconSortOff">
            <app-svg-icon [height]="20" [width]="26" name="sort_on" class="sort_on"></app-svg-icon>
          </ng-container>
          <ng-template #iconSortOff>
            <app-svg-icon [height]="16" [width]="18" name="sort_off"></app-svg-icon>
          </ng-template>
        </button>
      </ng-container>
      <button class="dc-toolbar-btn mr-0" (click)="addEvent()">
        <app-svg-icon [height]="18" [width]="18" name="plus"></app-svg-icon>
      </button>
      <button class="dc-toolbar-btn mr-0" (click)="showModalHelper = true">
        <app-svg-icon [height]="18" [width]="13" name="question"></app-svg-icon>
      </button>
    </div>
  </div>

  <app-view-week
    *ngIf="enumTypePlanner.WEEK === typePlanner"
    [style.max-height.px]="heightViewEvent"
    [calendarData]="calendarData"
    [currentDayOnCalendar]="currentDayOnCalendar"
    [eventForHour]="eventForHour"
  ></app-view-week>

  <app-view-month
    *ngIf="enumTypePlanner.MONTH === typePlanner"
    [calendarData]="calendarData"
    [heightView]="heightViewEvent"
    [currentDayOnCalendar]="currentDayOnCalendar"
    [eventForDay]="eventForDay"
  ></app-view-month>

  <app-view-year
    *ngIf="enumTypePlanner.YEAR === typePlanner"
    [currentYear]="currentYear"
  ></app-view-year>

</div>

<app-modal-window #modalFilter [idModalInjector]="id" [backdrop]="'static'" [customClass]="'planner-filter'"
                  [isShowed]="showFilter" (closeEvent)="showFilter = false" [centered]="true">
  <div class="planner-filter-header">
    <span>Показать</span>
    <span (click)="setFilter(true)" *ngIf="selectFilterState?.length" class="clear_filter">
      <app-svg-icon [height]="14" [width]="14" name="close_wide" (click)="changeDate(-1)"></app-svg-icon>Сбросить фильтр
    </span>
  </div>
  <app-multiselect-filter
    [list]="listStateForFilter"
    (submitEvent)="applyFilter($event)"
    (cancelEvent)="showFilter = false"
  >
  </app-multiselect-filter>
</app-modal-window>

<app-modal-window [backdrop]="true" [isShowed]="showModalHelper" (closeEvent)="closeModalHelper()">
  <div class="helper-calendar">
    <div class="helper-calendar-text">
      Справка
    </div>
    <div class="modal-footer">
      <button class="btn btn-dc-primary" (click)="closeModalHelper()">
        Закрыть
      </button>
    </div>
  </div>
</app-modal-window>
