import { Component } from '@angular/core';
import { AuthService } from '@app/shared/services/auth.service';
import { NotificationsService } from 'angular2-notifications';
import { ActivatedRoute, Router } from '@angular/router';
import { REDIRECT_AFTER_LOGIN } from '../redirect-after-login.constants';

@Component({
  template: ``,
})
export class ActivateComponent {
  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private notify: NotificationsService
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      const email = params['email'];
      const key = params['key'];
      if (email && key)
        this.authService.activateConfirmUser(email, key).subscribe(
          () => {
            this.notify.success('Успешно!', 'Добро пожаловать!');
            authService.updateUser().subscribe(user => this.router.navigate([REDIRECT_AFTER_LOGIN]));
          },
          () => this.router.navigate(['signin'])
        );
    });
  }
}
