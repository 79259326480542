import { Column } from '@app/shared/models/table';
import { FlaskQueryFilter } from '@app/shared/models/filters.model';
import { CatalogFilterCategory } from '@app/+competence-map/models/user-filters.model';

export enum UserFiltersEnum {
  COMMERCIAL = 'commercial',
  STANDARTS = 'standard',
  TECHNOLOGICAL = 'consumer',
  LOGISTIC = 'logistic',
}

export const FILTERS_CATEGORIES: CatalogFilterCategory[] = [
  {
    label: 'Коммерческие',
    value: UserFiltersEnum.COMMERCIAL,
    checked: false,
    filtersCount: 0,
  },
  {
    label: 'Стандарты',
    value: UserFiltersEnum.STANDARTS,
    checked: false,
    filtersCount: 0,
  },
  {
    label: 'Потребительские',
    value: UserFiltersEnum.TECHNOLOGICAL,
    checked: false,
    filtersCount: 0,
  },
  {
    label: 'Логистические',
    value: UserFiltersEnum.LOGISTIC,
    checked: false,
    filtersCount: 0,
  },
];

export enum UserFiltersKeys {
  ID = 'id',
  VALUE = 'value',
  STATUS = 'status',
  CREATED_AT = 'created_at',
  AUTHOR_ID = 'author_id',
  UNITS = 'units_of_measure_id',
  TITLE = 'title',
  TYPE = 'type',
  PATH = 'path',
}

export const USER_FILTERS_COLUMNS: Column<FlaskQueryFilter[]>[] = [
  {
    id: UserFiltersKeys.ID,
    name: 'ID',
    isEnabledSearch: true,
    searchPlaceholder: 'Поиск по id',
    width: 100,
    filterConfig: [
      {
        name: UserFiltersKeys.ID,
        op: 'in_',
        val: '',
      },
    ],
  },
  {
    id: UserFiltersKeys.TITLE,
    width: 300,
    name: 'Название фильтра',
    isEnabledSearch: true,
    searchPlaceholder: 'Поиск по названию',
    filterConfig: [
      {
        name: UserFiltersKeys.TITLE,
        op: 'in_',
        val: '',
      },
    ],
  },
  {
    id: UserFiltersKeys.PATH,
    name: 'Путь фильтра',
    isEnabledSearch: true,
    searchPlaceholder: 'Поиск по пути',
    width: 250,
    filterConfig: [
      {
        name: UserFiltersKeys.PATH,
        op: 'in_',
        val: '',
      },
    ],
  },
  {
    id: UserFiltersKeys.TYPE,
    width: 250,
    name: 'Тип',
    isEnabledFilter: true,
    searchPlaceholder: 'Поиск по типу',
    filterConfig: [
      {
        name: UserFiltersKeys.TYPE,
        op: 'in_',
        val: '',
      },
    ],
  },
  {
    id: UserFiltersKeys.UNITS,
    width: 200,
    name: 'Единицы',
    isEnabledSearch: true,
    searchPlaceholder: 'Поиск по Единицам',
    filterConfig: [
      {
        name: UserFiltersKeys.UNITS,
        op: 'in_',
        val: '',
      },
    ],
  },
  {
    id: UserFiltersKeys.AUTHOR_ID,
    width: 200,
    name: 'ID автора',
    searchPlaceholder: 'Поиск по id автора',
    isEnabledSearch: true,
    filterConfig: [
      {
        name: UserFiltersKeys.AUTHOR_ID,
        op: 'in_',
        val: '',
      },
    ],
  },
  {
    id: UserFiltersKeys.CREATED_AT,
    width: 200,
    name: 'Дата создания',
    isEnabledSearch: true,
    searchPlaceholder: 'Поиск по дате',
    filterConfig: [
      {
        name: UserFiltersKeys.CREATED_AT,
        op: 'between',
        val: null,
      },
    ],
  },
  {
    id: UserFiltersKeys.STATUS,
    width: 200,
    name: 'Статус',
    isEnabledFilter: true,
    searchPlaceholder: 'Поиск по статусу',
    filterConfig: [
      {
        name: UserFiltersKeys.STATUS,
        op: 'in_',
        val: '',
      },
    ],
  },
].map((column) => {
  return {
    ...column,
    defaultFilterConfig: column.filterConfig,
  };
});

export enum UserFilterStatuses {
  ACTIVE = 'active',
  ARCHIVE = 'archive',
  DRAFT = 'draft',
  DELETED = 'deleted',
}

export const USER_FILTERS_STATUSES = [
  {
    label: 'Актив',
    value: UserFilterStatuses.ACTIVE,
    class: 'status-green',
  },
  {
    label: 'Просмотр',
    value: UserFilterStatuses.DRAFT,
    class: 'status-yellow',
  },
  {
    label: 'Архив',
    value: UserFilterStatuses.ARCHIVE,
    class: 'status-red',
  },
];
