<div class="fmflc-item" [class.active]="hasSelected(file)" [class.selected]="isSelected(file)">
  <div class="text-center fm-file-icon">
    <a href>
      <app-svg-icon
        [width]="file.hasFile() ? 30 : 40"
        [height]="file.hasFile() ? 40 : 30"
        [name]="file.hasFile() ? file.extType().icon : 'folder'"
      ></app-svg-icon>
    </a>
  </div>
  <div class="fm-file-checkbox margin-l-80">
    <button class="btn-link hover">
      <input [checked]="hasSelected(file)" class="cbx-input" [id]="file.id" type="checkbox" />
      <label class="cbx-label" [for]="file.id"></label>
    </button>
    <button
      class="btn-link btn-link__share hover"
      *ngIf="file.hasShare() && !file.hasTrade()"
      (click)="toggleInfoUser($event, file)"
    >
      <app-svg-icon [width]="20" [height]="20" [name]="'crown'"></app-svg-icon>
    </button>
    <button
      class="btn-link btn-link__share hover"
      *ngIf="file.is_shared && !file.hasShare()"
      (click)="share($event, [file])"
    >
      <app-svg-icon [width]="20" [height]="20" [name]="'users'"></app-svg-icon>
    </button>
    <app-modal-window
      [isShowed]="file.hasShare() && file.showUserInfoInArea"
      (closeEvent)="toggleInfoUser($event, file)"
    >
      <app-user-card [user]="file.author" *ngIf="!!file.showUserInfoInArea"></app-user-card>
    </app-modal-window>
  </div>
  <div class="text-center fmflc-item-title">
    <span class="small" [innerHTML]="file.name | highlight: searchQuery"></span>
  </div>
</div>
