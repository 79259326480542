import { Component, EventEmitter, Input, Output } from '@angular/core';

import { DestroyService } from '@app/services/destroy.service';
import { UserFile } from '../../models/user-file.model';

@Component({
  selector: 'app-file-manager-tree',
  templateUrl: './file-manager-tree.component.html',
  styleUrls: ['./file-manager-tree.component.scss'],
  providers: [DestroyService],
})
export class FileManagerTreeComponent {
  @Input() files: UserFile[];
  @Input() selected: UserFile;
  @Input() selectedAndOpened: UserFile;
  @Input() isSearchQuery: boolean = false;
  @Input() searchQuery: string = '';
  @Input() openDelayTooltip?: number;
  @Input() isRoot: boolean = false;

  @Output() readonly onToggleFile = new EventEmitter<UserFile>();
  @Output() readonly onSelectFile = new EventEmitter<UserFile>();
  @Output() readonly onOpenSelectFile = new EventEmitter<UserFile>();
  @Output() readonly onShowUserInfo = new EventEmitter<UserFile>();
  @Output() readonly onShareFile = new EventEmitter<UserFile[]>();
  @Output() readonly onHelpFile = new EventEmitter<UserFile>();

  toggleFile(file: UserFile): void {
    this.onToggleFile.emit(file);
  }

  selectFile(file: UserFile): void {
    this.onSelectFile.emit(file);
  }

  openSelectFile(file: UserFile): void {
    this.onOpenSelectFile.emit(file);
  }

  shareFile(file: UserFile[]): void {
    this.onShareFile.emit(file);
  }

  showUserInfoFile(file: UserFile): void {
    this.onShowUserInfo.emit(file);
  }

  showHelpFile(file: UserFile): void {
    this.onHelpFile.emit(file);
  }
}
