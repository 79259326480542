import { Pipe, PipeTransform } from '@angular/core';
import { RESIDENCES } from '../constants/residences.constants';

@Pipe({
  name: 'residency',
})
export class ResidencyPipe implements PipeTransform {
  transform(residency: any): any {
    let result = '';
    const foundItem = [...RESIDENCES].find((res) => res.value === residency);
    if (foundItem) {
      result = foundItem.title;
    }
    return result;
  }
}
