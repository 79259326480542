import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@app/shared/models/user.model';
import { DutyUser } from '@app/shared/models/duty.model';

@Pipe({
  name: 'usersInitialsFilter',
})
export class UsersInitialsFilterPipe implements PipeTransform {
  transform(items: User[] | DutyUser[], filterString: string): User[] | DutyUser[] {
    if (!filterString || !items) {
      return items;
    }

    const newItems = [];

    items.forEach((item) => {
      if (
        (item.first_name && item.first_name.toLowerCase().includes(filterString)) ||
        (item.second_name && item.second_name.toLowerCase().includes(filterString)) ||
        (item.patronymic && item.patronymic.toLowerCase().includes(filterString))
      ) {
        newItems.push(item);
      }
    });

    return newItems;
  }
}
