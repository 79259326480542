<div [class.disabled]="disabled">
  <input
    class="cbx-input"
    [id]="'all_value_' + unique"
    type="checkbox"
    [ngModel]="allCheckedStatus"
    (change)="changeAll()"
  />
  <label class="cbx-label" [for]="'all_value_' + unique" appTestLocator="union-tree-choice__select-all">
    <span class="cbx-label-text">Все значения</span>
  </label>
</div>

<div class="tree-container">
  <tree-root #tree [nodes]="nodes" [options]="options">
    <ng-template #treeNodeFullTemplate let-node let-index="index" let-templates="templates">
      <div class="tree-node" appTestLocator="union-tree-choice__item-{{ node?.data?.id }}">
        <div class="tree-node__action">
          <input
            class="cbx-input"
            [id]="node?.data?.node_id + '_' + unique"
            type="checkbox"
            [disabled]="getCheckedInitializeValue(node?.data?.node_id) || disabled"
            [checked]="isNodeSelected(node) || getCheckedInitializeValue(node?.data?.node_id)"
            (change)="select(node)"
          />
          <label
            class="cbx-label"
            [for]="node?.data?.id + '_' + unique"
            appTestLocator="union-tree-choice__item-{{ node?.data?.id }}_checkbox"
          >
          </label>
        </div>
        <div
          class="tree-node__content"
          [class]="'tree-node-pl-' + node.level"
          appTestLocator="union-tree-choice__item-{{ node?.data?.id }}"
        >
          <div
            class="node-content-wrapper"
            [class.node-content-wrapper-active]="node.isActive"
            [class.node-content-wrapper-focused]="node.isFocused"
          >
            <ng-container *treeMobxAutorun="{ dontDetach: false }">
              <span
                [class.toggle-children-wrapper-expanded]="node.isExpanded"
                [class.toggle-children-wrapper-collapsed]="node.isCollapsed"
                class="toggle-children-wrapper"
                (click)="node.mouseAction('expanderClick', $event)"
                appTestLocator="union-tree-choice__item-{{ node?.data?.id }}_arrow"
              >
                <span class="toggle-children">
                  <app-svg-icon
                    [name]="!node.isExpanded ? 'caret_down' : 'caret_up'"
                    [class.disabled]="!node.hasChildren"
                    width="10"
                  ></app-svg-icon>
                </span>
              </span>
            </ng-container>
            <span class="cbx-label-text">{{ node?.data?.title }}</span>
          </div>
        </div>
      </div>

      <ng-container *ngIf="node?.isExpanded && node?.data?.type === filterTemplateTypes.RANGE">
        <div class="tree-node-range">
          <app-form-range-slider
            [readonly]="true"
            [setValueFrom]="node?.data?.default_range_from"
            [setValueTo]="node?.data?.default_range_to"
          ></app-form-range-slider>
        </div>
      </ng-container>

      <tree-node-children [node]="node" [templates]="templates"></tree-node-children>
    </ng-template>
    <ng-template #loadingTemplate>Загрузка...</ng-template>
  </tree-root>
</div>
