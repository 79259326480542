import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NotificationsService } from 'angular2-notifications';

import { takeUntil } from 'rxjs/operators';

import { Project, ProjectComment } from '@app/+competence-map/models/projects.models';
import { ProjectCommentAction } from '@app/+competence-map/constants/competence-map.constants';
import { CompetenceService } from '@app/+competence-map/services/competence.service';
import { DestroyService } from '@app/services/destroy.service';
import { PortalService } from '@app/shared/services/portal.service';

@Component({
  selector: 'app-project-comment-modal',
  templateUrl: './project-comment-modal.component.html',
  styleUrls: ['./project-comment-modal.component.scss'],
  providers: [DestroyService, PortalService],
})
export class ProjectCommentModalComponent {
  @Input() userId: number;

  @Output() closeEvent = new EventEmitter();
  @Output() reloadEvent = new EventEmitter();

  public commentField: FormControl = new FormControl(null);
  public isEditComment: boolean = false;
  public isCommentForm: boolean = false;
  public comments: ProjectComment[] = [];
  public selectedCommentIndex: number | null = null;

  private _project: Project;

  get project(): Project {
    return this._project;
  }

  @Input()
  set project(value: Project) {
    if (value) {
      this.commentField.setValue(null);
      this.isCommentForm = !value?.comments?.length;
      this._project = value;
      this.comments = [...value?.comments] || [];
    }
  }

  constructor(
    private readonly competenceService: CompetenceService,
    private readonly destroy: DestroyService,
    private readonly notify: NotificationsService
  ) {}

  public saveComment(): void {
    if (!this.commentField.value) {
      this.notify.error('Внимание', 'Вы не можете добавить пустой комментарий');
      return;
    }

    let newComment: Partial<ProjectComment> | null = null;

    if (this.isEditComment && (this.selectedCommentIndex || this.selectedCommentIndex === 0)) {
      this.comments[this.selectedCommentIndex].value = this.commentField.value;
      this.comments[this.selectedCommentIndex].action = ProjectCommentAction.CHANGED;
    } else {
      newComment = {
        action: ProjectCommentAction.CREATED,
        value: this.commentField.value,
      };
    }

    const comments = [...this.comments];
    if (newComment) {
      comments.push(newComment as ProjectComment);
    }

    this.competenceService
      .updateProject({
        id: this._project.id,
        comments,
      } as Project)
      .pipe(takeUntil(this.destroy))
      .subscribe(
        (updateProject) => {
          this.selectedCommentIndex = null;
          this.isEditComment = false;
          this.isCommentForm = false;
          this.comments = updateProject.comments;
          this.reloadEvent.emit();
        },
        ({ error }) => {
          if (error && error?.errors?.comments) {
            let errorMessage = '';
            error?.errors?.comments.forEach((value) => {
              errorMessage += value + '\n';
            });
            this.notify.error('Внимание', errorMessage);
          }
        }
      );
  }

  public newComment(): void {
    this.isCommentForm = true;
    this.isEditComment = false;
    this.commentField.setValue(null);
  }

  public editComment(comment: ProjectComment, index: number): void {
    this.isCommentForm = true;
    this.isEditComment = true;
    this.selectedCommentIndex = index;
    this.commentField.setValue(comment.value);
  }

  public cancel(): void {
    if (this.isEditComment || this.comments?.length) {
      this.isCommentForm = false;
      this.isEditComment = false;
      this.selectedCommentIndex = null;
    }
    this.commentField.setValue(null);
  }
}
