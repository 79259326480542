import { Column } from '@app/shared/models/table';
import { FlaskQueryFilter } from '@app/shared/models/filters.model';

export enum UnitsStatuses {
  ACTIVE = 'active',
  ARCHIVE = 'archive',
}

export enum UnitsColumnKeys {
  ID = 'id',
  VALUE = 'value',
  STATUS = 'status',
  NAME = 'name',
  FULL_NAME = 'full_name',
  CREATED_AT = 'created_at',
  AUTHOR_ID = 'author_id',
  REMOVED_AT = 'archive_date',
  DELETED_BY_USER_ID = 'updated_by',
  TEMPLATE_FILTERS = 'template_filters',
}

export const UNITS_COLUMNS: Column<FlaskQueryFilter[]>[] = [
  {
    id: UnitsColumnKeys.ID,
    name: 'ID',
    isEnabledSearch: true,
    searchPlaceholder: 'Поиск по ID',
    width: 100,
    filterConfig: [
      {
        name: UnitsColumnKeys.ID,
        op: 'in_',
        val: '',
      },
    ],
  },
  {
    id: UnitsColumnKeys.FULL_NAME,
    width: 250,
    name: 'Полное название',
    isEnabledSearch: true,
    searchPlaceholder: 'Поиск по полному названию',
    filterConfig: [
      {
        name: UnitsColumnKeys.FULL_NAME,
        op: 'in_',
        val: '',
      },
    ],
  },
  {
    id: UnitsColumnKeys.NAME,
    width: 250,
    name: 'Сокращенное название',
    isEnabledSearch: true,
    searchPlaceholder: 'Поиск по сокращенному названию',
    filterConfig: [
      {
        name: UnitsColumnKeys.NAME,
        op: 'in_',
        val: '',
      },
    ],
  },
  {
    id: UnitsColumnKeys.TEMPLATE_FILTERS,
    width: 200,
    name: 'ID фильтров БШФ',
    isEnabledSearch: true,
    searchPlaceholder: 'Поиск по ID фильтров БШФ',
    filterConfig: [
      {
        name: UnitsColumnKeys.TEMPLATE_FILTERS,
        op: 'in_',
        val: '',
      },
    ],
  },
  {
    id: UnitsColumnKeys.AUTHOR_ID,
    width: 200,
    name: 'ID автора',
    isEnabledSearch: true,
    searchPlaceholder: 'Поиск по ID автора',
    filterConfig: [
      {
        name: UnitsColumnKeys.AUTHOR_ID,
        op: 'in_',
        val: '',
      },
    ],
  },
  {
    id: UnitsColumnKeys.CREATED_AT,
    width: 200,
    name: 'Дата создания',
    isEnabledSearch: true,
    searchPlaceholder: 'Поиск по дате',
    filterConfig: [
      {
        name: UnitsColumnKeys.CREATED_AT,
        op: 'between',
        val: null,
      },
    ],
  },
  {
    id: UnitsColumnKeys.STATUS,
    width: 200,
    name: 'Статус',
    isEnabledFilter: true,
    searchPlaceholder: 'Показать',
    filterConfig: [
      {
        name: UnitsColumnKeys.STATUS,
        op: 'in_',
        val: '',
      },
    ],
  },
  {
    id: UnitsColumnKeys.DELETED_BY_USER_ID,
    width: 250,
    name: 'ID удалившего',
    searchPlaceholder: 'Поиск по ID удалившего',
    isEnabledSearch: true,
    filterConfig: [
      {
        name: UnitsColumnKeys.DELETED_BY_USER_ID,
        op: 'in_',
        val: '',
      },
    ],
  },
  {
    id: UnitsColumnKeys.REMOVED_AT,
    width: 250,
    name: 'Дата удаления',
    isEnabledSearch: true,
    searchPlaceholder: 'Поиск по дате',
    filterConfig: [
      {
        name: UnitsColumnKeys.REMOVED_AT,
        op: 'between',
        val: null,
      },
    ],
  },
].map((column) => {
  return {
    ...column,
    defaultFilterConfig: column.filterConfig,
  };
});
