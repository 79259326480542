import { AfterViewInit, Component, Input, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { ModalInjectorService } from '@app/modal-injector/services/modal-injector.service';
import { EventHourListModel, STATE_DAY_PLANNER, StateListColorModel } from '@app/calendar/constants/calendar.constants';
import { FINISH_STATE, LIST_STATE, STATE } from '@app/shared/components/calendar-widget/constants/calendar.constants';
import { ModalInjectorConstants } from '@app/modal-injector/constants/modal-injector.constants';
import { DATE_FORMAT } from '@app/shared/constants/date.constants';
import { POSITION } from '@app/shared/components/move-pin/constants/move-pin.constants';
import { debounce, range } from 'lodash-es';

@Component({
  selector: 'app-view-week',
  templateUrl: './view-week.component.html',
  styleUrls: ['./view-week.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ViewWeekComponent implements AfterViewInit {
  @Input() currentYear;
  @Input() currentDayOnCalendar;
  @Input() calendarData = [];
  @Input() eventForHour: EventHourListModel;
  state_day = STATE_DAY_PLANNER;
  state = STATE;
  finishState = FINISH_STATE;
  listState = LIST_STATE;
  currentHour: number = +moment().locale('ru').format('HH');
  currentDay: string = moment().locale('ru').format('D');
  heightHeader = 35;
  maxLengthList = 3;
  range = range;
  recountEventStateDeb = debounce(this.recountEventState, 50);
  upStateList: StateListColorModel = {};
  downStateList: StateListColorModel = {};

  constructor(private modalInjectorService: ModalInjectorService) {}

  ngAfterViewInit() {
    setTimeout(() => {
      const el = document.getElementById('row_8');
      if (el) {
        el.scrollIntoView();
        document.getElementById('view-week').scrollTop -= this.heightHeader;
        this.recountEventState();
      }
    }, 100);
  }

  recountEventState() {
    const mainWindow = document.getElementById('view-week').getBoundingClientRect();
    let firstRow = 23,
      lastRow = 0;
    this.range(24).forEach((hour) => {
      const row = document.getElementById(`row_${hour}`).getBoundingClientRect();
      if (row.top + row.height / 2 > mainWindow.top && row.top + row.height / 2 < mainWindow.top + mainWindow.height) {
        // tslint:disable-next-line:no-unused-expression
        hour < firstRow && (firstRow = hour);
        // tslint:disable-next-line:no-unused-expression
        hour > lastRow && (lastRow = hour);
      }
    });
    this.calendarData.forEach((day) => {
      this.upStateList[day.day] = {};
      this.downStateList[day.day] = {};
      this.range(firstRow).forEach((hour) => {
        this.eventForHour[day.day]?.[hour].list.forEach((event) => {
          this.upStateList[day.day][event.status] = true;
        });
      });
      this.range(lastRow + 1, 24).forEach((hour) => {
        this.eventForHour[day.day]?.[hour].list.forEach((event) => {
          this.downStateList[day.day][event.status] = true;
        });
      });
    });
  }

  setColor(state) {
    // tslint:disable-next-line:triple-equals
    return this.listState.find((item) => item.key == state).color;
  }

  findStateHelper(state) {
    // tslint:disable-next-line:triple-equals
    return this.listState.find((item) => item.key == state)?.text || '';
  }

  checkDay(day, hour) {
    return +moment(`${String(hour).padStart(2, '0')} ${day}`, `HH ${DATE_FORMAT}`) >= +moment().startOf('hours');
  }

  infoForDay(event, day) {
    const parent = event.target.getBoundingClientRect();
    this.modalInjectorService.addModal(ModalInjectorConstants.INFO_FOR_DAY, {
      day: day.day,
      dayForDatepicker: day.dayForDatepicker,
      parent,
      position: POSITION.CENTER,
    });
  }

  addEvent(day, hour) {
    this.modalInjectorService.addModal(ModalInjectorConstants.ADD_EVENT_CALENDAR, {
      show: true,
      addEvent: true,
      day,
      hour: (hour + ':00').padStart(5, '0'),
    });
  }
}
