import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-change-status-warning',
  templateUrl: './change-status-warning.component.html',
  styleUrls: ['./change-status-warning.component.scss'],
})
export class ChangeStatusWarningComponent implements OnInit {
  @Input() status: string = 'архив';

  @Input() isOpened = true;

  @Output() cancelEvent = new EventEmitter();
  @Output() agreeEvent = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  agree() {
    this.agreeEvent.emit();
    this.isOpened = false;
  }

  cancel() {
    this.cancelEvent.emit();
    this.isOpened = false;
  }
}
