import { PaymentRegistryColumn } from '../models/payment-registry-column.model';

export const paymentAccrualsColumns: PaymentRegistryColumn[] = [
  {
    id: 'id',
    label: 'ID операции',
    width: '110px',
    checkbox: true,
  },
  {
    id: 'type',
    label: 'Тип операции',
    width: '170px',
    filter: 'filter',
  },
  {
    id: 'user_id',
    label: 'ID / ИНН пользователя',
    width: '170px',
  },
  {
    id: 'name_user_or_company',
    label: 'Пользователь',
    width: '190px',
  },
  {
    id: 'user_status',
    label: 'Статус пользователя',
    width: '140px',
    filter: 'filter',
  },
  {
    id: 'trade_id',
    label: 'ID торгов',
    width: '120px',
  },
  {
    id: 'amount',
    label: 'Сумма операции',
    width: '130px',
    filter: 'filter',
  },
  {
    id: 'approval_date',
    label: 'Дата операции',
    width: '110px',
    filter: 'filter',
  },
  {
    id: 'payment_order_id',
    label: '№ п/п',
    width: '150px',
  },
  {
    id: 'user_created',
    label: 'Оператор операции',
    width: '165px',
  },
  {
    id: 'meta',
    label: 'Мета-данные',
    width: '105px',
    filter: 'filter',
  },
  {
    id: 'comment',
    label: 'Комментарий',
    width: '205px',
  },
];

export const paymentAccrualsFilteKey = {
  id: ['id'],
  type: ['type'],
  user_id: ['user_id', 'user_inn'],
  name_user_or_company: ['name_user_or_company'],
  user_status: ['user_status'],
  trade_id: ['trade_id'],
  amount: ['from_amount', 'to_amount'],
  approval_date: ['from_approval_date', 'to_approval_date'],
  payment_order_id: ['payment_order_id'],
  user_created: ['user_created'],
  meta: ['is_manual', 'is_reverse'],
  comment: ['comment'],
};

export const fileLogAccrualsColumns: PaymentRegistryColumn[] = [
  {
    id: 'transaction_id',
    label: 'ID операции',
    width: '110px',
  },
  {
    id: 'created_at',
    label: 'Дата операции',
    width: '110px',
    filter: 'filter',
  },
  {
    id: 'file_name',
    label: 'Название закачанного файла',
    width: '170px',
  },
  {
    id: 'user_full_name',
    label: 'ФИО оператора',
    width: '190px',
  },
];

export const fileLogAccrualsFilteKey = {
  transaction_id: ['transaction_id'],
  created_at: ['from_created_at', 'to_created_at'],
  file_name: ['file_name'],
  user_full_name: ['user_full_name'],
};

export enum UploadPaymentOpenEnum {
  FILE_MANAGER = 'FileManager',
  FILE_RESULT = 'FileResult',
  SEND_TRANSACTION = 'SendTransaction',
  SEND_TRANSACTION_FORCE = 'SendTransactionForce',
}

export enum UploadPaymentTypeFileEnum {
  CHECKING_ACCOUNT = 'xls_checking_account',
  ACQUIRING = 'xls_acquiring',
}

export interface UploadFileType {
  title: string;
  value: UploadPaymentTypeFileEnum;
}

export const UPLOAD_FILE_TYPE: UploadFileType[] = [
  { title: 'Excel-файл с оплатами через расчетный счет', value: UploadPaymentTypeFileEnum.CHECKING_ACCOUNT },
  { title: 'Excel-файл с оплатами через эквайринг', value: UploadPaymentTypeFileEnum.ACQUIRING },
];

export enum UploadPaymentTypeEnum {
  CHECKING_ACCOUNT = 'checking_account',
  ACQUIRING = 'acquiring',
}

export interface UploadPaymentType {
  title: string;
  value: UploadPaymentTypeEnum;
}

export const UPLOAD_PAYMENT_TYPE: UploadPaymentType[] = [
  { title: 'Рачетный счет', value: UploadPaymentTypeEnum.CHECKING_ACCOUNT },
  { title: 'Эквайринг', value: UploadPaymentTypeEnum.ACQUIRING },
];

export const paymentsFromFileColumns: PaymentRegistryColumn[] = [
  {
    id: 'checkbox',
    label: '',
    width: '50px',
    checkbox: true,
    error: false,
  },
  {
    id: 'approval_date',
    label: 'Дата операции',
    width: '110px',
    error: null,
  },
  {
    id: 'payment_type',
    label: 'Тип транзакции',
    width: '170px',
    error: null,
  },
  {
    id: 'itn',
    label: '№ ПП',
    width: '170px',
    error: null,
  },
  {
    id: 'itn',
    label: 'ИНН',
    width: '190px',
    error: null,
  },
  {
    id: 'amount',
    label: 'Сумма операции',
    width: '190px',
    error: null,
  },
  {
    id: 'comment',
    label: 'Комментарий',
    width: '190px',
    error: null,
  },
  {
    id: 'error',
    label: 'Ошибка',
    width: '250px',
    error: true,
  },
];
