<!--<ul class="tree-list">-->
<!--  <li-->
<!--    *ngFor="let file of files"-->
<!--    [class.active]="file.id === current.id"-->
<!--    [class.opened]="file.opened"-->
<!--    [class.list-second__disabled]="isSearchResults(file)"-->
<!--    class="tree-list__item"-->
<!--  >-->
<!--    <app-modal-window [isShowed]="file.hasShare() && file.showUserInfo" (closeEvent)="toggleInfoUser(file)">-->
<!--      <app-user-card [user]="file.author" *ngIf="!!file.showUserInfo"></app-user-card>-->
<!--    </app-modal-window>-->
<!--    <ng-container-->
<!--      [ngTemplateOutlet]="file.hasFile() ? FILE_TEMPLATE : FOLDER_TEMPLATE"-->
<!--      [ngTemplateOutletContext]="{ $implicit: file }"-->
<!--    ></ng-container>-->

<!--    <app-file-manager-tree-->
<!--      *ngIf="file.opened && !file.hasFile()"-->
<!--      [files]="file.children"-->
<!--      [current]="current"-->
<!--      (onOpen)="open($event)"-->
<!--      (onShare)="share($event)"-->
<!--    ></app-file-manager-tree>-->
<!--  </li>-->
<!--  <li *ngIf="!files.length">-->
<!--    <span class="tree-list__empty">Папка пуста</span>-->
<!--  </li>-->
<!--</ul>-->

<!--<ng-template #FILE_TEMPLATE let-item>-->
<!--  <div class="tree-list__item-container">-->
<!--    <div class="tree-list__item-title">-->
<!--      <app-svg-icon-->
<!--        *ngIf="item.extType().icon"-->
<!--        [width]="12"-->
<!--        class="tree-list__item-icon margin-l-20"-->
<!--        [name]="item.extType().icon"-->
<!--      ></app-svg-icon>-->
<!--      <span-->
<!--        (dblclick)="toggleOpen(item, true)"-->
<!--        container="body"-->
<!--        triggers="mouseenter:mouseleave"-->
<!--        placement="top"-->
<!--        class="tree-list__item-text"-->
<!--        [ngbTooltip]="item.name"-->
<!--        [innerHTML]="item.name | highlight: searchQuery"-->
<!--      >-->
<!--        {{ item.name }}-->
<!--      </span>-->
<!--    </div>-->
<!--    <div class="tree-list__item-info">-->
<!--      <ng-container-->
<!--        [ngTemplateOutlet]="FILE_SHARED_TEMPLATE"-->
<!--        [ngTemplateOutletContext]="{ $implicit: item }"-->
<!--      ></ng-container>-->
<!--    </div>-->
<!--  </div>-->
<!--</ng-template>-->

<!--<ng-template #FOLDER_TEMPLATE let-item>-->
<!--  <div class="tree-list__item-container">-->
<!--    <div class="tree-list__item-title">-->
<!--      <app-svg-icon-->
<!--        [width]="12"-->
<!--        [name]="item.opened ? 'caret_up' : 'caret_down'"-->
<!--        class="tree-list__item-icon tree-list__item-icon&#45;&#45;black"-->
<!--        (click)="toggleOpen(item)"-->
<!--      ></app-svg-icon>-->
<!--      <app-svg-icon-->
<!--        (click)="toggleOpen(item, true)"-->
<!--        [width]="16"-->
<!--        name="folder_open"-->
<!--        class="tree-list__item-icon"-->
<!--        [name]="item.opened ? 'folder_open' : 'folder'"-->
<!--      ></app-svg-icon>-->
<!--      <span-->
<!--        (click)="toggleOpen(item, true)"-->
<!--        container="body"-->
<!--        triggers="mouseenter:mouseleave"-->
<!--        placement="top"-->
<!--        class="tree-list__item-text"-->
<!--        [ngbTooltip]="item.name"-->
<!--        [innerHTML]="item.name | highlight: searchQuery"-->
<!--      >-->
<!--        {{ item.name }}-->
<!--      </span>-->
<!--    </div>-->
<!--    <div class="tree-list__item-info">-->
<!--      <ng-container-->
<!--        [ngTemplateOutlet]="FILE_SHARED_TEMPLATE"-->
<!--        [ngTemplateOutletContext]="{ $implicit: item }"-->
<!--      ></ng-container>-->
<!--    </div>-->
<!--  </div>-->
<!--</ng-template>-->

<!--<ng-template #FILE_SHARED_TEMPLATE let-item>-->
<!--  <button-->
<!--    [ngStyle]="{ visibility: item.is_shared ? 'visible' : 'hidden' }"-->
<!--    container="body"-->
<!--    triggers="mouseenter:mouseleave"-->
<!--    placement="top"-->
<!--    ngbTooltip="Управление совместным доступом к файлам/папкам"-->
<!--    class="btn-link hover btn-link__share"-->
<!--    (click)="share([item])"-->
<!--    *ngIf="!item.hasShare()"-->
<!--  >-->
<!--    <app-svg-icon class="opacity-5" [width]="18" name="users"></app-svg-icon>-->
<!--  </button>-->
<!--  <button-->
<!--    class="btn-link hover btn-link__share"-->
<!--    (click)="toggleInfoUser(item)"-->
<!--    *ngIf="item.hasShare() && !item.hasTrade()"-->
<!--  >-->
<!--    <app-svg-icon class="opacity-5" [width]="18" name="crown"></app-svg-icon>-->
<!--  </button>-->
<!--</ng-template>-->

<!-- TODO: пока оставил для для проверки корректности правки в будущем -->
<ul class="list-unstyled list-second">
  <li
    *ngFor="let file of files"
    [class.active]="file.id === current.id"
    [class.opened]="file.opened"
    [class.list-second__disabled]="isSearchResults(file)"
  >
    <div>
      <app-modal-window [isShowed]="file.hasShare() && file.showUserInfo" (closeEvent)="toggleInfoUser(file)">
        <app-user-card [user]="file.author" *ngIf="!!file.showUserInfo"></app-user-card>
      </app-modal-window>
      <div class="file-manager-tree-text">
        <button
          class="btn-link hover folder-group"
          *ngIf="!file.hasFile()"
          (click)="toggleOpen(file)"
          (dblclick)="toggleOpen(file, true)"
        >
          <div class="file-manager-tree-icon-group">
            <app-svg-icon
              *ngIf="file.opened"
              [width]="12"
              name="caret_up"
              class="file-manager-tree-icon file-manager-tree-icon__black"
            ></app-svg-icon>
            <app-svg-icon
              *ngIf="!file.opened"
              [width]="12"
              name="caret_down"
              class="file-manager-tree-icon file-manager-tree-icon__black"
            ></app-svg-icon>
            <app-svg-icon
              *ngIf="file.opened"
              [width]="16"
              name="folder_open"
              class="file-manager-tree-icon"
            ></app-svg-icon>
            <app-svg-icon *ngIf="!file.opened" [width]="16" name="folder" class="file-manager-tree-icon"></app-svg-icon>
          </div>
        </button>

        <button
          class="btn-link hover folder-group padding-0"
          *ngIf="file.hasFile()"
          (dblclick)="toggleOpen(file, true)"
        >
          <div
            class="file-manager-tree-icon-group file-manager-tree-icon-group-file"
            [ngClass]="{ 'file-manager-tree-icon-group-file_node': file.is_file && file.parent > 0 }"
          >
            <app-svg-icon
              *ngIf="file.extType().icon"
              [width]="12"
              class="file-manager-tree-icon"
              [name]="file.extType().icon"
            ></app-svg-icon>
          </div>
        </button>

        <span
          *ngIf="!file.hasFile()"
          (click)="toggleOpen(file)"
          (dblclick)="toggleOpen(file, true)"
          container="body"
          triggers="mouseenter:mouseleave"
          placement="top"
          class="margin-l-6"
          [ngbTooltip]="file.name"
          [innerHTML]="file.name | highlight: searchQuery"
          >{{ file.name }}</span
        >
        <span
          *ngIf="file.hasFile()"
          (dblclick)="toggleOpen(file, true)"
          container="body"
          triggers="mouseenter:mouseleave"
          placement="top"
          class="margin-l-6"
          [ngbTooltip]="file.name"
          [innerHTML]="file.name | highlight: searchQuery"
          >{{ file.name }}</span
        >

        <button
          [ngStyle]="{ visibility: file.is_shared ? 'visible' : 'hidden' }"
          container="body"
          triggers="mouseenter:mouseleave"
          placement="top"
          ngbTooltip="Управление совместным доступом к файлам/папкам"
          class="btn-link hover btn-link__info btn-link__share"
          (click)="share([file])"
          *ngIf="!file.hasShare()"
        >
          <app-svg-icon class="opacity-5" [width]="18" name="users"></app-svg-icon>
        </button>
        <button
          class="btn-link hover btn-link__info btn-link__share"
          (click)="toggleInfoUser(file)"
          *ngIf="file.hasShare() && !file.hasTrade()"
        >
          <app-svg-icon class="opacity-5" [width]="18" name="crown"></app-svg-icon>
        </button>
      </div>
    </div>
    <app-file-manager-tree
      *ngIf="file.opened && !file.hasFile()"
      [files]="file.children"
      [current]="current"
      (onOpen)="this.open($event)"
      (onShare)="share($event)"
    ></app-file-manager-tree>
  </li>
  <li *ngIf="!files.length">
    <span class="text-center file-manager-tree-empty"
      ><i><small>Папка пуста</small></i></span
    >
  </li>
</ul>
