import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import {
  AggregationDataGeneral,
  DataForUpdateRestrictionsTrade,
  PaymentRegistryReportOptions,
} from '../models/payment-registry.model';
import { PaymentRegistryFilter } from '../models/payment-registry-filter.model';
import { GeneralWalletAggregation } from '../models/wallet-balance.model';
import {
  AddAccrualModel,
  FileLogAccrualModel,
  FileLogFilterAccrualModel,
  GeneralAccrualAggregation,
  ReverseAccrualModel,
} from '../models/payment-accrual.model';
import { ParamsApiUser, User } from '../../shared/models/user.model';
import { filterToParams } from '@app/shared/utils';

@Injectable()
export class PaymentRegistryService {
  constructor(private http: HttpClient) {}

  getPaymentRegistry(filter: Partial<PaymentRegistryFilter>): Observable<AggregationDataGeneral> {
    const params = filterToParams<PaymentRegistryFilter>(filter);
    return this.http.get<AggregationDataGeneral>(`${environment.api_url}/register/payment-register`, { params });
  }

  getCustomerWallet(filter: Partial<PaymentRegistryFilter>): Observable<AggregationDataGeneral> {
    const params: { [param: string]: string | string[] } = filterToParams<PaymentRegistryFilter>(filter);
    return this.http.get<AggregationDataGeneral>(`${environment.api_url}/register/customer`, { params });
  }

  getUsers(filter: ParamsApiUser): Observable<User[]> {
    const params: { [param: string]: string | string[] } = filterToParams<ParamsApiUser>(filter);
    return this.http.get<User[]>(`${environment.api_url}/users`, { params });
  }

  getAgentWallet(filter: Partial<PaymentRegistryFilter>): Observable<AggregationDataGeneral> {
    const params: { [param: string]: string | string[] } = filterToParams<PaymentRegistryFilter>(filter);
    return this.http.get<AggregationDataGeneral>(`${environment.api_url}/register/agent`, { params });
  }

  updateRestrictionsTrade(body: DataForUpdateRestrictionsTrade) {
    return this.http.post<AggregationDataGeneral>(`${environment.api_url}/update-restrictions-trade`, body);
  }

  getFileByPayerId(payerId: number, month: number, year: number, reportType: 'aps' | 'invoice'): Observable<any> {
    return this.http.get(`${environment.api_url}/month-bill/${reportType}/${year}/${month}/${payerId}`, {
      responseType: 'blob',
    });
  }

  pdfReport({ registryType, name, pageSize, layout }: PaymentRegistryReportOptions) {
    return this.http.post<any>(`${environment.api_url}/registry-trades-to-pdf/${registryType}`, {
      html_report: name,
      orientation: layout,
      page_size: pageSize,
    });
  }

  downloadPdf(url) {
    return this.http.get(url, { responseType: 'blob', observe: 'response' });
  }

  /**
   * Выгрузка кошелька агента
   * @param filter
   */
  getWalletAgent(filter: Partial<any>): Observable<GeneralWalletAggregation> {
    const params = filterToParams<any>(filter);
    return this.http.get<GeneralWalletAggregation>(`${environment.api_url}/wallet/agent`, { params });
  }

  /**
   * Выгрузка кошелька клиента
   * @param filter
   */
  getWalletCustomer(filter: Partial<any>): Observable<GeneralWalletAggregation> {
    const params = filterToParams<any>(filter);
    return this.http.get<GeneralWalletAggregation>(`${environment.api_url}/wallet/customer`, { params });
  }

  /**
   * Выгрузка кошелька суперюзера и бухгалтера
   * @param filter
   */
  getWalletFull(filter: Partial<any>): Observable<GeneralWalletAggregation> {
    const params = filterToParams<any>(filter);
    return this.http.get<GeneralWalletAggregation>(`${environment.api_url}/wallet/full`, { params });
  }

  getAccrual(filter: Partial<any>): Observable<GeneralAccrualAggregation> {
    const params = filterToParams<any>(filter);
    return this.http.get<GeneralAccrualAggregation>(`${environment.api_url}/transaction`, {
      params: { ...params, sort_id: 'desk' },
    });
  }

  addAccrual(body: AddAccrualModel) {
    return this.http.post<any>(`${environment.api_url}/transaction`, body);
  }

  reverseAccrual(body: ReverseAccrualModel) {
    return this.http.post<any>(`${environment.api_url}/transaction/reverse`, body);
  }

  getFileLogAccrual(filter: Partial<FileLogFilterAccrualModel>) {
    const params: { [param: string]: string | string[] } = filterToParams<FileLogFilterAccrualModel>(filter);
    return this.http.get<FileLogAccrualModel[]>(`${environment.api_url}/transaction/from-file/history`, { params });
  }
}
