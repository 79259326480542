import { takeUntil } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { ROLES, RolesEnum } from '@app/shared/constants/roles.constants';
import { AuthService } from '@app/shared/services/auth.service';
import { ChatService } from '@app/chat/services/chat.service';
import { SocketDataService } from '@app/services/socket-data.service';
import { ChatSectionsEnum } from '@app/chat/constants/chat-sections.constants';
import { ChatRoom, ChatSection } from '@app/chat/models/chat.model';
import { User } from '@app/shared/models/user.model';

@Component({
  selector: 'app-chat-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss'],
})
export class ChatGroupComponent implements OnInit, OnDestroy {
  @Input() group: ChatRoom;
  @Input() contactSelected: ChatRoom;

  roles = ROLES;
  themes = [];
  userId: number;
  user: User;
  filterString: string;
  chatSectionSelected: ChatSection;
  groupSelected: ChatRoom;
  isReallyRemoving: boolean = false;
  chatSectionsEnum = ChatSectionsEnum;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private chatService: ChatService,
    private chatDataService: SocketDataService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.filterString = this.chatService.contactsFilter;
    this.chatSectionSelected = this.chatService.getChatSectionSelected();
    this.chatService.contactsFilterChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((filterString) => {
      this.filterString = filterString;
      this.filterThemes(this.chatService.getThemes());
    });
    this.authService.userStream.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => (this.user = user));
    this.userId = +this.authService.user_id;
    this.filterThemes(this.chatService.getThemes());

    this.chatService.themesChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((themes) => this.filterThemes(themes));
    this.chatService.chatSectionSelectedChanged
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((chatSectionSelected) => (this.chatSectionSelected = chatSectionSelected));

    this.chatService.groupExpanded.pipe(takeUntil(this.ngUnsubscribe)).subscribe((group) => {
      this.group.isGroupExpanded = this.group.id === group.id;
    });

    this.chatService.groupSelectedChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((groupSelected) => {
      this.groupSelected = groupSelected;
    });
  }

  filterThemes(themes) {
    this.themes = [];

    Object.keys(themes).forEach((room_id) => {
      const theme = themes[room_id];
      if (theme.group_id === this.group.id) {
        this.themes.push(theme);
      }
    });

    // Для тех. чата
    if (this.group.topics) {
      this.group.topics.forEach((t) => {
        t.section = this.chatSectionSelected;
        this.themes.push(t);
      });
    }
  }

  onGroupSelect(group: ChatRoom) {
    const { room_id } = group;
    this.chatService.setRoomSelected(group);
    this.chatService.focusChanged.next(true);

    if (group.counter) {
      this.chatDataService.setMessageRead(room_id);
    }
  }

  onAddTheme($event: Event) {
    $event.stopPropagation();
    this.chatService.setEditingGroupOrThemeObject({
      group_id: this.group.id,
      owner_id: this.group.owner_id,
      trade_id: this.group.trade_id,
      group: this.group,
    } as ChatRoom);
    this.chatService.isEditingGroupOrThemeChanged.next(true);
  }

  onGroupEdit($event: Event) {
    $event.stopPropagation();
    console.log('SELECTED GROUP', this.group);
    if (
      this.chatSectionSelected.name !== ChatSectionsEnum.TECH /* &&
      this.chatSectionSelected.name !== ChatSectionsEnum.TRADE*/
    ) {
      this.chatService.setEditingGroupOrThemeObject(this.group);
      this.chatService.isEditingGroupOrThemeChanged.next(true);
    }
  }

  onRemoveGroupAsk($event: Event) {
    $event.stopPropagation();
    this.isReallyRemoving = true;
  }

  onRemoveGroup() {
    this.isReallyRemoving = false;
    this.chatDataService.removeGroup({
      group_id: this.group.id,
      section: this.chatService.getChatSectionSelected().name,
    });
  }

  onToggleGroupExpanded() {
    if (!this.themes.length) {
      return;
    }

    this.group.isGroupExpanded = !this.group.isGroupExpanded;
  }

  canEditGroup() {
    return (
      this.userId === this.group.owner_id ||
      (this.chatSectionSelected.name === this.chatSectionsEnum.TECH && this.userId === this.group.id) ||
      (this.chatSectionSelected.name === this.chatSectionsEnum.ADMIN && this.user.type === RolesEnum.SUPERUSER)
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
