<div>
  <div class="mb-4">
    <app-multiple-livesearch-select
      #livesearchSelect
      [placeholder]="placeholder"
      [list]="filterSuggestions"
      [title]="title"
      [isNumber]="isNumber"
      [template]="template"
      (searchEvent)="searchEvent.next({ value: $event })"
      (selectEvent)="select($event)"
    >
    </app-multiple-livesearch-select>
  </div>

  <div class="d-flex justify-content-end">
    <button
      class="btn btn-dc-primary"
      appTestLocator="multiple-search__confirm"
      [disabled]="isApplyDisable"
      (click)="apply()"
    >
      Применить
    </button>
  </div>
</div>
