import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ITreeOptions, TreeNode } from '@circlon/angular-tree-component';

import { FileManagerShareUserEnum } from '@app/file-manager/constants/file-manager-share.constants';
import { AuthorFile } from '@app/file-manager/models/author-file.model';
import { RolesEnum } from '@app/shared/constants/roles.constants';

@Component({
  selector: 'app-file-manager-share-user-tree-item',
  templateUrl: './file-manager-share-user-tree-item.component.html',
  styleUrls: ['./file-manager-share-user-tree-item.component.scss'],
})
export class FileManagerShareUserTreeItemComponent {
  @Input() node: TreeNode;
  @Input() item: AuthorFile;
  @Input() type: FileManagerShareUserEnum;
  @Input() detached: boolean;
  @Input() treeOptions: ITreeOptions;
  @Input() readonly: boolean = false;

  @Output() onToggle = new EventEmitter();
  @Output() onInfo = new EventEmitter<AuthorFile>();
  @Output() onCompetence = new EventEmitter<AuthorFile>();
  @Output() onAdd = new EventEmitter<AuthorFile>();

  get fileManagerShareUserType(): typeof FileManagerShareUserEnum {
    return FileManagerShareUserEnum;
  }

  get userTypes(): typeof RolesEnum {
    return RolesEnum;
  }

  toggleNodeClicked(): void {
    this.onToggle.emit();
  }

  toggleSelected(items: AuthorFile[]): void {
    items.forEach((item: AuthorFile) => {
      item.selected = this.item.selected;
      if (item.children) {
        this.toggleSelected(item.children);
      }
    });
  }

  add(): void {
    if (this.readonly) {
      return;
    }
    this.item.selected = true;
    if (this.node && this.item.children && this.treeOptions.useTriState) {
      this.toggleSelected(this.item.children);
    }
    this.onAdd.emit(this.item);
  }

  onCheckboxChange(event): void {
    event.stopPropagation();
    this.item.selected = !this.item.selected;
    if (this.node && this.item.children && this.treeOptions.useTriState) {
      this.toggleSelected(this.item.children);
    }
  }

  info(event): void {
    this.onInfo.emit(this.item);
    event.stopPropagation();
  }

  competence(event): void {
    this.onCompetence.emit(this.item);
    event.stopPropagation();
  }
}
