<div (click)="cancel()" class="dc-fileman-modal-x-button">
  <app-svg-icon [width]="20" [height]="20" name="close"></app-svg-icon>
</div>

<div class="modal-header">
  <div class="modal-title">Компетенции {{ user | initials }}</div>
</div>

<div class="modal-body">
  <app-competence-map-view
    *ngIf="competencies$ | async as competencies"
    [competencies]="competencies"
  ></app-competence-map-view>
</div>
