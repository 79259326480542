import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Subject } from 'rxjs';

import { UserFile } from '@app/file_manager/models/user-file.model';

@Component({
  selector: 'app-select-documents-dialog',
  templateUrl: './select-documents-dialog.component.html',
  styleUrls: ['./select-documents-dialog.component.scss']
})
export class SelectDocumentsDialogComponent implements OnInit, OnDestroy {
  @Input() title: string;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public selectedFile: UserFile | null = null;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.ngUnsubscribe = null;
  }

  public close(): void {
    this.activeModal.close();
  }

  public success(): void {
    this.activeModal.close({'file': this.selectedFile});
  }

  public selectFile(userFile: UserFile[]): void {
    this.selectedFile = userFile[0];
  }
}
