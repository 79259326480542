import { Component } from '@angular/core';

@Component({
  selector: 'app-svg-modal-close',
  template: `
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.5891 4.41076C15.9145 4.7362 15.9145 5.26384 15.5891 5.58928L5.58909 15.5893C5.26366 15.9147 4.73602 15.9147 4.41058 15.5893C4.08514 15.2638 4.08514 14.7362 4.41058 14.4108L14.4106 4.41076C14.736 4.08533 15.2637 4.08533 15.5891 4.41076Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.41058 4.41076C4.73602 4.08533 5.26366 4.08533 5.58909 4.41076L15.5891 14.4108C15.9145 14.7362 15.9145 15.2638 15.5891 15.5893C15.2637 15.9147 14.736 15.9147 14.4106 15.5893L4.41058 5.58928C4.08514 5.26384 4.08514 4.7362 4.41058 4.41076Z"
        fill="currentColor"
      />
    </svg>
  `,
})
export class SvgModalCloseComponent {}
