import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-search-text',
  templateUrl: './search-text.component.html',
  styleUrls: ['./search-text.component.scss'],
})
export class SearchTextComponent implements OnInit, OnChanges {
  @Input() placeholder: string;
  @Input() value: string;
  @Input() filterSuggestions: string[];
  @Input() isNumber: boolean;

  @Output() submitEvent = new EventEmitter<{ value: string }>();
  @Output() searchEvent = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {}

  ngOnChanges() {
    if (!this.placeholder) {
      this.placeholder = '';
    }
  }

  apply() {
    this.submitEvent.emit({ value: this.value });
  }

  search(value: string) {
    this.value = value;
    this.searchEvent.emit(value);
  }

  select(value: string) {
    this.value = value;
  }
}
