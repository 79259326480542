import { UserFile } from './user-file.model';

export enum BufferTypeEnum {
  COPY = 'copy',
  CUT = 'cut',
}

export interface BufferInterface {
  type: BufferTypeEnum;
  files: UserFile[];
}

export interface BufferFilePath {
  fileId: number;
  files: UserFile[];
}
