import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import {
  CreateOrChangeFilterTemplateParams,
  FilterTemplate,
  TreeDataAction,
} from '@app/+competence-map/models/filter-templates.model';
import { CompetenceService } from './competence.service';
import { FilterTemplatesHelper } from '../helpers/filter-templates.helper';
import { switchMap, map, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { MultipleSearchSuggestion } from '@app/shared/models/multiple-search.model';
import { FilterTemplateTypes } from '@app/+competence-map/constants/filter-templates.constants';

@Injectable({
  providedIn: 'root',
})
export class FilterTemplateDataService {
  treeActionSubject = new Subject<TreeDataAction>();
  treeAction$ = this.treeActionSubject.asObservable();

  constructor(private readonly competenceService: CompetenceService) {}

  deleteTreeFilterDataItem(params: CreateOrChangeFilterTemplateParams, event: TreeDataAction): void {
    if (params.childes) {
      for (let i = 0; i < params.childes.length; i++) {
        if (params.childes[i].index === event.data.index) {
          params.childes.splice(i, 1);
          break;
        } else {
          this.deleteTreeFilterDataItem(params.childes[i], event);
        }
      }
    }
  }

  addIndexToFilterData(data: CreateOrChangeFilterTemplateParams): CreateOrChangeFilterTemplateParams {
    if (data) {
      const queue: CreateOrChangeFilterTemplateParams[] = [data];
      let currentIndex = 0;

      while (currentIndex < queue.length) {
        const currentNode = queue[currentIndex];
        currentNode.index = currentIndex;

        if (currentNode.childes && Array.isArray(currentNode.childes)) {
          queue.push(...currentNode.childes);
        }

        currentIndex++;
      }
    }

    return data;
  }

  findLastFilterByFilterId(
    tree: CreateOrChangeFilterTemplateParams,
    nodeId: string
  ): CreateOrChangeFilterTemplateParams | null {
    if (tree && tree.node_id === nodeId) {
      return tree;
    }

    if (tree && tree?.childes && tree.childes.length) {
      for (let i = tree.childes.length - 1; i >= 0; i--) {
        const result = this.findLastFilterByFilterId(tree.childes[i], nodeId);
        if (result) {
          return result;
        }
      }
    }

    return null;
  }

  findLastFilterByTitleAndType(
    tree: CreateOrChangeFilterTemplateParams,
    title: string,
    type: FilterTemplateTypes
  ): CreateOrChangeFilterTemplateParams | null {
    if (tree && tree.node_info.title === title && tree.node_info.type === type) {
      return tree;
    }

    if (tree && tree?.childes && tree.childes.length) {
      for (let i = tree.childes.length - 1; i >= 0; i--) {
        const result = this.findLastFilterByTitleAndType(tree.childes[i], title, type);
        if (result) {
          return result;
        }
      }
    }

    return null;
  }

  findParentFilterByDeletedFilterIndex(
    tree: CreateOrChangeFilterTemplateParams,
    index: number,
    parent: CreateOrChangeFilterTemplateParams | null = null
  ): CreateOrChangeFilterTemplateParams | null {
    if (tree.index === index) {
      return parent;
    }

    if (tree.childes && Array.isArray(tree.childes)) {
      for (const child of tree.childes) {
        const result = this.findParentFilterByDeletedFilterIndex(child, index, tree);
        if (result) {
          return result;
        }
      }
    }

    return null;
  }

  // TODO: допилить стрим автокомплита
  filterTemplatesSource<T>(source: Observable<T>): Observable<MultipleSearchSuggestion[]> {
    return source.pipe(
      debounceTime(600),
      distinctUntilChanged(),
      switchMap((value) => {
        return this.competenceService.getFilterTemplates([{ name: 'title', op: 'ilike', val: `%${value}%` }]).pipe(
          switchMap((filterTemplates) => {
            const ids = filterTemplates.map((v) => Number(v.id));
            return this.competenceService.getTemplateStructureFilter({
              template_filter_ids: ids,
              unload_mirrors: true,
            });
          }),
          map((filterTemplateParams: CreateOrChangeFilterTemplateParams[]) =>
            filterTemplateParams.map((param) => FilterTemplatesHelper.transformToFilterTemplate(param, true))
          ),
          map((filterTemplates) => {
            const getViewTitle = (filterTemplate: FilterTemplate) => {
              let title = filterTemplate.title;
              if (filterTemplate.children?.length) {
                title += '/';
                title += getViewTitle(filterTemplate.children[0]);
                return title;
              } else {
                return title;
              }
            };
            const getLastId = (filterTemplate: FilterTemplate) => {
              if (filterTemplate.children?.length) {
                return getLastId(filterTemplate.children[0]);
              } else {
                return filterTemplate.node_id;
              }
            };
            return filterTemplates.map((filterTemplate: FilterTemplate) => ({
              id: getLastId(filterTemplate),
              title: getViewTitle(filterTemplate),
            }));
          }),
          map((list: any[]) =>
            list.map((item) => ({ id: item.id || item.node_id, label: item.title || item.label, value: true }))
          )
        );
      })
    );
  }
}
