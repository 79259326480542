<div *ngFor="let breadcrumb of breadcrumbs; first as isFirst; last as isLast" class="breadcrumb-area-item">
  <div
    class="breadcrumb-area-item__folder"
    (click)="navigateFolder(breadcrumb)"
    [class.disabled]="isLast"
    appTestLocator="breadcrumbs__file-{{ breadcrumb?.id }}"
  >
    {{ breadcrumb.name }}
  </div>
</div>
