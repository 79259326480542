import { Injectable } from '@angular/core';
import {
  MoveSectionParams,
  Section,
  SectionUpdateParams,
  SplitSectionParams,
  UnionSectionParams,
} from '../models/sections.model';
import { Observable } from 'rxjs';
import { Project } from '../models/projects.models';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FlaskQueryFilter } from '@app/shared/models/filters.model';
import { environment } from '@app/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CompetenceSectionsService {
  constructor(private http: HttpClient) {}

  createSection(body: SectionUpdateParams[]): Observable<Project> {
    return this.http.post(`${environment.api_url}/competency-map/sections`, body);
  }

  updateSection(sectionId: string, body: SectionUpdateParams): Observable<Project> {
    return this.http.patch(`${environment.api_url}/competency-map/sections/${sectionId}`, body);
  }

  moveSection(body: MoveSectionParams): Observable<Project> {
    return this.http.post(`${environment.api_url}/competency-map/sections/move-section`, body);
  }

  unionSections(body: UnionSectionParams): Observable<Project> {
    return this.http.post(`${environment.api_url}/competency-map/sections/union-sections`, body);
  }

  splitSections(body: SplitSectionParams): Observable<Project> {
    return this.http.post(`${environment.api_url}/competency-map/sections/split`, body);
  }

  getSections(filter: FlaskQueryFilter[] = []): Observable<Section[]> {
    const params = new HttpParams({
      fromObject: {
        filter: JSON.stringify(filter),
        sort: 'title',
      },
    });
    return this.http.get<Section[]>(`${environment.api_url}/competency-map/sections`, {
      headers: {
        Accept: 'application/vnd.api+json',
      },
      params,
    });
  }
}
