import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-file-manager-modal-help',
  templateUrl: './file-manager-modal-help.component.html',
  styleUrls: ['./file-manager-modal-help.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileManagerModalHelpComponent {
  constructor(private readonly activeModal: NgbActiveModal) {}

  cancel(): void {
    this.activeModal.close();
  }
}
