<div class="search-text" appClickElsewhere="" (appClickElsewhere)="closeList()">
  <div class="search-text-input mb-4">
    <input
      type="text"
      class="kt-input"
      [onlyInteger]="!!isNumber"
      [(ngModel)]="value"
      (focus)="handleFocus($event)"
      (keyup)="showSuggestions()"
      [placeholder]="placeholder"
      appTestLocator="livesearch-select__input"
    />
  </div>
  <div class="search-text-list" [class.showed]="isListOpen">
    <ng-container *ngIf="list?.length">
      <div
        class="search-text-list-item"
        *ngFor="let item of list | filterSimple: value"
        (click)="selectSuggestion(item)"
        appTestLocator="livesearch-select__list-item-{{ item.id }}"
      >
        {{ item }}
      </div>
    </ng-container>
    <div class="search-text-list-item" *ngIf="!list?.length" appTestLocator="livesearch-select__no-data">
      Нет значений
    </div>
  </div>
</div>
