<div class="section-header">
  <div class="d-flex flex-row">
    <div class="d-flex flex-column">
      <div class="section-header-row" appTestLocator="split-filter-template__author">
        <div class="section-header-label">Автор:</div>
        <div class="section-header-value">{{ userAuthor | roleName }}</div>
      </div>
      <div class="section-header-row" appTestLocator="split-filter-template__date">
        <div class="section-header-label">Дата:</div>
        <div class="section-header-value">{{ projectDate | dateFormat }}</div>
      </div>
    </div>
    <div class="d-flex flex-column ml-3">
      <div class="section-header-row" appTestLocator="split-filter-template__project">
        <div class="section-header-label">Проект ID:</div>
        <div class="section-header-value">{{ projectId }}</div>
      </div>
      <div class="section-header-row" appTestLocator="split-filter-template__status">
        <div class="section-header-label">Статус:</div>
        <div class="section-header-value d-flex align-items-center">
          {{ projectStatus | cmProjectStatus }}&nbsp;&nbsp;
          <app-svg-icon name="change" width="16" class="color-brand-light"></app-svg-icon>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="section-header-text" appTestLocator="split-filter-template__title">Разделить фильтр</div>
  </div>
  <div>
    <div
      class="section-header-archive"
      [class.disabled]="isArchiveDisabled()"
      (click)="archive()"
      appTestLocator="split-filter-template__archive"
    >
      <app-svg-icon name="trash" width="16"></app-svg-icon>
      <span>Перенести в архив</span>
    </div>
  </div>
</div>
<div class="modal-body">
  <div class="section-form">
    <div class="row">
      <div class="col-4">
        <div class="mb-3" appTestLocator="split-filter-template__filter-heading">Название делимого фильтра</div>
        <div class="mb-5">
          <dc-form-field-wrapper [style]="'material'" [vertically]="true">
            <dc-form-input>
              <input
                appTestLocator="split-filter-template__filter-name"
                formInput
                [ngModel]="templateFilter?.title"
                [disabled]="true"
                placeholder="Введите название или выберите из каталога"
              />
            </dc-form-input>
          </dc-form-field-wrapper>
        </div>
        <div class="mb-3" appTestLocator="split-filter-template__union-heading">Единица измерения</div>
        <div class="mb-5">
          <dc-form-field-wrapper [style]="'material'" [vertically]="true">
            <dc-form-input>
              <input
                formInput
                [ngModel]="templateFilter?.units_of_measure"
                [disabled]="true"
                appTestLocator="split-filter-template__union-name"
              />
            </dc-form-input>
          </dc-form-field-wrapper>
        </div>
        <div class="mb-2" appTestLocator="split-filter-template__filter-value_heading">Значения фильтра</div>
        <div class="mb-3 split-tree-view">
          <app-split-tree-view
            [data]="treeItemChildes"
            [selectedNodes]="selectedTreeNodes"
            appTestLocator="split-filter-template__filter-value_view"
          ></app-split-tree-view>
        </div>
      </div>

      <form class="col-8 split-sections" [formGroup]="form">
        <div formArrayName="sections" class="d-flex">
          <div *ngFor="let section of formSections.controls; let i = index" class="mr-4">
            <div
              [formGroupName]="i"
              class="split-new-section"
              appTestLocator="split-filter-template__filter-section-{{ i }}"
            >
              <button
                (click)="removeSection(i)"
                [disabled]="i < 2"
                class="dc-icon-button split-sections__remove"
                appTestLocator="split-filter-template__filter-section-{{ i }}_remove"
              >
                <app-svg-icon name="close-search" width="14"></app-svg-icon>
              </button>
              <div class="mb-3" appTestLocator="split-filter-template__filter-section-{{ i }}_name">
                Название {{ i + 1 }}-го фильтра
              </div>
              <div class="mb-5">
                <dc-form-field-wrapper
                  [style]="'material'"
                  [vertically]="true"
                  [required]="true"
                  [class.disabled]="isDataDisabled()"
                >
                  <dc-form-input [maxLength]="30" [isShowLength]="true">
                    <input
                      formInput
                      formControlName="title"
                      placeholder="Введите название {{ i + 1 }} раздела"
                      appTestLocator="split-filter-template__filter-section-{{ i }}_input"
                    />
                  </dc-form-input>
                </dc-form-field-wrapper>
              </div>
              <div class="mb-3" appTestLocator="split-filter-template__filter-section-{{ i }}_unit-heading">
                Единица измерения
              </div>
              <div class="mb-5">
                <dc-form-field-wrapper
                  [style]="'material'"
                  [vertically]="true"
                  [required]="true"
                  [class.disabled]="isDataDisabled()"
                >
                  <dc-form-input>
                    <input
                      formInput
                      formControlName="unit"
                      appTestLocator="split-filter-template__filter-section-{{ i }}_unit"
                    />
                  </dc-form-input>
                </dc-form-field-wrapper>
              </div>
              <app-split-tree-choice
                *ngIf="treeItemChildes?.length"
                [disabled]="isDataDisabled()"
                [data]="treeItemChildes"
                [selectedNodes]="section.get('values').value"
                (selectEvent)="selectTreeItem($event, i)"
              ></app-split-tree-choice>
            </div>
          </div>

          <div class="add-new-section">
            <button
              class="dc-icon-button"
              (click)="addNewSection()"
              [disabled]="!canAddNew || isDataDisabled()"
              appTestLocator="split-filter-template__add-new-filter"
            >
              <app-svg-icon name="plus" width="14"></app-svg-icon>
              <span>Добавить ещё фильтр</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<app-operation-modal-footer
  (onCancel)="cancel()"
  (onSave)="save($event)"
  (onAgree)="agree($event)"
  [project]="project"
  [form]="form"
>
</app-operation-modal-footer>
