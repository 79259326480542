<div *ngIf="!!isNumber; else isText" class="mb-4">
  <dc-form-field-wrapper [style]="'material'" [vertically]="true" [required]="true">
    <dc-form-input [isShowClear]="true">
      <input
        formInput
        [onlyInteger]="!!isNumber"
        [(ngModel)]="searchValue"
        (ngModelChange)="searchEvent.next($event)"
        [placeholder]="placeholder"
        appTestLocator="multiple-livesearch-select__search"
      />
    </dc-form-input>
  </dc-form-field-wrapper>
</div>

<ng-template #isText>
  <div class="mb-4">
    <dc-form-field-wrapper [style]="'material'" [vertically]="true" [required]="false">
      <dc-form-input [isShowClear]="true">
        <input
          formInput
          [onlyInteger]="false"
          [formControl]="searchControl"
          [placeholder]="placeholder"
          appTestLocator="multiple-livesearch-select__search"
        />
      </dc-form-input>
    </dc-form-field-wrapper>
  </div>
</ng-template>

<div class="multiple-search-text-all" [class.disabled]="disabled || isToggleAllDisable">
  <input class="cbx-input" id="all" name="all" type="checkbox" [(ngModel)]="allChecked" (change)="changeAll()" />
  <label
    class="cbx-label"
    for="all"
    [class.cbx-label-interminate]="partiallySelected"
    appTestLocator="multiple-livesearch-select__select-all"
  >
    <span class="cbx-label-text">{{ title }}</span>
  </label>
</div>

<div class="multiple-search-text-list">
  <ng-container *ngIf="list?.length">
    <div
      *ngFor="let item of list | filter : searchValue : searchedFieldName"
      class="mb-1"
      [class.disabled]="item.disabled || disabled"
    >
      <input
        class="cbx-input"
        id="multiple-livesearch-select{{ item.id }}"
        [name]="item.id"
        type="checkbox"
        [ngModel]="item.value"
        (change)="selectSuggestion(item)"
      />
      <ng-container *ngTemplateOutlet="template ? template : base; context: { $implicit: item }"></ng-container>
    </div>
  </ng-container>
  <div *ngIf="!list?.length" appTestLocator="multiple-livesearch-select__no-data">Нет значений</div>
</div>

<ng-template #base let-item>
  <label
    class="cbx-label"
    [for]="'multiple-livesearch-select' + item.id"
    [ngbTooltip]="item.label"
    [disableTooltip]="item.label?.toString()?.length < 24"
    appTestLocator="'multiple-livesearch-select__item-{{ item.id }}"
  >
    <div class="text-overflow">
      <span class="cbx-label-text" [innerHTML]="item.label | highlight : searchValue"></span>
    </div>
  </label>
</ng-template>
