import { Routes } from '@angular/router';

import { ProfileComponent } from './profile/profile.component';
import { ProfileContactComponent } from './profile-contact/profile-contact.component';
import { ProfileContractComponent } from './profile-contract/profile-contract.component';

export const profileRoutes: Routes = [
  { path: '', component: ProfileComponent },
  { path: 'contact', component: ProfileContactComponent },
  { path: 'contract', component: ProfileContractComponent },
];
