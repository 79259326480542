import {Directive, ElementRef, HostListener, Inject, Input, Optional, Renderer2} from '@angular/core';
import {COMPOSITION_BUFFER_MODE, DefaultValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Directive({
  selector: 'input[appInputTrim], textarea[appInputTrim]',
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: InputTrimDirective, multi: true}]

})
export class InputTrimDirective extends DefaultValueAccessor {
  private _type = 'text';
  private _sourceRenderer: Renderer2;
  private _sourceElementRef: ElementRef;

  @Input() appInputTrim: string;

  @Input()
  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value || 'text';
    this._sourceRenderer.setAttribute(this._sourceElementRef.nativeElement, 'type', this._type);
  }

  set value(val: any) {
    this.writeValue(val);
    this.onChange(val);
  }

  @HostListener('input', ['$event.type', '$event.target.value'])
  onInput(event: string, value: string): void {
    this.updateValue(event, value);
  }

  constructor(@Inject(Renderer2) renderer: Renderer2,
              @Inject(ElementRef) elementRef: ElementRef,
              @Optional() @Inject(COMPOSITION_BUFFER_MODE) compositionMode: boolean) {
    super(renderer, elementRef, compositionMode);

    this._sourceRenderer = renderer;
    this._sourceElementRef = elementRef;
  }

  public writeValue(value: any): void {
    this._sourceRenderer.setProperty(this._sourceElementRef.nativeElement, 'value', value);
    if (this._type !== 'text') {
      this._sourceRenderer.setAttribute(this._sourceElementRef.nativeElement, 'value', value);
    }
    this._sourceRenderer.setAttribute(this._sourceElementRef.nativeElement, 'type', this._type);
  }

  private updateValue(event: string, value: string): void {
    this.value = (this.appInputTrim !== '' && event !== this.appInputTrim) ? value : value.trim();
  }
}
