import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-potencial-suppliers',
  templateUrl: './potencial-suppliers.component.html',
  styleUrls: ['./potencial-suppliers.component.sass']
})

export class PotencialSuppliersComponent implements OnInit {
  @Input() suppliers: any[] = [];

  constructor() {
  }

  ngOnInit() {
  }

}
