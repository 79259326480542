import { Injectable, Inject } from '@angular/core';
import { AppComponent } from '@app/app.component';
import { ComponentPortal } from '@angular/cdk/portal';

@Injectable()
export class PortalService {
  constructor(@Inject(AppComponent) private root: AppComponent) {}

  attach(component): any {
    this.detach();

    const portal = new ComponentPortal(component);

    const ref = this.root.virtualPortalOutlet.attach(portal);

    return ref;
  }

  detach() {
    this.root.virtualPortalOutlet.detach();
  }
}
