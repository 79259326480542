import { PaymentRegistryColumn } from '../models/payment-registry-column.model';

/** настройка колонок кошелька */
export const paymentWalletColumns: PaymentRegistryColumn[] = [
  {
    id: 'status',
    label: 'Статус',
    width: '140px',
    filter: 'filter',
    filterKeys: ['status'],
  },
  {
    id: 'name',
    label: 'Период/имя пользователя',
    width: '250px',
    filter: 'filter',
    filterKeys: ['from_date', 'to_date', 'name'],
  },
  {
    id: 'user_id',
    label: 'ID пользователя',
    width: '75px',
    filter: 'search',
    filterKeys: ['user_id'],
  },
  {
    id: 'trade_ids',
    label: 'ID торгов',
    width: '75px',
    filter: 'search',
    filterKeys: ['trade_id'],
  },
  {
    id: 'balance_start',
    label: 'Баланс начала периода',
    width: '175px',
    filter: 'filter',
    filterKeys: ['from_balance_start', 'to_balance_start'],
  },
  {
    id: 'charge',
    label: 'Начисления',
    width: '110px',
    filter: 'filter',
    filterKeys: ['from_charge', 'to_charge'],
  },
  {
    id: 'payment',
    label: 'Оплаты',
    width: '136px',
    filter: 'filter',
    filterKeys: ['from_payment', 'to_payment'],
  },
  {
    id: 'balance_end',
    label: 'Баланс конца периода',
    width: '175px',
    filter: 'filter',
    filterKeys: ['from_balance_end', 'to_balance_end'],
  },
  {
    id: 'operation_id',
    label: 'ID операции',
    width: '110px',
    filter: 'filter',
    filterKeys: ['id'],
  },
];
