import { Injectable } from '@angular/core';
import { FlaskQueryFilter } from '../../shared/models/filters.model';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { SectionAccess, SectionAccessParams } from '../models/access.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '@app/shared/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class SectionAccessService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  userAccess$ = this.authService.userStream.pipe(
    switchMap((user) =>
      this.getSectionAccess([
        {
          name: 'user_id',
          op: 'eq',
          val: user.id,
        },
      ])
    ),
    shareReplay()
  );

  /**
   * Метод всегда возвращает права только для рутовых разделов
   * Актуально для эксперта, АТП всегда имеет все права, но для него метод возвращает пустой массив
   * @param filter
   * @returns
   */
  getSectionAccess(filter: FlaskQueryFilter[] = []): Observable<SectionAccess[]> {
    const params = new HttpParams({
      fromObject: {
        filter: JSON.stringify(filter),
      },
    });

    return this.http
      .get<SectionAccess[]>(`${environment.api_url}/competency-map/section-accesses`, {
        headers: {
          Accept: 'application/vnd.api+json',
        },
        params,
      })
      .pipe(
        catchError((e) => {
          return observableThrowError(e);
        })
      );
  }

  addSectionAccess(body: SectionAccessParams): Observable<SectionAccess[]> {
    return this.http.post<SectionAccess[]>(`${environment.api_url}/competency-map/section-accesses`, body).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  updateSectionAccess(body: SectionAccessParams): Observable<SectionAccess[]> {
    return this.http
      .patch<SectionAccess[]>(
        `${environment.api_url}/competency-map/section-accesses/${body.user_id}/${body.section_id}`,
        body
      )
      .pipe(
        catchError((e) => {
          return observableThrowError(e);
        })
      );
  }
}
