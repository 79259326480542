<app-status-bar></app-status-bar>

<div class="main-container inline" (window:resize)="onResize()">
  <div #leftMenu class="left-menu" [ngClass]="{ collapsed: isMenuCollapsed, 'has-scroll': isMenuCollapsedScroll }">
    <div class="btn-collapse inline middle">
      <div class="inline middle" (click)="onCollapseMenu()">
        <fa-icon *ngIf="!isMenuCollapsed" class="icon" [icon]="['fas', 'angle-double-left']"></fa-icon>
        <fa-icon *ngIf="isMenuCollapsed" class="icon" [icon]="['fas', 'angle-double-right']"></fa-icon>
        <div class="menu-label">Свернуть</div>
      </div>

      <div class="start-page">
        <button
          (click)="onOpenSelectStartPage($event)"
          title="Выбрать стартовую страницу"
          class="icon-button"
          [ngClass]="{ disabled: !user?.company?.id && user?.type !== ROLES.ADMIN_OF_DIRECTION }"
        >
          <fa-icon [icon]="['fas', 'cog']"></fa-icon>
        </button>
      </div>
    </div>
    <app-left-menu
      [menu_items]="MENU_ITEMS[user?.type ? user?.type : 'demo']"
      [menu_disabled]="demo || !user"
      [menuCollapsed]="isMenuCollapsed"
      (toggleItem)="onToggleItem()"
    >
    </app-left-menu>
  </div>

  <div class="main-content" [ngClass]="{ collapsed: isMenuCollapsed }" [class.off-scroll]="offScroll">
    <!--<div>-->
    <router-outlet></router-outlet>

    <!--</div>-->
  </div>
</div>
