import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-datepicker-filter',
  templateUrl: './datepicker-filter.component.html',
  styleUrls: ['./datepicker-filter.component.sass']
})
export class DatepickerFilterComponent implements OnInit, OnChanges {
  @Input() value: any;

  @Input() placeholder: string;

  dateOptions = {
    initialDate: null
  };

  @Output() submitEvent = new EventEmitter<{value: string}>();

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    const {value} = changes;

    if (value && value.currentValue) {
      this.dateOptions.initialDate = this.value;
    }
  }

  changeDate(event) {
    if (event.type === 'clear') {
      this.value = '';
      this.dateOptions.initialDate = this.value;
    }
  }

  apply() {
    this.submitEvent.emit({value: this.value});
  }
}
