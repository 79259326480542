<div class="wrapper form-group">
  <div style="position: relative;">
    <form [formGroup]="searchForm">
      <input
        class="form-control filter-input"
        (mousedown)="$event.stopPropagation()"
        formControlName="searchText"
        (keyup)="onKeyUp()"
        type="text"
      />
    </form>
    <div *ngIf="!isUsingSearch" class="filter-icon">
      <fa-icon [icon]="['fas', 'search']"></fa-icon>
    </div>
    <div *ngIf="isUsingSearch" (click)="onClearSearch()" class="filter-icon" style="cursor: pointer;">
      <fa-icon [icon]="['fas', 'times']"></fa-icon>
    </div>
  </div>
</div>
