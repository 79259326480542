import {Pipe, PipeTransform} from '@angular/core';
import get from 'lodash-es/get';

@Pipe({name: 'orderBy'})

export class OrderByPipe implements PipeTransform {
  transform(obj: any, orderFields: string): any {
    let orderType = 'ASC';
    if (!obj || !orderFields) return obj;

    if (orderFields[0] === '-') {
      orderFields = orderFields.substring(1);
      orderType = 'DESC';
    }

    obj.sort((a, b) => {
      const [s1, s2] = orderType === 'ASC' ? [-1, 1] : [1, -1];
      return get(a, orderFields) < get(b, orderFields) ? s1 : get(a, orderFields) > get(b, orderFields) ? s2 : 0;
    });
    return obj;
  }
}
