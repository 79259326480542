<div class="tree-item" [class.editing]="isEditing">
  <a
    class="tree-item-collapse"
    [class.collapsed]="isCollapsed"
    [class.disabled]="isDisabledCollapsed"
    appTestLocator="filter-template-item__arrow"
    (click)="changeCollapseItem()"
  >
    <app-svg-icon [name]="isCollapsed ? 'caret_down' : 'caret_up'" [width]="10" [height]="6"></app-svg-icon>
  </a>

  <div class="tree-item-group">
    <div
      class="tree-item-group-title"
      appTestLocator="filter-template-item__title"
      [ngbTooltip]="filterTemplateParams?.node_info?.title"
      [disableTooltip]="filterTemplateParams?.node_info?.title?.length < 24"
    >
      {{ filterTemplateParams?.node_info?.title }}
    </div>

    <div class="tree-item-group-actions" *ngIf="isItemDisplayActions">
      <a
        class="tree-item-group-action"
        appTestLocator="filter-template-item__action_add"
        (click)="sendAction(treeAction.ADD, filterTemplateParams)"
        [class.tree-item-group-action__no-active]="isChildMirrorHideAction"
        [class.disabled]="isDisableAdd"
      >
        <app-svg-icon name="plus" [width]="14" [height]="14"></app-svg-icon>
      </a>

      <a
        appTestLocator="filter-template-item__action_edit"
        class="tree-item-group-action"
        (click)="sendAction(treeAction.EDIT, filterTemplateParams)"
        [class.tree-item-group-action__no-active]="isChildMirrorHideAction"
        [class.disabled]="isDisableEdit"
      >
        <app-svg-icon name="edit" [width]="16" [height]="16"></app-svg-icon>
      </a>

      <a
        appTestLocator="filter-template-item__action_delete"
        class="tree-item-group-action"
        [class.tree-item-group-action__no-active]="hideDeleteButton || isChildMirrorHideAction"
        (click)="sendAction(treeAction.DELETE, filterTemplateParams)"
        [class.disabled]="isDisableDelete"
      >
        <app-svg-icon name="close_wide" [width]="14" [height]="14"></app-svg-icon>
      </a>
    </div>
  </div>
</div>

<div [class.d-none]="isCollapsed" class="tree-item-child">
  <ng-container [ngSwitch]="filterTemplateParams?.node_info?.type">
    <ng-container *ngSwitchCase="filterTemplateTypes.CHOICE">
      <ng-container *ngIf="filterTemplateParams?.childes?.length">
        <app-filter-template-tree-item
          *ngFor="let item of filterTemplateParams?.childes"
          [project]="project"
          [filterTemplateParams]="item"
          [parentFilterTemplateParams]="filterTemplateParams"
          [selectedTemplateFilter]="selectedTemplateFilter"
          [mirrorsArrayIds]="mirrorsArrayIds"
          [editChildrenItemsArrayIds]="editChildrenItemsArrayIds"
          [lastEditingFilter]="lastEditingFilter"
          [isRoot]="false"
          [tmpLevel]="tmpLevel + 1"
          [isCreateTemplateFilter]="isCreateTemplateFilter"
          [isCollapsed]="item.node_info.type !== filterTemplateTypes.CHOICE"
        >
        </app-filter-template-tree-item>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="filterTemplateTypes.RANGE">
      <app-form-range-slider
        [readonly]="true"
        [setValueFrom]="filterTemplateParams?.node_info?.default_range_from"
        [setValueTo]="filterTemplateParams?.node_info?.default_range_to"
      ></app-form-range-slider>
    </ng-container>
    <ng-container *ngSwitchCase="filterTemplateTypes.BOOL">
      <app-form-checkbox-switch
        [checked]="filterTemplateParams?.node_info?.default_bool"
        [readonly]="true"
      ></app-form-checkbox-switch>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="color-brand-middle" *ngIf="filterTemplateParams?.node_info?.value">
        {{ filterTemplateParams?.node_info | templateFilterValue }}
      </div>
    </ng-container>
  </ng-container>
</div>
