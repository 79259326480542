import { Component, Input, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { DATE_FORMAT } from '@app/shared/constants/date.constants';

@Component({
  selector: 'app-view-year',
  templateUrl: './view-year.component.html',
  styleUrls: ['./view-year.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ViewYearComponent {
  @Input() set currentYear(value: string) {
    this.inputCurrentYear = value;
    this.recalcMonth();
  }
  startCurrentMount: string = moment().locale('ru').startOf('month').format(DATE_FORMAT);
  inputCurrentYear;
  repeatMonth = [];

  constructor() {}

  recalcMonth() {
    this.repeatMonth = [];
    if (this.inputCurrentYear) {
      let startMount = moment(this.inputCurrentYear, 'YYYY').startOf('year');
      for (let i = 0; i < 3; i++) {
        const rowMonth = [];
        for (let i = 0; i < 4; i++) {
          rowMonth.push(startMount.format(DATE_FORMAT));
          startMount = startMount.add(1, 'month');
        }
        this.repeatMonth.push(rowMonth);
      }
    }
  }
}
