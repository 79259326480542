<div [ngClass]="{
'input-field-container': !isTextarea,
'textarea-field-container': isTextarea,
'field-limited': isShowLength && maxLength && !isShowClear,
'field-cleared': isShowClear
}">

  <ng-content></ng-content>

  <div *ngIf="isShowLength && maxLength && !isShowClear" class="field-length">
    {{ inputLength }}/{{ maxLength }}
  </div>

  <div *ngIf="isShowClear && !isTextarea" class="field-clear" [ngClass]="{ active: inputLength }">
    <app-svg-icon [width]="14" [height]="14" (click)="clearInput()" name="close-search"></app-svg-icon>
  </div>
</div>
