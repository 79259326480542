<ng-container *ngIf="!isRadioBtnCircle; else employeeTemplate">
  <div [formGroup]="form" class="form-container">
    <div class="list-container">
      <div class="list-row" *ngFor="let item of list" [ngClass]="item?.classes">
        <label class="cbx-radio">
          <fa-icon [icon]="item.icon"></fa-icon>
          <span class="cbx-radio-text">{{ item.name }}</span>
          <input class="cbx-input" type="radio" [value]="item.id" name="formRadio" formControlName="formRadio" />
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-center">
    <button class="btn btn-dc-primary" (click)="apply()">Фильтровать</button>
  </div>
</ng-container>

<ng-template #employeeTemplate>
  <div [formGroup]="form">
    <div *ngFor="let item of list" class="radio-list-wrapper">
      <label>
        <input type="radio" [value]="item.id" formControlName="formRadio" />
        <app-svg-icon class="icon" [class]="item.classes" [name]="item.iconSvg" width="16" height="16"></app-svg-icon>
      </label>
      <span class="status-name">{{ item.name }}</span>
    </div>
  </div>

  <div class="buttons-action-wrapper">
    <button type="button" class="btn btn-dc-outline btn-sm" (click)="cancel()">Отменить</button>
    <button class="btn btn-dc-primary btn-sm" (click)="apply()">Применить</button>
  </div>
</ng-template>
