import { Column } from '@app/shared/models/table';

import { ProjectColumnKeys } from './projects.constants';
import { FlaskQueryFilter } from '@app/shared/models/filters.model';

export const PROJECT_LOGS_COLUMNS: Column<FlaskQueryFilter[]>[] = [
  {
    id: ProjectColumnKeys.SEND_DATE,
    name: 'Дата',
    isEnabledSearch: true,
    width: 150,
    filterConfig: [
      {
        name: ProjectColumnKeys.SEND_DATE,
        op: 'between',
        val: null,
      },
    ],
  },
  {
    id: ProjectColumnKeys.ID,
    name: 'ID проекта',
    isEnabledSearch: true,
    searchPlaceholder: 'Поиск по id',
    width: 100,
    data: {
      isSticky: true,
      indent: 0,
    },
    filterConfig: [
      {
        name: ProjectColumnKeys.ID,
        op: 'in_',
        val: '',
      },
    ],
  },
  {
    id: ProjectColumnKeys.TITLE,
    name: 'Название проекта',
    isEnabledSearch: true,
    searchPlaceholder: 'Поиск по названию',
    width: 120,
    data: {
      isSticky: true,
      indent: 100,
    },
    filterConfig: [
      {
        name: ProjectColumnKeys.TITLE,
        op: 'in_',
        val: '',
      },
    ],
  },
  {
    id: ProjectColumnKeys.TYPE,
    name: 'Тип проекта',
    isEnabledFilter: true,
    searchPlaceholder: 'Поиск по типу',
    width: 250,
    filterConfig: [
      {
        name: ProjectColumnKeys.TYPE,
        op: 'in_',
        val: '',
      },
    ],
  },
].map((column) => {
  return {
    ...column,
    defaultFilterConfig: column.filterConfig,
  };
});
