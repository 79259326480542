import { Pipe, PipeTransform } from '@angular/core';
import { Initials } from '../../models/base-user.model';

@Pipe({
  name: 'initials',
})
export class InitialsPipe implements PipeTransform {
  transform(value: Initials): any {
    if (!value) {
      return '';
    }

    return `${value.second_name || ''} ${this.getFirstChar(value.first_name)}${this.getFirstChar(value.patronymic)}`;
  }

  getFirstChar(value) {
    return value ? `${value.charAt(0)}.` : '';
  }
}
