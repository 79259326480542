import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FlaskQueryFilter } from '../../../models/filters.model';
import { DoubleDatePickerParams } from '../../double-datepicker-filter/double-datepicker-filter.component';
import * as moment from 'moment';
import { BACKEND_DATE_FORMAT, DATE_FORMAT } from '../../../constants/date.constants';
import { Column } from '../../../models/table';
import { MultipleSearchSuggestion } from '@app/shared/models/multiple-search.model';
import { uniqBy } from 'lodash-es';

@Component({
  template: '',
})
export abstract class FlaskQueryHeaderCellComponent implements OnChanges {
  filterValue = null;
  _filterSuggestions: MultipleSearchSuggestion[];

  listLimit = 50;

  @Input() column: Column<FlaskQueryFilter[]>;

  @Input() set rows(rows: any[]) {
    this._filterSuggestions = uniqBy(rows, (row) => row[this.column.id])
      .filter((row) => (Array.isArray(row[this.column.id]) ? !!row[this.column.id].length : !!row[this.column.id]))
      .map((row) => {
        return {
          id: row[this.column.id],
          label: row[this.column.id].toString(),
          value: true,
        };
      });
  }

  @Output() changeFilterEvent = new EventEmitter<Column<FlaskQueryFilter[]>>();

  get filterSuggestions(): MultipleSearchSuggestion[] {
    return this._filterSuggestions;
  }

  ngOnChanges(): void {
    if (this.column) {
      if (!this.column.filterConfig) {
        return;
      }
      this.filterValue = this.getFilterValueParams(this.column);

      this.column.isFilterActive = this.isColumnActive(this.column);
    }
  }

  getFilterValueParams(column: Column<FlaskQueryFilter[]>) {}

  clickFilterIcon() {
    this.column.isFilterOpen = true;
  }

  saveBaseFilter(value: { [key: string]: any }) {
    this.closeFilter();

    this.column.filterConfig = this.column.defaultFilterConfig.map((item) => {
      return {
        ...item,
        val: value.value,
      };
    });

    const target = this.column.filterConfig.find((item) => item.name === this.column.id);

    this.column.isFilterActive = this.isColumnActive(this.column);

    this.filterValue = target ? target.val : '';

    this.changeFilterEvent.emit(this.column);
  }

  saveDateRangeFilter(value: DoubleDatePickerParams) {
    this.closeFilter();

    const newValue: DoubleDatePickerParams = {
      fromDate: value.fromDate ? moment(value.fromDate, DATE_FORMAT).format(BACKEND_DATE_FORMAT) : null,
      toDate: value.toDate ? moment(value.toDate, DATE_FORMAT).add(1, 'days').format(BACKEND_DATE_FORMAT) : null,
    };

    this.column.filterConfig = this.column.defaultFilterConfig.map((item) => {
      return {
        ...item,
        val: [newValue.fromDate, newValue.toDate],
      };
    });

    this.column.isFilterActive = this.isColumnActive(this.column);

    this.changeFilterEvent.emit(this.column);
  }

  isColumnActive(column: Column<FlaskQueryFilter[]>) {
    return !!column.filterConfig.filter((item) => !!item.val).length;
  }

  clickFilterClearIcon() {
    this.column.filterConfig = [];

    this.column.isFilterActive = false;
    this.filterValue = null;

    this.changeFilterEvent.emit(this.column);
  }

  closeFilter(): void {
    this.column.isFilterOpen = false;
  }

  saveNewBaseFilter(event) {
    this.saveBaseFilter({
      value: event.value.filter((item) => item.value).map((item) => item.id),
    });
  }

  saveNewBaseFilterlabel(event) {
    this.saveBaseFilter({
      value: event.value.filter((item) => item.value).map((item) => item.label),
    });
  }
}
