import { Component, HostBinding } from '@angular/core';

import { FileManagerAreaFileComponent } from '../file-manager-area-file/file-manager-area-file.component';

@Component({
  selector: 'app-file-manager-grid-item',
  templateUrl: './file-manager-grid-item.component.html',
  styleUrls: ['./file-manager-grid-item.component.scss'],
})
export class FileManagerGridItemComponent extends FileManagerAreaFileComponent {
  @HostBinding('class.current-area-file-selector') fileCurrentSelectedValue = true;
  @HostBinding('class.cut-file') fileCutValue = true;
}
