import { takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { ChatService } from '@app/chat/services/chat.service';
import { AuthService } from '@app/shared/services/auth.service';
import { RolesEnum } from '@app/shared/constants/roles.constants';
import { ChatSection } from '@app/chat/models/chat.model';
import { ChatSectionsEnum } from '@app/chat/constants/chat-sections.constants';
import { User } from '@app/shared/models/user.model';
import { UserTypes } from '@app/shared/types/user.types';

@Component({
  selector: 'app-chat-section-select',
  templateUrl: './chat-section-select.component.html',
  styleUrls: ['./chat-section-select.component.scss'],
})
export class ChatSectionSelectComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public chatSections: ChatSection[] = [];
  user: User;
  chatSectionsEnum = ChatSectionsEnum;

  constructor(private chatService: ChatService, private authService: AuthService) {}

  ngOnInit() {
    this.authService.userStream.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
      this.user = user;
      this.setSections(user);
    });

    this.chatService.chatSectionSelectedChanged
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((chatSection) =>
        this.chatSections.forEach((section) => (section.isSelected = section.name === chatSection.name))
      );
  }

  setSections(user: User) {
    this.chatSections = [];

    if (
      ([RolesEnum.ADMIN_OF_USER, RolesEnum.ADMIN_OF_DIRECTION, RolesEnum.OPERATOR] as UserTypes[]).includes(user.type)
    ) {
      this.chatSections.push(
        this.chatService.chatSections.corp,
        this.chatService.chatSections.trade,
        this.chatService.chatSections.tech
      );
    }

    if (
      ([RolesEnum.SUPERUSER, RolesEnum.ACCOUNTANT, RolesEnum.EXPERT, RolesEnum.PARTNER] as UserTypes[]).includes(
        user.type
      )
    ) {
      this.chatSections.push(this.chatService.chatSections.admin, this.chatService.chatSections.tech);
    }

    if (RolesEnum.PARTNER === user.type && user.flags.is_tso) {
      this.chatService.chatSections.tech.title = 'Раздел ОТП';
    }

    if (RolesEnum.PARTNER === user.type && user.flags.is_duty_tso) {
      this.chatSections.push(this.chatService.chatSections.otp);
    }

    this.onSectionSelect(this.chatSections[0]);
  }

  onSectionSelect(chatSection) {
    this.chatSections.forEach((section) => (section.isSelected = section.name === chatSection.name));
    this.chatService.setChatSectionSelected(chatSection);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
