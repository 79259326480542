import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalInjectorConstants } from '@app/modal-injector/constants/modal-injector.constants';
import { ListModalInjector } from '@app/modal-injector/models/modal-injector.model';

@Injectable()
export class ModalInjectorService {
  arrayModal: ListModalInjector[] = [];
  id = 1;

  private modalInjectSubject = new Subject<any>();
  public modalInject$ = this.modalInjectSubject.asObservable();

  addModal(name_temp: ModalInjectorConstants, param: any = {}) {
    this.arrayModal.push({
      id: this.id,
      componentClass: {
        id: this.id,
        ...param,
      },
      name_temp,
    });
    this.id++;
    this.modalInjectSubject.next(this.arrayModal);
  }

  findTopElem() {
    return !!this.arrayModal.find((item) => item.alwaysTop);
  }

  closeModal(id: number) {
    const blockElem = [...this.arrayModal].reverse().find((item) => item.blockCloseWindow),
      elemDelete = this.arrayModal.find((item) => item.id === id);
    if (!blockElem || blockElem.id <= id || elemDelete.closeAnyClick) {
      setTimeout(() => {
        const indexDelete = this.arrayModal.findIndex((item) => item.id === id);
        // tslint:disable-next-line:no-bitwise
        if (~indexDelete) {
          // tslint:disable-next-line:no-shadowed-variable
          const indexDelete = this.arrayModal.findIndex((item) => item.id === id);
          this.arrayModal.splice(indexDelete, 1);
          this.modalInjectSubject.next(this.arrayModal);
        }
      }, 0);
    }
  }
}
