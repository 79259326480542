import {Observable} from 'rxjs';

export class FilesHelper {
  static readFileAsDataURL(file: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        resolve(e.target.result);
      };
      reader.onerror = (err) => {
        reject(err);
      };
      reader.readAsDataURL(file);
    });
  }

  static createImageFromBlob(image: Blob): Observable<string> {
    const reader = new FileReader();
    return new Observable(observer => {
      reader.addEventListener('load', () => {
        observer.next(reader.result as any);
      }, false);

      if (image) {
        reader.readAsDataURL(image);
      }
    });
  }
}

