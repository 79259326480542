import { takeUntil } from 'rxjs/operators';
import { FaqTooltipService } from '../../services/faq-tooltip.service';
import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { offset } from '../../utils';
import { NavigationStart, Router } from '@angular/router';
import { FaqQuestionStatus, FaqTabs } from 'app/shared/enums/faq.common.enum';
import { FaqHint } from '../../models/faq.api.model';

const MARGIN = 20;

@Component({
  selector: 'app-faq-tooltip',
  templateUrl: './faq-tooltip.component.html',
  styleUrls: ['./faq-tooltip.component.scss'],
})
export class FaqTooltipComponent implements OnDestroy, OnInit {
  public FaqTabs = FaqTabs;
  FaqQuestionStatus = FaqQuestionStatus;
  hint: FaqHint;

  @Input() tooltipName: number;
  @Input() tooltipWhite = false;

  @Input() scrollElements: ElementRef[];

  @ViewChild('textContainer') textContainer;
  @ViewChild('icon') icon;
  @ViewChild('container', { static: true }) container;

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  isTooltipShown = false;
  isLoading = false;
  errorText = 'Произошла ошибка, возможно подсказка еще не задана или сервис временно недоступен.';

  constructor(private faqTooltipService: FaqTooltipService, private router: Router, private renderer: Renderer2) {
    this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value) => {
      if (value instanceof NavigationStart) {
        this.close();
      }
    });
  }

  ngOnInit() {
    if (!Array.isArray(this.scrollElements)) {
      return;
    }

    this.scrollElements
      .filter((el) => !!el)
      .forEach((el) => {
        this.renderer.listen(el, 'scroll', () => {
          if (this.isTooltipShown) {
            this.close();
          }
        });
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  open(event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    this.isLoading = true;
    this.faqTooltipService
      .getTooltipText(this.tooltipName)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (hint: FaqHint) => {
          this.isLoading = false;
          this.hint = hint;
          if (this.hint.status !== FaqQuestionStatus.Approved) {
            this.hint.value = this.errorText;
          }
          this.setPosition();
        },
        (e) => {
          this.isLoading = false;
          this.setPosition();
        }
      );
    this.isTooltipShown = true;
    setTimeout(() => {
      this.migrateToBody();
      this.setPosition();
    }, 0);
  }

  close() {
    this.isTooltipShown = false;
    this.container.nativeElement.appendChild(this.textContainer.nativeElement);
    this.textContainer.nativeElement.style.display = 'none';
  }

  migrateToBody() {
    document.body.appendChild(this.textContainer.nativeElement);
    this.textContainer.nativeElement.style.display = 'block';
  }

  setPosition() {
    const el = this.textContainer.nativeElement;

    el.style.top = `${offset(this.icon.nativeElement).top + this.icon.nativeElement.offsetHeight}px`;
    el.style.left = `${offset(this.icon.nativeElement).left + MARGIN / 2}px`;

    if (offset(el).left + el.offsetWidth > window.innerWidth) {
      el.style.left = `${window.innerWidth - el.offsetWidth - MARGIN * 2}px`;
    }
  }
}
