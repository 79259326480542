import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';

import { FormHelper } from '@app/shared/helpers/form.helper';
import { ProjectBase, ProjectBaseComponent } from '@app/+competence-map/models/project.class';
import { FilterTemplatesHelper, SelectOptions } from '@app/+competence-map/helpers/filter-templates.helper';

import { DestroyService } from '@app/services/destroy.service';
import { AuthService } from '@app/shared/services/auth.service';
import { PortalService } from '@app/shared/services/portal.service';
import { CompetenceService } from '@app/+competence-map/services/competence.service';
import { FilterTemplateDataService } from '@app/+competence-map/services/filter-template-data.service';

import { Project } from '@app/+competence-map/models/projects.models';
import {
  CreateOrChangeFilterTemplateParams,
  FilterTemplate,
  SelectedTemplateFilter,
  TreeAction,
  TreeDataAction,
} from '@app/+competence-map/models/filter-templates.model';
import { UnitsMeasure } from '@app/+competence-map/models/units.model';
import { FilterTemplateStatuses, FilterTemplateTypes } from '@app/+competence-map/constants/filter-templates.constants';
import { CMActions, CMProjectStatuses } from '@app/+competence-map/constants/projects.constants';

import { Observable, of } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap, takeUntil } from 'rxjs/operators';

import { cloneDeep } from 'lodash-es';
import { NotificationsService } from 'angular2-notifications';

export const separatorTitle = ',';

@Component({
  selector: 'app-create-filter-template',
  templateUrl: './create-filter-template.component.html',
  styleUrls: ['./create-filter-template.component.scss'],
  providers: [PortalService, DestroyService],
})
export class CreateFilterTemplateComponent extends ProjectBaseComponent implements OnInit, AfterViewInit, ProjectBase {
  @ViewChild('hiddenInput') hiddenInput: ElementRef;

  private isProjectDirty: boolean = false;
  private lastFilterAdded: { title: string; type: FilterTemplateTypes } | null = null;

  form: FormGroup;
  formFooter: FormGroup;

  treeFilterData: CreateOrChangeFilterTemplateParams = null;
  treeFilterDataWithIndex: CreateOrChangeFilterTemplateParams = null;
  treeFilterDataCopy: CreateOrChangeFilterTemplateParams = null;
  selectedFilter: CreateOrChangeFilterTemplateParams | null = null;

  selectedTemplateFilter: SelectedTemplateFilter | null = null;

  sliderOptions = { min: 0, max: 0 };
  selectedProjectSpecType: CMActions = CMActions.CREATE_TEMPLATE_STRUCTURE_FILTER;

  disableFormSlider: boolean = false;
  disableFooterSaveButton: boolean = false;
  disableFooterAgreeButton: boolean = true;
  lastEditingFilter: SelectedTemplateFilter;
  savedDeletedFilter: CreateOrChangeFilterTemplateParams;

  unitsOptions$: Observable<SelectOptions[]>;

  @Output() closeEvent = new EventEmitter();
  @Output() reloadEvent = new EventEmitter();
  @Input() filterTemplates: FilterTemplate[] = [];

  @Input() set selectedItem(value: CreateOrChangeFilterTemplateParams) {
    if (value) {
      this.filterTemplateDataService.treeActionSubject.next({ action: TreeAction.EDIT, data: value });
      this.selectedTemplateFilter = { action: TreeAction.EDIT, id: value.node_id, item: value };
      this.updateFormValue(value.node_info);
    }
  }

  @Input() set projectData(value: Project) {
    this.project = value;
    const projectSpec = this.project.spec;
    if (projectSpec[CMActions.CREATE_TEMPLATE_STRUCTURE_FILTER]) {
      this.treeFilterData = FilterTemplatesHelper.getTreeSectionFilter(
        this.project.spec[CMActions.CREATE_TEMPLATE_STRUCTURE_FILTER]
      );
      if (this.treeFilterData) {
        this.initLastEditingFilter();
        this.treeDataAction({ action: TreeAction.EDIT, data: this.treeFilterDataWithIndex });
        this.isProjectDirty = true;

        this.setDisableLeftForm(this.project);
        this.setValidPristineFormFooter();
      }
    }
    if (projectSpec[CMActions.CHANGE_TEMPLATE_STRUCTURE_FILTER]) {
      this.selectedProjectSpecType = CMActions.CHANGE_TEMPLATE_STRUCTURE_FILTER;
      this.treeFilterData = FilterTemplatesHelper.getTreeSectionFilter(
        this.project.spec[CMActions.CHANGE_TEMPLATE_STRUCTURE_FILTER]
      );
      this.initLastEditingFilter();
      this.treeDataAction({ action: TreeAction.EDIT, data: this.treeFilterDataWithIndex });
      this.isProjectDirty = true;

      this.setValidPristineFormFooter();
    }
    if (projectSpec[CMActions.COPY_TEMPLATE_STRUCTURE_FILTER]) {
      this.selectedProjectSpecType = CMActions.COPY_TEMPLATE_STRUCTURE_FILTER;
      this.treeFilterData = FilterTemplatesHelper.getTreeSectionFilter(
        this.project.spec[CMActions.COPY_TEMPLATE_STRUCTURE_FILTER]
      );

      this.form.controls['title'].setValidators([
        Validators.required,
        filterTitleValidator(),
        Validators.maxLength(29),
      ]);

      this.treeDataAction({ action: TreeAction.EDIT, data: this.treeFilterData });
      // Сценарий: копируем фильтр -> кнопка согласвоать должна быть активной, остальыне не активны до внесения изменений
      this.isProjectDirty = true;
      this.setValidPristineFormFooter();
    }
  }

  private initLastEditingFilter(): void {
    this.treeFilterDataWithIndex = {
      ...this.filterTemplateDataService.addIndexToFilterData(this.treeFilterData),
    };

    if (!this.isNew && this.lastFilterAdded) {
      const lastFilter = this.selectedFilter
        ? this.filterTemplateDataService.findLastFilterByFilterId(
            this.treeFilterDataWithIndex,
            this.selectedFilter.node_id
          )
        : this.filterTemplateDataService.findLastFilterByTitleAndType(
            this.treeFilterDataWithIndex,
            this.lastFilterAdded.title,
            this.lastFilterAdded.type
          );

      if (lastFilter && this.lastEditingFilter?.action !== TreeAction.DELETE) {
        this.lastEditingFilter = {
          id: lastFilter.node_id,
          item: lastFilter,
          action: TreeAction.EDIT,
        };
      }
    }
  }

  get disableItems(): boolean {
    if (
      this.project?.spec &&
      (this.project?.spec[CMActions.CHANGE_TEMPLATE_STRUCTURE_FILTER] ||
        this.project?.spec[CMActions.COPY_TEMPLATE_STRUCTURE_FILTER])
    ) {
      return false;
    }
    return this.isNew;
  }

  get title(): string {
    if (this.project?.spec) {
      if (this.project.spec[CMActions.CREATE_TEMPLATE_STRUCTURE_FILTER]) {
        return `Создать новый фильтр`;
      } else if (this.project.spec[CMActions.CHANGE_TEMPLATE_STRUCTURE_FILTER]) {
        return `Редактировать фильтр`;
      } else if (this.project.spec[CMActions.COPY_TEMPLATE_STRUCTURE_FILTER]) {
        return `Копировать фильтр`;
      }
    }
    return `Создать новый фильтр`;
  }

  get isCreateTemplateFilter(): boolean {
    return !!this.project.spec[CMActions.CREATE_TEMPLATE_STRUCTURE_FILTER];
  }

  get filterTemplateTypes(): typeof FilterTemplateTypes {
    return FilterTemplateTypes;
  }

  get filterTemplateActionType(): typeof TreeAction {
    return TreeAction;
  }

  get IsRangeInvalid(): boolean {
    return (
      this.form.get('min').errors?.invalidMinMaxRangeValue &&
      this.form.get('filterType')?.value === this.filterTemplateTypes.RANGE
    );
  }

  displayFn = (value): string => {
    if (typeof value !== 'string') {
      return value?.label || '';
    }
    return value;
  };

  constructor(
    private fb: FormBuilder,
    private destroyed: DestroyService,
    private notify: NotificationsService,
    private competenceService: CompetenceService,
    private filterTemplateDataService: FilterTemplateDataService,
    protected authService: AuthService,
    protected portalService: PortalService,
    private cdr: ChangeDetectorRef
  ) {
    super(portalService, authService);
    this.initializeForms();
  }

  ngOnInit(): void {
    this.initializeOptionsSelectors();

    this.filterTemplateDataService.treeAction$.pipe(takeUntil(this.destroyed)).subscribe((value) => {
      this.treeDataAction(value);
    });

    this.form.statusChanges.pipe(takeUntil(this.destroyed)).subscribe((status) => {
      if (this.selectedTemplateFilter?.action === TreeAction.ADD || !this.project) {
        // Сценарий: открыли окно создания
        // -> заполнили форму (все 3 кнопки активны)
        this.setValidDirtyFormFooter();
      }

      if (this.selectedTemplateFilter?.action === TreeAction.ADD && this.project) {
        // Сценарий: открыли проект/добавляем дочерний узел
        // -> форма не заполена (активны кнопки согласовать и отменить)
        this.setValidDirtyFormFooter(true);
        this.disableFooterSaveButton = true;

        this.clearNullableTreeItems(this.treeFilterData);

        const findItem: CreateOrChangeFilterTemplateParams = this.findTreeDataItem([this.treeFilterData]);
        if (findItem) {
          if (!findItem.childes) {
            findItem.childes = [];
          }

          findItem.childes.unshift({
            node_id: null,
            node_info: {
              title: '',
              type: FilterTemplateTypes.CHOICE,
            },
          });
          this.cdr.detectChanges();
        }
      }

      if (this.selectedTemplateFilter?.action === TreeAction.ADD && this.project) {
        // // Сценарий: открыли проект/добавляем дочерний узел
        // -> форма заполнена (все 3 кнопки активны)
        this.disableFooterSaveButton = false;
      }

      if (this.lastEditingFilter?.action === TreeAction.DELETE) {
        // Сценарий: открыли проект/удаляем зеркало
        // -> форма заполена (все 3 кнопки активны)
        this.setValidDirtyFormFooter();
      }
    });

    this.form.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroyed)).subscribe((value) => {
      if (this.selectedTemplateFilter && this.selectedTemplateFilter?.action === TreeAction.EDIT) {
        this.clearNullableTreeItems(this.treeFilterData);

        const { title, default_range_from, default_range_to, type, units_of_measure_id } =
          this.selectedTemplateFilter?.item?.node_info;

        const selectedTemplateFilterInForm =
          title !== value.title ||
          default_range_from !== (value.min === 0 ? null : value.min) ||
          default_range_to !== (value.max === 0 ? null : value.max) ||
          type !== value.filterType ||
          units_of_measure_id !== value.unit?.id;

        if (selectedTemplateFilterInForm) {
          // Активны кнопки: сохранить, отменить, сохранить
          this.setValidDirtyFormFooter();
          this.disableFooterSaveButton = false;
        } else if (this.isProjectDirty) {
          // Активны кнопки: сохранить, отменить
          this.setValidPristineFormFooter();
        } else {
          // Активны кнопки футера: Сохранить
          this.setValidPristineFormFooter(null);
        }
      }

      if (this.selectedFilter && this.selectedFilter?.node_info?.title !== value.title) {
        this.selectedFilter = null;
        this.form.get('filterType').setValue(null);
      }
    });
  }

  ngAfterViewInit(): void {
    this.hiddenInput.nativeElement.focus();
  }

  agree(status: CMProjectStatuses): void {
    const { title, type, units_of_measure, units_of_measure_id, default_range_to, default_range_from } =
      this.getFormValue();
    const { level } = this.form.value;

    if (this.selectedTemplateFilter?.action === TreeAction.ADD && !this.form.dirty) {
      // Так как кнопка Согласовать уже активна, то даем возможность согласовать то что уже есть если форма не заполнялась
      this.addOrUpdateProject(status);
      return;
    }

    this.form.markAllAsTouched();

    if (title?.length > 30 && (level === 1 || this.isNew)) {
      this.notify.error('Ошибка!', 'Длина поля не может превышать 30 символов при создании фильтра БШФ');
      return;
    }

    if (this.form.controls['title']?.errors?.invalidMultiLength && !this.isNew) {
      this.notify.error(
        'Ошибка!',
        'Длина одного или нескольких значений при множественном добавлении превышает 30 символов'
      );
      return;
    }

    if (this.form.invalid) {
      this.notify.error('Ошибка!', 'Пожалуйста, проверьте введенные значения');
      return;
    }

    if (!type && !this.form.controls['filterType'].disabled) {
      this.notify.error('Ошибка!', 'Пожалуйста, укажите тип фильтра/значения');
      return;
    }

    if (!title && !this.form.controls['title'].disabled) {
      this.notify.error('Ошибка!', 'Пожалуйста, укажите название фильтра/значения');
      return;
    }

    if (!units_of_measure && units_of_measure_id === undefined) {
      this.notify.error('Ошибка!', 'Введенная единица измерения отсутствует в БД ед. измерения');
      return;
    }

    this.form.markAsPristine();
    this.addOrUpdateProject(status);
  }

  archive(): void {
    this.warnChangeArchiveStatus(() => {
      this.updateProject(CMProjectStatuses.ARCHIVE);
    });
  }

  cancel(): void {
    if (!this.project || !this.project?.spec[CMActions.COPY_TEMPLATE_STRUCTURE_FILTER]) {
      // TODO: сброс состояния
      this.clearForm();
      this.selectedFilter = null;

      if (this.selectedTemplateFilter) {
        this.treeDataAction({ action: this.selectedTemplateFilter.action, data: this.selectedTemplateFilter.item });
      }

      if (this.selectedTemplateFilter?.action === TreeAction.ADD) {
        this.treeDataAction({ action: TreeAction.EDIT, data: this.selectedTemplateFilter.item });
      }

      if (this.lastEditingFilter?.action === TreeAction.DELETE) {
        this.treeDataAction({ action: TreeAction.ROLLBACK, data: this.savedDeletedFilter });
      }
    }

    this.clearNullableTreeItems(this.treeFilterData);

    if (this.isProjectDirty) {
      this.setValidPristineFormFooter(true);
    } else {
      this.setValidPristineFormFooter(null);
    }

    this.competenceService.updateProjectOnAgreement();
  }

  save(status: CMProjectStatuses): void {
    this.form.enable();
    const { title, type, units_of_measure, units_of_measure_id, default_range_from, default_range_to } =
      this.getFormValue();
    this.savedDeletedFilter = null;
    this.treeFilterDataCopy = null;
    const { level } = this.form.value;
    this.form.markAllAsTouched();

    if (type === FilterTemplateTypes.RANGE && +default_range_from > +default_range_to) {
      this.notify.error('Ошибка!', 'Значение от должно быть меньше значения до');
      return;
    }

    if (title?.length > 30 && (level === 1 || this.isNew)) {
      this.notify.error('Ошибка!', 'Длина поля не может превышать 30 символов при создании фильтра БШФ');
      return;
    }

    if (this.form.controls['title']?.errors?.invalidMultiLength && !this.isNew) {
      this.notify.error(
        'Ошибка!',
        'Длина одного или нескольких значений при множественном добавлении превышает 30 символов'
      );
      return;
    }

    if (this.form.invalid) {
      this.notify.error('Ошибка!', 'Пожалуйста, проверьте введенные значения');
      return;
    }

    if (!type) {
      this.notify.error('Ошибка!', 'Пожалуйста, укажите тип фильтра/значения');
      return;
    }

    if (!title) {
      this.notify.error('Ошибка!', 'Пожалуйста, укажите название фильтра/значения');
      return;
    }

    if (!units_of_measure && units_of_measure_id === undefined) {
      this.notify.error('Ошибка!', 'Введенная единица измерения отсутствует в БД ед. измерения');
      return;
    }

    this.form.markAsPristine();
    this.disableFooterAgreeButton = false;
    this.addOrUpdateProject(status);
  }

  addProject(status?: CMProjectStatuses): void {
    switch (this.selectedProjectSpecType) {
      case CMActions.CREATE_TEMPLATE_STRUCTURE_FILTER: {
        this.createTemplateStructure(status);
        break;
      }
      case CMActions.CHANGE_TEMPLATE_STRUCTURE_FILTER: {
        this.changeTemplateStructure(status);
        break;
      }
      case CMActions.COPY_TEMPLATE_STRUCTURE_FILTER: {
        this.copyTemplateStructure(status);
        break;
      }
    }
  }

  updateProject(status?: CMProjectStatuses): void {
    const cloneTreeFilterData = cloneDeep(this.treeFilterData);
    let specData = {};

    if (this.selectedTemplateFilter && status !== CMProjectStatuses.ROLLBACK) {
      this.modifyTreeFilterDataItemById(
        cloneTreeFilterData,
        this.selectedTemplateFilter.id,
        this.selectedTemplateFilter.action
      );
    }

    switch (this.selectedProjectSpecType) {
      case CMActions.CREATE_TEMPLATE_STRUCTURE_FILTER: {
        specData = {
          create_template_structure_filter: FilterTemplatesHelper.getTreeSectionFilter(cloneTreeFilterData, 'get'),
        };
        break;
      }
      case CMActions.CHANGE_TEMPLATE_STRUCTURE_FILTER: {
        specData = {
          change_template_structure_filter: FilterTemplatesHelper.getTreeSectionFilter(cloneTreeFilterData, 'get'),
        };
        break;
      }
      case CMActions.COPY_TEMPLATE_STRUCTURE_FILTER: {
        specData = {
          copy_template_structure_filter: FilterTemplatesHelper.getTreeSectionFilter(cloneTreeFilterData, 'get'),
        };
        break;
      }
    }

    const updateProject = {
      ...this.project,
      status: status !== CMProjectStatuses.ROLLBACK ? status : CMProjectStatuses.DELETED,
      spec: specData,
    } as Project;

    this.competenceService
      .updateProject(updateProject)
      .pipe(takeUntil(this.destroyed))
      .subscribe(
        (project) => {
          this.projectData = project;
          this.competenceService.projectSuccess(status);
          this.reloadEvent.emit();
          this.competenceService.updateProjectOnAgreement();

          // Помечаем проект как существующий - нужно для дельнейших проверок футера
          this.isProjectDirty = true;

          this.setLastUpdatedFilter();

          // проверяем надо ли задизаблить форму в случае если проект согласован или на согласовании
          if (status !== CMProjectStatuses.ROLLBACK) {
            this.setDisableLeftForm(project);
          }

          // // если проект был согласован, то ставим форму в pristine, чтобы не открывалось окно подтверждения
          // if (this.project.status === CMProjectStatuses.AGREED) {
          //   this.setValidPristineFormFooter();
          // }

          console.log('form status: ', this.form.dirty);
        },
        (httpError) => {
          this.competenceService.error(httpError);
        }
      );
  }

  treeDataAction(event: TreeDataAction): void {
    switch (event.action) {
      case TreeAction.ADD: {
        this.lastEditingFilter = null;
        this.selectedTemplateFilter = { action: TreeAction.ADD, id: event.data?.node_id, item: event.data };
        this.clearForm();
        break;
      }
      case TreeAction.EDIT: {
        this.selectedTemplateFilter = { action: TreeAction.EDIT, id: event.data?.node_id, item: event.data };
        this.updateFormValue(event.data?.node_info);

        if (this.isProjectDirty) {
          this.setValidPristineFormFooter(true);
        } else {
          this.setValidPristineFormFooter(null);
        }

        break;
      }
      case TreeAction.DELETE: {
        this.lastEditingFilter = null;
        this.savedDeletedFilter = event.data;
        this.treeFilterDataCopy = this.treeFilterDataWithIndex;

        const parentItem = this.filterTemplateDataService.findParentFilterByDeletedFilterIndex(
          this.treeFilterDataWithIndex,
          event.data.index
        );

        this.lastEditingFilter = {
          action: TreeAction.DELETE,
          id: parentItem.node_id,
          item: parentItem,
        };

        this.filterTemplateDataService.deleteTreeFilterDataItem(this.treeFilterData, event);

        if (!this.isNew) {
          this.updateProject(CMProjectStatuses.DELETED);
        }
        break;
      }
      case TreeAction.ROLLBACK: {
        this.selectedFilter = event.data;
        this.treeFilterData = this.treeFilterDataCopy;
        this.selectedTemplateFilter = { action: TreeAction.ROLLBACK, id: event.data?.node_id, item: event.data };
        this.lastEditingFilter = {
          action: TreeAction.DELETE,
          id: event.data.node_id,
          item: event.data,
        };
        this.updateFormValue(event.data?.node_info);
        this.updateProject(CMProjectStatuses.ROLLBACK);
        break;
      }
    }
  }

  selectTemplateFilter(templateFilter: FilterTemplate): void {
    const titleControl = this.form.get('title');
    const filterTypeControl = this.form.get('filterType');
    const unitControl = this.form.get('unit');
    const minControl = this.form.get('min');
    const maxControl = this.form.get('max');

    titleControl.setValue(templateFilter.title);
    titleControl.disable();

    filterTypeControl.setValue(templateFilter.type);
    filterTypeControl.disable();

    if (templateFilter.units_of_measure_relation) {
      const selected: SelectOptions = {
        id: templateFilter.units_of_measure_id,
        value: templateFilter.units_of_measure_relation,
        label:
          templateFilter.units_of_measure_relation.full_name + ', ' + templateFilter.units_of_measure_relation.name,
      };

      unitControl.setValue(selected);
    }

    minControl.setValue(templateFilter.default_range_from ?? 0);
    maxControl.setValue(templateFilter.default_range_to ?? 0);

    unitControl.disable();
    this.disableFormSlider = true;

    this.competenceService
      .getTemplateStructureFilter({ node_id: templateFilter.id })
      .pipe(takeUntil(this.destroyed))
      .subscribe((items) => {
        this.selectedFilter = items[0];
      });
  }

  private modifyTreeFilterDataItemById(
    tree: CreateOrChangeFilterTemplateParams,
    id?: string,
    action?: TreeAction
  ): void {
    const { title, type } = this.getFormValue();
    const isFormData = title && type;

    if (id && tree?.node_id === id) {
      if (action === TreeAction.EDIT && isFormData) {
        tree.node_info = { ...tree?.node_info, ...this.getFormValue() };
      } else {
        if (!tree?.childes) {
          tree.childes = [];
        }
        if (this.selectedFilter && title === this.selectedFilter.node_info.title) {
          tree.childes.push(this.selectedFilter);
        } else if (isFormData) {
          // TODO: Добавляем сразу несколько фильтров
          if (title.indexOf(separatorTitle) !== -1) {
            const filterNames = title.split(separatorTitle);
            for (let i = 0; i < filterNames.length; i++) {
              const filterItemTitle = filterNames[i].trim();
              if (filterItemTitle.length) {
                const nodeInfoItem = { ...this.getFormValue(), title: filterItemTitle };
                tree.childes.push({ node_id: null, node_info: nodeInfoItem, childes: [] });
              }
            }
          } else {
            tree.childes.push({ node_id: null, node_info: { ...this.getFormValue() }, childes: [] });
          }
        }
      }
    } else {
      if (tree?.childes?.length) {
        for (let i = 0; i < tree.childes.length; i++) {
          this.modifyTreeFilterDataItemById(tree.childes[i], id, action);
        }
      }
    }
  }

  private updateFormValue(filterTemplate: FilterTemplate): void {
    const { title, type, default_range_from, default_range_to, value, units_of_measure, units_of_measure_id, level } =
      filterTemplate;
    FormHelper.updateForm<unknown>(this.form, {
      title,
      level,
      filterType: type,
      unit: { id: units_of_measure_id, label: units_of_measure },
    });
    if (units_of_measure_id && units_of_measure) {
      this.competenceService
        .getUnits()
        .pipe(
          takeUntil(this.destroyed),
          map((units) => {
            const result = units.find((unit) => unit.id === +units_of_measure_id);
            return result || null;
          })
        )
        .subscribe((findUnit) => {
          FormHelper.updateForm<unknown>(this.form, {
            unit: { id: findUnit.id, label: `${findUnit.full_name}, ${findUnit.name}` },
          });
        });
    }

    switch (type) {
      case FilterTemplateTypes.RANGE: {
        FormHelper.updateForm<unknown>(this.form, {
          min: default_range_from,
          max: default_range_to,
          filterValue: null,
        });
        break;
      }
      case FilterTemplateTypes.BOOL: {
        FormHelper.updateForm<unknown>(this.form, {
          filterValue: value,
          min: this.sliderOptions.min,
          max: this.sliderOptions.max,
        });
        break;
      }
    }
  }

  private getFormValue(): FilterTemplate {
    const { title, filterType, unit, min, max } = this.form.value;
    const selectedFilterType = this.form.get('filterType').value;
    let filterTemplate = {
      value: '',
      default_range_from: null,
      default_range_to: null,
      default_bool: null,
      units_of_measure: unit ? unit.label : null,
      units_of_measure_id: unit ? unit.id : null,
    };
    switch (selectedFilterType) {
      case FilterTemplateTypes.BOOL: {
        filterTemplate = { ...filterTemplate, value: 'true', default_bool: true };
        break;
      }
      case FilterTemplateTypes.RANGE: {
        filterTemplate = { ...filterTemplate, value: '', default_range_from: min, default_range_to: max };
        break;
      }
    }
    const result = { title, type: filterType, ...filterTemplate };
    if (typeof unit === 'string') {
      return { ...result, units_of_measure: null };
    } else {
      return result;
    }
  }

  private copyTemplateStructure(status?: CMProjectStatuses): void {
    const { spec } = this.project;
    const { node_id } = spec[CMActions.COPY_TEMPLATE_STRUCTURE_FILTER];
    const data = {
      ...spec[CMActions.COPY_TEMPLATE_STRUCTURE_FILTER],
      donor_node_id: node_id,
    };

    if (data.childes.length) {
      const removeNodeId = (node: CreateOrChangeFilterTemplateParams) => {
        delete node?.node_id;
        if (node.childes.length) {
          for (let i = 0; i < node.childes.length; i++) {
            removeNodeId(node.childes[i]);
          }
        }
      };
      for (let i = 0; i < data.childes.length; i++) {
        removeNodeId(data.childes[i]);
      }
    }

    if (this.getFormValue().title) {
      data.node_info = this.getFormValue();
    }

    if (status === CMProjectStatuses.AGREED) {
      data.node_info.status = FilterTemplateStatuses.ACTIVE;
    }

    if (this.getFormValue().title.length === 30) {
      const maxFilterTitle = this.filterTemplates.find((value) => value.title === this.getFormValue().title);
      if (maxFilterTitle) {
        this.notify.error('Ошибка', 'Пожалуйста измените название БШФ');
        return;
      }
    }

    this.competenceService
      .copyTemplateStructure(data)
      .pipe(
        takeUntil(this.destroyed),
        switchMap((project) => (status ? this.competenceService.updateProject({ ...project, status }) : of(project)))
      )
      .subscribe(
        (project) => {
          this.projectData = project;
          this.competenceService.projectSuccess(status);
          this.reloadEvent.emit();
          this.competenceService.updateProjectOnAgreement();

          this.setDisableLeftForm(project);
        },
        (httpError) => {
          this.competenceService.error(httpError);
        }
      );
  }

  private changeTemplateStructure(status?: CMProjectStatuses): void {
    const cloneTreeFilterData = cloneDeep(this.treeFilterData);

    if (this.selectedTemplateFilter) {
      this.modifyTreeFilterDataItemById(
        cloneTreeFilterData,
        this.selectedTemplateFilter.id,
        this.selectedTemplateFilter.action
      );
    }

    this.competenceService
      .updateFilterTemplate(FilterTemplatesHelper.getTreeSectionFilter(cloneTreeFilterData, 'get'))
      .pipe(
        takeUntil(this.destroyed),
        switchMap((project) => (status ? this.competenceService.updateProject({ ...project, status }) : of(project)))
      )
      .subscribe(
        (project) => {
          this.projectData = project;
          this.competenceService.projectSuccess(status);
          this.reloadEvent.emit();
          this.competenceService.updateProjectOnAgreement();

          this.setDisableLeftForm(project);
          this.isProjectDirty = true;

          this.setLastUpdatedFilter();

          this.setValidPristineFormFooter(true);
        },
        (httpError) => {
          this.competenceService.error(httpError);
        }
      );
  }

  // здесь надо установить последний в редактирование либо рутовый если последний определить не удалось
  private setLastUpdatedFilter(): void {
    const { lastFilterAdded, treeFilterData } = this;

    const filterToEdit = lastFilterAdded
      ? this.findLastUpdatedFilter(
          [treeFilterData],
          lastFilterAdded.title,
          lastFilterAdded.type,
          this.lastEditingFilter?.item.node_id ?? ''
        )
      : treeFilterData;

    if (filterToEdit) {
      this.treeDataAction({ action: TreeAction.EDIT, data: filterToEdit });
    }
  }

  private createTemplateStructure(status?: CMProjectStatuses): void {
    this.competenceService
      .addFilterTemplate({ node_info: this.getFormValue() })
      .pipe(
        takeUntil(this.destroyed),
        switchMap((project) => (status ? this.competenceService.updateProject({ ...project, status }) : of(project)))
      )
      .subscribe(
        (project) => {
          this.projectData = project;
          this.treeDataAction({ action: TreeAction.EDIT, data: this.treeFilterData });
          this.competenceService.projectSuccess(status);
          this.reloadEvent.emit();
          this.competenceService.updateProjectOnAgreement();

          this.setDisableLeftForm(project);

          this.isProjectDirty = true;
          this.setValidPristineFormFooter(true);
        },
        (httpError) => {
          this.competenceService.error(httpError);
        }
      );
  }

  private initializeForms(): void {
    this.formFooter = this.fb.group({ status: this.fb.control(null, Validators.required) });
    this.form = this.fb.group({
      title: this.fb.control('', Validators.compose([Validators.required, filterTitleValidator()])),
      unit: this.fb.control(''),
      filterType: this.fb.control(''),
      filterValue: this.fb.control(''),
      min: this.fb.control(0),
      max: this.fb.control(0),
      level: this.fb.control(null),
    });
  }

  private initializeOptionsSelectors(): void {
    let unitsOptions: UnitsMeasure[] = [];
    this.unitsOptions$ = this.competenceService.getUnits().pipe(
      switchMap((values) => {
        unitsOptions = values;
        return this.form.controls.unit.valueChanges;
      }),
      startWith(''),
      map((value) =>
        FilterTemplatesHelper.selectorsFilterMultiField<UnitsMeasure>(
          value,
          unitsOptions,
          ['full_name', 'name'],
          'full_name'
        )
      )
    );
  }

  private clearForm(): void {
    this.form.reset();
    FormHelper.updateForm<unknown>(this.form, this.sliderOptions);
  }

  setRangeValues(range: { min: number; max: number }): void {
    const minControl = this.form.get('min');
    const maxControl = this.form.get('max');

    const decimalPlaces = (num: number) => (num.toString().split('.')[1] || []).length;
    const isValidDecimalPlaces = decimalPlaces(range.min) <= 4 && decimalPlaces(range.max) <= 4;

    minControl.setValue(range.min);
    maxControl.setValue(range.max);
    minControl.setErrors(null);

    this.disableFooterSaveButton = false;
    this.disableFooterAgreeButton = false;

    if (!isValidDecimalPlaces) {
      this.disableFooterSaveButton = true;
      this.disableFooterAgreeButton = true;
      return;
    }

    if (range.max < range.min && range.min) {
      minControl.setErrors({ invalidMinMaxRangeValue: true });
      this.disableFooterSaveButton = true;
      this.disableFooterAgreeButton = true;
    }
  }

  // Сделать форму валидной/не валидной и чистой
  private setValidPristineFormFooter(status: boolean | null = true): void {
    this.formFooter.get('status').setValue(status);
    this.formFooter.markAsPristine();
  }

  // Сделать форму валидной/не валидной и грязной
  private setValidDirtyFormFooter(status: boolean | null = true): void {
    this.formFooter.get('status').setValue(status);
    this.formFooter.markAsDirty();
  }

  // Сделать форму не активной при согласовании проекта или архив
  private setDisableLeftForm(project: Project): void {
    if (
      project.status === CMProjectStatuses.AGREED ||
      project.status === CMProjectStatuses.ON_AGREEMENT ||
      project.status === CMProjectStatuses.ARCHIVE
    ) {
      this.form.controls['title'].disable();
      this.form.controls['unit'].disable();
      this.form.controls['filterType'].disable();
      this.disableFormSlider = true;
    } else {
      this.form.controls['title'].enable();
      this.form.controls['unit'].enable();
      this.form.controls['filterType'].enable();
      this.disableFormSlider = false;
    }
  }

  private findTreeDataItem(list: CreateOrChangeFilterTemplateParams[]): CreateOrChangeFilterTemplateParams {
    for (let i = 0; i < list?.length; i++) {
      if (list[i].node_id === this.selectedTemplateFilter.id) {
        return list[i];
      } else {
        const filterTemplateParams = this.findTreeDataItem(list[i]?.childes);
        if (filterTemplateParams) {
          return filterTemplateParams;
        }
      }
    }
  }

  private findLastUpdatedFilter(
    list: CreateOrChangeFilterTemplateParams[],
    title: string,
    type: FilterTemplateTypes,
    nodeId?: string
  ): CreateOrChangeFilterTemplateParams {
    for (const item of list || []) {
      const isMatch = item.node_info.title === title && item.node_info.type === type;

      if (isMatch && (!nodeId || (nodeId && item.node_id === nodeId))) {
        return item;
      }

      const filterTemplateParams = this.findLastUpdatedFilter(item.childes, title, type, nodeId);
      if (filterTemplateParams) {
        return filterTemplateParams;
      }
    }

    return null;
  }

  private clearNullableTreeItems(item: CreateOrChangeFilterTemplateParams): void {
    if (!item?.childes) {
      return;
    }

    item.childes = [...item.childes.filter((filter) => filter.node_id)];

    if (!item.childes?.length) {
      delete item.childes;
    }

    for (let i = 0; i < item.childes?.length; i++) {
      this.clearNullableTreeItems(item.childes[i]);
    }
  }

  private addOrUpdateProject(status: CMProjectStatuses): void {
    this.clearNullableTreeItems(this.treeFilterData);

    const { title, type } = this.getFormValue();
    this.lastFilterAdded = { title, type };

    if (this.isNew) {
      this.addProject(status);
    } else {
      this.updateProject(status);
    }
  }
}

export function filterTitleValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    const value = control.value;

    if (!value) {
      return null;
    }

    if (value.length > 30 && value.indexOf(separatorTitle) === -1) {
      return { invalidOneLength: true };
    }

    if (value.indexOf(',') !== -1) {
      const filterNames = value.split(separatorTitle);
      for (let i = 0; i < filterNames.length; i++) {
        const filterItemTitle = filterNames[i].trim();
        if (filterItemTitle.length > 30) {
          return { invalidMultiLength: true };
        }
      }
    }

    return null;
  };
}
