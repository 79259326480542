<div class="modal-header">
  <div class="modal-title" appTestLocator="user-card__title">{{ title }}</div>
</div>
<div class="modal-body" *ngIf="user$ | async as userData">
  <div class="user-card-row padding-l-26" appTestLocator="user-card__fullname">
    {{ userData?.fullName }}
  </div>

  <div class="user-card-row" appTestLocator="user-card__company">
    <app-status-viewer class="padding-r-8" [status]="userData.status"></app-status-viewer>
    {{ userData.company | companyName }}
  </div>

  <div class="user-card-row padding-l-26" appTestLocator="user-card__type">
    {{ userData?.type | titleRole }}
  </div>
  <div *ngFor="let phone of userData?.phones" class="user-card-row" appTestLocator="user-card__phone">
    <app-svg-icon name="phone" width="16"></app-svg-icon>
    {{ phone | textSeparator: '-':3 }}
  </div>
  <div class="user-card-row" appTestLocator="user-card__email">
    <app-svg-icon name="email" width="16"></app-svg-icon>
    {{ userData?.email || '-' }}
  </div>
  <div class="user-card-row">
    <app-svg-icon name="comment" width="16"></app-svg-icon>
    <a [chatLink]="userData.id" appTestLocator="user-card__chat" [class.disabled]="userData.id === userId"
      >Написать в чат</a
    >
  </div>
</div>
