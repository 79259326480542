import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textTransfer'
})
export class TextTransferPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value.replace(/\ /g, '<br>');
  }

}
