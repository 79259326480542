import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from '@app/shared/models/user.model';
import { CompetenceService } from '@app/+competence-map/services/competence.service';
import { Observable, defer, of } from 'rxjs';
import { ProviderCompetency } from '@app/+competence-map/models/user-competency.model';
import { RolesEnum } from '@app/shared/constants/roles.constants';
import { tap } from 'rxjs/operators';

enum CompetencyType {
  PROVIDER,
  EXPERT,
}

@Component({
  selector: 'app-user-competence-map-modal',
  templateUrl: './user-competence-map-modal.component.html',
  styleUrls: ['./user-competence-map-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserCompetenceMapModalComponent {
  private _user: User;
  get user(): User {
    return this._user;
  }
  @Input() set user(value: User) {
    this._user = value;
    this.competencies$ = this.getUserCompetencies();
  }

  get competencyType(): CompetencyType {
    if (
      this.user.type === RolesEnum.ADMIN_OF_USER ||
      this.user.type === RolesEnum.ADMIN_OF_DIRECTION ||
      this.user.type === RolesEnum.OPERATOR
    ) {
      return CompetencyType.PROVIDER;
    } else if (this.user.type === RolesEnum.EXPERT || this.user.type === RolesEnum.PARTNER) {
      return CompetencyType.EXPERT;
    }
  }

  competencies$: Observable<ProviderCompetency[]>;

  constructor(private readonly activeModal: NgbActiveModal, private readonly competenceService: CompetenceService) {}

  private getUserCompetencies(): Observable<ProviderCompetency[]> {
    return defer(() => {
      if (this.competencyType === CompetencyType.PROVIDER) {
        return this.competenceService.getProviderCompetency([{ name: 'provider_id', op: 'eq', val: this.user.id }]);
      } else if (this.competencyType === CompetencyType.EXPERT) {
        return this.competenceService.getExpertCompetency([{ name: 'user_id', op: 'eq', val: this.user.id }]);
      } else {
        return of([]);
      }
    });
  }

  cancel(): void {
    this.activeModal.dismiss();
  }
}
