<div class="container">
  <div class="row">
    <div class="col-md-4"></div>
    <div class="col-md-4" style="min-width: 385px">
      <section class="auth-form">
        <form role="auth" novalidate>
          <app-faq-tooltip tooltipName="auth__auth_signin" class="hint-right"></app-faq-tooltip>
          <h3>Dircont</h3>
          <div class="form-group" [ngClass]="{ 'has-danger': errors.email }">
            <input
              type="email"
              name="email"
              [(ngModel)]="email"
              placeholder="Электронная почта"
              class="form-control input-lg"
              appInputTrim
            />
            <div class="form-control-feedback" *ngFor="let err of errors.email">{{ err | translate }}</div>
          </div>
          <div class="form-group" [ngClass]="{ 'has-danger': errors.password }">
            <div class="inline middle group-pass">
              <input
                type="{{ !isShowPass ? 'password' : 'text' }}"
                name="password"
                [(ngModel)]="password"
                class="form-control"
                placeholder="Пароль"
                appInputTrim
              />
              <button class="dc-icon-button show-pass" (click)="isShowPass = !isShowPass">
                <fa-icon [icon]="['fas', isShowPass ? 'eye' : 'eye-slash']"></fa-icon>
              </button>
            </div>
            <div class="form-control-feedback" *ngFor="let err of errors.password">{{ err | translate }}</div>
          </div>

          <div *ngIf="countTries >= totalTries" class="form-group" [ngClass]="{ 'has-danger': errors.captcha }">
            <re-captcha #captchaRef="reCaptcha" (resolved)="handleCorrectCaptcha($event)"></re-captcha>
            <div class="form-control-feedback" *ngFor="let err of errors.captcha">{{ err | translate }}</div>
          </div>

          <button type="submit" (click)="login()" class="btn btn-lg btn-dc-primary btn-block">Войти</button>
          <div>
            <p>
              <a [routerLink]="['/signup']">Зарегистрироваться</a>
            </p>
            <p>
              <a [routerLink]="['/reset_pass']">Восстановить пароль</a>
            </p>
          </div>
        </form>
      </section>
    </div>
    <div class="col-md-4"></div>
  </div>
</div>
