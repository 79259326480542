import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CalendarDateFormatter, CalendarMonthViewDay, CalendarView } from 'angular-calendar';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { CustomDateFormatter } from '@app/+employees/setup-duty-tso/custom-date-formatter.provider';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})
export class ScheduleComponent {
  @Input() viewDate: Date = moment().toDate();
  @Input() selectedMonthViewDay: CalendarMonthViewDay;

  view: CalendarView = CalendarView.Month;
  weekendDays = [6, 0];
  refresh: Subject<any> = new Subject();
  locale = 'ru-RU';

  @Output() onDayClicked = new EventEmitter();
  @Output() onBeforeMonthViewRender = new EventEmitter();

  dayClicked(day: CalendarMonthViewDay): void {
    this.onDayClicked.emit(day);
  }

  beforeMonthViewRender(event) {
    this.onBeforeMonthViewRender.emit(event);
  }

  getFormatedMonth(viewDate) {
    return moment(viewDate).format('MMMM');
  }
}
