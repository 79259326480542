<span class="blocked-status" *ngIf="status === 'blocked'">
  <fa-icon [icon]="['fas', 'circle']"></fa-icon>
</span>
<span class="active-status" *ngIf="status === 'active'">
  <fa-icon [icon]="['fas', 'circle']"></fa-icon>
</span>
<span class="archive-status" *ngIf="status === 'in_archive'">
  <fa-icon [icon]="['fas', 'circle']"></fa-icon>
</span>
<span class="view-status" *ngIf="status === 'browsing'">
  <fa-icon [icon]="['fas', 'circle']"></fa-icon>
</span>
