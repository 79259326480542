<div
  class="fileman-sub-icons"
  [ngClass]="{
    'justify-content-between': layout === fileManagerLayouts.PROFILE,
    'd-flex': layout === fileManagerLayouts.PROFILE
  }"
></div>
<div class="fileman-file-list margin-t-24" [ngSwitch]="view">
  <div class="fileman-ul-container" *ngSwitchCase="VIEW_TYPE.LIST">
    <div class="fileman-files-container" *ngIf="!isSearch">
      <ul class="list-unstyled fileman-ul-container-list">
        <li *ngFor="let file of current.children" (click)="select($event, file)" (dblclick)="open(file)">
          <app-file-item-list
            [file]="file"
            [selected]="selected"
            [layout]="layout"
            (onShare)="onShare.emit($event)"
            (onSelected)="onSelected.emit($event)"
          >
          </app-file-item-list>
        </li>
      </ul>
    </div>
    <div class="fileman-search-container" *ngIf="isSearch">
      <p class="fileman-search-query">Выведены результаты поиска по запросу "{{ searchQuery }}"</p>
      <ul class="list-unstyled fileman-ul-container-list">
        <h4 class="fileman-search-header">Мои файлы</h4>
        <li
          *ngFor="let file of searchMy"
          (click)="select($event, file)"
          (dblclick)="open(file)"
          [class.hidden]="isSearchResults(file)"
        >
          <app-file-item-list
            [file]="file"
            [selected]="selected"
            [layout]="layout"
            [searchQuery]="searchQuery"
            (onShare)="onShare.emit($event)"
            (onSelected)="onSelected.emit($event)"
          >
          </app-file-item-list>
        </li>
        <h4 class="fileman-search-header">Файлы с открытым доступом</h4>
        <li
          *ngFor="let file of searchShared"
          (click)="select($event, file)"
          (dblclick)="open(file)"
          [class.hidden]="isSearchResults(file)"
        >
          <app-file-item-list
            [file]="file"
            [selected]="selected"
            [layout]="layout"
            [searchQuery]="searchQuery"
            (onShare)="onShare.emit($event)"
            (onSelected)="onSelected.emit($event)"
          >
          </app-file-item-list>
        </li>
      </ul>
    </div>
  </div>
  <div class="fileman-file-list-container-wrapper" *ngSwitchCase="VIEW_TYPE.DETAIL">
    <div class="fileman-file-list-container" *ngIf="!isSearch">
      <div
        *ngFor="let file of current.children"
        (click)="select($event, file)"
        (dblclick)="open(file)"
        container="body"
        triggers="mouseenter:mouseleave"
        placement="top"
        [ngbTooltip]="tooltip(file)"
      >
        <app-file-item-detail
          [file]="file"
          [selected]="selected"
          (onShare)="onShare.emit($event)"
        ></app-file-item-detail>
      </div>
    </div>
    <div class="fileman-search-container" *ngIf="isSearch">
      <p class="fileman-search-query">Выведены результаты поиска по запросу "{{ searchQuery }}"</p>
      <h4 class="fileman-search-header">Мои файлы</h4>
      <div class="fileman-file-list-container">
        <div
          *ngFor="let file of searchMy"
          (click)="select($event, file)"
          (dblclick)="open(file)"
          [class.hidden]="isSearchResults(file)"
          container="body"
          triggers="mouseenter:mouseleave"
          placement="top"
          [ngbTooltip]="tooltip(file)"
        >
          <app-file-item-detail
            [file]="file"
            [selected]="selected"
            [current]="current"
            [searchQuery]="searchQuery"
            (onShare)="onShare.emit($event)"
          ></app-file-item-detail>
        </div>
      </div>
      <h4 class="fileman-search-header">Файлы с открытым доступом</h4>
      <div class="fileman-file-list-container">
        <div
          *ngFor="let file of searchShared"
          (click)="select($event, file)"
          (dblclick)="open(file)"
          [class.hidden]="isSearchResults(file)"
          container="body"
          triggers="mouseenter:mouseleave"
          placement="top"
          [ngbTooltip]="tooltip(file)"
        >
          <app-file-item-detail
            [file]="file"
            [selected]="selected"
            [current]="current"
            [searchQuery]="searchQuery"
            (onShare)="onShare.emit($event)"
          ></app-file-item-detail>
        </div>
      </div>
    </div>
  </div>
</div>

<app-modal-window [customClass]="'fileman-file-open'" [isShowed]="modal.isOpen" (closeEvent)="modal.close()">
  <div class="fileman-file-open" *ngIf="modal.file">
    <div class="file-name">
      <p>
        <strong>{{ 'fm_view' | translate }}</strong>
      </p>
      <p>"{{ modal.file.name }}"</p>
    </div>
    <div *ngIf="modal.file" class="text-center">
      <img *ngIf="modal.file.extType().hasImage" src="{{ modal.file.url }}" alt="{{ modal.file.name }}" />
      <span>
        <fa-icon
          [icon]="['fas', modal.file.extType().icon]"
          *ngIf="!modal.file.extType().hasImage"
          [size]="'5x'"
        ></fa-icon>
      </span>
    </div>
    <div class="fmfo-buttons text-center">
      <div>
        <button class="btn btn-dc-outline" (click)="modal.close()">{{ 'fm_close' | translate }}</button>
      </div>
      <div>
        <button class="btn btn-dc-primary" (click)="modal.action.download(modal.file)">
          {{ 'fm_download' | translate }}
        </button>
      </div>
      <div>
        <button class="btn btn-dc-primary" (click)="modal.action.print(modal.file)">
          {{ 'fm_print' | translate }}
        </button>
      </div>
    </div>
  </div>
</app-modal-window>
