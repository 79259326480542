<div>
  <div class="modal-header">
    <div class="modal-title">{{ title }}</div>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <embed [src]="link" [type]="'application/pdf'" />
  </div>
</div>
