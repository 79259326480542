<div class="select-all margin-t-20">
  <input
    [checked]="selectedAllRoles"
    (change)="selectAllRoles($event)"
    class="cbx-input"
    [id]="'selected_all_roles'"
    type="checkbox"
  />
  <label class="cbx-label" [for]="'selected_all_roles'">
    <span class="margin-l-10">Выделить всё/снять выделение</span>
  </label>
</div>
<ul class="list-unstyled margin-t-20">
  <li *ngFor="let role of items" class="shared-users-list-item-checkbox">
    <a>
      <input [id]="role.value" class="cbx-input" type="checkbox" [(ngModel)]="role.selected" (change)="selectRole()" />
      <label class="cbx-label" [for]="role.value">
        <span class="cbx-label-text">{{ role.fullTitle }}</span>
      </label>
    </a>
  </li>
</ul>
