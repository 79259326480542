import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { forkJoin, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ITreeOptions, TREE_ACTIONS, TreeComponent, TreeModel, TreeNode } from '@circlon/angular-tree-component';

import { DestroyService } from '@app/services/destroy.service';
import { FileManagerService } from '@app/file-manager/services/file-manager.service';

import { TreeHelper } from '@app/shared/helpers/tree.helper';
import { RolesEnum } from '@app/shared/constants/roles.constants';
import { OPEN_DELAY_TOOLTIP } from '@app/file-manager/constants/file-manager-base.constants';
import { FileManagerShareUserEnum } from '@app/file-manager/constants/file-manager-share.constants';

import { UserFile } from '@app/file-manager/models/user-file.model';
import { ShareUserQueryInterface } from '@app/file-manager/models/share-user-query.interface';
import { ShareUserFilterInterface } from '@app/file-manager/models/share-user-filter.interface';
import { AuthorFile } from '@app/file-manager/models/author-file.model';

import { FileManagerModalUserInfoComponent } from '@app/file-manager/components/file-manager-modal-user-info/file-manager-modal-user-info.component';
import { UserCompetenceMapModalComponent } from '@app/+competence-map/competence-shared/user-competence-map/user-competence-map-modal/user-competence-map-modal.component';

enum FilterTypeEnum {
  ALL = 'all',
  SHARED = 'shared',
  UNSHARED = 'unshared',
}

const ENABLED_FILES_COUNT = 4;

@Component({
  selector: 'app-file-manager-modal-share-files',
  templateUrl: './file-manager-modal-share-files.component.html',
  styleUrls: ['./file-manager-modal-share-files.component.scss'],
  providers: [DestroyService],
})
export class FileManagerModalShareFilesComponent implements OnInit {
  @Input() title: string = 'Управление правами доступа';
  @Input() files: UserFile[] = [];
  @Input() readonly: boolean = false;
  @Input() authUserRole: RolesEnum;
  @ViewChild('disallowTree') private treeShareDisallow: TreeComponent;

  private filterOpened: { shared: boolean; unshared: boolean } = { shared: false, unshared: false };
  private filterConfig: {
    shared: ShareUserFilterInterface;
    unshared: ShareUserFilterInterface;
  } = {
    shared: {
      roles: [],
      goods: [],
      services: [],
    },
    unshared: {
      roles: [],
      goods: [],
      services: [],
    },
  };

  selected: [boolean, boolean];
  loading: [boolean, boolean];
  detached: [number[], number[]];
  querySearches: [string, string] = ['', ''];

  users: {
    disallow: AuthorFile[];
    allow: AuthorFile[];
  } = { allow: [], disallow: [] };

  // Так как здесь фильтрация фронтовая только то нужна всмопогательная переменная
  private allowFilteredUser: AuthorFile[] = [];

  treeOptions: ITreeOptions = {
    displayField: 'name',
    useVirtualScroll: false,
    isExpandedField: 'expanded',
    idField: 'id',
    nodeHeight: 24,
    dropSlotHeight: 6,
    useTriState: false,
  };

  get openDelayTooltip(): number {
    return OPEN_DELAY_TOOLTIP;
  }

  get enabledFilesCount(): number {
    return ENABLED_FILES_COUNT;
  }

  get hiddenFiles(): string {
    let result = '';
    for (let i = 5; i < this.files.length; i++) {
      result += this.files[i].name;
      if (i !== this.files.length) {
        result += ', ';
      }
    }
    return result;
  }

  get filterType(): typeof FilterTypeEnum {
    return FilterTypeEnum;
  }

  get isSharedFilterOpen(): boolean {
    return this.filterOpened.shared;
  }

  get isUnSharedFilterOpen(): boolean {
    return this.filterOpened.unshared;
  }

  get filterConfigByOpen(): ShareUserFilterInterface {
    if (this.filterOpened.shared) {
      return this.filterConfig.shared;
    }
    if (this.filterOpened.unshared) {
      return this.filterConfig.unshared;
    }
  }

  get sharedFilterConfig(): boolean {
    const keyList = Object.keys(this.filterConfig.shared);
    for (let i = 0; i < keyList.length; i++) {
      const current = keyList[i];
      if (this.filterConfig.shared[current].length > 0) {
        return true;
      }
    }
    return false;
  }

  get unSharedFilterConfig(): boolean {
    const keyList = Object.keys(this.filterConfig.unshared);
    for (let i = 0; i < keyList.length; i++) {
      const current = keyList[i];
      if (this.filterConfig.unshared[current].length > 0) {
        return true;
      }
    }
    return false;
  }

  get fileManagerShareUserType(): typeof FileManagerShareUserEnum {
    return FileManagerShareUserEnum;
  }

  get isSelectAllDisabled(): boolean {
    return this.authUserRole !== RolesEnum.SUPERUSER;
  }

  get disallowUsers(): AuthorFile[] {
    return this.users.disallow.sort(this.sortAuthorFile.bind(this));
  }

  get allowUsers(): AuthorFile[] {
    let resultList = [];
    if (this.sharedFilterConfig || this.querySearches[1]?.length) {
      resultList = this.allowFilteredUser;
    } else {
      resultList = this.users.allow;
    }

    return resultList.sort(this.sortAuthorFile.bind(this));
  }

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly destroy$: DestroyService,
    private readonly modalService: NgbModal,
    private readonly fileManagerService: FileManagerService
  ) {}

  ngOnInit(): void {
    this.selected = [false, false];
    this.loading = [false, false];
    this.detached = [[], []];

    this.initialAllowUUsers();
    this.initialDisallowUsers();
  }

  save(): void {
    this.users.disallow = TreeHelper.treeToArray(this.users.disallow).filter(
      (user: AuthorFile) =>
        !this.detached[this.getFileManagerShareType(FileManagerShareUserEnum.DISALLOW)].includes(user.id)
    );
    this.activeModal.close(this.users);
  }

  cancel(): void {
    this.activeModal.close();
  }

  isDetached(type: FileManagerShareUserEnum, item: number): boolean {
    return this.detached[this.getFileManagerShareType(type)]
      ? this.detached[this.getFileManagerShareType(type)]?.includes(item)
      : false;
  }

  addSelectedUsersFromTo(from: FileManagerShareUserEnum, to: FileManagerShareUserEnum) {
    const selected = this.getSelectedUsers(from);
    if (selected.length) {
      if (from === FileManagerShareUserEnum.ALLOW) {
        selected.forEach((user: AuthorFile) => {
          this.users[from].splice(this.users[from].indexOf(user), 1);
        });
        this.removeDetached(FileManagerShareUserEnum.DISALLOW, selected);
      } else {
        this.addDetached(FileManagerShareUserEnum.DISALLOW, selected);
      }
      this.addUsersShare(to, selected);
      this.toggleSelectedUsers(this.users[from], false);
      this.toggleSelectedUsers(this.users[to], false);
      this.toggleSelectedAll(false);
      this.treeShareDisallow.treeModel.update();
    }
  }

  getSelectedUsers(type: FileManagerShareUserEnum): AuthorFile[] {
    return this.getUsersArray(type).filter((user: AuthorFile) => user.selected);
  }

  expandItem(tree: TreeModel, node: TreeNode, $event: any): void {
    TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
  }

  openUserInfo(user: AuthorFile): void {
    const modal = this.modalService.open(FileManagerModalUserInfoComponent, {
      centered: true,
      windowClass: 'dc-modal modal-window',
      animation: true,
    });
    modal.componentInstance.user = user;
    modal.componentInstance.title = 'Контакты';
  }

  openUserCompetence(user: AuthorFile): void {
    const modal = this.modalService.open(UserCompetenceMapModalComponent, {
      centered: true,
      animation: true,
      windowClass: 'dc-modal modal-window',
      size: 'lg',
    });
    modal.componentInstance.user = user;
  }

  addUsersShare(type: FileManagerShareUserEnum, users: AuthorFile[]) {
    users.forEach((user: AuthorFile) => this.addUserShare(type, user));
    if (type === FileManagerShareUserEnum.ALLOW) {
      this.searchFilter(this.querySearches[1], FilterTypeEnum.SHARED);
    }
  }

  addUserShare(type: FileManagerShareUserEnum, user: AuthorFile) {
    const exist = this.getUsersArray(type).some((u: AuthorFile) => u.id === user.id);
    if (!exist) {
      this.users[type].push(user);
    }
  }

  selectAllUsers(shareUserType: FileManagerShareUserEnum) {
    this.selected[shareUserType] = !this.selected[shareUserType];
    this.toggleSelectedUsers(this.users[shareUserType], this.selected[shareUserType]);
  }

  toggleFilter(type: FilterTypeEnum, open: boolean = true): void {
    if (type === FilterTypeEnum.ALL) {
      this.filterOpened[FilterTypeEnum.SHARED] = open;
      this.filterOpened[FilterTypeEnum.UNSHARED] = open;
    } else {
      this.filterOpened[type] = open;
    }
  }

  changeFilter(filterData?: ShareUserFilterInterface): void {
    if (this.filterOpened.shared) {
      if (filterData) {
        this.filterConfig.shared = filterData;
        this.searchFilter(this.querySearches[1], FilterTypeEnum.SHARED);
      }
    }

    if (this.filterOpened.unshared) {
      if (filterData) {
        this.filterConfig.unshared = filterData;
      }

      const { roles, goods, services } = this.filterConfig.unshared;
      this.initialDisallowUsers({
        query: this.querySearches[0],
        userRoles: roles,
        sectionIds: [...goods.map(String), ...services.map(String)],
      });
    }
    this.filterOpened.shared = false;
    this.filterOpened.unshared = false;
  }

  searchFilter(query: string, type?: FilterTypeEnum): void {
    switch (type) {
      case FilterTypeEnum.SHARED: {
        const { roles, goods, services } = this.filterConfig.shared;
        const competenceIds = [...goods, ...services];
        this.querySearches[1] = query || '';

        const allowUser = [...this.users.allow];

        const allowFilterUsers = this.querySearches[1]
          ? allowUser.filter((user) => {
              return (
                user.second_name?.toLowerCase().includes(query?.toLowerCase()) ||
                user.first_name?.toLowerCase().includes(query?.toLowerCase()) ||
                user.patronymic?.toLowerCase().includes(query?.toLowerCase())
              );
            })
          : allowUser;

        this.allowFilteredUser = allowFilterUsers.filter((user) => {
          if (roles.length && competenceIds.length) {
            return (
              roles.includes(user.type as RolesEnum) &&
              competenceIds.some((value) => user.competence_sections.map((value1) => value1.id).includes(value))
            );
          } else if (roles.length && !competenceIds.length) {
            return roles.includes(user.type as RolesEnum);
          } else if (!roles.length && competenceIds.length) {
            return competenceIds.some((value) => user.competence_sections.map((value1) => value1.id).includes(value));
          } else {
            return user;
          }
        });
        break;
      }
      case FilterTypeEnum.UNSHARED: {
        this.querySearches[0] = query;
        const { roles, goods, services } = this.filterConfig.unshared;
        this.initialDisallowUsers({
          query: this.querySearches[0],
          userRoles: roles,
          sectionIds: [...goods.map(String), ...services.map(String)],
        });
        break;
      }
    }
  }

  resetFilter(type: FilterTypeEnum): void {
    // TODO: reset filter
    this.filterConfig[type] = { roles: [], services: [], goods: [] };
    const { roles, goods, services } = this.filterConfig[type];
    this.initialDisallowUsers({
      query: this.querySearches[0],
      userRoles: roles,
      sectionIds: [...goods.map(String), ...services.map(String)],
    });
  }

  private toggleSelectedAll(value?: boolean): void {
    this.selected[FileManagerShareUserEnum.ALLOW] =
      value !== null ? value : !this.selected[FileManagerShareUserEnum.ALLOW];
    this.selected[FileManagerShareUserEnum.DISALLOW] =
      value !== null ? value : !this.selected[FileManagerShareUserEnum.DISALLOW];
  }

  private getUsersArray(type: FileManagerShareUserEnum): AuthorFile[] {
    if (type === FileManagerShareUserEnum.DISALLOW) {
      return TreeHelper.treeToArray(this.users[type]);
    } else {
      return this.users[type];
    }
  }

  /**
   * Получаем правильную структуру пользователей которым расшарен файл(ы)
   * @private
   */
  private initialAllowUUsers(query?: ShareUserQueryInterface): void {
    if (this.files.length === 1 && this.files[0].is_shared) {
      this.fileManagerService
        .getShareUsers(this.files[0].id, this.fileManagerShareUserType.ALLOW, query)
        .pipe(takeUntil(this.destroy$))
        .subscribe((allowShareUsers) => {
          const allowUsers = JSON.parse(JSON.stringify(allowShareUsers.items)) || [];
          this.users.allow = allowUsers.map(
            (item) =>
              new AuthorFile({
                ...item,
                parent: { id: item.parent_id },
              })
          );
          this.addDetached(FileManagerShareUserEnum.DISALLOW, this.users.allow);
        });
    } else {
      this.users.allow = [];
    }
  }

  /**
   * Получаем правильную структуру всех доступных пользователей которым можно расшарить файл(ы)
   * @private
   */
  private initialDisallowUsers(query?: ShareUserQueryInterface): void {
    const disallowUserList$: Observable<any>[] = [];
    const allowUserList$: Observable<any>[] = [];

    if (this.files.length === 1) {
      disallowUserList$.push(
        this.fileManagerService.getShareUsers(this.files[0].id, this.fileManagerShareUserType.DISALLOW, query)
      );
      allowUserList$.push(
        this.fileManagerService.getShareUsers(this.files[0].id, this.fileManagerShareUserType.ALLOW, query)
      );
    } else if (this.files.some((value) => !value?.is_shared)) {
      const unSharedFileIdx = this.files.findIndex((value) => !value.is_shared);
      disallowUserList$.push(
        this.fileManagerService.getShareUsers(
          this.files[unSharedFileIdx].id,
          this.fileManagerShareUserType.DISALLOW,
          query
        )
      );
    } else {
      this.files.forEach((file) => {
        disallowUserList$.push(
          this.fileManagerService.getShareUsers(file.id, this.fileManagerShareUserType.DISALLOW, query)
        );
        allowUserList$.push(this.fileManagerService.getShareUsers(file.id, this.fileManagerShareUserType.ALLOW, query));
      });
    }
    forkJoin([...disallowUserList$, ...allowUserList$])
      .pipe(takeUntil(this.destroy$))
      .subscribe((disallowUserList) => {
        let allDisallowUser = [];
        disallowUserList.forEach((disallowUserItem) => {
          allDisallowUser = [...allDisallowUser, ...disallowUserItem.items];
        });

        const uniqueDisallowUser = [...new Set(allDisallowUser)];
        this.users.disallow = TreeHelper.arrayToTree(
          uniqueDisallowUser.map(
            (item) =>
              new AuthorFile({
                ...item,
                parent: { id: item.parent_id },
              })
          )
        );
      });
  }

  private toggleSelectedUsers(users: AuthorFile[], selected: boolean): void {
    if (users.length > 0) {
      users.map((user: AuthorFile) => {
        user.selected = selected;
        if (user.children?.length > 0) {
          this.toggleSelectedUsers(user.children, selected);
        }
      });
    }
  }

  private addDetached(type: FileManagerShareUserEnum, users: AuthorFile[]): void {
    users.forEach((user: AuthorFile) => {
      if (!this.detached[this.getFileManagerShareType(type)].includes(user.id)) {
        this.detached[this.getFileManagerShareType(type)].push(user.id);
      }
    });
  }

  private removeDetached(type: FileManagerShareUserEnum, users: AuthorFile[]): void {
    users.forEach((user: AuthorFile) => {
      this.detached[this.getFileManagerShareType(type)].splice(
        this.detached[this.getFileManagerShareType(type)].indexOf(user.id),
        1
      );
    });
  }

  private getFileManagerShareType(type: FileManagerShareUserEnum): number {
    return type === FileManagerShareUserEnum.DISALLOW ? 0 : 1;
  }

  private sortAuthorFile(a: AuthorFile, b: AuthorFile): number {
    if (a.sortValue < b.sortValue) {
      return -1;
    }
    if (a.sortValue > b.sortValue) {
      return 1;
    }
    return 0;
  }
}
