import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-modal',
  templateUrl: './help-modal.component.html',
  styleUrls: ['./help-modal.component.scss'],
})
export class HelpModalComponent implements OnInit {
  @Input() disabled = false;
  isOpened = false;

  constructor() {}

  ngOnInit() {}
}
