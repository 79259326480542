import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { SHARE_TYPE, SHARE_MAX_FILES_COUNT_TITLE } from '@app/file_manager/file_manager.constants';
import { AuthorFile } from '@app/file_manager/models/author-file.model';
import { UserFile } from '@app/file_manager/models/user-file.model';
import { FileManagerService } from '../../services/file_manager.service';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TreeHelper } from '@app/shared/helpers/tree.helper';
import { FilesTooltipComponent } from '@app/shared/components/files-tooltip/files-tooltip.component';
import { PopoverService } from '@app/shared/services/popover.service';
import { FormBuilder } from '@angular/forms';
import { ROLES, RolesEnum } from '@app/shared/constants/roles.constants';
import { ITreeOptions, TREE_ACTIONS, TreeComponent, TreeModel, TreeNode } from '@circlon/angular-tree-component';
import { Role } from '@app/shared/models/user.model';
import { delay, takeUntil } from 'rxjs/operators';
import { AuthService } from '@app/shared/services/auth.service';

@Component({
  selector: 'app-share-users-form',
  templateUrl: './share-users-form.component.html',
  styleUrls: ['./share-users-form.component.scss'],
})
export class ShareUsersFormComponent implements OnDestroy {
  @Input() files: UserFile[];

  @Output() onSave = new EventEmitter<[AuthorFile[], AuthorFile[]]>();
  @Output() onCancel = new EventEmitter();

  @ViewChild('disallowTree') private treeShareDisallow: TreeComponent;

  treeOptions: ITreeOptions = {
    displayField: 'name',
    useVirtualScroll: false,
    isExpandedField: 'expanded',
    idField: 'id',
    nodeHeight: 24,
    dropSlotHeight: 6,
  };

  users: [AuthorFile[], AuthorFile[]];
  detached: [number[], number[]];
  selected: [boolean, boolean];
  loading: [boolean, boolean];
  filterApplied: [boolean, boolean];
  selectedUserInfo: AuthorFile;
  isUnshareTreeExpandedL1: boolean;
  isUnshareTreeExpandedL2: boolean;
  _isOpen: boolean;

  filter: any = {
    type: SHARE_TYPE,
    isOpen: false,
    action: () => {
      this.filterApplied[this.filter.type] = true;
      this.applyFilters(this.files, this.filter.type);
      this.filter.isOpen = false;
    },
    close: () => {
      this.filterApplied[this.filter.type] = false;
      this.resetFilters(this.filter.type, this.applyFilters);
      this.filter.isOpen = false;
    },
  };
  filters: any = [];

  SHARE_TYPE = SHARE_TYPE;
  ROLES: Role[] = ROLES;
  SHARE_MAX_FILES_COUNT_TITLE = SHARE_MAX_FILES_COUNT_TITLE;

  private onChangeSearch$: Subject<boolean> = new Subject();

  constructor(
    private fileManagerService: FileManagerService,
    private translate: TranslateService,
    private popover: PopoverService,
    private fb: FormBuilder,
    private user: AuthService
  ) {
    this.translate.setDefaultLang('ru');
  }

  @Input() set isOpen(value: boolean) {
    this._isOpen = value;
    if (this._isOpen) {
      this.init();
      this.resetFilters();
      this.fetch();
    }
  }

  ngOnDestroy() {
    this.onChangeSearch$.complete();
  }

  init(): void {
    this.detached = [[], []];
    this.users = [[], []];
    this.selected = [false, false];
    this.loading = [false, false];
    this.filterApplied = [false, false];
  }

  fetch(): void {
    const obs: Observable<any>[] = [];
    this.files.forEach((file: UserFile) => {
      obs.push(
        this.fileManagerService.getShareUsers({
          file_id: file.id,
          type: this.SHARE_TYPE.DISALLOW,
        })
      );
      obs.push(
        this.fileManagerService.getShareUsers({
          file_id: file.id,
          type: this.SHARE_TYPE.ALLOW,
        })
      );
    });

    forkJoin(obs).subscribe(
      (data: any) => {
        const disallowUsers = JSON.parse(JSON.stringify(data[0].items)) || [];
        const allowUsers = JSON.parse(JSON.stringify(data[1].items)) || [];
        this.users[SHARE_TYPE.DISALLOW] = disallowUsers.map((item) => {
          return {
            ...item,
            parent: {
              id: item.parent_id,
            },
          };
        });
        this.users[SHARE_TYPE.ALLOW] = allowUsers.map((item) => {
          return {
            ...item,
            parent: {
              id: item.parent_id,
            },
          };
        });
        this.users[SHARE_TYPE.DISALLOW] = this.getUsersTree(SHARE_TYPE.DISALLOW);
      },
      (err: any) => {
        this.users[SHARE_TYPE.DISALLOW] = [];
        this.users[SHARE_TYPE.ALLOW] = [];
        console.error(err);
      }
    );
  }

  onFilter(type: SHARE_TYPE): void {
    this.filter.type = type;
    this.filter.isOpen = true;
    this.resetFilters(this.filter.type, this.applyFilters);
  }

  applyFilters(files: UserFile[], type: SHARE_TYPE): void {
    const obs: Observable<any>[] = [];
    let search: string = null;
    let roles: string[] = null;
    let competencies: string;

    if (this.filters[type].search) {
      search = this.filters[type].search.controls.query.value;
    }
    if (this.filters[type].roles) {
      roles = this.filters[type].roles.filter((data: any) => data.checked === true).map((data: any) => data.value);
    }

    if (this.filters[type].competenciesSelected) {
      competencies = this.filters[type].competenciesSelected;
      this.filters[type].competenciesFiltered = [...this.filters[type].competenciesSelected];
    }

    files.forEach((file: UserFile) => {
      if (roles && roles.length) {
        roles.forEach((role: RolesEnum) => {
          obs.push(
            this.fileManagerService.getShareUsers({
              file_id: file.id,
              type: type,
              query: search,
              user_role: role,
              competencies,
            })
          );
        });
      } else {
        obs.push(
          this.fileManagerService.getShareUsers({
            file_id: file.id,
            type: type,
            query: search,
            competencies,
          })
        );
      }
    });
    forkJoin(obs).subscribe(
      (data: any) => {
        data.forEach((res) => {
          if (type === this.SHARE_TYPE.DISALLOW) {
            this.users[type] = this.getUsersArray(type);
            this.users[type] = [...res.items];
          } else {
            this.users[type] = [...res.items];
            this.detached[this.SHARE_TYPE.DISALLOW] = [];
          }
          res.items.forEach((user: AuthorFile) => {
            this.addUserShare(type, user);
          });
          this.users[type] = this.getUsersTree(type);
          this.treeShareDisallow?.treeModel?.update();
        });
      },
      () => {
        this.users[type] = [];
      }
    );
  }

  resetFilters(type?: SHARE_TYPE, callback?: Function): void {
    if (type) {
      this.filterApplied[type] = false;
      this.filters[type] = {
        search: this.fb.group({
          query: [''],
        }),
        roles: this.ROLES.map((role: Role) => {
          return {
            title: role.title,
            value: role.value,
            checked: false,
          };
        }),
        competenciesSelected: [],
        competenciesFiltered: [],
      };
      this.filters[type].search.valueChanges.subscribe(() => {
        this.onChangeSearch$.next(true);
        of(null)
          .pipe(delay(200), takeUntil(this.onChangeSearch$))
          .subscribe(() => {
            this.applyFilters(this.files, type);
          });
      });
    } else {
      this.filters[SHARE_TYPE.ALLOW] = {
        search: this.fb.group({
          query: [''],
        }),
        roles: this.ROLES.map((role: Role) => {
          return {
            title: role.title,
            value: role.value,
            checked: false,
          };
        }),
        competenciesSelected: [],
        competenciesFiltered: [],
      };
      this.filters[SHARE_TYPE.DISALLOW] = {
        search: this.fb.group({
          query: [''],
        }),
        roles: this.ROLES.map((role: Role) => {
          return {
            title: role.title,
            value: role.value,
            checked: false,
          };
        }),
        competenciesSelected: [],
        competenciesFiltered: [],
      };
      this.filters[SHARE_TYPE.DISALLOW].search.valueChanges.subscribe(() => {
        this.onChangeSearch$.next(true);
        of(null)
          .pipe(delay(200), takeUntil(this.onChangeSearch$))
          .subscribe(() => {
            this.applyFilters(this.files, SHARE_TYPE.DISALLOW);
          });
      });
      this.filters[SHARE_TYPE.ALLOW].search.valueChanges.subscribe(() => {
        this.onChangeSearch$.next(true);
        of(null)
          .pipe(delay(200), takeUntil(this.onChangeSearch$))
          .subscribe(() => {
            this.applyFilters(this.files, SHARE_TYPE.ALLOW);
          });
      });
      this.filterApplied[SHARE_TYPE.DISALLOW] = false;
      this.filterApplied[SHARE_TYPE.ALLOW] = false;
    }
    if (callback) {
      callback.apply(this, [this.files, type]);
    }
  }

  save(): void {
    this.users[SHARE_TYPE.DISALLOW] = TreeHelper.treeToArray(this.users[SHARE_TYPE.DISALLOW]).filter(
      (user: AuthorFile) => !this.detached[SHARE_TYPE.DISALLOW].includes(user.id)
    );
    this.onSave.emit(this.users);
  }

  cancel(): void {
    this.resetFilters();
    this.files = [];
    this.users[SHARE_TYPE.ALLOW] = [];
    this.users[SHARE_TYPE.DISALLOW] = [];
    this.detached[SHARE_TYPE.ALLOW] = [];
    this.detached[SHARE_TYPE.DISALLOW] = [];
    this._isOpen = false;
    this.filter.isOpen = false;
    this.onCancel.emit();
  }

  getUsers(type: SHARE_TYPE): AuthorFile[] {
    return this.users[type];
  }

  isDetached(type: SHARE_TYPE, item: number): boolean {
    return this.detached[type] ? this.detached[type].includes(item) : false;
  }

  toggleUserInfo(user?: AuthorFile): void {
    this.selectedUserInfo = user;
  }

  openUserCompetence(user: AuthorFile): void {
    console.log('TODO: Competence Card');
  }

  getSelectedUsers(type: SHARE_TYPE): AuthorFile[] {
    return this.getUsersArray(type).filter((user: AuthorFile) => user.selected);
  }

  addSelectedUsersFromTo(from: SHARE_TYPE, to: SHARE_TYPE): void {
    const selected = this.getSelectedUsers(from);
    if (selected.length) {
      if (from === SHARE_TYPE.ALLOW) {
        selected.forEach((user: AuthorFile) => {
          this.users[from].splice(this.users[from].indexOf(user), 1);
        });
        this.removeDetached(this.SHARE_TYPE.DISALLOW, selected);
      } else {
        this.addDetached(this.SHARE_TYPE.DISALLOW, selected);
      }
      this.addUsersShare(to, selected);
      this.toggleSelectedUsers(this.users[from], false);
      this.toggleSelectedUsers(this.users[to], false);
      this.toggleSelectedAll(false);
      this.treeShareDisallow.treeModel.update();
    }
  }

  addUsersShare(type: SHARE_TYPE, users: AuthorFile[]) {
    users.forEach((user: AuthorFile) => this.addUserShare(type, user));
  }

  expandItem(tree: TreeModel, node: TreeNode, $event: any): void {
    TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
  }

  addUserShare(type: SHARE_TYPE, user: AuthorFile) {
    const exist = this.getUsersArray(type).some((u: AuthorFile) => u.id === user.id);
    if (!exist) {
      this.users[type].push(user);
    }
  }

  selectAllUsers(type: SHARE_TYPE): void {
    this.selected[type] = !this.selected[type];
    this.toggleSelectedUsers(this.users[type], this.selected[type]);
  }

  getSelectedFileName(files: UserFile[]): string {
    const data = files
      .slice(0, this.SHARE_MAX_FILES_COUNT_TITLE)
      .map((file: UserFile) => file.name)
      .join(', ');
    if (files.length > this.SHARE_MAX_FILES_COUNT_TITLE) {
      return data + ',';
    }
    return data;
  }

  isSelectAllDisabled(): boolean {
    return this.user.user_type !== RolesEnum.SUPERUSER;
  }

  showMoreSelectedFileName(origin: HTMLElement, files: UserFile[]): void {
    const data = files.slice(this.SHARE_MAX_FILES_COUNT_TITLE, files.length);
    this.popover.open({
      content: FilesTooltipComponent,
      origin,
      data: {
        files: data,
      },
      height: 'auto',
    });
  }

  toggleByLevel(tc: any, level: number): void {
    const tree: TreeModel = tc.treeModel;
    switch (level) {
      case 1:
        if (this.isUnshareTreeExpandedL1) {
          tree.getVisibleRoots().forEach((node: TreeNode) => node.collapse());
          this.isUnshareTreeExpandedL1 = false;
        } else {
          tree.getVisibleRoots().forEach((node: TreeNode) => node.expand());
          this.isUnshareTreeExpandedL1 = true;
        }
        break;
      case 2:
        if (!this.isUnshareTreeExpandedL2 && !this.isUnshareTreeExpandedL1) {
          this.toggleByLevel(tc, 1);
        }
        if (this.isUnshareTreeExpandedL2) {
          tree.getVisibleRoots().forEach((node: TreeNode) => {
            node.getVisibleChildren().forEach((n: TreeNode) => n.collapse());
          });
          this.isUnshareTreeExpandedL2 = false;
        } else {
          tree.getVisibleRoots().forEach((node: TreeNode) => {
            node.getVisibleChildren().forEach((n: TreeNode) => n.expand());
          });
          this.isUnshareTreeExpandedL2 = true;
        }
        break;
    }
  }

  private getUsersTree(type: SHARE_TYPE): AuthorFile[] {
    return TreeHelper.arrayToTree(this.users[type]);
  }

  private getUsersArray(type: SHARE_TYPE): AuthorFile[] {
    if (type === this.SHARE_TYPE.DISALLOW) {
      return TreeHelper.treeToArray(this.getUsers(type));
    } else {
      return this.getUsers(type);
    }
  }

  private toggleSelectedUsers(users: AuthorFile[], selected: boolean): void {
    if (users.length > 0) {
      users.map((user: AuthorFile) => {
        user.selected = selected;
        if (user.children?.length > 0) {
          this.toggleSelectedUsers(user.children, selected);
        }
      });
    }
  }

  private toggleSelectedAll(value?: boolean): void {
    this.selected[SHARE_TYPE.ALLOW] = value !== null ? value : !this.selected[SHARE_TYPE.ALLOW];
    this.selected[SHARE_TYPE.DISALLOW] = value !== null ? value : !this.selected[SHARE_TYPE.DISALLOW];
  }

  private addDetached(type: SHARE_TYPE, users: AuthorFile[]): void {
    users.forEach((user: AuthorFile) => {
      if (!this.detached[type].includes(user.id)) {
        this.detached[type].push(user.id);
      }
    });
  }

  private removeDetached(type: SHARE_TYPE, users: AuthorFile[]): void {
    users.forEach((user: AuthorFile) => {
      this.detached[type].splice(this.detached[type].indexOf(user.id), 1);
    });
  }
}
