import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, ElementRef,
  HostListener,
  Input,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ModalInjectorService } from '@app/modal-injector/services/modal-injector.service';
import { POSITION, TYPE_PARENT } from '@app/shared/components/move-pin/constants/move-pin.constants';

@Component({
  selector: 'app-move-pin',
  templateUrl: './move-pin.component.html',
  styleUrls: ['./move-pin.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MovePinComponent implements AfterViewInit {
  @Input() id;
  @Input() classForChildren: string = '';
  @Input() parent: TYPE_PARENT | null = null;
  @Input() position: POSITION | null = null;
  @Input() margin: number = 15;
  @Input() mlPinIcon: number = 5;
  @Input() color;
  @Input() resize;
  @Input() noPinAndMove = false;
  @ViewChild('mainWindow') private mainWindow: any;
  isOpen: boolean = false;
  x: number;
  y: number;
  px: number;
  py: number;
  width: string;
  height: string;
  @Input() widthPx: number;
  @Input() heightPx: number;
  draggingWindow: boolean;
  draggingCorner: boolean;
  pinFlag = false;
  minHeight = 335;
  minWidth = 520;
  sizeElem = { height: 0, width: 0 };

  constructor(private modalInjectorService: ModalInjectorService, private cdRef: ChangeDetectorRef) {
    this.x = 300;
    this.y = 50;
    this.px = 0;
    this.py = 0;
    this.width = 'max-content';
    this.height = 'max-content';
    this.draggingWindow = false;
    this.draggingCorner = false;

    window.onmousemove = this.onWindowDrag;
  }

  onWindowPress(event: MouseEvent) {
    this.draggingWindow = true;
    this.px = event.clientX;
    this.py = event.clientY;
  }

  onCornerClick(event: MouseEvent) {
    this.draggingCorner = true;
    this.px = event.clientX;
    this.py = event.clientY;
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('document:mousemove', ['$event'])
  onCornerMove(event: MouseEvent) {
    this.onWindowDrag(event);
    if (!this.draggingCorner) {
      return;
    } else {
      const offsetX = event.clientX - this.px,
        offsetY = event.clientY - this.py,
        elem = this.mainWindow.nativeElement;
      if (!this.widthPx) {
        this.widthPx = elem.offsetWidth;
      }
      if (!this.heightPx) {
        this.heightPx = elem.offsetHeight;
      }
      const pWidth = this.widthPx,
        pHeight = this.heightPx;

      this.widthPx += offsetX;
      this.heightPx += offsetY;

      if (pHeight < this.minHeight) {
        this.heightPx = this.minHeight;
      }
      if (pWidth < this.minWidth) {
        this.widthPx = this.minWidth;
      }
      this.px = event.clientX;
      this.py = event.clientY;
    }
  }

  onWindowDrag(event: MouseEvent) {
    if (!this.draggingWindow) {
      return;
    }
    const offsetX = event.clientX - this.px;
    const offsetY = event.clientY - this.py;
    this.x += offsetX;
    this.y += offsetY;
    this.px = event.clientX;
    this.py = event.clientY;
  }

  onClose() {
    this.modalInjectorService.closeModal(this.id);
  }

  onClickedOutside(event) {
    if (!this.pinFlag && this.isOpen) {
      this.modalInjectorService.closeModal(this.id);
    }
    this.isOpen = true;
  }

  @HostListener('document:mouseup', ['$event'])
  onCornerRelease() {
    this.draggingWindow = false;
    this.draggingCorner = false;
  }

  setPosition(pos) {
    switch (pos) {
      case POSITION.TOP:
        return {
          x: this.parent.left - (this.sizeElem.width - this.parent.width) / 2,
          y: this.parent.top - this.sizeElem.height - this.margin,
        };
      case POSITION.TOP_RIGHT:
        return {
          x: this.parent.left + this.parent.width + this.margin,
          y: this.parent.top - this.sizeElem.height - this.margin,
        };
      case POSITION.TOP_LEFT:
        return {
          x: this.parent.left - this.sizeElem.width - this.margin,
          y: this.parent.top - this.sizeElem.height - this.margin,
        };
      case POSITION.RIGHT:
        return { x: this.parent.left + this.parent.width + this.margin, y: this.parent.top };
      case POSITION.DOWN:
        return {
          x: this.parent.left - (this.sizeElem.width - this.parent.width) / 2,
          y: this.parent.top + this.parent.height + this.margin,
        };
      case POSITION.DOWN_RIGHT:
        return {
          x: this.parent.left + this.parent.width + this.margin,
          y: this.parent.top + this.parent.height + this.margin,
        };
      case POSITION.DOWN_LEFT:
        return {
          x: this.parent.left - this.sizeElem.width - this.margin,
          y: this.parent.top + this.parent.height + this.margin,
        };
      case POSITION.LEFT:
        return { x: this.parent.left - this.sizeElem.width - this.margin, y: this.parent.top };
      case POSITION.CENTER:
        return {
          x: window.innerWidth / 2 - this.sizeElem.width / 2,
          y: window.innerHeight / 2 - this.sizeElem.height / 2,
        };
    }
  }

  ngAfterViewInit() {
    if (this.parent) {
      const elem = this.mainWindow.nativeElement;
      this.sizeElem = { height: elem.offsetHeight, width: elem.offsetWidth };
      let startPosition = this.setPosition(this.position || POSITION.RIGHT),
        flag = true,
        listPosition = [POSITION.TOP, POSITION.DOWN, POSITION.LEFT, POSITION.RIGHT,
          POSITION.TOP_RIGHT, POSITION.TOP_LEFT, POSITION.DOWN_LEFT, POSITION.DOWN_RIGHT, POSITION.CENTER],
        i = 0;
      while (flag && i < listPosition.length) {
        if (
          window.innerWidth < startPosition.x + this.sizeElem.width ||
          startPosition.x < 0 ||
          window.innerHeight < startPosition.y + this.sizeElem.height ||
          startPosition.y < 0
        ) {
          startPosition = this.setPosition(listPosition[i]);
          i++;
        } else {
          flag = false;
        }
      }
      this.x = startPosition.x;
      this.y = startPosition.y;
      this.cdRef.detectChanges();
    }
  }
}
