import { AfterContentInit, Component, ContentChild, Input } from '@angular/core';

import { FormFieldDirective } from '@app/shared/directives/form-field.directive';

@Component({
  selector: 'dc-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
})
export class FormInputComponent implements AfterContentInit {
  @Input() isShowClear: boolean = false;
  @Input() maxLength: number | null = null;
  @Input() isShowLength: boolean = false;

  @ContentChild(FormFieldDirective, { static: true, read: FormFieldDirective }) formInput: FormFieldDirective;

  get inputLength(): number {
    if (this.formInput?.ngControl?.value) {
      if (typeof this.formInput?.ngControl?.value === 'string') {
        return this.formInput?.ngControl?.value?.trim().length;
      } else {
        return this.formInput?.ngControl?.value?.length;
      }
    }
    return 0;
  }

  get isTextarea(): boolean {
    return this.formInput.isTextarea();
  }

  ngAfterContentInit(): void {
    if (this.maxLength && this.maxLength > 0) {
      this.formInput.setLength(this.maxLength);
    }
  }

  public clearInput(): void {
    this.formInput.clear();
  }
}
