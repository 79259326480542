import { NgModule } from '@angular/core';
import { ShareTreeItemComponent } from './share-tree-item/share-tree-item.component';
import { ShareUsersFormComponent } from '@app/file_manager/share-users-form/share-users-form/share-users-form.component';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { SVGSpriteModule } from '@app/svg-sprite/svg-sprite.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { TreeModule } from '@circlon/angular-tree-component';
import { ShareFilterComponent } from '@app/file_manager/share-users-form/share-filter/share-filter.component';
import { ShareFilterItemComponent } from '@app/file_manager/share-users-form/share-filter-item/share-filter-item.component';
import { ShareFilterTreeComponent } from '@app/file_manager/share-users-form/share-filter-tree/share-filter-tree.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './i18n/', '.json');
}

@NgModule({
  imports: [
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    CommonModule,
    SVGSpriteModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    TreeModule,
  ],
  declarations: [
    ShareTreeItemComponent,
    ShareUsersFormComponent,
    ShareFilterComponent,
    ShareFilterItemComponent,
    ShareFilterTreeComponent,
  ],
  exports: [
    ShareTreeItemComponent,
    ShareUsersFormComponent,
    ShareFilterComponent,
    ShareFilterItemComponent,
    ShareFilterTreeComponent,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class ShareUsersFormModule {}
