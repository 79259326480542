import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FaqFilesFieldComponent } from './faq-files-field.component';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { FaqApiService } from '../../services/faq.api.service';

library.add(fas, faTimes);

@NgModule({
  imports: [CommonModule, FontAwesomeModule, NgbPopoverModule],
  declarations: [FaqFilesFieldComponent],
  exports: [FaqFilesFieldComponent],
  providers: [FaqApiService],
})
export class FaqFilesFieldModule {}
