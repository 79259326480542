import { Component, Input } from '@angular/core';
import { CMProjectStatuses } from '@app/+competence-map/constants/projects.constants';

@Component({
  selector: 'app-project-status-view',
  templateUrl: './project-status-view.component.html',
  styleUrls: ['./project-status-view.component.scss'],
})
export class ProjectStatusViewComponent {
  @Input() status: CMProjectStatuses;

  getStatus(): string {
    if (this.status === CMProjectStatuses.AGREED) {
      return 'согласовано';
    }

    if (this.status === CMProjectStatuses.ON_AGREEMENT) {
      return 'на согласовании';
    }

    if (this.status === CMProjectStatuses.ARCHIVE) {
      return 'в архиве';
    }

    if (this.status === CMProjectStatuses.EDITING) {
      return 'редактирование';
    }
  }
}
