import { Observable, throwError as observableThrowError } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';

@Injectable()
export class AccessService {
  url: string = environment.api_url;

  constructor(private http: HttpClient) {}

  getAllowedUsersByPage(page: string) {
    return this.http.get(`${this.url}/access/page/${page}`).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  getPagesAllowedToUserByUserId(user_id: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${this.url}/access/user/${user_id}`).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  setUserAccessToPage(data) {
    return this.http.post(`${this.url}/access/page/${data.page}`, data).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }

  removeUserAccessFromPage(data) {
    return this.http.request('delete', `${this.url}/access/page/${data.page}`, { body: data }).pipe(
      catchError((e) => {
        return observableThrowError(e);
      })
    );
  }
}
