import { Component, EventEmitter, Input, Output } from '@angular/core';

import { UserFile } from '@app/file-manager/models/user-file.model';

@Component({
  selector: 'app-file-manager-selected-info',
  templateUrl: './file-manager-selected-info.component.html',
  styleUrls: ['./file-manager-selected-info.component.scss'],
})
export class FileManagerSelectedInfoComponent {
  @Input() selectedFiled: UserFile[];
  @Output() readonly onResetSelected = new EventEmitter<unknown>();

  get count(): number {
    return this.selectedFiled.length;
  }

  resetSelected(): void {
    this.onResetSelected.emit();
  }
}
