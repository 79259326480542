import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Project } from '@app/+competence-map/models/projects.models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompetenceService } from '@app/+competence-map/services/competence.service';
import { CMActions, CMProjectStatuses } from '@app/+competence-map/constants/projects.constants';
import { switchMap } from 'rxjs/internal/operators';
import { Observable, of } from 'rxjs';
import { CreateOrChangeFilterTemplateParams, FilterTemplate } from '@app/+competence-map/models/filter-templates.model';
import { ITreeOptions, TREE_ACTIONS, TreeModel, TreeNode, TreeComponent } from '@circlon/angular-tree-component';
import { CMCatalogTypes, CMSectionStatuses } from '@app/+competence-map/constants/sections.constants';
import { doForAllSync } from '@app/+competence-map/helpers/competence-map.helpers';
import { CreateFilterParams } from '@app/+competence-map/models/user-filters.model';
import { DCTreeItem } from '@app/+competence-map/models/competence-map.models';
import { FILTERS_CATEGORIES, UserFiltersEnum } from '@app/+competence-map/constants/user-filters.constants';
import { FilterTemplateStatuses, FilterTemplateTypes } from '@app/+competence-map/constants/filter-templates.constants';
import { TreeHelper } from '@app/shared/helpers/tree.helper';
import { map } from 'rxjs/operators';
import { CompetenceSectionsService } from '@app/+competence-map/services/competence-sections.service';
import { ProjectBase, ProjectBaseComponent } from '@app/+competence-map/models/project.class';
import { PortalService } from '@app/shared/services/portal.service';
import { AuthService } from '@app/shared/services/auth.service';

@Component({
  selector: 'app-add-user-filter',
  templateUrl: './add-user-filter.component.html',
  styleUrls: ['./add-user-filter.component.scss'],
  providers: [PortalService],
})
export class AddUserFilterComponent extends ProjectBaseComponent implements OnInit, ProjectBase {
  @Input() categoryId: string;
  @Input() sectionId: number;

  @ViewChild('filtersTree') private filtersTree: TreeComponent;
  @ViewChild('sectionsTree') private sectionsTree: TreeComponent;

  sections: DCTreeItem[] = [];
  sectionsSearchValue = '';

  filterTemplates: FilterTemplate[] = [];
  filterTemplatesSearchValue = '';

  form: FormGroup;

  categories = FILTERS_CATEGORIES;

  treeOptions: ITreeOptions = {
    isExpandedField: 'expanded',
    nodeHeight: 22,
  };

  filterTemplateTypes = FilterTemplateTypes;

  allFilters: boolean = false;
  allSections: boolean = false;

  FilterTemplateStatuses = FilterTemplateStatuses;

  @Output() closeEvent = new EventEmitter();
  @Output() reloadEvent = new EventEmitter();

  constructor(
    private competenceService: CompetenceService,
    private competenceSectionsService: CompetenceSectionsService,
    protected authService: AuthService,
    protected portalService: PortalService,
    private fb: FormBuilder
  ) {
    super(portalService, authService);
  }

  get isNew() {
    return !this.project || (this.project && !this.project.id);
  }

  ngOnInit(): void {
    this.initializeForm();

    this.initData();
  }

  initData() {
    this.getFilterTemplates();
    this.getSections();

    this.categories.forEach((item) => {
      item.checked = this.project && this.project.spec[this.project.action].categories.includes(item.value);

      if (!!this.categoryId) {
        item.checked = item.value === this.categoryId;
      }
    });

    this.patchCategoriesControl();

    this.form.markAsPristine();
  }

  initializeForm() {
    this.form = this.fb.group({
      filters: [[], Validators.required],
      sections: [[], Validators.required],
      categories: [[], Validators.required],
    });

    if (!this.isNew) {
      this.form.setValue({
        sections: this.project.spec[this.project.action].section_ids,
        filters: this.project.spec[this.project.action].trees,
        categories: this.project.spec[this.project.action].categories,
      });
    }
  }

  getSections() {
    this.competenceSectionsService
      .getSections([
        {
          name: 'catalog',
          op: 'in_',
          val: [CMCatalogTypes.GOODS, CMCatalogTypes.SERVICES],
        },
        {
          name: 'status',
          op: 'in_',
          val: [CMSectionStatuses.ACTIVE, CMSectionStatuses.DRAFT],
        },
      ])
      .subscribe((sections) => {
        const result = sections.map((item) => {
          return {
            ...item,
            selected:
              item.id === this.sectionId ||
              (this.project && this.project.spec[this.project.action].section_ids.includes(item.id)),
            parent: {
              id: item.parent_id,
            },
          };
        });

        this.sections = TreeHelper.list_to_tree(result);

        if (this.isNew) {
          this.highlightTreeBySelected();
        }

        setTimeout(() => {
          this.patchSectionsControl();
          this.form.markAsPristine();
        });
      });
  }

  highlightTreeBySelected() {
    doForAllSync(this.sections, (item) => {
      if (item.selected) {
        doForAllSync(item.children, (child) => {
          child.selected = item.selected;
        });
      }
    });
  }

  getFilterTemplates() {
    const filterConfig = [
      {
        name: 'level',
        val: '1',
        op: 'eq',
      },
      {
        name: 'status',
        val: FilterTemplateStatuses.ACTIVE,
        op: 'eq',
      },
    ];

    this.competenceService.getFilterTemplates(filterConfig).subscribe(
      (value) => {
        this.filterTemplates = value.map((item) => {
          return {
            ...item,
            children: [],
            hasChildren: true,
            checked:
              this.project &&
              this.project.spec[this.project.action].trees.some(
                (treeItem) => treeItem.node_info.template_filter_id === item.id
              ),
          };
        });

        setTimeout(() => {
          this.filtersTree.treeModel.getVisibleRoots().forEach((node) => {
            if (node.data.checked) {
              this.getChildrenStructureFilter(node.data.id).subscribe((structureFilters) => {
                node.data.children = structureFilters[0].children;
                this.filtersTree.treeModel.update();

                this.doForAllCheck(node);
              });
            }
          });
        }, 0);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getChildrenStructureFilter(nodeId: string): Observable<FilterTemplate[]> {
    return this.competenceService.getTemplateStructureFilter({ node_id: nodeId }).pipe(
      map((structureFilters) => {
        return this.transformToFilterTemplate(structureFilters);
      })
    );
  }

  getSelectedCategories(): UserFiltersEnum[] {
    return this.categories.filter((item) => item.checked).map((item) => item.value);
  }

  getSelectedSections(): number[] {
    return this.getTreeSelected(this.sectionsTree, 'selected');
  }

  getSelectedFilters(items: FilterTemplate[]): CreateFilterParams[] {
    return items
      .filter((item) => item.checked && item.status === FilterTemplateStatuses.ACTIVE)
      .map((item) => {
        return {
          childes: this.getSelectedFilters(item.children),
          node_info: {
            template_filter_id: +item.id,
          },
        };
      });
  }

  cancel() {
    this.initializeForm();

    this.initData();

    this.closeEvent.emit();
  }

  addProject(status?: CMProjectStatuses): void {
    this.competenceService
      .addUserFilter({
        categories: this.getSelectedCategories(),
        section_ids: this.getSelectedSections(),
        trees: this.getSelectedFilters(this.filtersTree.treeModel.nodes),
      })
      .pipe(
        switchMap((project) => {
          if (!status) {
            return of(project);
          } else {
            return this.competenceService.updateProject({
              id: project.id,
              status,
            });
          }
        })
      )
      .subscribe((project) => {
        this.project = project;

        this.reloadEvent.emit();
        this.competenceService.projectSuccess(status);
        this.form.markAsPristine();
      }, this.competenceService.error);
  }

  updateProject(status?: CMProjectStatuses): void {
    this.competenceService
      .updateProject({
        id: this.project.id,
        spec: {
          [CMActions.CREATE_FILTERS]: {
            categories: this.getSelectedCategories(),
            section_ids: this.getSelectedSections(),
            trees: this.getSelectedFilters(this.filtersTree.treeModel.nodes),
          },
        },
        status,
      } as Project)
      .subscribe((project) => {
        this.project = project;

        this.reloadEvent.emit();
        this.competenceService.projectSuccess(status);
        this.form.markAsPristine();
      }, this.competenceService.error);
  }

  save(status: CMProjectStatuses) {
    if (this.isNew) {
      this.addProject(status);
    } else {
      this.updateProject(status);
    }
  }

  agree(status: CMProjectStatuses) {
    if (this.isNew) {
      this.addProject(status);
    } else {
      this.updateProject(status);
    }
  }

  archive() {
    this.warnChangeArchiveStatus(() => {
      this.updateProject(CMProjectStatuses.ARCHIVE);
    });
  }

  expandFilterItem(tree: TreeModel, node: TreeNode): void {
    if (!node.data.children.length) {
      this.getChildrenStructureFilter(node.data.id).subscribe((structureFilters) => {
        node.data.children = structureFilters[0].children;
        this.filtersTree.treeModel.update();

        this.expandItem(this.filtersTree, node);
      });
    } else {
      this.expandItem(this.filtersTree, node);
    }
  }

  expandItem(tree, node: TreeNode) {
    TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, null);
  }

  filterTreeData(tree: TreeComponent, query: string): void {
    tree.treeModel.filterNodes((node: TreeNode) => {
      if (!query) {
        return true;
      }

      return node.data.title.toLowerCase().includes(query.toLowerCase());
    });
  }

  selectSection(section: DCTreeItem) {
    doForAllSync(section.children, (node) => {
      node.selected = section.selected;
    });

    this.patchSectionsControl();
  }

  selectCategory() {
    this.patchCategoriesControl();
  }

  selectFilter(node: TreeNode) {
    if (node.data.level === 1) {
      if (!node.data.children.length) {
        this.getChildrenStructureFilter(node.data.id).subscribe((structureFilters) => {
          node.data.children = structureFilters[0].children;

          this.filtersTree.treeModel.update();

          this.doForAllCheck(node);
          this.patchFiltersControl();
        });
      } else {
        this.doForAllCheck(node);
        this.patchFiltersControl();
      }
    } else {
      this.doForAllCheck(node);
      this.patchFiltersControl();
    }
  }

  doForAllCheck(node: TreeNode) {
    doForAllSync(node.data.children, (nodeItem: any) => {
      nodeItem.checked = node.data.checked;
    });
  }

  toggleFilters(data: FilterTemplate[]) {
    doForAllSync(data, (item) => {
      if (item.status === FilterTemplateStatuses.ACTIVE) {
        item.checked = this.allFilters;
      }
    });

    this.patchFiltersControl();
  }

  toggleSections(data: DCTreeItem[]) {
    doForAllSync(data, (item) => {
      if (item.hasAccess && (item.status === CMSectionStatuses.ACTIVE || item.status === CMSectionStatuses.DRAFT)) {
        item.selected = this.allSections;
      }
    });

    this.patchSectionsControl();
  }

  private getTreeSelected(tree: TreeComponent, field: string): number[] {
    const data = [];

    doForAllSync(tree.treeModel.getVisibleRoots(), (node: TreeNode) => {
      if (node.data[field]) {
        data.push(node.data.id);
      }
    });

    return data;
  }

  private transformToFilterTemplate(structureFilters: CreateOrChangeFilterTemplateParams[]): FilterTemplate[] {
    return structureFilters.map((item) => {
      return {
        ...item?.node_info,
        id: item?.node_id,
        children: this.transformToFilterTemplate(item.childes),
        isExpanded: false,
        checked:
          this.project &&
          this.project.spec[this.project.action].trees.some(
            (treeItem) => +treeItem.node_info.template_filter_id === +item.node_info.id
          ),
      };
    });
  }

  private patchFiltersControl() {
    this.form.controls.filters.patchValue(this.getSelectedFilters(this.filtersTree.treeModel.nodes));

    this.form.markAsDirty();
  }

  private patchSectionsControl() {
    this.form.controls.sections.patchValue(this.getSelectedSections());
    this.form.markAsDirty();
  }

  private patchCategoriesControl() {
    this.form.controls.categories.patchValue(this.getSelectedCategories());
    this.form.markAsDirty();
  }
}
