import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-view-pdf-modal',
  templateUrl: './view-pdf-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewPdfModalComponent {
  public title: string;
  public docLink: string;

  get link(): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.docLink);
  }

  constructor(public activeModal: NgbActiveModal, private sanitizer: DomSanitizer) {}
}
