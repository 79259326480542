import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-form-checkbox-switch',
  templateUrl: './form-checkbox-switch.component.html',
  styleUrls: ['./form-checkbox-switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormCheckboxSwitchComponent),
    },
  ],
})
export class FormCheckboxSwitchComponent implements ControlValueAccessor {
  @Input() readonly = false;
  @Input() checked = false;
  @Input() withLabels = true;

  onChange: any = () => {};
  onTouch: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(checked: boolean) {
    this.checked = checked;
  }

  onModelChange(e: boolean) {
    this.checked = e;
    this.onChange(e);
  }
}
