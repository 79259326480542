import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faCaretDown,
  faQuestion,
  faDollarSign,
  faCar,
  faBicycle,
  faStar,
  faTimes,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { faApple } from '@fortawesome/fontawesome-free-brands';
import { CngHtmlCompilerModule } from '@codehint-ng/html-compiler';
import { ModalInjectorService } from '@app/modal-injector/services/modal-injector.service';
import { ModalInjectorComponent } from '@app/modal-injector/modal-injector/modal-injector.component';
import { SharedModule } from '@app/shared/shared.module';

library.add(
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faCaretDown,
  faQuestion,
  faDollarSign,
  faCar,
  faBicycle,
  faStar,
  faTimes,
  faPlus,
  faBell,
  faApple as any
);

@NgModule({
  imports: [CommonModule, FormsModule, TextMaskModule, FontAwesomeModule, CngHtmlCompilerModule, SharedModule],
  declarations: [ModalInjectorComponent],
  providers: [ModalInjectorService],
  exports: [ModalInjectorComponent],
})
export class ModalInjectorModule {}
