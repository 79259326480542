import { Component, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Subject } from 'rxjs';

import { AuthService } from '@app/shared/services/auth.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-reset-password-modal',
  templateUrl: './reset-password-modal.component.html',
  styleUrls: ['./reset-password-modal.component.scss'],
})
export class ResetPasswordModalComponent implements OnDestroy {
  @Input() currentEmail: string;
  private _submit: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  get submit(): boolean {
    return this._submit;
  }

  constructor(private activeModal: NgbActiveModal, private modalService: NgbModal, private authService: AuthService) {}

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.ngUnsubscribe = null;
  }

  public close(): void {
    this.activeModal.close();
  }

  public success(): void {
    this._submit = true;
    this.authService
      .resetPass(this.currentEmail)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this._submit = false;
        this.modalService.dismissAll();
        this.authService.logout();
      });
  }
}
