<div (click)="cancel()" class="dc-fileman-modal-x-button">
  <app-svg-icon [width]="20" [height]="20" name="close"></app-svg-icon>
</div>
<div class="modal-header center">
  <div class="modal-title">Справка</div>
</div>
<div class="modal-body">__CONTENT__</div>
<div class="modal-footer">
  <div class="dc-footer-buttons">
    <button class="btn btn-dc-primary" (click)="cancel()">Закрыть</button>
  </div>
</div>
