import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NotificationsService } from 'angular2-notifications';
import {
  EventModel, LIST_REPEAT_SELECT,
  LIST_STATE,
  STATE,
  StatusModel,
} from '@app/shared/components/calendar-widget/constants/calendar.constants';
import { ModalInjectorConstants } from '@app/modal-injector/constants/modal-injector.constants';
import { ModalInjectorService } from '@app/modal-injector/services/modal-injector.service';
import * as moment from 'moment';
import { BACKEND_DATE_TIME_FORMAT } from '@app/shared/constants/date.constants';
import { CalendarDataService } from '@app/shared/services/calendar/calendar-data.service';
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
  selector: 'app-info-event',
  templateUrl: './info-event.component.html',
  styleUrls: ['./info-event.component.scss'],
})
export class InfoEventComponent implements OnInit {
  @Input() event_id: number;
  @Input() modalId: number;
  @Output() colorEvent = new EventEmitter<string>();
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  title: string = '';
  status: StatusModel;
  event: EventModel;
  listState = LIST_STATE;
  state = STATE;

  constructor(
    private http: HttpClient,
    private notify: NotificationsService,
    private modalInjectorService: ModalInjectorService,
    private calendarDataService: CalendarDataService
  ) {}

  setColor(state) {
    return this.listState.find((item) => item.key == state).color;
  }

  editEvent(eventModel, copy = false) {
    this.modalInjectorService.addModal(ModalInjectorConstants.ADD_EVENT_CALENDAR, { eventModel, show: true, addEvent: true, copy });
  }

  deleteEvent(event) {
    this.modalInjectorService.addModal(ModalInjectorConstants.DELETE_EVENT, { show: true, event });
  }

  updateData() {
    this.calendarDataService.getEventsById(this.event_id).subscribe((res: EventModel) => {
        this.event = res;
        if (!this.event.end_date) {
          this.title =
            moment(`${res.start_date} ${res.start_time}`, BACKEND_DATE_TIME_FORMAT)
              .format('HH:mm, dd, DD MMMM YYYY');
        } else {
          this.title =
            moment(`${res.extra.start_date_first_event || res.start_date} ${res.start_time}`, BACKEND_DATE_TIME_FORMAT)
              .format('HH:mm, dd, DD MMMM YYYY') + ' - ' +
            moment(`${res.end_date} ${res.end_time || ''}`, BACKEND_DATE_TIME_FORMAT)
              .format(res.end_time ? 'HH:mm, dd, DD MMMM YYYY' : 'dd, DD MMMM YYYY');
        }

        this.status = this.listState.find((item) => item.key == res.status);
        this.colorEvent.emit(this.status.color);
      },
      (error) => {
        this.modalInjectorService.closeModal(this.modalId)
      });
  }

  ngOnInit() {
    this.updateData();
    this.calendarDataService.updateDataAllEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
      this.updateData();
    });
  }
}
