import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from 'angular2-notifications';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ResetPasswordModalComponent } from '@app/profile/shared/components/reset-password-modal/reset-password-modal.component';
import { passwordRequirements, ProfileModalBaseOptions } from '@app/profile/constants/profile.modal.constants';
import { AuthService } from '@app/shared/services/auth.service';
import { FormHelper } from '@app/shared/helpers/form.helper';
import { FORM_VALIDATOR_MESSAGES } from '@app/shared/constants/form.constants';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss'],
})
export class ChangePasswordModalComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public passwordUpdated: boolean = false;
  public isCorrectPassword: boolean = true;

  @Input() currentEmail: string;

  messages = FORM_VALIDATOR_MESSAGES;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private authService: AuthService,
    private notify: NotificationsService
  ) {}

  get newPasswordInvalid(): boolean {
    return this.form.get('new_password').touched && this.form.get('new_password').invalid;
  }

  get notEquivalentPasswords(): boolean | null {
    if (this.form.get('confirm_password').touched) {
      return this.form.get('confirm_password').errors?.notEquivalent || this.form.invalid;
    }
    return null;
  }

  ngOnInit(): void {
    this.form = this.fb.group(
      {
        current_password: [null, Validators.required],
        new_password: [null, [Validators.pattern(passwordRequirements), Validators.required, Validators.minLength(6)]],
        confirm_password: [null, Validators.required],
      },
      { validator: FormHelper.checkIfMatchingPasswords('new_password', 'confirm_password') }
    );
    this.inputPassword();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.ngUnsubscribe = null;
  }

  public inputPassword(): void {
    this.isCorrectPassword = true;
  }

  public resetPassword(): void {
    const modal = this.modalService.open(ResetPasswordModalComponent, {
      ...ProfileModalBaseOptions,
      windowClass: 'dc-modal profile-modal modal-window',
    });

    (<ResetPasswordModalComponent>modal.componentInstance).currentEmail = this.currentEmail;
  }

  public close(): void {
    this.activeModal.close();
  }

  public success(): void {
    console.log(this.form);
    if (this.form.valid) {
      const { current_password, new_password } = this.form.value;
      this.authService
        .changePassword(current_password, new_password)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          () => {
            this.passwordUpdated = true;
            this.notify.success('УСПЕШНО', 'Данные успешно сохранены');
            this.close();
          },
          (err) => {
            if (err.error.message === 'Current password is incorrect') {
              this.isCorrectPassword = false;
            }
          }
        );
    }
  }
}
