import { delay, switchMap, takeUntil } from 'rxjs/operators';
import { ChatService } from '@app/chat/services/chat.service';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { ChatRoom } from '@app/chat/models/chat.model';
import { ChatSectionsEnum } from '@app/chat/constants/chat-sections.constants';

@Component({
  selector: 'app-chat-tabs',
  templateUrl: './chat-tabs.component.html',
  styleUrls: ['./chat-tabs.component.scss'],
})
export class ChatTabsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('scrollMe', { static: true }) scrollMe: ElementRef;
  ngUnsubscribe: Subject<void> = new Subject<void>();
  contactSelected: ChatRoom;
  tabs: ChatRoom[] = [];

  constructor(private chatService: ChatService) {}

  ngOnInit() {
    this.tabs = this.filterTabs(this.chatService.getTabs());
    this.chatService.tabsChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(tabs => {
      this.tabs = this.filterTabs(tabs);
    });
    this.contactSelected = this.chatService.getContactSelected();
    this.chatService.contactSelectedChanged
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(contactSelected => (this.contactSelected = contactSelected));
  }

  filterTabs(tabs: ChatRoom[]) {
    return tabs.filter(tab => {
      if (this.chatService.isTradeGroup(tab)) {
        return false;
      }

      return tab;
    });
  }

  ngAfterViewInit() {
    this.chatService.scrollToTabChanged
      .pipe(
        takeUntil(this.ngUnsubscribe),
        switchMap((elem: ElementRef) => {
          return this.chatService.setScrollTabs(this.scrollMe, elem);
        }),
        delay(1)
      )
      .subscribe((val: number) => {
        this.scrollMe.nativeElement.scrollLeft = val;
      });
  }

  scroll(size) {
    this.scrollMe.nativeElement.scrollLeft += size;
  }

  canScrollR(): boolean {
    return (
      this.scrollMe.nativeElement.scrollLeft <
      this.scrollMe.nativeElement.scrollWidth - this.scrollMe.nativeElement.clientWidth
    );
  }

  canScrollL(): boolean {
    return this.scrollMe.nativeElement.scrollLeft > 0;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
