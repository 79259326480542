import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { AuthService } from '@app/shared/services/auth.service';
import { PersonalDataService } from '@app/shared/services/personal-data.service';

import { MENU_ITEMS } from '@app/shared/constants/menu-items.constants';
import { RolesEnum } from '@app/shared/constants/roles.constants';
import { modalBaseOptions } from '@app/shared/constants/modal-options.constants';

import { UserSettings } from '@app/shared/models/user-settings.model';
import { User } from '@app/shared/models/user.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { StartPageDialogComponent } from '@app/shared/components/start-page-dialog/start-page-dialog.component';
import { NotificationsService } from 'angular2-notifications';


@Component({
  templateUrl: './dashboard_layout.component.html',
  styleUrls: ['./dashboard_layout.component.sass'],
})
export class DashboardLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  private menuItems: Array<any>;
  private startPageSelected: any;
  private userSettings: UserSettings;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  public ROLES = RolesEnum;
  public demo = false;
  public user: User;
  public MENU_ITEMS = MENU_ITEMS;
  public isMenuCollapsed = false;
  public isMenuCollapsedScroll = false;
  public offScroll = false;

  @ViewChild('leftMenu') private leftMenuElm: ElementRef;

  private static hasOffScroll(url): boolean {
    return url === '/trades/customer' || url === '/trades/supplier';
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private modalService: NgbModal,
    private notify: NotificationsService,
    private personalDataService: PersonalDataService
  ) {
    this.route.data.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      if (data['type'] === 'demo') {
        this.demo = true;
      }
    });
  }

  ngOnInit(): void {
    this.authService.userStream
      .pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe(
      user => {
        this.user = user;

        this.menuItems = MENU_ITEMS[user.type].tuning;

        this.addPayment();
        this.getUserSettings();
      });

    this.offScroll = DashboardLayoutComponent.hasOffScroll(this.router.url);

    this.router.events
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(value => value instanceof NavigationEnd)
      ).subscribe((value: NavigationEnd) => {
      this.offScroll = DashboardLayoutComponent.hasOffScroll(value.url);
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.checkLeftMenuScroll());
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.ngUnsubscribe = null;
  }

  public onResize(): void {
    this.checkLeftMenuScroll();
  }

  public onCollapseMenu(): void {
    this.isMenuCollapsed = !this.isMenuCollapsed;
    setTimeout(() => this.checkLeftMenuScroll(), 300);
  }

  public onToggleItem(): void {
    setTimeout(() => this.checkLeftMenuScroll());
  }

  public onOpenSelectStartPage(event): void {
    event.preventDefault();
    event.stopPropagation();
    const modal = this.modalService.open(StartPageDialogComponent, {
      ...modalBaseOptions,
      windowClass: 'dc-modal faq-modal modal-window'
    });

    (<StartPageDialogComponent>modal.componentInstance).menuItems = this.menuItems;
    (<StartPageDialogComponent>modal.componentInstance).startPageSelected = this.startPageSelected;

    modal.result.then(
      (result: 0 | { menuItem: string, selectedPageValue: string }) => {
        if (result !== 0) {
          this.startPageSelected = result.selectedPageValue;
          this.onStartPageChange(result.menuItem);
        }
      }
    );
  }

  private onStartPageChange(menuItem): void {
    this.userSettings.start_page = menuItem.link;
    this.personalDataService.updateUserSettings(this.userSettings)
      .pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe(() => this.notify.success('Успешно!', 'Данные успешно сохранены'));
  }

  private addPayment(): void {
    const turnAU = this.MENU_ITEMS.admin_of_user.tuning;
    const turnAD = this.MENU_ITEMS.admin_of_direction.tuning;
    let accessAU = true;
    let accessAD = true;
    for (const item of turnAU) {
      if (item.link === '/payment') {
        accessAU = false;
      }
    }
    for (const item of turnAD) {
      if (item.link === '/payment') {
        accessAD = false;
      }
    }
  }

  private checkLeftMenuScroll(): void {
    this.isMenuCollapsedScroll =
      this.leftMenuElm.nativeElement.scrollHeight > this.leftMenuElm.nativeElement.clientHeight;
  }

  private getUserSettings(): void {
    this.personalDataService.getUserSettings()
      .pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe(
      (settings: UserSettings) => {
        this.userSettings = settings;

        const foundIndex = this.menuItems.findIndex(item => item.link === settings.start_page);

        if (foundIndex < 0) {
          this.startPageSelected = 'value-0';
        } else {
          this.startPageSelected = `value-${foundIndex}`;
        }
      });
  }
}
