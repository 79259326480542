import { Component, OnDestroy, OnInit } from '@angular/core';

import { ROLES, RolesEnum } from '@app/shared/constants/roles.constants';
import { ChatService } from '../services/chat.service';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DCTreeItem } from '@app/+competence-map/models/competence-map.models';
import { onlyActiveItems } from '@app/+competence-map/helpers/competence-map.helpers';
import { deepCopy } from '@app/shared/utils';
import { ChatSectionsEnum } from '@app/chat/constants/chat-sections.constants';
import { ChatSection } from '@app/chat/models/chat.model';
import { UserTypes } from '@app/shared/types/user.types';
import { CompetenceService } from '@app/+competence-map/services/competence.service';
import { CMCatalogTypes } from '@app/+competence-map/constants/sections.constants';

@Component({
  selector: 'app-chat-contacts-only-filter',
  templateUrl: './chat-contacts-only-filter.component.html',
  styleUrls: ['./chat-contacts-only-filter.component.scss'],
})
export class ChatContactsOnlyFilterComponent implements OnInit, OnDestroy {
  roles = ROLES;
  goods: DCTreeItem[];
  services: DCTreeItem[];

  rolesSelected: string[];
  competenciesSelected: number[];
  chatSection: ChatSection;
  chatSectionsEnum = ChatSectionsEnum;

  private destroyed$: Subject<boolean> = new Subject();

  constructor(public chatService: ChatService, private competenceService: CompetenceService) {
    forkJoin([
      this.competenceService.getTree(CMCatalogTypes.GOODS),
      this.competenceService.getTree(CMCatalogTypes.SERVICES),
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe((competencies: DCTreeItem[][]) => {
        this.goods = onlyActiveItems(deepCopy(competencies[0]));
        this.services = onlyActiveItems(deepCopy(competencies[1]));
      });

    this.rolesSelected = this.chatService.contactsOnlyFilterChanged.value.roles;
    this.competenciesSelected = this.chatService.contactsOnlyFilterChanged.value.competencies;
  }

  ngOnInit() {
    this.chatSection = this.chatService.getChatSectionSelected();

    if (this.chatSection.name === ChatSectionsEnum.ADMIN) {
      this.roles = [...this.roles].filter(
        (role) =>
          role.value === RolesEnum.SUPERUSER ||
          role.value === RolesEnum.EXPERT ||
          role.value === RolesEnum.ACCOUNTANT ||
          role.value === RolesEnum.PARTNER
      );

      this.roles.push({
        title: 'ОТП',
        shortTitle: 'ОТП',
        value: RolesEnum.TSO as UserTypes,
      });
    }

    if (this.chatSection.name === ChatSectionsEnum.HOLDING) {
      this.roles = [...this.roles].filter(
        (role) =>
          role.value === RolesEnum.ADMIN_OF_DIRECTION ||
          role.value === RolesEnum.ADMIN_OF_USER ||
          role.value === RolesEnum.OPERATOR
      );
    }

    this.roles.forEach((role) => (role.selected = this.rolesSelected.includes(role.value)));
  }

  changeRoles() {
    this.rolesSelected = [...this.roles].filter((role) => role.selected).map((role) => role.value);
  }

  changeCompetencies(event) {
    this.competenciesSelected = event.value;
  }

  onCancelEdit() {
    this.chatService.toggleContactsOnlyFilter(false);
  }

  onSubmit() {
    this.chatService.contactsOnlyFilterChanged.next({
      roles: this.rolesSelected,
      competencies: this.competenciesSelected,
    });

    this.chatService.toggleContactsOnlyFilter(false);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
