import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accessUsersSort',
})
export class AccessUsersSortPipe implements PipeTransform {
  transform(array, owner_id: number, field = 'second_name'): unknown {
    return array.sort((a, b) => {
      if (a.id === owner_id && b.id !== owner_id) {
        return -1;
      }

      if (a.id !== owner_id && b.id === owner_id) {
        return 1;
      }

      return a[field].localeCompare(b[field], 'ru', { sensitivity: 'base' });
    });
  }
}
