import { Component, InjectionToken, Input } from '@angular/core';

export const FORM_FIELD = new InjectionToken<FormFieldWrapperComponent>('FormFieldWrapperComponent')

@Component({
  selector: 'dc-form-field-wrapper',
  templateUrl: './form-field-wrapper.component.html',
  styleUrls: ['./form-field-wrapper.component.scss'],
  providers: [{provide: FORM_FIELD, useExisting: FormFieldWrapperComponent}]
})
export class FormFieldWrapperComponent {
  @Input()
  required: boolean = false;

  @Input()
  id?: string;

  @Input()
  vertically: boolean = false;

  @Input()
  style: 'bootstrap' | 'material' = 'material';
}
