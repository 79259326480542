import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth.service';
import { CalendarService } from './calendar.service';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { EventFilterModel, EventModel } from '@app/shared/components/calendar-widget/constants/calendar.constants';
import { map, takeUntil } from 'rxjs/operators';
import {
  BACKEND_DATE_FORMAT,
  BACKEND_DATE_TIME_FORMAT,
  BACKEND_TIME_FORMAT,
} from '@app/shared/constants/date.constants';

@Injectable()
export class CalendarDataService {
  url: string = environment.api_url;
  updateDataEvent = new Subject<any>();
  openMonthPlannerEvent = new Subject<any>();
  openWeekPlannerEvent = new Subject<any>();
  updateDatePickerEvent = new Subject<any>();
  updateDataAllEvent = new Subject<any>();
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  timeZoneOffset = 0;

  constructor(private http: HttpClient, private authService: AuthService, private calendarService: CalendarService) {
    this.authService.userStream.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
      this.timeZoneOffset = user.timezone_offset;
    });
  }

  updateData(obj) {
    this.updateDataEvent.next(obj);
  }

  openWeekPlanner(obj) {
    this.openWeekPlannerEvent.next(obj);
  }

  openMonthPlanner(obj) {
    this.openMonthPlannerEvent.next(obj);
  }

  updateDatepicker(obj) {
    this.updateDatePickerEvent.next(obj);
  }

  updateDataAll() {
    this.updateDataAllEvent.next(null);
  }

  getEventsByPeriod(filter: EventFilterModel) {
    let filterState = '';
    if (filter.state?.length) {
      filterState = `,{"name":"status","op":"in_","val":["${filter.state.join('","')}"]}`;
    }
    filter.startDate = moment(filter.startDate, BACKEND_DATE_FORMAT).add(-1, 'days').format(BACKEND_DATE_FORMAT);
    filter.endDate = moment(filter.endDate, BACKEND_DATE_FORMAT).add(1, 'days').format(BACKEND_DATE_FORMAT);
    return this.http
      .get(
        `${this.url}/calendar/events?filter=[{"name":"start_date","op":"between","val":["${filter.startDate}","${filter.endDate}"]}${filterState}]`
      )
      .pipe(
        map((events: EventModel[]) => {
          return events.map((event) => {
            if (event.start_time) {
              const newStartDate = moment(`${event.start_date} ${event.start_time}`, BACKEND_DATE_TIME_FORMAT).add(
                this.timeZoneOffset,
                'minutes'
              );
              event.start_date = newStartDate.format(BACKEND_DATE_FORMAT);
              event.start_time = newStartDate.format(BACKEND_TIME_FORMAT);
            }
            if (event.end_time) {
              const newStartDate = moment(`${event.end_date} ${event.end_time}`, BACKEND_DATE_TIME_FORMAT).add(
                this.timeZoneOffset,
                'minutes'
              );
              event.end_time = newStartDate.format(BACKEND_DATE_FORMAT);
              event.end_time = newStartDate.format(BACKEND_TIME_FORMAT);
            }
            return event;
          });
        })
      );
  }

  updateSaveFilter(state, type) {
    const data = {
      calendar_settings: {
        filter: state.join(','),
        type,
      },
    };
    this.http.put(`${environment.api_url}/user/settings`, data).subscribe(() => {});
  }

  getSaveFilter() {
    return this.http.get(`${environment.api_url}/user/settings`);
  }

  addEvent(formValue: Partial<EventModel>, id, repeat = false) {
    formValue.start_date && (formValue.start_date = formValue.start_date.split('.').reverse().join('-'));
    formValue.end_date && (formValue.end_date = formValue.end_date.split('.').reverse().join('-'));
    !formValue.start_time && delete formValue.start_time;
    if (!formValue.end_date) {
      delete formValue.end_date;
      delete formValue.end_time;
    } else {
      !formValue.end_time && delete formValue.end_time;
    }
    if (formValue.start_time) {
      const newStartDate = moment(`${formValue.start_date} ${formValue.start_time}`, BACKEND_DATE_TIME_FORMAT).add(
        -this.timeZoneOffset,
        'minutes'
      );
      formValue.start_date = newStartDate.format(BACKEND_DATE_FORMAT);
      formValue.start_time = newStartDate.format(BACKEND_TIME_FORMAT);
    }
    if (formValue.end_time) {
      const newStartDate = moment(`${formValue.end_date} ${formValue.end_time}`, BACKEND_DATE_TIME_FORMAT).add(
        -this.timeZoneOffset,
        'minutes'
      );
      formValue.end_time = newStartDate.format(BACKEND_DATE_FORMAT);
      formValue.end_time = newStartDate.format(BACKEND_TIME_FORMAT);
    }

    if (id) {
      return this.http.patch<any>(`${environment.api_url}/calendar/event${repeat ? '/repeat' : ''}/${id}`, formValue);
    } else {
      return this.http.post<any>(`${environment.api_url}/calendar/events`, formValue);
    }
  }

  getEventsById(id: number) {
    return this.http.get(`${this.url}/calendar/event/${id}`).pipe(
      map((event: EventModel) => {
        if (event.start_time) {
          const newStartDate = moment(`${event.start_date} ${event.start_time}`, BACKEND_DATE_TIME_FORMAT).add(
            this.timeZoneOffset,
            'minutes'
          );
          event.start_date = newStartDate.format(BACKEND_DATE_FORMAT);
          event.start_time = newStartDate.format(BACKEND_TIME_FORMAT);
        }
        if (event.end_time) {
          const newStartDate = moment(`${event.end_date} ${event.end_time}`, BACKEND_DATE_TIME_FORMAT).add(
            this.timeZoneOffset,
            'minutes'
          );
          event.end_time = newStartDate.format(BACKEND_DATE_FORMAT);
          event.end_time = newStartDate.format(BACKEND_TIME_FORMAT);
        }
        return event;
      })
    );
  }

  setEvent(data: any) {
    const user_id = this.authService.user_id;
    data.author = {
      id: user_id,
    };

    return this.http.post(`${this.url}/calendar/events`, data);
  }

  updateEvent(id: number, data) {
    return this.http.patch(`${this.url}/calendar/event/${id}`, data);
  }
}
