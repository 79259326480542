import { Lot } from '../models/lots.model';
import { CriteriaType } from '../types/trades.types';

export const VALID_WEIGHT_VALUE = 10;
export const ONE_VALUE = 1;

export const DEFAULT_NOMENCLATURE_UNIT = null;

export const DECIMAL_PIPE_PARAMETER = '1.0-2';

export const MIN_LOTS_LENGTH = 1;
export const MAX_LOTS_LENGTH = 10;

export const MIN_NOMENCLATURES_LENGTH = 1;
export const MAX_NOMENCLATURES_LENGTH = 100;

export const MAX_LOT_TITLE_LENGTH = 200;

export const MAX_NOMENCLATURE_TITLE_LENGTH = 100;

export const MIN_CRITERIA_LENGTH = 1;
export const MAX_CRITERIA_LENGTH = 20;

export const MAX_CRITERIA_TITLE_LENGTH = 100;
export const MAX_VIEW_CRITERIA_TITLE_LENGTH = 64;
export const MAX_CRITERIA_COMMENT_LENGTH = 300;

export const MIN_WEIGTH_VALUE = 0.01;
export const MAX_WEIGTH_VALUE = 10;
export const DELAY_VALUE_SEND_SUPPLIER_LOTS_IN_MINUTES = 15;

export const MIN_MAX_REGEXP = /^\-?(([0-9]{1,12})(\.[0-9]{0,4}){0,1})?$/;

export enum CriteriaTypesEnum {
  NUMBERS = 'float',
  DATES = 'date',
  YES_NO = 'bool',
}

export enum CriteriaVectorEnum {
  LESS_BETTER = 'from_smaller_to_larger',
  MORE_BETTER = 'from_larger_to_smaller',
  CLEAR = '',
}

export const LOTS: Lot[] = [
  {
    number: 1,
    title: 'Первый лот',
    weight: 10,
    extra: {
      expanded: false,
      criteriaExpanded: false,
      weight: 10,
      number: 1,
      title: 'Первый лот',
      criteria: [
        {
          title: 'Критерий 1',
          best_direction: '',
          type: CriteriaTypesEnum.NUMBERS,
          weight: 10,
          unit_id: 1,
        },
      ],
      nomenclatures: [
        {
          title: 'Фарш 1 кг',
          weight: 10,
          unit_id: 1,
          selected: false,
          criteria: [
            {
              min: '4',
              max: '6',
              best_direction: CriteriaVectorEnum.LESS_BETTER,
              weight: 10,
              comment: 'Комментарий',
            },
          ],
        },
      ],
    },
    nomenclatures: [
      {
        title: 'Фарш 1 кг',
        weight: 10,
        unit_id: 1,
        criteria: [
          {
            min: '4',
            max: '6',
            title: 'Процент',
            type: CriteriaTypesEnum.NUMBERS,
            best_direction: CriteriaVectorEnum.LESS_BETTER,
            weight: 10,
            unit_id: 1,
            comment: 'Комментарий',
            sort: 0,
          },
        ],
      },
    ],
  },
];

export interface CriteriaTypeMap {
  id: CriteriaType | null;
  name: string;
}

export const CRITERIA_TYPES: CriteriaTypeMap[] = [
  {
    id: null,
    name: '',
  },
  {
    id: CriteriaTypesEnum.NUMBERS,
    name: 'Числа',
  },
  {
    id: CriteriaTypesEnum.DATES,
    name: 'Даты',
  },
  {
    id: CriteriaTypesEnum.YES_NO,
    name: 'Нет/Да',
  },
];
