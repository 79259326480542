import { Injectable, Injector } from '@angular/core';

import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { ConnectionPositionPair, Overlay, OverlayConfig, PositionStrategy } from '@angular/cdk/overlay';

import { PopoverParams, PopoverRef } from '../helpers/popover-helper';
import { PopoverComponent } from '../components/popover/popover.component';

@Injectable()
export class PopoverService {
  private static getPositions(): ConnectionPositionPair[] {
    return [
      {
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'center',
      },
      {
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'center',
      },
    ];
  }
  constructor(private overlay: Overlay, private injector: Injector) {}

  open<T>({ origin, content, data, width = 200, height = 200, config }: PopoverParams<T>): PopoverRef<T> {
    const overlayRef = this.overlay.create(this.getOverlayConfig({ origin, width, height, config }));
    const popoverRef = new PopoverRef<T>(overlayRef, content, data);

    const injector = this.createInjector(popoverRef, this.injector);
    overlayRef.attach(new ComponentPortal(PopoverComponent, null, injector));

    return popoverRef;
  }

  createInjector(popoverRef: PopoverRef, injector: Injector) {
    const tokens = new WeakMap([[PopoverRef, popoverRef]]);
    return new PortalInjector(injector, tokens);
  }

  private getOverlayConfig({ origin, width, height, config }): OverlayConfig {
    return new OverlayConfig({
      hasBackdrop: true,
      width,
      height,
      backdropClass: 'popover-backdrop',
      positionStrategy: config ? this.getOverlayModalPosition(origin, config) : this.getOverlayPosition(origin),
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });
  }

  private getOverlayModalPosition(origin: HTMLElement, config: any): PositionStrategy {
    return this.overlay
      .position()
      .flexibleConnectedTo(origin)
      .withPositions([config])
      .withFlexibleDimensions(true)
      .withPush(true);
  }

  private getOverlayPosition(origin: HTMLElement): PositionStrategy {
    return this.overlay
      .position()
      .flexibleConnectedTo(origin)
      .withPositions(PopoverService.getPositions())
      .withFlexibleDimensions(true)
      .withPush(true);
  }
}
