import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'month',
})
export class MonthPipe implements PipeTransform {
  transform(value: number): string {
    return moment.monthsShort(value);
  }
}
