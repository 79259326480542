<div class="view-month">
  <table>
    <tbody>
    <tr #firstRowMonth>
      <td>
        <div class="week-header">
          Нед.
        </div>
      </td>
      <td colspan="7"></td>
    </tr>
    <ng-container *ngFor="let week of inputCalendarData">
      <tr class="view-month-days">
        <td class="view-month-days-week-number pointer" [ngClass]="{ 'week-current': nowWeek == week.number && currentDayOnCalendar }"
            (click)="openWeekPlanner(week)">
          {{ week.number }}
        </td>
        <td
          *ngFor="let day of week.days; let i = index"
          [class]="day.class"
          [style.height.px]="heightTd"
          [ngClass]="{
              'week-current': (i === nowDayWeekNumber - 1 || nowWeek == week.number) && currentDayOnCalendar,
              'day-off': i > 4,
              'day-event': eventForDay[day.day] == 1,
              'day-event-end': eventForDay[day.day] == 2
            }"
        >
          <div class="view-month-days-day-body">
            <div class="view-month-days-day-body-header row">
              <div class="col-4">
                {{ day.dayOfWeek + ' ' + day.dayNumber }}
              </div>
              <div class="col-8">
                <app-svg-icon [height]="12" [width]="16" name="check" class="green"
                              *ngIf="eventForDay[day.day]?.state == state_day.FINISH">
                </app-svg-icon>
                <span *ngIf="eventForDay[day.day]?.list?.length">{{ eventForDay[day.day].list.length}}</span>
                <app-svg-icon
                  [height]="14"
                  [width]="14"
                  name="plus"
                  class="pointer change-color hover-color"
                  [ngClass]="!checkDay(day.dayForDatepicker) ? 'disabled' : ''"
                  (click)="checkDay(day.dayForDatepicker) && addEvent(day.dayForDatepicker)"></app-svg-icon>
              </div>
            </div>
            <ng-container *ngIf="eventForDay[day.day]?.list">
              <div
                *ngFor="let event of eventForDay[day.day].list.slice(0, maxLengthList)"
                [style.color]="state.UNDER_CONTROL == event.status ? '#fff' : ''"
                (click)="infoForDay($event, day)"
                class="view-month-days-day-body-events pointer"
                   [style.background-color]="!finishState.includes(event.status) ? setColor(event.status) : 'unset'">
                <span [ngbTooltip]="findStateHelper(event.status)" [style.background-color]="finishState.includes(event.status) ? setColor(event.status) : 'unset'">
                  {{event.start_time.substr(0, 5)}}</span
                >&nbsp;<span>{{event.title}}</span>
              </div>

              <app-svg-icon *ngIf="eventForDay[day.day].list.length > maxLengthList" (click)="infoForDay($event, day)"
                [height]="8" [width]="12" name="down" class="pointer low-height hover-color"></app-svg-icon>
            </ng-container>
          </div>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</div>
