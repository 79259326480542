import {
  CompetencyUpdate,
  ExpertCompetency,
  ExpertCompetencyCreate,
  ProviderCompetency,
  ProviderCompetencyCreate,
  ProviderCompetencyUpdate,
  TradeCompetency,
  TradeCompetencyCreate,
  TradeCompetencyUpdate,
  TreeSectionsAndFilters,
} from '../models/user-competency.model';
import { UserCompetencyStatuses, UserCompetencyTypes } from '../constants/user-competency.constants';
import { FilterTemplateTypes } from '../constants/filter-templates.constants';
import { doForAllSync } from './competence-map.helpers';

export class CmHelper {
  // TODO: требуется унификация, рефакторинг методов, многие делают тоже самое, но имеют чуть другой интерфейс
  static getParamsForCreateCompetencies(treeData: TreeSectionsAndFilters[], trade_id?: number): any {
    return {
      competencies: treeData.map((item) => {
        const result: ProviderCompetencyCreate | TradeCompetencyCreate = {
          node_in_tree_id: +item.id,
        };

        if (trade_id) {
          (result as TradeCompetencyCreate).trade_id = trade_id;
        }

        if (item.type === UserCompetencyTypes.FILTER) {
          if (item.instance_filter.type === FilterTemplateTypes.RANGE) {
            result.value_range_from = item.providerCompetency.value_range_from;
            result.value_range_to = item.providerCompetency.value_range_to;
          }

          if (item.instance_filter.type === FilterTemplateTypes.BOOL) {
            result.value_bool = item.providerCompetency.value_bool;
          }
        }

        return result;
      }),
    };
  }

  static getParamsForUpdateCompetencies(
    treeData: TreeSectionsAndFilters[],
    competencies: ProviderCompetency[] | TradeCompetency[],
    trade_id?: number
  ): any {
    return {
      competencies: treeData
        .filter(
          (item) => item.type === UserCompetencyTypes.FILTER && item.instance_filter.type !== FilterTemplateTypes.CHOICE
        )
        .map((item) => {
          const targetCompetence = competencies.find((el) => el.node_in_tree_id === item.id);
          const result: ProviderCompetencyUpdate | TradeCompetencyUpdate = {
            id: targetCompetence && targetCompetence.id,
            node_in_tree_id: targetCompetence && targetCompetence.node_in_tree_id,
            status: UserCompetencyStatuses.ACTIVE,
          };

          if (trade_id) {
            (result as TradeCompetencyUpdate).trade_id = trade_id;
          }

          if (item.instance_filter.type === FilterTemplateTypes.RANGE) {
            result.value_range_from = item.providerCompetency.value_range_from;
            result.value_range_to = item.providerCompetency.value_range_to;
          }

          if (item.instance_filter.type === FilterTemplateTypes.BOOL) {
            result.value_bool = item.providerCompetency.value_bool;
          }

          return result;
        }),
    };
  }

  static getParamsForDeleteCompetencies(
    treeData: TreeSectionsAndFilters[],
    competencies: ProviderCompetency[] | TradeCompetency[],
    trade_id?: number
  ): any {
    return {
      competencies: treeData.map((item) => {
        const targetCompetence = competencies.find((el) => el.node_in_tree_id === item.id);
        const result: ProviderCompetencyUpdate | TradeCompetencyUpdate = {
          id: targetCompetence && targetCompetence.id,
          node_in_tree_id: targetCompetence && targetCompetence.node_in_tree_id,
          status: UserCompetencyStatuses.ARCHIVE,
        };

        if (trade_id) {
          (result as TradeCompetencyUpdate).trade_id = trade_id;
        }

        return result;
      }),
    };
  }

  static getNewCompetencies<T extends { node_in_tree_id: number }>(
    selectedData: TreeSectionsAndFilters[],
    competencies: T[]
  ): TreeSectionsAndFilters[] {
    const result: TreeSectionsAndFilters[] = [];

    doForAllSync(selectedData, (childItem: TreeSectionsAndFilters) => {
      if (childItem.selected && isNew(childItem)) {
        result.push(childItem);
      }
    });

    function isNew(childItem: TreeSectionsAndFilters): boolean {
      return !competencies.map((el) => el.node_in_tree_id).includes(childItem.id);
    }

    return result;
  }

  static getExistsCompetencies(
    selectedData: TreeSectionsAndFilters[],
    competencies: ProviderCompetency[]
  ): TreeSectionsAndFilters[] {
    const result = [];
    /*
      Редактировать имеет смысл только фильтры range и bool, фильтр choice и секции только добавляются/удаляются
    */

    doForAllSync(selectedData, (childItem: TreeSectionsAndFilters) => {
      if (
        childItem.selected &&
        childItem.type === UserCompetencyTypes.FILTER &&
        isExists(childItem) &&
        hasFilterChanges(childItem)
      ) {
        result.push(childItem);
      }
    });

    function hasFilterChanges(childItem: TreeSectionsAndFilters): boolean {
      const competency = competencies.find((el) => el.node_in_tree_id === childItem.id);

      if (childItem.instance_filter.type === FilterTemplateTypes.RANGE) {
        return (
          childItem.providerCompetency.value_range_from !== competency.value_range_from ||
          childItem.providerCompetency.value_range_to !== competency.value_range_to
        );
      }

      if (childItem.instance_filter.type === FilterTemplateTypes.BOOL) {
        return childItem.providerCompetency.value_bool !== competency.value_bool;
      }
    }

    function isExists(childItem: TreeSectionsAndFilters): boolean {
      return competencies.map((el) => el.node_in_tree_id).includes(childItem.id);
    }

    return result;
  }

  static getDeletedCompetencies(
    selectedData: TreeSectionsAndFilters[],
    competencies: ProviderCompetency[]
  ): TreeSectionsAndFilters[] {
    const savedIdsSet = new Set(competencies.map((el) => el.node_in_tree_id));
    const result = [];

    doForAllSync(selectedData, (childItem: TreeSectionsAndFilters) => {
      if (!childItem.selected && savedIdsSet.has(childItem.id)) {
        result.push(childItem);
      }
    });

    return result;
  }

  /*
    For expert competencies
  */

  static getParamsForCreateExpertCompetencies(treeData: TreeSectionsAndFilters[]): any {
    return {
      competencies: treeData.map((item) => {
        const result: ExpertCompetencyCreate = {
          section_id: +item.section.id,
        };

        return result;
      }),
    };
  }

  static getParamsForDeleteExpertCompetencies(
    treeData: TreeSectionsAndFilters[],
    competencies: ExpertCompetency[]
  ): any {
    return {
      competencies: treeData.map((item) => {
        const targetCompetence = competencies.find((el) => +el.section_id === +item.section.id);
        const result: CompetencyUpdate = {
          id: targetCompetence && targetCompetence.id,
          status: UserCompetencyStatuses.ARCHIVE,
        };

        return result;
      }),
    };
  }

  static getNewExpertCompetencies<T extends { section_id: number }>(
    selectedData: TreeSectionsAndFilters[],
    competencies: T[]
  ): TreeSectionsAndFilters[] {
    const result = [];

    doForAllSync(selectedData, (childItem: any, nodes, i, parent) => {
      if (
        childItem.selected &&
        !parent?.selected &&
        !competencies.map((el) => el.section_id).includes(childItem.section_id) &&
        !result.map((resItem) => resItem.id).includes(childItem.parent_id)
      ) {
        result.push(childItem);
      }
    });

    return result;
  }

  static getDeletedExpertCompetencies(
    selectedData: TreeSectionsAndFilters[],
    competencies: any[]
  ): TreeSectionsAndFilters[] {
    const result = [];

    doForAllSync(selectedData, (childItem: any, nodes, i, parent) => {
      if (
        !childItem.selected &&
        !parent?.selected &&
        competencies.map((el) => el.section_id).includes(childItem.section_id) &&
        !result.map((resItem) => resItem.id).includes(childItem.parent_id)
      ) {
        result.push(childItem);
      }
    });

    return result;
  }
}
