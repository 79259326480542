import { BaseUser } from './base-user.model';
import { UserStatusesTypes } from '../types/user-statuses.types';
import { UserTypes } from '../types/user.types';
import { Company } from './company.model';

interface UserFlags {
  is_can_be_payer: boolean;
  is_ready_tso: boolean;
  is_tso: boolean;
  is_duty_tso: boolean;
  is_tso_temporarily_blocked: boolean;
  is_owner: boolean;
  is_payer: boolean;
  is_confirmed: boolean;
  // Право на запуск торгов у АП/АН – неотъемлемо.
  is_can_start_trade: boolean;
  is_accepting_as_tso: boolean;
  is_tso_for_tech_chat: boolean;
  password_auto: boolean;
  is_right_of_possession?: boolean;
}

export class User extends BaseUser {
  birthday?: string;
  company?: Company;
  company_id?: number;
  has_password: boolean;
  is_owner: boolean;
  parent_id: number;
  status: UserStatusesTypes;
  type: UserTypes;
  timezone_offset?: number;
  parent?: User;
  competence_sections?: any[];
  competenciesString?: string;

  created_at: string;
  modified_at: string;
  tso?: User;
  tso_id: number;
  typeLabel?: string;

  flags: UserFlags;

  // TODO: need move to other model
  room_id?: string;
  isCrownActive?: boolean;

  group_room_id?: string;
  title: string;
  full_name: string;

  constructor(data) {
    super(data);
    this.company = this.company || new Company();
  }

  set newCompany(data) {
    this.company = new Company(data);
  }
}

export interface Role {
  title: string;
  value: UserTypes;
  fullTitle?: string;
  shortTitle: string;
  selected?: boolean;
}

export interface TradeInfo {
  tariff_sum?: number;
  trades_count?: number;
}

export interface ParamsApiUser {
  include?: string;
  'field[company]'?: string;
  'field[user]'?: string;
  'filter[type]'?: UserTypes;
}
