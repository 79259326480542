import {Component, EventEmitter, OnInit, Output} from '@angular/core';


@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.sass'],
})

export class SearchBarComponent implements OnInit {
  @Output('onTypingEvent') onTypingEvent = new EventEmitter<string>();
  constructor() {}

  ngOnInit() {}

  onTyping(e): void {
    this.onTypingEvent.next(e.target.value || '');
  }
}
