import { Component, Output, EventEmitter, Input } from '@angular/core';

import { Project } from '@app/+competence-map/models/projects.models';
import { DCTreeItem } from '@app/+competence-map/models/competence-map.models';
import { CMProjectStatuses } from '@app/+competence-map/constants/projects.constants';

import { ChangeStatusWarningComponent } from '@app/+competence-map/competence-shared/change-status-warning/change-status-warning.component';

import { PortalService } from '@app/shared/services/portal.service';
import { UserTypes } from '@app/shared/types/user.types';
import { AuthService } from '@app/shared/services/auth.service';

export declare interface ProjectBase {
  addProject(status: CMProjectStatuses): void;
  updateProject(status: CMProjectStatuses): void;
  cancel(): void;
  save(status: CMProjectStatuses): void;
  agree(status: CMProjectStatuses): void;
  archive(): void;
}

@Component({
  template: '',
  providers: [PortalService],
})
export abstract class ProjectBaseComponent {
  @Input() project: Project;
  parent: DCTreeItem;

  @Output() reloadEvent = new EventEmitter();

  private currentDate = new Date();

  get isNew(): boolean {
    return !this.project || (this.project && !this.project.id);
  }

  get userAuthor(): UserTypes {
    if (this.project?.author?.type) {
      return this.project?.author?.type;
    } else {
      return this.authService.user_type;
    }
  }

  get projectDate(): string {
    if (this.project?.created_at) {
      return this.project?.created_at;
    } else {
      return this.currentDate.toISOString();
    }
  }

  get projectStatus(): CMProjectStatuses | string {
    return this.project?.status || '-';
  }

  get projectId(): number | string {
    return this.project?.id || '-';
  }

  protected constructor(protected portalService: PortalService, protected authService: AuthService) {}

  isDataDisabled() {
    return (
      this.project &&
      (this.project.status === CMProjectStatuses.ARCHIVE ||
        this.project.status === CMProjectStatuses.AGREED ||
        this.project.status === CMProjectStatuses.ON_AGREEMENT)
    );
  }

  isArchiveDisabled(): boolean {
    if (this.isNew) {
      return true;
    }
    return (
      this.project.status === CMProjectStatuses.ARCHIVE ||
      this.project.status === CMProjectStatuses.AGREED ||
      this.project.status === CMProjectStatuses.ON_AGREEMENT
    );
  }

  warnChangeArchiveStatus(cb: Function): void {
    if (!this.isNew) {
      const component = this.portalService.attach(ChangeStatusWarningComponent);

      component.instance.agreeEvent.subscribe(() => {
        cb();
      });
    }
  }
}
