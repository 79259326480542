import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';


import {City, Region, Country} from 'app/shared/models/location.model';
import {environment} from 'environments/environment';

@Injectable()
export class RequestsService {
  public user: Object = {};

  constructor(private http: HttpClient) {
  }

  handleLocationRequest(request_id: number, country: Country, region: Region, city: City, admin_comment = '', status: string) {
    const data = {
      admin_comment,
      location: {
        country,
        region,
        city
      },
      status
    };

    return this.http.put(`${environment.api_url}/request/locations/${request_id}`, data);
  }

  newLocationRequest(city: string, region: string, country: string, author_comment): Observable<any> {
    const data = {
      data: {
        city: {title: city},
        region: {title: region},
        country: {title: country}
      },
      author_comment
    };

    return this.http.post(`${environment.api_url}/requests/locations`, data).pipe(
      catchError((e) => {
        return observableThrowError(e);
      }));
  }

  getLocationRequests(): Observable<any> {
    return this.http.get(`${environment.api_url}/requests/locations`).pipe(
      catchError((e) => {
        return observableThrowError(e);
      }));
  }
}
