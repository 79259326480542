import { Component } from '@angular/core';
import { AuthService } from '@app/shared/services/auth.service';
import { NotificationsService } from 'angular2-notifications';
import { Validation } from '@app/shared/services/validation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserSettings } from '@app/shared/models/user-settings.model';
import { MENU_ITEMS } from '@app/shared/constants/menu-items.constants';
import { REDIRECT_AFTER_LOGIN } from '@app/auth/redirect-after-login.constants';
import { PersonalDataService } from '@app/shared/services/personal-data.service';

@Component({
  templateUrl: './confirm_resetpass.component.html',
  styleUrls: ['../auth.component.sass'],
})
export class ConfirmResetPassComponent {
  password = '';
  password_double = '';
  errors = <any>{};
  isShowPass = false;

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private notify: NotificationsService,
    private personalDataService: PersonalDataService
  ) {}

  confirm() {
    this.errors = {};
    const equals_pass_error = Validation.equalsPasswords(this.password, this.password_double);
    const pass_error = Validation.validatePassword(this.password, true);

    if (equals_pass_error.length) {
      this.errors.password_double = equals_pass_error;
      return;
    }
    if (pass_error.length) {
      this.errors.password = pass_error;
      return;
    }

    this.activatedRoute.queryParams.subscribe((params) => {
      const email = params['email'];
      const key = params['key'];
      if (email && key)
        this.authService.resetPassConform(email, key, this.password).subscribe(
          () => {
            this.notify.success('Успешно!', 'Добро пожаловать!');
            this.authService.updateUser().subscribe((user) => {
              this.personalDataService.getUserSettings().subscribe((settings: UserSettings) => {
                const menuItems = MENU_ITEMS[user.type].tuning as any;
                const found = menuItems.find((item) => item.link === settings.start_page);
                if (found && found.link) {
                  this.router.navigate([found.link]);
                } else {
                  this.router.navigate([REDIRECT_AFTER_LOGIN]);
                }
              });
            });
          },
          () => this.router.navigate(['signin'])
        );
    });
  }
}
