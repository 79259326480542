import { Pipe, PipeTransform } from '@angular/core';

// TODO: переместить в shared/cdk
@Pipe({
  name: 'cmMapper',
})
export class CmMapperPipe implements PipeTransform {
  transform<T, K>(value: T, mapper: (item: T, ...args: any[]) => K, ...args: any[]): K {
    return mapper(value, ...args);
  }
}
