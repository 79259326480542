<div class="container">
  <div class="row">
    <div class="col-md-4"></div>
    <div class="col-md-4">
      <section class="auth-form">
        <form role="auth" novalidate>
          <app-faq-tooltip tooltipName="auth__auth_reset" class="hint-right"></app-faq-tooltip>
          <h3>Восстановить пароль</h3>
          <div class="form-group" [ngClass]="{ 'has-danger': errors.email }">
            <input
              type="email"
              [(ngModel)]="email"
              placeholder="Электронная почта"
              name="email"
              class="form-control input-lg"
              appInputTrim
            />
            <div class="form-control-feedback" *ngFor="let err of errors.email">{{ err | translate }}</div>
          </div>
          <button type="submit" (click)="reset()" class="btn btn-lg btn-dc-primary btn-block">Восстановить</button>
          <button type="button" [routerLink]="['/signin']" class="btn btn-lg btn-dc-primary btn-block">Вход</button>
        </form>
      </section>
    </div>
    <div class="col-md-4"></div>
  </div>
</div>
