import {Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NotificationsService } from 'angular2-notifications';
import {
  EventFilterModel,
  EventModel, FINISH_STATE, LIST_REPEAT_SELECT,
  LIST_STATE,
  STATE
} from '@app/shared/components/calendar-widget/constants/calendar.constants';
import { ModalInjectorConstants } from '@app/modal-injector/constants/modal-injector.constants';
import { ModalInjectorService } from '@app/modal-injector/services/modal-injector.service';
import * as moment from 'moment';
import {BACKEND_DATE_FORMAT, DATE_FORMAT} from '@app/shared/constants/date.constants';
import { CalendarDataService } from '@app/shared/services/calendar/calendar-data.service';
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {STATE_DAY_PLANNER} from "@app/calendar/constants/calendar.constants";

@Component({
  selector: 'app-info-for-day',
  templateUrl: './info-for-day.component.html',
  styleUrls: ['./info-for-day.component.scss'],
})
export class InfoForDayComponent implements OnInit, OnDestroy {
  @Input() modalId: number;
  @Input() day: string;
  @Input() dayForDatepicker: string;
  @ViewChild('infoDayMain') private infoDayMain: ElementRef;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  title: string = '';
  eventList: EventModel[] = [];
  thisDayMoment: moment.Moment;
  listState = LIST_STATE;
  listRepeatType = LIST_REPEAT_SELECT;
  stateDay = STATE_DAY_PLANNER;
  state = STATE;
  maxHeightEvents = 250;
  currentStateDay = STATE_DAY_PLANNER.FINISH;
  firstRow = 52;
  data = <any>{};

  constructor(
    private http: HttpClient,
    private notify: NotificationsService,
    private modalInjectorService: ModalInjectorService,
    private calendarDataService: CalendarDataService
  ) {}

  @HostListener('document:mousemove', ['$event'])
  onCornerMove(event: MouseEvent) {
    this.maxHeightEvents = this.infoDayMain.nativeElement.offsetHeight - this.firstRow;
  }

  setColor(state) {
    return this.listState.find((item) => item.key == state).color;
  }

  addEvent() {
    this.modalInjectorService.addModal(ModalInjectorConstants.ADD_EVENT_CALENDAR, { day: this.dayForDatepicker, show: true, addEvent: true });
  }

  editEvent(eventModel, copy = false) {
    this.modalInjectorService.addModal(ModalInjectorConstants.ADD_EVENT_CALENDAR, { eventModel, show: true, addEvent: true, copy });
  }

  showEvent(event_id: number) {
    const parent = this.infoDayMain.nativeElement.offsetParent.getBoundingClientRect();
    this.modalInjectorService.addModal(ModalInjectorConstants.SHOW_EVENT_CALENDAR, {
      event_id,
      parent,
      classForChildren: 'show_event',
    });
  }

  updateData() {
    const dateForFilter = this.thisDayMoment.startOf('day').format(BACKEND_DATE_FORMAT),
      filterData: EventFilterModel = {
      startDate: dateForFilter,
      endDate: dateForFilter,
    };
    this.calendarDataService.getEventsByPeriod(filterData).subscribe((res: EventModel[]) => {
        this.eventList = res.filter(item => item.start_date === dateForFilter).sort(function(a, b) {
          return Number(a.start_time.replace(/:/g, '')) - Number(b.start_time.replace(/:/g, ''));
        });
        this.eventList.forEach(item => {
          if (this.currentStateDay === STATE_DAY_PLANNER.FINISH && !~FINISH_STATE.indexOf(item.status)) {
            this.currentStateDay = STATE_DAY_PLANNER.IN_PROGRESS;
          }
        })
      },
      (error) => {
        this.notify.error('Ошибка!', error);
        this.modalInjectorService.closeModal(this.modalId)
      });
  }

  getDateFrontFormat(date) {
    return moment(date, BACKEND_DATE_FORMAT).format(DATE_FORMAT);
  }

  deleteEvent(event) {
    this.modalInjectorService.addModal(ModalInjectorConstants.DELETE_EVENT, { show: true, event });
  }

  ngOnInit() {
    this.thisDayMoment = moment(this.day, BACKEND_DATE_FORMAT);
    this.title = this.thisDayMoment.format('DD MMMM YYYY') + ' год';
    this.updateData();
    this.calendarDataService.updateDataAllEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
      this.updateData();
    });
  }

  findStateHelper(state) {
    return this.listState.find(item => item.key == state).text
  }

  ngOnDestroy() {}
}
