import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'titleFrom'})

// TODO: добавить возможность выбора title/shortTitle
export class TitleFromPipe implements PipeTransform {
  transform(value: string, objects_list: { title: string, value: string, shortTitle?: string }[]): any {
    const obj = objects_list.find(el => el.value === value);
    return obj ? obj.shortTitle || obj.title : '???';
  }
}
