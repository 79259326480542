<div class="section-header">
  <div class="row section-header-info">
    <div class="col-6">
      <div class="section-header-row" appTestLocator="union-section__author">
        <div class="section-header-label">Автор:</div>
        <div class="section-header-value">{{ userAuthor | roleName }}</div>
      </div>
      <div class="section-header-row" appTestLocator="union-section__date">
        <div class="section-header-label">Дата:</div>
        <div class="section-header-value">{{ projectDate | dateFormat }}</div>
      </div>
    </div>
    <div class="col-6">
      <div class="section-header-row" appTestLocator="union-section__project">
        <div class="section-header-label">Проект ID:</div>
        <div class="section-header-value">{{ projectId }}</div>
      </div>
      <div class="section-header-row" appTestLocator="union-section__status">
        <div class="section-header-label">Статус:</div>
        <div class="section-header-value d-flex align-items-center">
          {{ projectStatus | cmProjectStatus }}&nbsp;&nbsp;
          <app-svg-icon name="change" width="16" class="color-brand-light"></app-svg-icon>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="section-header-text" appTestLocator="union-section__title">
      Объединить раздел<br />
      {{ level }} уровня
    </div>
  </div>
  <div>
    <div
      class="section-header-archive"
      (click)="archive()"
      [class.disabled]="isArchiveDisabled()"
      appTestLocator="union-section__archive"
    >
      <app-svg-icon name="trash" width="16"></app-svg-icon>
      <span>Перенести в архив</span>
    </div>
  </div>
</div>
<form class="modal-body" [formGroup]="form">
  <div class="section-form">
    <div class="section-header-row section-path" appTestLocator="union-section__path">
      <div class="section-header-label">Путь:</div>
      <div class="section-header-value">{{ path }}</div>
    </div>
    <div class="row">
      <div class="col-4">
        <div class="mb-3">Название текущего раздела</div>
        <div class="mb-5">
          <dc-form-field-wrapper [style]="'material'" [vertically]="true" [required]="true">
            <dc-form-input>
              <input
                formInput
                [ngModel]="currentSection.title"
                [disabled]="true"
                [ngModelOptions]="{ standalone: true }"
                appTestLocator="union-section__current-section-input"
              />
            </dc-form-input>
          </dc-form-field-wrapper>
        </div>
      </div>
      <div class="col-4">
        <div class="mb-3">Добавить разделы</div>
        <app-multiple-livesearch-select
          appTestLocator="union-section__union-sections-select"
          *ngIf="list"
          [list]="list"
          [disabled]="isDataDisabled()"
          (selectEvent)="select($event)"
          placeholder="Введите или выберите из каталога"
        >
        </app-multiple-livesearch-select>
      </div>
      <div class="col-4">
        <div class="mb-3" appTestLocator="union-section__new-section_title">Название объединенного раздела</div>
        <div class="mb-3">
          <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="true">
            <dc-form-input [isShowLength]="true" [maxLength]="30">
              <textarea
                appTestLocator="union-section__new-section_textarea"
                formInput
                formControlName="newName"
                placeholder="Введите название объединенного раздела"
                required
              ></textarea>
            </dc-form-input>
          </dc-form-field-wrapper>
        </div>
      </div>
    </div>
  </div>
</form>

<app-operation-modal-footer
  (onCancel)="cancel()"
  (onSave)="save($event)"
  (onAgree)="agree($event)"
  [project]="project"
  [form]="form"
>
</app-operation-modal-footer>
