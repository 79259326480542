import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ITreeOptions, TreeComponent as TreeComponentLib, TreeModel, TreeNode } from '@circlon/angular-tree-component';
import { CompetenceService } from '@app/+competence-map/services/competence.service';
import { map } from 'rxjs/operators';
import { FilterTemplatesHelper } from '@app/+competence-map/helpers/filter-templates.helper';
import { FilterTemplate } from '@app/+competence-map/models/filter-templates.model';
import { FilterTemplateTypes } from '@app/+competence-map/constants/filter-templates.constants';

@Component({
  selector: 'app-union-tree-choice',
  templateUrl: './union-tree-choice.component.html',
  styleUrls: ['./union-tree-choice.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnionTreeChoiceComponent implements OnChanges {
  @Input() data: any[] = [];
  @Input() selectedNodes: number[] = [];
  @Input() selectedNodesForm: number[] = [];
  @Input() checkedSelectedNodes: number[] = [];
  @Input() filterValue: string = '';
  @Input() selectAll: boolean = false;
  @Input() disabled: boolean;
  @Output() selectEvent = new EventEmitter<number[]>();
  options: ITreeOptions = {
    getChildren: this.getChildren.bind(this),
    useCheckbox: true,
  };
  unique = (Math.random() * 1e8).toString(16);
  nodes: any[] = [];
  isLoadData: boolean = true;
  selectedItems: number[] = [];

  @ViewChild('tree', { static: true }) tree: TreeComponentLib;

  get filterTemplateTypes(): typeof FilterTemplateTypes {
    return FilterTemplateTypes;
  }

  get allCheckedStatus(): boolean {
    return (
      this.tree.treeModel?.roots
        ?.filter((item) => !this.checkedSelectedNodes.includes(item.data.node_id))
        .every((treeChild) => treeChild.isSelected === true) && this.selectedNodesForm.length > 0
    );
  }

  constructor(private competenceService: CompetenceService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { filterValue, data } = changes;

    if (this.isLoadData) {
      this.nodes = data.currentValue;
      if (this.nodes) {
        this.isLoadData = false;
      }
    }

    if (filterValue?.currentValue !== filterValue?.previousValue) {
      this.filterFn(filterValue.currentValue, this.tree?.treeModel);
    }

    this.tree.treeModel.update();
  }

  isNodeSelected(node: TreeNode): boolean {
    return this.selectedNodesForm.includes(node?.data?.node_id);
    // return node.isSelected;
  }

  select(node: TreeNode): void {
    const searchedId = this.selectedItems.indexOf(node.data.id || node.data.node_id);
    if (searchedId > -1) {
      node.setIsSelected(false);
      this.selectedItems.splice(searchedId, 1);
    } else {
      node.setIsSelected(true);
      this.selectedItems.push(node.data.node_id);
    }
    this.selectEvent.emit(Array.from(new Set([...this.selectedNodes, ...this.selectedItems])));
  }

  filterFn(value: string, treeModel: TreeModel) {
    if (treeModel?.nodes) {
      treeModel?.filterNodes((node: TreeNode) => fuzzySearch(value, node.data.title));
    }
  }

  changeAll(): void {
    const checkedStatus = this.allCheckedStatus;
    this.tree.treeModel.doForAll((node: TreeNode) => {
      node.setIsSelected(!checkedStatus);
    });

    this.selectedItems = Object.values(this.tree.treeModel.selectedLeafNodes)
      .filter((value) => value.isSelected === true)
      .map((item) => {
        return item.data.node_id;
      });

    this.selectEvent.emit(Array.from(new Set([...this.selectedNodes, ...this.selectedItems])));
  }

  getCheckedInitializeValue(node_id: number): boolean {
    return this.checkedSelectedNodes.includes(node_id);
  }

  private getChildren(node: TreeNode): Promise<FilterTemplate[]> {
    return this.competenceService
      .getTemplateStructureFilter({ node_id: node?.data?.id || node?.data?.node_id })
      .pipe(
        map((structureFilters) => {
          return structureFilters[0].childes.map((childrenStructureFilterList) => ({
            ...FilterTemplatesHelper.transformToFilterTemplate(childrenStructureFilterList),
          }));
        })
      )
      .toPromise();
  }
}

function fuzzySearch(needle: string, haystack: string) {
  const haystackLC = haystack.toLowerCase();
  const needleLC = needle.toLowerCase();

  const hLen = haystack.length;
  const nLen = needleLC.length;

  if (nLen > hLen) {
    return false;
  }
  if (nLen === hLen) {
    return needleLC === haystackLC;
  }
  outer: for (let i = 0, j = 0; i < nLen; i++) {
    const nch = needleLC.charCodeAt(i);

    while (j < hLen) {
      if (haystackLC.charCodeAt(j++) === nch) {
        continue outer;
      }
    }
    return false;
  }
  return true;
}
