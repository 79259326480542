import { cloneDeep } from 'lodash-es';
import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import {
  ProviderCompetency,
  TreeSectionsAndFilters,
  TreeSectionsAndFiltersItem,
} from '@app/+competence-map/models/user-competency.model';
import { CMCatalogTypes } from '@app/+competence-map/constants/sections.constants';
import { FilterTemplateTypes } from '@app/+competence-map/constants/filter-templates.constants';
import { UserCompetencyTypes } from '@app/+competence-map/constants/user-competency.constants';
import { Section } from '@app/+competence-map/models/sections.model';
import { CompetenceService } from '@app/+competence-map/services/competence.service';
import { ActivatedRoute } from '@angular/router';
import { FlaskQueryFilter } from '@app/shared/models/filters.model';
import { takeUntil, switchMap, tap, map } from 'rxjs/operators';
import { DestroyService } from '@app/services/destroy.service';
import { Observable, forkJoin } from 'rxjs';
import { TreeHelper } from '@app/shared/helpers/tree.helper';
import { GoodsResolver } from '@app/shared/resolvers/goods.resolver';
import { ServicesResolver } from '@app/shared/resolvers/services.resolver';

@Component({
  selector: 'app-competence-map-view',
  templateUrl: './competence-map-view.component.html',
  styleUrls: ['./competence-map-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class CompetenceMapViewComponent {
  private _competencies: ProviderCompetency[] = [];
  @Input() set competencies(value: ProviderCompetency[]) {
    this.clear();

    if (value?.length) {
      value.forEach((el) => {
        el.type === UserCompetencyTypes.FILTER ? this._selectedFilters.push(el) : this._competencies.push(el);
      });

      this.getCompetenceTreeWithFilters()
        .pipe(takeUntil(this.destroy$))
        .subscribe((filters) => {
          this.setFilters(filters);
          this.cdr.markForCheck();
        });
    }
  }

  competenceTree: Record<CMCatalogTypes, TreeSectionsAndFiltersItem[]>;

  // Максимальное количество фильтров в запросе
  private _maxQueryFiltersAmount = 15;
  private _selectedFilters: ProviderCompetency[] = [];
  private _goods: TreeSectionsAndFiltersItem[];
  private _services: TreeSectionsAndFiltersItem[];

  constructor(
    private competenceService: CompetenceService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private destroy$: DestroyService,
    private serviceResolver: ServicesResolver,
    private goodsResolver: GoodsResolver
  ) {
    this._goods = this.route.snapshot.data.goods;
    this._services = this.route.snapshot.data.services;
  }

  toggle(event: MouseEvent, item: TreeSectionsAndFiltersItem) {
    event.stopPropagation();
    item.expanded = !item.expanded;
  }

  isAllExpanded(item: TreeSectionsAndFiltersItem) {
    if (!item.expanded && item.children?.length) {
      return false;
    }

    if (item.children?.length) {
      for (let i = 0; i < item.children?.length; i++) {
        if (!this.isAllExpanded(item.children[i])) {
          return false;
        }
      }
    }

    return true;
  }

  toggleAll(event: MouseEvent, item: TreeSectionsAndFiltersItem) {
    event.stopPropagation();
    this.changeExpandAll(item, !item.expanded);
  }

  getSelectedFilter(filter: TreeSectionsAndFiltersItem): ProviderCompetency {
    return [FilterTemplateTypes.BOOL, FilterTemplateTypes.RANGE].includes(filter.instance_filter.type)
      ? this._selectedFilters?.find((f) => f.instance_filter.id === filter.instance_filter.id)
      : null;
  }

  isShowFilter(filter: TreeSectionsAndFiltersItem): boolean {
    const filterIds = TreeHelper.flatTree(filter, 'filters').map((el) => el.id);
    return !!this._selectedFilters?.find((f) => filterIds.includes(f.node_in_tree_id));
  }

  private changeExpandAll(item: TreeSectionsAndFiltersItem, expanded: boolean) {
    item.expanded = expanded;
    item.children?.forEach((child) => this.changeExpandAll(child, expanded));
    item.filters?.forEach((child) => this.changeExpandAll(child, expanded));
  }

  private getCompetenceTreeWithFilters() {
    if (this._goods && this._services) {
      return this.getCompetenceFilters();
    } else {
      return forkJoin([this.serviceResolver.resolve(), this.goodsResolver.resolve()]).pipe(
        tap(([services, goods]) => {
          this._goods = goods;
          this._services = services;
        }),
        switchMap(() => {
          return this.getCompetenceFilters();
        })
      );
    }
  }

  private getCompetenceFilters(): Observable<Record<number, TreeSectionsAndFiltersItem[]>> {
    const queryFiltersMap = this.buildTreeFromCompetencies();

    const statusFilter = { name: 'status_filter', op: 'eq', val: 'active' };
    const queries: Array<Observable<TreeSectionsAndFilters[]>> = [];

    /***
     * Разбиваем на несколько запросов, при отправке одним запросом
     * выдает ошибку на макимальную длину query парамметров
     */
    queryFiltersMap.forEach((queryFilters) => {
      queries.push(this.competenceService.getSectionsAndFilters([statusFilter, { or: queryFilters }]));
    });

    return forkJoin(queries).pipe(
      map((treeLists) => {
        const filters: Map<number, TreeSectionsAndFilters> = new Map();

        treeLists.forEach((treeList) => {
          treeList.forEach((tree) => {
            if (tree.type === UserCompetencyTypes.FILTER && !filters.has(tree.id)) {
              filters.set(tree.id, tree);
            }
          });
        });

        const filtersMap = {
          [CMCatalogTypes.GOODS]: {},
          [CMCatalogTypes.SERVICES]: {},
        };

        filters.forEach((filter) => {
          filter.expanded = true;
          filtersMap[filter.catalog][filter.tree_id]
            ? filtersMap[filter.catalog][filter.tree_id].push(filter)
            : (filtersMap[filter.catalog][filter.tree_id] = [filter]);
        });

        Object.values(filtersMap[CMCatalogTypes.GOODS]).forEach((filters) =>
          this.buildFiltersTree(<TreeSectionsAndFilters[]>filters)
        );
        Object.values(filtersMap[CMCatalogTypes.SERVICES]).forEach((filters) =>
          this.buildFiltersTree(<TreeSectionsAndFilters[]>filters)
        );

        return filtersMap;
      })
    );
  }

  private buildTreeFromCompetencies(): Map<number, FlaskQueryFilter[]> {
    const queryFiltersMap: Map<number, FlaskQueryFilter[]> = new Map<number, FlaskQueryFilter[]>([[0, []]]);
    const cloneGoods = cloneDeep(this._goods);
    const cloneServices = cloneDeep(this._services);

    this._competencies.forEach((competency) => {
      const section = (competency.section || competency) as Section;
      const catalog = section.catalog === CMCatalogTypes.GOODS ? cloneGoods : cloneServices;
      const tree = this.checkSelectedCompetencies(catalog, section, queryFiltersMap);

      if (!this.competenceTree[section.catalog].find((el) => el.id === tree.id)) {
        this.competenceTree[section.catalog].push(tree);
      }
    });

    return queryFiltersMap;
  }

  private checkSelectedCompetencies(
    trees: TreeSectionsAndFiltersItem[],
    section: Section,
    queryFiltersMap: Map<number, FlaskQueryFilter[]>
  ) {
    for (let i = 0; i < trees.length; i++) {
      if (trees[i].section.id === section.id) {
        TreeHelper.flatTree(trees[i]).forEach((el) => {
          el.selected = true;

          this.addQueryFilterForCompetency(el, queryFiltersMap);
        });
        return trees[i];
      } else {
        if (trees[i].children.length) {
          if (this.checkSelectedCompetencies(trees[i].children, section, queryFiltersMap)) {
            trees[i].selected = true;
            this.addQueryFilterForCompetency(trees[i], queryFiltersMap);
            return trees[i];
          }
        }
      }
    }
  }

  private addQueryFilterForCompetency(
    competence: TreeSectionsAndFiltersItem,
    queryFiltersMap: Map<number, FlaskQueryFilter[]>
  ) {
    const queryFilter = <FlaskQueryFilter>{
      and: [
        { name: 'left', op: 'gte', val: +competence.left },
        { name: 'right', op: 'lte', val: +competence.right },
        { name: 'tree_id', op: 'eq', val: +competence.tree_id },
      ],
    };

    const currentList = queryFiltersMap.get(queryFiltersMap.size - 1);
    currentList.length === this._maxQueryFiltersAmount
      ? queryFiltersMap.set(queryFiltersMap.size, [queryFilter])
      : currentList.push(queryFilter);
  }

  private setFilters(filtersMap: Record<number, TreeSectionsAndFiltersItem[]>) {
    Object.entries(this.competenceTree).forEach(([type, catalog]) =>
      catalog.forEach((el) => this.setFiltersToSection(filtersMap[type], el))
    );
  }

  private setFiltersToSection(
    filtersMap: Record<number, TreeSectionsAndFiltersItem[]>,
    section: TreeSectionsAndFiltersItem
  ) {
    section.filters = filtersMap[section.tree_id]?.filter((el) => el.parent_id === section.id) || [];

    if (section?.children?.length) {
      section.children.forEach((child) => this.setFiltersToSection(filtersMap, child));
    }
  }

  private buildFiltersTree(filters: TreeSectionsAndFilters[]) {
    const levelsMap: Map<number, Record<number, TreeSectionsAndFilters>> = new Map();

    filters
      .sort((a, b) => a.level - b.level)
      .forEach((filter) => {
        if (filter.level > filters[0].level) {
          const parentFilter = levelsMap.get(filter.level - 1)[filter.parent_id];
          if (parentFilter && !parentFilter.filters?.find((f) => f.id === filter.id)) {
            parentFilter.filters ? parentFilter.filters.push(filter) : (parentFilter.filters = [filter]);
          }
        }

        levelsMap.has(filter.level)
          ? (levelsMap.get(filter.level)[filter.id] = filter)
          : levelsMap.set(filter.level, { [filter.id]: filter });
      });
  }

  private clear() {
    this.competenceTree = {
      [CMCatalogTypes.GOODS]: [],
      [CMCatalogTypes.SERVICES]: [],
    };
    this._selectedFilters = [];
    this._competencies = [];
  }
}
