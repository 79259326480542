import { ChatService } from '@app/chat/services/chat.service';
import { Component, OnDestroy, Input, ElementRef, OnChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { SocketDataService } from '@app/services/socket-data.service';

@Component({
  selector: 'app-chat-tab',
  templateUrl: './chat-tab.component.html',
  styleUrls: ['./chat-tab.component.scss'],
})
export class ChatTabComponent implements OnDestroy, OnChanges {
  ngUnsubscribe: Subject<void> = new Subject<void>();
  @Input() tab: any;
  @Input() active: boolean = false;
  @Input() contactSelected;

  constructor(
    private elRef: ElementRef,
    private chatService: ChatService,
    private chatDataService: SocketDataService
  ) {}

  ngOnChanges(changes: any) {
    if (changes.active && changes.active.currentValue === true) {
      this.chatService.scrollMeToTab(this.elRef);
    }
  }

  onTabSelect() {
    if (this.tab.counter > 0) {
      this.chatDataService.setMessageRead(this.tab.room_id);
    }

    this.chatService.setRoomSelected(this.tab);
    this.chatService.setChatSectionSelected(this.tab.section);
  }

  getTabTitle(): string {
    if (!this.tab.first_name && !this.tab.second_name) {
      return this.tab.email || 'Без имени';
    } else {
      return `${this.tab.first_name || ''} ${this.tab.second_name || ''}`;
    }
  }

  onTabRemove($event: Event) {
    $event.stopPropagation();
    this.chatService.deleteTab(this.tab);
    this.chatService.goToRoot();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
