<form (ngSubmit)="onMessageFormSubmit()" [formGroup]="messageForm">
  <div class="form-group message">
    <div>
      <textarea
        #messageInput
        (keydown.enter)="$event.preventDefault(); onKeyDown()"
        class="form-control message-input"
        formControlName="message"
        type="text"
      ></textarea>
      <button class="file-send" [disabled]="!contactSelected.id" type="button" (click)="openFileManager()">
        <fa-icon [icon]="['fas', 'paperclip']"></fa-icon>
      </button>
      <button class="message-send" [class.disabled]="!messageForm.value.message || !contactSelected.id" type="submit">
        <fa-icon [icon]="['fas', 'paper-plane']"></fa-icon>
      </button>
    </div>

    <div>
      <span class="blue-links d-inline-flex" *ngFor="let file of files">
        <span class="mr-1">{{ file.name }}</span>
        <a (click)="removeFile(file)" class="mr-3">
          <fa-icon [icon]="['fas', 'times']"></fa-icon>
        </a>
      </span>
    </div>
  </div>
</form>

<!--<app-modal-window [isShowed]="isFileManagerOpened" (closeEvent)="closeFileManager()" customClass="file-manager-modal">-->
<!--  <app-file-manager-->
<!--    *ngIf="isFileManagerOpened"-->
<!--    (onExportSelected)="handleSelectedExport($event)"-->
<!--    [layout]="tradesLayouts.EXPORT"-->
<!--  ></app-file-manager>-->
<!--</app-modal-window>-->
