import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';
import { PersonalDataService } from './personal-data.service';
import { REDIRECT_AFTER_LOGIN, REDIRECT_TO_LOGIN } from '../../auth/redirect-after-login.constants';
import { MENU_ITEMS } from '../constants/menu-items.constants';

@Injectable()
export class IsLoggedIn implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (!this.authService.isLoggedIn()) {
      this.router.navigate([REDIRECT_TO_LOGIN]);
      return false;
    }
    return true;
  }
}

@Injectable()
export class IsLoggedOut implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (this.authService.isLoggedIn()) {
      this.router.navigate([REDIRECT_AFTER_LOGIN]);
      return false;
    }
    return true;
  }
}

@Injectable()
export class IsNotBlocked implements CanActivate {
  constructor(
    private router: Router,
    private personalDataService: PersonalDataService,
    private authService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.authService.userStream.pipe(
      map((user) => {
        if (user.status === 'blocked') this.router.navigate([REDIRECT_AFTER_LOGIN]);
        return user.status !== 'blocked';
      })
    );
  }
}

@Injectable()
export class HasAccess implements CanActivate {
  constructor(
    private router: Router,
    private personalDataService: PersonalDataService,
    private authService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.authService.userStream.pipe(
      map((user) => {
        const menuItems = MENU_ITEMS[user.type].tuning;

        if (!menuItems) {
          this.router.navigate([REDIRECT_AFTER_LOGIN]);
          return false;
        }

        return true;
      })
    );
  }
}
