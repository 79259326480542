import { interval as observableInterval, Observable } from 'rxjs';

import { share, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class ClockService {
  private readonly clock: Observable<Date>;

  constructor() {
    this.clock = observableInterval(1000).pipe(
      map(tick => new Date()),
      share()
    );
  }

  getClock(): Observable<Date> {
    return this.clock;
  }
}
