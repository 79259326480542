import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@app/shared/models/user.model';
import { DutyUser } from '@app/shared/models/duty.model';

@Pipe({
  name: 'usersField',
})
export class UsersFieldPipe implements PipeTransform {
  transform(value: User[] | DutyUser[], field: string, filterString: string): User[] | DutyUser[] {
    if (!filterString) {
      return value;
    }

    try {
      return value.filter((user) => user[field].toString().includes(filterString));
    } catch (e) {
      console.log(e);
    }
  }
}
