import {Directive, Input} from '@angular/core';
import {FormControl, NG_VALIDATORS} from '@angular/forms';
import {BACKEND_DATE_FORMAT} from '../constants/date.constants';
import * as moment from 'moment';
import {DatesHelper} from '../helpers/dates.helper';

@Directive({
  selector: '[minDateValue]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: MinDateValueDirective,
      multi: true
    }
  ]
})
export class MinDateValueDirective {
  @Input('minDateValue') minDateValue: any;

  constructor() {
  }

  validate(c: FormControl): {[key: string]: {valid: boolean}} {
    const v = c.value;
    const invalid = {maxDateValue: {valid: false}};

    if (!v) {
      return null;
    }

    if (!this.isDateValid(v)) {
      return invalid;
    }

    if (!this.minDateValue) {
      return null;
    }

    if (moment(v, BACKEND_DATE_FORMAT).valueOf() < moment(this.minDateValue, BACKEND_DATE_FORMAT).valueOf()) {
      return invalid;
    }

    return null;
  }

  isDateValid(date: string) {
    return moment(date, BACKEND_DATE_FORMAT).isValid();
  }

}
