import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort',
  pure: false
})
export class SortPipe implements PipeTransform {

  transform(value: any, propName: string, direction: number): any {
    if (direction === 0) return value;
    const args = direction === 1 ? [-1, 1] : [1, -1];
    direction = +direction;
    return value.sort((a, b) => {
      if (a[propName] > b[propName]) {
        return args[0];
      } else {
        return args[1];
      }
    });
  }

}
