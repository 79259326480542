import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  ITreeOptions,
  TREE_ACTIONS,
  TreeNode,
  TreeComponent as TreeComponentLib,
} from '@circlon/angular-tree-component';

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
})
export class TreeComponent implements OnInit {
  @Input() data: any[] = [];
  @Input() selected: TreeNode;

  @Input() selectable = false;
  @Input() searchEvent = new EventEmitter();

  @ViewChild('tree') private tree: TreeComponentLib;

  readonly TREE_ACTIONS = TREE_ACTIONS;

  options: ITreeOptions = {
    isExpandedField: 'expanded',
    nodeHeight: 22,
  };

  @Output() selectEvent = new EventEmitter<TreeNode>();

  ngOnInit() {
    this.searchEvent.subscribe((value) => {
      this.tree.treeModel.filterNodes((node) => {
        return node.data.title.toLowerCase().includes(value.toLowerCase());
      });
    });
  }

  select(node: TreeNode) {
    if (!this.selectable) {
      return;
    }

    this.selected = node;

    this.selectEvent.emit(this.selected);
  }
}
