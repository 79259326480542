import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FaqModalActions } from '../../../shared/enums/faq.common.enum';

@Component({
  selector: 'app-faq-image-modal',
  templateUrl: './faq-image-modal.component.html',
  styleUrls: ['./faq-image-modal.component.scss'],
})
export class FaqImageModalComponent implements OnInit {
  @Input() image: string;
  constructor(private activeModal: NgbModal) {}

  ngOnInit(): void {}

  close(reason = FaqModalActions.Close) {
    this.activeModal.dismissAll({ reason });
  }
}
