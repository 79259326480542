<div class="fileman-filter-container" *ngIf="_isOpen">
  <div class="text-center mb-4">
    <b>Настроить фильтр сотрудников</b>
  </div>
  <div class="filter-grid">
    <div class="filter-grid-header">
      <div class="filter-grid-header-title" (click)="active = 0" [ngClass]="{ active: active === 0 }">
        <h4>Роли</h4>
        <span>{{ getSelectedCountRoles() }}</span>
      </div>
      <div
        class="filter-grid-header-title filter-grid-header-title__bordered"
        (click)="active = 1"
        [class.active]="active === 1"
      >
        <h4>Компетенции в товарах</h4>
        <span>{{ getSelectedCount(goods) }}</span>
      </div>
      <div class="filter-grid-header-title" (click)="active = 2" [ngClass]="{ active: active === 2 }">
        <h4>Компетенции в услугах</h4>
        <span>{{ getSelectedCount(services) }}</span>
      </div>
      <div class="filter-grid-header-question">
        <button class="btn-link btn-link__info" (click)="openHelper()">
          <app-svg-icon [width]="16" name="question-circle-color"></app-svg-icon>
        </button>
      </div>
    </div>
    <div class="filter-grid-body">
      <div class="filter-grid-body-item" [class.hidden]="active !== 0">
        <div class="select-all margin-t-20">
          <input
            [checked]="selectedAllRoles"
            (change)="selectAllRoles($event)"
            class="cbx-input"
            [id]="'selected_all_roles'"
            type="checkbox"
          />
          <label class="cbx-label" [for]="'selected_all_roles'">
            <span class="margin-l-10">Выделить всё/снять выделение</span>
          </label>
        </div>
        <ul class="list-unstyled margin-t-20">
          <li *ngFor="let role of filter.roles" class="shared-users-list-item-checkbox">
            <a>
              <input [id]="role.value" class="cbx-input" type="checkbox" [(ngModel)]="role.checked" />
              <label class="cbx-label" [for]="role.value">
                <span class="cbx-label-text">{{ role.title }}</span>
              </label>
            </a>
          </li>
        </ul>
      </div>
      <app-share-filter-tree
        [active]="active"
        [counter]="1"
        [items]="goods"
        [type]="filterType.GOODS"
        [selected]="selectedGoods"
      >
      </app-share-filter-tree>
      <app-share-filter-tree
        [active]="active"
        [counter]="2"
        [items]="services"
        [type]="filterType.SERVICES"
        [selected]="selectedServices"
      >
      </app-share-filter-tree>
    </div>
  </div>
  <div class="d-flex fmfu-buttons">
    <div class="text-right margin-r-24">
      <button class="btn btn-dc-outline" (click)="cancel()">Отменить</button>
    </div>
    <div class="text-left">
      <button class="btn btn-dc-primary" (click)="apply()">Применить</button>
    </div>
  </div>
</div>
