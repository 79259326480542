import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Project } from '@app/+competence-map/models/projects.models';
import { CMActions, CMProjectStatuses } from '@app/+competence-map/constants/projects.constants';
import { CreateOrChangeFilterTemplateParams, FilterTemplate } from '@app/+competence-map/models/filter-templates.model';

import { DestroyService } from '@app/services/destroy.service';
import { AuthService } from '@app/shared/services/auth.service';
import { PortalService } from '@app/shared/services/portal.service';
import { CompetenceService } from '@app/+competence-map/services/competence.service';

import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, takeUntil } from 'rxjs/operators';

import { FilterTemplatesHelper } from '@app/+competence-map/helpers/filter-templates.helper';
import { ProjectBase, ProjectBaseComponent } from '@app/+competence-map/models/project.class';
import { FilterTemplateTypes } from '@app/+competence-map/constants/filter-templates.constants';

@Component({
  selector: 'app-union-filter-template',
  templateUrl: './union-filter-template.component.html',
  styleUrls: ['./union-filter-template.component.scss'],
  providers: [PortalService, DestroyService],
})
export class UnionFilterTemplateComponent extends ProjectBaseComponent implements OnInit, ProjectBase {
  unionForm: FormGroup;
  validForm: FormGroup;
  filterTemplateItems$: Observable<FilterTemplate[]>;

  filterTemplateSnapshot: FilterTemplate[];

  filterValue: string = '';
  unionFilterTree: FilterTemplate[] = [];
  firstTemplateFilterType: FilterTemplateTypes | null = null;

  private _rootCheckedTemplateFiltersIds: number[] = [];
  private _rootCheckedTemplateFiltersViewIds: number[] = [];
  private _templateFilter: FilterTemplate;

  @Output() closeEvent = new EventEmitter();
  @Output() reloadEvent = new EventEmitter();

  get rootCheckedTemplateFiltersIds(): number[] {
    return this._rootCheckedTemplateFiltersIds;
  }

  get rootCheckedTemplateFiltersViewIds(): number[] {
    return this._rootCheckedTemplateFiltersViewIds;
  }

  get formCheckedItems(): number[] {
    return this.unionForm.get('unionValues')?.value;
  }

  @Input() set templateFilter(value: FilterTemplate) {
    this.firstTemplateFilterType = value.type;

    this._rootCheckedTemplateFiltersIds.push(Number(value.id || value.node_id));
    this._rootCheckedTemplateFiltersViewIds.push(...(this.getRootChildrenViewIds(value) || []));
    this._templateFilter = value;
  }

  get templateFilter(): FilterTemplate {
    return this._templateFilter;
  }

  @Input() set projectData(value: Project) {
    this.project = value;

    if (this.project.spec[CMActions.UNION_FILTER]) {
      const { title, template_filters_ids } = this.project.spec[CMActions.UNION_FILTER];

      this.unionForm.patchValue({
        unionName: title,
      });

      if (this.project.status !== CMProjectStatuses.AGREED) {
        this._rootCheckedTemplateFiltersIds = template_filters_ids;
        this.selectTreeItem(template_filters_ids);

        this.competenceService
          .getTemplateStructureFilter({ template_filter_ids: [...template_filters_ids] })
          .pipe(takeUntil(this.destroy$))
          .subscribe((structureFilters) => {
            this.templateFilter = structureFilters.map((childrenStructureFilterList) =>
              FilterTemplatesHelper.transformToFilterTemplate(childrenStructureFilterList)
            )[0];

            this.initializeSearchFilterTemplate();
          });
      } else {
        this.showAgreedSnapshop();
      }
    }
  }

  constructor(
    private competenceService: CompetenceService,
    private fb: FormBuilder,
    private destroy$: DestroyService,
    protected authService: AuthService,
    protected portalService: PortalService
  ) {
    super(portalService, authService);
    this.initializeForm();
  }

  ngOnInit(): void {
    this.initializeSearchFilterTemplate();
  }

  showAgreedSnapshop() {
    const { template_filters_ids } = this.project.spec[CMActions.UNION_FILTER];

    if (this.project.object_to[CMActions.UNION_FILTER]) {
      const params = this.project.object_to[CMActions.UNION_FILTER] as CreateOrChangeFilterTemplateParams[];

      const filterTemplates = params.map((param) =>
        this.transformToFilterTemplate({
          ...param,
        })
      );

      this.filterTemplateSnapshot = filterTemplates;

      this.unionFilterTree = filterTemplates.map((template) => template.children).reduce((a, b) => [...a, ...b]);

      this._rootCheckedTemplateFiltersIds = template_filters_ids;
    }
  }

  transformToFilterTemplate(node: CreateOrChangeFilterTemplateParams): FilterTemplate {
    return {
      ...node.node_info,
      node_id: node.node_info.id,
      children: node.childes?.length ? node.childes.map((value) => this.transformToFilterTemplate(value)) : [],
    };
  }

  selectTreeItem(treeNodes: number[]): void {
    const filterTemplateChildes$: Observable<CreateOrChangeFilterTemplateParams[]>[] = [];
    if (treeNodes?.length > 0) {
      const unionRootTemplateFilterIds = [...treeNodes];
      this.unionForm.get('unionValues').setValue(unionRootTemplateFilterIds);
      const nodeIds = this.unionForm.get('unionValues').value;

      nodeIds.forEach((nodeId) => {
        filterTemplateChildes$.push(this.competenceService.getTemplateStructureFilter({ node_id: nodeId }));
      });

      forkJoin(filterTemplateChildes$)
        .pipe(take(1), takeUntil(this.destroy$))
        .subscribe((resultList) => {
          this.unionFilterTree = [];
          resultList.forEach((resultItem) => {
            if (this.firstTemplateFilterType === FilterTemplateTypes.RANGE) {
              this.unionFilterTree.push(FilterTemplatesHelper.transformToFilterTemplate(resultItem[0]));
            } else {
              this.unionFilterTree.push(
                ...resultItem[0].childes.map((value) => FilterTemplatesHelper.transformToFilterTemplate(value))
              );
            }
          });
        });
    } else {
      this.unionFilterTree = [];
    }
  }

  save(status: CMProjectStatuses): void {
    if (this.isNew) {
      this.addProject(status);
    } else {
      this.updateProject(status);
    }
  }

  agree(status: CMProjectStatuses): void {
    if (this.isNew) {
      this.addProject(status);
    } else {
      this.updateProject(status);
    }
  }

  archive(): void {
    this.warnChangeArchiveStatus(() => {
      this.updateProject(CMProjectStatuses.ARCHIVE);
    });
  }

  cancel(): void {
    this.unionForm.get('unionName').setValue('');
    this.unionForm.get('unionValues').setValue([]);
    this.selectTreeItem([]);
    this.closeEvent.emit();
  }

  addProject(status: CMProjectStatuses): void {
    const { unionName, unionValues } = this.unionForm.value;

    const sendData = {
      title: unionName,
      template_filters_ids: [...unionValues],
    };

    this.filterTemplateItems$
      .pipe(
        switchMap(() => {
          return this.competenceService.unionFilterSection(sendData);
        }),
        switchMap((project) => {
          if (status === CMProjectStatuses.AGREED || status === CMProjectStatuses.ON_AGREEMENT) {
            return this.competenceService.updateProject({ ...project, status });
          } else {
            return of(project);
          }
        }),
        catchError((err) => {
          this.competenceService.error(err);
          return EMPTY;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (project) => {
          this.competenceService.projectSuccess(status);
          this.project = project;
          this.reloadEvent.emit();
        },
        (httpError) => {
          this.competenceService.error(httpError);
        }
      );
  }

  updateProject(status: CMProjectStatuses): void {
    const { unionName, unionValues } = this.unionForm.value;
    const originalIds = this.project?.id ? [] : this.templateFilter.children.map((value) => value.node_id);

    const sendData = {
      title: unionName,
      template_filters_ids: [...originalIds, ...unionValues],
    };

    this.competenceService
      .updateProject({
        ...this.project,
        status,
        spec: {
          [CMActions.UNION_FILTER]: sendData,
        },
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (project) => {
          this.competenceService.projectSuccess(status);
          this.project = project;
          this.reloadEvent.emit();

          if (
            this.project?.status === CMProjectStatuses.AGREED ||
            this.project?.status === CMProjectStatuses.ON_AGREEMENT
          ) {
            this.showAgreedSnapshop();
          }
        },
        (httpError) => {
          this.competenceService.error(httpError);
        }
      );
  }

  private initializeForm(): void {
    this.validForm = this.fb.group({
      isValid: [null, Validators.required],
    });
    this.unionForm = this.fb.group({
      unionName: ['', Validators.required],
      unionUnits: [{ value: ' - ', disabled: true }],
      unionValues: [[], Validators.required],
    });
  }

  private initializeSearchFilterTemplate(): void {
    if (!this._templateFilter) {
      return;
    }

    const filterConfig = [
      { name: 'level', val: '1', op: 'eq' },
      { name: 'status', val: 'archive', op: 'ne' },

      { name: 'type', val: this._templateFilter.type, op: 'eq' },
    ];

    filterConfig.push({ name: 'units_of_measure_id', val: this._templateFilter?.units_of_measure_id, op: 'eq' });

    this.filterTemplateItems$ = this.competenceService.getFilterTemplates(filterConfig).pipe(
      map((filterTemplateItems) => {
        return filterTemplateItems.map((item) => {
          return { ...item, node_id: item.id, hasChildren: true };
        });
      })
    );
  }

  private getRootChildrenViewIds(filterTemplate: FilterTemplate, list: number[] = []): number[] {
    const result = list;
    result.push(Number(filterTemplate.id || filterTemplate.node_id));
    if (filterTemplate?.children?.length) {
      filterTemplate.children.forEach((item) => {
        this.getRootChildrenViewIds(item, list);
      });
    }
    return result;
  }
}
