import { Column } from '@app/shared/models/table';
import { TradesRequestConfig } from '@app/+trades/models/trades.model';
import { CatalogTab } from '@app/+competence-map/models/competence-map.models';

export enum TradeColumnsKeys {
  ID = 'id',
  SUPPLIER_TRADE_ID = 'id_trade',
  PROJECT_DATE = 'date_create_trade',
  PRETRADE_DATE = 'user_who_invite_provider',
  TRADE_DATE = 'date_start_trade',
  ARCHIVE_DATE = 'date_end_trade',
  TITLE = 'title',
  CUSTOMERS = 'customers',
  SUPPLIERS = 'providers',
  STATUS = 'status',
  ACCESS = 'access',
  COMPETENCIES = 'competencies',
  CUSTOMER_COMPANY = 'name_company',
  CUSTOMER_NAME = 'owner_trade',
  POTENTIAL_SUPPLIERS = 'number_potential_providers',
  SENDED_INVITATIONS = 'number_sent_invitations',
  ACCEPTED_INVITATIONS = 'number_confirmed_invitations',
  TRADE_SUPPLIER_PLAYERS = 'number_of_bidders',
  WINNERS_PROVIDERS = 'winners_providers',
  REASON_END_TRADE = 'reason_end_trade',
  PAYER = 'payer',
  REQ_DATA = 'date_registration_payer',
  REQ_DATA_STATE = 'date_state_registration',
  TARIFF = 'tariff',
  TRADE_RESULTS = 'rating',
  TRADE_DATE_FOR_SUPPLIER = 'date_start_trade',
  ARCHIVE_DATE_FOR_SUPPLIER = 'date_end_trade',
  SELECTED_PROVIDER_IN_TRADE = 'selected_provider_in_trade',
}

export const CUSTOMER_TRADE_COLUMNS: Column<TradesRequestConfig>[] = [
  {
    id: TradeColumnsKeys.ID,
    name: 'ID',
    label: 'Поиск по ID торгов',
    isEnabledSearch: true,
    searchPlaceholder: 'Начните вводить ID торгов',
    width: 70,
    filterConfig: {
      filter: [
        {
          name: TradeColumnsKeys.ID,
          op: 'in_',
          val: '',
        },
      ],
    },
  },
  {
    id: TradeColumnsKeys.TITLE,
    name: 'Название торгов',
    label: 'Поиск по названию торгов',
    isEnabledSearch: true,
    searchPlaceholder: 'Начните вводить название торгов',
    width: 191,
    filterConfig: {
      filter: [
        {
          name: TradeColumnsKeys.TITLE,
          op: 'in_',
          val: '',
        },
      ],
    },
  },
  {
    id: TradeColumnsKeys.COMPETENCIES,
    name: 'Сфера торгов',
    label: 'Показать',
    isEnabledSearch: true,
    searchPlaceholder: 'Начните вводить название товара или услуги',
    width: 161,
    filterConfig: {
      competence_sections: null,
    },
  },
  {
    id: TradeColumnsKeys.PROJECT_DATE,
    name: 'Создание КТ',
    label: 'Показать',
    isEnabledFilter: true,
    width: 150,
    filterConfig: {
      filter: [
        {
          name: TradeColumnsKeys.PROJECT_DATE,
          op: 'between',
          val: null,
        },
      ],
    },
  },
  {
    id: TradeColumnsKeys.CUSTOMERS,
    name: 'Доступ к КТ',
    label: 'Показать',
    isEnabledSearch: true,
    searchPlaceholder: 'Начните вводить пользователя или компанию',
    width: 200,
    data: {
      // поле data используется для сопоставления фильтра и названия колонки
      ['owner.id']: TradeColumnsKeys.CUSTOMERS,
      ['customers.user.id']: TradeColumnsKeys.CUSTOMERS,
    },
    filterConfig: {
      filter: [
        {
          name: 'customers.user.id',
          op: 'in_',
          val: '',
        },
      ],
    },
  },
  {
    id: TradeColumnsKeys.STATUS,
    name: 'Статус',
    label: 'Показать',
    isEnabledFilter: true,
    width: 128,
    filterConfig: {
      filter: [
        {
          name: TradeColumnsKeys.STATUS,
          op: 'in_',
          val: '',
        },
      ],
    },
  },
  {
    id: TradeColumnsKeys.PRETRADE_DATE,
    name: 'Приглашение к торгам',
    label: 'Показать',
    isEnabledSearch: true,
    searchPlaceholder: 'Начните вводить ФИО плательщика',
    width: 150,
    data: {
      ['date_invitations_are_sent']: TradeColumnsKeys.PRETRADE_DATE,
      ['user_who_invite_provider.id']: TradeColumnsKeys.PRETRADE_DATE,
    },
    filterConfig: {
      filter: [
        {
          name: 'date_invitations_are_sent',
          op: 'between',
          val: '',
        },
        {
          name: 'user_who_invite_provider.id',
          op: 'in_',
          val: '',
        },
      ],
    },
  },
  {
    id: TradeColumnsKeys.POTENTIAL_SUPPLIERS,
    name: 'Потенциальные<br/>поставщики',
    label: 'Показать',
    isEnabledSearch: true,
    width: 170,
    filterConfig: {
      from_number_potential_providers: null,
      to_number_potential_providers: null,
    },
  },
  {
    id: TradeColumnsKeys.SENDED_INVITATIONS,
    name: 'Отправлено<br/>приглашений',
    label: 'Показать',
    isEnabledSearch: true,
    width: 150,
    filterConfig: {
      from_number_sent_invitations: null,
      to_number_sent_invitations: null,
    },
  },
  {
    id: TradeColumnsKeys.ACCEPTED_INVITATIONS,
    name: 'Подтверждено<br/>приглашений',
    label: 'Показать',
    isEnabledSearch: true,
    width: 160,
    filterConfig: {
      from_number_confirmed_invitations: null,
      to_number_confirmed_invitations: null,
    },
  },
  {
    id: TradeColumnsKeys.TRADE_SUPPLIER_PLAYERS,
    name: 'Приняло участие<br/>в торгах',
    label: 'Показать',
    isEnabledSearch: true,
    width: 175,
    filterConfig: {
      from_number_of_bidders: null,
      to_number_of_bidders: null,
    },
  },
  {
    id: TradeColumnsKeys.TRADE_DATE,
    name: 'Начало торгов',
    label: 'Показать',
    isEnabledFilter: true,
    width: 155,
    filterConfig: {
      filter: [
        {
          name: TradeColumnsKeys.TRADE_DATE,
          op: 'between',
          val: null,
        },
      ],
    },
  },
  {
    id: TradeColumnsKeys.ARCHIVE_DATE,
    name: 'Завершение торгов',
    label: 'Показать',
    isEnabledFilter: true,
    width: 190,
    filterConfig: {
      filter: [
        {
          name: TradeColumnsKeys.ARCHIVE_DATE,
          op: 'between',
          val: null,
        },
      ],
    },
  },
  {
    id: TradeColumnsKeys.REASON_END_TRADE,
    name: 'Причина завершения',
    label: 'Показать',
    isEnabledFilter: true,
    width: 200,
    filterConfig: {
      filter: [
        {
          name: TradeColumnsKeys.REASON_END_TRADE,
          op: 'in_',
          val: '',
        },
      ],
    },
  },
  {
    id: TradeColumnsKeys.WINNERS_PROVIDERS,
    name: 'Победители',
    label: 'Показать',
    isEnabledSearch: true,
    width: 215,
    searchPlaceholder: 'Начните вводить фамилию, ID или компанию',
    filterConfig: {
      winners_providers: null,
    },
  },
  {
    id: TradeColumnsKeys.TARIFF,
    width: 115,
    name: 'Тариф за торги',
    label: 'Показать',
    isEnabledSearch: true,
    data: {
      ['tariff.payment']: TradeColumnsKeys.TARIFF,
    },
    filterConfig: {
      filter: [
        {
          name: 'tariff.payment',
          op: 'in_',
          val: '',
        },
      ],
    },
  },
  {
    id: TradeColumnsKeys.PAYER,
    name: 'Плательщик',
    label: 'Поиск по плательщикам',
    isEnabledSearch: true,
    width: 200,
    searchPlaceholder: 'Начните вводить ФИО плательщика',
    data: {
      ['payer.id']: TradeColumnsKeys.PAYER,
    },
    filterConfig: {
      filter: [
        {
          name: 'payer.id',
          op: 'in_',
          val: '',
        },
      ],
    },
  },
].map((column) => {
  return {
    ...column,
    defaultFilterConfig: {
      filter: column.filterConfig.filter,
    },
  };
});

export const SUPPLIER_TRADE_COLUMNS: Column<TradesRequestConfig>[] = [
  {
    id: TradeColumnsKeys.SUPPLIER_TRADE_ID,
    name: 'ID',
    label: 'Поиск по ID торгов',
    isEnabledSearch: true,
    searchPlaceholder: 'Начните вводить ID торгов',
    width: 70,
    data: {
      ['trade.id']: TradeColumnsKeys.SUPPLIER_TRADE_ID,
    },
    filterConfig: {
      filter: [
        {
          name: 'trade.id',
          op: 'in_',
          val: '',
        },
      ],
    },
  },
  {
    id: TradeColumnsKeys.TITLE,
    name: 'Название торгов',
    label: 'Поиск по названию торгов',
    isEnabledSearch: true,
    searchPlaceholder: 'Поиск по названию',
    width: 191,
    data: {
      ['trade.title']: TradeColumnsKeys.TITLE,
    },
    filterConfig: {
      filter: [
        {
          name: 'trade.title',
          op: 'in_',
          val: '',
        },
      ],
    },
  },
  {
    id: TradeColumnsKeys.COMPETENCIES,
    name: 'Сфера торгов',
    label: 'Показать',
    isEnabledSearch: true,
    searchPlaceholder: 'Начните вводить название товара или услуги',
    width: 161,
    filterConfig: {
      competence_sections: null,
    },
  },
  {
    id: TradeColumnsKeys.CUSTOMER_COMPANY,
    name: 'Наименование и ИНН<br/>заказчика',
    label: 'Показать',
    isEnabledSearch: true,
    width: 205,
    searchPlaceholder: 'Поиск по наименованию и ИНН',
    data: {
      'trade.owner.company.name_and_itn': TradeColumnsKeys.CUSTOMER_COMPANY,
    },
    filterConfig: {
      filter: [
        {
          name: 'trade.owner.company.name_and_itn',
          op: 'in_',
          val: '',
        },
      ],
    },
  },
  {
    id: TradeColumnsKeys.REQ_DATA_STATE,
    name: 'Государственная<br/>регистрация',
    label: 'Показать',
    width: 175,
    isEnabledFilter: true,
    data: {
      ['providers.user.company.date_state_registration']: TradeColumnsKeys.REQ_DATA_STATE,
    },
    filterConfig: {
      filter: [
        {
          name: 'providers.user.company.date_state_registration',
          op: 'between',
          val: null,
        },
      ],
    },
  },
  {
    id: TradeColumnsKeys.REQ_DATA,
    name: 'Регистрация<br/>в сервисе',
    label: 'Показать',
    width: 145,
    isEnabledFilter: true,
    data: {
      ['trade.owner.created_at']: TradeColumnsKeys.REQ_DATA,
    },
    filterConfig: {
      filter: [
        {
          name: 'trade.owner.created_at',
          op: 'between',
          val: null,
        },
      ],
    },
  },
  {
    id: TradeColumnsKeys.CUSTOMER_NAME,
    name: 'ФИО заказчика',
    label: 'Поиск по ФИО заказчика',
    isEnabledSearch: true,
    searchPlaceholder: 'Начните вводить ФИО заказчика',
    width: 170,
    data: {
      ['trade.owner.full_name']: TradeColumnsKeys.CUSTOMER_NAME,
    },
    filterConfig: {
      filter: [
        {
          name: 'trade.owner.full_name',
          op: 'in_',
          val: '',
        },
      ],
    },
  },
  {
    id: TradeColumnsKeys.SUPPLIERS,
    name: 'Допуск к КТ',
    label: 'Показать',
    isEnabledSearch: true,
    searchPlaceholder: 'Начните вводить пользователя или компанию',
    width: 200,
    data: {
      ['owner.id']: TradeColumnsKeys.SUPPLIERS,
      ['providers.user.id']: TradeColumnsKeys.SUPPLIERS,
    },
    filterConfig: {
      filter: [
        {
          name: 'providers.user.id',
          op: 'in_',
          val: '',
        },
        {
          name: 'owner.id',
          op: 'in_',
          val: '',
        },
      ],
    },
  },
  {
    id: TradeColumnsKeys.STATUS,
    name: 'Статус КТ',
    label: 'Показать',
    isEnabledFilter: true,
    width: 125,
    data: {
      ['trade.status']: TradeColumnsKeys.STATUS,
    },
    filterConfig: {
      filter: [
        {
          name: 'trade.status',
          op: 'in_',
          val: '',
        },
      ],
    },
  },
  {
    id: TradeColumnsKeys.ACCESS,
    name: 'Статус<br/>поставщика',
    label: 'Показать',
    isEnabledFilter: true,
    width: 140,
    data: {
      ['status']: TradeColumnsKeys.ACCESS,
    },
    filterConfig: {
      filter: [
        {
          name: 'status',
          op: 'in_',
          val: '',
        },
      ],
    },
  },
  {
    id: TradeColumnsKeys.TRADE_DATE_FOR_SUPPLIER,
    name: 'Начало торгов',
    label: 'Показать',
    isEnabledFilter: true,
    width: 155,
    data: {
      ['trade.date_start_trade']: TradeColumnsKeys.TRADE_DATE_FOR_SUPPLIER,
    },
    filterConfig: {
      filter: [
        {
          name: 'trade.date_start_trade',
          op: 'between',
          val: null,
        },
      ],
    },
  },
  {
    id: TradeColumnsKeys.ARCHIVE_DATE_FOR_SUPPLIER,
    name: 'Завершение торгов',
    label: 'Показать',
    width: 190,
    isEnabledFilter: true,
    data: {
      ['trade.date_end_trade']: TradeColumnsKeys.ARCHIVE_DATE_FOR_SUPPLIER,
    },
    filterConfig: {
      filter: [
        {
          name: 'trade.date_end_trade',
          op: 'between',
          val: null,
        },
      ],
    },
  },
  {
    id: TradeColumnsKeys.REASON_END_TRADE,
    name: 'Причина завершения',
    label: 'Показать',
    width: 200,
    isEnabledFilter: true,
    data: {
      ['trade.reason_end_trade']: TradeColumnsKeys.REASON_END_TRADE,
    },
    filterConfig: {
      filter: [
        {
          name: 'trade.reason_end_trade',
          op: 'in_',
          val: '',
        },
      ],
    },
  },
  {
    id: TradeColumnsKeys.TRADE_RESULTS,
    name: 'Результаты торгов',
    label: 'Показать места',
    width: 168,
    isEnabledSearch: true,
    filterConfig: {
      from_number_place_in_ranking: null,
      to_number_place_in_ranking: null,
    },
  },
].map((column) => {
  return {
    ...column,
    defaultFilterConfig: {
      filter: column.filterConfig.filter,
    },
  };
});

export const competenciesTabs: CatalogTab[] = [
  {
    label: 'Товары',
    title: 'Товары',
    value: 'products',
  },
  {
    label: 'Работы и услуги',
    title: 'Работы и услуги',
    value: 'services',
  },
];
