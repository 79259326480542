import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

import { SVGSpriteLoaderService } from '../../services/svg-sprite-loader.service';
import { SVG_SPRITE_CONFIG } from '../../constants/svg-sprite.config';
import { SVGSpriteConfig } from '../../types/svg-sprite-config.type';

@Component({
  selector: 'app-svg-file',
  template: `
    <svg width="100%" height="100%" *ngIf="xLinkName$ | async">
      <use [attr.xlink:href]="xLinkName"></use>
    </svg>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SVGFileComponent implements OnInit {
  @Input()
  name: string;

  public xLinkName$: Observable<boolean>;

  get xLinkName(): string {
    return `#${this.config.spriteFilePrefix}` + this.name;
  }

  constructor(
    private svgSpriteService: SVGSpriteLoaderService,
    @Inject(SVG_SPRITE_CONFIG) private config: SVGSpriteConfig
  ) {}

  ngOnInit(): void {
    this.xLinkName$ = this.svgSpriteService.getFile(this.name);
  }
}
