<div class="delete-event">
  <div class="delete-event-text">
    Вы действительно хотите удалить событие?
    <br /><br />
    Отменить это действие будет невозможно!
  </div>
  <div class="modal-footer">
    <button class="btn btn-dc-outline" (click)="closeEvent()">
      Нет, отменить
    </button>
    <button class="btn btn-dc-primary" (click)="deleteEvent()">
      Да, удалить
    </button>
  </div>
</div>
