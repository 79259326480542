<div class="profile-container">
  <div class="d-flex flex-row justify-content-between">
    <div class="profile-info">
      <h1 class="profile-info-header">Личные данные</h1>

      <div class="profile-user-contact" *ngIf="user">
        <div class="user-contact-header d-flex mod-align-center">
          Пароль и контактная информация
          <button type="button" class="icon-button">
            <app-svg-icon [height]="16" [width]="16" name="note"></app-svg-icon>
          </button>
          <button type="button" class="icon-button" (click)="editUserContact()">
            <app-svg-icon [height]="18" [width]="16" name="pencil_alt"></app-svg-icon>
          </button>
        </div>
        <div class="user-contact-content">
          <div class="label-tr">
            <span>ФИО: </span>
            <span class="text-medium-normal">{{ user?.fullName }}</span>
          </div>

          <ng-container *ngIf="user && user.phones && user.phones.length > 0; else NO_PHONES">
            <div class="label-tr" *ngFor="let phone of user.phones; let i = index; let first = first">
              <span>Телефон{{ !first ? ' №' + (i + 1) : '' }}: </span>
              <span *ngIf="phone" class="text-medium-normal">{{ phone }}</span>
            </div>
          </ng-container>
          <ng-template #NO_PHONES>
            <div class="label-tr">
              <span>Телефон:</span>
              <span class="text-medium-normal">-</span>
            </div>
          </ng-template>

          <div class="label-tr">
            <span>E-mail: </span>
            <span class="text-medium-normal">{{ user.email }}</span>
          </div>

          <div class="label-tr">
            <span>Пароль: </span>
            <span class="text-medium-normal">
              <ng-container *ngIf="!hasNewPassword; else NO_PASSWORD">
                &#x25cf; &#x25cf; &#x25cf; &#x25cf; &#x25cf; &#x25cf; &#x25cf; &#x25cf;
              </ng-container>
              <ng-template #NO_PASSWORD>-</ng-template>
            </span>
          </div>
        </div>
      </div>

      <div class="profile-user-contract">
        <ng-container [ngSwitch]="userType">
          <!-- region EXPERT -->
          <ng-container *ngSwitchCase="rolesEnum.EXPERT">
            <div class="profile-user-contract">
              <ng-container *ngTemplateOutlet="CONTRACT_HEADER_DEFAULT"></ng-container>
              <div class="user-contract-block-container">
                <ng-container
                  [ngTemplateOutlet]="CONTRACT_RESIDENCY"
                  [ngTemplateOutletContext]="{ options: { isEdit: false } }"
                ></ng-container>
                <ng-container *ngTemplateOutlet="CONTRACT_PASSPORT"></ng-container>
                <ng-container
                  [ngTemplateOutlet]="CONTRACT_REGISTRATION_ADDRESS"
                  [ngTemplateOutletContext]="{ options: { title: 'Адрес регистрации' } }"
                ></ng-container>

                <ng-container
                  [ngTemplateOutlet]="CONTRACT_MAIL_ADDRESS"
                  [ngTemplateOutletContext]="{ options: { title: 'Почтовый адрес' } }"
                ></ng-container>
                <ng-container *ngTemplateOutlet="CONTRACT_BANK_DEFAULT"></ng-container>
              </div>
            </div>
          </ng-container>
          <!-- endregion -->

          <!-- region PARTNER -->
          <ng-container *ngSwitchCase="rolesEnum.PARTNER">
            <div class="profile-user-contract">
              <ng-container *ngTemplateOutlet="CONTRACT_HEADER_DEFAULT"></ng-container>
              <div class="user-contract-block-container">
                <ng-container
                  [ngTemplateOutlet]="CONTRACT_RESIDENCY"
                  [ngTemplateOutletContext]="{ options: { isEdit: false } }"
                ></ng-container>
                <ng-container *ngTemplateOutlet="CONTRACT_PASSPORT"></ng-container>
                <ng-container
                  [ngTemplateOutlet]="CONTRACT_REGISTRATION_ADDRESS"
                  [ngTemplateOutletContext]="{ options: { title: 'Адрес регистрации' } }"
                ></ng-container>

                <ng-container
                  [ngTemplateOutlet]="CONTRACT_MAIL_ADDRESS"
                  [ngTemplateOutletContext]="{ options: { title: 'Почтовый адрес' } }"
                ></ng-container>
                <ng-container *ngTemplateOutlet="CONTRACT_BANK_DEFAULT"></ng-container>
              </div>
            </div>
          </ng-container>
          <!-- endregion -->

          <!-- region SUPERUSER -->
          <ng-container *ngSwitchCase="rolesEnum.SUPERUSER">
            <div class="profile-user-contract">
              <ng-container *ngTemplateOutlet="CONTRACT_HEADER_DEFAULT"></ng-container>
              <div class="user-contract-block-container">
                <ng-container
                  [ngTemplateOutlet]="CONTRACT_RESIDENCY"
                  [ngTemplateOutletContext]="{ options: { isEdit: false } }"
                ></ng-container>
                <ng-container *ngTemplateOutlet="CONTRACT_PASSPORT"></ng-container>
                <ng-container
                  [ngTemplateOutlet]="CONTRACT_REGISTRATION_ADDRESS"
                  [ngTemplateOutletContext]="{ options: { title: 'Адрес регистрации' } }"
                ></ng-container>

                <ng-container
                  [ngTemplateOutlet]="CONTRACT_MAIL_ADDRESS"
                  [ngTemplateOutletContext]="{ options: { title: 'Почтовый адрес' } }"
                ></ng-container>
                <ng-container *ngTemplateOutlet="CONTRACT_BANK_DEFAULT"></ng-container>
              </div>
            </div>
          </ng-container>
          <!-- endregion -->

          <!-- region ACCOUNTANT -->
          <ng-container *ngSwitchCase="rolesEnum.ACCOUNTANT">
            <div class="profile-user-contract">
              <ng-container *ngTemplateOutlet="CONTRACT_HEADER_DEFAULT"></ng-container>
              <div class="user-contract-block-container">
                <ng-container
                  [ngTemplateOutlet]="CONTRACT_RESIDENCY"
                  [ngTemplateOutletContext]="{ options: { isEdit: false } }"
                ></ng-container>
                <ng-container *ngTemplateOutlet="CONTRACT_PASSPORT"></ng-container>
                <ng-container
                  [ngTemplateOutlet]="CONTRACT_REGISTRATION_ADDRESS"
                  [ngTemplateOutletContext]="{ options: { title: 'Адрес регистрации' } }"
                ></ng-container>

                <ng-container
                  [ngTemplateOutlet]="CONTRACT_MAIL_ADDRESS"
                  [ngTemplateOutletContext]="{ options: { title: 'Почтовый адрес' } }"
                ></ng-container>
                <ng-container *ngTemplateOutlet="CONTRACT_BANK_DEFAULT"></ng-container>
              </div>
            </div>
          </ng-container>
          <!-- endregion -->

          <!-- region ADMIN_OF_USER -->
          <ng-container *ngSwitchCase="rolesEnum.ADMIN_OF_USER">
            <ng-container *ngIf="!user.company.residency && !user.company.legal_status">
              <div class="profile-user-contract">
                <ng-container *ngTemplateOutlet="CONTRACT_HEADER_DEFAULT"></ng-container>
                <div class="user-contract-block-container">
                  <ng-container
                    [ngTemplateOutlet]="CONTRACT_RESIDENCY"
                    [ngTemplateOutletContext]="{ options: { isEdit: false } }"
                  ></ng-container>
                  <ng-container *ngTemplateOutlet="CONTRACT_PASSPORT"></ng-container>
                  <ng-container
                    [ngTemplateOutlet]="CONTRACT_REGISTRATION_ADDRESS"
                    [ngTemplateOutletContext]="{ options: { title: 'Адрес регистрации' } }"
                  ></ng-container>

                  <ng-container
                    [ngTemplateOutlet]="CONTRACT_MAIL_ADDRESS"
                    [ngTemplateOutletContext]="{ options: { title: 'Почтовый адрес' } }"
                  ></ng-container>
                  <ng-container *ngTemplateOutlet="CONTRACT_BANK_DEFAULT"></ng-container>
                </div>
              </div>
            </ng-container>
            <ng-container [ngSwitch]="user.company.residency">
              <ng-container *ngSwitchCase="residencesEnum.RUSSIAN_FEDERATION">
                <ng-container [ngSwitch]="user.company.legal_status">
                  <ng-container *ngSwitchCase="legalStatusesEnum.NATURAL_PERSON">
                    <div class="profile-user-contract">
                      <div class="user-contract-block-container">
                        <ng-container
                          [ngTemplateOutlet]="CONTRACT_RESIDENCY"
                          [ngTemplateOutletContext]="{ options: { isEdit: true } }"
                        ></ng-container>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngSwitchCase="legalStatusesEnum.LEGAL_ENTITY">
                    <div class="profile-user-contract">
                      <ng-container *ngTemplateOutlet="CONTRACT_HEADER_DEFAULT"></ng-container>
                      <div class="user-contract-block-container">
                        <ng-container *ngTemplateOutlet="CONTRACT_RESIDENCY_LEGAL_ENTITY"></ng-container>
                        <ng-container *ngTemplateOutlet="REGISTRATION_DATA_LEGAL_ENTITY"></ng-container>
                        <ng-container *ngTemplateOutlet="CONTRACT_PERSON_AUTHORIZED"></ng-container>
                        <ng-container
                          [ngTemplateOutlet]="CONTRACT_REGISTRATION_ADDRESS"
                          [ngTemplateOutletContext]="{ options: { title: 'Адрес регистрации юридического лица' } }"
                        ></ng-container>

                        <ng-container
                          [ngTemplateOutlet]="CONTRACT_MAIL_ADDRESS"
                          [ngTemplateOutletContext]="{ options: { title: 'Почтовый адрес юридического лица' } }"
                        ></ng-container>
                        <ng-container *ngTemplateOutlet="CONTRACT_BANK_ACCOUNT"></ng-container>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngSwitchCase="legalStatusesEnum.INDIVIDUAL_ENTREPRENEUR">
                    <div class="profile-user-contract">
                      <ng-container *ngTemplateOutlet="CONTRACT_HEADER_DEFAULT"></ng-container>
                      <div class="user-contract-block-container">
                        <ng-container *ngTemplateOutlet="CONTRACT_RESIDENCY"></ng-container>
                        <ng-container *ngTemplateOutlet="CONTRACT_PASSPORT_INDIVIDUAL_ENTREPRENEUR"></ng-container>
                        <ng-container *ngTemplateOutlet="REGISTRATION_DATA_INDIVIDUAL_ENTREPRENEUR"></ng-container>
                        <ng-container
                          [ngTemplateOutlet]="CONTRACT_REGISTRATION_ADDRESS"
                          [ngTemplateOutletContext]="{
                            options: { title: 'Адрес регистрации индивидуального предпринимателя' }
                          }"
                        ></ng-container>
                        <ng-container
                          [ngTemplateOutlet]="CONTRACT_MAIL_ADDRESS"
                          [ngTemplateOutletContext]="{
                            options: { title: 'Почтовый адрес индивидуального предпринимателя' }
                          }"
                        ></ng-container>
                        <ng-container *ngIf="user?.company?.bankcard_number">
                          <ng-container *ngTemplateOutlet="CONTRACT_BANK_DEFAULT"></ng-container>
                        </ng-container>
                        <ng-container *ngIf="!user?.company?.bankcard_number">
                          <ng-container *ngTemplateOutlet="CONTRACT_BANK_ACCOUNT"></ng-container>
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>

              <ng-container *ngSwitchCase="residencesEnum.OTHER">
                <ng-container [ngSwitch]="user.company.legal_status">
                  <ng-container *ngSwitchCase="legalStatusesEnum.NATURAL_PERSON">
                    <div class="profile-user-contract">
                      <div class="user-contract-block-container">
                        <ng-container
                          [ngTemplateOutlet]="CONTRACT_RESIDENCY"
                          [ngTemplateOutletContext]="{ options: { isEdit: true } }"
                        ></ng-container>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="legalStatusesEnum.LEGAL_ENTITY">
                    <div class="profile-user-contract">
                      <ng-container *ngTemplateOutlet="CONTRACT_HEADER_DEFAULT"></ng-container>
                      <div class="user-contract-block-container">
                        <ng-container *ngTemplateOutlet="CONTRACT_RESIDENCY_LEGAL_ENTITY"></ng-container>
                        <ng-container *ngTemplateOutlet="REGISTRATION_DATA_LEGAL_ENTITY_OTHER"></ng-container>
                        <ng-container *ngTemplateOutlet="CONTRACT_PERSON_AUTHORIZED"></ng-container>
                        <ng-container
                          [ngTemplateOutlet]="CONTRACT_REGISTRATION_ADDRESS"
                          [ngTemplateOutletContext]="{ options: { title: 'Адрес регистрации юридического лица' } }"
                        ></ng-container>
                        <ng-container
                          [ngTemplateOutlet]="CONTRACT_MAIL_ADDRESS"
                          [ngTemplateOutletContext]="{ options: { title: 'Почтовый адрес юридического лица' } }"
                        ></ng-container>
                        <ng-container *ngTemplateOutlet="CONTRACT_BANK_ACCOUNT_OTHER"></ng-container>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
          <!-- endregion -->

          <!-- region ADMIN_OF_DIRECTION -->
          <ng-container *ngSwitchCase="rolesEnum.ADMIN_OF_DIRECTION">
            <!-- region ADMIN_OF_DIRECTION not canByPayer -->
            <ng-container *ngIf="!isCanBePayer">
              <ng-container [ngSwitch]="user.parent.company.legal_status">
                <ng-container *ngSwitchCase="legalStatusesEnum.NATURAL_PERSON">
                  <ng-container *ngTemplateOutlet="CONTRACT_HEADER_PARENT_USER"></ng-container>
                  <div class="user-contract-block-container">
                    <ng-container *ngTemplateOutlet="PARENT_USER_ADMIN_OF_USER"></ng-container>
                    <ng-container *ngTemplateOutlet="CONTRACT_NATURAL_PERSON_NO_PAYER"></ng-container>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="legalStatusesEnum.INDIVIDUAL_ENTREPRENEUR">
                  <ng-container *ngTemplateOutlet="CONTRACT_HEADER_PARENT_USER"></ng-container>
                  <div class="user-contract-block-container">
                    <ng-container *ngTemplateOutlet="PARENT_USER_ADMIN_OF_USER"></ng-container>
                    <ng-container *ngTemplateOutlet="CONTRACT_HEADER_NOT_SELF_PAYER"></ng-container>
                    <ng-container *ngTemplateOutlet="CONTRACT_INDIVIDUAL_ENTREPRENEUR"></ng-container>
                    <ng-container
                      [ngTemplateOutlet]="CONTRACT_REGISTRATION_ADDRESS_PARENT"
                      [ngTemplateOutletContext]="{
                        options: { title: 'Адрес регистрации индивидуального предпринимателя' }
                      }"
                    ></ng-container>
                    <ng-container
                      [ngTemplateOutlet]="CONTRACT_MAIL_ADDRESS_PARENT"
                      [ngTemplateOutletContext]="{
                        options: { title: 'Почтовый адрес индивидуального предпринимателя' }
                      }"
                    ></ng-container>
                    <ng-container *ngIf="parentUser?.company?.bankcard_number">
                      <ng-container *ngTemplateOutlet="CONTRACT_BANK_DEFAULT_PARENT"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="!parentUser?.company?.bankcard_number">
                      <ng-container *ngTemplateOutlet="CONTRACT_BANK_ACCOUNT_PARENT"></ng-container>
                    </ng-container>
                  </div>
                </ng-container>
              </ng-container>

              <ng-container [ngSwitch]="user.parent.company.residency">
                <ng-container *ngSwitchCase="residencesEnum.RUSSIAN_FEDERATION">
                  <ng-container [ngSwitch]="user.parent.company.legal_status">
                    <ng-container *ngSwitchCase="legalStatusesEnum.LEGAL_ENTITY">
                      <ng-container *ngTemplateOutlet="CONTRACT_HEADER_PARENT_USER"></ng-container>
                      <div class="user-contract-block-container">
                        <ng-container *ngTemplateOutlet="PARENT_USER_ADMIN_OF_USER"></ng-container>
                        <ng-container *ngTemplateOutlet="CONTRACT_HEADER_NOT_SELF_PAYER"></ng-container>
                        <ng-container *ngTemplateOutlet="CONTRACT_LEGAL_ENTITY_PARENT"></ng-container>
                        <ng-container
                          [ngTemplateOutlet]="CONTRACT_REGISTRATION_ADDRESS_PARENT"
                          [ngTemplateOutletContext]="{ options: { title: 'Адрес регистрации юридического лица' } }"
                        ></ng-container>
                        <ng-container
                          [ngTemplateOutlet]="CONTRACT_MAIL_ADDRESS_PARENT"
                          [ngTemplateOutletContext]="{ options: { title: 'Почтовый адрес юридического лица' } }"
                        ></ng-container>
                        <ng-container *ngTemplateOutlet="CONTRACT_BANK_ACCOUNT_PARENT"></ng-container>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="residencesEnum.OTHER">
                  <ng-container [ngSwitch]="user.parent.company.legal_status">
                    <ng-container *ngSwitchCase="legalStatusesEnum.LEGAL_ENTITY">
                      <ng-container *ngTemplateOutlet="CONTRACT_HEADER_PARENT_USER"></ng-container>
                      <div class="user-contract-block-container">
                        <ng-container *ngTemplateOutlet="PARENT_USER_ADMIN_OF_USER"></ng-container>
                        <ng-container *ngTemplateOutlet="CONTRACT_HEADER_NOT_SELF_PAYER"></ng-container>
                        <ng-container *ngTemplateOutlet="CONTRACT_LEGAL_ENTITY_OTHER_PARENT"></ng-container>
                        <ng-container
                          [ngTemplateOutlet]="CONTRACT_REGISTRATION_ADDRESS_PARENT"
                          [ngTemplateOutletContext]="{ options: { title: 'Адрес регистрации юридического лица' } }"
                        ></ng-container>
                        <ng-container
                          [ngTemplateOutlet]="CONTRACT_MAIL_ADDRESS_PARENT"
                          [ngTemplateOutletContext]="{ options: { title: 'Почтовый адрес юридического лица' } }"
                        ></ng-container>
                        <ng-container *ngTemplateOutlet="CONTRACT_BANK_ACCOUNT_OTHER_PARENT"></ng-container>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
            <!-- endregion -->

            <!-- region ADMIN_OF_DIRECTION canByPayer -->
            <ng-container *ngIf="isCanBePayer">
              <ng-container *ngIf="!user.company.residency && !user.company.legal_status">
                <div class="profile-user-contract">
                  <ng-container *ngTemplateOutlet="CONTRACT_HEADER_DEFAULT"></ng-container>
                  <div class="user-contract-block-container">
                    <ng-container
                      [ngTemplateOutlet]="CONTRACT_RESIDENCY"
                      [ngTemplateOutletContext]="{ options: { isEdit: false } }"
                    ></ng-container>
                    <ng-container *ngTemplateOutlet="CONTRACT_PASSPORT"></ng-container>
                    <ng-container
                      [ngTemplateOutlet]="CONTRACT_REGISTRATION_ADDRESS"
                      [ngTemplateOutletContext]="{ options: { title: 'Адрес регистрации' } }"
                    ></ng-container>

                    <ng-container
                      [ngTemplateOutlet]="CONTRACT_MAIL_ADDRESS"
                      [ngTemplateOutletContext]="{ options: { title: 'Почтовый адрес' } }"
                    ></ng-container>
                    <ng-container *ngTemplateOutlet="CONTRACT_BANK_DEFAULT"></ng-container>
                  </div>
                </div>
              </ng-container>
              <ng-container [ngSwitch]="user.company.residency">
                <ng-container *ngSwitchCase="residencesEnum.RUSSIAN_FEDERATION">
                  <ng-container [ngSwitch]="user.company.legal_status">
                    <!-- TODO: уточнить у дизайнеров про размер шрифта "Резидентство и юридический статус -->
                    <ng-container *ngSwitchCase="legalStatusesEnum.NATURAL_PERSON">
                      <div class="profile-user-contract">
                        <ng-container *ngTemplateOutlet="CONTRACT_HEADER_PARENT_USER"></ng-container>
                        <div class="user-contract-block-container">
                          <ng-container *ngTemplateOutlet="PARENT_USER_ADMIN_OF_USER"></ng-container>

                          <ng-container
                            [ngTemplateOutlet]="CONTRACT_RESIDENCY"
                            [ngTemplateOutletContext]="{ options: { isEdit: true } }"
                          ></ng-container>
                        </div>
                      </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="legalStatusesEnum.INDIVIDUAL_ENTREPRENEUR">
                      <div class="profile-user-contract">
                        <ng-container *ngTemplateOutlet="CONTRACT_HEADER_PARENT_USER"></ng-container>
                        <div class="user-contract-block-container">
                          <ng-container *ngTemplateOutlet="PARENT_USER_ADMIN_OF_USER"></ng-container>
                        </div>
                      </div>
                      <div class="profile-user-contract">
                        <ng-container *ngTemplateOutlet="CONTRACT_HEADER_DEFAULT"></ng-container>
                        <div class="user-contract-block-container">
                          <ng-container
                            [ngTemplateOutlet]="CONTRACT_RESIDENCY"
                            [ngTemplateOutletContext]="{ options: { isEdit: false } }"
                          ></ng-container>
                          <ng-container *ngTemplateOutlet="CONTRACT_PASSPORT_INDIVIDUAL_ENTREPRENEUR"></ng-container>
                          <ng-container *ngTemplateOutlet="REGISTRATION_DATA_INDIVIDUAL_ENTREPRENEUR"></ng-container>
                          <ng-container
                            [ngTemplateOutlet]="CONTRACT_REGISTRATION_ADDRESS"
                            [ngTemplateOutletContext]="{
                              options: { title: 'Адрес регистрации индивидуального предпринимателя' }
                            }"
                          ></ng-container>
                          <ng-container
                            [ngTemplateOutlet]="CONTRACT_MAIL_ADDRESS"
                            [ngTemplateOutletContext]="{
                              options: { title: 'Почтовый адрес индивидуального предпринимателя' }
                            }"
                          ></ng-container>
                          <ng-container *ngIf="user?.company?.bankcard_number">
                            <ng-container *ngTemplateOutlet="CONTRACT_BANK_DEFAULT"></ng-container>
                          </ng-container>
                          <ng-container *ngIf="!user?.company?.bankcard_number">
                            <ng-container *ngTemplateOutlet="CONTRACT_BANK_ACCOUNT"></ng-container>
                          </ng-container>
                        </div>
                      </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="legalStatusesEnum.LEGAL_ENTITY">
                      <div class="profile-user-contract">
                        <ng-container *ngTemplateOutlet="CONTRACT_HEADER_PARENT_USER"></ng-container>
                        <div class="user-contract-block-container">
                          <ng-container *ngTemplateOutlet="PARENT_USER_ADMIN_OF_USER"></ng-container>
                        </div>
                      </div>
                      <div class="profile-user-contract">
                        <ng-container *ngTemplateOutlet="CONTRACT_HEADER_DEFAULT"></ng-container>
                        <div class="user-contract-block-container">
                          <ng-container
                            [ngTemplateOutlet]="CONTRACT_RESIDENCY_LEGAL_ENTITY"
                            [ngTemplateOutletContext]="{ options: { isEdit: false } }"
                          ></ng-container>
                          <ng-container *ngTemplateOutlet="REGISTRATION_DATA_LEGAL_ENTITY"></ng-container>
                          <ng-container *ngTemplateOutlet="CONTRACT_PERSON_AUTHORIZED"></ng-container>
                          <ng-container
                            [ngTemplateOutlet]="CONTRACT_REGISTRATION_ADDRESS"
                            [ngTemplateOutletContext]="{ options: { title: 'Адрес регистрации юридического лица' } }"
                          ></ng-container>
                          <ng-container
                            [ngTemplateOutlet]="CONTRACT_MAIL_ADDRESS"
                            [ngTemplateOutletContext]="{ options: { title: 'Почтовый адрес юридического лица' } }"
                          ></ng-container>
                          <ng-container *ngTemplateOutlet="CONTRACT_BANK_ACCOUNT"></ng-container>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="residencesEnum.OTHER">
                  <ng-container [ngSwitch]="user.company.legal_status">
                    <ng-container *ngSwitchCase="legalStatusesEnum.NATURAL_PERSON">
                      <div class="profile-user-contract">
                        <ng-container *ngTemplateOutlet="CONTRACT_HEADER_PARENT_USER"></ng-container>
                        <div class="user-contract-block-container">
                          <ng-container *ngTemplateOutlet="PARENT_USER_ADMIN_OF_USER"></ng-container>

                          <ng-container
                            [ngTemplateOutlet]="CONTRACT_RESIDENCY"
                            [ngTemplateOutletContext]="{ options: { isEdit: true } }"
                          ></ng-container>
                        </div>
                      </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="legalStatusesEnum.LEGAL_ENTITY">
                      <div class="profile-user-contract">
                        <ng-container *ngTemplateOutlet="CONTRACT_HEADER_PARENT_USER"></ng-container>
                        <div class="user-contract-block-container">
                          <ng-container *ngTemplateOutlet="PARENT_USER_ADMIN_OF_USER"></ng-container>
                        </div>
                      </div>
                      <div class="profile-user-contract">
                        <ng-container *ngTemplateOutlet="CONTRACT_HEADER_DEFAULT"></ng-container>
                        <div class="user-contract-block-container">
                          <ng-container
                            [ngTemplateOutlet]="CONTRACT_RESIDENCY"
                            [ngTemplateOutletContext]="{ options: { isEdit: false } }"
                          ></ng-container>
                          <ng-container *ngTemplateOutlet="CONTRACT_RESIDENCY_LEGAL_ENTITY"></ng-container>
                          <ng-container *ngTemplateOutlet="REGISTRATION_DATA_LEGAL_ENTITY_OTHER"></ng-container>
                          <ng-container *ngTemplateOutlet="CONTRACT_PERSON_AUTHORIZED"></ng-container>
                          <ng-container
                            [ngTemplateOutlet]="CONTRACT_REGISTRATION_ADDRESS"
                            [ngTemplateOutletContext]="{ options: { title: 'Адрес регистрации юридического лица' } }"
                          ></ng-container>
                          <ng-container
                            [ngTemplateOutlet]="CONTRACT_MAIL_ADDRESS"
                            [ngTemplateOutletContext]="{ options: { title: 'Почтовый адрес юридического лица' } }"
                          ></ng-container>
                          <ng-container *ngTemplateOutlet="CONTRACT_BANK_ACCOUNT_OTHER"></ng-container>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
            <!-- endregion -->
          </ng-container>
          <!-- endregion -->

          <!-- region OPERATOR -->
          <ng-container *ngSwitchCase="rolesEnum.OPERATOR">
            <div class="profile-user-contract">
              <ng-container *ngIf="user?.company?.legal_status">
                <ng-container [ngSwitch]="user.company.legal_status">
                  <ng-container *ngSwitchCase="legalStatusesEnum.NATURAL_PERSON">
                    <ng-container *ngTemplateOutlet="CONTRACT_HEADER_PARENT_USER"></ng-container>
                    <div class="user-contract-block-container">
                      <ng-container *ngTemplateOutlet="PARENT_USER_ADMIN_OF_USER"></ng-container>
                      <ng-container *ngTemplateOutlet="CONTRACT_NATURAL_PERSON"></ng-container>
                    </div>
                  </ng-container>

                  <ng-container *ngSwitchCase="legalStatusesEnum.INDIVIDUAL_ENTREPRENEUR">
                    <ng-container *ngTemplateOutlet="CONTRACT_HEADER_PARENT_USER"></ng-container>
                    <div class="user-contract-block-container">
                      <ng-container *ngTemplateOutlet="PARENT_USER_ADMIN_OF_USER"></ng-container>
                      <ng-container *ngTemplateOutlet="CONTRACT_HEADER_NOT_SELF_PAYER"></ng-container>
                      <ng-container *ngTemplateOutlet="CONTRACT_LEGAL_ENTITY"></ng-container>
                      <ng-container
                        [ngTemplateOutlet]="CONTRACT_REGISTRATION_ADDRESS"
                        [ngTemplateOutletContext]="{
                          options: { title: 'Адрес регистрации индивидуального предпринимателя' }
                        }"
                      ></ng-container>
                      <ng-container
                        [ngTemplateOutlet]="CONTRACT_MAIL_ADDRESS"
                        [ngTemplateOutletContext]="{
                          options: { title: 'Почтовый адрес индивидуального предпринимателя' }
                        }"
                      ></ng-container>
                      <ng-container *ngTemplateOutlet="CONTRACT_BANK_ACCOUNT"></ng-container>
                    </div>
                  </ng-container>

                  <ng-container *ngSwitchCase="legalStatusesEnum.LEGAL_ENTITY">
                    <ng-container *ngTemplateOutlet="CONTRACT_HEADER_PARENT_USER"></ng-container>
                    <div class="user-contract-block-container">
                      <ng-container *ngTemplateOutlet="PARENT_USER_ADMIN_OF_USER"></ng-container>
                      <ng-container *ngTemplateOutlet="CONTRACT_HEADER_NOT_SELF_PAYER"></ng-container>
                      <ng-container *ngTemplateOutlet="CONTRACT_LEGAL_ENTITY"></ng-container>
                      <ng-container
                        [ngTemplateOutlet]="CONTRACT_REGISTRATION_ADDRESS"
                        [ngTemplateOutletContext]="{ options: { title: 'Адрес регистрации юридического лица' } }"
                      ></ng-container>
                      <ng-container
                        [ngTemplateOutlet]="CONTRACT_MAIL_ADDRESS"
                        [ngTemplateOutletContext]="{ options: { title: 'Почтовый адрес юридического лица' } }"
                      ></ng-container>
                      <ng-container *ngTemplateOutlet="CONTRACT_BANK_ACCOUNT"></ng-container>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
          <!-- endregion -->
        </ng-container>
      </div>
    </div>

    <div class="profile-documents">
      <ng-container *ngIf="userType === rolesEnum.SUPERUSER; else SHOW_RULES_LINK">
        <ngb-accordion [closeOthers]="true" class="profile-document-links" #acc="ngbAccordion">
          <ngb-panel id="ngb-panel-one">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="profile-document-title">
                <button ngbPanelToggle>
                  <span class="d-flex align-items-center">
                    <app-svg-icon class="margin-r-10" name="subtract" [height]="16" [width]="12"></app-svg-icon>
                    Выбор файлов для правил и Договоров
                  </span>
                  <span class="margin-l-6">
                    <app-svg-icon *ngIf="!opened" [width]="9.5" [height]="5.5" name="caret_down"></app-svg-icon>
                    <app-svg-icon *ngIf="opened" [width]="9.5" [height]="5.5" name="caret_up"></app-svg-icon>
                  </span>
                </button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="profile-document-group">
                <div class="profile-document-item" (click)="selectProfileDocument()">Выбор Правил</div>
                <div class="profile-document-item" (click)="selectProfileDocument(rolesEnum.ADMIN_OF_USER)">
                  Выбор Договора для АП/АН
                </div>
                <div class="profile-document-item" (click)="selectProfileDocument(rolesEnum.PARTNER)">
                  Выбор Договора для Агентов
                </div>
                <div class="profile-document-item" (click)="selectProfileDocument(rolesEnum.EXPERT)">
                  Выбор Договора для Экспертов
                </div>
              </div>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="ngb-panel-two">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="profile-document-title">
                <button ngbPanelToggle>
                  <span class="d-flex align-items-center">
                    <app-svg-icon class="margin-r-10" name="file_signature" [height]="16" [width]="18"></app-svg-icon>
                    Актуальные Правила и Договора
                  </span>
                  <span class="margin-l-6">
                    <app-svg-icon *ngIf="!opened" [width]="9.5" [height]="5.5" name="caret_down"></app-svg-icon>
                    <app-svg-icon *ngIf="opened" [width]="9.5" [height]="5.5" name="caret_up"></app-svg-icon>
                  </span>
                </button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="profile-document-group">
                <div class="profile-document-item" (click)="getRulesFile()">Правила</div>
                <div class="profile-document-item" (click)="getAgreementsFile(rolesEnum.ADMIN_OF_USER)">
                  Договора для АП/АН
                </div>
                <div class="profile-document-item" (click)="getAgreementsFile(rolesEnum.PARTNER)">
                  Договора для Агентов
                </div>
                <div class="profile-document-item" (click)="getAgreementsFile(rolesEnum.EXPERT)">
                  Договора для Экспертов
                </div>
              </div>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="ngb-panel-three">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="profile-document-title">
                <button ngbPanelToggle>
                  <span class="d-flex align-items-center">
                    <app-svg-icon class="margin-r-10" name="archive" [height]="14" [width]="16"></app-svg-icon>
                    Архив Правил и Договоров
                  </span>
                  <span class="margin-l-6">
                    <app-svg-icon *ngIf="!opened" [width]="9.5" [height]="5.5" name="caret_down"></app-svg-icon>
                    <app-svg-icon *ngIf="opened" [width]="9.5" [height]="5.5" name="caret_up"></app-svg-icon>
                  </span>
                </button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="profile-document-group">
                <div class="profile-document-item" (click)="showArchiveRules()">Архив Правил</div>
                <div class="profile-document-item" (click)="showArchiveAgreements(rolesEnum.ADMIN_OF_USER)">
                  Архив Договоров для АП/АН
                </div>
                <div class="profile-document-item" (click)="showArchiveAgreements(rolesEnum.PARTNER)">
                  Архив Договоров для Агентов
                </div>
                <div class="profile-document-item" (click)="showArchiveAgreements(rolesEnum.EXPERT)">
                  Архив Договоров для Экспертов
                </div>
              </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </ng-container>

      <ng-template #SHOW_RULES_LINK>
        <ng-container *ngIf="user?.company?.id">
          <div class="profile-document-rules">
            <div class="profile-rules-link" (click)="getRulesFile()">
              <app-svg-icon [height]="16" [width]="12" name="file-alt"></app-svg-icon>
              <span>Правила сервиса</span>
            </div>
          </div>
          <div *ngIf="isShowContractLink" class="profile-document-rules">
            <div class="profile-contract-link" (click)="getAgreementsFile()">
              <app-svg-icon [height]="16" [width]="18" name="file-signature"></app-svg-icon>
              <span>Договор</span>
            </div>
          </div>
        </ng-container>
      </ng-template>
    </div>
  </div>

  <ng-template #CONTRACT_HEADER_DEFAULT>
    <div class="user-contract-header d-flex mod-align-center">
      Реквизиты пользователя
      <button type="button" class="icon-button">
        <app-svg-icon [height]="16" [width]="16" name="note"></app-svg-icon>
      </button>
      <button type="button" class="icon-button" (click)="editUserContract()" [disabled]="!isEditContract">
        <app-svg-icon [height]="18" [width]="16" name="pencil_alt"></app-svg-icon>
      </button>
    </div>
  </ng-template>

  <ng-template #CONTRACT_HEADER_NOT_SELF_PAYER>
    <div class="user-contract-header d-flex mod-align-center">
      Реквизиты пользователя
      <button type="button" class="icon-button">
        <app-svg-icon [height]="16" [width]="16" name="note"></app-svg-icon>
      </button>
    </div>
  </ng-template>

  <ng-template #CONTRACT_HEADER_PARENT_USER>
    <div class="user-contract-header d-flex mod-align-center">
      Ваш руководитель
      <button type="button" class="icon-button">
        <app-svg-icon [height]="16" [width]="16" name="note"></app-svg-icon>
      </button>
    </div>
  </ng-template>

  <ng-template #PARENT_USER>
    <div class="user-contract-block user-contract-parent-user margin-b-40">
      <div class="user-contract-content">
        <div class="label-tr">
          <span>ФИО: </span>
          <span class="text-medium-normal">
            {{ user.parent?.second_name }} {{ user.parent?.first_name }} {{ user.parent?.patronymic || '' }}
          </span>
        </div>
        <div class="label-tr">
          <span>Должность: </span>
          <span class="text-medium-normal">{{ user.parent?.company?.position }}</span>
        </div>
        <div class="label-tr">
          <ng-container *ngIf="user && user?.parent?.phones && user?.parent?.phones.length > 0">
            <div class="label-tr" *ngFor="let phone of user.phones; let i = index; let first = first">
              <span>Телефон{{ !first ? ' №' + (i + 1) : '' }}: </span>
              <span *ngIf="phone" class="text-medium-normal">{{ phone }}</span>
            </div>
          </ng-container>
        </div>
        <div class="label-tr">
          <span>E-mail: </span>
          <span class="text-medium-normal">{{ user.parent?.email }}</span>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #PARENT_USER_ADMIN_OF_USER>
    <div class="user-contract-block user-contract-parent-user margin-b-40">
      <div class="user-contract-content">
        <div class="label-tr">
          <span>ФИО: </span>
          <span class="text-medium-normal">
            {{ user.parent?.second_name }} {{ user.parent?.first_name }} {{ user.parent?.patronymic || '' }}
          </span>
        </div>
        <div class="label-tr">
          <span>Должность: </span>
          <span class="text-medium-normal">{{ user.parent.type | roleName : 'full' }}</span>
        </div>
        <div class="label-tr">
          <ng-container *ngIf="user && user?.parent?.phones && user?.parent?.phones.length > 0">
            <div class="label-tr" *ngFor="let phone of user.parent.phones; let i = index; let first = first">
              <span>Телефон{{ !first ? ' №' + (i + 1) : '' }}: </span>
              <span *ngIf="phone" class="text-medium-normal">{{ phone }}</span>
            </div>
          </ng-container>
        </div>
        <div class="label-tr">
          <span>E-mail: </span>
          <span class="text-medium-normal">{{ user.parent?.email }}</span>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #CONTRACT_RESIDENCY let-options="options">
    <div class="user-contract-block user-contract-residency">
      <div class="user-contract-header2 d-flex mod-align-center">
        Резидентство и юридический статус
        <button type="button" class="icon-button">
          <app-svg-icon [height]="16" [width]="16" name="note"></app-svg-icon>
        </button>
        <button
          *ngIf="options?.isEdit"
          type="button"
          class="icon-button"
          (click)="editUserContract()"
          [disabled]="!isEditContract"
        >
          <app-svg-icon [height]="18" [width]="16" name="pencil_alt"></app-svg-icon>
        </button>
      </div>
      <div class="user-contract-content">
        <div class="label-tr">
          <span>Резидентство: </span>
          <span class="text-medium-normal">{{ (user?.company?.residency | residency) || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Юридический статус: </span>
          <span class="text-medium-normal">{{ (user?.company?.legal_status | legalStatus) || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Полное название: </span>
          <span class="text-medium-normal">{{ (user?.company | companyName) || '-' }}</span>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #CONTRACT_RESIDENCY_LEGAL_ENTITY let-options="options">
    <div class="user-contract-block user-contract-residency">
      <div class="user-contract-header2 d-flex mod-align-center">
        Резидентство и юридический статус
        <button type="button" class="icon-button">
          <app-svg-icon [height]="16" [width]="16" name="note"></app-svg-icon>
        </button>
        <button
          *ngIf="options?.isEdit"
          type="button"
          class="icon-button"
          (click)="editUserContract()"
          [disabled]="!isEditContract"
        >
          <app-svg-icon [height]="18" [width]="16" name="pencil_alt"></app-svg-icon>
        </button>
      </div>
      <div class="user-contract-content">
        <div class="label-tr">
          <span>Резидентство: </span>
          <span class="text-medium-normal">{{ (user?.company?.residency | residency) || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Юридический статус: </span>
          <span class="text-medium-normal">{{ (user?.company?.legal_status | legalStatus) || '-' }}</span>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #CONTRACT_REGISTRATION_ADDRESS let-options="options">
    <div class="user-contract-block user-contract-registration-address">
      <div class="user-contract-header2 d-flex mod-align-center">
        {{ options.title ? options.title : 'Адрес регистрации' }}
        <button type="button" class="icon-button">
          <app-svg-icon [height]="16" [width]="16" name="note"></app-svg-icon>
        </button>
      </div>
      <div class="user-contract-content">
        <div class="label-tr">
          <span>Индекс: </span>
          <span class="text-medium-normal">{{ user?.company?.registration_address_postcode || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Адрес: </span>
          <span class="text-medium-normal">{{ registrationAddress || '-' }}</span>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #CONTRACT_MAIL_ADDRESS let-options="options">
    <div class="user-contract-block user-contract-mail-address">
      <div class="user-contract-header2 d-flex mod-align-center">
        {{ options.title ? options.title : 'Почтовый адрес' }}
        <button type="button" class="icon-button">
          <app-svg-icon [height]="16" [width]="16" name="note"></app-svg-icon>
        </button>
      </div>
      <div class="user-contract-content">
        <div class="label-tr">
          <span>Индекс: </span>
          <span class="text-medium-normal">{{ user?.company?.postal_address_postcode || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Адрес: </span>
          <span class="text-medium-normal">{{ postalAddress || '-' }}</span>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #CONTRACT_REGISTRATION_ADDRESS_PARENT let-options="options">
    <div class="user-contract-block user-contract-registration-address">
      <div class="user-contract-header2 d-flex mod-align-center">
        {{ options.title ? options.title : 'Адрес регистрации' }}
        <button type="button" class="icon-button">
          <app-svg-icon [height]="16" [width]="16" name="note"></app-svg-icon>
        </button>
      </div>
      <div class="user-contract-content">
        <div class="label-tr">
          <span>Индекс: </span>
          <span class="text-medium-normal">{{ parentUser?.company?.registration_address_postcode || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Адрес: </span>
          <span class="text-medium-normal">{{ registrationParentAddress || '-' }}</span>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #CONTRACT_MAIL_ADDRESS_PARENT let-options="options">
    <div class="user-contract-block user-contract-mail-address">
      <div class="user-contract-header2 d-flex mod-align-center">
        {{ options.title ? options.title : 'Почтовый адрес' }}
        <button type="button" class="icon-button">
          <app-svg-icon [height]="16" [width]="16" name="note"></app-svg-icon>
        </button>
      </div>
      <div class="user-contract-content">
        <div class="label-tr">
          <span>Индекс: </span>
          <span class="text-medium-normal">{{ parentUser?.company?.postal_address_postcode || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Адрес: </span>
          <span class="text-medium-normal">{{ postalParentAddress || '-' }}</span>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #CONTRACT_NATURAL_PERSON>
    <div class="user-contract-block user-contract-natural-person-info">
      <div class="user-contract-header2 d-flex mod-align-center">
        Вы работаете по договору физ.лица {{ user.fullName }}
      </div>
    </div>
  </ng-template>

  <ng-template #CONTRACT_NATURAL_PERSON_NO_PAYER>
    <div class="user-contract-block user-contract-natural-person-info">
      <div class="user-contract-header2 d-flex mod-align-center">
        Вы работаете по договору физ.лица {{ user.parent | initials }}
      </div>
    </div>
  </ng-template>

  <ng-template #CONTRACT_LEGAL_ENTITY>
    <div class="user-contract-block user-contract-natural-person-info">
      <div class="user-contract-header2 d-flex mod-align-center">
        Вы работаете по договору {{ user.company | companyName }}
      </div>
      <div class="user-contract-content">
        <div class="label-tr">
          <span>ИНН: </span>
          <span class="text-medium-normal">{{ user?.company?.itn || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>КПП: </span>
          <span class="text-medium-normal">{{ user?.company?.iec || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>ОГРН: </span>
          <span class="text-medium-normal">{{ user?.company?.psrn || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Номер регистрации: </span>
          <span class="text-medium-normal">{{ user?.company?.certificate_number || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Дата регистрации: </span>
          <span class="text-medium-normal">{{ (user?.company?.certificate_date | dateFormat) || '-' }}</span>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #CONTRACT_LEGAL_ENTITY_PARENT>
    <div class="user-contract-block user-contract-natural-person-info">
      <div class="user-contract-header2 d-flex mod-align-center">
        Вы работаете по договору {{ parentUser.company | companyName }}
      </div>
      <div class="user-contract-content">
        <div class="label-tr">
          <span>ИНН: </span>
          <span class="text-medium-normal">{{ parentUser?.company?.itn || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>КПП: </span>
          <span class="text-medium-normal">{{ parentUser?.company?.iec || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>ОГРН: </span>
          <span class="text-medium-normal">{{ parentUser?.company?.psrn || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Номер регистрации: </span>
          <span class="text-medium-normal">{{ parentUser?.company?.certificate_number || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Дата регистрации: </span>
          <span class="text-medium-normal">{{ (parentUser?.company?.certificate_date | dateFormat) || '-' }}</span>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #CONTRACT_LEGAL_ENTITY_OTHER>
    <div class="user-contract-block user-contract-natural-person-info">
      <div class="user-contract-header2 d-flex mod-align-center">
        Вы работаете по договору {{ user.company | companyName }}
      </div>
      <div class="user-contract-content">
        <div class="label-tr">
          <span>Номер налогоплательщика: </span>
          <span class="text-medium-normal">{{ user?.company?.itn || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Номер регистрации: </span>
          <span class="text-medium-normal">{{ user?.company?.certificate_number || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Дата регистрации: </span>
          <span class="text-medium-normal">{{ (user?.company?.certificate_date | dateFormat) || '-' }}</span>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #CONTRACT_LEGAL_ENTITY_OTHER_PARENT>
    <div class="user-contract-block user-contract-natural-person-info">
      <div class="user-contract-header2 d-flex mod-align-center">
        Вы работаете по договору {{ parentUser.company | companyName }}
      </div>
      <div class="user-contract-content">
        <div class="label-tr">
          <span>Номер налогоплательщика: </span>
          <span class="text-medium-normal">{{ parentUser?.company?.itn || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Номер регистрации: </span>
          <span class="text-medium-normal">{{ parentUser?.company?.certificate_number || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Дата регистрации: </span>
          <span class="text-medium-normal">{{ (parentUser?.company?.certificate_date | dateFormat) || '-' }}</span>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #CONTRACT_INDIVIDUAL_ENTREPRENEUR>
    <div class="user-contract-block user-contract-natural-person-info">
      <div class="user-contract-header2 d-flex mod-align-center">Вы работаете по договору ИП {{ parentUserName }}</div>
      <div class="user-contract-content">
        <div class="label-tr">
          <span>ИНН: </span>
          <span class="text-medium-normal">{{ parentUser?.company?.itn || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>ОГРНИП: </span>
          <span class="text-medium-normal">{{ parentUser?.company?.psrnsp || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Номер регистрации: </span>
          <span class="text-medium-normal">{{ parentUser?.company?.certificate_number || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Дата регистрации: </span>
          <span class="text-medium-normal">{{ (parentUser?.company?.certificate_date | dateFormat) || '-' }}</span>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #CONTRACT_BANK_DEFAULT>
    <div class="user-contract-block user-contract-bank-details">
      <div class="user-contract-header2 d-flex mod-align-center">
        Банковские реквизиты
        <button type="button" class="icon-button">
          <app-svg-icon [height]="16" [width]="16" name="note"></app-svg-icon>
        </button>
      </div>
      <div class="user-contract-content">
        <div class="label-tr">
          <span>Номер банковской карты: </span>
          <span class="text-medium-normal">
            {{ (user?.company?.bankcard_number | mask : '0000-0000-0000-0000') || '-' }}
          </span>
        </div>
        <div class="label-tr">
          <span>Банк-эмитент: </span>
          <span class="text-medium-normal">{{ user?.company?.bankcard_bank_name || '-' }}</span>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #CONTRACT_BANK_ACCOUNT>
    <div class="user-contract-block user-contract-bank-details">
      <div class="user-contract-header2 d-flex mod-align-center">
        Платёжные реквизиты
        <button type="button" class="icon-button">
          <app-svg-icon [height]="16" [width]="16" name="note"></app-svg-icon>
        </button>
      </div>
      <div class="user-contract-content">
        <div class="label-tr">
          <span>Название банка: </span>
          <span class="text-medium-normal">
            {{ user?.company?.bank_name || '-' }}
          </span>
        </div>
        <div class="label-tr">
          <span>БИК банка: </span>
          <span class="text-medium-normal">{{ user?.company?.bank_bic || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Корреспондентский счёт: </span>
          <span class="text-medium-normal">{{ user?.company?.bank_corresponding_account || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Расчётный счёт: </span>
          <span class="text-medium-normal">{{ user?.company?.bank_current_account || '-' }}</span>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #CONTRACT_BANK_DEFAULT_PARENT>
    <div class="user-contract-block user-contract-bank-details">
      <div class="user-contract-header2 d-flex mod-align-center">
        Банковские реквизиты
        <button type="button" class="icon-button">
          <app-svg-icon [height]="16" [width]="16" name="note"></app-svg-icon>
        </button>
      </div>
      <div class="user-contract-content">
        <div class="label-tr">
          <span>Номер банковской карты: </span>
          <span class="text-medium-normal">
            {{ (parentUser?.company?.bankcard_number | mask : '0000-0000-0000-0000') || '-' }}
          </span>
        </div>
        <div class="label-tr">
          <span>Банк-эмитент: </span>
          <span class="text-medium-normal">{{ parentUser?.company?.bankcard_bank_name || '-' }}</span>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #CONTRACT_BANK_ACCOUNT_PARENT>
    <div class="user-contract-block user-contract-bank-details">
      <div class="user-contract-header2 d-flex mod-align-center">
        Платёжные реквизиты
        <button type="button" class="icon-button">
          <app-svg-icon [height]="16" [width]="16" name="note"></app-svg-icon>
        </button>
      </div>
      <div class="user-contract-content">
        <div class="label-tr">
          <span>Название банка: </span>
          <span class="text-medium-normal">
            {{ parentUser?.company?.bank_name || '-' }}
          </span>
        </div>
        <div class="label-tr">
          <span>БИК банка: </span>
          <span class="text-medium-normal">{{ parentUser?.company?.bank_bic || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Корреспондентский счёт: </span>
          <span class="text-medium-normal">{{ parentUser?.company?.bank_corresponding_account || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Расчётный счёт: </span>
          <span class="text-medium-normal">{{ parentUser?.company?.bank_current_account || '-' }}</span>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #CONTRACT_BANK_ACCOUNT_OTHER>
    <div class="user-contract-block user-contract-bank-details">
      <div class="user-contract-header2 d-flex mod-align-center">
        Платёжные реквизиты
        <button type="button" class="icon-button">
          <app-svg-icon [height]="16" [width]="16" name="note"></app-svg-icon>
        </button>
      </div>
      <div class="user-contract-content">
        <div class="label-tr">
          <span>Название банка: </span>
          <span class="text-medium-normal">
            {{ user?.company?.bank_name || '-' }}
          </span>
        </div>
        <div class="label-tr">
          <span>SWIFT: </span>
          <span class="text-medium-normal">{{ user?.company?.swift || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>IBAN: </span>
          <span class="text-medium-normal">{{ user?.company?.iban || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Расчётный счёт: </span>
          <span class="text-medium-normal">{{ user?.company?.bank_current_account || '-' }}</span>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #CONTRACT_BANK_ACCOUNT_OTHER_PARENT>
    <div class="user-contract-block user-contract-bank-details">
      <div class="user-contract-header2 d-flex mod-align-center">
        Платёжные реквизиты
        <button type="button" class="icon-button">
          <app-svg-icon [height]="16" [width]="16" name="note"></app-svg-icon>
        </button>
      </div>
      <div class="user-contract-content">
        <div class="label-tr">
          <span>Название банка: </span>
          <span class="text-medium-normal">
            {{ parentUser?.company?.bank_name || '-' }}
          </span>
        </div>
        <div class="label-tr">
          <span>SWIFT: </span>
          <span class="text-medium-normal">{{ parentUser?.company?.swift || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>IBAN: </span>
          <span class="text-medium-normal">{{ parentUser?.company?.iban || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Расчётный счёт: </span>
          <span class="text-medium-normal">{{ parentUser?.company?.bank_current_account || '-' }}</span>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #REGISTRATION_DATA_INDIVIDUAL_ENTREPRENEUR>
    <div class="user-contract-block user-registration-data">
      <div class="user-contract-header2 d-flex mod-align-center">
        Данные о регистрации
        <button type="button" class="icon-button">
          <app-svg-icon [height]="16" [width]="16" name="note"></app-svg-icon>
        </button>
      </div>
      <div class="user-contract-content">
        <div class="label-tr">
          <span>Номер свидетельства о гос. регистрации: </span>
          <span class="text-medium-normal">{{ user?.company?.certificate_number || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Дата гос. регистрации: </span>
          <span class="text-medium-normal">{{ (user?.company?.certificate_date | dateFormat) || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>ИНН: </span>
          <span class="text-medium-normal">{{ user?.company?.itn || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>ОГРНИП: </span>
          <span class="text-medium-normal">{{ user?.company?.psrnsp || '-' }}</span>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #REGISTRATION_DATA_LEGAL_ENTITY>
    <div class="user-contract-block user-registration-data">
      <div class="user-contract-header2 d-flex mod-align-center">
        Данные о регистрации
        <button type="button" class="icon-button">
          <app-svg-icon [height]="16" [width]="16" name="note"></app-svg-icon>
        </button>
      </div>
      <div class="user-contract-content">
        <div class="label-tr">
          <span>ОПФ: </span>
          <span class="text-medium-normal">{{ user?.company?.organization_type || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Название организации: </span>
          <span class="text-medium-normal">{{ user?.company?.organization_name || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Полное название организации: </span>
          <span class="text-medium-normal">{{ (user?.company | companyName) || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Номер свидетельства гос. регистрации: </span>
          <span class="text-medium-normal">{{ user?.company?.certificate_number || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Дата гос. регистрации: </span>
          <span class="text-medium-normal">{{ (user?.company?.certificate_date | dateFormat) || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>ИНН: </span>
          <span class="text-medium-normal">{{ user?.company?.itn || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>КПП: </span>
          <span class="text-medium-normal">{{ user?.company?.iec || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>ОГРН: </span>
          <span class="text-medium-normal">{{ user?.company?.psrn || '-' }}</span>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #REGISTRATION_DATA_LEGAL_ENTITY_OTHER>
    <div class="user-contract-block user-registration-data">
      <div class="user-contract-header2 d-flex mod-align-center">
        Данные о регистрации
        <button type="button" class="icon-button">
          <app-svg-icon [height]="16" [width]="16" name="note"></app-svg-icon>
        </button>
      </div>
      <div class="user-contract-content">
        <div class="label-tr">
          <span>ОПФ: </span>
          <span class="text-medium-normal">{{ user?.company?.organization_type || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Название организации: </span>
          <span class="text-medium-normal">{{ user?.company?.organization_name || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Полное название организации: </span>
          <span class="text-medium-normal">{{ (user?.company | companyName) || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Номер свидетельства гос. регистрации: </span>
          <span class="text-medium-normal">{{ user?.company?.certificate_number || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Дата гос. регистрации: </span>
          <span class="text-medium-normal">{{ (user?.company?.certificate_date | dateFormat) || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Номер налогопательщика: </span>
          <span class="text-medium-normal">{{ user?.company?.itn || '-' }}</span>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #CONTRACT_PASSPORT>
    <div class="user-contract-block user-contract-passport">
      <div class="user-contract-header2 d-flex mod-align-center">
        Паспорт и личные регистрационные данные
        <button type="button" class="icon-button">
          <app-svg-icon [height]="16" [width]="16" name="note"></app-svg-icon>
        </button>
      </div>
      <div class="user-contract-content">
        <div class="label-tr">
          <span>Серия и номер: </span>
          <span class="text-medium-normal"
            >{{ user?.company?.passport_series || '-' }} {{ user?.company?.passport_number }}</span
          >
        </div>
        <div class="label-tr">
          <span>Код подразделения: </span>
          <span class="text-medium-normal">{{
            (user?.company?.passport_division_code | mask : '000-000') || '-'
          }}</span>
        </div>
        <div class="label-tr">
          <span>Дата выдачи: </span>
          <span class="text-medium-normal">{{ (user?.company?.passport_date | dateFormat) || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Выдан: </span>
          <span class="text-medium-normal">{{ user?.company?.passport_issued_by || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>ИНН: </span>
          <span class="text-medium-normal">{{ user?.company?.itn || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>СНИЛС: </span>
          <span class="text-medium-normal">{{ (user?.company?.snils | mask : '000-000-000 00') || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Дата рождения: </span>
          <span class="text-medium-normal">{{ (user?.birthday | dateFormat) || '-' }}</span>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #CONTRACT_PERSON_AUTHORIZED>
    <div class="user-contract-block user-person-authorized">
      <div class="user-contract-header2 d-flex mod-align-center">
        Лицо, уполномоченное на подписание договора
        <button type="button" class="icon-button">
          <app-svg-icon [height]="16" [width]="16" name="note"></app-svg-icon>
        </button>
      </div>
      <div class="user-contract-content">
        <div class="label-tr">
          <span>ФИО: </span>
          <span class="text-medium-normal">{{ (user?.company | companyAuthorizedPerson) || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Должность: </span>
          <span class="text-medium-normal">{{ user?.company?.position || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Основание полномочий: </span>
          <span class="text-medium-normal">{{ user?.company?.authority_basis || '-' }}</span>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #CONTRACT_PASSPORT_INDIVIDUAL_ENTREPRENEUR>
    <div class="user-contract-block user-contract-passport">
      <div class="user-contract-header2 d-flex mod-align-center">
        Паспортные данные
        <button type="button" class="icon-button">
          <app-svg-icon [height]="16" [width]="16" name="note"></app-svg-icon>
        </button>
      </div>
      <div class="user-contract-content">
        <div class="label-tr">
          <span>Серия и номер: </span>
          <span class="text-medium-normal"
            >{{ user?.company?.passport_series || '-' }} {{ user?.company?.passport_number }}</span
          >
        </div>
        <div class="label-tr">
          <span>Код подразделения: </span>
          <span class="text-medium-normal">{{
            (user?.company?.passport_division_code | mask : '000-000') || '-'
          }}</span>
        </div>
        <div class="label-tr">
          <span>Дата выдачи: </span>
          <span class="text-medium-normal">{{ (user?.company?.passport_date | dateFormat) || '-' }}</span>
        </div>
        <div class="label-tr">
          <span>Выдан: </span>
          <span class="text-medium-normal">{{ user?.company?.passport_issued_by || '-' }}</span>
        </div>
      </div>
    </div>
  </ng-template>
</div>
