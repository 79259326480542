<form (ngSubmit)="onSubmit()" [formGroup]="groupEditForm">
  <div class="group-edit">
    <div class="container-fluid">
      <div class="row">
        <div *ngIf="chatSectionSelected.name !== chatSectionsEnum.TECH" class="col-5">
          <div class="row">
            <div class="col-10">
              <input
                class="form-control"
                placeholder="Искать"
                formControlName="filter"
                (mousedown)="$event.stopPropagation()"
                (keyup)="onFilterEdit($event)"
                type="text"
              />
            </div>
          </div>
          <div class="row user-filters">
            <div class="col-6 text-left">
              <a (click)="onFilterToggle()" class="user-filter">
                <fa-icon [icon]="['fas', 'sort-amount-down']"></fa-icon> Фильтр
              </a>
            </div>
            <div class="col-6 text-right" (click)="onClearFilter()">
              <a (click)="onClearFilter()" class="user-filter-clear">
                <fa-icon [icon]="['fas', 'ban']"></fa-icon> Очистить
              </a>
            </div>
          </div>
        </div>
        <div [ngClass]="chatSectionSelected.name !== chatSectionsEnum.TECH ? 'col-5 offset-2' : 'col-12 text-center'">
          <div class="row">
            <div class="col-12">
              <input
                (mousedown)="$event.stopPropagation()"
                *ngIf="!editingObject.group_id"
                [readonly]="
                  (editingObject.owner_id !== userId && editingObject.id) ||
                  chatSectionSelected.name === chatSectionsEnum.TRADE
                "
                class="form-control"
                formControlName="title"
                placeholder="Название группы"
                type="text"
              />
              <input
                (mousedown)="$event.stopPropagation()"
                *ngIf="editingObject.group_id"
                [readonly]="editingObject.owner_id !== userId"
                class="form-control"
                formControlName="title"
                placeholder="Название темы"
                type="text"
              />
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="chatSectionSelected.name !== chatSectionsEnum.TECH" class="row">
        <div class="col-5 contacts">
          <div
            class="contacts-item"
            (dblclick)="onContactClick(contact)"
            *ngFor="let contact of contacts | usersInitialsFilter: filterString | contactsFilter: contactsOnlyFilter"
          >
            <div *ngIf="contact.status !== 'in_archive'" class="row" [class.selected]="contact.selected">
              <div class="col-12">
                <div class="contacts-item__name">
                  {{ contact | initials }}
                </div>
                <div class="contacts-item__role">
                  {{ contact.type | titleRole }}
                </div>
                <div class="contacts-item__role">{{ contact.competence_sections | competencies }}</div>
                <div
                  class="contacts-item__company"
                  *ngIf="contact.company && chatSectionSelected.name !== chatSectionsEnum.ADMIN"
                >
                  {{ contact.company | companyName }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-5 offset-2 contacts">
          <div
            class="contacts-item"
            (dblclick)="onGroupContactClick(contact)"
            *ngFor="let contact of groupContacts | accessUsersSort: editingObject.owner_id"
          >
            <div *ngIf="contact.status !== 'in_archive'" [class.selected]="contact.selected">
              <div>
                <div
                  *ngIf="!this.editingObject.group_id || this.editingObject.owner_id === contact.id"
                  (click)="$event.stopPropagation(); onContactCrownClick(contact)"
                  [class.active]="this.editingObject.owner_id === contact.id"
                  class="crown"
                >
                  <fa-icon [icon]="['fas', 'crown']"></fa-icon>
                </div>
                <div class="contacts-item__name">
                  {{ contact | initials }}
                </div>
                <div class="contacts-item__role">
                  {{ contact.type | titleRole }}
                </div>
                <div class="contacts-item__role">
                  {{ contact.competence_sections | competencies }}
                </div>
                <div class="contacts-item__company">
                  {{ contact.company | companyName }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6 text-right">
          <button (click)="onCancelGroupEdit()" class="btn btn btn-dc-primary" type="button">Отменить</button>
        </div>
        <div class="col-6 text-left">
          <button [disabled]="!groupEditForm.valid" type="submit" class="btn btn btn-dc-primary">
            Сохранить
          </button>
        </div>
      </div>
    </div>
    <div
      *ngIf="chatSectionSelected.name !== chatSectionsEnum.TECH && chatSectionSelected.name !== chatSectionsEnum.TRADE"
      [class.disabled]="editingObject.owner_id && editingObject.owner_id !== userId"
      (click)="moveToGroup()"
      class="move move-to-group"
    >
      <fa-icon [icon]="['fas', 'angle-double-right']"></fa-icon>
    </div>
    <div
      *ngIf="chatSectionSelected.name !== chatSectionsEnum.TECH && chatSectionSelected.name !== chatSectionsEnum.TRADE"
      (click)="moveFromGroup()"
      [class.disabled]="editingObject.owner_id && editingObject.owner_id !== userId"
      class="move move-from-group"
    >
      <fa-icon [icon]="['fas', 'angle-double-left']"></fa-icon>
    </div>
  </div>
</form>
