import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { TreeComponent as TreeComponentLib, TreeModel, TreeNode } from '@circlon/angular-tree-component';
import { CompetenceService } from '@app/+competence-map/services/competence.service';
import { FilterTemplateTypes } from '@app/+competence-map/constants/filter-templates.constants';

@Component({
  selector: 'app-union-tree-choice-snapshot',
  templateUrl: './union-tree-choice-snapshot.component.html',
  styleUrls: ['./union-tree-choice-snapshot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnionTreeChoiceSnapshotComponent implements OnChanges {
  @Input() data: any[] = [];
  @Input() selectedNodes: number[] = [];
  @Input() filterValue: string = '';
  @Input() selectAll: boolean = false;
  @Input() disabled: boolean;

  unique = (Math.random() * 1e8).toString(16);
  allChecked: boolean = false;
  nodes: any[] = [];
  isLoadData: boolean = true;
  selectedItems: number[] = [];

  @ViewChild('tree', { static: true }) tree: TreeComponentLib;

  get filterTemplateTypes(): typeof FilterTemplateTypes {
    return FilterTemplateTypes;
  }

  constructor(private competenceService: CompetenceService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { filterValue, data } = changes;

    if (this.isLoadData) {
      this.nodes = data.currentValue;
      if (this.nodes) {
        this.isLoadData = false;
      }
    }

    if (filterValue?.currentValue !== filterValue?.previousValue) {
      this.filterFn(filterValue.currentValue, this.tree?.treeModel);
    }

    this.tree.treeModel.update();
  }

  isNodeSelected(node: TreeNode): boolean {
    return node.isSelected;
  }

  filterFn(value: string, treeModel: TreeModel) {
    if (treeModel?.nodes) {
      treeModel?.filterNodes((node: TreeNode) => fuzzySearch(value, node.data.title));
    }
  }

  getCheckedInitializeValue(node_id: number): boolean {
    return this.selectedNodes.includes(node_id);
  }

  getAllCheckedStatus(): boolean {
    return (
      this.tree?.treeModel?.selectedLeafNodes?.length + this.selectedNodes.length ===
      this.tree?.treeModel?.nodes?.length
    );
  }
}
function fuzzySearch(needle: string, haystack: string) {
  const haystackLC = haystack.toLowerCase();
  const needleLC = needle.toLowerCase();

  const hLen = haystack.length;
  const nLen = needleLC.length;

  if (nLen > hLen) {
    return false;
  }
  if (nLen === hLen) {
    return needleLC === haystackLC;
  }
  outer: for (let i = 0, j = 0; i < nLen; i++) {
    const nch = needleLC.charCodeAt(i);

    while (j < hLen) {
      if (haystackLC.charCodeAt(j++) === nch) {
        continue outer;
      }
    }
    return false;
  }
  return true;
}
