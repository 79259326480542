<div class="employees-component" *ngIf="authUser | async">
  <div class="container-fluid">
    <div class="fileman-container">
      <div class="fileman-container-left-menu">
        <div class="margin-t-4">
          <h1>{{ MODULE_TITLE }}</h1>
        </div>
        <ng-container *ngIf="layout !== fileManagerLayouts.PROFILE; else PROFILE_LEFT_MENU">
          <div class="padding-r-2">
            <form [formGroup]="searchForm" (ngSubmit)="searchFormSubmit()">
              <dc-form-field-wrapper [style]="'material'" [vertically]="true">
                <dc-form-input [isShowLength]="false">
                  <input
                    formInput
                    formControlName="query"
                    placeholder="Введите имя файла или ФИО его владельца"
                    [attr.disabled]="false"
                  />
                  <button (click)="resetSearch()" *ngIf="isSearchQuery()" class="reset-search">
                    <app-svg-icon [width]="14" [height]="14" name="times"> </app-svg-icon>
                  </button>
                </dc-form-input>
              </dc-form-field-wrapper>
            </form>
          </div>
          <div
            class="fileman-progress-bar padding-r-2 margin-t-30"
            [ngClass]="{ 'fileman-progress-bar__danger': used > 80 }"
          >
            <mat-progress-bar [value]="percentUsed"></mat-progress-bar>
          </div>
          <div class="fileman-free-space">
            <span class="small" *ngIf="limit && remaining">Свободно: {{ remaining }} из {{ limit }}</span>
          </div>
          <div class="fileman-left-menu margin-t-4">
            <ul class="list-unstyled list-first">
              <li>
                <button
                  class="btn-link folder-group folder-group__root"
                  [class.folder-group__root_opened]="isRootOpened"
                  (click)="openRootDir(getRootDir())"
                  (dblclick)="openRootDir(getRootDir(), true)"
                >
                  <div class="file-manager-tree-icon-group">
                    <app-svg-icon
                      *ngIf="getRootDir().opened"
                      [width]="12"
                      name="caret_up"
                      class="file-manager-tree-icon__black"
                    ></app-svg-icon>
                    <app-svg-icon
                      *ngIf="!getRootDir().opened"
                      [width]="12"
                      name="caret_down"
                      class="file-manager-tree-icon__black"
                    ></app-svg-icon>
                    <app-svg-icon *ngIf="getRootDir().opened" [width]="16" name="folder_open"></app-svg-icon>
                    <app-svg-icon *ngIf="!getRootDir().opened" [width]="16" name="folder"></app-svg-icon>
                  </div>
                  <span class="margin-l-6">{{ 'root_dir' | translate }}</span>
                </button>
                <button
                  class="btn-link__info hover padding-0"
                  [class.folder-group__root_opened]="isRootOpened"
                  (click)="openHelper()"
                >
                  <app-svg-icon [width]="16" class="opacity-5" name="question-circle-color"></app-svg-icon>
                </button>
                <app-file-manager-tree
                  *ngIf="getRootDir().opened"
                  [files]="getRootDir().children"
                  (onOpen)="openDir($event)"
                  [current]="CURRENT_DIR"
                  [searchQuery]="searchString"
                  [isSearch]="isSearch"
                  [searchResults]="searchResults"
                  (onShare)="onShare($event)"
                ></app-file-manager-tree>
              </li>
            </ul>
            <ul class="list-unstyled list-first">
              <li>
                <button
                  class="btn-link folder-group folder-group__root"
                  [class.folder-group__root_opened]="isRootShareOpened"
                  (click)="openRootDir(getRootSharedDir())"
                  (dblclick)="openRootDir(getRootSharedDir(), true)"
                >
                  <div class="file-manager-tree-icon-group">
                    <app-svg-icon
                      *ngIf="getRootDir().opened"
                      [width]="12"
                      name="caret_up"
                      class="file-manager-tree-icon__black"
                    ></app-svg-icon>
                    <app-svg-icon
                      *ngIf="!getRootDir().opened"
                      [width]="12"
                      name="caret_down"
                      class="file-manager-tree-icon__black"
                    ></app-svg-icon>
                    <app-svg-icon *ngIf="getRootDir().opened" [width]="16" name="folder_open"></app-svg-icon>
                    <app-svg-icon *ngIf="!getRootDir().opened" [width]="16" name="folder"></app-svg-icon>
                  </div>
                  <span class="margin-l-6">{{ 'root_dir_share' | translate }}</span>
                </button>
                <button
                  class="hover padding-0 btn-link__info"
                  [class.folder-group__root_opened]="isRootShareOpened"
                  (click)="openHelper()"
                >
                  <app-svg-icon [width]="16" class="opacity-5" name="question-circle-color"></app-svg-icon>
                </button>
                <app-file-manager-tree
                  *ngIf="getRootSharedDir().opened"
                  [files]="getRootSharedDir().children"
                  (onOpen)="openDir($event)"
                  [searchQuery]="searchString"
                  [isSearch]="isSearch"
                  [searchResults]="searchResults"
                  [current]="CURRENT_DIR"
                ></app-file-manager-tree>
              </li>
            </ul>
          </div>
        </ng-container>
      </div>
      <div class="fileman-container-right-menu">
        <app-file-manager-toolbar
          [selected]="selected"
          [disabledExportButton]="disabledExportButton"
          [buffer]="buffer"
          [legends]="legends"
          [current]="CURRENT_DIR"
          [history]="history"
          (onAdd)="add($event)"
          (onEdit)="edit($event)"
          (onCut)="cut()"
          (onCopy)="copy()"
          (onPaste)="paste()"
          (onShare)="onShare($event)"
          (onDownload)="download($event)"
          (onDelete)="delete($event)"
          (onUpload)="upload($event)"
          (onExport)="exportSelected($event)"
          (onBack)="goBack()"
          (onView)="view()"
          (onForward)="goForward()"
          (onResetSelected)="selected = []"
          [layout]="layout"
          [view]="VIEW"
        ></app-file-manager-toolbar>
        <app-file-manager-area
          [selected]="selected"
          [current]="CURRENT_DIR"
          [isSearch]="isSearch"
          [searchQuery]="searchString"
          [searchResults]="searchResults"
          [searchMy]="getRootDir().children"
          [searchShared]="getRootSharedDir().children"
          [multipleSelection]="multipleSelection"
          (onOpen)="setCurrentDir($event)"
          (onShare)="onShare($event)"
          (onDownload)="download($event)"
          (onPrint)="print($event)"
          [layout]="layout"
          [view]="VIEW"
          (onSelected)="selected = $event"
        ></app-file-manager-area>
      </div>
    </div>
  </div>
</div>

<app-share-users-form
  [files]="modal.share.files"
  [isOpen]="modal.share.isOpen"
  (onSave)="modal.share.action($event)"
  (onCancel)="modal.share.close()"
></app-share-users-form>

<app-modal-window [isShowed]="modal.helper.isOpen" (closeEvent)="modal.helper.close()">
  <div class="modal-header center">
    <div class="modal-title">{{ 'fm_helper' | translate }}</div>
  </div>
  <div class="modal-footer">
    <div class="dc-footer-buttons">
      <button class="btn btn-dc-outline" (click)="modal.helper.close()">{{ 'fm_close' | translate }}</button>&nbsp;
    </div>
  </div>
</app-modal-window>
