export enum CMSectionStatuses {
  ACTIVE = 'active',
  DRAFT = 'draft',
  REMOVED = 'removed',
  ARCHIVE = 'archive',
}

export enum CMCatalogTypes {
  GOODS = 'goods',
  SERVICES = 'services',
}
