import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ModalInjectorService } from '@app/modal-injector/services/modal-injector.service';
import * as moment from 'moment';
import { CalendarDataService } from '@app/shared/services/calendar/calendar-data.service';
import {
  LIST_YEARS_LIMIT,
  SIZE_WINDOW,
  TYPE_SELECT,
} from '@app/shared/components/calendar-widget/constants/select-date.constants';
import { TYPE_PARENT } from '@app/shared/components/move-pin/constants/move-pin.constants';

@Component({
  selector: 'app-select-date',
  templateUrl: './select-date.component.html',
  styleUrls: ['./select-date.component.scss'],
})
export class SelectDateComponent implements AfterViewInit {
  @Input() id;
  @Input() parentId;
  @Input() countWeek: number;
  @Input() type: TYPE_SELECT;
  @Input() parent: TYPE_PARENT;
  position = {
    x: 0,
    y: 0,
  };
  size = {
    height: 218,
    width: 126,
  };
  isOpen = false;
  context: string[] = [];
  typeSelect = TYPE_SELECT;
  marginWindow = 20;

  constructor(
    private cdRef: ChangeDetectorRef,
    private modalInjectorService: ModalInjectorService,
    private calendarDataService: CalendarDataService
  ) {}

  onClickedOutside(event) {
    if (this.isOpen) {
      this.modalInjectorService.closeModal(this.id);
    }
    this.isOpen = true;
  }

  selectValue(value) {
    this.calendarDataService.updateData({ value, id: this.parentId, type: this.type });
    this.modalInjectorService.closeModal(this.id);
  }

  ngAfterViewInit() {
    this.size = SIZE_WINDOW[this.type];
    this.position = {
      x: this.parent.left - (this.size.width - this.parent.width) / 2,
      y: this.parent.top + this.parent.height + 2,
    };
    switch (this.type) {
      case TYPE_SELECT.YEAR:
        this.context = [];
        for (let i = LIST_YEARS_LIMIT.startYear; i <= LIST_YEARS_LIMIT.endYear; i++) {
          this.context.push(String(i));
        }
        break;
      case TYPE_SELECT.MONTH:
        this.context = moment.months();
        break;
      case TYPE_SELECT.WEEK:
        this.context = [];
        for (let i = 1; i <= this.countWeek; i++) {
          this.context.push(String(i));
        }
        break;
    }
    this.cdRef.detectChanges();
  }
}
