<div class="chat-contact">
  <span *ngIf="contact.type !== rolesEnum.PARTNER" class="contact__type">
    {{ contact.type | titleFrom: roles }}
  </span>
  <span *ngIf="contact.type === rolesEnum.PARTNER && !contact.is_duty_tso" class="contact__type">
    ОТП
  </span>
  <span *ngIf="contact.type === rolesEnum.PARTNER && contact.is_duty_tso" class="contact__type">
    деж.ОТП
  </span>
  <span class="contact__competence"> | {{ contact.competence_sections | competencies }} </span>
</div>
