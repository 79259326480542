import { Creator } from './creator.model';
import { User } from './user.model';
import { TitleFromPipe } from '../pipes/title-from.pipe';
import { STATUSES } from '../constants/statuses.constants';
import { DatesHelper } from '@app/shared/helpers/dates.helper';

export class Log extends Creator {
  created_at: string;
  previous_status: string;
  status: string;
  editor: User;

  format(): string {
    const created_at = DatesHelper.formatDate(this.created_at);
    const titleFrom = new TitleFromPipe();
    return (
      `${this.editor.shortName} ${created_at}: ${titleFrom.transform(this.previous_status, STATUSES)} --> ` +
      `${titleFrom.transform(this.status, STATUSES)}`
    );
  }
}
