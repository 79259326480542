<div (click)="cancel()" class="dc-fileman-modal-x-button">
  <app-svg-icon [width]="20" [height]="20" name="close"></app-svg-icon>
</div>
<div class="modal-header share-employee-header">
  <div class="modal-title share-employee-header__title">{{ title }}</div>
  <div class="share-employee-header__subtitle">
    <span>для файлов и папок:</span>&nbsp;
    <span *ngIf="files.length === 1">
      {{ files[0]?.name }}
    </span>
    <span *ngIf="files.length > 1">
      <ng-container *ngFor="let file of files; let last = last; let index = index">
        <span *ngIf="index <= enabledFilesCount">
          {{ file?.name }}<ng-container *ngIf="!last">,&nbsp;</ng-container>
        </span>
        <span
          *ngIf="index === enabledFilesCount + 1"
          class="share-employee-header__subtitle_eg"
          container="body"
          triggers="mouseenter:mouseleave"
          placement="top-left"
          ngbTooltip="{{ hiddenFiles }}"
          animation="true"
          [openDelay]="openDelayTooltip"
        >
          ещё {{ files.length - enabledFilesCount - 1 }}</span
        >
      </ng-container>
    </span>
  </div>
</div>
<div class="modal-body">
  <div class="share-employee-access">
    <div class="share-employee-access__out">
      <div class="share-employee-access__title dc-icon-filter-button">
        Сотрудники без доступа
        <ng-container *ngIf="!readonly">
          <button
            type="button"
            class="dc-icon-button share-employee-access__filter-icon"
            [class.active]="unSharedFilterConfig"
            (click)="toggleFilter(filterType.UNSHARED, true)"
          >
            <app-svg-icon [width]="12" [height]="12" name="filter"></app-svg-icon>
          </button>
          <button
            *ngIf="unSharedFilterConfig"
            (click)="resetFilter(filterType.UNSHARED)"
            type="button"
            class="dc-icon-button share-employee-access__filter-clear"
          >
            <app-svg-icon [width]="12" [height]="12" name="close_wide"></app-svg-icon>
          </button>
        </ng-container>
      </div>
      <div *ngIf="!readonly" class="share-employee-access__search">
        <app-file-manager-search
          (onSearch)="searchFilter($event, filterType.UNSHARED)"
          placeholder="Начните вводить ФИО сотрудника"
        ></app-file-manager-search>
      </div>
      <div class="share-employee-access__tree">
        <div *ngIf="!readonly" class="share-employee-access__tree-all" [class.disabled]="isSelectAllDisabled">
          <input
            *ngIf="selected"
            [checked]="selected[fileManagerShareUserType.DISALLOW]"
            [disabled]="loading[fileManagerShareUserType.DISALLOW]"
            (change)="selectAllUsers(fileManagerShareUserType.DISALLOW)"
            [id]="'selected_' + fileManagerShareUserType.DISALLOW"
            class="cbx-input"
            type="checkbox"
          />
          <label class="cbx-label" [for]="'selected_' + fileManagerShareUserType.DISALLOW">
            <span class="margin-l-10">Выделить всё/снять выделение</span>
          </label>
        </div>
        <div class="share-employee-access__tree-content">
          <tree-root #disallowTree [nodes]="disallowUsers" [options]="treeOptions" class="dc-tree">
            <ng-template #treeNodeTemplate let-node let-index="index">
              <app-file-manager-share-user-tree-item
                [node]="node"
                [item]="node.data"
                [treeOptions]="treeOptions"
                [type]="fileManagerShareUserType.DISALLOW"
                [readonly]="readonly"
                [detached]="isDetached(fileManagerShareUserType.DISALLOW, node.data.id)"
                (onToggle)="expandItem(disallowTree.treeModel, node, $event)"
                (onInfo)="openUserInfo($event)"
                (onCompetence)="openUserCompetence($event)"
                (onAdd)="addSelectedUsersFromTo(fileManagerShareUserType.DISALLOW, fileManagerShareUserType.ALLOW)"
              >
              </app-file-manager-share-user-tree-item>
            </ng-template>
          </tree-root>
        </div>
      </div>
    </div>
    <div class="share-employee-access__actions">
      <ng-container *ngIf="!readonly">
        <app-svg-icon
          [class.disabled]="!(getSelectedUsers(fileManagerShareUserType.ALLOW).length > 0)"
          (click)="addSelectedUsersFromTo(fileManagerShareUserType.ALLOW, fileManagerShareUserType.DISALLOW)"
          class="share-employee-access__actions-control margin-b-10"
          [width]="20"
          [height]="20"
          name="caret-square-left"
        ></app-svg-icon>
        <app-svg-icon
          [class.disabled]="!(getSelectedUsers(fileManagerShareUserType.DISALLOW).length > 0)"
          (click)="addSelectedUsersFromTo(fileManagerShareUserType.DISALLOW, fileManagerShareUserType.ALLOW)"
          class="share-employee-access__actions-control"
          [width]="20"
          [height]="20"
          name="caret-square-right"
        ></app-svg-icon>
      </ng-container>
    </div>
    <div class="share-employee-access__in">
      <div class="share-employee-access__title dc-icon-filter-button">
        Сотрудники с доступом
        <ng-container *ngIf="!readonly">
          <button
            type="button"
            class="dc-icon-button share-employee-access__filter-icon"
            [class.active]="sharedFilterConfig"
            [class.disabled]="!allowUsers?.length && !querySearches[1]?.length"
            (click)="toggleFilter(filterType.SHARED, true)"
          >
            <app-svg-icon [width]="12" [height]="12" name="filter"></app-svg-icon>
          </button>
          <button
            *ngIf="sharedFilterConfig"
            (click)="resetFilter(filterType.SHARED)"
            type="button"
            class="dc-icon-button share-employee-access__filter-clear"
          >
            <app-svg-icon [width]="12" [height]="12" name="close_wide"></app-svg-icon>
          </button>
        </ng-container>
      </div>
      <div *ngIf="!readonly" class="share-employee-access__search">
        <app-file-manager-search
          (onSearch)="searchFilter($event, filterType.SHARED)"
          placeholder="Начните вводить ФИО сотрудника"
        ></app-file-manager-search>
      </div>
      <div class="share-employee-access__tree">
        <div
          *ngIf="!readonly"
          class="share-employee-access__tree-all"
          [class.disabled]="isSelectAllDisabled || !allowUsers.length"
        >
          <input
            *ngIf="selected"
            [checked]="selected[fileManagerShareUserType.ALLOW]"
            [disabled]="loading[fileManagerShareUserType.ALLOW]"
            (change)="selectAllUsers(fileManagerShareUserType.ALLOW)"
            [id]="'selected_' + fileManagerShareUserType.ALLOW"
            class="cbx-input"
            type="checkbox"
          />
          <label class="cbx-label" [for]="'selected_' + fileManagerShareUserType.ALLOW">
            <span class="margin-l-10">Выделить всё/снять выделение</span>
          </label>
        </div>
        <div class="share-employee-access__tree-content">
          <app-file-manager-share-user-tree-item
            *ngFor="let allowUser of allowUsers"
            [item]="allowUser"
            [treeOptions]="treeOptions"
            [readonly]="readonly"
            [detached]="false"
            [type]="fileManagerShareUserType.ALLOW"
            (onInfo)="openUserInfo($event)"
            (onCompetence)="openUserCompetence($event)"
            (onAdd)="addSelectedUsersFromTo(fileManagerShareUserType.ALLOW, fileManagerShareUserType.DISALLOW)"
          >
          </app-file-manager-share-user-tree-item>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!readonly" class="modal-footer">
  <div class="dc-footer-buttons">
    <button class="btn btn-dc-outline" (click)="cancel()">Отменить</button>
    <button class="btn btn-dc-primary" (click)="save()">Сохранить</button>
  </div>
</div>

<div *ngIf="isSharedFilterOpen || isUnSharedFilterOpen" class="share-employee-access__filter">
  <app-file-manager-share-user-filter
    [filterConfig]="filterConfigByOpen"
    (closeEvent)="toggleFilter(filterType.ALL, false)"
    (shareUserFilterEvent)="changeFilter($event)"
  ></app-file-manager-share-user-filter>
</div>
