import { ComponentFactoryResolver, Inject, Injectable } from '@angular/core';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from '@app/app.component';
import { ModalWindowComponent } from '@app/shared/components/modal-window/modal-window.component';
import { ProjectBaseComponent } from '@app/+competence-map/models/project.class';
import { CMActions } from '../constants/projects.constants';
import { UnsavedConfirmLeaveComponent } from '@app/+competence-map/competence-shared/unsaved-confirm-leave/unsaved-confirm-leave.component';

import { DYNAMIC_PROJECT_CONTRACT } from '@app/+competence-map/constants/dynamic-projects.constants';

@Injectable()
export class ProjectsService {
  constructor(
    @Inject(AppComponent) private root: AppComponent,
    private modalService: NgbModal,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  openProject(
    action: CMActions,
    data: any
  ): {
    modalRef: ModalWindowComponent;
    projectRef: ProjectBaseComponent;
  } {
    this.root.projectModalContainer.clear();

    const contract = DYNAMIC_PROJECT_CONTRACT[action];

    const modalFactory = this.componentFactoryResolver.resolveComponentFactory(ModalWindowComponent);
    const projectFactory = this.componentFactoryResolver.resolveComponentFactory(contract.componentType);
    const projectRef = <any>this.root.projectModalContainer.createComponent(projectFactory);

    projectRef.instance = Object.assign(projectRef.instance, data);

    const modalRef = this.root.projectModalContainer.createComponent(modalFactory, 0, undefined, [
      [projectRef.location.nativeElement],
    ]);

    modalRef.instance.isShowed = true;
    modalRef.instance.customClass = contract.modalClasses;

    modalRef.instance.beforeDismiss = () => {
      // TODO удалить комменты если ничего не сломалось после тестов

      // TODO: нужно во всех компонентах добавить идентификатор на не сохраненные изменения
      // TODO: formFooter - исключение для БШФ
      // if (projectRef.instance.formFooter && projectRef.instance.formFooter.dirty) {
      //   console.log('if1');
      //   const modal = this.showUnsavedMessage();
      //   modal.result.then(({ exit }) => {
      //     if (exit) {
      //       modalRef.instance.ngbModalRef.close();
      //     }
      //   });
      // }

      if (projectRef.instance.form && projectRef.instance.form.dirty) {
        const modal = this.showUnsavedMessage();
        modal.result.then(({ exit }) => {
          if (exit) {
            modalRef.instance.ngbModalRef.close();
          }
        });
      } else {
        modalRef.instance.ngbModalRef.close();
      }
      return false;
    };

    return {
      modalRef: modalRef.instance,
      projectRef: projectRef.instance,
    };
  }

  private showUnsavedMessage(): NgbModalRef {
    return this.modalService.open(UnsavedConfirmLeaveComponent, {
      windowClass: 'dc-modal faq-modal modal-window',
      centered: true,
      backdropClass: 'creating-modal-bg',
      backdrop: 'static',
    });
  }
}
