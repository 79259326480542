import { Role } from '../models/user.model';

export enum RolesEnum {
  PARTNER = 'partner',
  ADMIN_OF_USER = 'admin_of_user',
  ADMIN_OF_DIRECTION = 'admin_of_direction',
  OPERATOR = 'operator',
  ACCOUNTANT = 'accountant',
  EXPERT = 'expert',
  SUPERUSER = 'superuser',
  TSO = 'tso',
}

export const ROLES: Role[] = [
  {
    title: 'Админ. пользователя',
    fullTitle: 'Администратор пользователя',
    value: RolesEnum.ADMIN_OF_USER,
    shortTitle: 'АП',
  },
  {
    title: 'Админ. направления',
    fullTitle: 'Администратор направления',
    value: RolesEnum.ADMIN_OF_DIRECTION,
    shortTitle: 'АН',
  },
  { title: 'Оператор', fullTitle: 'Оператор', value: RolesEnum.OPERATOR, shortTitle: 'Оператор' },
  {
    title: 'Админ. тех.поддержки',
    fullTitle: 'Администратор технической поддержки',
    value: RolesEnum.SUPERUSER,
    shortTitle: 'АТП',
  },
  { title: 'Бухгалтер', fullTitle: 'Бухгалтер', value: RolesEnum.ACCOUNTANT, shortTitle: 'Бухгалтер' },
  { title: 'Эксперт', fullTitle: 'Эксперт', value: RolesEnum.EXPERT, shortTitle: 'Эксперт' },
  { title: 'Агент', fullTitle: 'Агент', value: RolesEnum.PARTNER, shortTitle: 'Агент' },
];
