import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CompetenceService } from '@app/+competence-map/services/competence.service';
import { Project } from '@app/+competence-map/models/projects.models';
import {
  CMActions,
  PROJECT_ACTION_LABELS,
  ProjectColumnKeys,
  ProjectLogActions,
} from '@app/+competence-map/constants/projects.constants';
import { AuthService } from '@app/shared/services/auth.service';
import { RolesEnum } from '@app/shared/constants/roles.constants';
import { Column } from '@app/shared/models/table';
import { FlaskQueryFilter } from '@app/shared/models/filters.model';
import { PROJECT_LOGS_COLUMNS } from '@app/+competence-map/constants/project-logs.constants';
import { Lexicon } from '@app/+competence-map/models/words.model';
import { ProjectsService } from '@app/+competence-map/services/projects.service';
import { ProjectBaseComponent } from '@app/+competence-map/models/project.class';
import { DestroyService } from '@app/services/destroy.service';
import { takeUntil } from 'rxjs/operators';
import { CMCatalogTypes } from '@app/+competence-map/constants/sections.constants';
import { CATALOG_LABELS } from '@app/+competence-map/constants/competence-map.constants';

@Component({
  selector: 'app-project-logs',
  templateUrl: './project-logs.component.html',
  styleUrls: ['./project-logs.component.scss'],
  providers: [ProjectsService, DestroyService],
})
export class ProjectLogsComponent implements OnInit, OnDestroy {
  @Input() title = '';
  @Input() projectTypes: CMActions[] = [];
  @Input() catalog: CMCatalogTypes;

  projectTypesFilterItems = [];

  projects: Project[] = [];
  isLoading: boolean;
  projectActionLabels = PROJECT_ACTION_LABELS;
  allChecked: boolean;
  isExpert: boolean;

  columnKeys = ProjectColumnKeys;

  viewedData = {};
  columns: Column<FlaskQueryFilter[]>[] = PROJECT_LOGS_COLUMNS;
  filterConfig: FlaskQueryFilter[] = [];

  constructor(
    private competenceService: CompetenceService,
    private authService: AuthService,
    private projectsService: ProjectsService,
    private destroy$: DestroyService
  ) {
    this.isExpert = this.authService.user_type === RolesEnum.EXPERT;
  }

  ngOnInit(): void {
    this.projectTypesFilterItems = [...this.projectTypes].map((item) => {
      return {
        id: item,
        name: PROJECT_ACTION_LABELS[item],
      };
    });

    this.loadProjects();
  }

  getRowColumn(row: Lexicon) {
    return this.columns.find((column) => row[column.id]);
  }

  loadProjects() {
    this.isLoading = true;

    if (this.catalog) {
      this.filterConfig.push({
        name: 'path',
        op: 'startswith',
        val: `${CATALOG_LABELS[this.catalog]}/`,
      });
    }

    this.competenceService
      .getProjects(
        this.filterConfig.concat({
          name: 'action',
          op: 'in_',
          val: this.projectTypes,
        })
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (value) => {
          this.projects = value;
          this.projects.sort((a, b) => {
            return a.logs.length - b.logs.length;
          });

          this.projects.forEach((project) => {
            this.viewedData[project.id] = this.isViewed(project);
          });

          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  viewProjects() {
    Object.keys(this.viewedData)
      .filter((projectId) => !!this.viewedData[projectId])
      .forEach((projectId) => {
        this.viewProject(projectId);
      });
  }

  isViewed(project: Project): boolean {
    return !!project.logs.find((log) => log.action === ProjectLogActions.VIEWED);
  }

  handleAllCheck() {
    Object.keys(this.viewedData)
      .filter((projectId) => {
        const project = this.projects.find((item) => +item.id === +projectId);

        return !this.isViewed(project);
      })
      .forEach((projectId) => {
        this.viewedData[projectId] = !!this.allChecked;
      });
  }

  handleCheck(projectId: number) {
    if (!this.viewedData[projectId]) {
      this.allChecked = false;
    }
  }

  filterProjects(event: Column<FlaskQueryFilter[]>) {
    this.filterConfig = this.filterConfig.filter((config) => config.name !== event.id).concat(event.filterConfig);

    this.loadProjects();
  }

  hasChanges() {
    return this.projects.some((project) => {
      return !this.isViewed(project) && this.viewedData[project.id];
    });
  }

  viewProject(projectId: string) {
    this.competenceService
      .updateProjectLog({
        project_id: projectId,
        action: ProjectLogActions.VIEWED,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.loadProjects();
      }, this.competenceService.error);
  }

  openProject(project: Project) {
    if (!this.isViewed(project)) {
      this.viewProject(project.id.toString());
    }

    let ref: ProjectBaseComponent = null;
    switch (project.action) {
      case CMActions.COPY_TEMPLATE_STRUCTURE_FILTER:
      case CMActions.CREATE_TEMPLATE_STRUCTURE_FILTER:
      case CMActions.CHANGE_TEMPLATE_STRUCTURE_FILTER:
      case CMActions.UNION_FILTER:
      case CMActions.SPLIT_TEMPLATE_STRUCTURE_FILTER: {
        ref = this.projectsService.openProject(project.action, {
          projectData: project,
        }).projectRef;
        break;
      }
      default: {
        ref = this.projectsService.openProject(project.action, {
          project,
        }).projectRef;
        break;
      }
    }

    ref.reloadEvent.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.loadProjects();
    });
  }

  ngOnDestroy() {
    this.competenceService.updateProjectLogsLength();
  }
}
