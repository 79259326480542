import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({ selector: '[mouseoverTime]' })
export class MouseoverTimeDirective {
  @Output('mouseoverTime') mouseoverTime = new EventEmitter();
  @Input() mouseoverTimeInSec: number = 1;
  event;

  constructor() {}

  @HostListener('mouseover', ['$event'])
  mouseoverEvent(event) {
    this.event = setTimeout(() => this.mouseoverTime.emit(), this.mouseoverTimeInSec * 1000);
  }

  @HostListener('mouseout', ['$event'])
  mouseoutEvent(event) {
    clearTimeout(this.event);
  }
}
