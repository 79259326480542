import { Component } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-welcome-contract-modal',
  templateUrl: './welcome-contract-modal.component.html',
  styleUrls: ['./welcome-contract-modal.component.scss'],
})
export class WelcomeContractModalComponent {
  constructor(private modalService: NgbModal, private activeModal: NgbActiveModal, private router: Router) {}

  public close(): void {
    this.activeModal.close();
  }

  public success(): void {
    this.router.navigate(['profile', 'contract']).then(() => this.close());
  }
}
