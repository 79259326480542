import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileManagerComponent } from './file-manager/file-manager.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FileManagerTreeComponent } from './file-manager-tree/file-manager-tree.component';
import { FileManagerService } from './services/file_manager.service';
import { SharedModule } from '@app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FileManagerToolbarComponent } from './file-manager-toolbar/file-manager-toolbar.component';
import { FileManagerAreaComponent } from './file-manager-area/file-manager-area.component';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UserFileZipPipe } from './pipes/file_manager';
import { HighlightSearchPipe } from './pipes/highlight-search.pipe';
import { FileManagerLightComponent } from './file_manager_light/file-manager-light.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SVGSpriteModule } from '@app/svg-sprite/svg-sprite.module';
import { TreeModule } from '@circlon/angular-tree-component';
import { ShareUsersFormModule } from './share-users-form/share-users-form.module';
import { FileItemListComponent } from './file-item-list/file-item-list.component';
import { FileItemDetailComponent } from './file-item-detail/file-item-detail.component';

library.add(fas, far);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './i18n/', '.json');
}

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgbModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    SVGSpriteModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ClickOutsideModule,
    MatProgressBarModule,
    TreeModule,
    ShareUsersFormModule,
  ],
  declarations: [
    FileManagerComponent,
    FileManagerTreeComponent,
    FileManagerToolbarComponent,
    FileManagerAreaComponent,
    UserFileZipPipe,
    HighlightSearchPipe,
    FileManagerLightComponent,
    FileItemListComponent,
    FileItemDetailComponent,
  ],
  exports: [
    FileManagerComponent,
    FileManagerTreeComponent,
    FileManagerLightComponent,
    FileManagerToolbarComponent,
    FileManagerAreaComponent,
    SharedModule,
    NgbModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FileItemListComponent,
    FileItemDetailComponent,
    HighlightSearchPipe,
  ],
  providers: [FileManagerService],
})
export class FileManagerPublicModule {}
