import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

import { NotificationComponent } from './notification.component';
import { SharedModule } from '@app/shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CompetenceMapModule } from '@app/+competence-map/competence-map.module';
import { RouterModule } from '@angular/router';
import { NotificationListComponent } from '@app/notification/notification-list/notification-list.component';
import { NotificationSettingComponent } from '@app/notification/notification-setting/notification-setting.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    FontAwesomeModule,
    SharedModule,
    NgbModule,
    CompetenceMapModule,
    RouterModule,
  ],
  declarations: [NotificationComponent, NotificationListComponent, NotificationSettingComponent],
  exports: [NotificationComponent],
})
export class NotificationModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far);
  }
}
