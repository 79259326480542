import { Component, Input } from '@angular/core';

import { FileManagerTreeItemComponent } from '../file-manager-tree-item/file-manager-tree-item.component';

@Component({
  selector: 'app-file-manager-tree-folder',
  templateUrl: './file-manager-tree-folder.component.html',
  styleUrls: ['./file-manager-tree-folder.component.scss'],
})
export class FileManagerTreeFolderComponent extends FileManagerTreeItemComponent {
  @Input() isRoot: boolean = false;

  get isShowIcon(): boolean {
    if (this.file?.virtualShared && !this.isRoot) {
      return true;
    }
    if (
      (this.file?.inner_sharing && !this.isRoot) ||
      (this.file?.is_shared && !this.file?.virtualShared && !this.isRoot)
    ) {
      return true;
    }
    return this.isRoot;
  }
}
