import { Pipe, PipeTransform } from '@angular/core';
import {DatesHelper} from '../helpers/dates.helper';

@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {

  transform(value: any, offset?: any): any {
    return DatesHelper.formatTime(value, offset);
  }

}
