<button class="dc-toolbar-btn mr-0" (click)="isOpened = true" [disabled]="disabled">
  <fa-icon [icon]="['fas', 'question']"></fa-icon>
</button>
<app-modal-window [isShowed]="isOpened" (closeEvent)="isOpened = false">
  <div class="text-center">
    <h5>Помощь</h5>
    <p>Модальное окно с помощью</p>
    <div class="d-flex justify-content-center">
      <button class="btn btn-dc-outline" (click)="isOpened = false">Закрыть</button>
    </div>
  </div>
</app-modal-window>
