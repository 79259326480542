import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'ratingValue',
})
export class RatingValuePipe extends DecimalPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return super.transform(value, '1.0-0', args) || '–';
  }
}
