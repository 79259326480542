<div class="dc-modal-x-button dc-fa-button" (click)="close()">
  <app-svg-icon [height]="20" [width]="20" name="close"></app-svg-icon>
</div>
<div class="modal-window-content">
  <div class="modal-header">
    <div class="modal-title text-left">Создать пароль</div>
  </div>

  <div class="modal-body">
    <form [formGroup]="form" class="profile-password-form" (ngSubmit)="success()">
      <div class="form-body">
        <dc-form-field-wrapper [style]="'bootstrap'">
          <dc-form-label>Введите новый пароль:</dc-form-label>
          <app-password-form-input formInput formControlName="new_password"></app-password-form-input>
        </dc-form-field-wrapper>

        <dc-form-field-wrapper [style]="'bootstrap'">
          <dc-form-label>Подтвердите новый пароль:</dc-form-label>
          <app-password-form-input formInput formControlName="confirm_password"></app-password-form-input>
        </dc-form-field-wrapper>
      </div>
    </form>

    <div class="password-requirements" [ngClass]="{ success: passwordUpdated }">
      <p class="password-requirements-title">Требования к паролю:</p>
      <div
        class="password-requirement"
        [ngClass]="{
          invalid: newPasswordInvalid,
          valid: !newPasswordInvalid
        }"
      >
        <div class="password-requirement-icon">
          <app-svg-icon [height]="9" [width]="12" name="check"></app-svg-icon>
        </div>
        <div class="password-requirement-text">
          должен содержать минимум 6 символов, в том числе одну заглавную букву и одну цифру
        </div>
      </div>
      <div
        class="password-requirement"
        [ngClass]="{
          invalid: newPasswordInvalid,
          valid: !newPasswordInvalid
        }"
      >
        <div class="password-requirement-icon">
          <app-svg-icon [height]="9" [width]="12" name="check"></app-svg-icon>
        </div>
        <div class="password-requirement-text">
          пароль может содержать латинские буквы, буквы кириллицы, цифры, специальные знаки
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-dc-outline" (click)="close()">Отменить</button>
    <button type="submit" class="btn btn-dc-primary" [disabled]="form.invalid" (click)="success()">Сохранить</button>
  </div>
</div>
