<div class="modal-header justify-content-center">
  <div class="modal-title">{{ comments?.length ? 'Комментарии' : 'Добавить комментарий' }}</div>
</div>
<div class="modal-body">
  <ng-container *ngIf="comments?.length">
    <div class="comment-list">
      <ng-container *ngFor="let comment of comments; let i = index">
        <div class="comment" [class.comment-self]="userId === comment?.author?.id">
          <div class="comment-header">
            <div class="comment-header-author">{{ comment?.author | initials }}</div>
            <div class="comment-header-date">{{ comment?.created_at * 1000 | dateTimeFormat }}</div>
            <div *ngIf="userId === comment?.author?.id" class="comment-header-edit">
              <a (click)="editComment(comment, i)">
                <app-svg-icon name="edit" [height]="16" [width]="16"></app-svg-icon>
              </a>
            </div>
          </div>
          <div class="comment-body">
            {{ comment.value }}
          </div>
        </div>
      </ng-container>
    </div>

    <div class="new-comment" *ngIf="!isCommentForm">
      <a (click)="newComment()">
        <app-svg-icon class="margin-l-10" name="plus" [height]="14" [width]="14"></app-svg-icon>
        &nbsp;Добавить комментарий
      </a>
    </div>
  </ng-container>
</div>
<div class="modal-footer">
  <ng-container *ngIf="isCommentForm">
    <dc-form-field-wrapper [style]="'bootstrap'" [vertically]="false">
      <dc-form-label>
        {{
          isEditComment ? 'Редактировать комментарий' : comments?.length ? 'Добавить комментарий' : 'Текст комментария'
        }}
      </dc-form-label>
      <dc-form-input [maxLength]="100" [isShowLength]="true">
        <textarea formInput [formControl]="commentField" [maxLength]="100"></textarea>
      </dc-form-input>
    </dc-form-field-wrapper>

    <div class="dc-footer-buttons">
      <button class="btn btn-dc-outline" type="button" (click)="cancel()">Отменить</button>
      <button class="btn btn-dc-primary" type="button" (click)="saveComment()">Сохранить</button>
    </div>
  </ng-container>
</div>
