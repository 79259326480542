import { UserTypes } from '@app/shared/types/user.types';
import { getTitleRole } from '@app/shared/utils';
import { formatSectionsStringByOneLevel } from '@app/+competence-map/helpers/competence-map.helpers';
import { Company } from '@app/shared/models/company.model';

export class AuthorFile {
  id: number;
  is_owner: boolean;
  children: AuthorFile[];
  first_name: string;
  second_name: string;
  type: UserTypes;
  parent_id: number;
  parent: AuthorFile[];
  typeLabel: string;
  competenciesString: string;
  company: Company;
  phones: string[];
  selected?: boolean = false;
  constructor(data: any) {
    this.id = data.id || null;
    this.first_name = data.first_name || null;
    this.second_name = data.second_name || null;
    this.is_owner = data.is_owner || false;
    this.parent_id = data.parent_id || null;
    this.parent = data.parent || [];
    this.children = data.children || [];
    this.typeLabel = getTitleRole(data.type);
    this.competenciesString = formatSectionsStringByOneLevel(data.competence_sections, ' / ', '');
    this.company = data.company;
    this.phones = data.phones;
    this.selected = data.selected || false;
  }
}
