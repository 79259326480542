import {Directive, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import * as noUiSlider from 'nouislider';
import {SliderValue} from '../models/slider.model';

@Directive({
  selector: '[appDcSlider]'
})

export class SliderDirective implements OnInit, OnChanges {
  @Output() onChange: EventEmitter<SliderValue> = new EventEmitter<SliderValue>();
  @Output() afterRangeChange: EventEmitter<SliderValue> = new EventEmitter<SliderValue>();
  @Input() minRange: string = '0';
  @Input() maxRange: string = '100';
  @Input() unit: string = '';
  @Input() value: SliderValue = {
    min: 0,
    max: 0
  };

  sliderDiv: any;
  resultLabelsDiv: any;

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit() {
    this.initSlider();
  }

  ngOnChanges() {
    if (this.sliderDiv) {
      this.sliderDiv.noUiSlider.updateOptions({
        start: [this.value.min, this.value.max],
      });
      this.updateResultLabels(this.resultLabelsDiv, this.value);
    }

  }

  initSlider() {
    let minRange = parseInt(this.minRange, 10);
    let maxRange = parseInt(this.maxRange, 10);
    this.sliderDiv = document.createElement('div');
    if (minRange >= maxRange) {
      minRange = 0;
      maxRange = 100;
      this.elementRef.nativeElement.classList.add('disabled');
    }

    noUiSlider.create(this.sliderDiv, {
      start: [this.value.min, this.value.max],
      connect: true,
      step: 1,
      range: {
        'min': minRange,
        'max': maxRange
      }
    });

    const labelsDiv = this.createLabelsDiv(this.minRange, this.maxRange);
    this.resultLabelsDiv = this.createResultLabelsDiv(this.value.min.toString(), this.value.max.toString());
    this.elementRef.nativeElement.append(labelsDiv);
    this.elementRef.nativeElement.append(this.sliderDiv);
    this.elementRef.nativeElement.append(this.resultLabelsDiv);

    this.sliderDiv.noUiSlider.on('update', (values) => {
      const newVal = {
        min: parseInt(values[0], 10),
        max: parseInt(values[1], 10),
      };

      this.onChange.next(newVal);
      this.updateResultLabels(this.resultLabelsDiv, newVal);
    });

    this.sliderDiv.noUiSlider.on('change', (values) => {
      this.onChangeCallback(values);
    });

    this.resultLabelsDiv.querySelector('input.min').addEventListener('change', (e: any) => {
      let newVal = Number(e.target.value);
      if (newVal < minRange) {
        newVal = minRange;
      } else if (newVal > maxRange) {
        newVal = maxRange;
      }

      if (newVal > this.value.max) {
        this.value.max = newVal;
      }

      this.sliderDiv.noUiSlider.set([newVal, this.value.max]);
      this.onChangeCallback([newVal, this.value.max]);
    });

    this.resultLabelsDiv.querySelector('input.max').addEventListener('change', (e: any) => {
      let newVal = Number(e.target.value);

      if (newVal < minRange) {
        newVal = minRange;
      } else if (newVal > maxRange) {
        newVal = maxRange;
      }

      if (newVal < this.value.min) {
        this.value.min = newVal;
      }
      this.sliderDiv.noUiSlider.set([this.value.min, newVal]);
      this.onChangeCallback([this.value.min, newVal]);
    });
  }

  private updateResultLabels(labelsDiv: HTMLDivElement, val: SliderValue) {
    this.value.min = val.min;
    this.value.max = val.max;
    labelsDiv.querySelector('.min-result').querySelector('input').value = String(val.min);
    labelsDiv.querySelector('.min-result').querySelector('span').innerText = this.unit;

    labelsDiv.querySelector('.max-result').querySelector('input').value = String(val.max);
    labelsDiv.querySelector('.max-result').querySelector('span').innerText = this.unit;
  }

  private createLabelsDiv(min: string, max: string) {
    const labelsDiv = document.createElement('div');
    labelsDiv.classList.add('dc-slider-labels');
    labelsDiv.classList.add('inline');
    labelsDiv.classList.add('middle');
    labelsDiv.classList.add('space-between');

    const label1 = document.createElement('span');
    label1.classList.add('min');
    label1.innerText = min;

    const label2 = document.createElement('span');
    label2.classList.add('max');
    label2.innerText = max;

    labelsDiv.appendChild(label1);
    labelsDiv.appendChild(label2);
    return labelsDiv;
  }

  private onChangeCallback(values): void {
    const newVal = {
      min: parseInt(values[0], 10),
      max: parseInt(values[1], 10),
    };

    this.afterRangeChange.next(newVal);
  }

  private createResultLabelsDiv(min: string, max: string) {
    const labelsDiv = document.createElement('div');
    labelsDiv.classList.add('dc-slider-result-labels');

    const div1 = document.createElement('div');
    div1.classList.add('min-result');
    div1.innerHTML = `<div>
      <input class="min" value="${min}" class="kt-small-input" />
    </div>
    <div><span>${this.unit}</span></div>`;

    const div2 = document.createElement('div');
    div2.classList.add('max-result');
    div2.innerHTML = `<div><input class="max" value="${max}" class="kt-small-input" /></div>
    <div><span>${this.unit}</span></div>`;

    labelsDiv.appendChild(div1);
    labelsDiv.appendChild(div2);
    return labelsDiv;
  }
}
