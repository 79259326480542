import {Component, Input, Output, EventEmitter, ViewEncapsulation} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NotificationsService } from 'angular2-notifications';
import { EventModel, STATE } from '@app/shared/components/calendar-widget/constants/calendar.constants';
import { CalendarDataService } from '@app/shared/services/calendar/calendar-data.service';
import { ModalInjectorService } from '@app/modal-injector/services/modal-injector.service';

@Component({
  selector: 'app-delete-event',
  templateUrl: './delete-event.component.html',
  styleUrls: ['./delete-event.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DeleteEventComponent {
  @Input() id;
  @Input() event;
  @Input() showModal: boolean;
  @Output() showModalChange = new EventEmitter<boolean>();

  constructor(
    private calendarDataService: CalendarDataService,
    private notify: NotificationsService,
    private modalInjectorService: ModalInjectorService,
  ) {}

  closeEvent() {
    this.showModal = false;
    this.showModalChange.emit(this.showModal);
    this.modalInjectorService.closeModal(this.id);
  }

  deleteEvent() {
    this.calendarDataService.updateEvent(this.event.id, { status: STATE.DELETED }).subscribe(
      (res: EventModel[]) => {
        this.notify.success('Успешно!', 'Событие удалено');
        this.calendarDataService.updateDataAll();
        this.showModal = false;
        this.showModalChange.emit(this.showModal);
        this.modalInjectorService.closeModal(this.id);
      },
      (error) => {
        this.notify.error('Ошибка!', error);
        this.showModal = false;
        this.showModalChange.emit(this.showModal);
        this.modalInjectorService.closeModal(this.id);
      }
    );
  }
}
