<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="version">
        <fa-icon class="href" [icon]="['fas', 'chevron-left']"
                 (click)="previous_versions.hidden = !previous_versions.hidden;version_describe.hidden = true"></fa-icon>
        <div style="display: inline-block" #previous_versions hidden>
          previous versions:
          <a *ngFor="let version of keys(VERSIONS_DESCRIBE).slice(0,-1)" href="#"
             (click)="$event.preventDefault();selectVersion = version;version_describe.hidden = false">
            {{version}}
          </a>
        </div>
        current version:
        <a href="#" (click)="$event.preventDefault();selectVersion = appVersion;version_describe.hidden = false"> {{appVersion}}</a> build: {{build}}
        <div class="version-describe" #version_describe hidden>
          <fa-icon class="ico-close" [icon]="['fas', 'times']" (click)="version_describe.hidden = true"></fa-icon>
          <div class="header">
            В сборку {{selectVersion}} вошли задачи:
          </div>

          <div class="body">
            <div *ngFor="let describe of VERSIONS_DESCRIBE[selectVersion]">
              <a *ngIf="describe.href" target="_blank" href="{{describe.href}}">{{describe.title}}</a>
              <div *ngIf="!describe.href">{{describe.title}}</div>
            </div>
          </div>
        </div>
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
