<div
  (click)="onGroupSelect(group)"
  [routerLink]="group.room_id"
  [class.selected]="contactSelected?.room_id === group.room_id || groupSelected?.room_id === group.room_id"
  (mouseleave)="group.menuExpanded = false; group.menuShown = false"
  class="group"
>
  <div class="d-flex align-items-center">
    <a (click)="$event.stopPropagation(); onToggleGroupExpanded()" class="mr-2" [class.disabled]="!themes.length">
      <fa-icon [icon]="['fas', group.isGroupExpanded ? 'chevron-up' : 'chevron-down']"></fa-icon>
    </a>
    <div (mouseenter)="$event.stopPropagation(); group.menuShown = true" class="group-title">
      <div class="group-title-text text-overflow">
        <span class="text-overflow" [title]="group.title">Группа {{ group.title }}</span>
      </div>
      <span *ngIf="themes.length" class="chat-number">
        {{ themes.length }}
      </span>
      <span *ngIf="userId === group.owner_id" class="group__crown">
        <fa-icon [icon]="['fas', 'crown']"></fa-icon>
      </span>
    </div>
  </div>
  <div>
    <div
      *ngIf="canEditGroup()"
      (mouseenter)="$event.stopPropagation(); group.menuExpanded = true"
      (mouseleave)="group.menuExpanded = false"
      class="group-menu"
    >
      <a *ngIf="chatSectionSelected.name !== chatSectionsEnum.TECH" (click)="onGroupEdit($event)">
        <fa-icon [icon]="['fas', 'cog']"></fa-icon>
      </a>

      <div
        [ngClass]="group.menuExpanded || chatSectionSelected.name === chatSectionsEnum.TECH ? '' : 'hidden'"
        (mouseenter)="$event.stopPropagation(); group.menuShown = true; group.menuExpanded = true"
        (mouseleave)="group.menuExpanded = false"
      >
        <div>
          <a (click)="onAddTheme($event)" *ngIf="userId === group.owner_id">
            <fa-icon [icon]="['fas', 'plus']"></fa-icon>
          </a>
        </div>
        <div
          *ngIf="
            chatSectionSelected.name !== chatSectionsEnum.TECH && chatSectionSelected.name !== chatSectionsEnum.TRADE
          "
        >
          <a (click)="onRemoveGroupAsk($event)" *ngIf="userId === group.owner_id">
            <fa-icon [icon]="['fas', 'trash']"></fa-icon>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<div>
  <div *ngFor="let theme of themes">
    <app-chat-theme
      *ngIf="group.isGroupExpanded"
      [theme]="theme"
      [group]="group"
      [userId]="userId"
      [contactSelected]="contactSelected"
    ></app-chat-theme>
  </div>
</div>

<app-modal-window [isShowed]="isReallyRemoving" (closeEvent)="isReallyRemoving = false" customClass="confirm-modal">
  <p class="mb-0">Удалить группу</p>
  <p class="mb-5 text-overflow">
    <b [title]="group.title">Группа {{ group.title }}</b>
  </p>
  <div class="d-flex justify-content-between">
    <button class="btn btn-dc-outline" (click)="isReallyRemoving = false">Отменить</button>
    <button class="btn btn-dc-primary" (click)="onRemoveGroup()">Удалить</button>
  </div>
</app-modal-window>
