<div class="card shadow">
  <div class="card-body">
    {{ userInfo.company | companyName }}
    <br />
    ID: {{ userInfo.company.id }} ИНН: {{ userInfo.company.itn }}
    <br />
    {{ userInfo.second_name }} {{ userInfo.first_name }}
    <button class="icon-button" (click)="navigateToChat()" *ngIf="isShowChatIcon">
      <fa-icon [icon]="['fas', 'comment']"></fa-icon>
    </button>
    <div *ngFor="let phone of userInfo.phones">
      {{ phone }}
    </div>
  </div>
</div>
