<div class="base-tree">
  <tree-root #tree [(nodes)]="data">
    <ng-template #treeNodeTemplate let-node>
      <div (click)="select(node)" [class.selected]="node.data.id === selected?.data?.id" class="base-tree-item">
        <app-svg-icon
          [name]="!node.isExpanded ? 'caret_down' : 'caret_up'"
          [class.disabled]="!node.hasChildren"
          [style.display]="node.level === 3 ? 'none' : 'initial'"
          (click)="TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event)"
          width="10"
        ></app-svg-icon>
        <div class="base-tree-item-title text-overflow">{{ node?.data?.title }}</div>
      </div>
    </ng-template>
    <ng-template #loadingTemplate>Загрузка...</ng-template>
  </tree-root>
</div>
