import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnsavedConfirmLeaveComponent } from './unsaved-confirm-leave.component';
import { SVGSpriteModule } from '../../../svg-sprite/svg-sprite.module';

@NgModule({
  declarations: [UnsavedConfirmLeaveComponent],
  exports: [UnsavedConfirmLeaveComponent],
  imports: [CommonModule, SVGSpriteModule],
})
export class UnsavedConfirmLeaveModule {}
