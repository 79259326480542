<div class="dc-modal-x-button dc-fa-button" (click)="close()">
  <app-svg-icon [height]="20" [width]="20" name="close"></app-svg-icon>
</div>
<div class="modal-window-content">
  <div class="modal-header">
    <div class="modal-title text-left">
      Выберите стартовую страницу:
    </div>
  </div>

  <div class="modal-body">
    <div class="radio-group">
      <ul class="list-unstyled">
        <li class="dc-radio-input" *ngFor="let menuItem of menuItems; let i = index">
          <input
            type="radio"
            name="residence"
            [value]="'value-' + i"
            [id]="'id-' + i"
            [attr.disabled]="menuItem.link === 'disable' || null"
            [(ngModel)]="pageSelected"
            (ngModelChange)="selectPage(menuItem)"
          />
          <label class="radio-label" [for]="'id-' + i">
            {{ menuItem.label }}
          </label>
        </li>
      </ul>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-dc-outline" (click)="close()">Отменить</button>
    <button type="button" class="btn btn-dc-primary" [disabled]="pageSelected === startPageSelected" (click)="success()">Сохранить
    </button>
  </div>
</div>
