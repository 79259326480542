import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DCTreeItem } from '@app/+competence-map/models/competence-map.models';
import { CompetenceService } from '@app/+competence-map/services/competence.service';
import { switchMap, takeUntil } from 'rxjs/operators';

import { Project } from '@app/+competence-map/models/projects.models';
import { CMCatalogTypes, CMSectionStatuses } from '@app/+competence-map/constants/sections.constants';
import { CATALOG_LABELS_FULL } from '@app/+competence-map/constants/competence-map.constants';

import { CMActions, CMProjectStatuses } from '@app/+competence-map/constants/projects.constants';
import { of } from 'rxjs';
import { TreeHelper } from '@app/shared/helpers/tree.helper';
import { TreeNode, TREE_ACTIONS, TreeModel } from '@circlon/angular-tree-component';
import { CompetenceSectionsService } from '@app/+competence-map/services/competence-sections.service';
import { ProjectBase, ProjectBaseComponent } from '@app/+competence-map/models/project.class';
import { PortalService } from '@app/shared/services/portal.service';
import { AuthService } from '@app/shared/services/auth.service';
import { DestroyService } from '@app/services/destroy.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-move-section',
  templateUrl: './move-section.component.html',
  styleUrls: ['./move-section.component.scss'],
  providers: [PortalService, DestroyService],
})
export class MoveSectionComponent extends ProjectBaseComponent implements OnInit, ProjectBase {
  @ViewChild('sourceTree') sourceTree;
  @ViewChild('destTree') destTree;

  @Input() catalog: CMCatalogTypes = CMCatalogTypes.GOODS;
  @Input() path: string;
  @Input() currentNode: TreeNode = {} as TreeNode;

  form: FormGroup;

  selected: TreeNode = {
    data: {},
  } as TreeNode;
  searchValue: string;
  sections: DCTreeItem[];
  targetSections: DCTreeItem[];

  destSections: DCTreeItem[];
  sectionStatuses = CMSectionStatuses;

  searchEvent = new EventEmitter();

  @Output() closeEvent = new EventEmitter();
  @Output() reloadEvent = new EventEmitter();

  constructor(
    private competenceService: CompetenceService,
    private competenceSectionsService: CompetenceSectionsService,
    private destroy$: DestroyService,
    protected portalService: PortalService,
    protected authService: AuthService,
    private fb: FormBuilder
  ) {
    super(portalService, authService);
  }

  get isNew() {
    return !this.project || (this.project && !this.project.id);
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  initSelected() {
    this.selected = {
      data: {
        id: (this.project && this.project.spec[this.project.action]?.parent_id) || '',
      },
    } as TreeNode;

    if (!this.isNew && !this.project.spec[this.project.action]?.parent_id) {
      this.selected.data.id = {
        data: {
          id: -1,
        },
      } as TreeNode;
    }

    if (!this.isNew) {
      this.currentNode.data = {
        id: (this.project && this.project.spec[this.project.action]?.id) || '',
      };
    }
  }

  initializeForm() {
    this.initSelected();

    this.form = this.fb.group({
      parent: [this.selected.data.id, Validators.required],
    });

    this.competenceSectionsService
      .getSections([
        {
          name: 'catalog',
          op: 'eq',
          val: this.catalog,
        },
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((sections) => {
        const result = sections.map((item) => {
          return {
            ...item,
            parent: {
              id: item.parent_id,
            },
          };
        });

        this.currentNode.data.status = result.find((item) => item.id === this.currentNode.data.id)?.status;

        const root = {
          title: CATALOG_LABELS_FULL[this.catalog],
          level: -1,
          id: -1,
          status: null,
          catalog: this.catalog,
          children: null,
        };

        this.sections = [
          {
            ...root,
            children: TreeHelper.list_to_tree(result),
          },
        ];

        this.targetSections = [
          {
            ...root,
            children: TreeHelper.list_to_tree(
              result
                .map((item) => ({ ...item }))
                .filter((section) => {
                  return (
                    section.level !== 3 &&
                    section.status !== CMSectionStatuses.ARCHIVE &&
                    section.id !== this.currentNode?.data?.id &&
                    section.id !== this.currentNode?.data?.parent_id &&
                    (section.status === this.currentNode.data.status ||
                      (section.status === CMSectionStatuses.ACTIVE &&
                        this.currentNode.data.status === CMSectionStatuses.DRAFT))
                  );
                })
            ),
          },
        ];
      });
  }

  addProject(status?: CMProjectStatuses): void {
    if (!this.selected) {
      return;
    }

    const params = {
      id: +this.currentNode.data.id,
      parent_id: this.selected.data.id,
    };

    if (this.selected.data.id === -1) {
      params.parent_id = null;
    }

    this.competenceSectionsService
      .moveSection(params)
      .pipe(
        switchMap((project) => {
          if (!status) {
            return of(project);
          } else {
            return this.competenceService.updateProject({
              id: project.id,
              status,
            });
          }
        })
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((project) => {
        this.project = project;

        this.reloadEvent.emit();
        this.competenceService.projectSuccess(status);
        this.form.markAsPristine();
      }, this.competenceService.error);
  }

  updateProject(status?: CMProjectStatuses) {
    const params = {
      id: +this.currentNode.data.id,
      parent_id: this.selected.data.id,
    };

    if (this.selected.data.id === -1) {
      params.parent_id = null;
    }

    this.competenceService
      .updateProject({
        id: this.project.id,
        spec: {
          [CMActions.MOVE_SECTION]: params,
        },
        status,
      } as Project)
      .pipe(takeUntil(this.destroy$))
      .subscribe((project) => {
        this.project = project;

        this.reloadEvent.emit();
        this.competenceService.projectSuccess(status);
        this.form.markAsPristine();
      }, this.competenceService.error);
  }

  filterSections() {
    this.destTree.treeModel.filterNodes((node: TreeNode) => {
      const comparator = node.data.title.toLowerCase().includes(this.searchValue.toLowerCase());

      node.data.searchedInactive = false;

      if (!comparator) {
        node.data.searchedInactive = true;
      }

      return comparator;
    });
  }

  select(event: TreeNode) {
    if (this.isDataDisabled()) {
      return;
    }
    this.selected = event;

    this.form.controls.parent.patchValue(this.selected.data.id);
    this.form.markAsDirty();
  }

  cancel() {
    this.initSelected();

    this.form.controls.parent.patchValue(this.selected.data.id);
    this.form.markAsPristine();
  }

  save(status: CMProjectStatuses) {
    if (this.isNew) {
      this.addProject(status);
    } else {
      this.updateProject(status);
    }
  }

  agree(status: CMProjectStatuses) {
    if (this.isNew) {
      this.addProject(status);
    } else {
      this.updateProject(status);
    }
  }

  archive() {
    this.warnChangeArchiveStatus(() => {
      this.updateProject(CMProjectStatuses.ARCHIVE);
    });
  }

  toggleItem(tree, node) {
    TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, null);
  }

  onInitializedTree(tree) {
    tree.treeModel.expandAll();
  }

  isDataDisabled() {
    return (
      this.project &&
      (this.project.status === CMProjectStatuses.ARCHIVE ||
        this.project.status === CMProjectStatuses.AGREED ||
        this.project.status === CMProjectStatuses.ON_AGREEMENT)
    );
  }

  isArchiveDisabled() {
    if (this.isNew) {
      return true;
    }
    return this.project.status === CMProjectStatuses.ARCHIVE || this.project.status === CMProjectStatuses.AGREED;
  }
}
