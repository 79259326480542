import { ComponentRef, Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { CompanyTooltipComponent } from './company-tooltip.component';
import { UserShortInfoNoCompetenceSections } from '../../models/user-short-info.model';

@Directive({
  selector: '[appCompanyTooltip]',
})
export class CompanyTooltipDirective implements OnInit, OnDestroy {
  @Input('appCompanyTooltip') userInfo: UserShortInfoNoCompetenceSections;
  private overlayRef: OverlayRef;
  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    const positionStrategy = this.overlayPositionBuilder.flexibleConnectedTo(this.elementRef).withPositions([
      {
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'bottom',
        offsetY: -8,
      },
    ]);
    this.overlayRef = this.overlay.create({
      positionStrategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
    });
    this.overlayRef.backdropClick().subscribe(() => this.overlayRef.detach());
  }

  ngOnDestroy(): void {
    this.overlayRef.detach();
  }

  @HostListener('click', ['$event'])
  show(event) {
    const tooltipRef: ComponentRef<CompanyTooltipComponent> = this.overlayRef.attach(
      new ComponentPortal(CompanyTooltipComponent)
    );
    tooltipRef.instance.userInfo = this.userInfo;
    tooltipRef.instance.overlayRef = this.overlayRef;
    event.stopPropagation();
  }
}
