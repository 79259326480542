import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UserFile } from '../models/user-file.model';
import { Subject, of } from 'rxjs';
import { delay, takeUntil } from 'rxjs/internal/operators';
import { FILE_MANAGER_LAYOUTS, TOOLTIP_FILE_TITLE_LENGTH, VIEW_MODE_TYPE } from '../file_manager.constants';
import { FileManagerService } from '../services/file_manager.service';

@Component({
  selector: 'app-file-manager-area',
  templateUrl: './file-manager-area.component.html',
  styleUrls: ['./file-manager-area.component.scss'],
})
export class FileManagerAreaComponent implements OnInit, OnDestroy {
  @Input() selected: UserFile[] = [];
  @Input() current: UserFile;
  @Input() searchResults: number[];
  @Input() searchMy: UserFile[];
  @Input() searchShared: UserFile[];
  @Input() layout: FILE_MANAGER_LAYOUTS;
  @Input() view: VIEW_MODE_TYPE;
  @Input() multipleSelection = true;
  @Input() isSearch: boolean;
  @Input() searchQuery: string;

  @Output() onDownload = new EventEmitter<UserFile[]>();
  @Output() onPrint = new EventEmitter<UserFile>();
  @Output() onOpen = new EventEmitter<UserFile>();
  @Output() onShare = new EventEmitter<UserFile[]>();
  @Output() onSelected = new EventEmitter<UserFile[]>();

  VIEW_TYPE = VIEW_MODE_TYPE;
  TOOLTIP_FILE_TITLE_LENGTH = TOOLTIP_FILE_TITLE_LENGTH;
  fileManagerLayouts = FILE_MANAGER_LAYOUTS;
  modal: any = {
    isOpen: false,
    file: null,
    action: {
      download: ($event?: any) => {
        this.download($event);
      },
      print: ($event?: any) => {
        this.print($event);
      },
    },
    close: () => {
      this.modal.file = null;
      this.modal.isOpen = false;
    },
  };
  private onDestroy$: Subject<boolean> = new Subject();
  private dbClick$: Subject<boolean> = new Subject();
  constructor(private fileManagerService: FileManagerService) {}

  ngOnInit() {
    this.fileManagerService
      .onOpenFile()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((file: UserFile) => {
        this.open(file);
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
    this.dbClick$.complete();
  }

  select($event, file: UserFile): void {
    $event.preventDefault();
    of(null)
      .pipe(delay(250), takeUntil(this.dbClick$))
      .subscribe(() => {
        if (this.layout === FILE_MANAGER_LAYOUTS.TRADES && !file.is_file) {
          return;
        }
        if (!this.multipleSelection) {
          this.selected = [];
        }

        if (!this.selected.find((s) => s.id === file.id)) {
          this.selected.push(file);
        } else {
          this.selected = this.selected.filter((s) => s.id !== file.id);
        }

        this.onSelected.emit(this.selected);
      });
  }

  hasSelected(file: UserFile): boolean {
    return this.selected.some((data) => data.id === file.id);
  }

  open(file: UserFile): void {
    this.dbClick$.next(true);
    if (file.hasFile()) {
      this.modal.file = file;
      this.modal.isOpen = true;
    } else {
      this.onOpen.emit(file);
    }
  }

  tooltip(file: UserFile): string | null {
    return file.name.length >= this.TOOLTIP_FILE_TITLE_LENGTH ? file.name : null;
  }

  download(file: UserFile): void {
    this.onDownload.emit([file]);
  }

  print(file: UserFile): void {
    this.onPrint.emit(file);
  }

  isSearchResults(file: UserFile): boolean {
    if (!this.isSearch) {
      return false;
    }
    return !(this.isSearch && this.searchResults.includes(file.id));
  }
}
