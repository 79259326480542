<app-table-header-cell
  [column]="column"
  (filterEvent)="clickFilterIcon()"
  (clearEvent)="clickFilterClearIcon()"
></app-table-header-cell>

<app-modal-window [isShowed]="column.isFilterOpen" (closeEvent)="closeFilter()">
  <ng-container
    *ngIf="column.id === columnKeys.ID || column.id === columnKeys.TITLE || column.id === columnKeys.AUTHOR"
  >
    <div class="modal-header">
      <div class="modal-title">{{ column.searchPlaceholder }}</div>
    </div>
    <app-multiple-search-text
      *ngIf="column.isFilterOpen"
      [filterSuggestions]="filterSuggestions"
      [placeholder]="column.searchPlaceholder"
      (submitEvent)="saveNewBaseFilter($event)"
    ></app-multiple-search-text>
  </ng-container>

  <ng-container *ngIf="column.id === columnKeys.SEND_DATE">
    <div class="modal-header">
      <div class="modal-title margin-b-40">Показать</div>
    </div>
    <app-double-datepicker-filter
      *ngIf="column.isFilterOpen"
      [value]="filterValue"
      [placeholder]="column.searchPlaceholder"
      (submitEvent)="saveDateRangeFilter($event)"
    >
    </app-double-datepicker-filter>
  </ng-container>

  <ng-container *ngIf="column.id === columnKeys.TYPE">
    <div class="modal-header">
      <div class="modal-title">Показать типы проектов</div>
    </div>
    <app-multiselect-filter
      *ngIf="column.isFilterOpen"
      [selected]="filterValue"
      [list]="projectTypes"
      (submitEvent)="saveBaseFilter($event)"
    ></app-multiselect-filter>
  </ng-container>

  <!--  <ng-container-->
  <!--    *ngIf="column.id === columnKeys.ID || column.id === columnKeys.TITLE || column.id === columnKeys.AUTHOR"-->
  <!--  >-->
  <!--    <app-search-text-->
  <!--      [placeholder]="column.searchPlaceholder"-->
  <!--      [value]="filterValue"-->
  <!--      *ngIf="column.isFilterOpen"-->
  <!--      (submitEvent)="saveBaseFilter($event)"-->
  <!--    >-->
  <!--    </app-search-text>-->
  <!--  </ng-container>-->
  <!--  <ng-container *ngIf="column.id === columnKeys.SEND_DATE">-->
  <!--    <app-double-datepicker-filter-->
  <!--      *ngIf="column.isFilterOpen"-->
  <!--      [value]="filterValue"-->
  <!--      [placeholder]="column.searchPlaceholder"-->
  <!--      (submitEvent)="saveDateRangeFilter($event)"-->
  <!--    >-->
  <!--    </app-double-datepicker-filter>-->
  <!--  </ng-container>-->

  <!--  <ng-container *ngIf="column.id === columnKeys.TYPE">-->
  <!--    <div class="modal-header">-->
  <!--      <div class="modal-title">Показать типы проектов</div>-->
  <!--    </div>-->
  <!--    <app-multiselect-filter-->
  <!--      *ngIf="column.isFilterOpen"-->
  <!--      [selected]="filterValue"-->
  <!--      [list]="projectTypes"-->
  <!--      (submitEvent)="saveBaseFilter($event)"-->
  <!--    ></app-multiselect-filter>-->
  <!--  </ng-container>-->
</app-modal-window>
