<div *ngFor="let templateFilter of filterTemplates | filter: searchString:'title'">
  <div class="dc-radio-input">
    <input
      type="radio"
      name="templateFilter"
      [disabled]="isDisabled"
      [checked]="selectedFilterTemplate.value?.title === templateFilter?.title"
      [id]="'filter_radio_' + templateFilter?.id"
      (change)="selectFilter(templateFilter)"
    />
    <label
      class="radio-label d-flex align-items-center"
      [for]="'filter_radio_' + templateFilter?.id"
      appTestLocator="filter-templates__item-{{ templateFilter?.id }}_radio"
    >
      <div class="d-inline-flex clip-title filter-template-title">
        <app-svg-icon
          (click)="toggleTreeSelectedFilter(templateFilter, false, $event)"
          class="caret-filter"
          [class.disabled]="templateFilter?.type === filterTemplateTypes.SIMPLE"
          [name]="templateFilter?.isExpanded ? 'caret_up' : 'caret_down'"
          width="9.5"
        ></app-svg-icon>
        <span
          class="ml-3 tree-selection-title"
          [ngbTooltip]="templateFilter?.title"
          [disableTooltip]="templateFilter?.title?.length < 24"
        >
          {{ templateFilter?.title || '-' }}
        </span>
      </div>
    </label>
    <ng-container *ngIf="templateFilter?.isExpanded">
      <ng-container
        *ngTemplateOutlet="templateFilterTreeOrValue; context: { $implicit: templateFilter }"
      ></ng-container>
    </ng-container>
  </div>
</div>

<ng-template #templateFilterTreeOrValue let-node>
  <ng-container *ngIf="$any(node).isExpanded">
    <ng-container [ngSwitch]="node.type">
      <!-- CHOICE -->
      <ng-container *ngSwitchCase="filterTemplateTypes.CHOICE">
        <ng-container *ngIf="node?.children?.length">
          <div
            *ngFor="let treeItem of node.children"
            [ngClass]="getNestedTitleClassName(treeItem)"
            appTestLocator="filter-templates__item-{{ treeItem?.id }}_choice"
          >
            <div class="d-inline-flex clip-title">
              <app-svg-icon
                (click)="toggleTreeSelectedFilter(treeItem, false, $event)"
                class="caret-filter"
                appTestLocator="filter-templates__item-{{ treeItem?.id }}_arrow"
                [name]="treeItem.isExpanded ? 'caret_up' : 'caret_down'"
                width="9.5"
              ></app-svg-icon>
              <span class="ml-3" appTestLocator="filter-templates__item-{{ treeItem?.id }}_title">{{
                treeItem.title || '-'
              }}</span>
            </div>
            <ng-container
              *ngTemplateOutlet="templateFilterTreeOrValue; context: { $implicit: treeItem }"
            ></ng-container>
          </div>
        </ng-container>
      </ng-container>
      <!-- /CHOICE -->

      <!-- BOOL -->
      <ng-container *ngSwitchCase="filterTemplateTypes.BOOL">
        <div class="padding-l-42" appTestLocator="filter-templates__item-{{ treeItem?.id }}_bool">
          <app-form-checkbox-switch [checked]="node?.value" [readonly]="true"></app-form-checkbox-switch>
        </div>
      </ng-container>
      <!-- /BOOL -->

      <!-- RANGE -->
      <ng-container *ngSwitchCase="filterTemplateTypes.RANGE">
        <app-form-range-slider
          appTestLocator="filter-templates__item-{{ treeItem?.id }}_range"
          [readonly]="true"
          [setValueFrom]="node.default_range_from"
          [setValueTo]="node.default_range_to"
        ></app-form-range-slider>
      </ng-container>
      <!-- /RANGE -->
    </ng-container>
  </ng-container>
</ng-template>
