import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompetenceMapViewComponent } from './competence-map-view.component';
import { CompetenceSharedModule } from '../competence-shared.module';

@NgModule({
  declarations: [CompetenceMapViewComponent],
  exports: [CompetenceMapViewComponent],
  imports: [CommonModule, CompetenceSharedModule],
})
export class CompetenceMapViewModule {}
