import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ITreeOptions, TREE_ACTIONS, TreeComponent, TreeModel, TreeNode } from '@circlon/angular-tree-component';

import { DCTreeItem } from '@app/+competence-map/models/competence-map.models';
import { CMCatalogTypes } from '@app/+competence-map/constants/sections.constants';
import { DestroyService } from '@app/services/destroy.service';

@Component({
  selector: 'app-file-manager-share-user-filter-tree',
  templateUrl: './file-manager-share-user-filter-tree.component.html',
  styleUrls: ['./file-manager-share-user-filter-tree.component.scss'],
  providers: [DestroyService],
})
export class FileManagerShareUserFilterTreeComponent implements OnInit {
  private _selected: number[] = [];
  selectedAll: boolean = false;
  treeOptions: ITreeOptions = {};
  isTreeExpanded1: boolean = false;
  isTreeExpanded2: boolean = false;

  @ViewChild('itemsTree') private itemsTree: TreeComponent;

  @Input() items: DCTreeItem[] = [];
  @Input() type: CMCatalogTypes;

  @Output() selectItemEmit: EventEmitter<number[]> = new EventEmitter<number[]>();

  @Input() set selected(value: number[]) {
    this._selected = value;
  }

  ngOnInit(): void {
    if (this.items.length > 0) {
      this.initialSelected(this.items);
    }
  }

  initialSelected(items: DCTreeItem[]): void {
    items.forEach((item: DCTreeItem) => {
      if (this._selected.includes(item.id)) {
        item.selected = true;
      }
      if (item.children?.length > 0) {
        this.initialSelected(item.children);
      }
    });
  }

  selectAll(items: DCTreeItem[], event: any): void {
    items.forEach((item: DCTreeItem) => {
      item.selected = event.target.checked;
      this.selectItem(item);
      if (item.children?.length > 0) {
        this.selectAll(item.children, event);
      }
    });
  }

  selectItem(item: DCTreeItem): void {
    if (item.selected) {
      if (!this._selected.includes(item.id)) {
        this._selected.push(item.id);
      }
    } else {
      this._selected.splice(this._selected.indexOf(item.id), 1);
    }
    this.selectItemEmit.emit(this._selected);
  }

  searchItems(query: string): void {
    this.itemsTree.treeModel.filterNodes((node: any) => {
      return node.data.title.toLowerCase().includes(query?.toLowerCase());
    });
  }

  expandItem(tree: TreeComponent, node: TreeNode, $event: any): void {
    TREE_ACTIONS.TOGGLE_EXPANDED(tree.treeModel, node, $event);
  }

  toggleByLevel(tc: any, level: number): void {
    const tree: TreeModel = tc.treeModel;
    switch (level) {
      case 1:
        if (this.isTreeExpanded1) {
          tree.getVisibleRoots().forEach((node: TreeNode) => node.collapse());
          this.isTreeExpanded1 = false;
        } else {
          tree.getVisibleRoots().forEach((node: TreeNode) => node.expand());
          this.isTreeExpanded1 = true;
        }
        break;
      case 2:
        if (!this.isTreeExpanded2 && !this.isTreeExpanded1) {
          this.toggleByLevel(tc, 1);
        }
        if (this.isTreeExpanded2) {
          tree.getVisibleRoots().forEach((node: TreeNode) => {
            node.getVisibleChildren().forEach((n: TreeNode) => n.collapse());
          });
          this.isTreeExpanded2 = false;
        } else {
          tree.getVisibleRoots().forEach((node: TreeNode) => {
            node.getVisibleChildren().forEach((n: TreeNode) => n.expand());
          });
          this.isTreeExpanded2 = true;
        }
        break;
    }
  }
}
