import { InjectionToken } from '@angular/core';
import { SVGSpriteConfig } from '../types/svg-sprite-config.type';

export const SVG_SPRITE_CONFIG = new InjectionToken<SVGSpriteConfig>('SvgSpriteConfig', {
  providedIn: 'root',
  factory: () => {
    return {
      getBaseSvgFilePath: (name: string) => `assets/icons/files/${name}.svg`,
      getBaseSvgIconPath: (name: string) => `assets/icons/${name}.svg`,
      spriteFilePrefix: `dc-sprite_file__`,
      spriteIconPrefix: `dc-sprite_icon__`,
      spriteContainer: `dc-sprite-container`,
    };
  },
});
