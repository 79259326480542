import { Component } from '@angular/core';
import { AuthService } from '@app/shared/services/auth.service';
import { Validation } from '@app/shared/services/validation.service';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import isEmpty from 'lodash-es/isEmpty';

@Component({
  templateUrl: `./resetpass.component.html`,
  styleUrls: ['../auth.component.sass'],
})
export class ResetPassComponent {
  email = '';
  errors = <any>{};

  constructor(
    private authService: AuthService,
    private notify: NotificationsService,
    private router: Router,
    private translate: TranslateService
  ) {}

  reset(): void {
    this.errors = {};

    const mail_error = Validation.validateEmail(this.email);
    if (mail_error.length) this.errors.email = mail_error;
    if (!isEmpty(this.errors)) return;

    this.authService.resetPass(this.email).subscribe(
      () => {
        this.notify.success('Успешно!', 'Вам на почту отправлено письмо для восстановления пароля!');
        this.router.navigate(['signin']);
      },
      error => {
        if (error.errors) this.errors = error.errors;
        if (error.message !== 'Validation failed') this.notify.error('Ошибка!', this.translate.instant(error.message));
      }
    );
  }
}
