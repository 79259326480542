import { Pipe, PipeTransform } from '@angular/core';
import { UserTypes } from '../types/user.types';
import { getFullTitleRole, getTitleRole } from '@app/shared/utils';

@Pipe({
  name: 'fullTitleRole',
})
export class FullTitleRolePipe implements PipeTransform {
  transform(type: UserTypes): any {
    return getFullTitleRole(type);
  }
}
