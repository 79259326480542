import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { Subject } from 'rxjs';

import { ChatService } from '@app/chat/services/chat.service';

@Component({
  selector: 'app-chat-contacts-filter',
  templateUrl: './chat-contacts-filter.component.html',
  styleUrls: ['./chat-contacts-filter.component.scss'],
})
export class ChatContactsFilterComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  isUsingSearch: boolean = false;
  searchForm: FormGroup;
  userType: string;

  constructor(private chatService: ChatService) {}

  ngOnInit() {
    this.initForm();
    this.chatService.chatSectionSelectedChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.onClearSearch();
    });
  }

  initForm() {
    this.searchForm = new FormGroup({
      searchText: new FormControl(null),
    });
  }

  onKeyUp() {
    const searchText = this.searchForm.value.searchText || '';
    this.chatService.filterContactsGroupsThemes(searchText);
    this.isUsingSearch = !!searchText;
  }

  onClearSearch() {
    this.searchForm.reset();
    this.isUsingSearch = false;
    const searchText = this.searchForm.value.searchText || '';
    this.chatService.filterContactsGroupsThemes(searchText);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
