import { Pipe, PipeTransform } from '@angular/core';
import { ChatRoom } from '../models/chat.model';

@Pipe({
  name: 'groupsRolesFilter',
})
export class GroupsRolesFilterPipe implements PipeTransform {
  transform(items: ChatRoom[], roles: string[]): any {
    if (!roles) {
      return items;
    }
    if (!roles.length) {
      return items;
    }

    const newItems = [];

    items.forEach(item => {
      const owner = item.userItems.find(user => user.id === item.owner_id);

      if (owner && roles.includes(owner.type)) {
        newItems.push(item);
      }
    });

    return newItems;
  }
}
