import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ITreeOptions, TREE_ACTIONS, TreeComponent, TreeModel, TreeNode } from '@circlon/angular-tree-component';
import { DCTreeItem } from '@app/+competence-map/models/competence-map.models';
import { CompetenceService } from '@app/+competence-map/services/competence.service';
import { doForAllSync, keepCaretState } from '@app/+competence-map/helpers/competence-map.helpers';
import { CMCatalogTypes, CMSectionStatuses } from '@app/+competence-map/constants/sections.constants';
import { CMActions } from '@app/+competence-map/constants/projects.constants';
import { TreeHelper } from '@app/shared/helpers/tree.helper';
import { MultipleSearchSuggestion } from '@app/shared/models/multiple-search.model';
import {
  CompetenceDataService,
  GOODS_COMPETENCE_STATE,
  SERVICES_COMPETENCE_STATE,
} from '@app/+competence-map/services/competence-data.service';
import { ProviderCompetency, TreeSectionsAndFilters } from '@app/+competence-map/models/user-competency.model';
import { UserCompetencyTypes } from '@app/+competence-map/constants/user-competency.constants';
import { takeUntil } from 'rxjs/operators';
import { DestroyService } from '@app/services/destroy.service';
import { ActivatedRoute } from '@angular/router';

function flatten<T extends { children: T[] }>(item: T): readonly T[] {
  return item.children.length ? item.children.map(flatten).reduce((arr, item) => [...arr, ...item], []) : [item];
}

@Component({
  selector: 'app-my-catalog-sections',
  templateUrl: './my-catalog-sections.component.html',
  styleUrls: ['./my-catalog-sections.component.scss'],
  providers: [DestroyService],
})
export class MyCatalogSectionsComponent implements OnInit {
  private _myCompetencies: ProviderCompetency[];
  private _catalog: CMCatalogTypes;
  get catalog() {
    return this._catalog;
  }
  @Input() set catalog(value: CMCatalogTypes) {
    this._catalog = value;
    this.initialize();
  }

  private _competencies: ProviderCompetency[];
  get competencies() {
    return this._competencies;
  }
  @Input() set competencies(competencies: ProviderCompetency[]) {
    this._competencies = competencies;

    if (this.state) {
      this.highlightByCompetencies(this._competencies);
    }
  }

  @Input() data: TreeSectionsAndFilters[] = [];
  @Input() readonly: boolean;
  @Input() level = 0;
  @Input() isExpert: boolean;
  @Input() isMyCompetencies: boolean;

  @Output() searchEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSectionSelect: EventEmitter<TreeSectionsAndFilters> = new EventEmitter<TreeSectionsAndFilters>();
  @Output() onCompetencyChanged: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Output() onShowedMyCompetencies: EventEmitter<{
    nodes: TreeSectionsAndFilters[];
    hiddenIds: { [key: number]: boolean };
  }> = new EventEmitter<{
    nodes: TreeSectionsAndFilters[];
    hiddenIds: { [key: number]: boolean };
  }>();

  @ViewChild('catalogTree') private tree: TreeComponent;

  selectedItemId: number;
  isCatalogTreeExpandedL1: boolean;
  isCatalogTreeExpandedL2: boolean;
  keepCaretState = keepCaretState;
  treeOptions: ITreeOptions = {
    displayField: 'title',
    isExpandedField: 'expanded',
    idField: 'id',
    nodeHeight: 23,
  };

  modals: {
    [key: string]: {
      isShowed: boolean;
      data?: DCTreeItem;
    };
  } = {};

  treeSuggestions: MultipleSearchSuggestion[] = [];
  CMActions = CMActions;
  allSectionsSelected: boolean;
  isShowedMy: boolean;

  filterValues: {
    [key: string]: {
      value: string | MultipleSearchSuggestion[];
    };
  } = {};

  private state: CompetenceDataService;
  private previousSelectionMap: Map<number, boolean> = new Map();

  get allPartiallySelected() {
    return (
      (this.data.length && this.data.some((item) => item.selected) && !this.data.every((item) => item.selected)) ||
      this.data.some((item) => item.selected === null)
    );
  }

  selectionMap = new Map<number, boolean>();

  constructor(
    @Inject(SERVICES_COMPETENCE_STATE) private servicesStateService: CompetenceDataService,
    @Inject(GOODS_COMPETENCE_STATE) private goodsStateService: CompetenceDataService,
    private competenceService: CompetenceService,
    private destroy$: DestroyService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.initData();
  }

  initialize() {
    if (this.route.snapshot.data.myCompetencies) {
      this._myCompetencies = this.route.snapshot.data.myCompetencies.sectors[this._catalog];
    }

    this.state = this._catalog === CMCatalogTypes.GOODS ? this.goodsStateService : this.servicesStateService;
    this.state.resetSubject.pipe(takeUntil(this.destroy$)).subscribe(() => this.initData());
    this.state.filterSelectSubject.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      doForAllSync(this.data, (item) => {
        if (item.id === value.id) {
          flatten(item).forEach((item) => {
            item.selected = true;
            this.updateSelectionMap(item.id, item.selected);
          });
        }
      });

      this.selectionMap = new Map(this.selectionMap.entries());

      this.state.competencySelectionSections = this.data;
      this.updateAllSelectedCheckbox();
      this.searchEvent.emit();
    });
  }

  highlightByCompetencies(competencies: ProviderCompetency[]) {
    this.setPropToDataByCompetencies(competencies);
    this.state.competencySelectionSections = this.data;
    this.updateAllSelectedCheckbox();
  }

  setPropToDataByCompetencies(competencies: ProviderCompetency[], prop = 'selected') {
    if (competencies?.length && this.data) {
      if (!this.isExpert) {
        doForAllSync(this.data, (childItem, nodes, i, parent) => {
          childItem[prop] = parent?.[prop] || competencies.map((el) => +el.node_in_tree_id).includes(+childItem.id);
        });
      } else {
        doForAllSync(this.data, (childItem: any, nodes, i, parent) => {
          childItem[prop] = parent?.[prop] || competencies.map((el) => +el.section_id).includes(+childItem.section_id);
        });
      }
    }

    doForAllSync(this.data, (childItem) => {
      this.updateSelectionMap(childItem.id, childItem.selected);
    });
  }

  toggleByLevel(tc: any, level: number): void {
    const tree: TreeModel = tc.treeModel;
    switch (level) {
      case 1:
        if (this.isCatalogTreeExpandedL1) {
          tree.getVisibleRoots().forEach((node: TreeNode) => node.collapse());
          this.isCatalogTreeExpandedL1 = false;
        } else {
          tree.getVisibleRoots().forEach((node: TreeNode) => node.expand());
          this.isCatalogTreeExpandedL1 = true;
        }
        break;
      case 2:
        if (!this.isCatalogTreeExpandedL2 && !this.isCatalogTreeExpandedL1) {
          this.toggleByLevel(tc, 1);
        }
        if (this.isCatalogTreeExpandedL2) {
          tree.getVisibleRoots().forEach((node: TreeNode) => {
            node.getVisibleChildren().forEach((n: TreeNode) => n.collapse());
          });
          this.isCatalogTreeExpandedL2 = false;
        } else {
          tree.getVisibleRoots().forEach((node: TreeNode) => {
            node.getVisibleChildren().forEach((n: TreeNode) => n.expand());
          });
          this.isCatalogTreeExpandedL2 = true;
        }
        break;
    }
  }

  initData(): void {
    this.competenceService
      .getSectionsAndFilters([
        {
          name: 'type',
          op: 'eq',
          val: UserCompetencyTypes.SECTOR,
        },
        {
          name: 'status_section',
          op: 'eq',
          val: CMSectionStatuses.ACTIVE,
        },
        {
          name: 'catalog',
          op: 'eq',
          val: this._catalog,
        },
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        const result = value.map((item) => {
          return {
            ...item,
            parent: {
              id: item.parent_id,
            },
          };
        });

        this.data = TreeHelper.list_to_tree(result);
        this.getTreeSuggestions();
        this.highlightByCompetencies(this._competencies);
        this.setPropToDataByCompetencies(this._myCompetencies, 'isMyCompetence');
      });
  }

  getTreeSuggestions() {
    this.treeSuggestions = TreeHelper.treeToArray(this.data)
      .map((item) => {
        return {
          id: item.id,
          label: item.section.title.trim(),
          value: true,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  sectionSelected(event) {
    this.selectedItemId = event.id;
    this.state.sectionSelectSubject.next();
    this.onSectionSelect.emit(event.node.data);
  }

  checkboxSelectionChanged(event: { id: number; node: TreeNode }) {
    if (!this.isMyCompetencies && this.isShowedMy) {
      this.isShowedMy = false;
      this.previousSelectionMap.clear();
      this.tree.treeModel.clearFilter();
    }

    flatten(event.node.data).forEach((item) => {
      item.selected = event.node.data.selected;
      this.updateSelectionMap(item.id, event.node.data.selected);
    });

    this.selectionMap = new Map(this.selectionMap.entries());

    this.state.competencySelectionSections = this.data;
    this.sectionSelected(event);
    this.updateAllSelectedCheckbox();
    this.searchEvent.emit();
  }

  getValue(item: TreeSectionsAndFilters, map: Map<number, boolean>): boolean | null {
    const flat = flatten(item);
    const result = !!map.get(flat[0].id);

    for (const item of flat) {
      if (result !== !!map.get(item.id)) {
        return null;
      }
    }

    return result;
  }

  showOnlyMySections() {
    if (this.isShowedMy) {
      this.tree.treeModel.filterNodes((node) => {
        if (this.isMyCompetencies) {
          return node.data.selected;
        } else {
          if (node.data.selected) {
            this.previousSelectionMap.set(node.data.id, node.data.selected);
          }

          if (node.data.isMyCompetence) {
            node.data.selected = true;
            this.updateSelectionMap(node.data.id, node.data.selected);
          }

          return node.data.isMyCompetence;
        }
      });
    } else {
      if (this.isMyCompetencies) {
        this.tree.treeModel.clearFilter();
      } else {
        this.tree.treeModel.filterNodes((node) => {
          if (node.data.isMyCompetence) {
            node.data.selected = this.previousSelectionMap.get(node.data.id);
            this.updateSelectionMap(node.data.id, node.data.selected);
          }

          return true;
        });

        this.previousSelectionMap.clear();
      }
    }

    this.selectionMap = new Map(this.selectionMap.entries());
    // Асинхронно эмитим событие, чтобы дерево обновилось согласно selectionMap
    setTimeout(() => {
      this.onShowedMyCompetencies.emit(
        this.isShowedMy
          ? {
              nodes: this.data,
              hiddenIds: this.tree.treeModel.hiddenNodeIds,
            }
          : null
      );
    });
  }

  expandItem(tree: TreeModel, node: TreeNode, $event: any): void {
    TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
  }

  openSearchModal() {
    this.modals[CMActions.SEARCH] = {
      isShowed: true,
    };

    const filterValue = this.filterValues[CMActions.SEARCH]?.value;

    this.treeSuggestions = this.treeSuggestions.map((suggestion) => {
      return {
        ...suggestion,
        value: Array.isArray(filterValue) ? !!filterValue.find((item) => item.id === suggestion.id) : false,
      };
    });
  }

  updateAllSelectedCheckbox() {
    this.allSectionsSelected = this.data.length && this.data.every((item) => item.selected);
  }

  closeActionModal(action: CMActions) {
    this.modals[action] = {
      isShowed: false,
    };
  }

  filterTreeData(query: MultipleSearchSuggestion[]): void {
    const selected = query.filter((item) => item.value);

    this.tree.treeModel.filterNodes((node) => {
      node.data.searchedInactive = false;

      if (!selected.length) {
        return true;
      }

      const matched = selected.map((item) => item.label).includes(node.data.section.title);

      if (!matched) {
        node.data.searchedInactive = true;
      }

      return matched;
    });

    this.filterValues[CMActions.SEARCH] = {
      value: selected,
    };

    this.closeActionModal(CMActions.SEARCH);
  }

  hasFilterValue(action): boolean {
    return this.filterValues[action] && !!this.filterValues[action].value.length;
  }

  expandItemFully(node: TreeNode): void {
    if (!node.isExpanded) {
      node.expandAll();
    } else {
      node.collapseAll();
    }
  }

  selectAll(value: boolean) {
    this.allSectionsSelected = value;

    doForAllSync(this.data, (item: TreeSectionsAndFilters) => {
      if (item.status_section !== CMSectionStatuses.ARCHIVE) {
        item.selected = value;

        this.updateSelectionMap(item.id, value);
      }
    });

    this.state.competencySelectionSections = this.data;
    this.searchEvent.emit();
  }

  private updateSelectionMap(id: number, value: boolean | null) {
    this.selectionMap.set(id, value);
  }
}
