export const VERSIONS_DESCRIBE = {
  '0.3.8': [
    {
      title: 'DIR-3 Доработки по модулям "ЛД" и "Сотрудники"',
      href: 'https://jira.dircont.com/issue/DIR-3'
    },
    {
      title: 'DIR-12 Добавить "дерево сотрудников"',
      href: 'https://jira.dircont.com/issue/DIR-12'
    },
    {
      title: 'DIR_DESIGN-3 Разработка UI-дизайна для страницы "Сотрудники"',
      href: 'https://jira.dircont.com/issue/DIR_DESIGN-3'
    },
  ],
  '0.4.0': [
    {
      title: 'DIR-18 Реализовать механизм назначения Партнера в качестве ОТП',
      href: 'https://jira.dircont.com/issue/DIR-18'
    },
    {
      title: 'DIR_DESIGN-1 Доработки по форме "Личные документы"',
      href: 'https://jira.dircont.com/issue/DIR_DESIGN-1'
    },
    {
      title: 'DIR-15 Модуль "Часовой пояс / Виджет Часы"',
      href: 'https://jira.dircont.com/issue/DIR-15'
    },
  ],
  '0.5.0': [
    {
      title: 'DIR-22 Модуль "Репутация поставщиков" Часть-1 "ЧБ список"',
      href: 'https://jira.dircont.com/issue/DIR-22'
    },
  ], '0.6.0': [
    {
      title: 'DIR-27 Модуль "Календарь событий"',
      href: 'https://jira.dircont.com/issue/DIR-27'
    },
    {
      title: 'DIR-26 Доработки модуля "Репутация"(DIR-22)',
      href: 'https://jira.dircont.com/issue/DIR-26'
    },
    {
      title: 'DIR_DESIGN-9 Разработка UI-дизайна модуля "Репутация (ЧБ-метки)"',
      href: 'https://jira.dircont.com/issue/DIR_DESIGN-9'
    },
  ], '0.7.2': [
    {
      title: 'DIR-24 Модуль "Тарифы"',
      href: 'https://jira.dircont.com/issue/DIR-24'
    },
    {
      title: 'DIR-30 Доработки Модуля "Календарь событий"',
      href: 'https://jira.dircont.com/issue/DIR-30'
    },
  ], '0.8.0': [
    {
      title: 'DIR-28 Модуль "ЧаВо"',
      href: 'https://jira.dircont.com/issue/DIR-28'
    },
    {
      title: 'DIR-32 Доработки по модулю Тарифы',
      href: 'https://jira.dircont.com/issue/DIR-32'
    },
    {
      title: 'Переработана работа календаря(обновление, без перезапуска страницы)',
      href: ''
    }
  ]
};
