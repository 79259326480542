<div class="file-manager-tree-folder">
  <div class="file-manager-tree-folder__title">
    <app-svg-icon
      [width]="12"
      [name]="file?.virtualOpened ? 'caret_up' : 'caret_down'"
      class="file-manager-tree-folder__caret-icon"
      (click)="toggleOpenFile()"
      appTestLocator="tree__file-{{ file?.id }}_caret"
    ></app-svg-icon>
    <span
      class="file-manager-tree-folder__title-text"
      appTestLocator="tree__file-{{ file?.id }}"
      (selectFile.single)="selectFile()"
      (selectFile.double)="openSelectFile()"
    >
      <app-svg-icon
        [width]="16"
        [name]="file?.virtualOpened ? 'folder_open' : 'folder'"
        class="file-manager-tree-folder__folder-icon"
      ></app-svg-icon>
      <span
        [class.file-manager-tree-folder__root]="isRoot"
        [class.disabled]="isSearchQuery && !file.search_result"
        [innerHTML]="file?.name | highlight: searchQuery"
      ></span>
    </span>
  </div>

  <div class="file-manager-tree-folder__info">
    <app-svg-icon
      *ngIf="isShowIcon && file?.in_shared_dir && !isRoot"
      (click)="showUserFile()"
      [width]="16"
      appTestLocator="tree__file-{{ file?.id }}_crown"
      [height]="12.8"
      class="file-manager-tree-folder__info-icon"
      name="crown"
    ></app-svg-icon>

    <app-svg-icon
      *ngIf="isShowIcon && !file?.in_shared_dir && (file?.is_shared || file?.inner_sharing) && !isRoot"
      (click)="shareFile()"
      appTestLocator="tree__file-{{ file?.id }}_share"
      [width]="16"
      class="file-manager-tree-folder__info-icon"
      [class.disabled]="file?.inner_sharing && !file?.is_shared"
      name="users"
    ></app-svg-icon>

    <app-svg-icon
      appTestLocator="tree__file-{{ file?.id }}_help"
      *ngIf="isShowIcon && isRoot"
      (click)="showHelpFile()"
      [width]="16"
      class="file-manager-tree-folder__info-icon"
      name="question-circle-color"
    ></app-svg-icon>
  </div>
</div>
