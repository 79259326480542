import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { SearchSuggestion } from '@app/shared/models/search.model';
import { FilterPipe } from '@app/shared/pipes/filter.pipe';

@Component({
  selector: 'app-live-search',
  templateUrl: './live-search.component.html',
  styleUrls: ['./live-search.component.scss'],
  providers: [FilterPipe],
})
export class LiveSearchComponent {
  @Input() template: TemplateRef<any>;
  @Input() placeholder: string = '';
  @Input() disabled: boolean;
  @Input() list: SearchSuggestion[] = [];

  @Output() onSubmit = new EventEmitter<SearchSuggestion>();

  searchValue = '';
  selected: SearchSuggestion = null;

  select(item: SearchSuggestion) {
    this.selected = this.selected?.id === item.id ? null : item;
  }

  apply() {
    this.onSubmit.emit(this.selected);
  }

  cancel() {
    this.selected = null;
  }
}
