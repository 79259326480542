<app-modal-window [isShowed]="isOpened" (closeEvent)="isOpened = false">
  <div class="text-center">
    <p>Внимание! Восстановление из статуса {{ status }} невозможно!</p>
    <p>Вы действительно хотите сменить статус на {{ status }}?</p>
    <div class="d-flex justify-content-between">
      <button class="btn btn-dc-outline" (click)="cancel()">Нет, отменить</button>
      <button class="btn btn-dc-primary" (click)="agree()">Да, сменить</button>
    </div>
  </div>
</app-modal-window>
