import { Pipe, PipeTransform } from '@angular/core';
import {DatesHelper} from '../helpers/dates.helper';

@Pipe({
  name: 'dateTableFormat'
})
export class DateTableFormatPipe implements PipeTransform {

  transform(value: any, offset?: any): any {
    if (!value) {
      return '-';
    }
    return DatesHelper.formatTableDate(value, offset);
  }

}
