import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'brToSpace'
})
export class BrToSpacePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value.replace(/<br\/>/g, ' ');
  }

}
