<div class="list-notification">
  <div class="row list-notification_viewed-all" [class.disabled]="!countNotification">
    <span (click)="viewedAll()">Пометить все прочитанным</span>
  </div>
  <div class="list-notification__noti">
    <ng-container *ngFor="let noti of dataSource.notifications">
      <div
        [class.no_read]="noti.status === notificationStatusEnum.SENT"
        class="row list-notification__noti__one"
        (mouseoverTime)="noti.status === notificationStatusEnum.SENT && viewedNoti(noti)"
      >
        <span class="col-2 ws-pre">
          {{ authService.getMomentGtmTimezone(noti.sending_date) | dateTimeShortFormatLineBreak }}
        </span>
        <span class="col-10">
          {{ noti.text }}
          <fa-icon
            *ngIf="noti.source?.module"
            (click)="generateLinkNotification(noti.source, noti)"
            class="list-notification__noti__one__copy-icon"
            [icon]="faExternalLinkAlt"
          ></fa-icon>
        </span>
      </div>
    </ng-container>
  </div>
  <div class="list-notification__button">
    <button
      (click)="showMore(dataSource.notifications?.length)"
      class="btn btn-outline-primary btn-pill"
      [disabled]="dataSource.notifications?.length == dataSource.total || disableButtonShowMore"
    >
      Показать ещё
    </button>
  </div>
</div>
