import { Pipe, PipeTransform } from '@angular/core';
import { Company } from '@app/shared/models/company.model';

@Pipe({
  name: 'companyName',
})
export class CompanyNamePipe implements PipeTransform {
  transform(value: { organization_type: string; organization_name?: string; name?: string } | Company): any {
    if (!value) {
      return '';
    }
    if (value.name) {
      return value.name;
    }
    return `${value.organization_type || ''} ${value.organization_name || '-'}`;
  }
}
