import { Pipe, PipeTransform } from '@angular/core';
import { FilterTemplateTypes } from '../../constants/filter-templates.constants';
import { FilterTemplate } from '../../models/filter-templates.model';

@Pipe({
  name: 'templateFilterValue',
})
export class TemplateFilterValuePipe implements PipeTransform {
  transform(value: FilterTemplate): unknown {
    switch (value.type) {
      case FilterTemplateTypes.BOOL: {
        return value.value === 'true' ? 'Да' : 'Нет';
      }
      case FilterTemplateTypes.RANGE: {
        return value.value;
      }
    }
  }
}
