import { HostListener } from '@angular/core';
import { ElementRef } from '@angular/core';
import { Directive, Input } from '@angular/core';
import { FormControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[minValue]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: MinValueDirective,
      multi: true,
    },
  ],
})
export class MinValueDirective {
  @Input('minValue') minValue: number;
  @Input('isStrict') isStrict = false;

  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home'];

  constructor(private el: ElementRef) {}

  validate(c: FormControl): { [key: string]: { valid: boolean } } {
    const v = c.value;
    const invalid = { minValue: { valid: false } };

    if (this.minValue !== null && +v !== null && +v < +this.minValue) {
      return invalid;
    }

    return null;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (!this.isStrict || this.specialKeys.includes(event.key)) {
      return;
    }

    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);

    if (next && (isNaN(+next) || +next < this.minValue)) {
      event.preventDefault();
    }
  }
}
