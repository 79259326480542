<div class="switch-wrapper">
  <span *ngIf="withLabels">да</span>
  <div
    class="switch"
    [class.without-labels]="!withLabels"
    [class.active]="checked"
    (click)="!readonly ? switch.click() : false"
    appTestLocator="switch__element"
  >
    <div class="switch-circle"></div>
    <input type="checkbox" [ngModel]="checked" (ngModelChange)="onModelChange($event)" #switch />
  </div>
  <span *ngIf="withLabels">нет</span>
</div>
