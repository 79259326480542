import { Pipe, PipeTransform } from '@angular/core';
import { CMProjectStatuses, PROJECT_STATUSES } from '@app/+competence-map/constants/projects.constants';

@Pipe({
  name: 'cmProjectStatus',
})
export class CmProjectStatusPipe implements PipeTransform {
  transform(value: CMProjectStatuses): unknown {
    const target = PROJECT_STATUSES.find((item) => item.value === value);
    return target ? target.label : '';
  }
}
